<h2 style="font-weight: bold" mat-dialog-title>Reservation Status Help</h2>
<mat-dialog-content class="mat-typography">
    <p>There are two flows based in the payment method selected:</p>
    <ol>
        <li>
            <p>In the case of payment with Yardi, the status flow will be as follows:</p>
            <ul>
                <li>Pre-reserved -> Processed -> Yardi Payment done -> Consumed.</li>
            </ul>
        </li>
        <li>
            <p>In the case of payment with Paypal, the status flow will be as follows:</p>
            <ul>
                <li>Pre-reserved -> PayPal Payment Pending -> Paypal Payment done -> PayPal Payment done - Yardi notified -> Consumed.</li>
            </ul>
        </li>
    </ol>
    <p>Both flows consider the status Cancelled, if any.</p>
    <img [src]="url">
    <p>Below there is a description about all statuses defined: </p>
    <ul>
        <li><b style="color: rgb(121,85,72);">Pre-reservation:</b> it happens during the reservation making time and it hasn't
            been selected the payment method yet. 10 minutes maximal. </li>
        <li><b style="color: rgb(255,152,0);">Processed:</b> Yardi's payment method has been selected. The system has already
            created the reservation in the final EPS system.</li>
        <li><b style="color: rgb(76,175,80);">Yardi Payment Generated:</b> the reservation has started and the payment charge has
            been marked to be sent to yardi (at the end of that day).</li>
        <li><b style="color: rgb(255,152,0);">PayPal Payment Pending:</b> payment by paypal was selected, the user for whom
            the reservation has been created is notified that he/she has a reservation pending payment. </li>
        <li><b style="color: rgb(76,175,80);">Paypal Payment done:</b>the pending paypal payment has been made. </li>
        <li><b style="color: rgb(76,175,80);">PayPal Payment done - Billing notified:</b> the paypal payment done has been notified to Billing System. </li>
        <li><b style="color: rgb(33, 150,243);">Consumed:</b> the reservation date has passed.</li>
        <li><b style="color: rgb(244,67,54);">Cancelled:</b> the reservation has been cancelled.</li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>