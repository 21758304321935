export class MicroappVmsTime {
    hour: number = null;
    minutes: number = null;

    constructor(time_in: string) {
        let time = time_in;
        if (time_in && time_in.length>5)
            time = time_in.substring(0,5);

        var re = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
        if (time && re.test(time)) {
            if (time.length == 5) {
                this.hour = +time.substr(0, 2);
                this.minutes = +time.substr(3, 2);
            }else{
                this.hour = +time.substr(0, 1);
                this.minutes = +time.substr(2, 2);
            }
        }
    };

}