import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EpsState } from './microapp-eps.reducer';


export const selectEpsState =
  createFeatureSelector<EpsState>("eps");

export const selectVehicleTypes = createSelector(
  selectEpsState,
  state => state.parkingVehicleTypes
);

export const selectReservationTypes = createSelector(
  selectEpsState,
  state => state.reservationTypes
);

export const selectSpotTypes = createSelector(
  selectEpsState,
  state => state.spotTypes
);

export const isLoading = createSelector(
  selectEpsState,
  state => state.loading
);





