export const MICROAPPFAKEDATABASE = {
    users: [
        {
            uid: "100",
            userName: "john.doe",
            userPassword: "jam",
            firstName: "John",
            lastName: "Doe",
            mail: "generic@user.com",
            sites: [
                {
                    uid: "1",
                    name: "Build 1",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "el tenant",
                            description: "Test tenant 1"
                        }
                    ]
                }
            ],
            roles: [
                {
                    uid: "USER",
                    name: "USER",
                },
    
                
            ]
        },
        {
            uid: "100",
            userName: "jane.doe",
            userPassword: "jam",
            firstName: "generic Tenant",
            lastName: "generic Fake",
            mail: "generic@user.com",
            sites: [
                {
                    uid: "1",
                    name: "Build 1",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "el tenant",
                            description: "Test tenant 1"
                        },
                        {
                            uid: "2",
                            name: "el tenant 2",
                            description: "Test tenant 2"
                        }
                    ]
                },
                {
                    uid: "2",
                    name: "Build 2",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "3",
                            name: "b2 el tenant",
                            description: "Test tenant 1"
                        },
                        {
                            uid: "4",
                            name: "b2 el tenant 2",
                            description: "Test tenant 2"
                        }
                    ]
                }

            ],
            roles: [
                {
                    uid: "USER",
                    name: "USER",
                },
                {
                    uid: "MANAGEMENT",
                    name: "MANAGEMENT",
                },
            ]
        },

        {
            uid: "101",
            userName: "richard.roe",
            userPassword: "tm",
            firstName: "generic Fake",
            lastName: "generic Fake",
            mail: "tm@user.com",
            sites: [
                {
                    uid: "1",
                    name: "Generic Build",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "el tenant",
                            description: "Test tenant 1"
                        },
                        {
                            uid: "2",
                            name: "el tenant 2",
                            description: "Test tenant 2"
                        }
                    ]
                }

            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                },
            ]
        },

        {
            sid: "1000",
            username: "genericuser",
            password: "genericpass",
            description: "A Generic Test User to add to the current database",
            fk_rol: "superadmin"
        }
    ],
    microappSecurityTree: {
        'vms': {
            'access': {
                'USER': true,
                'ADMIN': true,
                'SUPERADMIN': true,
                'MANAGEMENT': true,
            },
            'new_visit': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'edit_visit': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'edit_visitor': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'single_scope': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': false,
            },
            'tenant_scope': {
                'USER': false,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'facility_scope': {
                'USER': false,
                'ADMIN': true,
                'SUPERADMIN': true,
                'MANAGEMENT': false,
            },
            'address_book': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'update_visitor': {
                'USER': true,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            },
            'delete_visitor': {
                'USER': false,
                'ADMIN': false,
                'SUPERADMIN': false,
                'MANAGEMENT': true,
            }
        }
    },
    generics: [
        {
            sid: "1",
            name: "generic1"
        },
        {
            sid: "2",
            name: "generic2"
        },
        {
            sid: "3",
            name: "generic3"
        },
    ],
    loggedUser: null
}

MICROAPPFAKEDATABASE.loggedUser = MICROAPPFAKEDATABASE.users[0];
