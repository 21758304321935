<div *ngIf="parkingDataLoaded" class="fondoGeneral">

    <!-- HEADER-->
    <div class="header"></div>
    <div class="table-container">
        <table mat-table [dataSource]="parking_size_data" class="mat-elevation-z8" id="tablaParkingSpaces"
            style="width: 100%;">
            <ng-container *ngFor="let vehicle_type of parkingVehicleTypes"
                matColumnDef='{{vehicle_type.vehicle_type_id}}'>
                <th mat-header-cell *matHeaderCellDef>
                    <mat-icon>{{vehicle_type.vehicle_icon}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="getTooltip(element, vehicle_type.vehicle_type_id)">
                   Available: {{findParkingVehicleAvailableSpots(element, vehicle_type.vehicle_type_id)}} / 
                   Assigned: {{findParkingVehicleSpots(element, vehicle_type.vehicle_type_id) - findParkingVehicleAvailableSpots(element, vehicle_type.vehicle_type_id)}} / 
                   Total:  {{findParkingVehicleSpots(element, vehicle_type.vehicle_type_id)}}

                </td>

            </ng-container>
            <ng-container matColumnDef='spot_type'>
                <th mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.QUOTAS.TOTAL' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.spot_short_desc}}
                </td>
            </ng-container>
            <ng-container matColumnDef='actions'>
                <th mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.QUOTAS.ACTIONS' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <button matTooltip="{{'MAPP-EPS.QUOTAS.EDITTOOLTIP' | translate}}" mat-icon-button (click)="editParkingSpace(element)">
                        <mat-icon class="icActions">edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="sizeDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: sizeDisplayedColumns;"></tr>
        </table>
        <!--
        <div class="header"> Free to assign **</div>

        <table mat-table [dataSource]="parking_size_data" class="mat-elevation-z8" id="tablaParkingSpaces"
            style="width: 100%;">
            <ng-container *ngFor="let vehicle_type of parkingVehicleTypes"
                matColumnDef='{{vehicle_type.vehicle_type_id}}'>
                <th mat-header-cell *matHeaderCellDef>
                    <mat-icon>{{vehicle_type.vehicle_icon}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{findParkingVehicleAvailableSpots(element, vehicle_type.vehicle_type_id)}}
                </td>
            </ng-container>
            <ng-container matColumnDef='spot_type'>
                <th mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.QUOTAS.TOTAL' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.spot_short_desc}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="sizeDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: sizeDisplayedColumns;"></tr>
        </table>
        -->
    </div>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>