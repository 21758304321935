import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FbsState } from './microapp-fbs-reducer';



export const selectFeature =
  createFeatureSelector<FbsState>("fbs");

export const selectFbsUser = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.fbsUser;
    else return null;
  }
);

export const selectScheduled = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.schedule;
    else return null;
  }
);

export const anyServiceLoading = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) {
      return state.loading_user ||
        state.loading_ChargeTypes ||
        state.loading_data ||
        state.loading_resources ||
        state.loading_facilitiesAvailability ||
        state.loading_my_reservations;
    }
    else return null;
  }
);

export const selectChargeTypes = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.chargeTypes;
    else return null;
  }
);

export const selectResources = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.resources;
    else return null;
  }
);

export const selectResourceGroups = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.resources_grouped;
    else return null;
  }
);

export const selectError = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.error;
    else return null;
  }
);


export const selectErrorMsg = createSelector(
  selectFeature,
  (state: FbsState) => {
    if (state) return state.errorMsg;
    else return null;
  }
);