import { Injectable } from '@angular/core';
import { Issue } from './dto/issue';

@Injectable({
    providedIn: 'root',
  })
  export class MailService {
   
    constructor() {
    }
    resolveIssue():any{
      console.log('resolveIssue');

    }

    updateIssue():any{
      console.log('updateIssue');
    }
    createIssue():any{
      console.log('createIssue');
    }
    changeAsignIssue():any{
        console.log('changeAsignIssue');
       
    }

    escalateManagers(comment:string, issues:Issue[]){
      console.log('escalateManagers' + comment + issues);
    }
}