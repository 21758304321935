import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../../lib/store/tenant/microapp-eps.tenant.actions';
import { ChargeToOption, EpsParkingRequest } from '../../../../lib/model/reservation/microapp-eps.parking-request.model';


@Component({
  selector: 'empusa-microapp-eps-availability',
  templateUrl: './microapp-eps-availability.component.html',
  styleUrls: ['./microapp-eps-availability.component.css']
})
export class MicroappEpsAvailabilityComponent implements OnInit {

  requestsForInvoiceYardi: EpsParkingRequest[] = [];
  requestsForInvoicePaypal: EpsParkingRequest[] = [];
  paypalEnabled:boolean;
  @Input() mode: 'employee' | 'visitor' = 'employee';
  constructor(private store: Store<microappEpsAppReducer.MicroappEpsStates>) { }

  ngOnInit(): void {
    this.store.pipe(select('tenant')).subscribe((data) => {
      if (data != undefined && data.parkingRequestReservationStatus != undefined && data.parkingRequestReservationStatus.reservation_request != undefined) {
        this.requestsForInvoicePaypal = data.parkingRequestReservationStatus.reservation_request.filter(reservation => reservation.charge_to == 'User');
        this.requestsForInvoiceYardi = data.parkingRequestReservationStatus.reservation_request.filter(reservation => reservation.charge_to == 'Tenant');
        this.paypalEnabled = data.paypalEnabledForThisTenant;
        // this.requestsForInvoiceYardi = Object.assign([],data.parkingRequestReservationStatus.reservation_request);
        
      } else {
        this.requestsForInvoiceYardi = this.requestsForInvoicePaypal = undefined
      }
    });

  }

  changeChargeTo(reservarion_id: number, charge_to: ChargeToOption){
    this.store.dispatch(epsTenatActions.epsChangeChangeTo({reservarion_id, charge_to}))
  }



}
