<!--show feedback-->
<h2 class="titleNew">{{'MAPP-QUIZ.SHOWFEEDBACK.TITLE' | translate}}<button class="closeReporter" mat-icon-button
        mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" (click)="close()" aria-label="Example close icon">close</mat-icon>
    </button>
</h2>

<form [formGroup]="feedbackForm">
    <mat-form-field class="formFieldFeedback">
        <mat-label>{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.DATE' | translate}}</mat-label>
        <input matInput formControlName="date" name="date" readonly />
    </mat-form-field>
    <mat-form-field class="formFieldFeedback" *ngIf="!feedback.anonymized">
        <mat-label>{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.USER' | translate}}</mat-label>
        <input matInput formControlName="user" name="user" readonly />
    </mat-form-field>
    <mat-form-field class="formFieldFeedback">
        <mat-label>{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.TITLE' | translate}}</mat-label>

        <input matInput formControlName="type" name="type" readonly />
        <!-- <mat-select formControlName="type" name="type" readonly>
            <mat-option value="0">{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.COMMENT' | translate}}</mat-option>
            <mat-option value="1">{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.QUESTION' | translate}}</mat-option>
            <mat-option value="2">{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.PROBLEM' | translate}}</mat-option>
            <mat-option value="3">{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.OTHERS' | translate}}</mat-option>
        </mat-select> -->
    </mat-form-field>
    <div *ngIf="isSuperAdmin">
    <mat-form-field  *ngIf="isSuperAdmin">
        <mat-label>{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.READBY' | translate}}</mat-label>
        <input matInput formControlName="readBy" name="readBy" readonly />
    </mat-form-field>
    </div>
    <mat-form-field class="formFieldFeedbackDescription">
        <mat-label>{{'MAPP-QUIZ.SHOWFEEDBACK.FIELD.DESCRIPTION' | translate}}</mat-label>
        <textarea style="resize: none;" matInput formControlName="feedback" name="feedback" readonly></textarea>
    </mat-form-field>
</form>

<!-- <div mat-dialog-actions class="buttonDetailFault" align="end"> -->
    <!-- <button *ngIf="feedback.state == 1" class="buttonConfirm" mat-raised-button color="primary" (click)="onSubmit()">
        {{'MAPP-QUIZ.SHOWFEEDBACK.ACTION.READ' | translate}}
    </button> -->
    <!-- <button class="buttonClose" mat-raised-button mat-dialog-close (click)="close()">
        {{'MAPP-QUIZ.SHOWFEEDBACK.ACTION.CLOSE' | translate}}
    </button> -->
<!-- </div> -->