<h2 class="cabecera">
  <mat-icon class="iconoCabecera">ballot</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-QUIZ.DESCRIPTION' | translate}}</mat-label>
</h2>
<div class="fondo">
  <div class="headerFaultNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-QUIZ.MENU.SURVEY' | translate}}">
        <microapp-quiz-survey-list></microapp-quiz-survey-list>
      </mat-tab>
      <mat-tab label="{{'MAPP-QUIZ.MENU.FEEDBACK' | translate}}">
        <microapp-quiz-feedback-list></microapp-quiz-feedback-list>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!can_access" style="width: 100%; justify-content: center;
      display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>