import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import * as groupVisitActions from '../../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions'
import { Store, select } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsVisitGroup } from '../../../../lib/model/microapp-vms-visit-group.model';
import { MicroappVmsVisitWithVisitors } from '../../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit.model';
import { MicroappVmsVisitVisitor } from '../../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit-visitor.model';
import { newVisitLoading, showNewParticipant, scheduleValid, thereAreParticipants, updateDone, groupVisitsMessageCode } from '../../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage-selectors';
import { map, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VmsConfig } from '../../../../lib/microapp-vms-config';


@Component({
  selector: 'empusa-microapp-vms-update-visit',
  templateUrl: './microapp-vms-update-visit.component.html',
  styleUrls: ['./microapp-vms-update-visit.component.css']
})
export class MicroappVmsUpdateVisitComponent implements OnInit, OnDestroy {

  groupVisitForUpdate: MicroappVmsVisitGroup;
  loading$: Observable<boolean>;
  displayNewVisit$: Observable<boolean>;
  scheduleValid$: Observable<boolean>;
  thereAreParticipants$: Observable<boolean>;
  newVisitLoading$: Subscription;


  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private vmsConfig: VmsConfig,
    @Inject(MAT_DIALOG_DATA) data) {
    this.groupVisitForUpdate = data;
  }

  ngOnInit(): void {
    let visit = new MicroappVmsVisitWithVisitors();
    visit.visitors = [];

    this.groupVisitForUpdate.visits.forEach(element => {
      let visitor: MicroappVmsVisitVisitor = new MicroappVmsVisitVisitor()
      visitor.id = element.visitor_id;
      visit.group_id = element.group_id;
      visit.site_id = element.site_id;
      visit.host_id = element.host_id;
      visitor.about = element.about;
      visitor.first_name = element.first_name;
      visitor.last_name = element.last_name;
      visitor.email = element.email;
      visitor.company = element.company;
      visitor.iu = element.iu;
      visitor.complimentary_parking = element.complimentary_parking;
      visit.start_time = element.start_time;
      visit.end_time = element.end_time;
      visit.date = new Date(element.start_time);
      visit.date.setUTCMinutes(visit.date.getUTCMinutes() - this.vmsConfig.MASTER_CONFIG.offset_to_utc);
      visit.visitors.push(visitor);      
    });

    this.loading$ = this.store.pipe(select(newVisitLoading)).pipe(
      map(loading => {
        return loading;
      }));

    this.displayNewVisit$ = this.store.pipe(select(showNewParticipant)).pipe(
      map(displayNewVisit => {
        return displayNewVisit;
      }));

    this.scheduleValid$ = this.store.pipe(select(scheduleValid)).pipe(
      map(scheduleValid => {
        return scheduleValid;
      }));

    this.thereAreParticipants$ = this.store.pipe(select(thereAreParticipants)).pipe(
      map(thereAreParticipants => {
        return thereAreParticipants;
      }));


    visit.tenant_id = this.groupVisitForUpdate.tenant_id;
    this.store.dispatch(groupVisitActions.updateAVisit({ visit }));
  }

  onConfirm() {
    this.store.dispatch(groupVisitActions.performVisitUpdate({off_set_to_utc:this.vmsConfig.MASTER_CONFIG.offset_to_utc}));

    this.newVisitLoading$ = this.store.pipe(select(newVisitLoading)).subscribe(isLoading => {
      if (!isLoading) {
        this.store.pipe(select(updateDone)).pipe(take(1)).subscribe(uDone => {
          if (uDone) {
            this.store.select(groupVisitsMessageCode).pipe(take(1)).subscribe(msg => {
              console.log("msg", msg);
              this.openSnackBar(msg);
              this.store.dispatch(groupVisitActions.resetMessageCode());
              this.dialogRef.close(uDone);
            });
          }
        });
      }
    });

  }

  openSnackBar(msg) {
    const txt = this.translate.instant(msg);
    this._snackBar.open(txt, "X", {
      duration: 2000,
    });
  }

  ngOnDestroy(): void {
    if (this.newVisitLoading$)
      this.newVisitLoading$.unsubscribe();
  }

}
