<div style="margin-top: 50px;"></div>
<div #sliderElement class="rs-container" style="width: 100%;" >
  <div #scaleContainer class="rs-scale" [style.width.px]="sliderElement.clientWidth">
    <ng-container *ngFor="let item of values; let index = index">
      <span [style.width.px]="step"
            [style.backgroundColor]="item.color">
        <ins [style.marginLeft.px]="scaleContainer.children!.item(index)!.firstElementChild!.clientWidth / 2 * -1">{{item.label}}</ins>
      </span>
<!--      <span *ngIf="index == values.length -1"-->
<!--            [style.width.px]="sliderElement.clientWidth / (values.length - 1)"-->
<!--            [style.backgroundColor]="item.color">-->
<!--        <ins [style.marginLeft.px]="scaleContainer.children!.item(index)!.firstElementChild!.clientWidth / 2 * -1">{{item.label}}</ins>-->
<!--      </span>-->
    </ng-container>
  </div>
  <div class="rs-bg"></div>
  <div #selectedRangeElement class="rs-selected" style="{{cssSliderStyles}}"></div>
  <div #pointerLElement class="rs-pointer" (mousedown)="drag(pointerLElement)" (touchstart)="drag(pointerLElement)">
    <div class="rs-tooltip" *ngIf="showTooltips"> {{tooltipL}} </div>
  </div>

  <div #pointerRElement class="rs-pointer" (mousedown)="drag(pointerRElement)" (touchstart)="drag(pointerRElement)">
    <div class="rs-tooltip" *ngIf="showTooltips"> {{tooltipR}} </div>
  </div>
</div>
