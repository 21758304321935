import { EpsSpotType } from './../model/microapp-eps.spot-type.model';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "@empusa/empusa-core";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { EpsConfig } from "../microapp-eps-cfg";
import { EpsAssignedChargeTypeAdapter } from "../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model.adapter";
import { EpsChargeType } from "../model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsTenantTariff } from "../model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { EpsParkingSize } from "../model/microapp-eps.parking-size.model";
import { EpsTenantQuotas } from "../model/microapp-eps.tenan-quote.model";
import { EpsVehicleType } from "../model/microapp-eps.vehicle-type.model";
import { EpsAssignedChargeType } from '../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model';
import { EpsAssignedChargeTypeDBAdapter } from '../model/charges_and_tariffs/microapp-eps.assigned-charge-types-db.model.adapter';
import { EpsReservationAdapter } from "../model/reservation/microapp-eps.reservation.adapter";
import { Reservation } from "../model/reservation/microapp-eps.reservation.model";
import { EpsReservationType } from '../model/reservation/microapp-eps.reservation-type.model';
import { EpsTenantParkingRequest } from '../model/reservation/microapp-eps.tenant-parking-request.model';
import { EpsMasterConfig } from '../model/cfg/microapp-eps.cfg.model';
import { EpsQuote } from '../model/microapp-eps.quote.model';
import { EpsSpotsToAssign } from '../model/microapp-eps.spotsToAssign.model';
import { EpsNewParkingSize } from '../model/microapp-eps.new-parking-size.model';

@Injectable({
    providedIn: 'root',
})
export class EpsServices {

    constructor(private http: HttpClient,
        private auth: AuthenticationService,
        private epsAssignedChargeTypeAdapter: EpsAssignedChargeTypeAdapter,
        private epsAssignedChargeTypeAdapterDb: EpsAssignedChargeTypeDBAdapter,
        private epsReservationAdapter: EpsReservationAdapter,
        private epsConfig: EpsConfig,
        private translate: TranslateService) {
    }

    get_config() {
        const url = this.epsConfig.EPS_CONTEXT + "config";
        return this.http.get(url).pipe(
            map(data => data as EpsMasterConfig)
        )
    }

    parkingSize() {
        const url = this.epsConfig.EPS_CONTEXT + "parking_size";
        return this.http.get(url).pipe(
            map(data => data as EpsParkingSize[])
        )
    }

    setParkingSize(newParkingSize: EpsNewParkingSize[]) {
        const url = this.epsConfig.EPS_CONTEXT + "parking_size";
        return this.http.put(url, newParkingSize).pipe(
            map(data => data as EpsParkingSize[])
        )
    }

    quotas() {
        const url = this.epsConfig.EPS_CONTEXT + "tenant_quotas";
        return this.http.get(url).pipe(
            map(data => data as EpsTenantQuotas[])
        )
    }

    tenant_quotas(tenant: string) {
        let id = encodeURIComponent(tenant);
        const url = this.epsConfig.EPS_CONTEXT + "tenant_quotas/" + id;
        return this.http.get(url).pipe(
            map(data => data as EpsTenantQuotas)
        )
    }

    quotas_that_can_be_assigned_to(tenant: string) {
        let id = encodeURIComponent(tenant);
        const url = this.epsConfig.EPS_CONTEXT + "tenant_quotas/to_assign/" + id;
        return this.http.get(url).pipe(
            map(data => data as EpsParkingSize[])
        )
    }


    setQuotas(data: EpsTenantQuotas) {
        const url = this.epsConfig.EPS_CONTEXT + "tenant_quotas";
        return this.http.put(url, data).pipe(
            map(data => data as EpsTenantQuotas[])
        )
    }

    deleteQuotas(tenant_id: string) {
        let id = encodeURIComponent(tenant_id);
        const url = this.epsConfig.EPS_CONTEXT + "tenant_quotas/" + id;
        return this.http.delete(url).pipe(
            map(data => data as EpsTenantQuotas[])
        )
    }

    reservationTypes() {
        const url = this.epsConfig.EPS_CONTEXT + "reservation_types";
        return this.http.get(url).pipe(
            map(data => data as EpsReservationType[])
        )
    }

    spotTypes() {
        const url = this.epsConfig.EPS_CONTEXT + "spot_types";
        return this.http.get(url).pipe(
            map(data => data as EpsSpotType[])
        )
    }

    vehicleTypes() {
        const url = this.epsConfig.EPS_CONTEXT + "vehicle_types";
        return this.http.get(url).pipe(
            map(data => data as EpsVehicleType[])
        )
    }

    get_charges_and_tariffs() {
        const url = this.epsConfig.EPS_CONTEXT + "charges_and_tariffs";
        return this.http.get(url).pipe(
            map(data => data as EpsChargeType[])
        )
    }

    get_assigned_charges() {
        const url = this.epsConfig.EPS_CONTEXT + "assigned_charges";
        return this.http.get<any[]>(url).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsAssignedChargeTypeAdapter.adapt(one));
                return adapted;
            })
        )
    }

    post_assigned_charges(data: EpsAssignedChargeType) {
        const body = this.epsAssignedChargeTypeAdapterDb.adapt(data);
        const url = this.epsConfig.EPS_CONTEXT + "assigned_charges";
        return this.http.post<any[]>(url, body).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsAssignedChargeTypeAdapter.adapt(one));
                return adapted;
            })
        );
    }

    put_assigned_charges(data: EpsAssignedChargeType) {
        const body = this.epsAssignedChargeTypeAdapterDb.adapt(data);
        const url = this.epsConfig.EPS_CONTEXT + "assigned_charges";
        return this.http.put<any[]>(url, body).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsAssignedChargeTypeAdapter.adapt(one));
                return adapted;
            })
        );
    }


    delete_assigned_charges(id: number) {
        const url = this.epsConfig.EPS_CONTEXT + "assigned_charges/" + id;
        return this.http.delete<any[]>(url).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsAssignedChargeTypeAdapter.adapt(one));
                return adapted;
            })
        );
    }

    get_tenant_tariffs() {
        const url = this.epsConfig.EPS_CONTEXT + "tenant/tariffs";
        return this.http.get<EpsTenantTariff[]>(url).pipe(
            map(data => {
                return data as EpsTenantTariff[];
            })
        )
    }


    get_tenant_reservations(tenant_id: string, from_date: Date, to_date: Date) {
        if(tenant_id == 'ALL TENANTS'){
            tenant_id= '';
        }
        let id = encodeURIComponent(tenant_id);
        const url = this.epsConfig.EPS_CONTEXT + "reservation/" + id;
        let params = new HttpParams();
        if (from_date) {
            params = params.append('from_date', from_date.toISOString().split("T")[0]);
        };
        if (to_date) {
            params = params.append('to_date', to_date.toISOString().split("T")[0]);
        }
        return this.http.get<Reservation[]>(url, { params }).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsReservationAdapter.adapt(one));
                return adapted;
            })
        )
    }

    cancel_reservation(reservation_id: number, from_date: Date, to_date: Date) {
        const url = this.epsConfig.EPS_CONTEXT + "reservation/cancel/" + reservation_id;
        let params = new HttpParams();
        if (from_date) {
            params = params.append('from_date', from_date.toISOString().split("T")[0]);
        };
        if (to_date) {
            params = params.append('to_date', to_date.toISOString().split("T")[0]);
        }
        return this.http.delete<Reservation[]>(url, { params }).pipe(
            map(data => {
                const adapted = data.map((one) => this.epsReservationAdapter.adapt(one));
                return adapted;
            })
        )
    }

    do_reservation(parkingRequests: EpsTenantParkingRequest) {
        const url = this.epsConfig.EPS_CONTEXT + "reservation/";
        return this.http.post<EpsTenantParkingRequest>(url, parkingRequests).pipe(
            map(data => {
                return data as EpsTenantParkingRequest;
            })
        )
    }

    delete_reservation(parkingRequests: EpsTenantParkingRequest) {
        let params = new HttpParams();
        let ids = '';
        parkingRequests.reservation_request.forEach(r => {
            if (r.reservation_id)
                ids = ids + r.reservation_id + "#"
        });
        params = params.append('ids', ids)
        const url = this.epsConfig.EPS_CONTEXT + "reservation/";
        return this.http.delete<any>(url, { params }).pipe(
            map(data => {
                return data;
            })
        )
    }

    confirm_and_pay_reservations(parkingRequests: EpsTenantParkingRequest) {
        const url = this.epsConfig.EPS_CONTEXT + "reservation/tenant/confirm";
        return this.http.post<EpsTenantParkingRequest>(url, parkingRequests).pipe(
            map(data => {
                return data as EpsTenantParkingRequest;
            })
        )
    }

    confirm_and_pay_reservations_by_id(uid: number) {
        const url = this.epsConfig.EPS_CONTEXT + "reservation/tenant/confirm/" + uid;
        return this.http.post(url, null).pipe(
            map(data => {
                return data as EpsTenantParkingRequest;
            })
        )
    }


    assignTenant(tenant, chargeId, tariff) {
        let id = encodeURIComponent(tenant);
        const url = this.epsConfig.BILLING_CONTEXT + "charges/tenant/" + id + "/tarifs"
        var the_data = {
            tags: ["EPS"],
            charges: [
                {
                    chargeid: chargeId,
                    tarifid: tariff,
                },
            ]
        };
        return this.http.put<any>(url, the_data).
            //pipe(map((data) => data));
            pipe(map(data => data));
    }

    cancelRenewd(reservation_id: number) {
        const url = this.epsConfig.EPS_CONTEXT + "reservation/autorenew/" + reservation_id;
        return this.http.delete(url).pipe(
            map(data => data)
        )
    }

    paypalTenantConfig(){
        const url = this.epsConfig.EPS_CONTEXT + "paypal/tenants_config";
        return this.http.get(url).pipe(map(data => data));
    }

    updatePaypalTenantConfig(tenantId:string, paypal_enabled:boolean ){
        const body = {paypal_enabled:paypal_enabled}
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
          });
        let id = encodeURIComponent(tenantId);
        const url = this.epsConfig.EPS_CONTEXT + "paypal/tenants_config/"+id;
        return this.http.put(url, body, { headers }).pipe(map(data => data));
    }
}


