<div class="header-container">
  <div class="header-content">
    <mat-card>
      <div class="form-container">
        <mat-form-field>
          <mat-label>{{'MAPP-TARIFF.CALENDAR.SELECT' | translate}}</mat-label>
          <mat-select [(value)]="selectedEventType" (valueChange)="setFilterEvents()">
            <mat-option [value]="null"> {{'MAPP-TARIFF.CALENDAR.TYPES.ALL' | translate}} </mat-option>
            <ng-container *ngFor="let option of eventTypes">
              <mat-option [value]="option"> {{'MAPP-TARIFF.CALENDAR.TYPES.' + option.name | translate}} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
  </div>
  <div class="header-content">
    <mat-card>
      <h3> {{'MAPP-TARIFF.CALENDAR.CAPTION' | translate}} </h3>
      <ng-container *ngFor="let type of eventTypes">
        <p [ngStyle]="{'border-bottom': '4px solid ' + type.colour}"> {{'MAPP-TARIFF.CALENDAR.TYPES.' + type.name | translate}}</p>
      </ng-container>
    </mat-card>
  </div>
</div>

<mat-card>
  <div class="year-calendar">
    <div #calendarDiv>
    </div>
  </div>
</mat-card>


<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

