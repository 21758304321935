import { Tenant } from '@empusa/empusa-core';

export class MicroappVmsVisitorFilter{
    id: number;
    tenants: Tenant[] = [];
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    phone: string;
    license_plate: string;
    about: string;
    created: Date;
}