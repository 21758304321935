export const locale = {
    "lang": "en",
    "data": {
        "MAPP-EPS": { //mandatory - microapp context
            "DESCRIPTION": "eParking System",
            "TABS": {
                "EMPLOYEE": "Employee",
                "VISITOR": "Visitor",
            },
            "GENERIC": {
                "PASS": {
                    "DAY": "Daily pass",
                    "WEEK": "Week season pass",
                    "MONTH": "Month season pass"
                },
                "CALENDAR": {
                    "DAY": "Select day",
                    "WEEK": "Select week",
                    "MONTH": "Select month",
                },
                "GST": {
                    "PAYPAL":"GST (%) for Paypal Transactions",
                    "MESSAGE_OK":"The GST(%) has been changed",
                    "TITLE_OK":"OK",
                    "MESSAGE_KO":"Operation Failed!",
                    "TITLE_KO":"ERROR"
                },
                "SUPERERROR": "Sorry... Some data could not be loaded. Try again later.",
                "ACTIONS": "Actions",
                "NAME": "Name",
                "EDIT": "Edit",
                "DELETE": "Delete",
                "NEW": "Add new",
                "CANCEL": "Cancel",
                "CREATE": "Create",
                "UPDATE": "Update",
                "CHARGETO.TENANT": "Charge to tenant with Yardi",
                "CHARGETO.EMPLOYEE": "Charge to employee with Paypal",
                "UNKNOWN_ERROR": "There was an error performing the operation. Please try again later",
                "UNKNOWN_ERROR_TITLE": "Unknown error",
                "CLEAN": "Clean Filter",
                "FILTER": "Filter",
                "FORM_LABELS": {
                    "CHOSE_DATE": "Chose date",
                    "CHOSE_MONTH": "Chose month",
                    "AUTORENEW": "Autorenew"
                }
            },
            "CHARGE_TYPES_ASSOCIATION": {
                "VEHICLE_TYPE": "Vehicle type",
                "SPOT_TYPE": "Lot type",
                "RESERVATION_TYPE": "Reservation type",
                "CHARGE_TYPE": "Charge type",
                "NEW": "Add new association",
                "MODAL_TITLE": "New Association",
                "EDIT_CHARGE": "Edit Charge Type in the Association"
            },
            "FACILITY": {
                "TABS": {
                    "QUOTES": "Quotas",
                    "PASSES": "Tariffs Assignment",
                    "PRICES": "Prices",
                    "CHARGE_TYPES": "Charge Types",
                    "TARIFFS": "Tariffs Overview",
                    "RESERVATION": "Reservations",
                    "CHARGESTARIFF": "Charges & Tariffs",
                    "PARKING": "Parking Spaces",
                    "QUOTAS": "Quotas Assignment",
                    "PAYPAL": "Paypal Options"
                },
                "PASS": {
                    "SHOW_COMPLIMENTARY_TARIFS": "Show Complimentary Tariffs",
                    "SHOW_SEASON_TARIFS": "Show Season Tariffs",
                    "DAILY-USER-CAR": "Daily User",
                    "DAILY-USER-MOTO": "Daily User",
                    "DAILY-TENANT-CAR": "Daily Tenant",
                    "DAILY-TENANT-MOTO": "Daily Tenant",
                    "MONTHLY-USER-CAR": "Monthly User",
                    "MONTHLY-USER-MOTO": "Monthly User",
                    "MONTHLY-TENANT-CAR": "Monthly Tenant",
                    "MONTHLY-TENANT-MOTO": "Monthly Tenant",
                    "COMPLIMENTARY-CAR": "Complimentary",
                    "COMPLIMENTARY-MOTO": "Complimentary",
                },
                "PARKING": {
                    "TOTAL": "TOTAL PARKING SPACES",
                    "TENANT": "TENANTS PARKING SPACES",
                    "TENANT_FREE": "FREE PARKING SPACES",
                },
                "SEARCH": "Search ...",
                "TENANTS": "TENANTS",
                "ACTIONS": "Actions",
                "PARKING_SPACES": "PARKING SPACES",
                "CONCESSIONS": "CONCESSIONS",
                "NAME": "Name",
                "TAGS": "Tags",
                "NUMBERUNIT": "Number of Units",
                "UNITPRICE": "Unit Price",
                "TOTALPRICE": "Total Price",
                "CANCEL": "CANCEL",
                "CREATE": "CREATE",
            },
            "TENANT": {
                "STEPPER_NEXT": "Next",
                "STEPPER_BACK": "Back",
                "NEW_RESERVATION": "Make another reservation",
                "SEARCH": "Search ...",
                "PASS_SELECT": "Select Parking Pass",
                "AVAILABILITY": "Availability",
                "DISABLED-VISITOR":"The tenant has no rights to make parking reservations for Visitors (not non-entitled lots enabled). For further information contact the Facility Manager.",
                "PASS_TYPE": "Pass type",
                "CHECK_PARKING": "Check Parking Availability",
                "MANDATORY_PLATEIU": "Please Fill Plate and IU",
                "MANDATORY_VEHICLETYPE": "Please Select the Vehicle Type",
                "MANDATORY_TITLE": "Check Reservations",
                "IU_LENGTH": "IU Number must be 9 digits for motorbike, 10 digits for car",
                "IU_LENGTH_TITLE": "Invalid IU format",
                "PLATE_FORMAT_ERROR": "Invalid Plate format",
                "INVOICE": {
                    "TOTAL": "TOTAL*:",
                    "COST": "Cost",
                    "TARIFF_M": "Monthly",
                    "TARIFF_D": "Daily",
                    "TARIFF_W": "Daily weekend",
                    "TARIFF_F": "Daily public holiday",
                },
                "EMPLOYEE": {
                    "TITLE": "Employee",
                    "SELECT_USERS": "Select users",
                    "CHECK_RESERVATIONS": "Reservations",
                    "TABLE": {
                        "SELECT_ALL": "Select All",
                        "NAME": "Name",
                        "EMAIL": "Email",
                        "PLATE": "Vehicle No."
                    },
                    "RESERVATIONS_LIST": {
                        "TITLE": "Reservations List",
                        "SUBTITLE": "Parking Reservations for",
                        "TABLE": {
                            "DAY_RANGE": "Day/Range",
                            "MONTH": "Month",
                            "PASS": "Pass",
                            "ACTION": "Action"
                        }
                    },
                },
                "VISITOR": {
                    "TITLE": "Visitor",
                    "ADD_VISITOR_STEP": "Add Visitors",
                    "SURNAME": "Surname",
                    "GIVENNAME": "Given Name",
                    "EMAIL": "Email",
                    "IU": "In-Vehicle Unit No.",
                    "PLATE": "Vehicle No.",
                    "IU_NUMBER": "In-Vehicle Unit No.",
                    "REQUIRED": "Field is required",
                    "INVALID_EMAIL": "Please enter a valid email address",
                    "ADD_VISITOR": "Add Visitor",
                    "TABLE": {
                        "NAME": "Name",
                        "EMAIL": "Email",
                        "PLATE": "Vehicle No.",
                        "TYPE": "Type",
                        "IU": "In-Vehicle Unit No."
                    },
                    "FORM_VALIDATION": "Please correct before proceeding.",
                    "FORM_VALIDATION_TITLE": "Errors Found",
                    "PATTERN": {
                        "IU": "IU Number must be 9 digits for motorbike, 10 digits for car",
                        "PLATE": "Vehicle No. only supports numbers and letters"
                    }
                },
                "PAYMENT": {
                    "PAY": "Charge",
                    "STATUS_Reservation_expired": "The reservation is Expired!",
                    "STATUS_Reservation_not_found": "The reservation was NOT FOUND!",
                    "DONE_WARNING": "WARNING! Some payments contained inconsistencies. \nPlease review and contact your administrator.",
                    "DONE_SUCCESS": "All payments were processed correctly!",
                    "DONE_RELOAD": "Make another Reservation",
                    "GST": "*GST not included in this cost. GST will be applied additionally at the invoicing of this service."
                },
                "RESERVATION": {
                    "CONFIRM_TITLE": "Confirm Reservation",
                    "CONFIRM_MESSAGE": "All checks were successful, the reservation is ready to be performed.\nWould you like to confirm the pre-reservation?",
                    "No_quota": "There is no available quota for this reservation",
                    "No_spaces": "There are no available spaces for this reservation",
                    "Exist_reservation": "This vehicle has already a reservation. The system doesn't allow multiple reservations for the same vehicle",
                    "Fail": "EPS system not available at the moment",
                    "RESERVATION_FAIL": "Please recheck the availability!",
                    "RESERVATION_FAIL_TITLE": "Error during reservation"
                },
                "RESERVATIONTENANT": {
                    "CONFIRMATION_DATE": "Confirmation date:",
                    "RENEW_ERROR": "The renewal failed with the error:",
                    "WIL_BE_TRY": "Will be tried again later.",
                    "IU": "In-Vehicle Unit No.",
                    "VEHICLE_TYPE": "Vehicle Type",
                    "EXPIRED": "Expired Date",
                    "EFFECTIVE": "Effective Date",
                    "VEHICLEOWNER": "Vehicle Owner",
                    "STATUS": "Status",
                    "VEHICLENO": "Vehicle No",
                    "RESERVATIONTYPE": "Reservation Type",
                    "TARIFF": "Tariff",
                    "TENANT": "Tenant Name",
                    "ACTIONS": "Actions",
                    "CANCEL": "Cancel Reservation",
                    "ERROR": "Operation Failed!",
                    "CONFIRM": "Confirm Yardi Reservation",
                    "PRICEIS": ", the price is for",
                    "DAY": "day",
                    "MONTH": "month",
                    "COMPLIMENTARY": "Complimentary",
                    "EMPLOYEE": "Employee reservation",
                    "VISITOR": "Visitor reservation",
                    "EPS": "Reservation from eParking System",
                    "VMS": "Reservation from Visitor Management",
                    "RECURRENT": "This reservation is renewed automatically",
                    "RENEW_DONE": "This reservation has already been renewed",
                    "TARIFF_APPLIED": " tariff applied.",
                    "MESSAGE": "An error ocurred while trying to load data",
                    "DELETE": "Reservation",
                    "MESSAGEDELETE": "The reservation has been cancelled",
                    "ERRORDELETE": "ERROR",
                    "MESSAGEERRORDELTE": "An error ocurred while trying to delete data",
                    "MESSAGECONFIRMED": "The reservation has been confirmed",
                    "TITLECONFIRMED": "Confirmed",
                    "ERRORCONFIRMED": "ERROR",
                    "ERRORCMESSAGEONFIRMED": "An error ocurred while trying to confirm the pre-reserve",
                    "TITTLECONFIRM": "Confirm pre-reserve",
                    "MESSAGECONFIRM": "Are you sure that you want confirm the pre-reservation? A charge will be made to the tenant via Yardi",
                    "MESSAGECANCELRENEW": "The auto renew reservation has been cancelled",
                    "TITLECANCELRENEW": "RENEW",
                    "ERRORCANCELRENEW": "ERROR",
                    "ERRORCMESSAGECANCELRENEW": "An error ocurred while trying to cancel the auto renew reservation",
                    "EMPLOYEERESERVATION.TABLE":"Employee Reservations",
                    "HELP":"Status Help",
                    "DOWNLOAD": "Download CSV",
                    "RELOAD":"Reload Data",
                },
                "RESERVATION_STATUS_CODE": {
                    "OK": "Pre-reserved",
                    "NO_SPOTS_AVAILABLE": "Not available lots",
                    "NO_AUTO_RENEW_SPOTS_AVAILABLE": "Not available lots for auto-renew reservation.",
                    "NO_MULTIPLE_RESERVATIONS": "This IU has already a reservations and EPS does not allow multiple reservations",
                    "SPOT_RESERVED": "Reservation done",
                    "BAD_TARIFFICATION": "Tariffication code error.",
                    "DAILY_MIX": "This daily reservation can not include weekend and working days because has different tariffs",
                    "NO_TARIFF": "No tariff assigned",
                    "NO_CHARGES": "No charge type found",
                    "CONNECTION_ERROR": "Can't connect with remote EPS",
                    "EPS_RESERVATION_ERROR": "The season reservation has failed on the remote EPS",
                    "BAD_DATES": "Reservation dates are not correct",
                    "IU_DUPLICATED": "IU duplicated",
                    "LICENSE_PLATE_DUPLICATED": "License plate duplicated",

                },
                "RESERVATION_STATUS": {
                    "YARDI_PAYMENT_PENDING": "Processed",
                    "PAYMENT_DONE": "Yardi payment generated",
                    "PRE-RESERVED": "Pre-reserved",
                    "EXPIRED": "Cancelled",
                    "CANCELED": "Cancelled",
                    "PAYPAL_PAYMENT_PENDING":"Paypal payment pending",
                    "PAYPAL_PAID":"Paypal payment done",
                    "CONSUMED":"Consumed",
                    "PAYPAL_PAID_YARDI_SYNC": "PayPal Payment done - Billing notified"              
                },

                "PAYMENT_STEP": "Payment",
            },
            "QUOTAS": {
                "DELETEASSIGNED": "Delete asigned quotas for ",
                "DELETETITLE": "Delete Quotas",
                "UPDATEQUOTA": "Update",
                "REQUIRED": "Required",
                "TOTAL": "Total",
                "TNAME": "Tenant Name",
                "ACTIONS": "Actions",
                "SPOTTYPES": "Lots Types",
                "RESERVATIONTYPES": "Reservation Types",
                "UDPATE_QUOTA_ERROR": "The selecction oversize the parking, please, assign less quotas.",
                "LOAD_ERROR": "Could not load available quotas. Please, try again later",
                "EDITTOOLTIP": "Edit Quotas",
                "EDITTOOLTIPPARKING": "Edit",
                "DELETETOOLTIPPARKING": "Delete",
                "FILTER": "Tenant Filter",
                "ENABLED.NONENTITLED": "Enabled Not-entitled Lots",
                "ENABLED.NONENTITLED.COLUMN":"Enabled Non-entitled",
                "TOTALOTS": "Total Lots",
                "NONENTITLED.POOL":"Pool of non-entitled lots to assign:",
            },
            "TARIFF": {
                "CHARGE": "Charge",
                "TARIFF": "Tariff",
                "ACTIONS": "Actions",
                "ASSIGNTARIFF": "Tariff Assign",
                "CLOSE": "Close",
                "ASSIGN": "Assign",
                "OKMESSAGE": "OK",
                "OKTITLE": "The tariff has been assigned",
                "ERRORMESSAGE": "Operation Failed!",
                "ERRORTITLE": "An error ocurred while trying to assign tariff",
                "CHANGE":"Change Assign"
            },
            "PAYPAL":{
                "TENANT": "Tenant Name",
                "ENABLED": "Paypal Enabled"
            },
            "GENERICDIALOG": {
                "QUESTION": "Are you sure to carry out the operation?",
                "NOTICE": "By clicking 'Ok', I declare that all particulars provided in this application are true and acknowledge that charges are applicable once the reservation has been successfully made.",
                "OK": "CONTINUE",
                "CANCEL": "CANCEL",
                "CONFIRM": "CONFIRM"
            },
            "DIALOG_CANCEL_RESERVATION": {
                "TITLE": "Cancel reservation",
                "QUESTION": "Are you sure that you want cancel the reservation?"
            },
            "DIALOG_ASSIGN_QUOTAS": {
                "TITLE": "Assign Quotas",
                "SUFIX": "Max: ",
                "MAX_VALUE": "Quota overassigned",

            },
            "DIALOG_CANCEL_RENEW": {
                "TITLE": "Cancel Auto Renew Reservation",
                "QUESTION": "Are you sure that you want top cancel the auto renew reservation?"
            },
            "DIALOG_NEW_PARKING_SIZE": {
                "TITLE": "Edit parking size",
                "TYPE": "Lot type:",
                "PRODUCT": "+",
                // "PRODUCT": "X",
                "EQUIAL": " = ",
                "NOT_LESS_THAN": "Min. value:",
                "UPDATE": "Update",
                "CANCEL": "Cancel",
                "TOOLTIP_AVAILABLE": "There are {1} total lots. {3} avalaible lots can be assigned to tenants.",
                "TOOLTIP_NOT_AVAILABLE": "There are {1} available lots. All lots are assigned.",
                "TOOLTIP_AVAILABLE_OVERSELL": "There are {1} total lots of which {2} are oversell. There are still {3} avalaible lots to be assigned to tenants",
                "TOOLTIP_NOT_AVAILABLE_OVER_SELL": "There are {1} total lots of which {2} are oversell. All lots are assgined.",
                // "TOOLTIP_AVAILABLE_OVERSELL": "{1} available lots because {2}% of oversell. {3} lots can be assigned",
                // "TOOLTIP_NOT_AVAILABLE_OVER_SELL": "{1} available lots because {2}% of oversell. All lots are assgined.",
                "UNDER_ASSIGN": "Low value.",
                "LOW_ERROR": "Cannot reduce the availabe lots under the already assigned lots.",
                "OVERSELL":"Oversell"
            },
            "VALIDATIONS": {
                "MANDATORY": "Mandatory",
                "BAD_VALUE": "Incorrect value",
            }
        }
    }
}
