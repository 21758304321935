<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

<mat-toolbar class="toolbarDetail" color="primary">
    <mat-toolbar-row>
        <span class=tittleToolbar>{{'MAPP-VMS-EDIT-SINGLE-VISIT.TITLE' | translate}}</span>
        <span class="spacer"></span>
        <button class="closeEditVist" mat-icon-button (click)="onClose()">
            <mat-icon class="closeEditVisitIcon" aria-hidden="false" aria-label="Example heart icon">close
            </mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-dialog-content class="contentEditVisitSingle">
        <div>
            <div>
                <mat-form-field class="fieldEditVisitor">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.FIRST-NAME' | translate}}
                        {{dynamicRequiredMark('first_name')}}</mat-label>
                    <input matInput [formControl]="first_name" [maxLength]="vmsConfig.SIZE_FIRST_NAME">
                    <mat-error *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)">
                        {{'MAPP-VMS-EDIT-SINGLE-VISIT.FIRST-NAME-ERROR' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="fieldEditVisitor">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.LAST-NAME' | translate}}
                        {{dynamicRequiredMark('last_name')}}</mat-label>
                    <input matInput [formControl]="last_name" [maxLength]="vmsConfig.SIZE_LAST_NAME">
                    <mat-error *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)">
                        {{'MAPP-VMS-EDIT-SINGLE-VISIT.LAST-NAME-ERROR' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="fieldEditVisitor">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.COMPANY' | translate}} {{dynamicRequiredMark('company')}}
                    </mat-label>
                    <input matInput [formControl]="company" [maxLength]="vmsConfig.SIZE_COMPANY">
                </mat-form-field>
                <mat-form-field class="fieldEditVisitor">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.PHONE' | translate}} {{dynamicRequiredMark('phone')}}
                    </mat-label>
                    <input matInput [formControl]="phone" [maxLength]="vmsConfig.SIZE_PHONE">
                    <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                        {{'MAPP-VMS-EDIT-SINGLE-VISIT.PHONE-ERROR' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <p></p>
            <div>
                <mat-form-field class="fieldEditVisitor" style="float:left">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.EMAIL' | translate}}</mat-label>
                    <input matInput [formControl]="email" (keypress)="onEmailKey()" (blur)="onEmailBlur()"
                        [maxLength]="vmsConfig.SIZE_EMAIL">
                    <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                        {{getEmailErrorMessage() | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field style="float:left" class="fieldEditVisitor" *ngIf="vmsConfig.REQUEST_LICENSE_PLATE">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.LICENSE_PLATE' | translate}}
                        {{dynamicRequiredMark('license_plate')}}</mat-label>
                    <input matInput [formControl]="license_plate" [minLength]="vmsConfig.SIZE_LICENSE_PLATE_MIN"
                        [maxLength]="vmsConfig.SIZE_LICENSE_PLATE_MAX">
                    <mat-error *ngIf="license_plate.hasError('minlength')">
                        {{ 'MAPP-VMS-EDIT-SINGLE-VISIT.LICENSE_PLATE_MIN_LENGTH' | translate}}
                    </mat-error>
                    <mat-error *ngIf="license_plate.hasError('pattern')">
                        {{ 'MAPP-VMS-EDIT-SINGLE-VISIT.LICENSE_PLATE_PATTERN' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field style="float:left" class="fieldEditVisitor">
                    <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.IU' | translate}} {{dynamicRequiredMark('iu')}}
                    </mat-label>
                    <input matInput [formControl]="iu" [minLength]="vmsConfig.SIZE_IU_MIN"
                        [maxLength]="vmsConfig.SIZE_IU_MAX">
                    <mat-icon matSuffix matTooltip="{{'MAPP-VMS-EDIT-SINGLE-VISIT.IU_TOOLTIP' | translate}}">
                        warning_amber</mat-icon>
                    <mat-error *ngIf="iu.hasError('minlength')">
                        {{ 'MAPP-VMS-EDIT-SINGLE-VISIT.IU_MIN_LENGTH' | translate}}
                    </mat-error>
                    <mat-error *ngIf="iu.hasError('maxlength')">
                        {{ 'MAPP-VMS-EDIT-SINGLE-VISIT.IU_MIN_LENGTH' | translate}}
                    </mat-error>
                </mat-form-field>
                <div style="float:left" class="fieldEditVisitor">
                    <mat-label class="icon_label">{{'MAPP-VMS-EDIT-SINGLE-VISIT.VEHICLE_TYPE' | translate}}
                        {{dynamicRequiredMark('iu')}}
                        <br>
                        <mat-icon *ngFor="let one_vehicle of available_vehicles" class="vehicle_type"
                            [ngClass]="{'vehicle_type_selected': vehicle_type===one_vehicle.vehicle_code}"
                            (click)="changeVehicle(one_vehicle.vehicle_code);">{{one_vehicle.vehicle_icon}}</mat-icon>
                        <!--<mat-icon class="vehicle_type"
                            [ngClass]="{'vehicle_type_selected': vehicle_type===vmsConfig.VEHICLE_CAR}"
                            (click)="changeVehicle(vmsConfig.VEHICLE_CAR);">drive_eta</mat-icon>
                        <mat-icon class="vehicle_type"
                            [ngClass]="{'vehicle_type_selected': vehicle_type===vmsConfig.VEHICLE_MOTORBIKE}"
                            (click)="changeVehicle(vmsConfig.VEHICLE_MOTORBIKE);">two_wheeler</mat-icon>
                        -->
                    </mat-label>
                </div>
            </div>
            <p></p>
            <mat-checkbox class="checkParkingEditVisitor" [checked]="visit.complimentary_parking"
                [formControl]="complimentary_parking" *ngIf="masterCfg.parking_active && can_request_parking">
                <mat-icon class="icParkingKo" *ngIf="visit.complimentary_parking_status !=='R'"> local_parking
                </mat-icon>
                <mat-label
                    *ngIf="visit.complimentary_parking_status ==='R' || visit.complimentary_parking_status ==='E'">
                    {{'MAPP-VMS-EDIT-SINGLE-VISIT.PARKING_AGAIN' | translate}}</mat-label>
            </mat-checkbox>

            <p></p>
            <mat-form-field class="textAreaEditVisitor">
                <mat-label>{{'MAPP-VMS-EDIT-SINGLE-VISIT.ABOUT' | translate}}</mat-label>
                <textarea matInput [formControl]="about"></textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div class="buttonEditVisitor">
            <button class="editVisitorButton" mat-raised-button
                (click)="onCancelAddVisitor($event)">{{'MAPP-VMS-EDIT-SINGLE-VISIT.CANCEL' | translate}}</button>
            <button class="editVisitorButton" type="submit"
                [disabled]="!formGroup.valid || !emailValidated || formGroup.pristine" mat-raised-button
                color="primary">{{'MAPP-VMS-EDIT-SINGLE-VISIT.SAVE' | translate}}</button>
        </div>
    </mat-dialog-actions>

</form>