import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FaultService } from '../../client/fault.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, SingleDataSet, monkeyPatchChartJsTooltip, monkeyPatchChartJsLegend, MultiDataSet } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { Report } from '../../client/dto/report';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { Issue } from '../../client/dto/issue';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';



@Component({
  selector: 'microapp-faultreport-fault-report',
  templateUrl: './microapp-faultreport.report-fault.component.html',
  styleUrls: ['./microapp-faultreport.report-fault.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit {
  currentUser: User;
  reportingData: Report = new Report();
  customerBarChartData: boolean = false;
  dateEn = new Date();
  dateFilterStart;
  loading: boolean = true;
  dateFilterEnd = new FormControl();
  noDataWeek: boolean;
  noDataMonth: boolean;
  noDataYear: boolean;


  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}], yAxes: [{
        // ticks: {
        //   stepSize: 1
        // }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
    // cutoutPercentage: 70,
  };

  public bdoughnutChartOptions: ChartOptions = {
    responsive: true,
    cutoutPercentage: 70,
  };
  public pieChartLabelsStatus: Label[];
  public barChartLabelsOwner: Label[];
  public doughnutChartLabelsPriority: Label[];
  public pieChartLegend = true;
  public barChartLegend = true;
  public pieChartType: ChartType = 'pie';
  public barChartType: ChartType = 'bar';
  public doughnutChartType: ChartType = 'doughnut';
  public pieChartPlugins = [];
  public pieChartDataStatus: SingleDataSet;
  public barChartDataOwner: ChartDataSets[];
  public doughnutChartDataPriority: SingleDataSet;
  // public doughnutChartDataPriority: MultiDataSet;
  public doughnutChartColors: Color[] = [
    { backgroundColor: ["#45046a", "#5d17b7", "#a167ed", "#781af1", "#924bec", "#24074a", "#5a2e94"] }
  ];
  public pieChartColors: Color[] = [
    { backgroundColor: ["#dbecf8", "#2c82be"] }
  ];
  public labChartColors: Color[];
  public colorOpen = "#077d72";
  public colorClosed = "#51ebdd"
  filterValues = {};
  today = new Date();


  constructor(private translate: TranslateService, private faultService: FaultService,
    private snackBar: MatSnackBar, private auth: AuthenticationService) {
    this.currentUser = this.auth.getCurrentUser();
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    this.dateEn.setDate(1);
    this.dateFilterStart = new FormControl(this.dateEn);
  }

  ngOnInit() {

    //  this.selectedChange('startDate', this.dateEn);
    this.updateData();

  }

  updateData() {
    let varOpen = this.translate.instant('MAPP-FAULTREPORT.REPORT.OPEN');
    let varClosed = this.translate.instant('MAPP-FAULTREPORT.REPORT.CLOSED');
    let label: Label[] = [varOpen, varClosed];
    let statusOpen = 0;
    let statusClosed = 0;
    let statusOpenWeek = 0;
    let statusClosedWeek = 0;
    let statusOpenMonth = 0;
    let statusClosedMonth = 0;

    let today = new Date();
    let year = +today.getUTCFullYear() + "-01-01"
    this.filterValues["startDate"] = year;
    let dte = new Date();
    dte.setDate(dte.getDate() + 1)
    let tomorrow = new Date(dte)
    this.filterValues["endDate"] = tomorrow;
    let monthUTC = new Date()
    let monthString: string = monthUTC.toLocaleString('en-GB', { month: 'long' }).toLocaleUpperCase();

    this.faultService.getReporting(this.currentUser, this.filterValues).subscribe((data) => {

      this.loading = false;

      statusClosed = data[0].closed_year;
      statusOpen = data[0].open_year;
      statusOpenWeek = data[0].open_week;
      statusClosedWeek = data[0].closed_week;
      statusOpenMonth = data[0].open_month;
      statusClosedMonth = data[0].closed_month;

      if (statusClosedWeek == 0 && statusOpenWeek == 0) {
        this.noDataWeek = true;
      } else {
        this.noDataWeek = false;
      }
      if (statusOpenMonth == 0 && statusClosedMonth == 0) {
        this.noDataMonth = true;
      } else {
        this.noDataMonth = false;
      }
      
      if (statusClosed == 0 && statusOpen == 0) {
        this.noDataYear = true;
      } else {
        this.noDataYear = false;
      }

      this.pieChartLabelsStatus = label;
      this.pieChartDataStatus = [statusOpen, statusClosed];

      this.doughnutChartLabelsPriority = label;
      this.doughnutChartDataPriority = [statusOpenWeek, statusClosedWeek];

      this.barChartLabelsOwner = [monthString];
      this.barChartDataOwner = [
        { data: [statusOpenMonth, 0], label: varOpen },
        { data: [statusClosedMonth, 0], label: varClosed }
      ];

      let colorsOpen = [];
      let colorsClosed = [];
      this.labChartColors = [
        { backgroundColor: colorsOpen },
        { backgroundColor: this.colorClosed },
      ];
      this.customerBarChartData = true;
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.UPDATE.EDIT.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.UPDATE.EDIT.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }


  // selectedChange(filter, dateFilter) {
  //   if (dateFilter == null) { //clean date
  //     this.filterValues = {};
  //     if (this.dateFilterEnd != undefined) {
  //       this.filterValues['endDate'] = this.dateFilterEnd;
  //     }
  //     if (this.dateFilterStart != undefined) {
  //       this.filterValues['startDate'] = this.dateFilterStart;
  //     }
  //   } else {
  //     this.filterValues[filter] = dateFilter;
  //   }
  //   this.updateData();
  // }

  formatDate(date) {
    let formatedBarra: String = date.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + date.slice(11) + "Z";
    let dateNew = new Date(formated);
    return dateNew;
  }
}

