<mat-list>
   <mat-list-item>
      <mat-icon class="icDetailsVisitor" mat-list-icon>person</mat-icon>
      <div class="primaryLabel" mat-line>{{visitor.first_name}} {{visitor.last_name}}</div>
      <div class="secundaryLabel" mat-line>{{visitor.company}}</div>
   </mat-list-item>
   <mat-list-item>
      <mat-icon class="icDetailsVisitor" mat-list-icon>phone</mat-icon>
      <div class="primaryLabel" mat-line>{{visitor.phone}}</div>
   </mat-list-item>
   <mat-list-item>
      <mat-icon class="icDetailsVisitor" mat-list-icon>email</mat-icon>
      <div class="primaryLabel" mat-line>{{visitor.email}}</div>
   </mat-list-item>
   <mat-list-item>
      <mat-icon class="icDetailsVisitor" mat-list-icon>contactless</mat-icon>
      <div class="primaryLabel" mat-line>{{visitor.iu}}</div>
   </mat-list-item>
   <mat-list-item *ngIf="vmsConfig.REQUEST_LICENSE_PLATE">
      <!--mat-icon class="icDetailsVisitor" mat-list-icon>directions_car</mat-icon-->
      <mat-icon class="icDetailsVisitor" mat-list-icon
         *ngIf="!(visitor && visitor.vehicle_type && visitor.vehicle_type===vmsConfig.VEHICLE_MOTORBIKE)">
         drive_eta</mat-icon>
      <mat-icon class="icDetailsVisitor" mat-list-icon
         *ngIf="visitor && visitor.vehicle_type && visitor.vehicle_type===vmsConfig.VEHICLE_MOTORBIKE">
         two_wheeler</mat-icon>
      <div class="primaryLabel" mat-line>{{visitor.license_plate}}</div>
   </mat-list-item>
</mat-list>
<mat-form-field class="example-textArea">
   <mat-label>{{'MAPP-VMS-VISITOR-DETAIL.ABOUT' | translate}}</mat-label>
   <textarea matInput readonly>{{visitor.about}}</textarea>
</mat-form-field>