import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MicroappFbsResourceModel } from '../../../../client/dto/microapp-fbs-resource';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { selectChargeTypes, selectResources, } from '../../../../lib/store/microapp-fbs-selectors';
import * as microappFbsState from '../../../../lib/store/microapp-fbs-app-reducer';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FbsConfig } from '../../../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsChargeType } from '../../../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';


@Component({
  selector: 'empusa-microapp-fbs-new-update-resource',
  templateUrl: './microapp-fbs-new-update-resource.component.html',
  styleUrls: ['./microapp-fbs-new-update-resource.component.css']
})
export class MicroappFbsNewUpdateResourceComponent implements OnInit {

  resourceToUpdate: MicroappFbsResourceModel;

  newResourceFormGroup: FormGroup;
  name = new FormControl('', [Validators.required]);
  location = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  maxParticipants = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), , Validators.max(1000)]);
  chargeType = new FormControl('', [Validators.required]);

  selectChargeTypes: MicroappFbsChargeType[];
  loading: boolean;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private fbsConfig: FbsConfig,
    private messageHandler: MicroappFbsMessageHandlerService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data)
      this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {

        this.resourceToUpdate = resources.find(x => x.resourceId == data.singleResourceId);

      });
  }


  ngOnInit(): void {


    this.store.pipe(select(selectChargeTypes)).pipe(take(1)).subscribe(valor => {

      this.selectChargeTypes = valor;
    });


    this.buildForm();
  }

  buildForm() {
    this.newResourceFormGroup = new FormGroup({
      name: this.name,
      location: this.location,
      description: this.description,
      maxParticipants: this.maxParticipants,
      chargeType: this.chargeType,
    });
    if (this.resourceToUpdate) {
      this.newResourceFormGroup.patchValue(this.resourceToUpdate)
    }
  }

  onSubmit() {
    this.loading = true;
    let facility = new MicroappFbsResourceModel();
    facility.name = this.name.value;
    facility.location = this.location.value;
    facility.description = this.description.value;
    facility.maxParticipants = this.maxParticipants.value;
    facility.chargeType = this.chargeType.value;
    facility.type = this.fbsConfig.RESOURCE_TYPE_ATTR_FACILITY;
    if (this.resourceToUpdate) {
      facility.resourceId = this.resourceToUpdate.resourceId;
      this.update(facility);
    } else {
      this.newFacility(facility);
    }

  }
  update(facility: MicroappFbsResourceModel) {
    let ids: number[]=[];
    ids.push(+facility.resourceId);
    this.facilityBookingService.updateResource(facility,ids).subscribe((data: any[]) => {
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    },error => {
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

  newFacility(facility: MicroappFbsResourceModel) {
    this.facilityBookingService.newResource(facility).subscribe((data: any[]) => {
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    },error => {
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

}
