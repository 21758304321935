import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { GenericService } from '../client/generic.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MICROAPP_ACCESS, MICROAPP_NAME, MICROAPP_REPORTING } from '../public-api';
// import { MICROAPP_ACCESS, MICROAPP_NAME, MICROAPP_REPORTING } from '../lib/microapp-faultreport.module';

@Component({
  selector: 'microapp-faultreport',
  templateUrl: './microapp-faultreport.home.component.html',
  styleUrls: ['microapp-faultreport.home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroappFaultreportComponent implements OnInit {
  siteName: string;
  loading: boolean;
  user: string;
  permissionReporting: boolean;
  can_access: boolean = false;
  //  permissionReporting:Promise<boolean>;
  /** the currentsite subscription */
  private currentSiteSubscription: Subscription;


  constructor(private translate: TranslateService, private genericService: GenericService, private auth: AuthenticationService,
    private _snackBar: MatSnackBar) {
    let username = this.auth.getCurrentUserName();
    console.log(`The username was: ${username}`);
    this.loading = true;
    this.auth.loadCurrentUser().then((user: User) => {
      this.siteName = user.sites[0].name;
      this.loading = false;
      this.permissionReporting = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_REPORTING);
      this.can_access = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_ACCESS);
    })

    // this.currentSiteSubscription = this.auth.getEvent_CurrentSite().subscribe(site => {
    //   console.log(`New Site selected: ${site.name}`);
    //   // this._snackBar.open("MICROAPP_NAME", `New Site selected: ${site.name}`, {
    //   //   duration: 2000,
    //   // });
    // })

    // this.permissionReporting = this.auth.canUserExecuteAsync(MICROAPP_NAME, MICROAPP_REPORTING);


  }

  ngOnInit() {
    // this.siteName = this.auth.getCurrentSite().name;
    // this.loading = true;
    // this.auth.loadCurrentUser().then(user => {
    //   this.user = user.mail;
    // });

    // this.auth.loadCurrentSite().then(site => {
    //   this.loading = false;
    //   this.siteName = site.name;
    // });

  }

}