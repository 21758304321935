import * as epsReducer from './microapp-eps.reducer';
import * as epsTenantReducer from "./tenant/microapp-eps-tenant.reducer"

import { ActionReducerMap } from '@ngrx/store';


export interface MicroappEpsStates {
    eps: epsReducer.EpsState;
    tenant: epsTenantReducer.EpsTenantState
}

export const microappEpsReducer: ActionReducerMap<MicroappEpsStates> = {
    eps: epsReducer.epsCounterReducer,
    tenant: epsTenantReducer.epsTenantCounterReducer,
};


