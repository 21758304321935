<div class="fondoGeneral">
   <mat-drawer-container class="drawContainer">
      <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
         <mat-card class="mc">
            <mat-card-content class="filters"> {{'MAPP-FBS-MY-RESERVATIONS.FILTER' | translate}}</mat-card-content>
            <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
               <mat-icon class="closeIcFilter">close</mat-icon>
            </button>
         </mat-card>
         <mat-divider></mat-divider>
         <p></p>
         <div class="filtrosEmergentes">
            <mat-form-field>
               <input matInput (keyup)="applyGenericFilter($event.target.value)"
                  placeholder="{{'MAPP-FBS-MY-RESERVATIONS.GENERIC_PLACE_HOLDER' | translate}}">
               <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <p></p>
         </div>

      </mat-drawer>
      <!-- <form [formGroup]="formGroup" (ngSubmit)="onContinue()"> -->
      <div class="header" (ngSubmit)="onContinue()">
         <mat-form-field class="margen_cajas" *ngIf="can_see_all_reservations">
            <mat-label>{{'MAPP-FBS-MY-RESERVATIONS.TENANT' | translate}}</mat-label>
            <mat-select [formControl]="selectedTenantControl">
               <mat-option></mat-option>
               <mat-option *ngFor="let tenant of tenantsToSelect" [value]="tenant.uid">
                  {{tenant.name}}
               </mat-option>
            </mat-select>
         </mat-form-field>

         <!-- <div> -->
         <mat-form-field class="margen_cajas">
            <mat-label>{{'MAPP-FBS-MY-RESERVATIONS.DATE_FROM' | translate}}</mat-label>
            <input matInput [formControl]="start" [max]="maxDate" [matDatepicker]="pickerFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
         </mat-form-field>

         <mat-form-field class="margen_cajas">
            <mat-label>{{'MAPP-FBS-MY-RESERVATIONS.DATE_TO' | translate}}</mat-label>
            <input [min]="start.value" [max]="maxDate" matInput [formControl]="end" [matDatepicker]="pickerTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
            <mat-error *ngIf="end.invalid">{{'MAPP-FBS-MY-RESERVATIONS.DATE_TO_ERROR' | translate}}</mat-error>
         </mat-form-field>

         <button mat-flat-button class="buttonSearchDates" color="primary" (click)="search_dates()"
            [disabled]="can_see_all_reservations && !selectedTenantControl.valid">
            <mat-icon>search</mat-icon>
         </button>
         <!-- </div> -->
         <div class="divRight">
            <button class="expand" mat-button (click)="onExpand($event)">
               {{allRowsExpanded ? ('MAPP-FBS-MY-RESERVATIONS.COLLAPSE' | translate)
               : ('MAPP-FBS-MY-RESERVATIONS.EXPAND' | translate)}}
            </button>

            <button mat-icon-button class="buttonExpandFil">
               <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
         </div>
      </div>
      <!-- </form> -->


      <div>
         <table mat-table [dataSource]="reservationsDataSource" multiTemplateDataRows matSort id='tablaList'>

            <ng-container matColumnDef="owner">
               <mat-header-cell *matHeaderCellDef>{{'MAPP-FBS-MY-RESERVATIONS.OWNER' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let element">
                  {{element.owner.firstName}} {{element.owner.lastName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tenant_name">
               <mat-header-cell *matHeaderCellDef>{{'MAPP-FBS-MY-RESERVATIONS.TENANT' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let element">
                  {{element.owner.tenant_name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="resourceName">
               <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-MY-RESERVATIONS.RESOURCE' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="!element.extra">
                     {{element.resourceName}}
                     {{element.reservations.length>1?" (x" + element.reservations.length+")":"" }}
                  </ng-container>
                  <ng-container *ngIf="element.extra">
                     {{element.resourceName}}
                  </ng-container>
               </mat-cell>
            </ng-container>
            <ng-container matColumnDef="title">
               <mat-header-cell mat-header-cell *matHeaderCellDef>{{'MAPP-FBS-MY-RESERVATIONS.TITLE' | translate}}
               </mat-header-cell>
               <mat-cell mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
               <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{'MAPP-FBS-MY-RESERVATIONS.DATE' | translate}}</mat-header-cell>
               <mat-cell mat-cell *matCellDef="let element"> {{element.startDate | date:'shortDate'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="startDate">
               <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{'MAPP-FBS-MY-RESERVATIONS.FROM' | translate}}</mat-header-cell>
               <mat-cell mat-cell *matCellDef="let element"> {{element.startDate | date:'HH:mm'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate">
               <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{'MAPP-FBS-MY-RESERVATIONS.TO' | translate}}</mat-header-cell>
               <mat-cell mat-cell *matCellDef="let element"> {{element.endDate | date:'HH:mm'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
               <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
               <mat-cell *matCellDef="let reservation">
                  <button matTooltip=" {{'MAPP-FBS-TOOLTIP.RESERVATION.EDIT' | translate}}" mat-icon-button
                     *ngIf="(reservation.endDate >= now) && can_resources_reservation"
                     (click)="reservation_update(reservation)">
                     <mat-icon class="icActions">edit</mat-icon>
                  </button>
                  <button matTooltip=" {{'MAPP-FBS-TOOLTIP.RESERVATION.DELETE' | translate}}" mat-icon-button
                     *ngIf="(reservation.endDate >= now) && can_resources_reservation"
                     (click)="reservation_delete(reservation)">
                     <mat-icon class="icActions">delete</mat-icon>
                  </button>
               </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
               <td class="tdVisitors" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement || allRowsExpanded ? 'expanded' : 'collapsed'">
                     <div class="example-element-diagram">
                     </div>
                     <div class="example-element-description">
                        <p>
                           {{'MAPP-FBS-MY-RESERVATIONS.DESCRIPTION' | translate}}:
                           {{element.description}}
                        </p>
                        <p>
                           {{'MAPP-FBS-MY-RESERVATIONS.TOTAL_COST' | translate}}:
                           {{element.total_cost | currency :coin:'symbol-narrow'}}
                        </p>
                        <div *ngIf="element.extra && element.extra.resource_group">
                           <empusa-microapp-fbs-mini-resource-table [resources]="filterResourcesByIds(element.extra)">
                           </empusa-microapp-fbs-mini-resource-table>
                        </div>

                     </div>
                  </div>
               </td>
            </ng-container>

            <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
               [class.example-expanded-row]="expandedElement === element"
               (click)="expandedElement = expandedElement === element ? null : element; allRowsExpanded = false">
            </mat-row>

            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


         </table>

      </div>
      <mat-paginator [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
   </mat-drawer-container>

</div>