import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'microapp-quiz-generic',
  templateUrl: './microapp-quiz-generic.component.html',
  styleUrls: ['./microapp-quiz-generic.component.css']
})


export class MicroappQuizGenericComponent {
    constructor(
        public dialogRef: MatDialogRef<MicroappQuizGenericComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: MicroappQuizGenericComponentgData) { 
  }
}

export interface MicroappQuizGenericComponentgData {
    title: string;
    question: string;
    icon: string;
}