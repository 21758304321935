import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';
import { MicroappBfsMakeAReservationComponent } from '../make-a-facility-reservation/microapp-bfs-make-a-reservation.component';
import { AuthenticationService } from '@empusa/empusa-core';
import { MicroappFbsResourceGroup } from '../../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsMakeAAccessoryReservationComponent } from '../make-a-accesory-reservation/microapp-fbs-make-a-accessory-reservation.component';
import { Store } from '@ngrx/store';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import { MicroappFbsResourceModel } from '../../../client/dto/microapp-fbs-resource';
import { Subscription } from 'rxjs';
import { MicroappFbsMakeAFacilityGroupReservationComponent } from '../make-a-facility-group-reservation/microapp-fbs-make-a-facility-group-reservation.component';

@Component({
  selector: 'empusa-microapp-fbs-resource-detail',
  templateUrl: './microapp-fbs-resource-detail.component.html',
  styleUrls: ['./microapp-fbs-resource-detail.component.css']
})
export class MicroappFbsResourceDetailComponent implements OnInit,OnDestroy {

  resource: MicroappFbsResourceGroup;
  can_resources_reservation: boolean;
  raw_resources: MicroappFbsResourceModel[];
  storeSubscription: Subscription;


  constructor(private dialogRef: MatDialogRef<any>,
    private fbsConfig: FbsConfig,
    private dialog: MatDialog,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private authservice: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) data) {

    this.resource = data;
  }

  ngOnInit(): void {
    this.can_resources_reservation = this.authservice.canUserExecute(this.fbsConfig.APPLICATION,this.fbsConfig.ROLE_RESOURCES_RESERVATION);
    this.storeSubscriptionFn();
  }

  private storeSubscriptionFn() {

    this.storeSubscription = this.store.select('fbs').subscribe(data => {
      if (data && data.resources) {
        this.raw_resources = data.resources;
      };

    });
  }

  close() {
    this.dialogRef.close(false);
  }


  public toBook() {

    if ( this.resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_ACCESSORY) {
      this.bookAAccessory();
    } else if (this.resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_FACILITY){
      this.bookAFacility();
    } else if (this.resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP){
      this.bookAGroup();
    }
  }
    


  private bookAFacility() {
    const dialogConfig = new MatDialogConfig();
    const selectedFacility = this.raw_resources.find(x => x.resourceId == this.resource.singleResourceId);
    dialogConfig.data = [selectedFacility];
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(MicroappBfsMakeAReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
        this.dialogRef.close(true);
      }
    });
  }


  private bookAAccessory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [this.resource];
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(MicroappFbsMakeAAccessoryReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
        this.dialogRef.close(true);
      }
    });
  }

  private bookAGroup() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.data =  [this.resource];
  dialogConfig.width = '1000px';
  const dialogRef = this.dialog.open(MicroappFbsMakeAFacilityGroupReservationComponent, dialogConfig);
  dialogRef.afterClosed().subscribe(result => {
    if (result == true) {
      this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
      this.dialogRef.close(true);
    }
  });
}


  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    };
  }
}
