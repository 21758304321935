<h2 class="titleAssign"> {{'MAPP-FBS-ASSIGN-UPDATE.TITLE' | translate}}</h2>
<h5>{{'MAPP-FBS-ASSIGN-UPDATE.MESSAGE' | translate}} {{dataLoad.resource.application_charge_code}}</h5>
<form [formGroup]="newTariffForm">


    <div>
        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-FBS-ASSIGN-UPDATE.TARIFF' | translate}}</mat-label>
            <mat-select required formControlName="tariff"> 
                <mat-option *ngFor="let tarif of tarifList" [value]="tarif.tarif_id">{{tarif.tarif_name}} - ({{tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</form>

<div mat-dialog-actions class="buttonDetailCharge" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-FBS-ASSIGN-UPDATE.CLOSE' | translate}}</button>
    <button [disabled]="!newTariffForm.valid" (click)="onSubmit()" class="buttonConfirm" mat-raised-button
        color="primary">
        {{'MAPP-FBS-ASSIGN-UPDATE.ASSIGN' | translate}}</button>
</div>