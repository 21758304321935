import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'microapp-tariff-generic',
  templateUrl: './microapp-tariff-generic.component.html',
  styleUrls: ['./microapp-tariff-generic.component.css']
})


export class MicroappTariffGenericComponent {


  listTenant = []

    constructor(
        public dialogRef: MatDialogRef<MicroappTariffGenericComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: MicroappTariffGenericComponentData) {}
}

export interface MicroappTariffGenericComponentData {
    title: string;
    question: string;
    list: string[];
    message: string;
    icon: string;
}