import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as vmsReducer from '../lib/microapp-vms.reducer';
import * as logBookActions from '../lib/logbook/store/microapp-vms-logbook.actions'
import * as addressBookActions from '../lib/address-book/store/microapp-vms-address-book.actions';
import * as newVisitActions from '../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions';

import { Store } from '@ngrx/store';
import { Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel } from '@angular/router';
import { AuthenticationService } from '@empusa/empusa-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { selectLogBookError } from '../lib/logbook/store/microapp-vms-logbook-selectors';
import { selectAddressBookError } from '../lib/address-book/store/microapp-vms-address-book-selectors';
import { MICROAPP_VMS_NAME, VmsConfig } from '../lib/microapp-vms-config';
import { Subscription } from 'rxjs';
import { newVisitError } from '../lib/group-visit-manage/store/microapp-vms-group-visit-manage-selectors';
import { MicroappVmsConfigService } from '../lib/services/microapp-vms-config.service';
import { VmsMasterConfiguration } from '../lib/model/microapp-vms-config.model';


@Component({
  selector: 'empusa-microapp-vms-home',
  templateUrl: './microapp-vms-home.component.html',
  styleUrls: ['./microapp-vms-home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroappVmsHomeComponent implements OnInit {
  option: number = 0;
  loading = false;
  loading_cfg = true;
  can_address_book: boolean;
  currentSiteSubscription: Subscription;
  siteName: string;
  config: VmsMasterConfiguration;
  timezone = null;
  difftz: boolean;
  can_access: boolean = false;

  constructor(private translate: TranslateService,
    private store: Store<vmsReducer.MicroappVmsStates>,
    private router: Router,
    private vmsConfig: VmsConfig,
    private configService: MicroappVmsConfigService,
    private authservice: AuthenticationService,
    private snackBar: MatSnackBar) {
    this.can_address_book = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_ADDRESS_BOOK);
    this.can_access = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CAN_ACCESS)
  }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });



    this.store.select(selectLogBookError).subscribe(error => {
      if (error) {
        const txt = this.translate.instant('MESSAGE_CODES.GENERIC');
        this.openSnackBar(txt, "X");
        this.store.dispatch(logBookActions.resetError());
      }
    });

    this.store.select(selectAddressBookError).subscribe(error => {
      if (error) {
        const txt = this.translate.instant('MESSAGE_CODES.GENERIC');
        this.openSnackBar(txt, "X");
        this.store.dispatch(addressBookActions.resetError());
      }
    });

    this.store.select(newVisitError).subscribe(error => {
      if (error) {
        let txt = this.translate.instant('MESSAGE_CODES.GENERIC');
        this.openSnackBar(txt, "X");
        this.store.dispatch(newVisitActions.resetError());
      }
    });

    this.currentSiteSubscription = this.authservice.getEvent_CurrentSite().subscribe(newSite => {
      this.siteName = newSite.name;
      this.store.dispatch(logBookActions.startLogBook({
        site: this.authservice.getCurrentSite(),
        loggedUser: this.authservice.getCurrentUser()
      }));
    })

    //TODO PASAr ESTO A SUBSCIRPCION
    this.configService.getConfiguration().subscribe(cfg => {
      this.store.dispatch(logBookActions.storeConfig({ config: cfg }));
      this.config = cfg;
      this.loading_cfg = false;
      console.log("cfg:", cfg);
      this.vmsConfig.MASTER_CONFIG = cfg;
      this.vmsConfig.REQUEST_LICENSE_PLATE = cfg.request_license_plate;
      console.log("this.vmsConfig.REQUEST_LICENSE_PLATE", this.vmsConfig.REQUEST_LICENSE_PLATE);
      this.timezone = this.vmsConfig.MASTER_CONFIG.timezone;
      this.difftz = this.vmsConfig.MASTER_CONFIG.timezone != this.vmsConfig.MASTER_CONFIG.localTimeZone;
      this.router.navigateByUrl('vms/logbook');
    }, error => {
      console.error(error);
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }

}
