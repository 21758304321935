import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'empusa-question',
    templateUrl: 'empusa-question.component.html',
    styleUrls: ['./empusa-question.component.css']
})
export class EmpusaQuestionDialog {
    constructor(
        public dialogRef: MatDialogRef<EmpusaQuestionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: EmpusaQuestionDialogData) {
    }
}


export interface EmpusaQuestionDialogData {
    title: string;
    question: string;
    icon: string;
}