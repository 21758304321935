import { Injectable } from '@angular/core';
import { AuthenticationService, Tenant } from '@empusa/empusa-core';
import { Adapter } from './microapp-vms-adapter';
import { MicroappVmsVisit } from './microapp-vms-visit.model';

@Injectable({
  providedIn: "root",
})
export class MicroappVmsVisitAdapter implements Adapter<MicroappVmsVisit> {

  constructor(private authservice: AuthenticationService) { };


  adapt(item: any): MicroappVmsVisit {
    let visitAdapted = new MicroappVmsVisit;
    if (item.visitor_id)
      visitAdapted.visitor_id = item.visitor_id;
    if (item.delivery_id)
      visitAdapted.delivery_id = item.delivery_id;
    visitAdapted.about = item.about;
    visitAdapted.check_in_time = this.formatDateTime(item.check_in_time);
    visitAdapted.check_out_time = this.formatDateTime(item.check_out_time);
    visitAdapted.auto_check_out = item.auto_check_out;
    visitAdapted.first_in_time = this.formatDateTime(item.first_in_time);
    visitAdapted.last_out_time = this.formatDateTime(item.last_out_time);
    visitAdapted.company = item.company;
    if (item.complimentary_parking_status === "C" || item.complimentary_parking_status === "P"
          || item.complimentary_parking_status === "E" || item.complimentary_parking_status === "R")
      visitAdapted.complimentary_parking = true;
    else
      visitAdapted.complimentary_parking = false;
    visitAdapted.complimentary_parking_status = item.complimentary_parking_status;
    visitAdapted.complimentary_parking_message = item.complimentary_parking_message;
    visitAdapted.date = this.formatDateTime(item.start_time);
    visitAdapted.date.setHours(0, 0, 0, 0);
    visitAdapted.email = item.email;
    visitAdapted.end_time = this.formatDateTime(item.end_time);
    visitAdapted.first_name = item.first_name;
    visitAdapted.group_id = item.group_id;
    visitAdapted.host = item.host;
    visitAdapted.host_id = item.host_id;
    visitAdapted.id = item.id;
    visitAdapted.last_name = item.last_name;
    visitAdapted.iu = item.iu;
    visitAdapted.license_plate = item.license_plate;
    visitAdapted.phone = item.phone;
    visitAdapted.site_id = item.site_id;
    visitAdapted.start_time = this.formatDateTime(item.start_time);
    if (item.tenant) {
      let one_tenant = new Tenant();
      one_tenant.name = item.tenant.name;
      visitAdapted.tenant = one_tenant;
    }
    visitAdapted.tenant_id = item.tenant_id;
    visitAdapted.visitor_id = item.visitor_id;
    visitAdapted.notification_status = item.notification_status;
    visitAdapted.past_visit = item.past_visit;
    visitAdapted.visit_type = item.visit_type;
    visitAdapted.vehicle_type = item.vehicle_type;
    visitAdapted.owner = false;
    visitAdapted.result_check_mask = item.result_check_mask;
    visitAdapted.result_check_passive_liveness = item.result_check_passive_liveness;
    visitAdapted.result_check_temperature = item.result_check_temperature;

    const visitSite = this.authservice.getCurrentUser().sites.find(userSite => userSite.dn == visitAdapted.site_id);
    if (visitSite) {
      if (visitSite.tenants.find(t => t.dn == visitAdapted.tenant_id)) {
        visitAdapted.owner = true;
      }
    }
    return visitAdapted;
  }

  formatDateTime(date: number): Date {
    if (date == null) return null;
    return new Date(date * 1000);
  }
}
