import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';
import { Subscription } from 'rxjs';
import { EpsParkingSize } from '../../../lib/model/microapp-eps.parking-size.model';
import { MatTableDataSource } from '@angular/material/table';
import { EpsVehicleType } from '../../../lib/model/microapp-eps.vehicle-type.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MicroappEpsParkingSizeEditComponent } from '../parking-size-edit/microapp-eps-parking-size-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'empusa-microapp-eps-quotas-spaces',
  templateUrl: './microapp-eps-quotas-spaces.component.html',
  styleUrls: ['./microapp-eps-quotas-spaces.component.css']
})
export class MicroappEpsQuotasSpacesComponent implements OnInit, OnDestroy {

  storeSubscription: Subscription;
  loading: boolean = false;
  parkingDataLoaded: boolean = false;
  parkingSize: EpsParkingSize[];
  parkingVehicleTypes: EpsVehicleType[];

  parking_size_data: MatTableDataSource<EpsParkingSize>;
  public sizeDisplayedColumns: string[] = [];

  first_header: boolean = false;

  constructor(private epsServices: EpsServices,
    public dialog: MatDialog,
    private translate: TranslateService,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
  ) { }


  ngOnInit(): void {
    this.getInfo();
    this.storeSubscription = this.store.select('eps').subscribe(data => {
      this.loading = data.loading;
      if (data.parkingSize && data.parkingVehicleTypes) {
        this.parkingSize = data.parkingSize;
        this.parkingVehicleTypes = data.parkingVehicleTypes;
        this.parkingDataLoaded = data.parkingDataLoaded;

        this.sizeDisplayedColumns = []

        if (data.parkingSize.length > 1) {
          this.first_header = true
          this.sizeDisplayedColumns = ['spot_type'];
        }

        for (var vType of data.parkingVehicleTypes) {
          this.sizeDisplayedColumns.push("" + vType.vehicle_type_id);
        }
        this.sizeDisplayedColumns.push("actions");
        this.parking_size_data = new MatTableDataSource(data.parkingSize);
      }
    })
  }

  findParkingVehicleSpots(element: EpsParkingSize, vehicle_type_id: number) {
    for (var vehicle_type of element.vehicle_type) {
      if (vehicle_type.vehicle_type_id == vehicle_type_id) {
        if (vehicle_type.oversell_ratio > 0)
        // suma directa  
        return (vehicle_type.number_of_spots + vehicle_type.oversell_ratio) 
         // % 
        // return (vehicle_type.number_of_spots + vehicle_type.oversell_spots) 

        else
          return vehicle_type.number_of_spots
      }
    }
    return 0;

  }


  getTooltip(element: EpsParkingSize, vehicle_type_id: number) {
    let tooltip: string;
    const vehicle_type = element.vehicle_type.find(vt => vt.vehicle_type_id == vehicle_type_id);
    if (vehicle_type == undefined) {
      return ""
    }
    if (vehicle_type.oversell_ratio > 0 && vehicle_type.can_assign > 0) {
      tooltip = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TOOLTIP_AVAILABLE_OVERSELL');
    } else if (vehicle_type.oversell_ratio > 0 && vehicle_type.can_assign == 0) {
      tooltip = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TOOLTIP_NOT_AVAILABLE_OVER_SELL');
    } else if (vehicle_type.can_assign == 0) {
      tooltip = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TOOLTIP_NOT_AVAILABLE');
    } else
      tooltip = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TOOLTIP_AVAILABLE');
    // %
    // tooltip = tooltip.replace('{1}', String(Number(vehicle_type.number_of_spots + vehicle_type.oversell_spots)));  
    // suma directa    
    tooltip = tooltip.replace('{1}', String(Number(vehicle_type.number_of_spots + vehicle_type.oversell_ratio)));
    tooltip = tooltip.replace('{2}', "" + vehicle_type.oversell_ratio);
    tooltip = tooltip.replace('{3}', "" + vehicle_type.can_assign);

    return tooltip;
  }



  findParkingVehicleAvailableSpots(element: EpsParkingSize, vehicle_type_id: number) {
    for (var vehicle_type of element.vehicle_type) {
      if (vehicle_type.vehicle_type_id == vehicle_type_id) {
        return vehicle_type.can_assign
      }
    }
    return 0;
  }

  getInfo() {
    this.store.dispatch(epsActions.epsGetParkinfData());
  }

  editParkingSpace(element: EpsParkingSize) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      oneTypeParkingSize: element,
      parkingVehicleTypes: this.parkingVehicleTypes,
    };
    dialogConfig.width = '500px';
    dialogConfig.maxHeight= '900px';
    const dialogRef = this.dialog.open(MicroappEpsParkingSizeEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }
}
