import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import * as vmsReducer from '../microapp-vms.reducer';
import * as logBookActions from './store/microapp-vms-logbook.actions'
import { Store, select } from '@ngrx/store';
import { logbookInit, selectVisitListLoadedOrError } from './store/microapp-vms-logbook-selectors';
import { tap, filter, first, finalize } from 'rxjs/operators';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { VmsConfig } from "../microapp-vms-config";

@Injectable()
export class LogBookVisitListResolver implements Resolve<boolean>{

    loading = false;
    user: User;

    constructor(private store: Store<vmsReducer.MicroappVmsStates>,
        private router: Router,
        private vmsConfig: VmsConfig,
        private authservice: AuthenticationService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        console.log("LogBookVisitListResolver.");
        this.store.dispatch(logBookActions.resetError());
        return this.store
            .pipe(
                select(logbookInit),
                tap(logbookInit => {
                    if (!this.loading) {
                        this.loading = true;
                        console.log("LogBookVisitListResolver. Search");
                        if (this.authservice.getCurrentUser()){
                            this.store.dispatch(logBookActions.startLogBook({site: this.authservice.getCurrentSite(),
                                                                             loggedUser: this.authservice.getCurrentUser()}));
                        }else{
                            this.router.navigateByUrl('/vms');
                        }
                    }
                }),
                filter(logbookInit => logbookInit),
                first(),
                finalize(() => this.loading = false)
            );
    }


}