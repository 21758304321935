export class MicroappVmsVisitor{
    id: number;
    tenant_id: string;
    host_id: string;
    site_id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    phone: string;
    iu: string;
    license_plate: string;
    about: string;
    created: Date;  
    vehicle_type;  
}