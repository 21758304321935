import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MicroappHvacComponent } from './microapp-hvac.home.component';
import { FacilityComponent } from './facility/microapp-hvac.facility-hvac.component';
import { FacilityPasesComponent } from './facility/passes/microapp-hvac.facility-pases-hvac.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-hvac.mat-spinner-overlay.component';
import { ReservationsTenantComponent } from './tenantReservation/microapp-hvac.tenant-reservation-hvac.component';

export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule,
    MatDialogModule,
    HttpClientModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatGridListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatListModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    RouterModule.forChild([
      {
        path: '', component: MicroappHvacComponent
        // , children: [
        //   { path: '', redirectTo: "conf/pases", pathMatch: 'full' },
        //   { path: 'conf/pases', component: FacilityPasesComponent },
        //   { path: 'conf/extensions', component: ReservationsTenantComponent },
        // ]
      }
    ])
  ],
  declarations: [
    MicroappHvacComponent,
    FacilityComponent,
    FacilityPasesComponent,
    MatSpinnerOverlayComponent,
    ReservationsTenantComponent
  ],
  providers: [
    MatSnackBar, DatePipe,
    { provide: DateAdapter, useClass: DateFormat }
  ]
})
export class HvacModule {

  constructor(private translationLoader: TranslationLoaderService, private dateAdapter: DateAdapter<Date>) {
    console.log("hvac microapp - lazy constructor");
    dateAdapter.setLocale("en-SG"); // DD/MM/YYYY
  }

}
