import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../client/notifications.service';
import { DatePipe } from '@angular/common';

export interface MatTicket {
  ticket_id: string
}
@Component({
  selector: 'empusa-microapp-notifications-details',
  templateUrl: './microapp-notifications.details.component.html',
  styleUrls: ['./microapp-notifications.details.component.css']
})
export class MicroappDetailsComponent implements OnInit {

  public detailForm: FormGroup;
  filesName = [];
  lista = []
  typeSend: string;
  loading : boolean = true;
  htmlString;
  readonly localDateFormat = "dd/MM/yyyy"; 
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService,
    private service: NotificationsService, private formBuilder: FormBuilder, private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public ticket_id: MatTicket) {


    this.detailForm = this.formBuilder.group({
      title: ['', Validators.required],
      date: ['', Validators.required],
      message: ['', Validators.required],
      destinatary: ['', Validators.required],
    })
    let id: string = ticket_id.ticket_id;
    this.service.getNotifications(id).subscribe(data => {
      this.typeSend = data[0].sendto;
      if (this.typeSend == "All") {
        let listAlll = []
        listAlll.push("All")
        this.detailDestinatary(listAlll);
      } else if (this.typeSend == "Selected Tenants") {
        this.service.getTenants(id).subscribe(data => {
          this.detailDestinatary(data)
        })
      } else {
        this.service.getDestinataries(id).subscribe(data => {
          this.detailDestinatary(data)
        })
      }
      this.service.getFilenames(id).subscribe(data => {
        this.filesName = data;
      })
      this.detailNotification(data);
    })
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.detailForm.controls; }


  ngOnInit(): void {
  }

  detailNotification(data) {
    this.f.title.setValue(data[0].title);
    let newDate = this.datepipe.transform(data[0].date, this.localDateFormat);
    this.f.date.setValue(newDate);
    this.htmlString = data[0].message;
    // var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
    this.f.message.setValue(this.htmlString);
   
    // this.f.destinatary.setValue(data.reporter);
  }

  detailDestinatary(data) {
    data.forEach(element => {
      if(element.email){
        this.lista.push(element.email);
      } else if (element.namet){
        this.lista.push(element.namet);
      } else {
        this.lista.push('All')
      }
      this.loading = false;
    });
    this.f.destinatary.setValue(this.lista);
  }

  downloadFile(filename: string) {
    this.service.downloadFile(this.ticket_id.ticket_id, filename).subscribe((resp : Blob) => {
      var a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(resp);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    },
      error => {
        this.snackBar.open(
          this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.FILES.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.FILES.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      });
  }
}
