import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'empusa-microapp-vms-visit-detail-container',
  templateUrl: './microapp-vms-visit-detail-container.component.html',
  styleUrls: ['./microapp-vms-visit-detail-container.component.css']
})
export class MicroappVmsVisitDetailContainerComponent  {


  displayLog: Boolean = false;
  refToDialog: MatDialogRef<any>;

  constructor(
    private dialogRef: MatDialogRef<any>) { 
      this.refToDialog = dialogRef;
    }

  displayVisitLog() {
    this.displayLog = true;

  }
  closeNewLog() {

    this.displayLog = false;
  }

}
