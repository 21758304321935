import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EpsAssignedChargeType } from "../../../lib/model/charges_and_tariffs/microapp-eps.assigned-charge-types.model";
import { EpsChargeType } from "../../../lib/model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsTenantTariff } from "../../../lib/model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { EpsParkingSize } from "../../../lib/model/microapp-eps.parking-size.model";
import { EpsSpotType } from "../../../lib/model/microapp-eps.spot-type.model";
import { EpsTenantQuotas } from "../../../lib/model/microapp-eps.tenan-quote.model";
import { EpsVehicleType } from "../../../lib/model/microapp-eps.vehicle-type.model";
import { EpsReservationType } from "../../../lib/model/reservation/microapp-eps.reservation-type.model";
import { EpsServices } from "../../../lib/services/microapp-eps.service";
import { forkJoin, of } from "rxjs";
import { switchMap, map, catchError, withLatestFrom, mergeMap } from "rxjs/operators";
import * as epsTenantActions from './microapp-eps.tenant.actions';
import { EpsTenantParkingRequest } from "../../model/reservation/microapp-eps.tenant-parking-request.model";
import { EpsParkingRequest } from "../../model/reservation/microapp-eps.parking-request.model";
import { Store } from "@ngrx/store";
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import { EpsMasterConfig } from "../../model/cfg/microapp-eps.cfg.model";
import { PaypalOptions } from "../../model/microapp-eps.paypalConfig.model";
import { AuthenticationService } from "@empusa/empusa-core";

@Injectable()
export class MicroappEpsTenantEffects {
  PARKING_REQUEST_SOURCE_SYSTEM = "EPS";

  constructor(
    private actions$: Actions,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private epsServices: EpsServices,
    private auth: AuthenticationService,
  ) {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsGetMasterData),
      switchMap((action) => {
        const vehicleTypes = this.epsServices.vehicleTypes();
        const reservationTypes = this.epsServices.reservationTypes();
        const spotTypes = this.epsServices.spotTypes();
        const tenantQuotas = this.epsServices.tenant_quotas(action.tenant);
        const masterConfigSrv = this.epsServices.get_config();
        const paypalTenantOptions = this.epsServices.paypalTenantConfig();
        const parkingSize = this.epsServices.parkingSize();
        return forkJoin([
          vehicleTypes,
          reservationTypes,
          spotTypes,
          tenantQuotas,
          masterConfigSrv,
          paypalTenantOptions,
          parkingSize
        ]).pipe(
          map((results) => {
            const parkingVehicleTypes: EpsVehicleType[] =
              results[0] as EpsVehicleType[];
            const reservationTypes: EpsReservationType[] =
              results[1] as EpsReservationType[];
            const spotTypes: EpsSpotType[] = results[2] as EpsSpotType[];
            const tenantQuotas: EpsTenantQuotas = results[3] as EpsTenantQuotas;
            const masterConfig: EpsMasterConfig = results[4] as EpsMasterConfig;
            let payTemp: PaypalOptions[] = results[5] as PaypalOptions[];
            const enabledNonEntitled = tenantQuotas.quota.find(nonEntitled => nonEntitled.spot_type_id == 2 && nonEntitled.vehicle_type_id == 1).association_enabled;
            const paypalEnabledForThisTenant: PaypalOptions = payTemp.find(paypalTenantOption => paypalTenantOption.tenant_id == action.tenant);
            const parkingSize: EpsParkingSize[] = results[6] as EpsParkingSize[];
            return epsTenantActions.epsStoreMasterData({
              parkingVehicleTypes,
              reservationTypes,
              spotTypes,
              tenantQuotas,
              masterConfig,
              paypalEnabledForThisTenant: paypalEnabledForThisTenant.paypal_enabled,
              nonEntitledEnabledForThisTenant:enabledNonEntitled,
              parkingSize, 
              loading: false
            });
          }),
          catchError((error) => {
            return of(epsTenantActions.epsTenantError());
          })
        );
      })
    )
  );

  loadMasterConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsGetMasterConfig),
      mergeMap( action => this.epsServices.get_config().pipe(
        map((masterConfig) => {
          return epsTenantActions.epsStoreMasterConfig({masterConfig});
        }, catchError((error) => {
          return of(epsTenantActions.epsTenantError());
        }))
      ))
    )
  );

  epsStoreSelectedEmployes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsStoreSelectedEmployes),
      withLatestFrom(this.store.select("tenant")),
      map(([action, state]) => {
        let parkingRequest: EpsTenantParkingRequest =
          new EpsTenantParkingRequest();
        parkingRequest.tenant_id = state.tenantQuotas.tenant_id;
        parkingRequest.source_system = this.PARKING_REQUEST_SOURCE_SYSTEM;
        parkingRequest.reservation_request = [];
        action.users.forEach((u) => {
          let one_request = new EpsParkingRequest();
          one_request.vehicle_owner_name = u.givenName + " " + u.surname;
          one_request.vehicle_owner_id = u.mail;
          parkingRequest.reservation_request.push(one_request);
        });
        return epsTenantActions.epsStoreParkingRequests({ parkingRequest });
      })
    )
  );

  epsDoReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsDoReservation),
      withLatestFrom(this.store.select("tenant")),
      switchMap(([action, state]) => {
        const EpsTenantParkingRequestSrv = this.epsServices.do_reservation(
          state.parkingRequestPasses
        );
        return EpsTenantParkingRequestSrv.pipe(
          map((parkingRequest) => {
            parkingRequest.reservation_request = parkingRequest.reservation_request.map(reservation =>{
              reservation.charge_to = 'Tenant';
              return reservation;
            })
            return epsTenantActions.epsStoreParkingRequestReservation({
              parkingRequest,
            });
          }),
          catchError((error) => {
            return of(epsTenantActions.epsTenantError());
          })
        );
      })
    )
  );

  epsStoreParkingRequestReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsStoreParkingRequestReservation),
      map((action) => {
        const i = action.parkingRequest.reservation_request.find((r) => {
          if (r.reservation_id) {
            return r;
          }
        });
        if (i == undefined) {
          return epsTenantActions.epsStoreReservationDone({
            reservationDone: false,
          });
        }
        return epsTenantActions.epsStoreReservationDone({
          reservationDone: true,
        });
      })
    )
  );

  epsConfirmAndPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(epsTenantActions.epsConfirmAndPay),
      withLatestFrom(this.store.select("tenant")),
      switchMap(([action, state]) => {
        const EpsTenantParkingRequestSrv =
          this.epsServices.confirm_and_pay_reservations(
            state.parkingRequestReservationStatus
          );
        return EpsTenantParkingRequestSrv.pipe(
          map((parkingRequest) => {
            let kos = { ...parkingRequest };
            kos.reservation_request = [];
            let kosSelectedUsers = [];
            parkingRequest.reservation_request.forEach((r) => {
              if (r.status != "ok") {
                kos.reservation_request.push({ ...r });
                const userKo = state.selectedUsers.find(
                  (u) => u.mail == r.vehicle_owner_id
                );
                kosSelectedUsers.push(userKo);
              }
            });
            if (kos.reservation_request.length > 0) {
              return epsTenantActions.epsStoreParkingRequestConfimrKo({
                parkingRequestConfirmationStatus: parkingRequest,
                parkingRequestNotConfirmed: kos,
                kosSelectedUsers,
              });
            } else {
              return epsTenantActions.epsStoreParkingRequestConfimrOk({
                parkingRequestConfirmationStatus: parkingRequest,
              });
            }
          }),
          catchError((error) => {
            return of(epsTenantActions.epsTenantError());
          })
        );
      })
    )
  );
}
