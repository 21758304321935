<h2 class="titleDel" mat-dialog-title> {{'MAPP-VMS-VISITOR-DELETE.TITLE' | translate}} </h2>
<mat-dialog-content class="deleteVisitDialog">
    {{'MAPP-VMS-VISITOR-DELETE.TEXT' | translate}}    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="deleteVisitButton" mat-button mat-dialog-close>{{'MAPP-VMS-VISITOR-DELETE.CANCEL' | translate}} </button>
    <button class="deleteVisitButton" (click)="deleteVisitor()" mat-button>{{'MAPP-VMS-VISITOR-DELETE.DELETE' | translate}} </button>
</mat-dialog-actions>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

