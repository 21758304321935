export class Report {
    open_year: number;
    closed_year: number;
    open_month: number;
    closed_month: number;
    open_week: number;
    closed_week: number;
    
    constructor() {
    }
}

