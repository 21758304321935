export const MICROAPPFAKEDATABASE = {
    users: [
        {
            uid: "100",
            userName: "generic",
            userPassword: "generic",
            firstName: "generic Fake",
            lastName: "generic Fake",
            mail: "generic@user.com",
            sites: [
                {
                    uid: "2",
                    name: "Generic Build",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                }

            ],
            roles: [
                {
                    uid: "GENERIC1",
                    name: "GENERIC1",
                }
            ]
        },

        {
            sid: "1000",
            username: "genericuser",
            password: "genericpass",
            description: "A Generic Test User to add to the current database",
            fk_rol: "superadmin"
        }
    ],
    microappSecurityTree: {
        'generic': {
            'access': {
                'USER': true,
                'ADMIN': true,
            },
            'genericFunctionality1': {
                'GENERIC1': true,
            },
            'genericFunctionality2': {
                'ADMIN': true
            }
        }
    },
    generics: [
        {
            sid: "1",
            name: "generic1"
        },
        {
            sid: "2",
            name: "generic2"
        },
        {
            sid: "3",
            name: "generic3"
        },
    ]
}