import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChargesService } from '../../../client/charges.service';
import { DTOTarif } from '../../../client/dto/tarif.dto';
import { TarifsService } from '../../../client/tarifs.service';
import { VOParkingPass } from '../../../client/vo/parking-passes.vo';
import { DTOTariffPass } from '../../../client/dto/parking-passes.dto';
import { UserService } from '../../../client/user.service';

@Component({
  selector: 'microapp-hvac-facility-pases',
  templateUrl: 'microapp-hvac.facility-pases-hvac.component.html',
  styleUrls: ['microapp-hvac.facility-pases-hvac.component.css']
})
export class FacilityPasesComponent {

  search: string;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.tenantSource) {
      this.tenantSource.paginator = mp;
    }
  }

  displayedColumns: string[] = ['tenants', 'cuc', 'cum', 'actions'];
  tenantSource = new MatTableDataSource([]);
  tarifList: DTOTarif[];
  loading: boolean = true;


  constructor(private translate: TranslateService,
    private auth: AuthenticationService,
    private chargesService: ChargesService,
    private tarifService: TarifsService,
    private userService: UserService,
    private _snackBar: MatSnackBar) {

    //obtain all the eps tarifs to show at the selection combos when editing
    this.tarifService.findHVACTarifs().subscribe(tarifs => {
      this.tarifList = tarifs;
    });
    //obtain all the tenants passes (tarif-pass association)
    this.getDataTenants();
  }

  /**
   * Event produced when the combo box has been changed for a certain parking pass
   * @param element <DTOParkingPass> the DTO that has changed
   * @param selection  the tarif id assigned
   */
  onTarifSelected(element: DTOTariffPass, selection) {
    //setting the tarif selected
    element.tarif = this.tarifList.find(tarif => tarif.tarif_id === selection.value);
  }

  /**
   * Obtain the Passes for all the tenants
   */
  getDataTenants() {
    this.tenantSource.sort = this.sort;
    // this.tenantSource.paginator = this.paginator;

    let datasource: VOParkingPass[] = [];
    let promises = [];

    this.userService.findAllTenants().then(tenants => {
      
      tenants.forEach(tenant => {
        let promise = this.chargesService.getDataPasesForTenant(tenant).toPromise();
        promises.push(promise);
        promise.then(passes => {
          let parkingPass: VOParkingPass = new VOParkingPass();
          parkingPass.dtos = passes;
          parkingPass.tenant = tenant;
          passes.forEach(pass => { if (pass.tarif === null) { pass.tarif = new DTOTarif() } });
          //Mapping HVAC CODES
          parkingPass.fan = passes.filter(pass => pass.application_charge_code == ChargesService.CODE_FAN)[0];
          parkingPass.hvac = passes.filter(pass => pass.application_charge_code == ChargesService.CODE_HVAC)[0];

          datasource.push(parkingPass);
        });
      });
      //waiting for all promises
      Promise.all(promises).then(values => {
        this.tenantSource.data = datasource;
        this.loading = false;
        this.tenantSource.filterPredicate = ((data, filter) => {
          if (data.tenant.name.toLowerCase().includes(filter)) {
            return true;
          }
          if (data.hvac.tarif.tarif_name && data.hvac.tarif.tarif_name.toLowerCase().includes(filter)) {
            return true;
          }
          if (data.fan.tarif.tarif_name && data.fan.tarif.tarif_name.toLowerCase().includes(filter)) {
            return true;
          }
          return false;
        });
      });
    });

  }

  /**
    * @name applyFilter
    * @description apply a filter to the table
    * @param {string} filterValue the value of the filter
    */
  searchText(filterValue) {
    this.tenantSource.filter = filterValue.trim().toLowerCase();
  }

  /** set a row editable */
  setEditable(element: VOParkingPass) {
    element.flag_editable = true;
  }

  /** save the changes */
  saveChanges(element: VOParkingPass) {
    this.chargesService.updatePasses(element.tenant, element.dtos).subscribe(data => {
      element.flag_editable = false;
    });
  }

}

