import { Injectable } from "@angular/core";
import { FbsConfig } from "../../lib/microapp-facilitiesbooking-config";
import { Adapter } from "../microapp-fbs-adapter";
import { FbsResourceAdapter } from "./microapp-fbs-resource-adapter";
import { MicroappFbsResourceListModel } from "./microapp-fbs-resource-list";

@Injectable({
  providedIn: "root",
})
export class FbsResourceListAdapter implements Adapter<MicroappFbsResourceListModel> {

  constructor(private fbsConfig: FbsConfig,
    private facilityAdapter: FbsResourceAdapter,
  ) { }

  adapt(item: MicroappFbsResourceListModel, checkStartDate: Date, checkEndDate: Date, referenceNumber: string[]): MicroappFbsResourceListModel {
    let resourceList = new MicroappFbsResourceListModel;
    resourceList.grouped_resources = [];
    resourceList.resources = item.resources.map((oneData) => this.facilityAdapter.adapt(oneData, checkStartDate, checkEndDate, referenceNumber));
    resourceList.grouped_as_one_resources = item.grouped_as_one_resources;

    if (resourceList.grouped_as_one_resources)
      resourceList.grouped_as_one_resources.forEach(group => {
        group.resources.forEach(rid =>{
          let the_resource = {...resourceList.resources.find(resource => resource.resourceId == ""+rid)};
          the_resource.resourceGroup = group.resource_group_id.toString();
          the_resource.name = group.resource_group_name;
          the_resource.description = group.resource_group_description;
          the_resource.chargeType = group.charge_type;
          the_resource.maxParticipants = +group.capacity;
          the_resource.type = group.type;
          resourceList.grouped_resources.push(the_resource);
        });
      })

    return resourceList;
  }
}
