export class MicroappVmsVisitVisitor{
    id: number;
    tenant_id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    phone: string;
    license_plate: string;
    iu: string;
    complimentary_parking: boolean;
    about: string;
    created: Date;
    vehicle_type;
}