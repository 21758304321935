import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
  })
  export class MailService {
   
    constructor() {
    }
    resolveFittingout():any{
      console.log('resolveFittingout');
    }

    updateFittingout():any{
      console.log('updateFittingout');
    }
    createFittingout():any{
      console.log('createFittingout');
    }
   
}