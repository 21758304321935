import { EpsParkingRequest } from "./microapp-eps.parking-request.model";

export class EpsTenantParkingRequest {
    tenant_id: string;
    from_date: Date;
    to_date: Date;
    booked_by: string;
    is_recurrent: boolean;
    source_system: string;
    user_reservation_type: string;
    reservation_request: EpsParkingRequest[];
}
