import { User } from "@empusa/empusa-core";
import { createReducer, on } from "@ngrx/store";
import { EpsSpotType } from "../../../lib/model/microapp-eps.spot-type.model";
import { EpsVehicleType } from "../../../lib/model/microapp-eps.vehicle-type.model";
import { EpsReservationType } from "../../../lib/model/reservation/microapp-eps.reservation-type.model";
import { EpsMasterConfig } from "../../model/cfg/microapp-eps.cfg.model";
import { EpsParkingSize } from "../../model/microapp-eps.parking-size.model";
import { EpsTenantQuotas } from "../../model/microapp-eps.tenan-quote.model";
import { ChargeToOption } from "../../model/reservation/microapp-eps.parking-request.model";
import { EpsTenantParkingRequest } from "../../model/reservation/microapp-eps.tenant-parking-request.model";
import * as epsTenantActions from './microapp-eps.tenant.actions';


export interface EpsTenantState {
    masterConfig: EpsMasterConfig;
    loading: boolean;
    selectedUsers: User[];
    //Parking request from step 1->2
    parkingRequest: EpsTenantParkingRequest;
    //Parking request with pass information from step 2 -> 3
    parkingRequestPasses: EpsTenantParkingRequest;
    parkingRequestReservationStatus: EpsTenantParkingRequest;
    //Parking request with confirmation information for step 4
    parkingRequestConfirmationStatus: EpsTenantParkingRequest;
    spotTypes: EpsSpotType[];
    parkingVehicleTypes: EpsVehicleType[];
    reservationTypes: EpsReservationType[];
    tenantQuotas: EpsTenantQuotas;
    reservationDone: boolean;
    paymentDone: boolean;
    supererror: boolean;
    paypalEnabledForThisTenant:boolean;
    nonEntitledEnabledForThisTenant:boolean;
    parkingSize: EpsParkingSize[];
}


const initialState = {
    loading: false,
    masterConfig: undefined,
    selectedUsers: [],
    parkingRequest: undefined,
    parkingRequestPasses: undefined,
    parkingRequestReservationStatus: undefined,
    parkingRequestConfirmationStatus: undefined,
    spotTypes: [],
    parkingVehicleTypes: [],
    reservationTypes: [],
    tenantQuotas: undefined,
    reservationDone: false,
    paymentDone: false,
    supererror: false,
    paypalEnabledForThisTenant: false,
    nonEntitledEnabledForThisTenant:false,
    parkingSize:[]
}

const _counterReducer = createReducer(
    initialState,
    on(epsTenantActions.epsTenantResetSteps, (state, action) => ({
        ...state,
        loading: false,
        selectedUsers: [],
        parkingRequest: undefined,
        parkingRequestPasses: undefined,
        parkingRequestReservationStatus: undefined,
        parkingRequestConfirmationStatus: undefined,
        reservationDone: false,
        paymentDone: false,
    })),
    on(epsTenantActions.epsTenantResetAvailabilityAndPayment, (state, action) => ({
        ...state,
        loading: false,
        parkingRequestReservationStatus: undefined,
        parkingRequestConfirmationStatus: undefined,
        reservationDone: false,
        paymentDone: false,
    })),
    on(epsTenantActions.epsGetMasterData, (state, action) => ({
        ...state,
        loading: true,
        paymentDone: false,
        supererror: false,
    })),
    on(epsTenantActions.epsStoreMasterData, (state, action) => ({
        ...state,
        spotTypes: action.spotTypes,
        parkingVehicleTypes: action.parkingVehicleTypes,
        reservationTypes: action.reservationTypes,
        tenantQuotas: action.tenantQuotas,
        masterConfig: action.masterConfig,
        paypalEnabledForThisTenant: action.paypalEnabledForThisTenant,
        nonEntitledEnabledForThisTenant:action.nonEntitledEnabledForThisTenant,
        parkingSize: action.parkingSize,
        loading: action.loading,
    })),
    on(epsTenantActions.epsStoreMasterConfig, (state, action) => ({
        ...state,
        masterConfig: action.masterConfig,
    })),
    on(epsTenantActions.epsTenantError, (state, action) => ({
        ...state,
        loading: false,
        supererror: true
    })),
    on(epsTenantActions.epsStoreSelectedEmployes, (state, action) => ({
        ...state,
        loading: false,
        selectedUsers: action.users
    })),
    on(epsTenantActions.epsStoreParkingRequests, (state, action) => ({
        ...state,
        loading: false,
        parkingRequest: action.parkingRequest
    })),
    on(epsTenantActions.epsStoreParkingPasses, (state, action) => ({
        ...state,
        parkingRequestPasses: action.parkingRequest,
    })),
    on(epsTenantActions.epsDoReservation, (state, action) => ({
        ...state,
        reservationDone: false,
        loading: true,
        paymentDone: false,
        parkingRequestReservationStatus: undefined
    })),
    on(epsTenantActions.epsStoreParkingRequestReservation, (state, action) => ({
        ...state,
        loading: false,
        parkingRequestReservationStatus: action.parkingRequest
    })),
    on(epsTenantActions.epsStoreReservationDone, (state, action) => ({
        ...state,
        loading: false,
        reservationDone: true,
    })),
    on(epsTenantActions.epsConfirmAndPay, (state, action) => ({
        ...state,
        parkingRequestConfirmationStatus: [],
        paymentDone: false,
        loading: true,
    })),
    on(epsTenantActions.epsStoreParkingRequestConfimrOk, (state, action) => ({
        ...state,
        loading: false,
        paymentDone: true,
        parkingPassFilled: false,
        reservationDone: false,
        parkingRequest: undefined,
        selectedUsers: [],
        parkingRequestPasses: undefined,
        parkingRequestReservationStatus: undefined,
        parkingRequestConfirmationStatus: action.parkingRequestConfirmationStatus
    })),

    on(epsTenantActions.epsStoreParkingRequestConfimrKo, (state, action) => ({
        ...state,
        loading: false,
        paymentDone: true,
        selectedUsers: action.kosSelectedUsers,
        parkingRequest: action.parkingRequestNotConfirmed,
        parkingRequestPasses: action.parkingRequestNotConfirmed,
        parkingRequestReservationStatus: action.parkingRequestNotConfirmed,
        parkingRequestConfirmationStatus: action.parkingRequestConfirmationStatus
    })),
    on(epsTenantActions.epsSelectParkingPassError, (state, action) => ({
        ...state,
        parkingRequestPasses: undefined,
    })),
    on(epsTenantActions.epsSetReservationDoneStatus, (state, action) => ({
        ...state,
        reservationDone: action.reservationDone,
    })),
    on(epsTenantActions.epsChangeChangeTo, (state, action) => ({
        ...state,
        parkingRequestReservationStatus: changeChargeTo(state, action.reservarion_id, action.charge_to)
    }))
);

export function epsTenantCounterReducer(state, action) {
    return _counterReducer(state, action);
}

function changeChargeTo(previousState: EpsTenantState, reservartion_id: number, charge_to: ChargeToOption) {
    let parkingRequestReservationStatusClone =  Object.assign({}, previousState.parkingRequestReservationStatus);
    parkingRequestReservationStatusClone.reservation_request = previousState.parkingRequestReservationStatus.reservation_request.map(reservation => {
        let cloneReservartion = Object.assign({}, reservation);
        if (cloneReservartion.reservation_id == reservartion_id) {
            cloneReservartion.charge_to = charge_to;
        }
        return cloneReservartion;
    });
    return parkingRequestReservationStatusClone;
}