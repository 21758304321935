import { EpsSpotType } from "../microapp-eps.spot-type.model";
import { EpsVehicleType } from "../microapp-eps.vehicle-type.model";
import { EpsReservationType } from "./microapp-eps.reservation-type.model";

export class Reservation {
    id: number;
    tenant_id: string;
    tenant_name:string;
    from_date: Date;
    to_date: Date;
    license_plate: string;
    vehicle_owner_id: string;
    vehicle_owner_name: string;
    vehicle_iu: string;
    price: number;
    units: number;
    charge_type: string;
    charge_id: string;
    charge_to: string;
    status: string;
    status_code: string;
    create_date: Date
    spot_type_code: string;
    reservation_tariffication_type: string;
    reservation_type: EpsReservationType;
    vehicle_type: EpsVehicleType
    spot_type: EpsSpotType;
    user_reservation_type: string;
    source_system: string;
    is_recurrent: boolean;
    recurrent_status_code: string;
    recurrent_child: number;
    recurrent_done: string;
    recurrent_attempts: number;
    booked_by: string;
}
