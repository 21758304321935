import { EpsVehicleType } from "../microapp-eps.vehicle-type.model"
import { EpsReservationType } from "../reservation/microapp-eps.reservation-type.model";
import { EpsSpotType } from "../microapp-eps.spot-type.model";

export class EpsAssignedChargeType {
    vehicle_type: EpsVehicleType;
    spot_type: EpsSpotType;
    reservation_type: EpsReservationType;
    charge_type: string;
    vehicle_spottype_reservationtype_id: number;
    description_charge: string;
}
