import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormGroupDirective } from '@angular/forms';
import { User } from '@empusa/empusa-core';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../../lib/store/tenant/microapp-eps.tenant.actions';
import { select, Store } from '@ngrx/store';
import { selectPaymentDone, selectSelectedUsers } from '../../../../lib/store/tenant/microapp-eps-tenan.selectors';

@Component({
  selector: 'empusa-microapp-eps-add-visits',
  templateUrl: './add-visits.component.html',
  styleUrls: ['./add-visits.component.css']
})
export class AddVisitsComponent implements OnInit {

  visitorsFormGroup: FormGroup;

  selectedUsers: User[] = [];
  selectedUsersDataSource: MatTableDataSource<User> = new MatTableDataSource();
  selectedUsersDisplayedColumns = ['name', 'mail', 'actions'];

  constructor(private formBuilder: FormBuilder,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>) { }

  ngOnInit(): void {
    this.visitorsFormGroup = this.formBuilder.group({
      surname: [null, Validators.required],
      givenName: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email]],
    });
    this.selectedUsersDataSource.data = this.selectedUsers;
    this.store.pipe(select(selectPaymentDone)).subscribe(pdone => {
      if (pdone == true) {
        this.store.pipe(select(selectSelectedUsers)).subscribe(users => {
          this.selectedUsers = [];
          if (users && users.length > 0) {
            users.forEach(us => {
              this.selectedUsers.push(us);
            })
          }
        });
      }
      this.selectedUsersDataSource.data = this.selectedUsers;
    })
  }

  addVisitor(visitorsFormGroup, visitorForm: FormGroupDirective) {
    if (this.visitorsFormGroup.valid) {
      const newUser = new User();
      newUser.surname = this.visitorsFormGroup.value.surname;
      newUser.givenName = this.visitorsFormGroup.value.givenName;
      newUser.mail = this.visitorsFormGroup.value.mail;
      this.selectedUsers.push(newUser);
      this.selectedUsersDataSource.data = this.selectedUsers;
      this.updateEpsStoreSelectedEmployees(this.selectedUsers);
      visitorForm.resetForm();
      visitorsFormGroup.reset();
    }
  }

  deleteVisitor(user: User) {
    this.selectedUsers = this.selectedUsers.filter(item => item !== user);
    this.selectedUsersDataSource.data = this.selectedUsers;
    this.updateEpsStoreSelectedEmployees(this.selectedUsers);
  }

  updateEpsStoreSelectedEmployees(users: User[]) {
    const usersClone = Object.assign([], users);
    this.store.dispatch(epsTenatActions.epsStoreSelectedEmployes({ users: usersClone }));
  }

}
