<div *ngIf="parkingDataLoaded" class="generalCard">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filters">  {{'MAPP-EPS.GENERIC.FILTER' | translate}}
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>

            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="applyGenericFilter($event.target.value)" placeholder=" {{'MAPP-EPS.QUOTAS.FILTER' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>

        <!-- HEADER-->
        <div class="header">
            <div class="lotsInHeader">
                <div style="display: inherit;" *ngFor="let spot_type of parkingSize;let spot_type_index=index">
                    <div class="nonEntitledPool" *ngFor="let vehicle_type of spot_type.vehicle_type">
                        <mat-icon *ngIf="spot_type.spot_type_id == 2">{{vehicle_type.vehicle_icon}}</mat-icon>
                        <span style="font-size: 15px;" *ngIf="spot_type.spot_type_id == 2">{{'MAPP-EPS.QUOTAS.NONENTITLED.POOL' | translate}} {{vehicle_type.can_assign}}</span>
                    </div>
                </div>
            </div>
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
            <button mat-button class="buttonExpandFil" (click)="getInfo()">
                <mat-icon class="expandFil" matSuffix>autorenew</mat-icon>
            </button>
        </div>

        <!-- TABLE -->
        <table mat-table [dataSource]="tenant_quotas_data" id="tablaQuotas" matSort class="mat-elevation-z8"
            style="width: 100%;">
            <ng-container matColumnDef='tenant_name'>
                <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'MAPP-EPS.QUOTAS.TNAME' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container *ngFor="let spot_type of parkingSize;let spot_type_index=index">
                <ng-container *ngFor="let vehicle_type of spot_type.vehicle_type"
                    matColumnDef='{{spot_type.spot_type_id}}{{vehicle_type.vehicle_type_id}}'>
                    <th mat-header-cell *matHeaderCellDef>
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <mat-icon>{{vehicle_type.vehicle_icon}}</mat-icon>
                            <!-- <span style="font-size: 15px;" *ngIf="spot_type.spot_type_id == 2" [matTooltip]="'Pool of lots to assign: '+vehicle_type.can_assign">({{vehicle_type.can_assign}})</span> -->
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="checkEnabledNonEntitledAssociation(element) || spot_type.spot_type_id == 1">{{findTenantData(element, spot_type.spot_type_id, vehicle_type.vehicle_type_id)}} </span>
                        <span *ngIf="!checkEnabledNonEntitledAssociation(element) && spot_type.spot_type_id != 1">--</span>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef='non_entitled_permission'>
                <th mat-header-cell *matHeaderCellDef>{{'MAPP-EPS.QUOTAS.ENABLED.NONENTITLED' | translate}}</th>
                <td mat-cell *matCellDef="let element"> 
                     <mat-icon *ngIf="checkEnabledNonEntitledAssociation(element)">done</mat-icon>
                     <mat-icon *ngIf="!checkEnabledNonEntitledAssociation(element)">cancel</mat-icon>
                    </td>
            </ng-container>

            <ng-container matColumnDef='total_spaces'>
                <th mat-header-cell *matHeaderCellDef>{{'MAPP-EPS.QUOTAS.TOTALOTS' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{totalTenantSpaces(element)}} </td>
            </ng-container>


            <ng-container matColumnDef='actions'>
                <th mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.QUOTAS.ACTIONS' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <button matTooltip="{{'MAPP-EPS.QUOTAS.EDITTOOLTIPPARKING' | translate}}" mat-icon-button (click)="edit_quotas(element)">
                        <mat-icon class="icActions">edit</mat-icon>
                    </button>
                    <button matTooltip="{{'MAPP-EPS.QUOTAS.DELETETOOLTIPPARKING' | translate}}" mat-icon-button (click)="delete_quotas(element)">
                        <mat-icon class="icActions">
                            delete</mat-icon>
                    </button>
                </td>
            </ng-container>


            <ng-container *ngIf="first_header">
                <ng-container matColumnDef="header-space">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
                </ng-container>
                <ng-container *ngFor="let spot_type of parkingSize">
                    <ng-container matColumnDef="{{spot_type.spot_type_id}}">
                        <th class="spotHeaderTable" mat-header-cell *matHeaderCellDef
                            [attr.colspan]="span_first_header">
                            {{spot_type.spot_short_desc}}
                        </th>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="headerDisplayedColumns">
                </tr>
            </ng-container>
            <tr mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator class="paginator" [pageSizeOptions]="[10,20,50,100]"></mat-paginator>
    </mat-drawer-container>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>