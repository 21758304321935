import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, } from 'rxjs';
import * as vmsReducer from '../../lib/microapp-vms.reducer';
import * as addressBookActions from '../address-book/store/microapp-vms-address-book.actions';
import { Store, select } from '@ngrx/store';
import { AuthenticationService } from '@empusa/empusa-core';
import { selectVisitorListLoadedOrError } from './store/microapp-vms-address-book-selectors';
import { tap, filter, first, finalize } from 'rxjs/operators';
import { MicroappVmsVisitorFilter } from '../model/microapp-vms-visitor-filter.model';

@Injectable()
export class MicroappVmsAddressBookResolver implements Resolve<boolean>{
    loading = false;

    constructor(private store: Store<vmsReducer.MicroappVmsStates>,
        private router: Router,
        private authservice: AuthenticationService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        console.log("AddressBookVisitorListResolver");
        this.store.dispatch(addressBookActions.resetAddressBook());
        return this.store
            .pipe(
                select(selectVisitorListLoadedOrError),
                tap(visitorsLoadedOrError => {
                    if (!this.loading && !visitorsLoadedOrError) {
                        if (this.authservice.getCurrentUser()) {
                            this.loading = true;
                            console.log("AddressBookVisitorListResolver cargo");
                            let filter = new MicroappVmsVisitorFilter();
                            const userSite = this.authservice.getCurrentUser().sites.find(site => site.uid == this.authservice.getCurrentSite().uid);
                            filter.tenants = userSite.tenants;
                            this.store.dispatch(addressBookActions.searchingVisitors({ filter, loggedUser: this.authservice.getCurrentUser() }))
                        } else {
                            this.router.navigateByUrl('/');
                        }
                    }
                    console.log("AddressBookVisitorListResolver end");
                }),
                filter(visitorsLoadedOrError => visitorsLoadedOrError),
                first(),
                finalize(() => this.loading = false)
            );
    }

}