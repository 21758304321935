import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MicroappEpsComponent } from './microapp-eps.home.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReservationsTenantComponent } from './reservation/tenant-reservation/microapp-eps.tenant-reservation-eps.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-eps.mat-spinner-overlay.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MicroappEpsQuotasComponent } from './management/quotas-list/microapp-eps-quotas.component';
import { StoreModule } from '@ngrx/store';
import * as epsAppReducer from '../lib/store/microapp-eps.app-reducer';
import { EffectsModule } from '@ngrx/effects';
import { MicroappEpsEffects } from '../lib/store/microapp-eps.effects';
import { MicroappEpsQuotasEditComponent } from './management/quotas-edit/microapp-eps-quotas-edit.component';
import { MicroappEpsQuotasDeleteComponent } from './management/quotas-delete/microapp-eps-quotas-delete.component';
import { MicroappEpsChargeListComponent } from './management/charges/microapp-eps-charge-list.component';
import { MicroappEpsTariffsComponent } from './management/tariffs/microapp-eps-tariffs.component';
import { MicroappEpsTariffUpdateComponent } from './management/tariff-update/microapp-eps-tariff-update.component';
import { ChargeTypeFormComponent } from './management/charges/charge-type-form/charge-type-form.component';
import { MicroappEpsSelectEmployeesComponent } from './reservation/steps/select-employees/microapp-eps-select-employees.component';
import { MicroappEpsSelectgReservationTypeComponent } from './reservation/steps/select-reservation-type/microapp-eps-selectg-reservation-type.component';
import { MicroappEpsTenantEffects } from '../lib/store/tenant/microapp-eps-tenant.effects';
import { MicroappEpsAvailabilityComponent } from './reservation/steps/availability/microapp-eps-availability.component';
import { MicroappEpsPaymentStatusComponent } from './reservation/steps/payment-status/microapp-eps-payment-status.component';
import { MicroappEpsTariffsAssignedComponent } from './management/tariff-assigned/microapp-eps-tariff-assigned.component';
import { MicroappEpsTenantSelectComponent } from './common/tenant-select/microapp-eps-tenant-select.component';
import { AddVisitsComponent } from './reservation/steps/add-visits/add-visits.component';
import { MakeReservationStepperComponent } from './reservation/make-reservation-stepper/make-reservation-stepper.component';
import { MiniReservationsInvoiceComponent } from './reservation/generics/mini-reservations-invoice/mini-reservations-invoice.component';
import { MicroappEpsQuotasSpacesComponent } from './management/quotas-spaces/microapp-eps-quotas-spaces.component';
import { ReservationTableComponent } from './reservation/generics/reservation/microapp-eps.reservation-table.component';
import { EmployeeReservationsEps } from './reservation/steps/select-employees/employee-reservation/microapp-eps.employee-reservation.component';
import { MicroappEpsParkingSizeEditComponent } from './management/parking-size-edit/microapp-eps-parking-size-edit.component';
import { MicroappEpsPaypalListComponent } from './management/paypal/microapp-eps.paypal-list/microapp-eps-paypal-list.component';
import { DialogStatusHelpComponent } from './common/dialog-status-help/dialog-status-help.component';
import { MatTableExporterModule } from 'mat-table-exporter';


const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};



@NgModule({
  entryComponents: [
    EmpusaQuestionDialog,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule,
    MatDialogModule,
    HttpClientModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatListModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableExporterModule,
    StoreModule.forRoot(epsAppReducer.microappEpsReducer),
    EffectsModule.forRoot([MicroappEpsEffects, MicroappEpsTenantEffects]),
    RouterModule.forChild([
      {
        path: '', component: MicroappEpsComponent
      }
    ]),
    NgxMaskModule.forRoot(maskConfigFunction),
  ],
  declarations: [
    MicroappEpsComponent,
    ReservationsTenantComponent,
    MatSpinnerOverlayComponent,
    MicroappEpsQuotasComponent,
    MicroappEpsQuotasEditComponent,
    MicroappEpsQuotasDeleteComponent,
    MicroappEpsChargeListComponent,
    MicroappEpsTariffsComponent,
    MicroappEpsTariffUpdateComponent,
    ChargeTypeFormComponent,
    MicroappEpsSelectEmployeesComponent,
    MicroappEpsSelectgReservationTypeComponent,
    MicroappEpsAvailabilityComponent,
    MicroappEpsPaymentStatusComponent,
    MicroappEpsTariffsAssignedComponent,
    MicroappEpsTenantSelectComponent,
    AddVisitsComponent,
    MakeReservationStepperComponent,
    MiniReservationsInvoiceComponent,
    MicroappEpsQuotasSpacesComponent,
    ReservationTableComponent,
    EmployeeReservationsEps,
    MicroappEpsParkingSizeEditComponent,
    MicroappEpsPaypalListComponent,
    DialogStatusHelpComponent

  ],
  providers: [
    DatePipe,
    MatSnackBar
  ]
})
export class EpsModule {

  constructor(private translationLoader: TranslationLoaderService) {

  }

}
