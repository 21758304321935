<!-- **************** DIALOG ENVIAR COMENTATIO******************** -->

<h3 mat-dialog-title class="titleDetail"> {{'MAPP-FAULTREPORT.LIST.REPORT' | translate}}</h3>
<div mat-dialog-content>
    <mat-form-field class="textCommentary">
        <mat-label>{{'MAPP-FAULTREPORT.EDIT.COMMENTARY' | translate}}*</mat-label>
        <textarea class="areaCommentary" placeholder="{{'MAPP-FAULTREPORT.EDIT.INTRODUCE' | translate}}" matInput [(ngModel)]="comment"
            name="comment"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonCloseReport" mat-button color="primary" mat-dialog-close>
        {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-stroked-button color="primary" mat-dialog-close [disabled]="comment===''"
        [mat-dialog-close]="comment">{{'MAPP-FAULTREPORT.EDIT.CONFIRM' | translate}}</button>
</div>