import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectParkingConfirmationStatus } from '../../../../lib/store/tenant/microapp-eps-tenan.selectors';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../../lib/store/tenant/microapp-eps.tenant.actions';
import { MatTableDataSource } from '@angular/material/table';
import { EpsParkingRequest } from '../../../../lib/model/reservation/microapp-eps.parking-request.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'empusa-microapp-eps-payment-status',
  templateUrl: './microapp-eps-payment-status.component.html',
  styleUrls: ['./microapp-eps-payment-status.component.css']
})
export class MicroappEpsPaymentStatusComponent implements OnInit {

  requestsForInvoice: EpsParkingRequest[] = [];
  requestsForInvoiceYardi: EpsParkingRequest[] = [];
  requestsForInvoicePaypal: EpsParkingRequest[] = [];
  paypalEnabled:boolean;
  @Input() mode: 'employee' | 'visitor' = 'employee';
  constructor(private store: Store<microappEpsAppReducer.MicroappEpsStates>) { }

  ngOnInit(): void {
    this.store.pipe(select('tenant')).subscribe((data) => {
      if (data.parkingRequestConfirmationStatus && data.parkingVehicleTypes && data.parkingVehicleTypes.length > 0 &&
        data.parkingRequestConfirmationStatus.reservation_request && data.parkingRequestConfirmationStatus.reservation_request.length > 0) {
        this.requestsForInvoice = data.parkingRequestConfirmationStatus.reservation_request.map(request => {
          const icon = data.parkingVehicleTypes.find(item => item.vehicle_type_id === request.vehicle_type_id).vehicle_icon;
          return { ...request, vehicle_icon: icon };
        });
        this.requestsForInvoicePaypal = this.requestsForInvoice.filter(reservation => reservation.charge_to == 'User');
        this.requestsForInvoiceYardi =  this.requestsForInvoice.filter(reservation => reservation.charge_to == 'Tenant');
        this.paypalEnabled = data.paypalEnabledForThisTenant;
      }
    });
  }

}