import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EpsChargeType } from '../model/charges_and_tariffs/microapp-eps.charge-type.model';
import { EpsConfig } from '../microapp-eps-cfg';
import { EpsChargeTypeAdapter } from '../model/charges_and_tariffs/assign_tariff/microapp-eps.charge-type-assign.adapter';
import { EpsTarifAdapter } from '../model/charges_and_tariffs/assign_tariff/microapp-eps-tarif-assign.adapter';
import { EpsTariff } from '../model/charges_and_tariffs/microapp-eps.tariff.model';

@Injectable({
  providedIn: 'root',
})
export class MicroappEpsChargesService {

  constructor(private http: HttpClient,
    private chargeTypeAdapter: EpsChargeTypeAdapter,
    private epsTariffAdapter: EpsTarifAdapter,
    private epsConfig: EpsConfig) {
  }

  getChargeTypes(tenant_dn: string): Observable<EpsChargeType[]> {
    let id = encodeURIComponent(tenant_dn);
    //const tenant_dn = this.authservice.getCurrentUser().sites[0].tenants[0].dn;
    const url = this.epsConfig.BILLING_CONTEXT+"charges/tenant/"+id;

    var the_data = { tags: ["EPS"] };
    return this.http.post<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map((data) =>  data.charge_codes_mapping.map((oneData) => this.chargeTypeAdapter.adapt(oneData))));
  }

  getTariffs(): Observable<EpsTariff[]> {
    const url = this.epsConfig.BILLING_CONTEXT+"tarifs/filter/tag";
    var the_data = { tags: ["EPS"] };
    return this.http.post<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map((data) => data.tarifs.map((oneData) => this.epsTariffAdapter.adapt(oneData))));
  }

  assignTenant(tenant, chargeId, tariff) {
    let id = encodeURIComponent(tenant);
    const url = this.epsConfig.BILLING_CONTEXT+"charges/tenant/"+id+"/tarifs";
    if (tariff == 'unassign') tariff = '' 
    var the_data = {
      tags: ["EPS"],
      charges: [
        {
          chargeid: chargeId,
          tarifid: tariff,
        },
      ]
    };
    return this.http.put<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map(data =>  data ));
  }

}
