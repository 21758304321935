import * as logBookReducer from './logbook/store/microapp-vms-logbook.reducer';
import * as addressBookReducer from './address-book/store/microapp-vms-address-book.reducer';
import * as groupVisitMamageReducer from './group-visit-manage/store/microapp-vms-group-visit-manage.reducer';


import { ActionReducerMap } from '@ngrx/store';


export interface MicroappVmsStates {
    logBook: logBookReducer.LogBookState;   
    addressBook: addressBookReducer.AddressBookState
    groupVisitManage: groupVisitMamageReducer.manageGroupVisitState;
}

export const microappVmsReducer: ActionReducerMap <MicroappVmsStates> = {
    logBook: logBookReducer.counterReducer,
    addressBook: addressBookReducer.counterReducer,
    groupVisitManage: groupVisitMamageReducer.counterReducer,
};
