<div>
  <table mat-table [dataSource]="dataSource" matSort id='tablaList'>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-MANAGEMENT.TYPE' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{'MAPP-FBS-MANAGEMENT.' + element.type | translate}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-MANAGEMENT.RESOURCE' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-MANAGEMENT.LOCATION' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-MANAGEMENT.DESCRIPTION' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="capacity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-FBS-MANAGEMENT.CAPACITY' | translate}}</mat-header-cell>
      <mat-cell class="cellCapacity" *matCellDef="let element">
        {{element.type==='facility' || element.type==='groupfacility'?element.maxParticipants:element.availableResources}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{'MAPP-FBS-MANAGEMENT.STATUS' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{(element | groupStatus | translate)
            .replace("%1",element.availableResources)
            .replace("%2",element.unAvailableResources)
            .replace("%3",element.totalResources)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-MANAGEMENT.ACTIONS' | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button matTooltip="{{'MAPP-FBS-TOOLTIP.MANAGEMENT.DETAIL' | translate}}" mat-icon-button (click)="the_resource_detail(element)">
          <mat-icon class="icActions">info</mat-icon>
        </button>
        <button matTooltip="{{'MAPP-FBS-TOOLTIP.MANAGEMENT.EDIT' | translate}}" mat-icon-button (click)="the_resource_update(element)">
          <mat-icon class="icActions">edit</mat-icon>
        </button>
        <button matTooltip="{{'MAPP-FBS-TOOLTIP.MANAGEMENT.DELETE' | translate}}" mat-icon-button (click)="the_resource_delete(element)"
          *ngIf="element.statusId==fbsConfig.RESOURCE_STATUS_HIDDEN || element.type == fbsConfig.RESOURCE_TYPE_ATTR_GROUP">
          <mat-icon class="icActions">
            delete</mat-icon>
        </button>
        <button matTooltip="{{'MAPP-FBS-TOOLTIP.MANAGEMENT.UNAVALIBLE' | translate}}" mat-icon-button (click)="the_resource_set_unavailable(element)"
          *ngIf="element.statusId==fbsConfig.RESOURCE_STATUS_AVAILABLE && element.type != fbsConfig.RESOURCE_TYPE_ATTR_GROUP">
          <mat-icon class="icActions">cancel_presentation</mat-icon>
        </button>
        <button matTooltip="{{'MAPP-FBS-TOOLTIP.MANAGEMENT.AVALIBLRE' | translate}}" mat-icon-button (click)="the_resource_set_available(element)"
          *ngIf="element.statusId !== fbsConfig.RESOURCE_STATUS_AVAILABLE">
          <mat-icon class="icActions">add</mat-icon>
        </button>

      </mat-cell>
    </ng-container>
  </table>
</div>
<div class="button-newVisit">
  <button *ngIf="group_rooms_enabled" class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="new_grouped_facility()">
    <mat-icon>add </mat-icon>
    <span class='extend_text'>{{'MAPP-FBS-MANAGEMENT.NEW-FACILITY-GROUP' | translate}}</span>
  </button>
  <button class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="new_facility()">
    <mat-icon>add </mat-icon>
    <span class='extend_text'>{{'MAPP-FBS-MANAGEMENT.NEW-FACILITY' | translate}}</span>
  </button>

  <button class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="new_accessory()">
    <mat-icon>add </mat-icon>
    <span class='extend_text'>{{'MAPP-FBS-MANAGEMENT.NEW-ACCESSORY' | translate}}</span>
  </button>
</div>
<mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
