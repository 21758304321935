<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div>
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.FIRST-NAME' | translate}}
                            {{dynamicRequiredMark('first_name')}}</mat-label>
                        <input matInput [formControl]="first_name" [maxLength]="vmsConfig.SIZE_FIRST_NAME">
                        <mat-error *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)">
                            {{'MAPP-VMS-VISITOR-FORM.FIRST-NAME-ERROR' | translate}}</mat-error>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.LAST-NAME' | translate}}
                            {{dynamicRequiredMark('last_name')}}</mat-label>
                        <input matInput [formControl]="last_name" [maxLength]="vmsConfig.SIZE_LAST_NAME">
                        <mat-error *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)">
                            {{'MAPP-VMS-VISITOR-FORM.LAST-NAME-ERROR' | translate}}</mat-error>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.COMPANY' | translate}} {{dynamicRequiredMark('company')}}
                        </mat-label>
                        <input matInput [formControl]="company" [maxLength]="vmsConfig.SIZE_COMPANY">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.PHONE' | translate}} {{dynamicRequiredMark('phone')}}
                        </mat-label>
                        <input matInput [formControl]="phone" [maxLength]="vmsConfig.SIZE_PHONE">
                        <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                            {{'MAPP-VMS-VISITOR-FORM.PHONE-ERROR' | translate}}</mat-error>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.EMAIL' | translate}} </mat-label>
                        <input matInput [formControl]="email" (keypress)="onEmailKey()" (blur)="onEmailBlur()"
                            [maxLength]="vmsConfig.SIZE_EMAIL">
                        <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                            {{getEmailErrorMessage() | translate}}</mat-error>
                    </mat-form-field>
                </td>
                <!-- <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.EMAIL' | translate}}</mat-label>
                        <input matInput [formControl]="email" (keypress)="onEmailKey()" (blur)="onEmailBlur()"
                            [maxLength]="vmsConfig.SIZE_EMAIL">
                        <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                            {{getEmailErrorMessage() | translate}}</mat-error>
                    </mat-form-field>
                </td> -->
                <td *ngIf="vmsConfig.REQUEST_LICENSE_PLATE">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.LICENSE_PLATE' | translate}}
                            {{dynamicRequiredMark('license_plate')}}</mat-label>
                        <input matInput [formControl]="license_plate" [minLength]="vmsConfig.SIZE_LICENSE_PLATE_MIN"
                            [maxLength]="vmsConfig.SIZE_LICENSE_PLATE_MAX">
                        <mat-error *ngIf="license_plate.hasError('minlength')">
                            {{ 'MAPP-VMS-VISITOR-FORM.LICENSE_PLATE_MIN_LENGTH' | translate}}
                        </mat-error>
                        <mat-error *ngIf="license_plate.hasError('pattern')">
                            {{ 'MAPP-VMS-VISITOR-FORM.LICENSE_PLATE_PATTERN' | translate}}
                        </mat-error>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{'MAPP-VMS-VISITOR-FORM.IU' | translate}} {{dynamicRequiredMark('iu')}} </mat-label>
                        <input matInput [formControl]="iu" [minLength]="vmsConfig.SIZE_IU_MIN"
                            [maxLength]="vmsConfig.SIZE_IU_MAX">
                        <mat-icon matSuffix matTooltip="{{'MAPP-VMS-VISITOR-FORM.IU_TOOLTIP' | translate}}">
                            warning_amber</mat-icon>
                        <mat-error *ngIf="iu.hasError('minlength')">
                            {{ 'MAPP-VMS-VISITOR-FORM.IU_MIN_LENGTH' | translate}}
                        </mat-error>
                        <mat-error *ngIf="iu.hasError('maxlength')">
                            {{ 'MAPP-VMS-VISITOR-FORM.IU_MIN_LENGTH' | translate}}
                        </mat-error>
                    </mat-form-field>
                </td>
                <td style="vertical-align:middle">
                    <mat-label class="icon_label">{{ 'MAPP-VMS-VISITOR-FORM.VEHICLE_TYPE' | translate}}</mat-label>
                    <div>
                        <mat-icon *ngFor="let one_vehicle of available_vehicles" class="vehicle_type"
                            [ngClass]="{'vehicle_type_selected': vehicle_type===one_vehicle.vehicle_code}"
                            (click)="changeVehicle(one_vehicle.vehicle_code);">{{one_vehicle.vehicle_icon}}</mat-icon>
                    </div>
                </td>
            </tr>
            <tr>
            </tr>
        </table>
        <p>
            <mat-form-field class="example-textArea">
                <mat-label>{{'MAPP-VMS-VISITOR-FORM.ABOUT' | translate}}</mat-label>
                <textarea matInput [formControl]="about"></textarea>
            </mat-form-field>
        </p>
    </div>
    <div class="buttonsStep" *ngIf="mode=='create-visit'">
        <button type="submit" [disabled]="!formGroup.valid || !emailValidated" class="buttonStepNext" mat-raised-button
            color="primary">{{'MAPP-VMS-VISITOR-FORM.ADD' | translate}}</button>
        <button class="buttonStepClose" mat-raised-button
            (click)="onCancelAddVisitor($event)">{{'MAPP-VMS-VISITOR-FORM.CANCEL' | translate}}</button>
    </div>
    <div class="buttonEditVisitor" align="end" *ngIf="mode=='address-book'">
        <button class="editVisitButton" mat-raised-button
            (click)="onCancelAddVisitor($event)">{{'MAPP-VMS-VISITOR-EDIT.CANCEL' | translate}}</button>
        <button class="editVisitButton" type="submit"
            [disabled]="!formGroup.valid || !emailValidated || formGroup.pristine" mat-raised-button
            color="primary">{{'MAPP-VMS-VISITOR-EDIT.SAVE' | translate}}</button>
    </div>
</form>