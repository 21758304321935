import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Generic } from './dto/generic';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GenericService {
    context: string = "generics";

    constructor(private http: HttpClient, @Inject('environment') private environment) {
    }

    allGenerics(): Observable<any> {
        return this.http.get(`${this.environment.urlbase}${this.context}`).pipe(
            map(data => data)
        );
    }

    getGeneric(id: String): Observable<any> {
        return this.http.get(`${this.environment.urlbase}${this.context}/` + id).pipe(
            map(data => data)
        );
    }

    addGeneric(generic: Generic): Observable<any> {
        let body = JSON.stringify(generic);
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        return this.http.post(`${this.environment.urlbase}${this.context}`, body, { headers }).pipe(map(resp => resp));
    }

    updateGeneric(generic: Generic): Observable<any> {
        let body = JSON.stringify(generic);
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        return this.http.put(`${this.environment.urlbase}${this.context}/${generic.sid}`, body, { headers }).pipe(map(resp => resp));
    }

    deleteGeneric(sid: String): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        return this.http.delete(`${this.environment.urlbase}${this.context}/${sid}`, { headers }).pipe(map(resp => resp));
    }

}
