<!-- <empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay> -->
<h2 class="cabecera">
    <mat-icon class="iconoCabecera">home</mat-icon>
    <mat-label class="textCabecera"><b>/{{siteName}}</b> Home </mat-label>
</h2>

<div class="fondoModules">
    <div  style="display: flex; flex-wrap: wrap;" >
        <mat-selection-list class="selectionModuleList" [multiple]="false">

            <mat-list-option class="moduleListOption" *ngFor="let module of modules; let i = index"
                routerLinkActive="active" [routerLink]="['/'+module.path]">
                <div class="moduleList">
                    <div class="moduleTitle">
                        <span>{{module.description | translate}}</span>
                    </div>
                    <div class="moduleIcon">
                        <mat-icon class="icModule">{{module.icon}}</mat-icon>
                    </div>
                </div>
            </mat-list-option>
            <div *ngIf="isAdmin && incluideGrafana" class="moduleListOption">
                <a href="{{bmsGrafana}}" target="_blank">
                    <mat-list-option class="optionLink">
                        <div class="moduleList">
                            <div class="moduleTitle">
                                <span>BMS Dashboards</span>
                            </div>
                            <div class="moduleIcon">
                                <mat-icon class="icModule">trending_up</mat-icon>
                            </div>
                        </div>
                    </mat-list-option>
                </a>
            </div>

        </mat-selection-list>
    </div>
    <div style="justify-content: center; display: flex;">
        <a style="float: inline-start !important;" mat-button href="https://boustead.sg/privacy-policy"
            target="_blank">Privacy Policy</a>
        <button style="float: inline-start !important; margin-right: 5%;" mat-button (click)="openDialog()">Terms of
            Use</button>
    </div>
</div>
<!-- </div> -->