export class LogIssue {
    owner: string;
    date: string;
    field: string;
    new_value: string;
    
    constructor() {
    }
}

