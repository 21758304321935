import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empusa-dialog-status-help',
  templateUrl: './dialog-status-help.component.html',
  styleUrls: ['./dialog-status-help.component.css']
})
export class DialogStatusHelpComponent implements OnInit {
  url: string;
  constructor() { }

  ngOnInit(): void {
    this.url = 'assets/img/statusFloyd.png'
  }

}
