import { Pipe, PipeTransform } from '@angular/core';
import { MicroappFbsAccessoryGroupSlotsModel } from '../../client/dto/Microapp-fbs-accessory-group-slots';


@Pipe({
    name: 'accessorySlotStatus'
})
export class MicroAppFbsAccessorySlotStatusPipe implements PipeTransform {

    transform(slot: MicroappFbsAccessoryGroupSlotsModel): string {
        if (slot.slots.length>1) {
            return "MAPP-FBS-ACCESSORY-RESERVATION-SLOTS.STATUS_AVAILABLE";
        };
        if (slot.slots.length==1) {
            return "MAPP-FBS-ACCESSORY-RESERVATION-SLOTS.STATUS_ONE";

        };
        return "MAPP-FBS-ACCESSORY-RESERVATION-SLOTS.STATUS_NOT_RESERVABLE";
    }
}