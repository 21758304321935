import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsResourceSlotModel } from './faciliti-slots';
import { MicroappFbsAccessoryGroupSlotsModel } from './Microapp-fbs-accessory-group-slots';
import { MicroappFbsResourceModel } from './microapp-fbs-resource';
import { MicroappFbsTarif } from './microapp-fbs-tarif';

export class MicroappFbsResourceGroup {
  singleResourceId: string;
  resourceGroupId: string;
  type: string;
  //quantity: number;
  totalResources: number;
  //available: number;
  availableResources: number;
  availableResourcesToBook: number;
  hiddenResources: number;
  unAvailableResources: number;
  name: string;
  location: string;
  description: string;
  maxParticipants: number;
  canBookInSelectedPeriod: boolean;
  slots: MicroappFbsResourceSlotModel[];
  tarif: MicroappFbsTarif;
  chargeType: string;
  statusId: number;
  combined_slots: MicroappFbsAccessoryGroupSlotsModel[];
  resourcesIds: number[];
  image_urls: string[];

  constructor(private fbsConfig: FbsConfig){
    this.resourcesIds=[];
  }

  getResourcesIds(){
    return this.resourcesIds;
  }

  addResourceToResourcesID(resource: MicroappFbsResourceModel){
    this.resourcesIds.push(+resource.resourceId);
    //A pack of facilities must be resered all or nothing. so if one
    //   facility is not available, then can not be booked.
    if (resource.type == this.fbsConfig.RESERVATION_GROUP_ATTR_LABEL){
        if (!resource.canBookInSelectedPeriod){
          this.canBookInSelectedPeriod = false;
          this.availableResourcesToBook = 0;
        };

    }else{
      if (resource.canBookInSelectedPeriod){
        this.canBookInSelectedPeriod = true;
      }
    }
  }
}
