import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, Site, Tenant } from '@empusa/empusa-core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { MicroappFbsChargeType } from '../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsChargesService } from '../../lib/microapp-facilitiesbooking-charge.service';
import { EditTariffTypeComponent } from './editTarifType/microapp-fbs-tariff-editTariffType.component';
import { MicroappChargeTariffFilter } from './microapp-fbs-tariff-management.filter';
import { EditNewChargeComponent } from './newEdit/microapp-fbs-tariff-newEdit.component';

@Component({
  selector: 'empusa-microapp-fbs-tariff-management',
  templateUrl: './microapp-fbs-tariff-management.component.html',
  styleUrls: ['./microapp-fbs-tariff-management.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappFbsTariffManagementComponent implements OnInit {

  displayedColumns = ['chargeType', 'tariff', 'actions'];
  dataSource = new MatTableDataSource([]);
  tenant;
  userFilter = new MicroappChargeTariffFilter;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private fbsChargesService: MicroappFbsChargesService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  selectedTenant(theTenant) {

    //  this.chargeTarif(theTenant);
    this.tenant = theTenant;
    const chTypes = this.fbsChargesService.getChargeTypes(theTenant);
    const allTarifs = this.fbsChargesService.getTariffs();
    return forkJoin([chTypes, allTarifs]).subscribe(
      resultados => {


        this.dataSource.data = resultados[0];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.defineDataSourceFilter(this.dataSource);
      });
  }

  createOrEdit() {
    let dialogAssign = this.dialog.open(EditNewChargeComponent, {
      width: '680px',
      // data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {

    });
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: MicroappFbsChargeType, filter: string) => {
        const theFilter: MicroappChargeTariffFilter = JSON.parse(filter);
        let generalFilter = false;
        if (!theFilter) return true;
        //general Filter
        if (theFilter.general) {
          if (aGroup.application_charge_code.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.description.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.tarif.tarif_name.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;

        return generalFilter;
      }
    //rol filter


  }


  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;

    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  the_tariffType_update(resource) {
    let dialogAssign = this.dialog.open(EditTariffTypeComponent, {
      width: '350px',
      data:
      {
        resource: resource,
        tenant: this.tenant,
      }
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.selectedTenant(this.tenant);
    });
  }
}
