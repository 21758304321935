<mat-list>

   <mat-list-item class="listItemVisitor">
      <mat-icon class="icDetailsVisitor" mat-list-icon>event</mat-icon>
      <div class="principalEvent" mat-line>{{visit.start_time| date: localDateFormat:offset }} </div>
      <div class="secundaryEvent" mat-line>{{visit.start_time | date : 'HH:mm':offset}} -
         {{visit.end_time | date : 'HH:mm':offset}} </div>
   </mat-list-item>
   <mat-list-item class="listItemVisitor">
      <mat-icon class="icDetailsVisitor" mat-list-icon>location_on</mat-icon>
      <div class="principalLoacation" mat-line>{{visit.host.firstName}} {{visit.host.lastName}}</div>
      <div class="secundaryLoacation" mat-line>{{visit.tenant.name}}</div>
   </mat-list-item>

   <mat-list-item class="listItemVisitor">
      <mat-icon class="icDetailsVisitor" mat-list-icon>person</mat-icon>
      <div class="primaryLabel" mat-line>{{visit.first_name}} {{visit.last_name}}</div>
      <div class="secundaryLabel" mat-line>{{visit.company}} </div>
   </mat-list-item>
   <mat-list-item class="listItemVisitor">
      <div class="itemVisitor">
         <div class="primaryLabel" mat-line>
            <mat-icon class="icDetailsVisitorDoble" mat-list-icon>phone</mat-icon> {{visit.phone}}
         </div>
      </div>
      <div class="itemVisitor">
         <div class="primaryLabel" mat-line>
            <mat-icon class="icDetailsVisitorDoble" mat-list-icon>email</mat-icon>{{visit.email?visit.email:' -- '}}
         </div>
      </div>
   </mat-list-item>

   <mat-list-item class="listItemVisitor">
      <div class="itemVisitor" *ngIf="vmsConfig.REQUEST_LICENSE_PLATE">
         <div class="primaryLabel" mat-line>
            <mat-icon class="icDetailsVisitor" mat-list-icon>{{getVehicleIcon(visit.vehicle_type)}}</mat-icon>
            {{visit.license_plate}}
         </div>
      </div>
      <div class="itemVisitor" *ngIf='visit.visitor_id'>
         <div class="primaryLabel" mat-line>
            <mat-icon class="icDetailsVisitor" mat-list-icon>contactless</mat-icon> {{visit.iu}}
         </div>
      </div>
   </mat-list-item>

   <mat-list-item class="listItemVisitor">
    <div class="itemVisitor">
       <div class="primaryLabel" mat-line>
          <mat-icon class="icDetailsVisitorDoble" mat-list-icon>masks</mat-icon>
          <ng-container *ngIf="visit.result_check_mask == true"> {{'MAPP-VMS-SINGLE-VISIT-DETAIL.WEAR_MASK' | translate}} </ng-container>
          <ng-container *ngIf="visit.result_check_mask == false"> {{'MAPP-VMS-SINGLE-VISIT-DETAIL.NOT_WEAR_MASK' | translate}} </ng-container>
          <ng-container *ngIf="visit.result_check_mask == null"> {{'GENERICS.NO_DATA' | translate}} </ng-container>
       </div>
    </div>
    <div class="itemVisitor">
       <div class="primaryLabel" mat-line>
          <mat-icon class="icDetailsVisitorDoble" mat-list-icon>thermostat</mat-icon>
          <ng-container *ngIf="visit.result_check_temperature != null"> {{visit.result_check_temperature | temperature}} </ng-container>
          <ng-container *ngIf="visit.result_check_temperature == null"> {{'GENERICS.NO_DATA' | translate}} </ng-container>
       </div>
    </div>
  </mat-list-item>

</mat-list>
<mat-form-field class="textAreaVisitor">
   <mat-label>{{'MAPP-VMS-SINGLE-VISIT-DETAIL.ABOUT_VISIT' | translate}}</mat-label>
   <textarea matInput readonly>{{visit.about}}</textarea>
</mat-form-field>
<div class="checkDiv">
   <div class="checkStart">
      <mat-label class="check">{{'MAPP-VMS-SINGLE-VISIT-DETAIL.IN' | translate}}</mat-label>
      <mat-label class="hora"> {{(visit.first_in_time!==null) ? (visit.first_in_time | date : 'HH:mm': offset) :
         '--:--'}}
      </mat-label>
   </div>
   <!-- <p></p> -->
   <div class="checkEnd">
      <mat-label class="check">{{'MAPP-VMS-SINGLE-VISIT-DETAIL.OUT' | translate}}</mat-label>
      <mat-label class="hora"> {{(visit.last_out_time!==null) ? (visit.last_out_time | date : 'HH:mm': offset) :
         '--:--'}}
      </mat-label>
   </div>
</div>
