import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@empusa/empusa-core';

@Injectable({
  providedIn: 'root',
})
export class RolService {

  url: string = this.environment.urlbase + 'usermanagement/services/';

  constructor(private http: HttpClient, @Inject('environment') private environment, private auth: AuthenticationService) {
  }

  allRol(): any {
    let user = this.auth.getCurrentUser();
    return this.http.get(`${this.url}roles/site/${user.sites[0].uid}`).pipe(
      map(data => data)
    );
  }

  getRolByName(uid: String): any {
    return this.http.get(this.url + 'roles/' + uid).pipe(
      map(data => data)
    );
  }
}
