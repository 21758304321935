<empusa-mat-spinner-overlay *ngIf="loading$ | async" overlay="true"></empusa-mat-spinner-overlay>


<h2 mat-dialog-title>{{dialogTitle}}</h2>
<mat-dialog-content>
    <ng-container *ngIf="formGroup">
        <ng-container [formGroup]="formGroup">
            <mat-form-field>
                <input matInput placeholder="Choose a date" [formControl]="date" [min]="minScheduleDate"
                    [max]="maxScheduleDate" [matDatepicker]="picker" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [formControl]="start_time" type="time" placeholder="Start time" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput [formControl]="end_time" type="time" placeholder="End time" required>
            </mat-form-field>
            <mat-checkbox [formControl]="complimentary_parking">Complimentary parking</mat-checkbox>
            <mat-form-field>
                <textarea matInput placeholder="About the visit" [formControl]="about"></textarea>
            </mat-form-field>
            <mat-form-field *ngIf="groupedVisit">
                <mat-slide-toggle matInput 
                [formControl]="modifyAllGroupedVisits"> This visit is a grouped visit. Modify all!</mat-slide-toggle>
                <textarea matInput hidden></textarea>
            </mat-form-field>
        </ng-container>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="onClose()">
        Close
    </button>
    <button mat-raised-button color="primary" [disabled]="!formGroup?.valid || (this.loading$ | async)"
        (click)="onSave()">
        Save
    </button>
</mat-dialog-actions>