import { TariffAssignmentComponent } from './tariff-assignment/tariff-assignment.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './microapp-usermanagement.user.component';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatListModule} from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ListUserComponent } from './user/list/microapp-usermanagement.list-user.component';
import { EditUserComponent } from './user/edit/microapp-usermanagement.edit-user.component';
import { EditTenantComponent } from './tenant/edit/microapp-usermanagement.edit-tenant.component';
import { ListTenantComponent } from './tenant/list/microapp-usermanagement.list-tenant.component';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-usm.mat-spinner-overlay.component';
import { MicroappusermanagementGenericComponent } from './generic/microapp-usermanagement-generic.component';
import { ListUserTenantComponent } from './userTenant/list/microapp-usermanagement.list-userTenant.component';
import { EditUserTenantComponent } from './userTenant/edit/microapp-usermanagement.edit-userTenant.component';
import { ListBuildingComponent } from './building/list/microapp-usermanagement.list-building.component';
import { EditBuildingComponent } from './building/edit/microapp-usermanagement.edit-building.component';

@NgModule({
  entryComponents: [
    EmpusaQuestionDialog,
    EditTenantComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatSortModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatListModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatSidenavModule,
    MatDividerModule,
    MatTabsModule,
    MatTooltipModule,
    MaterialFileInputModule,
    TranslateModule,
    RouterModule.forChild([
      {
        path: '', component: UserComponent
        // , children: [
        //   { path: '', redirectTo: "home", pathMatch: 'full' },
        //   { path: 'home', component: UserComponent },
        //   { path: 'user/list', component: ListUserComponent },
        //   { path: 'tenant/list', component: ListTenantComponent },
        //   { path: 'userTenant/list', component: ListUserTenantComponent },
        //   { path: 'building/list', component: ListBuildingComponent },
        // ]
      },
    ])
  ],
  declarations: [
    UserComponent,
    EditUserComponent,
    ListUserComponent,
    ListTenantComponent,
    ListUserTenantComponent,
    EditTenantComponent,
    EditUserTenantComponent,
    ListBuildingComponent,
    EditBuildingComponent,
    MatSpinnerOverlayComponent,
    MicroappusermanagementGenericComponent,
    TariffAssignmentComponent
  ],
  providers: [
    MatSnackBar
  ]
})
export class UserModule {

  constructor(private translationLoader: TranslationLoaderService) {
    //loading micro app translations
    this.translationLoader.loadTranslations(english);

  }

}
