import { Pipe, PipeTransform } from '@angular/core';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { FbsConfig } from '../microapp-facilitiesbooking-config';


@Pipe({
    name: 'groupStatus'
})
export class MicroAppFbsGroupStatusPipe implements PipeTransform {

    constructor(private fbsConfig : FbsConfig){}

    transform(group: MicroappFbsResourceGroup): string {
        if (group.singleResourceId || group.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP) {
            switch(group.statusId ) {
                case this.fbsConfig.RESOURCE_STATUS_AVAILABLE: {
                  return "MAPP-FBS-STATUS-PIPE.AVAILABLE";

                }
                case this.fbsConfig.RESOURCE_STATUS_HIDDEN: {
                    return "MAPP-FBS-STATUS-PIPE.DRAFT";
                }
                default: {
                    return "MAPP-FBS-STATUS-PIPE.UNAVAILABLE";
                }
             }
        };

        if (group.hiddenResources >0){
            return "MAPP-FBS-STATUS-PIPE.DRAFT";
        }

        /*const txt =  "Available: " + group.availableResources
                    + " Unavailable:" + group.unAvailableResources
                    + " Total: " + group.totalResources;*/


        return "MAPP-FBS-STATUS-PIPE.MIX";
    }
}
