import { User } from "@empusa/empusa-core";
import { createAction, props } from "@ngrx/store";
import { EpsTenantQuotas } from "../../model/microapp-eps.tenan-quote.model";
import { EpsSpotType } from "../../model/microapp-eps.spot-type.model";
import { EpsVehicleType } from "../../model/microapp-eps.vehicle-type.model";
import { EpsReservationType } from "../../model/reservation/microapp-eps.reservation-type.model";
import { EpsTenantParkingRequest } from "../../model/reservation/microapp-eps.tenant-parking-request.model";
import { EpsMasterConfig } from "../../model/cfg/microapp-eps.cfg.model";
import { ChargeToOption } from "../../model/reservation/microapp-eps.parking-request.model";
import { EpsParkingSize } from "../../model/microapp-eps.parking-size.model";


export const epsTenantResetSteps = createAction(
  '[Microapp eps] tenant epsTenantResetSteps'
);

export const epsTenantResetAvailabilityAndPayment = createAction(
  '[Microapp eps] tenant epsTenantResetAvailabilityAndPayment'
);

export const epsGetMasterData = createAction(
    '[Microapp eps] get master data',
    props<{ tenant: string }>()
);

export const epsStoreMasterData = createAction(
    '[Microapp eps] eps Store master data',
    props<{
        parkingVehicleTypes: EpsVehicleType[],
        reservationTypes: EpsReservationType[],
        spotTypes: EpsSpotType[],
        tenantQuotas: EpsTenantQuotas,
        masterConfig: EpsMasterConfig,
        paypalEnabledForThisTenant: boolean,
        nonEntitledEnabledForThisTenant:boolean,
        parkingSize: EpsParkingSize[];
        loading:boolean;
    }>()
);

export const epsGetMasterConfig = createAction(
  '[Microapp eps] get master data'
);

export const epsStoreMasterConfig = createAction(
  '[Microapp eps] eps Store master data',
  props<{
      masterConfig: EpsMasterConfig,
  }>()
);


export const epsTenantError = createAction(
    '[Microapp eps] tenant error'
);


export const epsStoreSelectedEmployes = createAction(
    '[Microapp eps] eps Store Selected Employes',
    props<{ users: User[] }>()
);


export const epsStoreParkingRequests = createAction(
    '[Microapp eps] eps store parking requests',
    props<{
        parkingRequest: EpsTenantParkingRequest,
    }>()
);

export const epsStoreParkingRequestReservation = createAction(
    '[Microapp eps] eps epsStoreParkingRequestReservation',
    props<{
        parkingRequest: EpsTenantParkingRequest,
    }>()
);

export const epsStoreReservationDone = createAction(
    '[Microapp eps] eps epsStoreReservationDone',
    props<{
        reservationDone: boolean,
    }>()
);

export const epsStoreParkingPasses = createAction(
    '[Microapp eps] eps store parking passes',
    props<{
        parkingRequest: EpsTenantParkingRequest,
    }>()
);

export const epsStoreParkingRequestConfimrOk = createAction(
    '[Microapp eps] epsStoreParkingRequestConfimrOk',
    props<{
        parkingRequestConfirmationStatus: EpsTenantParkingRequest,
    }>()
);

export const epsStoreParkingRequestConfimrKo = createAction(
    '[Microapp eps] epsStoreParkingRequestConfimrKo',
    props<{
        parkingRequestConfirmationStatus: EpsTenantParkingRequest,
        parkingRequestNotConfirmed: EpsTenantParkingRequest,
        kosSelectedUsers: User[],
    }>()
);

export const epsDoReservation = createAction(
    '[Microapp eps] epsDoReservation'
);

export const epsSelectParkingPassError = createAction(
    '[Microapp eps] parking pass step error'
);


export const epsConfirmAndPay = createAction(
    '[Microapp eps] epsConfirmAndPay'
);

export const epsSetReservationDoneStatus = createAction(
    '[Microapp eps] epsSetReservationDoneStatus',
    props<{
        reservationDone: boolean,
    }>()
);

export const epsChangeChangeTo = createAction(
    '[Microapp eps] epsChangeChangeTo',
    props<{
        reservarion_id: number,
        charge_to: ChargeToOption, 
    }>()
);
