<mat-list>

    <mat-list-item class="tableVisit">
       <mat-icon class="iconLog" mat-list-icon>subject</mat-icon>
       <div class="logFile" mat-line>Log file</div>


    </mat-list-item>
    <div class="visitors">
       <mat-table class="tableVisitor" [dataSource]="dataSource">
          <ng-container matColumnDef="date">
             <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
             <mat-cell class="cellDate" *matCellDef="let element">
                {{element.date | date:localDateFormat:offset}}
             </mat-cell>
          </ng-container>
          <ng-container matColumnDef="changes">
             <mat-header-cell *matHeaderCellDef> Changes </mat-header-cell>
             <mat-cell class="cellChange" *matCellDef="let element">
              {{element.logText}}
             </mat-cell>
          </ng-container>
          <ng-container matColumnDef="extraData">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="extraData" *matCellDef="let element">
                              <mat-icon class="success-wear-mask" *ngIf="element.result_check_mask == true"
                  matTooltip="{{'MAPP-VMS-TOOLTIPS.WEAR_MASK' | translate}}">
                  masks
                </mat-icon>

              <mat-icon class="not-wear-mask" *ngIf="element.result_check_mask == false"
                matTooltip="{{'MAPP-VMS-TOOLTIPS.NOT_WEAR_MASK' | translate}}">
                masks
              </mat-icon>

              <ng-container *ngIf="element.result_check_temperature != null">
                <mat-icon
                  matTooltip="{{'MAPP-VMS-TOOLTIPS.VISIT_TEMPERATURE' | translate}}">
                  thermostat
                </mat-icon>
                {{element.result_check_temperature | temperature}}
              </ng-container>
            </mat-cell>
         </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns" class="element-colum">
          </mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row">
          </mat-row>

       </mat-table>
    </div>
 </mat-list>
