import { DTOTariffPass } from '../dto/parking-passes.dto'
import { Tenant } from '@empusa/empusa-core';

export class VOParkingPass {
    tenant: Tenant;
    dtos: DTOTariffPass[];
    flag_editable: boolean;

    //complimentary passes
    hvac: DTOTariffPass;
    fan: DTOTariffPass;
}