import { Injectable, Inject } from '@angular/core';
    import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
    import { User } from '@empusa/empusa-core';


    

    @Injectable({
      providedIn: 'root',
    })
    export class InternalService {
   private REST_TRAC_SERVER: string = this.environment.urlbase + '/fittingout/services/tickets';
// private REST_TRAC_SERVER:string = 'http://fitting.local:8000/fittingout/services/tickets';

      constructor(private http: HttpClient,  @Inject('environment') private environment) {
      }
      currentDate(){
        let dateNow:string= new Date().getTime().toString();
        return dateNow;
      }
      getUrl(path:string){
        return this.REST_TRAC_SERVER + path +this.currentDate();
      }
    //FITTING  
      getOwner(currentUser:User):any{
        let groups:string[]=['Group 1', 'Group 2', 'Group 3'];
        return groups[Math.floor(Math.random()*groups.length)];
        /*let httpOptions = {
          headers: new HttpHeaders({
            'Accept':'text/plain'
          }),
          'responseType': 'text' as 'json'
        };
        let path:string = '/owner';
        return this.http.get(this.getUrl(path), httpOptions).pipe(
          map(data=>data)
        );*/  
    }
}