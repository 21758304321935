import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';


@Component({
  selector: 'empusa-microapp-fbs-set-accessory-available',
  templateUrl: './microapp-fbs-set-accessory-available.component.html',
  styleUrls: ['./microapp-fbs-set-accessory-available.component.css']
})
export class MicroappFbsSetAccessoryAvailableComponent implements OnInit {

  resource: MicroappFbsResourceGroup;
  numberOfAccessories = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), , Validators.max(999)]);
  formGroup: FormGroup;
  loading: boolean;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.resource = data;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = new FormGroup({
      numberOfAccessories: this.numberOfAccessories,
    });
    const suggest = this.resource.totalResources;
    this.numberOfAccessories.patchValue(suggest);
  }

  close($event) {
    $event.preventDefault();
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.loading = true;
    const size = this.numberOfAccessories.value;
    this.facilityBookingService.reescaleAccessores(size, this.resource.resourceGroupId).subscribe(response=>{
      this.loading = false;
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    },error => {
      this.loading = false;
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }
}
