import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { EpsModule } from '../pages/microapp-eps.home.module';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "eps";


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappEpsModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = "MAPP-EPS.DESCRIPTION"
  icon: string = "directions_car";
  page = EpsModule;
  homeApp: boolean = false;
  path: string = "eps";
  order: number = 8;
  //  fakebackend: EmpusaFakeBackend;

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }

}

