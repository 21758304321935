
import * as groupVisitManageActions from './microapp-vms-group-visit-manage.actions';
import { createReducer, on } from '@ngrx/store';
import { MicroappVmsVisitWithVisitors } from '../model/microapp-vms-manage-group-visit.model';
import { MicroappVmsVisitVisitor } from '../model/microapp-vms-manage-group-visit-visitor.model';
import { User, Tenant, Site } from '@empusa/empusa-core';



export interface manageGroupVisitState {
    // Visit and participants
    loggedUser: User,
    tenant: Tenant,
    site: Site,
    visit: MicroappVmsVisitWithVisitors;
    tenantVisitors: MicroappVmsVisitVisitor[];
    showNewParticipant: boolean;
    loading: boolean;
    scheduleValid: boolean;
    updateDone: boolean;
    newVisitDone: boolean;
    error: boolean;
    message_code: string;
}

const initialState = {
    loggedUser: null,
    tenant: null,
    site: null,
    visit: null,
    tenantVisitors: [],
    showNewParticipant: false,
    loading: false,
    scheduleValid: false,
    error: false,
    updateDone: false,
    newVisitDone: false,
    message_code: null,
}


const _counterReducer = createReducer(
    initialState,
    on(groupVisitManageActions.resetNewVisit, (state, action) => ({
        ...state,
        loggedUser: null,
        tenant: null,
        site: null,
        visit: null,
        tenantVisitors: [],
        showNewParticipant: false,
        loading: false,
        scheduleValid: false,
        updateDone: false,
        newVisitDone: false,
    })),
    on(groupVisitManageActions.startNewVisit, (state, action) => ({
        ...state,
        loggedUser: action.user,
        tenant: action.tenant,
        site: action.site,
        showNewParticipant: false,
        loading: true,
        scheduleValid: false,
        newVisitDone: false,
    })),
    on(groupVisitManageActions.showNewParticipant, (state, action) => ({
        ...state,
        showNewParticipant:true,
    })),
    on(groupVisitManageActions.hideNewParticipant, (state, action) => ({
        ...state,
        showNewParticipant:false,
    })),
    on(groupVisitManageActions.visitorsLoaded, (state, action) => ({
        ...state,
        visit: action.visit,
        tenantVisitors: action.tenantVisitors,
        loading: false,
    })),
    on(groupVisitManageActions.emailRepeated, (state, action) => ({
        ...state,
        error: true,
        message_code: 'MESSAGE_CODES.EMAIL_DUPLICATED',
        loading: false,
    })),
    on(groupVisitManageActions.newParticipant, (state) => ({
        ...state,
    })),
    on(groupVisitManageActions.participantListUpdated, (state, action) => ({
        ...state,
        visit: action.visitWithParticipants,
        tenantVisitors: action.tenantVisitors,
        showNewParticipant:false,
    })),
    on(groupVisitManageActions.updateVisitorForSelection, (state, action) => ({
        ...state,
        tenantVisitors: action.availableTenantVisitors,
    })),

    on(groupVisitManageActions.updateVisitAndVisitors, (state, action) => ({
        ...state,
        visit: action.visit,
    })),
    on(groupVisitManageActions.scheduleValid, (state, action) => ({
        ...state,
        scheduleValid: action.valid,
    })),
    on(groupVisitManageActions.goToSchedule, (state, action) => ({
        ...state,
    })),
    on(groupVisitManageActions.backToParticipants, (state) => ({
        ...state,
    })),
    on(groupVisitManageActions.scheduleTheVisit, (state, action) => ({
        ...state,
        loading:false,
    })),
    on(groupVisitManageActions.confirmTheNewVisit, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(groupVisitManageActions.visitStored, (state,action) => ({
        ...state,
        visit: null,
        loading: false,
        newVisitDone: true,
        message_code: action.message_code,
    })),
    on(groupVisitManageActions.error, (state) => ({
        ...state,
        loading: false,
        error: true,
    })),
    on(groupVisitManageActions.resetError, (state) => ({
        ...state,
        error: false,
        message_code: null,
    })),
    on(groupVisitManageActions.updateAVisit, (state, action) => ({
        ...state,
        showNewParticipant: false,
        loading: true,
        scheduleValid: true,
        visit: null,
        updateDone: false,
    })),
    on(groupVisitManageActions.performVisitUpdate, (state, action) => ({
        ...state,
        loading: true,
        updateDone: false,
        off_set_to_utc: action.off_set_to_utc,
    })),
    on(groupVisitManageActions.updateDone, (state, action) => ({
        ...state,
        loading: false,
        updateDone: true,
        visit: null,
        message_code: action.message_code
    })),
    on(groupVisitManageActions.resetMessageCode, (state, action) => ({
        ...state,
        message_code: null
    })),
);
 
export function counterReducer(state, action) {
  return _counterReducer(state, action);
}