import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { MicroappFbsResourceSlotModel } from './faciliti-slots';
import { MicroappFbsTarif } from './microapp-fbs-tarif';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsResourceModel } from './microapp-fbs-resource';

@Injectable({
  providedIn: "root",
})
export class FbsResourceAdapter implements Adapter<MicroappFbsResourceModel> {

  constructor(private fbsConfig: FbsConfig,
  ) { }

  adapt(item: any, checkStartDate: Date, checkEndDate: Date, referenceNumber: string[]): MicroappFbsResourceModel {
    let resourceAdapted = new MicroappFbsResourceModel;
    resourceAdapted.allowMultiday = item.allowMultiday;
    resourceAdapted.autoAssignPermissions = item.autoAssignPermissions;
    resourceAdapted.description = item.description;
    resourceAdapted.location = item.location;
    resourceAdapted.maxParticipants = item.maxParticipants;
    resourceAdapted.name = item.name;
    resourceAdapted.requiresCheckIn = item.requiresCheckIn;
    resourceAdapted.resourceId = item.resourceId;
    resourceAdapted.statusId = +item.statusId;
    resourceAdapted.scheduleId = item.scheduleId;
    resourceAdapted.isAvailable = item.isAvailable;
    resourceAdapted.canBookInSelectedPeriod = true;
    resourceAdapted.image_urls = item.image_urls;
    if (item.slots) {
      let theSlots: MicroappFbsResourceSlotModel[] = []
      item.slots.forEach((item_slot: MicroappFbsResourceSlotModel) => {
        let oneSlot = new MicroappFbsResourceSlotModel;
        oneSlot.endDateTime = new Date(item_slot.endDateTime);
        //oneSlot.endTime = item_slot.endTime;
        oneSlot.isReservable = item_slot.isReservable;
        oneSlot.isBooked = item_slot.reservation ? true : false;
        oneSlot.reservation = item_slot.reservation ? item_slot.reservation : null
        oneSlot.label = item_slot.label;
        oneSlot.startDateTime = new Date(item_slot.startDateTime);
        //oneSlot.startTime = item_slot.startTime;

        if (oneSlot.isBooked
          && referenceNumber
          && referenceNumber.findIndex(x => x == oneSlot.reservation.referenceNumber) != -1) {
        } else
          // Is the slot is inside the check dates ranges or solaped
          if ((checkStartDate <= oneSlot.startDateTime && checkEndDate >= oneSlot.endDateTime)) {
            resourceAdapted.canBookInSelectedPeriod = resourceAdapted.canBookInSelectedPeriod &&
              (!oneSlot.isBooked) &&
              item_slot.isReservable;
          } else
            if (checkStartDate >= oneSlot.startDateTime && checkEndDate <= oneSlot.endDateTime) {
              resourceAdapted.canBookInSelectedPeriod = resourceAdapted.canBookInSelectedPeriod &&
                (!oneSlot.isBooked) &&
                item_slot.isReservable;
            } else
              if (checkStartDate < oneSlot.startDateTime && checkEndDate > oneSlot.startDateTime) {
                resourceAdapted.canBookInSelectedPeriod = resourceAdapted.canBookInSelectedPeriod &&
                  (!oneSlot.isBooked) &&
                  item_slot.isReservable;
              } else
                if (checkStartDate < oneSlot.endDateTime && checkEndDate > oneSlot.endDateTime) {
                  resourceAdapted.canBookInSelectedPeriod = resourceAdapted.canBookInSelectedPeriod &&
                    (!oneSlot.isBooked) &&
                    item_slot.isReservable;
                };
        theSlots.push(oneSlot);
      });
      resourceAdapted.slots = theSlots;
    }

    resourceAdapted.resourceGroup = item.resourceGroup;
    resourceAdapted.chargeType = item.chargeType;


    resourceAdapted.type = item.type;

    if (item.tarif && item.tarif.number_of_units) {
      resourceAdapted.tarif = new MicroappFbsTarif();
      resourceAdapted.tarif.number_of_units = +item.tarif.number_of_units;
      resourceAdapted.tarif.unit_price = +item.tarif.unit_price;
    }

    return resourceAdapted;
  }

}
