<div class="generalCard">
  <mat-drawer-container class="drawContainer">
    <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
        <mat-card class="mc">
          <mat-card-content class="filtersListFault"> {{'MAPP-EPS.GENERIC.FILTER' | translate}}
          </mat-card-content>
          <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
            <mat-icon class="closeIcFilter">close</mat-icon>
          </button>
        </mat-card>
        <mat-divider></mat-divider>
        <p></p>

        <div class="form-column-container">
          <mat-form-field class="custom-form-content">
            <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.VEHICLE_TYPE' | translate}} </mat-label>
            <mat-select  (selectionChange)="applyFilters()" [(ngModel)]="filters.vehicleTypes" multiple>

              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let option of filters.vehicleTypes">
                    <div class="vehicle-type-container">
                      <div>
                        <mat-icon>{{option.vehicle_icon}}</mat-icon>
                      </div>
                      <div> {{option.vehicle_short_desc}} </div>
                    </div>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option *ngFor="let option of epsState.parkingVehicleTypes" [value]="option">
                <div class="vehicle-type-container">
                  <div>
                    <mat-icon>{{option.vehicle_icon}}</mat-icon>
                  </div>
                  <div> {{option.vehicle_short_desc}} </div>
                </div>
              </mat-option>

            </mat-select>
          </mat-form-field>
        </div>


        <div class="form-column-container">
          <mat-form-field class="custom-form-content">
            <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.SPOT_TYPE' | translate}} </mat-label>
            <mat-select  (selectionChange)="applyFilters()" [(ngModel)]="filters.spotTypes" multiple>
              <mat-option *ngFor="let option of epsState.spotTypes" [value]="option" >
                {{option.spot_short_desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-column-container">
          <mat-form-field class="custom-form-content">
            <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.RESERVATION_TYPE' | translate}} </mat-label>
            <mat-select  (selectionChange)="applyFilters()" [(ngModel)]="filters.reservationTypes" multiple>
              <mat-option *ngFor="let option of epsState.reservationTypes" [value]="option">
                {{option.reservation_types_short_desc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-column-container">
          <mat-form-field class="custom-form-content">
            <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.CHARGE_TYPE' | translate}} </mat-label>
            <mat-select (selectionChange)="applyFilters()" [(ngModel)]="filters.chargesAndTariffs" multiple>
              <mat-option *ngFor="let option of epsState.chargesAndTariffs" [value]="option">
                {{option.application_charge_code}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="divClear">
          <button mat-button class="custom-form-content" (click)="cleanFilters()">{{'MAPP-EPS.GENERIC.CLEAN' | translate}}</button>
        </div>
    </mat-drawer>

    <div class="header">
      <button mat-icon-button class="buttonExpandFil">
        <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
      </button>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="assignedChargesDataSource" matSort class="mat-elevation-z8" style="width: 100%;">
        <ng-container matColumnDef='vehicle_short_desc'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.VEHICLE_TYPE' |
            translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div class="vehicle-type-container">
              <div>
                <mat-icon>{{element.vehicle_type.vehicle_icon}}</mat-icon>
              </div>
              <div> {{element.vehicle_type.vehicle_short_desc}} </div>
            </div>

          </td>
        </ng-container>
        <ng-container matColumnDef='spot_short_desc'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.SPOT_TYPE' |
            translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.spot_type.spot_short_desc}} </td>
        </ng-container>
        <ng-container matColumnDef='reservation_types_short_desc'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.RESERVATION_TYPE' |
            translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.reservation_type.reservation_types_short_desc}} </td>
        </ng-container>
        <ng-container matColumnDef='charge_type'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.CHARGE_TYPE' |
            translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.charge_type}} - {{element.description_charge}}</td>
         
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px"> {{'MAPP-EPS.GENERIC.ACTIONS'
            |translate}} </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon matTooltip="{{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.EDIT_CHARGE' | translate}}" class="first-action-button action-button"
              (click)="editAssociation(element)">edit</mat-icon>
            <mat-icon matTooltip="{{'MAPP-EPS.GENERIC.DELETE' |translate}}" class="action-button"
              (click)="deleteAssociation(element.vehicle_spottype_reservationtype_id)">delete</mat-icon>
          </td>
        </ng-container>
       

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    

    <div class="footer-container">
      <div class="button-new-container">
        <button (click)="createNewChargeType()" *ngIf="notExistingAssociations.length > 0" class="extend" mat-fab color="primary" aria-label="FAB new visit">
          <mat-icon>add </mat-icon>
          <span class='extend_text'> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.NEW' |translate}} </span>
        </button>
      </div>
      <div class="paginator-container">
        <mat-paginator class="paginator" [pageSizeOptions]="[10,20,50,100]"></mat-paginator>
      </div>
    </div>

  </mat-drawer-container>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
