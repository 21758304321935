import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';

@Component({
  selector: 'empusa-microapp-set-available',
  templateUrl: './microapp-set-available.component.html',
  styleUrls: ['./microapp-set-available.component.css']
})
export class MicroappSetAvailableComponent implements OnInit {

  resource: MicroappFbsResourceGroup;
  loading: boolean;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.resource = data;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.loading = true;
    this.facilityBookingService.reescaleFacility(1, this.resource.singleResourceId).subscribe(response => {
      this.loading = false;
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

}
