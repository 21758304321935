import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { MICROAPP_ACCESS_TARIFF, MICROAPP_NAME } from '../public-api';
// import { MICROAPP_NAME, MICROAPP_ACCESS_TARIFF } from '../lib/microapp-tariff.module';

@Component({
  selector: 'microapp-tariff',
  templateUrl: 'microapp-tariff.home.component.html',
  styleUrls: ['microapp-tariff.home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroappTariffComponent implements OnInit {


  siteName: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  can_access: boolean = false;
  constructor(private auth: AuthenticationService, private router: Router) {
    // this.router.navigate(['../tariff/conf/prices']);
  }

  ngOnInit(): void {
    this.can_access = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_ACCESS_TARIFF)
    this.auth.loadCurrentUser().then((user: User) => {
      this.siteName = user.sites[0].name;
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
    })
  }


}