import { EpsTenantState } from './../../../../lib/store/tenant/microapp-eps-tenant.reducer';
import { EpsConfig } from './../../../../lib/microapp-eps-cfg';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { EpsVehicleType } from '../../../../lib/model/microapp-eps.vehicle-type.model';
import { selectMyTenantQuotas, selectParkingRequest, selectTenantReservationTypes, selectTenantSpotTypes, selectTenantVehicleTypes, selectReservationDone, selectPaymentDone } from '../../../../lib/store/tenant/microapp-eps-tenan.selectors';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../../lib/store/tenant/microapp-eps.tenant.actions';
import { EpsReservationType } from '../../../../lib/model/reservation/microapp-eps.reservation-type.model';
import { EpsSpotType } from '../../../../lib/model/microapp-eps.spot-type.model';
import { Subscription, forkJoin } from 'rxjs';
import { EpsTenantQuotas } from '../../../../lib/model/microapp-eps.tenan-quote.model';
import { EpsTenantParkingRequest } from '../../../../lib/model/reservation/microapp-eps.tenant-parking-request.model';
import { EpsQuote } from '../../../../lib/model/microapp-eps.quote.model';
import { deleteTimeZone } from '../../../common/microapp-eps-date.util';
import { EpsParkingSize } from '../../../../lib/model/microapp-eps.parking-size.model';
import { AuthenticationService, User } from '@empusa/empusa-core';


interface AvailableQuote {
  quote: EpsQuote,
  vehicle: EpsVehicleType
}


@Component({
  selector: 'empusa-microapp-eps-selectg-reservation-type',
  templateUrl: './microapp-eps-selectg-reservation-type.component.html',
  styleUrls: ['./microapp-eps-selectg-reservation-type.component.css']
})
export class MicroappEpsSelectgReservationTypeComponent implements OnInit, OnDestroy {

  @Input() mode: 'employee' | 'visitor' = 'employee';

  VISIT_DEFAULT_RESERVATION_TYPE_CODE = 'D';
  VISIT_DEFAULT_SPOT_TYPE_CODE = 'Non-entitled';
  WITHOUT_NON_ENTITLED_SPOT_TYPE_CODE = 'Entitled';
  MONTHLY_RESERVATION_TYPE_ID = 1;
  MOTORBIKE_TYPE_CODE = 'Motorbike';

  loading: boolean = false;
  formGroup: FormGroup;
  reservationTypeControl = new FormControl('', [Validators.required]);
  parkingSpotTypeControl = new FormControl('', [Validators.required]);
  startDateControl = new FormControl('', [Validators.required]);
  startDateControlMonth = new FormControl('', [Validators.required]);
  recurringMonthly = new FormControl(true, [Validators.required]);

  reservationTypes: EpsReservationType[];
  spotTypes: EpsSpotType[];

  storeSubscription: Subscription;
  my_quotes: EpsTenantQuotas;
  activateUsers: boolean = false;
  parkingRequests: EpsTenantParkingRequest;
  vehicleTypes: EpsVehicleType[];
  availableQuote: AvailableQuote[] = [];
  selectedTarificationType: string;
  parkingSize: EpsParkingSize[] = [];

  storeState: EpsTenantState;
  formChangesSubscription: Subscription;

  minSelectableDay: Date;
  minSelectableMonth: Date;
  user: User;


  constructor(private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private auth: AuthenticationService,
    public epsConfig: EpsConfig) { 
      this.auth.loadCurrentUser().then(user => {
        this.user = user;
      })
    }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      reservationTypeControl: this.reservationTypeControl,
      parkingSpotTypeControl: this.parkingSpotTypeControl,
      startDateControl: this.startDateControl,
      startDateControlMonth: this.startDateControlMonth,
      recurringMonthly: this.recurringMonthly
    });
    this.onFormChanges();

    this.store.pipe(select(selectReservationDone)).subscribe(reservationDone => {
      this.updateFormValuesAndValidity();
      // this.onFormChanges();
    });

    this.store.pipe(select(selectParkingRequest)).subscribe(p_r => {
      if (p_r && p_r.reservation_request) {
        for (const control in this.formGroup.controls) {
          if ((control.endsWith("iu") || (control.endsWith("licensePlate")) || (control.endsWith("vehicleType")))) {
            this.formGroup.removeControl(control);
          }
        }
        this.parkingRequests = p_r;
        p_r.reservation_request.forEach(pr => {
          let control = new FormControl()
          if (this.mode === 'visitor') {
            control = new FormControl(pr.vehicle_iu);
          } else {
            control = new FormControl(pr.vehicle_iu, [Validators.required, Validators.minLength(1)]);
          }

          this.formGroup.addControl(pr.vehicle_owner_id + "iu", control);

          control = new FormControl(pr.license_plate, [Validators.required, Validators.minLength(1)]);
          this.formGroup.addControl(pr.vehicle_owner_id + "licensePlate", control);

          let value = undefined;
          if (this.availableQuote)
            value = this.availableQuote.find(aq => aq.vehicle.vehicle_code == pr.vehicle_type_code)
          control = new FormControl(value, [Validators.required, Validators.minLength(1)]);
          this.formGroup.addControl(pr.vehicle_owner_id + "vehicleType", control);
        });
      }
    });


    this.store.pipe(select('tenant')).subscribe((data) => {
      this.storeState = data;
      if (data.reservationTypes) {
        this.reservationTypes = [];
        data.reservationTypes.forEach(rt => {
          if (!rt.sub_type_of_type_id || rt.sub_type_of_type_id == undefined) {
            this.reservationTypes.push(rt);
          }
        });
      }
      if (data.parkingSize) {
        this.parkingSize = data.parkingSize;
      }
      if (data.spotTypes) {
        this.spotTypes = data.spotTypes;
        if (!data.nonEntitledEnabledForThisTenant) {
          this.setSpotTypewithoutNonEntitled();
        }
      }
      if (data.tenantQuotas) {
        this.my_quotes = data.tenantQuotas;
      }
      if (data.parkingVehicleTypes) {
        this.vehicleTypes = data.parkingVehicleTypes;
      }

      if (this.mode === 'visitor' && this.spotTypes && this.reservationTypes &&
        data.parkingVehicleTypes && data.parkingRequest) {
        this.setDefaultReservationType();
        this.setDefaultSpotType();
      }

      if (data.masterConfig) {
        const minDay = new Date();
        minDay.setDate(minDay.getDate() + data.masterConfig.days_to_allow_reservation);
        this.minSelectableDay = minDay;

        const minMonth = new Date();
        minMonth.setMonth(minMonth.getMonth() + data.masterConfig.months_to_allow_reservation);
        minMonth.setDate(1);
        this.minSelectableMonth = minMonth;
      }
      this.setDefaultDate();
    });

  }

  setDefaultReservationType() {
    if (!this.formGroup.controls.reservationTypeControl.value) {
      const reservationTypeToSelect = this.reservationTypes.find(item => item.reservation_types_code === this.VISIT_DEFAULT_RESERVATION_TYPE_CODE);
      if (reservationTypeToSelect) {
        this.formGroup.controls.reservationTypeControl.setValue(reservationTypeToSelect.reservation_type_id);
        this.formGroup.controls.reservationTypeControl.disable();
        this.reservationTypeChange();
      }
    }

  }

  setDefaultDate() {
    if (!this.formGroup.controls.startDateControlMonth.value) {
      this.formGroup.controls.startDateControl.setValue(this.minSelectableDay);
    }
    if (!this.formGroup.controls.startDateControlMonth.value) {
      this.formGroup.controls.startDateControlMonth.setValue(this.minSelectableMonth);
    }
  }

  setDefaultSpotType() {
    if (!this.formGroup.controls.parkingSpotTypeControl.value) {
      const parkingSpotTypeToSelect = this.spotTypes.find(item => item.spot_type_code === this.VISIT_DEFAULT_SPOT_TYPE_CODE);
      if (parkingSpotTypeToSelect) {
        this.formGroup.controls.parkingSpotTypeControl.setValue(parkingSpotTypeToSelect.spot_type_id);
        this.formGroup.controls.parkingSpotTypeControl.disable();
        this.spotTypeChange();
      }
    }
  }

  setSpotTypewithoutNonEntitled() {
    if (!this.formGroup.controls.parkingSpotTypeControl.value) {
      const parkingSpotTypeToSelect = this.spotTypes.find(item => item.spot_type_code === this.WITHOUT_NON_ENTITLED_SPOT_TYPE_CODE);
      if (parkingSpotTypeToSelect) {
        this.formGroup.controls.parkingSpotTypeControl.setValue(parkingSpotTypeToSelect.spot_type_id);
        this.formGroup.controls.parkingSpotTypeControl.disable();
        this.spotTypeChange();
      }
    }
  }


  closeDatePicker(eventData: any, dp?: any) {
    this.formGroup.controls.startDateControlMonth.setValue(eventData);
    // this.formGroup.controls.startDateControl.setValue(eventData);
    dp.close();
  }

  updateFormValuesAndValidity() {
    for (const control in this.formGroup.controls) {
      this.formGroup.controls[control].updateValueAndValidity();
    }
  }


  spotTypeChange() {
    this.allSelected();
  }

  reservationTypeChange() {
    this.allSelected();
    this.getReservationTypeCode();
  }



  private getReservationTypeCode() {
    const rtcv = this.reservationTypeControl.value;
    if (rtcv) {
      const rfFound = this.reservationTypes.find(rt => rt.reservation_type_id == rtcv);
      this.selectedTarificationType = rfFound.reservation_tariffication_type;
    }
  }

  allSelected() {
    const stcv = this.parkingSpotTypeControl.value;
    const rtcv = this.reservationTypeControl.value;
    let availableQuote: AvailableQuote[] = [];
    let diffs = false;
    if (stcv && rtcv) {
      //Calculate the vehicles with this quote
      this.my_quotes.quota.forEach(qToClone => {
        const q = { ...qToClone }
        const the_vehicle = this.vehicleTypes.find(v => v.vehicle_type_id == q.vehicle_type_id);
        const data = { quote: q, vehicle: the_vehicle };
        availableQuote.push(data);
        if (diffs == false) {
          let exist = this.availableQuote.find(aq => aq.vehicle.vehicle_type_id == the_vehicle.vehicle_type_id)
          if (exist == undefined) {
            diffs = true
          }
        }
      });

      availableQuote.forEach(quoteAndVehicle => {
        if (quoteAndVehicle.quote.spot_type_id == 2) {
          const parkingNonEntitled = this.parkingSize.find(vehicleNontEntitled => vehicleNontEntitled.spot_type_id == 2);
          quoteAndVehicle.quote.quota += parkingNonEntitled.vehicle_type.find(vehicleParkingSize => vehicleParkingSize.vehicle_type_id == quoteAndVehicle.quote.vehicle_type_id).can_assign;
        }
      })
      availableQuote = availableQuote.filter(singleQuote => singleQuote.quote.quota > 0 && singleQuote.quote.spot_type_id == stcv)
      //check if diffs with before to change data
      if (diffs == false) {
        this.availableQuote.forEach(aq => {
          let find = availableQuote.find(aql => aql.vehicle.vehicle_type_id == aq.vehicle.vehicle_type_id)
          if (find == undefined) {
            diffs = true;
          }
        })
      }

      if (diffs) {
        this.availableQuote = availableQuote;
        for (const control in this.formGroup.controls) {
          if (control.endsWith("vehicleType")) {
            this.formGroup.controls[control].reset();
          }
        }
      }
      this.activateUsers = true;
    }
  }

  onSubmit() {
  }

  onFormChanges(): void {
    this.formChangesSubscription = this.formGroup.valueChanges.subscribe(val => {
      // this.startDateControl.reset();
      if (this.formGroup.valid) {
        const localParkingRequest = new EpsTenantParkingRequest;
        localParkingRequest.tenant_id = this.parkingRequests.tenant_id;
        localParkingRequest.booked_by = this.user.givenName + ' ' + this.user.surname + ' (' + this.user.mail + ')';
        if (this.selectedTarificationType == 'Monthly') {
          localParkingRequest.from_date = deleteTimeZone(this.startDateControlMonth.value);
          localParkingRequest.to_date = deleteTimeZone(this.startDateControlMonth.value);

        } else {
          localParkingRequest.from_date = deleteTimeZone(this.startDateControl.value);
          localParkingRequest.to_date = deleteTimeZone(this.startDateControl.value);

        }
        localParkingRequest.source_system = this.parkingRequests.source_system;
        if (this.formGroup.controls.reservationTypeControl.value == this.MONTHLY_RESERVATION_TYPE_ID) {
          localParkingRequest.is_recurrent = this.formGroup.controls.recurringMonthly.value;
        }
        localParkingRequest.user_reservation_type = this.mode;
        localParkingRequest.reservation_request = [];
        this.parkingRequests.reservation_request.forEach(pq => {
          const clone = { ...pq };
          // Update reservation type, becasue is asinc and here could be not updated
          this.getReservationTypeCode();
          clone.reservation_tariffication_type = this.selectedTarificationType;

          const stcv = this.parkingSpotTypeControl.value;
          const selSpotType = this.spotTypes.find(s => s.spot_type_id == stcv);
          clone.spot_type_code = selSpotType.spot_type_code;

          const iuControl = this.formGroup.controls[pq.vehicle_owner_id + 'iu'];
          if (iuControl && !iuControl.invalid) {
            clone.vehicle_iu = iuControl.value;
          }

          const licensePlateControl = this.formGroup.controls[pq.vehicle_owner_id + 'licensePlate'];
          if (licensePlateControl && !licensePlateControl.invalid) {
            clone.license_plate = licensePlateControl.value;
          }

          const vehicleTypeControl = this.formGroup.controls[pq.vehicle_owner_id + 'vehicleType'];
          if (vehicleTypeControl && !vehicleTypeControl.invalid) {
            const theValue: AvailableQuote = vehicleTypeControl.value;
            const theVehicle = this.vehicleTypes.find(v => v.vehicle_type_id == theValue.quote.vehicle_type_id);
            clone.vehicle_type_code = theVehicle.vehicle_code;
          }

          localParkingRequest.reservation_request.push(clone);
        });
        this.store.dispatch(epsTenatActions.epsStoreParkingPasses({ parkingRequest: localParkingRequest }));

      } else {
        this.store.dispatch(epsTenatActions.epsSelectParkingPassError());
      }
    });
  }

  uiFieldLength(vehicleTypeControlName: string) {
    const vehicleType = this.formGroup.controls[vehicleTypeControlName].value;
    if (vehicleType && vehicleType.vehicle.vehicle_code === this.MOTORBIKE_TYPE_CODE) {
      return this.epsConfig.MOTORBIKE_UI_SIZE;
    }
    return this.epsConfig.GENERAL_UI_SIZE;
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

}
