<h2 class="titleNew"> {{'MAPP-FBS-RESOUERCE-DETAILS.TITLE' | translate}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
  <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
</button>
</h2>
<div class="toFacilityData">
  <empusa-microapp-fbs-facility-data [facility]="resource"></empusa-microapp-fbs-facility-data>
</div>
<div mat-dialog-actions  *ngIf="can_resources_reservation" class="buttonFitting" align="end">
  <button mat-raised-button class="buttonClose"  *ngIf="can_resources_reservation" (click)="close()">
    {{'MAPP-FBS-RESOUERCE-DETAILS.CANCEL' | translate}}</button>
  <button class="buttonConfirm" mat-raised-button  *ngIf="can_resources_reservation && resource.tarif" color="primary" (click)="toBook()">
    {{'MAPP-FBS-RESOUERCE-DETAILS.BOOK' | translate}}
  </button>
</div>


   <!--
    <empusa-microapp-fbs-facility-reservations [facility]="facility"></empusa-microapp-fbs-facility-reservations>
    -->
 



