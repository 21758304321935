import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EpsTenantState } from './microapp-eps-tenant.reducer';


export const selectEpsState =
  createFeatureSelector<EpsTenantState>('tenant');

export const selectTenantVehicleTypes = createSelector(
  selectEpsState,
  state => state.parkingVehicleTypes
);

export const selectTenantReservationTypes = createSelector(
  selectEpsState,
  state => state.reservationTypes
);

export const selectTenantSpotTypes = createSelector(
  selectEpsState,
  state => state.spotTypes
);

export const selectMyTenantQuotas = createSelector(
  selectEpsState,
  state => state.tenantQuotas
);


export const selectParkingRequest = createSelector(
  selectEpsState,
  state => state.parkingRequest
);

export const selectLoading = createSelector(
  selectEpsState,
  state => state.loading
);

export const selectParkingRequestReservationStatus = createSelector(
  selectEpsState,
  state => state.parkingRequestReservationStatus
);

export const selectParkingConfirmationStatus = createSelector(
  selectEpsState,
  state => state.parkingRequestConfirmationStatus
);

export const selectReservationDone = createSelector(
  selectEpsState,
  state => state.reservationDone
);

export const selectPaymentDone = createSelector(
  selectEpsState,
  state => state.paymentDone
);

export const selectSelectedUsers = createSelector(
  selectEpsState,
  state => state.selectedUsers
);


