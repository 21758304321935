import { EpsParkingSize } from "../model/microapp-eps.parking-size.model";
import { EpsTenantQuotas } from "../model/microapp-eps.tenan-quote.model";
import { createReducer, on } from '@ngrx/store';
import * as epsActions from './microapp-eps.actions';
import { EpsVehicleType } from "../model/microapp-eps.vehicle-type.model";
import { EpsChargeType } from "../model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsAssignedChargeType } from "../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model";
import { EpsTenantTariff } from "../model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { EpsSpotType } from "../model/microapp-eps.spot-type.model";
import { EpsReservationType } from "../model/reservation/microapp-eps.reservation-type.model";

export interface EpsState {
    loading: boolean;
    parkingDataLoaded: boolean;
    parkingSize: EpsParkingSize[];
    tenantQuotas: EpsTenantQuotas[];
    parkingVehicleTypes: EpsVehicleType[];
    chargesAndTariffs: EpsChargeType[];
    assignedCharges: EpsAssignedChargeType[];
    tenantTariffs: EpsTenantTariff[];
    reservationTypes: EpsReservationType[];
    spotTypes: EpsSpotType[];
}

const initialState = {
    loading: false,
    parkingDataLoaded: false,
    parkingSize: null,
    tenantQuotas: [],
    parkingVehicleTypes: [],
    chargesAndTariffs: [],
    assignedCharges: [],
    tenantTariffs: [],
    reservationTypes: [],
    spotTypes: []
}

const _counterReducer = createReducer(
    initialState,
    on(epsActions.epsGetParkinfData, state => ({
        ...state,
        loading: true,
    })),
    on(epsActions.epsStoreParkingData, (state, action) => ({
        ...state,
        loading: false,
        parkingDataLoaded: true,
        parkingSize: action.parkingSize,
        tenantQuotas: action.tenantQuotas,
        parkingVehicleTypes: action.parkingVehicleTypes,
        chargesAndTariffs: action.chargesAndTariffs,
        assignedCharges: action.assignedCharges,
        tenantTariffs: action.tenantTariffs,
        reservationTypes: action.reservationTypes,
        spotTypes: action.spotTypes
    })),
    on(epsActions.epsGetQuotas, state => ({
        ...state,
        loading: true,
    })),
    on(epsActions.epsStoreAssignedCharges, (state, action) => ({
        ...state,
        loading: false,
        assignedCharges: action.assignedCharges,
    })),
    on(epsActions.epsGetParkingSize, state => ({
        ...state,
        loading: true,
    })),
    on(epsActions.epsStoreParkingSize, (state, action) => ({
        ...state,
        loading: false,
        parkingSize: action.parkingSize,
    })),
    on(epsActions.epsGetTenantTariffs, state => ({
        ...state,
        loading: true,
    })),
    on(epsActions.epsStoreTenantTariffs, (state, action) => ({
        ...state,
        loading: false,
        tenantTariffs: action.tenantTariffs,
    })),
    on(epsActions.epsStoreQuotas, (state, action) => ({
        ...state,
        loading: false,
        tenantQuotas: action.tenantQuotas,
    })),
    on(epsActions.epsError, state => ({
        ...state,
        loading: false,
    })),
);

export function epsCounterReducer(state, action) {
    return _counterReducer(state, action);
}
