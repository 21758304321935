import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogBookState } from '../../logbook/store/microapp-vms-logbook.reducer';


export const selectLogBookState =
  createFeatureSelector<LogBookState>("logBook");

export const selectLogBookLoading = createSelector(
  selectLogBookState,
  state => state.loading
);


export const logbookInit = createSelector(
  selectLogBookState,
  state => !!state.loggedUser
);

export const get_filter = createSelector(
  selectLogBookState,
  state => state.filter_visit
);


export const selectLogBookUpdateDone = createSelector(
  selectLogBookState,
  state => state.visitUpdated
);

export const getMasterConfig = createSelector(
  selectLogBookState,
  state => state.config
);

export const selectLogBookError= createSelector(
  selectLogBookState,
  state => state.error
);

export const selectLogBookMessageCode= createSelector(
  selectLogBookState,
  state => state.message_code
);

export const selectLogBookOk= createSelector(
  selectLogBookState,
  state => !state.error
);

export const selectVisitListLoaded = createSelector(
  selectLogBookState,
  state => state.visits_loaded
);

export const selectVisitListLoadedOrError = createSelector(
  selectLogBookState,
  state => state.visits_loaded || state.error
);

export const selectVisitLoaded = createSelector(
  selectLogBookState,
  state => state.visit_loaded
);

export const selectVisitLoadedOrError = createSelector(
  selectLogBookState,
  state => state.visit_loaded || state.error
);


