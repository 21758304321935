import { ThrowStmt } from "@angular/compiler/src/output/output_ast";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User } from "@empusa/empusa-core";
import { TranslateService } from "@ngx-translate/core";
import { Reservation } from "../../../../../lib/model/reservation/microapp-eps.reservation.model";
import { EpsServices } from "../../../../../lib/services/microapp-eps.service";

@Component({
    selector: 'employee-reservation-eps',
    templateUrl: './microapp-eps.employee-reservation.component.html',
    styleUrls: ['./microapp-eps.employee-reservation.component.css']
})
export class EmployeeReservationsEps implements OnInit, OnDestroy {
    tenant: string;
    userViewReservation: User;
    dataToTableReservation: Reservation[];
    currentDateTime;
    loading: boolean = true;

    constructor(private snackBar: MatSnackBar,
        private epsServices: EpsServices,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.userViewReservation = data.user;
        this.tenant = data.tenant;
        this.currentDateTime = new Date().getTime()
        this.viewReservation();
    }

    ngOnInit(): void { }

    viewReservation() {
        this.loading = true;
        this.epsServices.get_tenant_reservations(this.tenant, undefined, undefined).subscribe(data => {
            this.createDataTable(data);
        },
            error => {
                this.loading = false;
                this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGE"),
                    this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERROR"), {
                    duration: 5000,
                });
            })
    }

    private createDataTable(data: Reservation[]) {
        let listdataToTableReservation: Reservation[] = []

        data.forEach(reservation => {
            if (reservation.vehicle_owner_id === this.userViewReservation.mail) {
                listdataToTableReservation.push(reservation);
            }
        })
        this.dataToTableReservation = listdataToTableReservation;
        this.loading = false;

    }

    onModalSuccessfullyOperation() {
        this.viewReservation()
    }

    ngOnDestroy() { }
}
