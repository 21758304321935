<form [formGroup]="formGroup">
    <div fxLayout="column">
        <div fxLayout="row" fxFlex="100%" class="selectFieldsContainer" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="40%" *ngIf="tenantList.length>1">
                <mat-form-field>
                    <mat-label>{{'MAPP-VMS-NEW-VISIT-PARTICIPANTS.SELECT-TENANT' | translate}}</mat-label>
                    <mat-select [formControl]="tenantControl">
                        <mat-option *ngFor="let tenant of tenantList" [value]="tenant">
                            {{tenant.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="40%" *ngIf="selectedTenant">
                <mat-form-field *ngIf="filteredVisitors!= null && selectedTenant">
                    <mat-label>{{'MAPP-VMS-NEW-VISIT-PARTICIPANTS.SELECT-VISITOR' | translate}}</mat-label>
                    <input type="text" matInput [formControl]="visitorSelectorControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let visitor of filteredVisitors | async" [value]="visitor">
                            {{visitor.first_name}} {{visitor.last_name}} - {{visitor.email}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxFlex="100%">
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="90%">
                <mat-label class="visitorLabel">{{'MAPP-VMS-NEW-VISIT-PARTICIPANTS.VISITOR-LIST' | translate}}
                </mat-label>
            </div>
            <div fxLayout="column" fxLayoutAlign="end" fxFlex="10%" class="newVisitTxt">
                <mat-checkbox [formControl]="allParkinControl" (change)="onClickallParkinControl()"
                    *ngIf="masterCfg.parking_active && this.can_request_parking && eps_is_active">{{'MAPP-VMS-NEW-VISIT-PARTICIPANTS.ALL' | translate}}</mat-checkbox>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" fxFlex="100%">
            <mat-table class="tableAddVisitor" [dataSource]="participantsDataSource">
                <ng-container matColumnDef="img">
                    <mat-cell class="cellParticipant" *matCellDef="let participant">
                        <mat-icon> person</mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-cell class="cellParticipant" *matCellDef="let participant">
                        {{participant.first_name}} {{participant.last_name}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                    <mat-cell class="cellParticipant" *matCellDef="let participant"> {{participant.email}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="company">
                    <mat-cell class="cellParticipant" *matCellDef="let participant"> {{participant.company}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="parking" >
                    <mat-cell *matCellDef="let participant" [matTooltip]="tooltipEnabledNonEntitled">
                        <mat-checkbox [disabled]="enabled_non_entitled" *ngIf="eps_is_active" [checked]="participant.complimentary_parking"
                            (click)="onComplimentaryParking(participant)">
                            <mat-icon class="icParkingKo"> local_parking</mat-icon>
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let participant">
                        <mat-icon (click)="onDeleteParticipant(participant)">delete</mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row">
                </mat-row>
            </mat-table>
        </div>
        <div fxLayout="row" fxLayoutAlign="end" fxFlex="100%">
            <button mat-button class="buttonNewVisitor" (click)="displayNewVisit()"
                color="primary">{{'MAPP-VMS-NEW-VISIT-PARTICIPANTS.NEW-VISITOR' | translate}}</button>
        </div>

    </div>
</form>