export const MICROAPPFAKEDATABASE = {
    microappSecurityTree: {
        'eps': {
            'access': {
                'USER': true,
                'ADMIN': true,
            }
        }
    },
    users: [
        {
            uid: "1000",
            mail: "epsuser1@boustead.sh",
            userPassword: "admin",
            givenName: "epsuser",
            surname: "fake",
            sites: [
                {
                    uid: "1",
                    name: "Torre Triana",
                    description: "Torre Triana en Sevilla",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                }
            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                }
            ]
        },
        {
            uid: "1000",
            mail: "epsuser2@boustead.sh",
            userPassword: "admin",
            givenName: "epsuser-2",
            surname: "fake2",
            sites: [
                {
                    uid: "1",
                    name: "Torre Triana",
                    description: "Torre Triana en Sevilla",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                }
            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                }
            ]
        }
    ],
    tenants: [
        {
            uid: '1001',
            name: 'tenant 1'
        },
        {
            uid: '1002',
            name: 'tenant 2'
        }
    ],
    quotes: [
        {
            'Tenant':{
                'Id':'1000',
                'Name':'tenant 1'
            },
            'TotalParkingSpaces': 85,
            'CarParkingSpaces': 55,
            'MotorBikesParkingSpaces': 30,
            'ConcessionParkingSpaces': 22
        },
        {
            'Tenant':{
                'Id':'1002',
                'Name':'tenant 2'
            },
            'TotalParkingSpaces': 100,
            'CarParkingSpaces': 65,
            'MotorBikesParkingSpaces': 35,
            'ConcessionParkingSpaces': 11
        }
    ],
    tarifs: [
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de1",
            "tarif_name": "Tarif-1",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de2",
            "tarif_name": "Tarif-2",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de3",
            "tarif_name": "Tarif-3",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de4",
            "tarif_name": "Tarif-4",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de5",
            "tarif_name": "Tarif-5",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de6",
            "tarif_name": "Tarif-6",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de7",
            "tarif_name": "Tarif-7",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de8",
            "tarif_name": "Tarif-8",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de9",
            "tarif_name": "Tarif-9",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 10,
            "tags": [
                "Tag2",
                "Tag3"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6dA",
            "tarif_name": "Tarif-10",
            "total_price": 55.5,
            "unit_price": 5.55
        }
    ],
    allowedtags: [
        'tag1', 'tag2', 'tag3'
    ]
}