<div class="fondoGeneral">
  <mat-drawer-container class="drawContainer">
    <div class="header">
      <empusa-microapp-fbs-check-availability class="checkInHeader" (search)="search($event)">
      </empusa-microapp-fbs-check-availability>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort id='tablaList'>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-FACILITY-LIST.TYPE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{'MAPP-FBS-FACILITY-LIST.' + element.type | translate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-FACILITY-LIST.RESOURCE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-FACILITY-LIST.LOCATION' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-FBS-FACILITY-LIST.DESCRIPTION' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="capacity">
          <mat-header-cell *matHeaderCellDef>
            {{'MAPP-FBS-FACILITY-LIST.CAPACITY' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{(element | groupCapacity: facilitiesWithBookInfo | translate)
                                              .replace('%1',element.maxParticipants)
                                              .replace('%2',element.availableResources)
                                              .replace('%3',element.availableResourcesToBook)}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-FACILITY-LIST.ACTIONS' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button matTooltip="{{'MAPP-FBS-TOOLTIP.RESOURCE.DETAIL' | translate}}" mat-icon-button (click)="the_resource_detail(element)">
              <mat-icon class="icActions">info</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="bookAction">
          <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-FACILITY-LIST.ACTIONS' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button  matTooltip="{{'MAPP-FBS-TOOLTIP.RESOURCE.DETAIL' | translate}}" mat-icon-button (click)="the_resource_detail(element)">
              <mat-icon class="icActions">info</mat-icon>
            </button>
            <button mat-icon-button  matTooltip="{{'MAPP-FBS-TOOLTIP.RESOURCE.AVALIBLRE' | translate}}"
              *ngIf="(element.canBookInSelectedPeriod) && (element.slots.length>0) && element.tarif"
              (click)="toBook(element)">
              <mat-icon class="icActions">schedule</mat-icon>
            </button>
            <button  matTooltip="{{'MAPP-FBS-TOOLTIP.RESOURCE.UNAVALIBLE' | translate}}" mat-icon-button *ngIf="!element.tarif">
              <mat-icon class="icActions">warning</mat-icon>
            </button>
            <button matTooltip="{{'MAPP-FBS-TOOLTIP.RESOURCE.CANCEL' | translate}}" mat-icon-button *ngIf="!(element.canBookInSelectedPeriod) || (element.slots.length==0)">
              <mat-icon class="icActions">cancel</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>

      </table>

    </div>
    <mat-paginator [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
  </mat-drawer-container>
</div>