import { Answer } from './answer';

export class Question {
    sid: string;
    code: string;
    question: string;
    type: string;
    answers: Answer[];

    constructor() {
    }
}