import { EpsTariff } from "./microapp-eps.tariff.model"

export class EpsChargeType {
    id: string
    description: string
    application_charge_code: string
    //Available tariffs for a charge type
    available_tariffs: EpsTariff[]
    //Assingned tariff to a tenant
    tarif: EpsTariff
}