import { Inject, Injectable } from "@angular/core";



@Injectable({
    providedIn: 'root',
})

export class EpsConfig {

    constructor(@Inject('environment') private environment) {
    }

    //EPS_CONTEXT = "http://bousteadintegration.ddns.net:18080/smartparking/services/";
    EPS_CONTEXT = this.environment.urlbase + "smartparking/services/";
    // EPS_CONTEXT = "http://localhost:5300/smartparking/services/";
    //EPS_CONTEXT = "http://192.168.77.25:5300/smartparking/services/";
    BILLING_CONTEXT = this.environment.urlbase + "smartbilling/services/"
    USER_MANAGEMENT_CONTEXT = this.environment.urlbase + "usermanagement/services/"

    MOTORBIKE_UI_SIZE = 9;
    GENERAL_UI_SIZE = 10;

    SIZE_LICENSE_PLATE_MAX = 10;
    SIZE_LICENSE_PLATE_MIN = 6;
}
