import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MicroappFbsResourceGroup } from '../../../client/dto/microapp-fbs-resource-group';
import { FbsResourceGroupAdapter } from '../../../client/dto/microapp-fbs-resource-group-adapter';
import { MicroappFbsAccessoryReservationModel } from '../../../client/dto/microapp-fbs-reserve-accessory';
import { select, Store } from '@ngrx/store';
import { selectFbsUser, selectResources, selectScheduled } from '../../../lib/store/microapp-fbs-selectors';
import { take } from 'rxjs/operators';


import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';
import { SearchDates } from '../../../client/microapp-fbs.interfaces';
import { MicroappFbsUserModel } from '../../../client/dto/microapp-fbs-user';
import { MicroappFbsReservationGroupModel } from '../../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsMessageHandlerService } from '../../../lib/microapp-fbs-message-handler.service';
import { MicroappFbsPeriod, MicroappFbsSchedule } from '../../../client/dto/microapp-fbs-schedule';


import { MicroappFbsCheckAvailabilityComponent } from '../components/check-availability/microapp-fbs-check-availability.component';
import { FbsResourceAdapter } from '../../../client/dto/microapp-fbs-resource-adapter';
import { MicroappFbsResourceModel } from '../../../client/dto/microapp-fbs-resource';
import { MicroappFbsResourceListModel } from '../../../client/dto/microapp-fbs-resource-list';
import { Subscription } from 'rxjs';
import { cloneDeep } from "lodash";
import { SliderSelectionModel } from '../components/generics/range-slider/range-slider.component';
import {MicroappFbsSliderFacilitySlotsComponent} from "../components/slots/microapp-fbs-slider-facility-slots/microapp-fbs-slider-facility-slots.component";

interface DayHours {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'empusa-microapp-fbs-make-a-accessory-reservation',
  templateUrl: './microapp-fbs-make-a-accessory-reservation.component.html',
  styleUrls: ['./microapp-fbs-make-a-accessory-reservation.component.css']
})
export class MicroappFbsMakeAAccessoryReservationComponent implements OnInit {

  resourceGroup: MicroappFbsResourceGroup;
  resourceImages: string[];
  theBookDate: SearchDates;

  dateChecked: SearchDates;
  unitaryCost: number;

  fbsUser: MicroappFbsUserModel;

  mode_confirm = false;
  loading: boolean;
  reservation_mode_new = true;
  reservation_to_update: MicroappFbsReservationGroupModel;
  availability_checked = true;

  reservation: MicroappFbsAccessoryReservationModel;

  public reservationForm: FormGroup;
  amount = new FormControl(1);
  title = new FormControl("", [Validators.required, Validators.minLength(1)]);
  description = new FormControl();
  schedule_for_today: MicroappFbsPeriod;

  todayDate = new Date();
  avalibilityStartDate;
  selectedDay = new FormControl();
  selectedDaySub$: Subscription;
  selectedDayObject: SearchDates;
  initialSliderValues: SliderSelectionModel;
  renderSliderComponent = true;

  data;


  @ViewChild('checkAvailability') checkAvailabilityComponent: MicroappFbsCheckAvailabilityComponent;
  @ViewChild('sliderFacilitySlots') sliderFacilitySlots: MicroappFbsSliderFacilitySlotsComponent;
  schedule: MicroappFbsSchedule;
  raw_resources: MicroappFbsResourceModel[] = [];
  maxDay: Date;


  constructor(private dialogRef: MatDialogRef<any>,
    public fbsConfig: FbsConfig,
    private resourceGroupAdapter: FbsResourceGroupAdapter,
    private resourceAdapter: FbsResourceAdapter,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;

    const d = new Date();
    this.maxDay = new Date(d.setDate(d.getDate() + this.fbsConfig.DAYS_IN_ADVANCE));
    this.selectedDay.setValue('');
    this.resourceGroup = cloneDeep(data[0]);
    this.resourceImages = this.resourceGroup.image_urls;
    if (data[1]) {
      this.theBookDate = { ...data[1] };
      this.avalibilityStartDate = data[1].searchStartDate;
      this.selectedDay.setValue(data[1].searchStartDate);
      this.initialSliderValues = {start: data[1].searchStartDate, end: data[1].searchEndDate}
      let newDateObject = new SearchDates();
      newDateObject.searchStartDate = data[1].searchStartDate;
      newDateObject.searchEndDate = data[1].searchEndDate;
      this.selectedDayObject = newDateObject;
      this.checkFacilitieAvailability(this.selectedDayObject);
      this.calculateCost(this.selectedDayObject);
    }
    else {
      this.availability_checked = false;
    }
    this.dateChecked = this.theBookDate;

    this.reservationForm = new FormGroup({
      amount: this.amount,
      title: this.title,
      description: this.description,
    });

    this.amount.setValidators([Validators.required,
    Validators.min(1),
    Validators.max((this.reservation_to_update ? this.reservation_to_update.reservations.length : 0) + this.resourceGroup.availableResourcesToBook)]);

    if (data[2]) {
      this.reservation_mode_new = false;
      this.reservation_to_update = { ...data[2] };
      this.availability_checked = false;
      this.amount.patchValue(this.reservation_to_update.reservations.length);
      this.title.patchValue(this.reservation_to_update.title);
      this.description.patchValue(this.reservation_to_update.description);
    }
    this.selectedDaySub$ = this.selectedDay.valueChanges.subscribe(newValue => {
      let newDate = new Date(newValue);
      let newDateObject = new SearchDates();
      let newDateEnd = newDate;
      newDateEnd.setHours(10);
      newDateObject.searchStartDate = newDate;
      newDateObject.searchEndDate = newDateEnd;
      this.selectedDayObject = newDateObject;
      this.checkFacilitieAvailability(this.selectedDayObject);
    })


    this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {
      resources.forEach(one => {
        if (one.resourceGroup == this.resourceGroup.resourceGroupId) {
          this.raw_resources.push(one);
        }
      });
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(selectFbsUser)).pipe(take(1)).subscribe(valor => {
      this.fbsUser = valor;
    });
    this.store.pipe(select(selectScheduled)).subscribe(schedule => {
      if (schedule) {
        this.schedule = schedule;
        if (this.dateChecked && this.selectedDayObject) {
          this.calculate_schedule_for_today();
        }
      }
    });
  }

  private calculate_schedule_for_today() {
    // NOTE: Booked do not give the schedule in a specified date, so as a template
    //       we take week day
    const date = this.selectedDayObject.searchEndDate.toISOString().split("T")[0];
    let schedule_for_today = this.schedule.periods.find(x => x.date == date);
    if (!schedule_for_today) {
      // Find same day of week and get las occurrence. First is incorrect in some cases.
      schedule_for_today = this.schedule.periods.filter(x => new Date(x.date).getDay() == this.dateChecked.searchStartDate.getDay()).pop();
      schedule_for_today = cloneDeep(schedule_for_today);
      schedule_for_today.schedule_periods.forEach((schedulePeriod) => {
        const dateObject = new Date(date);
        schedulePeriod.start.setDate(dateObject.getDate());
        schedulePeriod.end.setDate(dateObject.getDate());
        schedulePeriod.start.setMonth(dateObject.getMonth());
        schedulePeriod.end.setMonth(dateObject.getMonth());
        schedulePeriod.start.setFullYear(dateObject.getFullYear());
        schedulePeriod.end.setFullYear(dateObject.getFullYear());
      });
      schedule_for_today.date = date;
    }
    this.schedule_for_today = schedule_for_today;
  }


  checkInDayFacilitieAvailability($event: SearchDates) {
    // this.checkAvailabilityComponent.setHourCombos($event);
    let reference_number_list: string[] = []
    if (this.reservation_to_update) {
      this.reservation_to_update.reservations.forEach(reservation => {
        reference_number_list.push(reservation.referenceNumber)
      });
    };
    let the_resources = [];
    this.raw_resources.forEach(one_resource => {
      if (one_resource.resourceGroup == this.resourceGroup.resourceGroupId) {
        const checked_resource = this.resourceAdapter.adapt(one_resource, $event.searchStartDate, $event.searchEndDate, reference_number_list);
        the_resources.push(checked_resource);
      };
    });
    this.dateChecked = $event;
    let sendo = $event.searchEndDate.getMinutes();
    let starto = $event.searchStartDate.getMinutes();
    if (sendo == starto && the_resources && the_resources.length > 0 && $event.searchStartDate.getTime() != $event.searchEndDate.getTime()){
      this.availability_checked = true;
      this.calculate_schedule_for_today();
      this.resourceGroup = this.generateView(the_resources, this.schedule_for_today);
      this.calculateCost($event);
      this.amount.setValidators([Validators.required,
        Validators.min(1),
        Validators.max((this.reservation_to_update ? this.reservation_to_update.reservations.length : 0) + this.resourceGroup.availableResourcesToBook)]);
      this.reservationForm.updateValueAndValidity();
    } else {
      this.resourceGroup = cloneDeep(this.resourceGroup);
      this.availability_checked = false;
    }
    this.cdRef.detectChanges();



  }

  public checkFacilitieAvailability($event: SearchDates) {


    let startDate = $event.searchStartDate;
    let endDate = $event.searchEndDate;
    this.avalibilityStartDate = startDate;
    this.loading = true;

    let call;
    if (this.reservation_mode_new) {
      call = this.facilityBookingService.getAvailableAccessory(startDate, endDate, this.resourceGroup.resourceGroupId, null)
    } else {
      let reference_number_list: string[] = []
      this.reservation_to_update.reservations.forEach(reservation => {
        reference_number_list.push(reservation.referenceNumber)
      });
      call = this.facilityBookingService.getAvailableAccessory(startDate, endDate, this.resourceGroup.resourceGroupId, reference_number_list);
    }

    call.subscribe((data: MicroappFbsResourceModel[]) => {
      this.raw_resources = data;
      this.loading = false;
      this.dateChecked = $event;
      this.availability_checked = true;
      this.calculate_schedule_for_today();
      this.resourceGroup = this.generateView(data, this.schedule_for_today)
      this.calculateCost($event);
      this.reloadSliderComponent();
      this.amount.setValidators([Validators.required,
        Validators.min(1),
        Validators.max((this.reservation_to_update ? this.reservation_to_update.reservations.length : 0) + this.resourceGroup.availableResourcesToBook)]);

    }, error => {

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  generateView(resources: MicroappFbsResourceModel[], schedule_for_today: MicroappFbsPeriod): MicroappFbsResourceGroup {
    let accessory: MicroappFbsResourceGroup;
    resources.forEach(one_resource => {
      if (one_resource.statusId != this.fbsConfig.RESOURCE_STATUS_AVAILABLE) {
        return;
      }
      if (accessory) {
        accessory.totalResources = accessory.totalResources + 1;
        accessory.availableResourcesToBook = accessory.availableResourcesToBook + (one_resource.canBookInSelectedPeriod ? 1 : 0);
        accessory.availableResources = accessory.availableResources + (one_resource.statusId == this.fbsConfig.RESOURCE_STATUS_AVAILABLE ? 1 : 0);
        if (!accessory.slots && one_resource.canBookInSelectedPeriod) {
          accessory.slots = one_resource.slots;
          accessory.canBookInSelectedPeriod = true;
        }
        accessory.resourcesIds.push(+one_resource.resourceId);
        if (accessory.combined_slots) {
          accessory.combined_slots.forEach(combined_slot => {
            if (one_resource.slots) {
              one_resource.slots.forEach(item_one_slot => {
                if (combined_slot.startDateTime.getTime() == item_one_slot.startDateTime.getTime() &&
                  combined_slot.endDateTime.getTime() == item_one_slot.endDateTime.getTime() &&
                  item_one_slot.isReservable && !item_one_slot.isBooked) {
                  combined_slot.slots.push(item_one_slot)
                }
              })
            }
          });
        }

      } else {
        accessory = this.resourceGroupAdapter.adapt(one_resource, schedule_for_today);
      }
    });
    return accessory;
  }


  calculateCost(dateTimes : SearchDates) {
   let diffhour = dateTimes.searchEndDate.getHours() - dateTimes.searchStartDate.getHours();
    this.unitaryCost = (diffhour * (this.resourceGroup.tarif.unit_price * 100)) / 100;
  }

  close() {

    this.dialogRef.close(false);
  }


  go_to_confirm() {
    this.reservation = new MicroappFbsAccessoryReservationModel();
    this.reservation.userId = +this.fbsUser.id;
    if (this.availability_checked) {
      this.initialSliderValues = {start : new Date(), end: new Date()};
      this.reservation.startDate = this.dateChecked.searchStartDate;
      this.reservation.endDate = this.dateChecked.searchEndDate;
      this.initialSliderValues.end = this.dateChecked.searchEndDate;
      this.initialSliderValues.start= this.dateChecked.searchStartDate;
    }
    this.reservation.availableResourceIds = this.resourceGroup.getResourcesIds();
    this.reservation.title = this.title.value;
    this.reservation.description = this.description.value;
    this.reservation.numberToReserve = this.amount.value;
    this.mode_confirm = true;
  }

  confirmBooking() {
    this.loading = true;
    let reservation: MicroappFbsAccessoryReservationModel = new MicroappFbsAccessoryReservationModel();
    reservation.userId = +this.fbsUser.id;
    if (this.availability_checked) {
      reservation.startDate = this.dateChecked.searchStartDate;
      reservation.endDate = this.dateChecked.searchEndDate;
    }
    reservation.availableResourceIds = this.resourceGroup.getResourcesIds();
    reservation.title = this.title.value;
    reservation.description = this.description.value;
    reservation.numberToReserve = this.amount.value;
    if (this.reservation_mode_new) {
      this.facilityBookingService.postAccessoryReservation(reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message(reservation.numberToReserve, response.pending);
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    } else {
      this.facilityBookingService.putAccessoryReservation(this.reservation_to_update.reservationGroupId, reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message(reservation.numberToReserve, response.pending);
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    }
  }
  reloadSliderComponent(){
    this.renderSliderComponent = false;
    this.cdRef.detectChanges();
    this.renderSliderComponent = true;
  }

  ngOnDestroy(): void {
  }



}
