import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import { Store } from '@ngrx/store';
import { MicroappFbsReservationGroupModel } from '../../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsMessageHandlerService } from '../../../lib/microapp-fbs-message-handler.service';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';


@Component({
  selector: 'empusa-microapp-fbs-reservation-delete',
  templateUrl: './microapp-fbs-reservation-delete.component.html',
  styleUrls: ['./microapp-fbs-reservation-delete.component.css']
})
export class MicroappFbsReservationDeleteComponent implements OnInit {
  reservation: MicroappFbsReservationGroupModel;
  loading = false;
  resource: any;
  isAccesory: boolean;
  coin: string;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    public fbsConfig: FbsConfig,
    private messageHandler: MicroappFbsMessageHandlerService,
    @Inject(MAT_DIALOG_DATA) data) {

    this.coin = fbsConfig.CURRENCY;
    this.reservation = data[0];
    this.resource = data[1]



  }

  ngOnInit(): void {

    if ( this.resource.type == "accessory"){
      this.isAccesory = true;
    }else{
      this.isAccesory = false;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  deleteReservation() {

    this.loading = true;
    if (this.reservation.reservationGroupId && this.reservation.reservationGroupId.length>0){

      this.deleteAccessoryReservation(this.reservation.reservationGroupId);
    }else{

      this.deleteFacilityReservation(this.reservation.reservations[0].referenceNumber);
    }

  }

  private deleteAccessoryReservation(reservation_group_id: string) {
    this.facilityBookingService.deleteReservationAccessory(reservation_group_id).subscribe((data: any[]) => {
      this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: null, endDateTime: null }));
      this.messageHandler.show_reservation_message();
      this.loading = false;
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_reservation_error(error.status);
      this.dialogRef.close(false);
    });
  }

  private deleteFacilityReservation(reservation_reference_number: string) {
    this.facilityBookingService.deleteReservationFacility(reservation_reference_number).subscribe((data: any[]) => {
      this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: null, endDateTime: null }));
      this.messageHandler.show_reservation_message();
      this.loading = false;
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_reservation_error(error.status);
      this.dialogRef.close(false);
    });
  }
}
