import { DTOCalendarEvent } from './../../client/dto/calendar-event.dto';
import { DTOCalendarEventType } from './../../client/dto/calendar-event-type.dto';
import { map } from 'rxjs/operators';
import { CalendarService } from './../../client/calendar.service';
import { AfterViewInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ElementRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";

import Calendar from "js-year-calendar";
import CalendarDataSourceElement from "js-year-calendar/dist/interfaces/CalendarDataSourceElement";
import CalendarOptions from "js-year-calendar/dist/interfaces/CalendarOptions";
import "js-year-calendar/locales/js-year-calendar.de";
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: "empusa-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, AfterViewInit {

  @ViewChild('calendarDiv') el: ElementRef;


  calendar: Calendar<CalendarDataSourceElement> = null;

  events: DTOCalendarEvent[];
  filteredEvents: DTOCalendarEvent[]

  eventsRequest: Observable<void>;
  eventTypes: DTOCalendarEventType[];
  eventTypesRequest: Observable<void>;

  selectedEventType: DTOCalendarEventType;

  loading = false;

  constructor(private calendarService: CalendarService){
    this.eventsRequest = this.calendarService.getAllEvents().pipe( map((events) => {
      this.events = events;
    }));
    this.eventTypesRequest = this.calendarService.getAllEventTypes().pipe( map((types) => {
      this.eventTypes = types;
    }));
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    forkJoin([this.eventsRequest, this.eventTypesRequest]).subscribe(() => {
      this.initializeCalendar();
    });
  }

  setFilterEvents(){
    if (this.selectedEventType) {
      this.filteredEvents = this.events.filter(item => item.type === this.selectedEventType.id);
    } else {
      this.filteredEvents = this.events;
    }
    if(this.calendar){
      this.calendar.setDataSource(this.adaptDataToCalendarDataSourceElement(this.filteredEvents));
    }
  }

  initializeCalendar() {
    this.setFilterEvents();
    this.calendar = new Calendar(this.el.nativeElement, {
      dataSource: this.adaptDataToCalendarDataSourceElement(this.filteredEvents),
      enableRangeSelection: false,
      roundRangeLimits: false,
      enableContextMenu: true,
      clickDay: (e) => {
        if(this.selectedEventType){
          const strDate = this.parseISOStringDate(e.date);
          const eventToRemove = this.findEventBySingleDateISOString(strDate);
          this.loading = true;
          if(eventToRemove){
            this.calendarService.deleteEvent(eventToRemove.id).subscribe((allEvents) => {
              this.events = allEvents;
              this.setFilterEvents();
              this.loading = false;
            }, err => {this.loading = false;});
          }else{
            const newEvent = new DTOCalendarEvent(strDate, strDate, "-", "", this.selectedEventType.id);
            this.calendarService.postEvent(newEvent).subscribe((allEvents) => {
              this.events = allEvents;
              this.setFilterEvents();
              this.loading = false;
            },  err => {this.loading = false;});
          }
        }
      }
    } as CalendarOptions<CalendarDataSourceElement>);
  }

  updateCalendar(){
    this.calendar.setDataSource(this.adaptDataToCalendarDataSourceElement(this.events));
  }

  adaptDataToCalendarDataSourceElement(data: any[]): CalendarDataSourceElement[]{
    const adapted: CalendarDataSourceElement[] = [];
    data.forEach((event) => {
      adapted.push({
        startDate: new Date(event.start_date),
        endDate: new Date(event.end_date),
        name: event.name,
        color: this.eventTypes.find(item => item.id === event.type).colour
      } as CalendarDataSourceElement);
    })
    return adapted;
  }

  findEventBySingleDateISOString(strDate: string){
    return this.events.find(item => {
      return item.start_date === strDate && item.end_date;
    });
  }

  parseISOStringDate(date: Date){
    return `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())}`;
  }
  pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

}
