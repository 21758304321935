import { ɵConsole } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Tenant, User } from '@empusa/empusa-core';
import { UserService } from '../user.service';
import { CountUsers } from './userCount';

export class UserTenant {
    tenant: Tenant;
    dataSource: MatTableDataSource<CountUsers>;
    users: CountUsers[] = [];


    constructor(private userService: UserService, tenant) {
        this.tenant = tenant;
        this.countRoles(tenant);
    }

    countRoles(tenant) {
        let countManager: number = tenant.statistics.count.MANAGEMENT;
        let countUser: number = tenant.statistics.count.USER;
        let total: number = tenant.statistics.count.total;
        let datos = new CountUsers(countManager, countUser, total);
        this.users.push(datos);
        this.dataSource = new MatTableDataSource<CountUsers>(this.users);
    }
}
