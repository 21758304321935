<h2 class="titleNew"> {{'MAPP-EPS.QUOTAS.DELETETITLE' | translate}}<button class="closeEditVist" mat-icon-button
    mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
</button></h2>
<div>
    {{'MAPP-EPS.QUOTAS.DELETEASSIGNED' | translate}} {{tenant_name}}?
</div>
<p></p>

<div class="buttonDetail" mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{'MAPP-EPS.GENERICDIALOG.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary" (click)="doAction()">{{'MAPP-EPS.GENERICDIALOG.CONFIRM' | translate}}</button>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>