<div class="reservationConfirm">
    <mat-label  class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.TITLE' | translate}}</b>
        {{_reservation.title}}
    </mat-label>
    <p></p>
    <mat-label  class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.DESCRIPTION' | translate}}</b>
        {{_reservation.description}}
    </mat-label>
    <p></p>
    <mat-label  class="accesoryReservationLabel"> <b>    {{'MAPP-FBS-RESERVATION-CONFIRM.DATE' | translate}}</b>
        {{_dates.searchStartDate | date:'shortDate'}}
    {{_dates.searchStartDate | date:'HH:mm'}} -
    {{_dates.searchEndDate | date:'HH:mm'}}.
    </mat-label>
    <p></p>
    <mat-label  class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.COST' | translate}}</b>
        {{_cost | currency :fbsConfig.CURRENCY:'symbol-narrow'}}
    </mat-label>
    <p></p>

</div>
