import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsVisit } from '../../../lib/model/microapp-vms-visit.model';
import { MicroappVmsNewVisitServices } from '../../../lib/services/microapp-vms-new-visit.services';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'empusa-microapp-vms-resent-notification',
  templateUrl: './microapp-vms-resent-notification.component.html',
  styleUrls: ['./microapp-vms-resent-notification.component.css']
})
export class MicroappVmsResentNotificationComponent implements OnInit, OnDestroy {

  loading: boolean;
  visit: MicroappVmsVisit;
  reNotifySubs$: Subscription;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    private newVisitServices: MicroappVmsNewVisitServices,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data) {
    this.visit = data;
  }

  ngOnInit(): void {
  }

  reSentNotification() {
    console.debug("Resent ", this.visit);
    this.loading = true;
    this.reNotifySubs$ = this.newVisitServices.postVisitReNotify(this.visit.id).subscribe(() => {
      this.loading = false;
      this.openSnackBar("MESSAGE_CODES.EMAIL_SENT");
      this.dialogRef.close(true);
    }, error => {
      console.debug("Resent error ", error);
      this.loading = false;
      let message = "MESSAGE_CODES.GENERIC";
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 0: {
            break;
          }
          case 503: { //Not Sent
            message = "MESSAGE_CODES.EMAIL_FAULT";
            break;
          }
          default: {  //Internal error
            break;
          }
        }
      }
      this.openSnackBar(message);
      this.dialogRef.close(false);
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string) {
    const txt = this.translate.instant(message);
    this.snackBar.open(txt, "X", {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    if (this.reNotifySubs$)
      this.reNotifySubs$.unsubscribe();
  }

}
