import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappVmsVisit } from '../../../lib/model/microapp-vms-visit.model';
import { MicroappVmsNewVisitServices } from '../../../lib/services/microapp-vms-new-visit.services';
import { Subscription } from 'rxjs';
import { MicroappVmsVisitLogAdapter } from '../../../lib/model/microapp-vms-visit-log.adapter';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { VmsConfig } from '../../../lib/microapp-vms-config';

@Component({
  selector: 'empusa-microapp-vms-single-visit-container',
  templateUrl: './microapp-vms-single-visit-container.component.html',
  styleUrls: ['./microapp-vms-single-visit-container.component.css']
})
export class MicroappVmsSingleVisitContainerComponent implements OnInit, OnDestroy {
  visit: MicroappVmsVisit;
  displayLog: Boolean = false;
  servSubscription$: Subscription;
  logAdapter: MicroappVmsVisitLogAdapter;

  constructor(
    private visitServices:MicroappVmsNewVisitServices,   
    private translate: TranslateService,
    private datepipe: DatePipe,
    private vmsConfig: VmsConfig,

    @Inject(MAT_DIALOG_DATA) data) {
      this.visit = data;
  }

  ngOnInit(): void {
    this.logAdapter = new MicroappVmsVisitLogAdapter(this.translate, this.vmsConfig, this.datepipe);

    if (!this.visit.log){
      console.log("Loading logs");
      this.servSubscription$= this.visitServices.getSingleVisitLog(this.visit.id).subscribe((rawLogs)=> {
          console.log("logs:", rawLogs);
          const logs = rawLogs.map ((one_log) => this.logAdapter.adapt(one_log))          
          this.visit.log = logs;
      })
    }
  }

   
  displayVisitLog(){
    this.displayLog = true;

  }
  closeVisitLog(){

    this.displayLog = false;
  }

  ngOnDestroy(): void {
    if (this.servSubscription$)
      this.servSubscription$.unsubscribe();
  }


}
