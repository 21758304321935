import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { VmsConfig } from "../microapp-vms-config";
import { MicroappVmsResponse } from "../model/microapp-vms-response.model";

@Injectable({
    providedIn: 'root'
})
export class MicroappVmsManageVisitService {
    constructor(private http: HttpClient,
        private vmsConfig: VmsConfig,) { }

    checkOutVisit(visitId:string): Observable<MicroappVmsResponse>{
        const url = this.vmsConfig.URL_REST_CHECK_OUT.replace('$1',visitId);
        return this.http.put<MicroappVmsResponse>(url,null).pipe(
            map(response => response as MicroappVmsResponse)
        )
    }
}