export const locale = {
    "lang": "en",
    "data": {
        "MAPP-HVAC": { //mandatory - microapp context
            "DESCRIPTION": "HVAC System",
            "GENERIC.CLEAN": "Clean Filter",
            "FACILITY": {
                "TABS": {
                    "PASSES": "Tariffs Assignment",
                },
                "PASS": {
                    "HVAC": "AHU",
                    "FAN": "FCU",
                },
                "SEARCH": "Search ...",
                "TENANTS": "TENANTS",
                "ACTIONS": "Actions",
                "PARKING_SPACES": "PARKING SPACES",
                "CONCESSIONS": "CONCESSIONS",
                "NAME": "Name",
                "TAGS": "Tags",
                "NUMBERUNIT": "Number of Units",
                "UNITPRICE": "Unit Price",
                "TOTALPRICE": "Total Price",
                "CANCEL": "CANCEL",
                "CREATE": "CREATE",
                "FILTER": "Filter",
                "EXTENSIONS":"Extensions Reservation",
                "RESERVATIONS":{
                    "DATE":" Date",
                    "USER":"Reserver",
                    "HOURS": "Num. Hours",
                    "START":"Extension Start",
                    "END":"Extension End",
                    "DEVICES":"Num. Devices",
                    "ERROR.TITLE":"Operation Failed!",
                    "ERROR.MESSAGE":"An error ocurred while trying to load data"
                }
            },
        }
    }
}