import { NgModule } from '@angular/core';
import { EmpusaMicroApp, TranslationLoaderService } from '@empusa/empusa-core';
import { QuizModule } from '../pages/microapp-quiz.module';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "quiz";
export const MICROAPP_ACCESS_QUIZ: string = "access";
@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: []

})
export class MicroappQuizModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = 'MAPP-QUIZ.DESCRIPTION';
  icon: string = "ballot";
  page = QuizModule;
  homeApp: boolean = false;
  path: string = "quiz";
  order: number = 7;

  constructor(private translationLoader: TranslationLoaderService, private translate: TranslateService) {
    //we register ourselves
    (<any>window).empusa(this);
    this.translationLoader.loadTranslations(english);
  }
}