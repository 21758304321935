import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { showNewParticipant, newVisitLoading, scheduleValid, thereAreParticipants, newVisitError, newVisitDone, groupVisitsMessageCode } from '../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage-selectors';
import * as newVisitActions from '../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions';
import { MicroappVmsVisitVisitor } from '../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit-visitor.model';
import { MatDialogRef } from '@angular/material/dialog';
import { MicroappVmsVisitWithVisitors } from '../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit.model';
import { TranslateService } from '@ngx-translate/core';
import { MICROAPP_VMS_NAME, VmsConfig } from '../../../lib/microapp-vms-config';
import { getMasterConfig } from '../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { VmsMasterConfiguration } from '../../../lib/model/microapp-vms-config.model';
import { AuthenticationService } from '@empusa/empusa-core';


@Component({
  selector: 'empusa-microapp-vms-create-new-visit',
  templateUrl: './microapp-vms-create-new-visit.component.html',
  styleUrls: ['./microapp-vms-create-new-visit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappVmsCreateNewVisitComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['name', 'email','company', 'parking'];
  participantsDataSource: MatTableDataSource<MicroappVmsVisitVisitor>;
  visitDataSource: MatTableDataSource<MicroappVmsVisitWithVisitors>;

  displayedColumnsAppointment: string[] = ['date', 'start', 'end'];
  isLinear = false;
  secondFormGroup: FormGroup;
  displayNewVisit$: Observable<boolean>;
  loading$: Observable<boolean>;
  scheduleValid$: Observable<boolean>;
  thereAreParticipants$: Observable<boolean>;
  saving$: Subscription;
  storeSuscripcion: Subscription;
  forced_off_set=undefined;
  masterCfg: VmsMasterConfiguration;
  can_request_parking: boolean;
  readonly localDateFormat = "dd/MM/yyyy";

  constructor(
    private authservice: AuthenticationService,
    private store: Store<vmsReducer.MicroappVmsStates>,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar,
    private vmsConfig: VmsConfig,
    private translate: TranslateService,
  ) { 
    this.store.select(getMasterConfig).pipe(take(1)).subscribe(masterCfg => {
      this.masterCfg = masterCfg;
      this.can_request_parking = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CAN_REQUEST_PARKING);
      console.debug("Can request parking:", this.can_request_parking);
      if (!this.masterCfg.parking_active || !this.can_request_parking){
        let i = this.displayedColumns.indexOf('parking');
        this.displayedColumns.splice(i,1);
      }
    });
  }

  ngOnInit() {
    console.log("MicroappVmsCreateNewVisitComponent.ngOnInit");
    this.forced_off_set=this.vmsConfig.MASTER_CONFIG.offset;

    this.loading$ = this.store.pipe(select(newVisitLoading)).pipe(
      map(loading => {
        return loading;
      }));

    this.store.dispatch(newVisitActions.resetNewVisit());

    this.displayNewVisit$ = this.store.pipe(select(showNewParticipant)).pipe(
      map(displayNewVisit => {
        return displayNewVisit;
      }));


    this.scheduleValid$ = this.store.pipe(select(scheduleValid)).pipe(
      map(scheduleValid => {
        return scheduleValid;
      }));

    this.thereAreParticipants$ = this.store.pipe(select(thereAreParticipants)).pipe(
      map(thereAreParticipants => {
        return thereAreParticipants;
      }));

    this.storeSuscripcion = this.store.select('groupVisitManage').subscribe(datos => {
      if (datos && datos.visit) {
        this.participantsDataSource =
          new MatTableDataSource<MicroappVmsVisitVisitor>(datos.visit.visitors);
        this.visitDataSource = new MatTableDataSource<MicroappVmsVisitWithVisitors>([datos.visit]);
      }
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    if (this.saving$)
      this.saving$.unsubscribe();
    if (this.storeSuscripcion)
      this.storeSuscripcion.unsubscribe()

  }

  onCancelAddVisitor() {
    this.store.dispatch(newVisitActions.hideNewParticipant());
  }

  onConfirm() {
    this.store.dispatch(newVisitActions.confirmTheNewVisit());
 
    this.saving$ = this.store.pipe(select(newVisitLoading)).subscribe(isLoading => {
      if (!isLoading) {
        this.store.pipe(select(newVisitDone)).pipe(take(1)).subscribe(visitDone =>{
          if (visitDone) {
            this.store.pipe(select(groupVisitsMessageCode)).pipe(take(1)).subscribe(message=>{
              this.openSnackBar(message);
              this.store.dispatch(newVisitActions.resetMessageCode());
              this.dialogRef.close(visitDone)
            })
          } else{
            this.dialogRef.close(false);
          }
        });
      }
    });

  }
  openSnackBar(message: string) {
    const txt = this.translate.instant(message);
    this._snackBar.open(txt, "X", {
      duration: 5000,
    });
  }
}


