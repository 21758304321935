import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'empusa-microapp-vms-tool-bar-visitor',
  templateUrl: './microapp-vms-tool-bar-visitor.component.html',
  styleUrls: ['./microapp-vms-tool-bar-visitor.component.css']
})
export class MicroappVmsToolBarVisitorComponent implements OnInit {
  displayLog: Boolean = false;

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
  }

  displayNewLog(){
      this.displayLog = true;

    }
    closeNewLog(){

      this.displayLog = false;
    }

    onClose() {
      this.dialogRef.close(null);
    }

}
