<h2 class="cabecera">
  <mat-icon class="iconoCabecera">account_balance</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-TARIFF.DESCRIPTION' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="centerHeaderNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-TARIFF.FACILITY.TABS.PRICES' | translate}}">
        <microapp-tariff-facility-prices></microapp-tariff-facility-prices>
      </mat-tab>
      <mat-tab label="{{'MAPP-TARIFF.FACILITY.TABS.CALENDAR' | translate}}" *ngIf="isAdmin || isSuperAdmin">
        <empusa-calendar></empusa-calendar>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>