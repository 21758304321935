import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { Feedback } from '../../../../client/dto/feedback';
import { FeedbackService } from '../../../../client/feedback.service';
import { User } from '../../../../client/dto/user';

@Component({
  selector: 'microapp-quiz.show-feedback',
  templateUrl: './microapp-quiz.show-feedback.component.html',
  styleUrls: ['./microapp-quiz.show-feedback.component.css']
})
export class ShowFeedbackComponent {

  /** form to validate */
  feedbackForm: FormGroup;
  currentUser: User;
  feedback: Feedback;
  isSuperAdmin: boolean;


  constructor(private dialogRef: MatDialogRef<ShowFeedbackComponent>, @Inject(MAT_DIALOG_DATA) private data: Feedback, 
    public translate: TranslateService, private auth: AuthenticationService, private feedbackService: FeedbackService) {
    this.currentUser = this.auth.getCurrentUser()
      this.feedback = data;
    this.feedbackForm = new FormGroup({
      sid: new FormControl(),
      date: new FormControl(''),
      user: new FormControl(''),
      type: new FormControl(),
      feedback: new FormControl(''),
      readBy: new FormControl('')
    });
    if(this.feedback.state == 1){
      this.onSubmit()
    }
    this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
    this.f.sid.setValue(data.sid);
    this.f.date.setValue(this.formatDate(data.date));
    this.f.user.setValue(data.user);
    if(data.type == 0){
      this.f.type.setValue(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.COMMENT"));
    }else if(data.type == 1){
      this.f.type.setValue(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.QUESTION")); 
    }else if(data.type == 2){
      this.f.type.setValue(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.PROBLEM"));
    }else if(data.type == 3){
      this.f.type.setValue(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.IMPROVEMENT"));
    }else if(data.type == 4){
      this.f.type.setValue(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.FIELD.TYPE.RELATED"));
    }
    // this.f.type.setValue(data.type + '');
    this.f.feedback.setValue(data.feedback);
    this.f.readBy.setValue(data.readBy);
  }

  // convenience getter for easy access to form fields
  get f():any { 
    return this.feedbackForm.controls; 
  }

  close(): void {

    this.dialogRef.close(this.feedback);
  }

  onSubmit(){
    this.feedbackService.readFeedback(this.feedback.sid, this.currentUser.mail).subscribe(
      data => {
        this.feedback.type = 3;
        // this.dialogRef.close(this.feedback);
      },
      error => {
        // this.dialogRef.close(null);
      }
    );
  }

  private formatDate(date) {
    var d = new Date(date);
    var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    var month = (d.getMonth()+1) < 10 ? '0' + (d.getMonth()+1) : (d.getMonth()+1);
    return day + "/" + month + "/" + d.getFullYear() + "  " + hours + ":" + minutes;
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}