<!--MODE BOOKING -->

<h2 *ngIf="!mode_confirm" class="titleNew">{{'MAPP-FBS-ACCESSORY-RESERVATION.BOOKING' | translate}} -
  {{resourceGroup.name}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<div *ngIf="!mode_confirm" class="divAccesoryTotal">
  <div class="izquierdaAccesory">
    <div class="firstPart">
      <div *ngIf="!resourceImages || resourceImages.length < 1" class="accesoryImage"></div>
      <div *ngIf="resourceImages && resourceImages.length > 0" class="carouselContainer">
        <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="primary" maxWidth="auto"
          proportion="100" slides="5" [loop]="true" [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="false"
          [useMouseWheel]="false" [maintainAspectRatio]="true" orientation="ltr">
          <mat-carousel-slide #matCarouselSlide *ngFor="let slide of resourceImages; let i = index"
            [image]="fbsConfig.BOOKED_IMAGES_URL + slide" [hideOverlay]="true"></mat-carousel-slide>
        </mat-carousel>
      </div>
      <!-- <mat-label class="resourceName"> {{resourceGroup.name}} </mat-label> -->
      <p></p>
      <mat-label class="accesoryReservationLabel">
        <b>{{'MAPP-FBS-ACCESSORY-RESERVATION.UNITARY_COST' | translate}}</b>
        {{this.resourceGroup.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}.
      </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-ACCESSORY-RESERVATION.TOTAL_UNIS' | translate}}</b>
        {{resourceGroup.availableResources}}.
      </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        {{reservation_to_update?('MAPP-FBS-ACCESSORY-RESERVATION.RESERVED_UNIS' | translate) + " " +
        reservation_to_update.reservations.length + ". ": "" }}
      </mat-label>
    </div>
    <div>
      <p></p>
      <mat-label class="accesoryReservationLabel"><b>{{'MAPP-FBS-ACCESSORY-RESERVATION.DESCRIPTION' | translate}}</b>:
        {{resourceGroup.description}}
      </mat-label>
      <p></p>
      <div *ngIf="!reservation_mode_new">
        <mat-label class="accesoryReservationLabel"><b>Your reservation:
            {{dateChecked.searchStartDate | date:'shortDate'}}
            {{dateChecked.searchStartDate | date:'HH:mm'}} -
            {{dateChecked.searchEndDate | date:'HH:mm'}}.
            {{'MAPP-FBS-ACCESSORY-RESERVATION.AVAILABLE_TO_BOOK' | translate}}</b>
          {{resourceGroup.availableResourcesToBook}}
        </mat-label>
      </div>
      <p></p>
      <div
      *ngIf="!reservation_mode_new">
      <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-ACCESSORY-RESERVATION.COST' | translate}}:</b>
        {{(unitaryCost * amount.value) | currency :fbsConfig.CURRENCY:'symbol-narrow'}}</mat-label>
    </div>
    </div>

  </div>

  <div class="derechaAccesory">
    <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>
    <div>
      <!-- <div>
        <empusa-microapp-fbs-check-availability #checkAvailability [theBookDate]="theBookDate"
          (search)="checkFacilitieAvailability($event)">
        </empusa-microapp-fbs-check-availability>
      </div> -->
      <form [formGroup]="reservationForm">
        <div>
          <mat-form-field class="formFieldAccesory">
            <mat-label>{{'MAPP-FBS-ACCESSORY-RESERVATION.AMOUNT' | translate}}</mat-label>
            <input matInput [formControl]="amount" type="number" [maxLength]="2">
            <mat-error *ngIf="amount.invalid && (amount.dirty || amount.touched)">
              {{'MAPP-FBS-ACCESSORY-RESERVATION.AMOUNT_NOT_VALID' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="formFieldAccesoryTitle">
            <mat-label>*{{'MAPP-FBS-ACCESSORY-RESERVATION.TITLE' | translate}}</mat-label>
            <input matInput [formControl]="title">
            <mat-error *ngIf="title.invalid && (title.dirty || title.touched)">
              {{'MAPP-FBS-ACCESSORY-RESERVATION.TITLE_NOT_VALID' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="formFieldAccesoryDescr">
            <mat-label>{{'MAPP-FBS-ACCESSORY-RESERVATION.DESCRIPTION' | translate}}</mat-label>
            <textarea matInput [formControl]="description"></textarea>
          </mat-form-field>
        </div>
      </form>
      <div *ngIf='reservation_mode_new == true'>
        <div>
          <mat-form-field class="formFieldAccesoryPicker">
            <mat-label>{{'MAPP-EPS.GENERIC.CALENDAR.DAY' | translate}}</mat-label>

            <input matInput [min]="todayDate" [max]="maxDay" [formControl]="selectedDay" [matDatepicker]="pickerEnd">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd" data-date-format="dd/mm/yyyy">
              <mat-icon class="matCalendarIcon"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>

          </mat-form-field>
        </div>
        <div class="changeDate">
          <h5> {{'MAPP-FBS-ACCESSORY-RESERVATION.CHANGE_DATE' | translate}}</h5>
        </div>
      </div>
    </div>
    <!--    <div *ngIf='reservation_mode_new == true'>-->
    <!--      <empusa-microapp-fbs-accessory-slots [resource_group]="resourceGroup"-->
    <!--        (search)="checkInDayFacilitieAvailability($event)" *ngIf="availability_checked || resourceGroup.combined_slots">-->
    <!--      </empusa-microapp-fbs-accessory-slots>-->
    <!--    </div>-->
  </div>
</div>

<div *ngIf="!mode_confirm" style="margin: 0 15px 0 15px;">
  <empusa-microapp-fbs-slider-accessory-slots [accessory]="resourceGroup"
    *ngIf="renderSliderComponent && reservation_mode_new && (availability_checked || (dateChecked && !availability_checked))"
    (onSelectedPeriod)="checkInDayFacilitieAvailability($event)" [initialSliderValues]="initialSliderValues">
  </empusa-microapp-fbs-slider-accessory-slots>
</div>
<div *ngIf="!mode_confirm && reservation_mode_new" class="reservationAccResolution">
  <div class="timeReservation"
    *ngIf="resourceGroup && resourceGroup.canBookInSelectedPeriod && dateChecked && availability_checked && reservation_mode_new">
    <mat-label class="accesoryReservationLabel"> <b> {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.
        {{'MAPP-FBS-ACCESSORY-RESERVATION.AVAILABLE_TO_BOOK' | translate}}</b>
      {{resourceGroup.availableResourcesToBook}}</mat-label>
  </div>
  <div
    *ngIf="(resourceGroup && resourceGroup.canBookInSelectedPeriod && dateChecked && availability_checked && reservation_mode_new)">
    <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-ACCESSORY-RESERVATION.COST' | translate}}:</b>
      {{(unitaryCost * amount.value) | currency :fbsConfig.CURRENCY:'symbol-narrow'}}</mat-label>
  </div>
  <div *ngIf="availability_checked && resourceGroup && !resourceGroup.canBookInSelectedPeriod && reservation_mode_new">
    <mat-label class="accesoryReservationLabel"><b>{{'MAPP-FBS-ACCESSORY-RESERVATION.NOT_POSSIBLE' | translate}}:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
      <span class="intervalHour"> {{'MAPP-FBS-ACCESSORY-RESERVATION.NOT_AVAILABLE' | translate}}</span>
    </mat-label>
  </div>
  <!-- <div *ngIf="!availability_checked && !reservation_mode_new">
  <mat-label class="accesoryReservationLabel">
  {{'MAPP-FBS-ACCESSORY-RESERVATION.CHECK_AVAILABILITY' | translate}}</mat-label>
</div> -->
  <div
    *ngIf="resourceGroup && (resourceGroup.canBookInSelectedPeriod || !resourceGroup.canBookInSelectedPeriod) && dateChecked && !availability_checked && reservation_mode_new">
    <mat-label class="accesoryReservationLabel"><b>{{'MAPP-FBS-ACCESSORY-RESERVATION.NOT_POSSIBLE' | translate}}:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
      <span class="intervalHour"> {{'MAPP-FBS-CHECK_AVAILABILITY.NOPERIOD' | translate}}</span>
    </mat-label>
  </div>
  <!-- <div *ngIf="!reservation_mode_new">
    <mat-label class="accesoryReservationLabel"><b>Your reservation:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.
        {{'MAPP-FBS-ACCESSORY-RESERVATION.AVAILABLE_TO_BOOK' | translate}}</b>
      {{resourceGroup.availableResourcesToBook}}
    </mat-label>
  </div> -->
</div>

<div mat-dialog-actions class="buttonFitting" align="end" *ngIf="!mode_confirm">
  <button mat-raised-button class="buttonClose" (click)="close()">
    {{'MAPP-FBS-ACCESSORY-RESERVATION.CANCEL' | translate}}</button>

  <button mat-raised-button color="primary" class="buttonConfirm" (click)="go_to_confirm()" *ngIf="reservation_mode_new"
    [disabled]="!resourceGroup ||
                !resourceGroup.canBookInSelectedPeriod ||
                !reservationForm.valid ||
                amount.value > resourceGroup.availableResourcesToBook ||
                !availability_checked">
    {{'MAPP-FBS-ACCESSORY-RESERVATION.RESERVE' | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="go_to_confirm()" class="buttonConfirm"
    *ngIf="!reservation_mode_new" [disabled]="!reservationForm.valid || !reservationForm.touched">
    {{'MAPP-FBS-ACCESSORY-RESERVATION.UPDATE' | translate}}
  </button>

</div>

<!-- MODE CONFIRM-->

<h2 *ngIf="mode_confirm" class="titleNew">{{'MAPP-FBS-ACCESSORY-RESERVATION.CONFIRMBOOKING' | translate}}<button
    class="closeEditVist" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<div *ngIf="mode_confirm" class="divAccesoryTotalConfirm">
  <div class="izquierdaAccesory">
    <div>
      <div *ngIf="!resourceImages || resourceImages.length < 1" class="accesoryImage"></div>
      <div *ngIf="resourceImages && resourceImages.length > 0" class="carouselContainer">
        <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="primary" maxWidth="auto"
          proportion="100" slides="5" [loop]="true" [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="false"
          [useMouseWheel]="false" [maintainAspectRatio]="true" orientation="ltr">
          <mat-carousel-slide #matCarouselSlide *ngFor="let slide of resourceImages; let i = index"
            [image]="fbsConfig.BOOKED_IMAGES_URL + slide" [hideOverlay]="true"></mat-carousel-slide>
        </mat-carousel>
      </div>
      <mat-label class="resourceName"> {{resourceGroup.name}} </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-ACCESSORY-RESERVATION.REQUESTED' | translate}} </b>
        {{reservation.numberToReserve}}
      </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        {{reservation_to_update?('MAPP-FBS-ACCESSORY-RESERVATION.RESERVED_UNIS' | translate) + " " +
        reservation_to_update.reservations.length + ". ": "" }}
        <b>{{'MAPP-FBS-ACCESSORY-RESERVATION.UNITARY_COST' | translate}}</b>
        {{this.resourceGroup.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}.
      </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel"><b>{{'MAPP-FBS-ACCESSORY-RESERVATION.DESCRIPTION' | translate}}</b>:
        {{resourceGroup.description}}
      </mat-label>
      <p></p>
    </div>
  </div>
  <div class="derechaAccesory">
    <mat-divider class="dividerAccesoryConfirm" [vertical]="true"></mat-divider>
    <empusa-microapp-fbs-reservation-confirm [reservation]="reservation" [dates]="dateChecked"
      [cost]="(unitaryCost * amount.value)"></empusa-microapp-fbs-reservation-confirm>
  </div>
</div>
<div mat-dialog-actions class="buttonFitting" align="end" *ngIf="mode_confirm">
  <button mat-raised-button color="primary" (click)="mode_confirm=false">
    {{'MAPP-FBS-ACCESSORY-RESERVATION.BACK' | translate}}</button>
  <button class="buttonConfirm" mat-raised-button color="primary" (click)="confirmBooking()">
    {{'MAPP-FBS-ACCESSORY-RESERVATION.CONFIRM' | translate}}
  </button>
</div>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>