<!-- ******************DIALOG NEW GROUP ASSIGN********************************* -->

<div mat-dialog-content>
    <form [formGroup]="assignForm">
        <mat-form-field class="fieldAssign">
            <mat-label>{{'MAPP-FAULTREPORT.EDIT.NEW_GROUP_ASSIGN' | translate}}</mat-label>
            <mat-select required class="selectDetailFault" [(ngModel)]="data.newGroup" formControlName="group">
                <mat-option *ngFor="let li of data.list" [value]="li.cn">{{(li.cn).replace("ADMIN","FACILITY MANAGER")}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="textCommentaryNewGroup">
            <mat-label>{{'MAPP-FAULTREPORT.EDIT.COMMENTARY' | translate}}</mat-label>
            <textarea formControlName="comentary" required class="areaComentaryNeewGroup"
                placeholder="{{'MAPP-FAULTREPORT.EDIT.INTRODUCE' | translate}}" matInput
                [(ngModel)]="data.newGroupComment" name="data.newGroupComment"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonCloseAssign" mat-raised-button mat-dialog-close>
        {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary" [disabled]="!assignForm.valid"
        [mat-dialog-close]="data"> {{'MAPP-FAULTREPORT.CHANGE' | translate}}</button>
</div>
<!-- 

<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close (click)="clean()">
        {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
    <button [disabled]="!newIssueForm.valid" class="buttonConfirm" mat-raised-button color="primary"
        (click)="onSubmit()">
        {{'MAPP-FAULTREPORT.EDIT.SUBMIT' | translate}}</button>
</div> -->