import { MicroappFbsResourceSlotModel } from './faciliti-slots';
import { MicroappFbsTarif } from './microapp-fbs-tarif';

export class MicroappFbsResourceModel{

  resourceId: string;
  quantity: number;
  name: string;
  location: string;
  description: string;
  maxParticipants: number;
  isAvailable: boolean;
  slots: MicroappFbsResourceSlotModel [];
  tarif: MicroappFbsTarif;
  resourceGroup: string;
  chargeType: string;
  chargeTypeDescription: string;
  statusId: number;
  type: string;
  // Default values
  //TODO: ver que significan y asignar los correctos
  scheduleId = 1;
  autoAssignPermissions = true;
  allowMultiday = true;
  requiresCheckIn = true;
  canBookInSelectedPeriod = true;
  image_urls: string[];

}
