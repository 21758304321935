import { CalendarComponent } from './calendar/calendar.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MicroappTariffComponent } from './microapp-tariff.home.component';
import { FacilityPricesComponent } from './prices/microapp-tariff.facility-prices-tariff.component';
import { PriceComponent } from './prices/addprice/microapp-tariff.components-price-tariff.component';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-tariff.mat-spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MicroappTariffGenericComponent } from './generic/microapp-tariff-generic.component';

@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule,
    MatDialogModule,
    HttpClientModule,
    MatCheckboxModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatGridListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatListModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    RouterModule.forChild([
      {
        path: '', component: MicroappTariffComponent
        // , children: [
        //   { path: '', redirectTo: "conf/prices", pathMatch: 'full' },
        //   { path: 'conf/prices', component: FacilityPricesComponent }
        // ]
      }
    ])
  ],
  declarations: [
    MicroappTariffComponent,
    FacilityPricesComponent,
    PriceComponent,
    MatSpinnerOverlayComponent,
    MicroappTariffGenericComponent,
    CalendarComponent
  ],
  providers: [
    MatSnackBar
  ]
})
export class TariffModule {

  constructor(private translationLoader: TranslationLoaderService) {
    console.log("tariff microapp - lazy constructor");

  }

}
