import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceGroup } from '../../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsMessageHandlerService } from '../../../lib/microapp-fbs-message-handler.service';

@Component({
  selector: 'empusa-microapp-fbs-detele-resource',
  templateUrl: './microapp-fbs-detele-resource.component.html',
  styleUrls: ['./microapp-fbs-detele-resource.component.css']
})
export class MicroappFbsDeteleResourceComponent implements OnInit {
  resource: MicroappFbsResourceGroup;
  loading: boolean;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.resource = data;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  deleteResource() {
    this.loading = true;
    if (this.resource.type === 'groupfacility'){
      this.facilityBookingService.deleteFacilityGroup(this.resource.resourceGroupId).subscribe((data: any[]) => {
        this.messageHandler.show_operation_done();
        this.dialogRef.close(true);
      },error=>{
        this.loading = false;
        this.messageHandler.show_management_error(error.status);
        this.dialogRef.close(false);
      });
    }else{
      if (!this.resource.resourceGroupId) {
        this.facilityBookingService.deleteFacility(this.resource.singleResourceId).subscribe((data: any[]) => {
          this.messageHandler.show_operation_done();
          this.dialogRef.close(true);
        },error=>{
          this.loading = false;
          this.messageHandler.show_management_error(error.status);
          this.dialogRef.close(false);
        });
      }else{
        this.facilityBookingService.deleteAccessory(this.resource.getResourcesIds()).subscribe((data ) => {
          this.messageHandler.show_operation_done();
          this.dialogRef.close(true);
        }, error => {
          this.loading = false;
          this.messageHandler.show_management_error(error.status);
          this.dialogRef.close(false);
        });
      }
    }
  }
}
