import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MicroappFbsMessageHandlerService {

    constructor(
        private translate: TranslateService,
        private snackBar: MatSnackBar) { }

    show_management_error(error_status: number) {
        switch (error_status) {
            case 301: {
                this.showMessage("MAPP-FBS-MESSAGES.NO_CHANGES");
                break;
            }
            case 403:{
                this.showMessage("MAPP-FBS-MESSAGES.USER_HAS_NO_ACCESS");
                break;
            }
            case 409: {
                this.showMessage("MAPP-FBS-MESSAGES.RESOURCE_WITH_RESERVATIONS");
                break;
            }
            case 503: { //Service unavailable
                this.showMessage("MAPP-FBS-MESSAGES.OPERATION_FAIL");
                break;
            }
            default: {
                this.showMessage("MAPP-FBS-MESSAGES.OPERATION_FAIL");
                break;
            }
        }
    }


    show_reservation_error(error_status: number) {
        switch (error_status) {
            case 301: {
                this.showMessage("MAPP-FBS-MESSAGES.NO_CHANGES");
                break;
            }
            case 400:{
                this.showMessage("MAPP-FBS-MESSAGES.RESERVATION_NOT_DONE_DATES");
                break;
            }
            case 403:{
                this.showMessage("MAPP-FBS-MESSAGES.USER_HAS_NO_ACCESS");
                break;
            }
            case 409: {
                this.showMessage("MAPP-FBS-MESSAGES.OPERATION_FAIL");
                break;
            }
            case 503: { //Service unavailable
                this.showMessage("MAPP-FBS-MESSAGES.OPERATION_FAIL");
                break;
            }
            default: {
                this.showMessage("MAPP-FBS-MESSAGES.OPERATION_FAIL");
                break;
            }
        }
    }

    show_reservation_message(reservations_requested?: number, reservations_pending?: number) {
        if (!reservations_requested){
            this.showMessage("MAPP-FBS-MESSAGES.OPERATION_DONE");
            return;
        };

        if (reservations_pending == reservations_requested) {
            this.showMessage("MAPP-FBS-MESSAGES.RESERVATION_NOT_DONE");
        } else if (reservations_pending != 0) {
            let msg: string;
            if (reservations_pending == 1)
                msg = this.translate.instant("MAPP-FBS-MESSAGES.RESERVATION_PENDING_1");
            else{
                msg = this.translate.instant("MAPP-FBS-MESSAGES.RESERVATION_PENDING_X");
                msg = msg.replace("%1",""+reservations_pending);
            }
            this.showTranslatedMessage(msg);
        } else {
            this.showMessage("MAPP-FBS-MESSAGES.OPERATION_DONE");
        };
    }

    show_operation_done() {
        this.showMessage("MAPP-FBS-MESSAGES.OPERATION_DONE");
    }

    private showTranslatedMessage(message: string) {
        this.snackBar.open(
            message,
            "X"
            , {
                duration: 5000,
            });
    }

    private showMessage(message: string) {
        this.snackBar.open(
            this.translate.instant(message),
            "X"
            , {
                duration: 5000,
            });
    }
}