<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filtersTypeList">  {{'MAPP-EPS.GENERIC.FILTER' | translate}}
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>
            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="applyGenericFilter($event.target.value)"
                        placeholder="  {{'MAPP-EPS.GENERIC.FILTER' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>
        <div class="header">
            <empusa-microapp-eps-tenant-select class="checkInHeader" (selectedTenant)="selectedTenant($event)">
            </empusa-microapp-eps-tenant-select>
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>

        <div class="table-container">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort id="tablaTariff"
                class="mat-elevation-z8" *ngIf="dataSource">

                <ng-container matColumnDef="chargeType">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-EPS.TARIFF.CHARGE' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.charge.application_charge_code}} - {{element.charge.description}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tariff">
                    <mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.TARIFF.TARIFF' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.tariff ? element.tariff.tarif_name : ''}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> {{'MAPP-EPS.TARIFF.ACTIONS' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button matTooltip="{{'MAPP-EPS.TARIFF.CHANGE' | translate}}" mat-icon-button (click)="editTariff(element)">
                            <mat-icon class="icActions">edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <mat-paginator class="paginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-drawer-container>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>