<div class="generalCard">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filters"> {{'MAPP-EPS.GENERIC.FILTER' | translate}}
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>

            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="applyGenericFilter($event.target.value)"
                        placeholder=" {{'MAPP-EPS.QUOTAS.FILTER' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>

        <!-- HEADER-->
        <div class="header">
            <div class="paypalGST">
                <span class="gstMessage">{{'MAPP-EPS.GENERIC.GST.PAYPAL' |translate}}</span>
                <form [formGroup]="vatForm">
                    <mat-form-field class="formGST" appearance="outline">
                        <input matInput *ngIf="!allowEdit" [readonly]="true" formControlName="vat" name="vat"
                            mt-autofocus>
                        <input matInput *ngIf="allowEdit" mask="999" formControlName="vat" name="vat" mt-autofocus>
                    </mat-form-field>
                    <button mat-icon-button *ngIf="!allowEdit" (click)='vatIsEdit()'>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button [disabled]="!vatForm.valid" *ngIf="allowEdit" (click)='editVat()'>
                        <mat-icon>save</mat-icon>
                    </button>

                </form>
            </div>

            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>

        </div>

        <!-- TABLE -->
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef='tenant_name'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-EPS.PAYPAL.TENANT' |translate}} </th>
                    <td mat-cell [class.editable]="element.flag_editable" *matCellDef="let element">{{element.tenant_name}}</td>
                </ng-container>
                <ng-container matColumnDef='paypal_enabled'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-EPS.PAYPAL.ENABLED' |translate}}</th>
                    <td mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <mat-select *ngIf="element.flag_editable" class="combobox" [value]="element.paypal_enabled"
                            (selectionChange)="onOptionSelected(element,$event)">
                            <mat-option *ngFor="let option of options" [value]="option.op">
                                    {{option.op}}
                            </mat-option>
                        </mat-select>
                        <mat-label *ngIf="!element.flag_editable">
                            <mat-icon *ngIf="element.paypal_enabled">done</mat-icon>
                            <mat-icon *ngIf="!element.paypal_enabled">cancel</mat-icon>
                        </mat-label>
                    </td>

                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px">
                        {{'MAPP-EPS.GENERIC.ACTIONS'|translate}} </th>
                    <td [class.editable]="element.flag_editable" mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="Edit" class="editrow" (click)="setEditable(element)"
                            *ngIf="!element.flag_editable">edit
                        </mat-icon>
                        <button mat-flat-button (click)="saveChanges(element)" *ngIf="element.flag_editable"
                            color="primary">Save</button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="paginator" [pageSizeOptions]="[10,20,50,100]"></mat-paginator>
    </mat-drawer-container>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>