import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { NotificationModule } from '../pages/microapp-notifications.module';
import { NotificationsFakeBackend } from '../fake/fake.backend';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "notifications";
export const MICROAPP_ACCESS_NOTIFICATIONS: string = "access";

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappNotificationsModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = "MAPP-NOTIFICATIONS.DESCRIPTION"
  icon: string = "sms";
  page = NotificationModule;
  homeApp: boolean = false;
  path: string = "not";
  order: number = 11;
  fakebackend: EmpusaFakeBackend = new NotificationsFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english, spanish);
  }

}

