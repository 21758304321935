import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as vmsReducer from '../../lib/microapp-vms.reducer';
import * as addresBookActions from '../../lib/address-book/store/microapp-vms-address-book.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MicroappVmsAddressBookUserFilter } from './microapp-vms-address-book.filter';
import { MicroappVmsVisitorAddressBook } from '../../lib/model/microapp-vms-visitor-address-book';
import { MicroappVmsVisit } from '../../lib/model/microapp-vms-visit.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Tenant, AuthenticationService } from '@empusa/empusa-core';
import { MicroappVmsVisitorContainerComponent } from './visitor-detail/microapp-vms-visitor-container.component';
import { MicroappVmsSingleVisitContainerComponent } from '../common/single-visit-detail/microapp-vms-single-visit-container.component';
import { MicroappVmsVisitorWithPendingVisits } from '../../lib/model/microapp-vms-visitor-pending-visits.model';
import { MicroappVmsDeleteSingleVisitComponent } from '../logbook/delete-visit/single/microapp-vms-delete-single-visit.component';
import { MicroappVmsDeleteVisitorComponent } from './delete-visitor/microapp-vms-delete-visitor.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MicroappVmsUpdateVisitorContainerComponent } from './edit-visitor/microapp-vms-update-visitor-container.component';
import { MICROAPP_VMS_NAME, VmsConfig } from '../../lib/microapp-vms-config';
import { MicroappVmsEditSingleVisitComponent } from '../logbook/edit-visit/single/microapp-vms-edit-single-visit.component';
import { MicroappVmsVisitorFilter } from '../../lib/model/microapp-vms-visitor-filter.model';
import { MicroappVmsResentNotificationComponent } from '../common/resent-notification/microapp-vms-resent-notification.component';
import { MicroappVmsCheckOutVisitComponent } from '../common/check-out-visit/microapp-vms-check-out-visit.component';
import { getMasterConfig } from '../../lib/logbook/store/microapp-vms-logbook-selectors';
import { take } from 'rxjs/operators';
import { VmsMasterConfiguration } from '../../lib/model/microapp-vms-config.model';


@Component({
  selector: 'empusa-microapp-vms-address-book',
  templateUrl: './microapp-vms-address-book.component.html',
  styleUrls: ['./microapp-vms-address-book.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class MicroappVmsAddressBookComponent implements OnInit, OnDestroy {
  //Form
  formGroup: any;
  tenanSearchControl = new FormControl();
  tenants: Tenant[];
  visitorDisplayedColumns: string[] = ['img', 'name', 'company', 'telphone', 'email', 'actions'];
  visitorsDataSource: MatTableDataSource<MicroappVmsVisitorAddressBook>;
  userFilter: MicroappVmsAddressBookUserFilter;
  allTenants = 'all';
  storeSuscripcion: Subscription;

  expandedVisits: MicroappVmsVisit;
  allRowsExpanded: boolean = false;

  displayedVisitColumnsPattern: string[] = ['date', 'start', 'end', 'parking', 'checkIn', 'checkOut', 'in', 'out', 'actions'];
  displayedVisitColumns: string[] = [];

  vistorsTableSize: number;
  masterCfg: VmsMasterConfiguration;


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.visitorsDataSource) {
      this.visitorsDataSource.paginator = mp;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  subsTenanSearchControl: Subscription;
  visitors: MicroappVmsVisitorWithPendingVisits[];
  can_edit: boolean;
  can_delete: boolean;
  can_check_in_out: boolean;
  visitors_with_visits: boolean;
  timezone = this.vmsConfig.MASTER_CONFIG.offset;

  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private authservice: AuthenticationService,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private vmsConfig: VmsConfig,
    private dialog: MatDialog) {
    this.store.select(getMasterConfig).pipe(take(1)).subscribe(masterCfg => {
      this.masterCfg = masterCfg;
      if (!this.masterCfg.parking_active) {
        let i = this.displayedVisitColumnsPattern.indexOf('parking');
        this.displayedVisitColumnsPattern.splice(i, 1);
      }
    });


    if ('auto'.toLowerCase() == vmsConfig.MASTER_CONFIG.check_in_mode.toLowerCase()) {
      let i = this.displayedVisitColumnsPattern.indexOf('checkIn');
      this.displayedVisitColumnsPattern.splice(i, 1);
      i = this.displayedVisitColumnsPattern.indexOf('checkOut');
      this.displayedVisitColumnsPattern.splice(i, 1);
    }

  }

  ngOnInit(): void {
    this.can_edit = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_EDIT_VISITOR);
    this.can_delete = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_DELETE_VISITOR);
    this.can_check_in_out = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CHECK_IN_OUT)
    let showHostId = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_TENANT_SCOPE) ||
      this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_FACILITY_SCOPE);


    const site = this.authservice.getCurrentSite();
    const userSite = this.authservice.getCurrentUser().sites.find(userSite => userSite.uid == site.uid);
    this.tenants = userSite.tenants;
    this.buildForm();
    this.userFilter = new MicroappVmsAddressBookUserFilter();
    this.storeSuscripcion = this.store.select('addressBook').subscribe(datos => {
      if (datos.visitors) {
        console.debug("datos.vis", datos.visitors);
        let visitors_with_visits_temp = false;
        let theHost = '';
        let vistorsTable: MicroappVmsVisitorAddressBook[] = [];
        datos.visitors.forEach(visitor => {
          let oneVisitor = new MicroappVmsVisitorAddressBook();
          oneVisitor.visitor = visitor;
          if (visitor.pending_visits && visitor.pending_visits.length > 0) {
            let tempDataSource = new MatTableDataSource<MicroappVmsVisit>(visitor.pending_visits);
            tempDataSource.sortingDataAccessor = (data: MicroappVmsVisit, sortHeaderId: string): string => {
              console.log("sortingVisits call");
              switch (sortHeaderId) {
                case 'Tenant': return data.tenant.name.toLocaleLowerCase();
                case 'Host': return data.host.firstName.toLocaleLowerCase()
                  + data.host.lastName.toLocaleLowerCase();
                case 'Date': return this.datepipe.transform(data.date, "yyyyMMdd");
                //TODO: Revisar
                //case 'Start': return data.start_time;
                //case 'End': return data.end_time;
                case 'parking': return "" + data.complimentary_parking;
                default:
                  if (typeof data[sortHeaderId] === 'string')
                    return data[sortHeaderId].toLocaleLowerCase();
                  else
                    return data[sortHeaderId];
              }
            };
            oneVisitor.dataSource = tempDataSource;
          }
          vistorsTable.push(oneVisitor);


          if (oneVisitor.visitor.pending_visits && oneVisitor.visitor.pending_visits.length > 0) {
            visitors_with_visits_temp = true
          }
        });

        this.visitors_with_visits = visitors_with_visits_temp;

        this.displayedVisitColumns = Object.assign([], this.displayedVisitColumnsPattern);
        if (showHostId) {
          this.displayedVisitColumns.unshift('host');
        }

        this.visitors = datos.visitors;
        // Assign the data to the data source for the table to render
        let tempVisitorsDataSource = new MatTableDataSource(vistorsTable);
        this.vistorsTableSize = vistorsTable.length;
        //this.visitorsDataSource = new MatTableDataSource(vistorsTable);
        //Sorting case insensitive
        //this.sortingByName(this.visitorsDataSource);
        //this.defineDataSourceFilter(this.visitorsDataSource);
        // this.visitorsDataSource.sort = this.sort;
        this.sortingByName(tempVisitorsDataSource);
        this.defineDataSourceFilter(tempVisitorsDataSource);
        tempVisitorsDataSource.sort = this.sort;

        this.visitorsDataSource = tempVisitorsDataSource;
        if (this.userFilter)
          this.visitorsDataSource.filter = JSON.stringify(this.userFilter);
      }// if change visitors

    });
    this.suscribeToTenantSearchControl();
  }


  private buildForm() {
    if (this.tenants.length > 1) {
      this.formGroup = new FormGroup({
        tenanSearchControl: this.tenanSearchControl,
      });
    } else {
      this.formGroup = new FormGroup({
      });
    }
  }

  sortingByName(tempDataSource: MatTableDataSource<MicroappVmsVisitorAddressBook>) {
    //Sorting case insensitive
    tempDataSource.sortingDataAccessor = (data: MicroappVmsVisitorAddressBook, sortHeaderId: string): string => {
      switch (sortHeaderId) {
        case 'name': return data.visitor.first_name.toLocaleLowerCase() + data.visitor.last_name.toLocaleLowerCase();
        case 'company':
          if (data.visitor.company)
            return data.visitor.company.toLocaleLowerCase();
          else return '';
        case 'telphone':
          if (data.visitor.phone)
            return data.visitor.phone.toLocaleLowerCase();
          else return '';
        case 'email':
          if (data.visitor.email)
            return data.visitor.email.toLocaleLowerCase();
          else
            return '';
        default:
          if (typeof data[sortHeaderId] === 'string')
            return data[sortHeaderId].toLocaleLowerCase();
          else
            return data[sortHeaderId];
      }
    };
  }

  sortingVisits(tempDataSource: MatTableDataSource<MicroappVmsVisit>): MatTableDataSource<MicroappVmsVisit> {
    tempDataSource.sortingDataAccessor = (data: MicroappVmsVisit, sortHeaderId: string): string => {
      switch (sortHeaderId) {
        case 'Tenant': return data.tenant.name.toLocaleLowerCase();
        case 'Host': return data.host.firstName.toLocaleLowerCase()
          + data.host.lastName.toLocaleLowerCase();
        case 'Date': return this.datepipe.transform(data.date, "yyyyMMdd");
        //TODO: Revisar
        //case 'Start': return data.start_time;
        //case 'End': return data.end_time;
        case 'parking': return "" + data.complimentary_parking;
        default:
          if (typeof data[sortHeaderId] === 'string')
            return data[sortHeaderId].toLocaleLowerCase();
          else
            return data[sortHeaderId];
      }
    };
    return tempDataSource;
  }


  defineDataSourceFilter(tempDataSource: MatTableDataSource<MicroappVmsVisitorAddressBook>) {
    tempDataSource.filterPredicate =
      (theVisitor: MicroappVmsVisitorAddressBook, filter: string) => {
        const theFilter: MicroappVmsAddressBookUserFilter = JSON.parse(filter);

        let tenantFilter = false;
        let generalFilter = false;

        if (!theFilter) return true;

        if (theFilter.tenant_id) {
          if (theVisitor.visitor.tenant_id.toLowerCase() == theFilter.tenant_id.toLowerCase()) {
            tenantFilter = true;
          }
        } else tenantFilter = true;


        if (theFilter.general) {
          if (theVisitor.visitor.first_name &&
            theVisitor.visitor.first_name.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          } else if (theVisitor.visitor.last_name &&
            theVisitor.visitor.last_name.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          } else if (theVisitor.visitor.company &&
            theVisitor.visitor.company.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          } else if (theVisitor.visitor.email.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          } else if (theVisitor.visitor.phone &&
            theVisitor.visitor.phone.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;

        return generalFilter && tenantFilter;
      }
  }

  suscribeToTenantSearchControl() {
    if (this.subsTenanSearchControl) return;
    if (!this.visitorsDataSource) return;
    if (this.tenants.length <= 1) return;
    this.subsTenanSearchControl = this.tenanSearchControl.valueChanges.subscribe(tenantSearch => {
      if (this.userFilter.tenant_id == null && tenantSearch === 'all') return;
      if (tenantSearch === 'all') {
        this.userFilter.tenant_id = null;
      } else {
        this.userFilter.tenant_id = tenantSearch;
      }
      this.visitorsDataSource.filter = JSON.stringify(this.userFilter);
    });
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.visitorsDataSource.filter = JSON.stringify(this.userFilter);
  }

  onExpand($event) {
    $event.preventDefault();
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedVisits = null;
  }


  notify(visit: MicroappVmsVisit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit;
    const dialogRef = this.dialog.open(MicroappVmsResentNotificationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.debug("Result:", result);
        let clon = { ...visit }
        clon.notification_status = "N"
        this.store.dispatch(addresBookActions.refreshVisit({ visit: clon }));
      }
    });
  }

  detailVisitor(visitor: MicroappVmsVisitorAddressBook) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'popToolbar'];
    dialogConfig.width = '430px';
    dialogConfig.height = '540px';
    dialogConfig.data = visitor.visitor;
    const dialogRef = this.dialog.open(MicroappVmsVisitorContainerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  detailSingleVisit(visit: MicroappVmsVisit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'popToolbar'];
    let theVisitor = this.visitors.find(visitor => visitor.id == visit.visitor_id);
    let data: MicroappVmsVisit = { ...visit };
    data.tenant = this.tenants.find(tenant => tenant.uid == visit.tenant_id);
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(MicroappVmsSingleVisitContainerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  singleDelete(visit: MicroappVmsVisit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit.id;
    const dialogRef = this.dialog.open(MicroappVmsDeleteSingleVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(actualiza => {
      console.log(`Dialog result actualiza: ${actualiza}`);
      if (actualiza == true) {
        this.store.dispatch(addresBookActions.deletePendingVisit({ visit_id: visit.id, visitor_id: visit.visitor_id }));
      }
    });
  }


  deleteVisitor(visitor: MicroappVmsVisitorAddressBook) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visitor.visitor.id;
    const dialogRef = this.dialog.open(MicroappVmsDeleteVisitorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`deleteVisitor result: ${result}`);
    });
  }

  updateVisitor(visitor: MicroappVmsVisitorAddressBook) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'editVisitor'];
    dialogConfig.data = visitor.visitor;
    const dialogRef = this.dialog.open(MicroappVmsUpdateVisitorContainerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`updateVisitor result: ${result}`);
    });
  }

  updateSingleVisit(visit: MicroappVmsVisit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'editVisit'];
    dialogConfig.width = '650px';
    if (this.vmsConfig.REQUEST_LICENSE_PLATE == true) {
      dialogConfig.width = '900px';
    }
    dialogConfig.maxWidth = '90vw';
    dialogConfig.height = 'auto';
    dialogConfig.data = visit;
    console.log("address book updateSingleVisit", visit);
    const dialogRef = this.dialog.open(MicroappVmsEditSingleVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log("result", result);
      if (result instanceof MicroappVmsVisit) {
        //this.store.dispatch(addresBookActions.refreshVisit({ visit: result }))
        this.reload();
      }
    });

  }

  checkOut(visit_id: number) {
    console.log("Do check-out");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit_id;
    const dialogRef = this.dialog.open(MicroappVmsCheckOutVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result <= 1) {
        this.reload();
      }
    });
  }

  reload() {
    let filter = new MicroappVmsVisitorFilter();
    const userSite = this.authservice.getCurrentUser().sites.find(site => site.uid == this.authservice.getCurrentSite().uid);
    filter.tenants = userSite.tenants;
    this.store.dispatch(addresBookActions.searchingVisitors({ filter, loggedUser: this.authservice.getCurrentUser() }))
  }

  ngOnDestroy(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
  }


}

export interface Edificio {
  nameEdificio: string;
}


