import { TemperaturePipe } from './../lib/pipes/temperature.pipe';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MicroappVmsHomeComponent } from './microapp-vms-home.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as vmsReducer from '../lib/microapp-vms.reducer';
import { MicroappVmsLogbookEffects } from '../lib/logbook/store/microapp-vms-logbook.effects';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MicroappVmsGroupVisitManageEffects } from '../lib/group-visit-manage/store/microapp-vms-group-visit-manage.effects';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MicroappVmsAddressbookEffects } from '../lib/address-book/store/microapp-vms-addressbook.effects';
import { LogBookVisitListResolver } from '../lib/logbook/microapp-vms-logbook.resolver';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MicroappVmsAddressBookResolver } from '../lib/address-book/microapp-vms-address-book.resolver';
import {MatDialogModule} from '@angular/material/dialog';
import { MicroappVmsVisitEditComponent } from './logbook/edit-visit/microapp-vms-visit-edit.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MicroAppVmsDisplayTime } from '../lib/pipes/display-time.pipe';
import {MatTabsModule} from '@angular/material/tabs';
import { MicroappVmsLogbookListComponent } from './logbook/list/microapp-vms-logbook-list.component';
import {MatListModule} from '@angular/material/list';
import { ToolbarVisit } from './common/toolbarVisit/toolbarVisit';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MicroappVmsCustomMatPaginatorIntl } from './common/microapp-vms-paginator';
import { MicroappVmsVisitDetailContainerComponent } from './logbook/visit-detail/microapp-vms-visit-detail-container.component';
import { MicroappVmsVisitDetailComponent } from './logbook/visit-detail/visit/microapp-vms-visit-detail.component';
import { MicroappVmsDeleteGroupVisitComponent } from './logbook/delete-visit/group/microapp-vms-delete-group-visit.component';
import { MicroappVmsDeleteSingleVisitComponent } from './logbook/delete-visit/single/microapp-vms-delete-single-visit.component';
import { MicroappVmsCreateNewVisitComponent } from './group-visit-manage/new-visit/microapp-vms-create-new-visit.component';
import {MatStepperModule} from '@angular/material/stepper';
import { MicroappVmsSelectParticipantsComponent } from './group-visit-manage/component/select-participants/microapp-vms-select-participants.component';
import { MicroappVmsScheduleVisitComponent } from './group-visit-manage/component/schedule/microapp-vms-schedule-visit.component';
import { MicroappVmsAddressBookComponent } from './address-book/microapp-vms-address-book.component';
import {MatChipsModule} from '@angular/material/chips';
import { MicroappVmsVisitorComponent } from './address-book/visitor-detail/visitor/microapp-vms-visitor.component';
import { MicroappVmsVisitorContainerComponent } from './address-book/visitor-detail/microapp-vms-visitor-container.component';
import { MicroappVmsSingleVisitContainerComponent } from './common/single-visit-detail/microapp-vms-single-visit-container.component';
import { MicroappVmsSingleVisitComponent } from './common/single-visit-detail/single-visit/microapp-vms-single-visit.component';
import { MicroappVmsToolBarVisitorComponent } from './common/toolbar-visitor/microapp-vms-tool-bar-visitor.component';
import { MicroappVmsDeleteVisitorComponent } from './address-book/delete-visitor/microapp-vms-delete-visitor.component';
import { MicroappVmsToolBarSingleVisitComponent } from './common/toolbar-single-visit/microapp-vms-tool-bar-single-visit.component';
import { MicroappVmsUpdateVisitorContainerComponent } from './address-book/edit-visitor/microapp-vms-update-visitor-container.component';
import { MicroappVmsVisitorFormComponent } from './common/visitor-form/microapp-vms-visitor-form.component';
import { MicroappVmsUpdateVisitComponent } from './logbook/edit-visit/group/microapp-vms-update-visit.component';
import { MicroappVmsTriStateCheckboxComponent } from './common/tri-state-checkbox/microapp-vms-tri-state-checkbox.component';
import { MicroappVmsEditSingleVisitComponent } from './logbook/edit-visit/single/microapp-vms-edit-single-visit.component';
import { MicroappVmsResentNotificationComponent } from './common/resent-notification/microapp-vms-resent-notification.component';
import { MicroappVmsVisitLogComponent } from './common/single-visit-detail/log/microapp-vms-visit-log.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MicroappVmsCheckOutVisitComponent } from './common/check-out-visit/microapp-vms-check-out-visit.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

export const vmsRoutes = [
  {
    path: '', component: MicroappVmsHomeComponent, children: [
      {
        path: 'logbook',
        component: MicroappVmsLogbookListComponent,
        resolve: {
          visits: LogBookVisitListResolver
        }
      },
      {
        path: 'address-book',
        component: MicroappVmsAddressBookComponent,
        resolve: {
          visitors: MicroappVmsAddressBookResolver,
        }
      },
    ]
  },
];


export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}


@NgModule({
  declarations: [
    MicroappVmsHomeComponent,
    MicroappVmsVisitEditComponent,
    MatSpinnerOverlayComponent,
    MicroappVmsLogbookListComponent,
    ToolbarVisit,
    MicroAppVmsDisplayTime,
    TemperaturePipe,
    MicroappVmsVisitDetailContainerComponent,
    MicroappVmsVisitDetailComponent,
    MicroappVmsDeleteGroupVisitComponent,
    MicroappVmsDeleteSingleVisitComponent,
    MicroappVmsCreateNewVisitComponent,
    MicroappVmsSelectParticipantsComponent,
    MicroappVmsScheduleVisitComponent,
    MicroappVmsAddressBookComponent,
    MicroappVmsVisitorComponent,
    MicroappVmsVisitorContainerComponent,
    MicroappVmsSingleVisitContainerComponent,
    MicroappVmsSingleVisitComponent,
    MicroappVmsToolBarVisitorComponent,
    MicroappVmsDeleteVisitorComponent,
    MicroappVmsToolBarSingleVisitComponent,
    MicroappVmsUpdateVisitorContainerComponent,
    MicroappVmsVisitorFormComponent,
    MicroappVmsUpdateVisitComponent,
    MicroappVmsTriStateCheckboxComponent,
    MicroappVmsEditSingleVisitComponent,
    MicroappVmsResentNotificationComponent,
    MicroappVmsVisitLogComponent,
    MicroappVmsCheckOutVisitComponent,

  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatSortModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    MatStepperModule,
    MatChipsModule,
    FlexLayoutModule,
    StoreModule.forRoot(vmsReducer.microappVmsReducer), // Declaro el reducer con la clave que quiera
    EffectsModule.forRoot([MicroappVmsLogbookEffects, MicroappVmsGroupVisitManageEffects, MicroappVmsAddressbookEffects]), // Registro las classes con efectos.
    RouterModule.forChild(vmsRoutes),
    NgxMaterialTimepickerModule
  ],
  providers: [DatePipe,
    LogBookVisitListResolver,
    { provide: DateAdapter, useClass: DateFormat },
    MicroappVmsAddressBookResolver,
    {
      provide: MatPaginatorIntl,
      useClass: MicroappVmsCustomMatPaginatorIntl
    }
  ],
})

export class MicroappVmsHomeModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    console.log("VMS management lazy constructor");
    dateAdapter.setLocale("en-SG"); // DD/MM/YYYY
  }

}
