<h2 *ngIf="!isEditMode" class="title"> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.MODAL_TITLE' | translate}}
  <button class="close" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<h2 *ngIf="isEditMode" class="title">{{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.EDIT_CHARGE' | translate}}
  <button class="close" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<form [formGroup]="form">
  <div mat-dialog-content class="content">
    <div class="form-row-container">
      <mat-form-field class="custom-form-field" *ngIf="!isEditMode">
        <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.VEHICLE_TYPE' | translate}} </mat-label>
        <mat-select formControlName="vehicle_type" [disabled]="!setSelectableVehicleTypes"
          (selectionChange)="onVehicleTypeChange()">
          <mat-option *ngFor="let option of selectableVehicles" [value]="option">
            {{option.vehicle_short_desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="isEditMode" appearance="none" class="custom-form-field">
        <mat-label>{{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.VEHICLE_TYPE' | translate}}</mat-label>
        <input [readonly]="true" matInput value="{{form.controls.vehicle_type.value.vehicle_code}}">
      </mat-form-field>
      <mat-form-field  *ngIf="!isEditMode" class="custom-form-field">
        <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.SPOT_TYPE' | translate}} </mat-label>
        <mat-select formControlName="spot_type" [disabled]="!selectableSpotTypes"
          (selectionChange)="onSpotTypeChange()">
          <mat-option *ngFor="let option of selectableSpotTypes" [value]="option">
            {{option.spot_short_desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="isEditMode" appearance="none" class="custom-form-field">
        <mat-label>{{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.SPOT_TYPE' | translate}}</mat-label>
        <input [readonly]="true" matInput value="{{form.controls.spot_type.value.spot_type_code}}">
      </mat-form-field>
    </div>
    <div class="form-row-container">
      <mat-form-field   *ngIf="!isEditMode" class="custom-form-field">
        <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.RESERVATION_TYPE' | translate}} </mat-label>
        <mat-select formControlName="reservation_type" [disabled]="!selectableReservationTypes">
          <mat-option *ngFor="let option of selectableReservationTypes" [value]="option">
            {{option.reservation_types_short_desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="isEditMode" appearance="none" class="custom-form-field">
        <mat-label>{{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.RESERVATION_TYPE' | translate}}</mat-label>
        <input [readonly]="true" matInput value="{{form.controls.reservation_type.value.reservation_types_short_desc}}">
      </mat-form-field>
      <mat-form-field class="custom-form-field">
        <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.CHARGE_TYPE' | translate}} </mat-label>
        <mat-select name="save_new_assigned_charge_types" formControlName="charge_type">
          <mat-option *ngFor="let option of chargeTypesToStringArray(epsState.chargesAndTariffs)" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
</form>
<div mat-dialog-actions class="button" align="end">
  <button class="buttonClose" mat-raised-button mat-dialog-close>
    {{'MAPP-EPS.GENERIC.CANCEL' |translate}}
  </button>
  <button class="buttonConfirm" mat-raised-button color="primary" [disabled]="!form.valid" (click)="submitForm()"
    *ngIf="!isEditMode">
    {{'MAPP-EPS.GENERIC.CREATE' |translate}}
  </button>
  <button class="buttonConfirm" mat-raised-button color="primary" [disabled]="!form.valid" (click)="submitForm()"
    *ngIf="isEditMode">
    {{'MAPP-EPS.GENERIC.UPDATE' |translate}}
  </button>
</div>