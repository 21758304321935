import { Observable, of, from, throwError } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmpusaFakeBackend, Tenant } from '@empusa/empusa-core';
import { MICROAPPFAKEDATABASE } from './fake.database';
import { DTOParkingQuote } from '../client/dto/parking-quotes.dto';
import { DTOTarif } from '../client/dto/tarif.dto';
import { User } from '@empusa/empusa-core';
import { ChargesService } from '../client/charges.service';

/**
 * HVAC Fake Backend
 */
export class HVACFakeBackend extends EmpusaFakeBackend {
    microappFakeData = MICROAPPFAKEDATABASE;

    handleRoute(url: string, method: string, headers, body, database: any): Observable<HttpEvent<any>> {
        switch (true) {
            case url.endsWith('TotalSpacesAvailable') && method === 'GET':
                return this.getTotalSpacesAvailable(database);
            case url.endsWith('tenantsParkingPlaces') && method === 'GET':
                return this.getTenantParkingPlaces(database);
            case url.endsWith('freeParkingPlaces') && method === 'GET':
                return this.getFreeParkingPlaces(database);
            case url.endsWith('tenantsQuotes') && method === 'GET':
                return this.getTenantQuotes(database);
            case url.match(/\/sites\/.*$/) && method === 'GET':
                return this.getTenantsBySite(database, EmpusaFakeBackend.idFromUrl(url));
            case url.match(/\/tenantsQuotes\/.*$/) && method === 'PUT':
                return this.updateTenantQuote(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('tarifs') && method === 'POST':
                return this.getTarifs(database);
            case url.endsWith('allowedtags') && method === 'GET':
                return this.getAllowedTags(database);
            case url.endsWith("tarifs") && method === 'PUT':
                return this.setParkingPass(database, body);
            case url.match(/\/tarifs\/.*$/) && method === 'PUT':
                return this.updateTarif(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('tarif') && method === 'POST':
                return this.createTarif(database, body);
            case url.match(/\/tarifs\/.*$/) && method === 'DELETE':
                return this.deleteTarif(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('users') && method === 'GET':
                return this.getUsers(database);
            case url.endsWith('checkAvailability') && method === 'POST':
                return this.getParkingAvailability(database, body);
            case url.endsWith('confirmReservation') && method === 'POST':
                return this.confirmReservation(database, body);
            case url.endsWith('reservation') && method === 'POST':
                return this.reservation(database, body);
            case url.endsWith('tarifs') && method === 'POST':
                return this.getParkingPasses(database, body);
            case url.endsWith('tarifs/filter/tag') && method === 'POST':
                return this.getTarifs(database);
            case url.endsWith('reservationsCost') && method === 'POST':
                return this.getCosts(database, body);
            case url.match(/\/SeasonReservation\/list\/.*$/) && method === 'GET':
                return this.getSeasonReservationList(database);
            default:
                //otherwise, we don't handle this request
                return null;
        }

    }

    getSeasonReservationList(database) {
        let result = {
            TotalLot: 3000
        }
        return EmpusaFakeBackend.ok(result);

    }

    confirmReservation(database, body): Observable<HttpResponse<any>> {
        let reservation_body = JSON.parse(body);
        let results = [];
        for (let i = 0; i < reservation_body.Reservations.length; i++) {
            let reserv = reservation_body.Reservations[i];
            let success = 'Success';
            switch (Math.floor(Math.random() * 3)) {
                case 0:
                    success = 'Success';
                    break;
                case 1:
                    success = 'Reservation_expired';
                    break;
                case 2:
                    success = 'Reservation_not_found';
                    break;
            }
            let result = {
                'ReservationId': reserv.ReservationId,
                'Result': success
            }
            results.push(result);
        }
        return EmpusaFakeBackend.ok({ 'ReturnStatus': 'Success', 'ReturnMessage': '', 'Reservations': results });
    }

    getCosts(database, body) {
        let reservations = JSON.parse(body);
        let costsResults = [];
        reservations.ParkingCosts.forEach(reservation => {
            let cost = {
                'IU': reservation['IU'],
                'Cost': 15
            }
            costsResults.push(cost);
        });

        return EmpusaFakeBackend.ok({ 'ReturnStatus': 'Success', 'ReservationsCost': costsResults });
    }

    setParkingPass(database, body) {
        return EmpusaFakeBackend.ok({ 'success': true });
    }

    getParkingPasses(database, body): Observable<HttpResponse<any>> {
        let filter_body = JSON.parse(body);

        let results = [];
        let codes = [ChargesService.CODE_FAN, ChargesService.CODE_HVAC]
        codes.forEach(code => {
            let element = {
                "application_charge_code": code,
                "billing_system_charge_code": "BSCC_2",
                "description": code,
                "id": "7a3971e6-faf7-4674-96db-0d32fbbf7af8",
                "tags": [
                    "HVAC"
                ],
                "tarif": null
            };
            element.tarif = new DTOTarif();
            Object.assign(element.tarif, database.tarifs[Math.floor(Math.random() * database.tarifs.length)]);
            results.push(element);
        });

        return EmpusaFakeBackend.ok({ 'charge_codes_mapping': results });
    }

    reservation(database, body): Observable<HttpResponse<any>> {
        let check_body = JSON.parse(body);
        let results = [];
        for (let i = 0; i < check_body.Reservations.length; i++) {
            let check = check_body.Reservations[i];
            let success = 'Success';
            switch (Math.floor(Math.random() * 4)) {
                case 0:
                    success = 'Success';
                    break;
                case 1:
                    success = 'No_spaces';
                    break;
                case 2:
                    success = 'No_quota';
                    break;
                case 3:
                    success = 'Fail';
                    break;
            }
            let result = {
                'ReservationId': 'xxxx',
                'IU': check.IU,
                'Plate': check.VehicleNo,
                'Result': (check.VehicleType === 'car' ? 'Success' : success)
            }
            results.push(result);
        }
        return EmpusaFakeBackend.ok({ 'ReturnStatus': 'Success', 'ReturnMessage': '', 'Reservations': results });
    }

    getParkingAvailability(database, body): Observable<HttpResponse<any>> {
        let check_body = JSON.parse(body);
        let results = [];
        for (let i = 0; i < check_body.Reservations.length; i++) {
            let check = check_body.Reservations[i];
            let success = 'Available';
            switch (Math.floor(Math.random() * 4)) {
                case 0:
                    success = 'Available';
                    break;
                case 1:
                    success = 'No_spaces';
                    break;
                case 2:
                    success = 'No_quota';
                    break;
                case 3:
                    success = 'Fail';
                    break;
            }
            let result = {
                'ReservationId': 'xxxx',
                'IU': check.IU,
                'Plate': check.VehicleNo,
                'Result': (check.VehicleType === 'car' ? 'Available' : success)
            }
            results.push(result);
        }
        return EmpusaFakeBackend.ok({ 'ReturnStatus': 'Success', 'ReturnMessage': '', 'Reservations': results });
    }

    getTotalSpacesAvailable(database): Observable<HttpResponse<any>> {
        let result = {
            TotalLot: 3000
        }
        return EmpusaFakeBackend.ok(result);
    }

    getUsers(database): Observable<HttpResponse<any>> {
        let result: User[] = [];
        //creating generics object
        database.users.forEach((x) => {
            let user: User = new User();
            Object.assign(user, x);
            result.push(user);
        });
        return EmpusaFakeBackend.ok({ 'users': result });
    }

    getTenantParkingPlaces(database): Observable<HttpResponse<any>> {
        let result = {
            TenantsParkingPlaces: 2500
        }
        return EmpusaFakeBackend.ok(result);
    }

    getFreeParkingPlaces(database): Observable<HttpResponse<any>> {
        let result = {
            FreeParkingPlaces: 500
        }
        return EmpusaFakeBackend.ok(result);
    }

    getTenantQuotes(database): Observable<HttpResponse<any>> {
        let result: DTOParkingQuote[] = [];
        //creating generics object
        database.quotes.forEach((x) => {
            let quote: DTOParkingQuote = new DTOParkingQuote();
            Object.assign(quote, x);
            result.push(quote);
        });
        return EmpusaFakeBackend.ok({ 'TenantsQuotes': result });
    }

    getTarifs(database): Observable<HttpResponse<any>> {
        let result: DTOTarif[] = [];
        //creating generics object
        database.tarifs.forEach((x) => {
            let tarif: DTOTarif = new DTOTarif();
            Object.assign(tarif, x);
            result.push(tarif);
        });
        return EmpusaFakeBackend.ok({ 'tarifs': result });
    }

    getAllowedTags(database): Observable<HttpResponse<any>> {
        let result: string[] = [];
        //creating generics object
        return EmpusaFakeBackend.ok({ 'tags': database.allowedtags });
    }

    getTenantsBySite(database, id) {
        let result: Tenant[] = [];
        //creating generics object
        database.tenants.forEach((x) => {
            let tenant: Tenant = new Tenant();
            Object.assign(tenant, x);
            result.push(tenant);
        });
        return EmpusaFakeBackend.ok({ 'tenants': result });
    }


    updateTenantQuote(database, body, sid) {
        let quote: DTOParkingQuote = JSON.parse(body.TenantQuote);
        database.quotes = database.quotes.map(x => (x.Tenant.Id == sid ? quote : x));
        return EmpusaFakeBackend.ok(body);
    }

    updateTarif(database, body, sid) {
        let tarif: DTOTarif = JSON.parse(body);
        database.tarifs = database.tarifs.map(x => (x.tarif_id == sid ? tarif : x));
        return EmpusaFakeBackend.ok(body);
    }

    createTarif(database, body) {
        let tarif: DTOTarif = JSON.parse(body);
        tarif.tarif_id = "" + Math.random() * 100;
        database.tarifs.push(tarif);
        return EmpusaFakeBackend.ok(body);
    }

    deleteTarif(database, body, sid) {
        let index = database.tarifs.indexOf(tarif => tarif.tarif_id == sid);
        database.tarifs.splice(index, 1);
        return EmpusaFakeBackend.ok(body);
    }

}