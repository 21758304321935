export const locale = {
    "lang": "en",
    "data": {
        "MAPP-QUIZ": {// mandatory - microapp context
            "DESCRIPTION":"Feedback & Survey",
            "GENERIC.CLEAN": "Clean Filter",
            "MENU":{
                "SURVEY":"Survey",
                "FEEDBACK":"Feedback"
            },
            "TOOLTIP":{
                "SURVEY.EDIT":"Edit Survey",
                "SURVEY.ACTIVATE":"Activate Survey",
                "SURVEY.EXPIRED":"Expire Survey",
                "SURVEY.REPORT":"Report Survey",
                "SURVEY.RESEND":"Resend Survey",
                "SURVEY.DELETE":"Delete Survey",
                "FEEDBACK.FEEDBACK":"Feedback",
                "FEEDBACK.DELETE":"Delete Feedback"
            },
            "DIALOG": {
                "AVTIVE": {
                    "TITLE": "Activate",
                    "QUESTION": "Are you sure you want to activate the survey? Questions cannot be modified afterwards.",
                    "CONFIRMATION": "The survey has been successfully activated",
                    "ERROR": "The survey does not pass the consistency check"
                },
                "INACTIVE": {
                    "TITLE": "Expire",
                    "QUESTION": "Are you sure you want to expire the survey? Survey cannot be activated afterwards.",
                    "CONFIRMATION": "The survey has been successfully expired",
                    "ERROR": "An error occurred while expiring the survey"
                },
                "RESEND": {
                    "TITLE": "Forward Notification",
                    "QUESTION": "Are you sure you want to forward notification?",
                    "CONFIRMATION": "The notification was successfully forwarded",
                    "ERROR": "An error occurred while forwarding the notification"
                },
                "DELETE":{
                    "TITLE": "TITLE"
                }
            },
            "SURVEY": {
                "HEAD": "Survey List",
                "FILTERS": "Filters",
                "FILTER": "Filter",
                "CLEAR":"Clear filters",
                "NEW":"New Survey",
                "LIST.TABLE.ID": "Id",
                "LIST.TABLE.TITLE": "Title",
                "LIST.TABLE.EXPIRES": "Expiration Date",
                "LIST.TABLE.ACTIVE": "Active",
                "LIST.TABLE.STATE": {
                    "TITLE": "State",
                    "INACTIVE": "Inactive",
                    "ACTIVE": "Active",
                    "EXPIRED": "Expired"
                },
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.ON": "Activate",
                "LIST.TABLE.ACTION.OFF": "Expire",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LIST.TABLE.ACTION.REPORT": "Report",
                "LIST.TABLE.ACTION.RESEND": "Forward notification",
                "LISTS.ACTION.NEW": "New"
            },
            "EDITSURVEY": {
                "TITLE": "Edit Survey",
                "NEW":"New Question",
                "FIELD.TITLE": "Title",
                "FIELD.DATESTAR": "Start Date",
                "FIELD.DATEEXPIRED": "Date of Expiration",
                "FIELD.QUESTIONS": "Questions",
                "FIELD.PARTICIPANTS": "Participants",
                "ACTION.SAVE": "Save",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field",
                "INVALID": "This field has an invalid value",
                "OK.MESSAGE": "The record was saved successfully",
                "OK.TITLE": "Operation Successful",
                "ERROR.MESSAGE": "Operation Failed!",
                "ERROR.TITLE": "An error has occurred trying to save the new record!"
            },
            "NEWSURVEY": {
                "TITLE": "New Survey",
                "SUBTITLE": "Fill in the form to create a new survey"
            },
            "QUESTION": {
                "LIST.TABLE.CODE": "Code",
                "LIST.TABLE.QUESTION": "Question",
                "LIST.TABLE.TYPE": "Question Type",
                "LIST.TABLE.TYPES": {
                    "ONE": "Unique Selection",
                    "MULTI": "Multiple Selection",
                    "FREE": "Free Text"
                },
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LISTS.ACTION.NEW": "Add"
            },
            "EDITQUESTION": {
                "TITLE": "Edit Question",
                "NEW":"New Answer",
                "FIELD.CODE": "Code",
                "FIELD.QUESTION": "Question",
                "FIELD.TYPE": "Type of question",
                "FIELD.ANSWERS": "List Answers",
                "ACTION.SAVE": "Save",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field",
                "TYPE": {
                    "SELECT": "Select ...",
                    "ONE": "Unique Selection",
                    "MULTI": "Multiple Selection",
                    "FREE": "Free Text"
                }
            },
            "NEWQUESTION": {
                "TITLE": "New Question",
            },
            "PARTICIPANT": {
                "LIST.TABLE.FIRSTNAME": "Name",
                "LIST.TABLE.LASTNAME": "Surname",
                "LIST.TABLE.EMAIL": "Mail",
                "LIST.TABLE.TENANT": "Tenant",
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LISTS.ACTION.NEW": "Add Participant",
                "LISTS.ACTION.NEWINVITED": "External",
                "LISTS.ACTION.PARTICIPANTS": "Participants",
                "LISTS.ACTION.PARTICIPANTS_TENANTS": "Participants by tenant",
            },
            "EDITPARTICIPANT": {
                "TITLE": "Edit Participant",
                "FIELD.FIRSTNAME": "Name",
                "FIELD.LASTNAME": "Surname",
                "FIELD.EMAIL": "Mail",
                "ACTION.FILTER": "Filter",
                "ACTION.ADD": "Add",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field"
            },
            "NEWPARTICIPANT": {
                "TITLE.EXT": "New External Participant",
                "TITLE.INT": "Add Participant",
                "TITLE.TEN": "Add participants by tenant"
            },
            "ANSWER": {
                "LIST.TABLE.CODE": "Code",
                "LIST.TABLE.ANSWER": "Answer",
                "LIST.TABLE.VALUE": "Value",
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LISTS.ACTION.NEW": "New"
            },
            "REPORT": {
                "HEAD": "Report",
                "TITLE": "",
                "SUBTITLE": "",
                "TOTAL": "Total participants:",
                "COMPLETED": "Participants completed:",
                "ACTION.BACK": "Back"
            },
            "FEEDBACK": {
                "HEAD": "Feedback List",
                "FILTERS": "Filters",
                "FILTER": "Filter",
                "CLEAR":"Clear filters",
                "LIST.TABLE.DATE": "Date",
                "LIST.TABLE.TIME": "Time",
                "LIST.TABLE.TYPE": {
                    "TITLE": "Type",
                    "COMMENT": "Compliment",
                    "QUESTION": "Question",
                    "PROBLEM": "Problem",
                    "IMPROVEMENT": "Area of Improvement",
                    "RELATED": "App Related Issues"
                },
                "LIST.TABLE.DESCRIPTION": "Description",
                "LIST.TABLE.STATE": "Read",
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LIST.TABLE.ACTION.VIEW": "View"
            },
            "SHOWFEEDBACK": {
                "TITLE": "Feedback",
                "FIELD.DATE": "Date",
                "FIELD.USER": "User",
                "FIELD.READBY": "Read By",
                "FIELD.TYPE": {
                    "TITLE": "Type",
                    "COMMENT": "Compliment",
                    "QUESTION": "Question",
                    "PROBLEM": "Problem",
                    "IMPROVEMENT": "Area of Improvement",
                    "RELATED": "App Related Issues"
                },
                "FIELD.DESCRIPTION": "Description",
                "ACTION.CLOSE": "Close",
                "ACTION.READ": "Read",
                "DIALOG": {
                    "TITLE": "Read",
                    "ERROR": "Error marking the feedback as read"
                }
            },
            "DOWNLOAD":{
                "KO.MESSAGE":"ERROR",
                "KO.TITLE":"Error download pdf"
            }
        }
    }
}