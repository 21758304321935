
export function deleteTimeZone(date: Date): Date {
    let date_out: Date;
    console.log(date);
    if (!(date instanceof Date))
        return date;
    // Calculo la fecha sumando el time zone ya que lo voy a ignorar
    let time = date.getTime();
    //Check if timezoneOffset is positive or negative
    if (date.getTimezoneOffset() <= 0) {
        //Convert timezoneOffset to hours and add to Date value in milliseconds                              
        let final = time + (Math.abs(date.getTimezoneOffset() * 60000));
        //Convert from milliseconds to date and convert date back to ISO string                              
        date_out = new Date(final);
    } else {
        let final = time + (-Math.abs(date.getTimezoneOffset() * 60000));
        date_out = new Date(final);
    }
    return date_out
}