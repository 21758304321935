import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


export interface DialogData {
  group: string;
  newGroup: string;
  newGroupComment: string;
  list: string[];
}

@Component({
  selector: 'microapp-faultreport-changeassing-fault-component',
  templateUrl: './microapp-faultreport.component-changeassing-fault.component.html',
  styleUrls: ['./microapp-faultreport.component-changeassing-fault.component.css']
})
export class ChangeassingDialog {

  public assignForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<ChangeassingDialog>, private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService) {

    this.assignForm = this.formBuilder.group({
      group: ['', Validators.required],
      comentary: ['', Validators.required],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
