<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

<div class="fondoGeneral">
   <mat-drawer-container class="drawContainer">
      <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
         <mat-card class="mc">
            <mat-card-content class="filters"> {{'MAPP-VMS-LOGBOOK.FILTER' | translate}}</mat-card-content>
            <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
               <mat-icon class="closeIcFilter">close</mat-icon>
            </button>
         </mat-card>
         <mat-divider></mat-divider>
         <p></p>
         <div class="filtrosEmergentes">
            <mat-form-field>
               <input matInput (keyup)="applyGenericFilter($event.target.value)"
                  placeholder="{{'MAPP-VMS-LOGBOOK.FILTER' | translate}}">
               <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <p></p>
            <!--mat-form-field>
               <mat-label>{{'MAPP-VMS-LOGBOOK.START-DATE' | translate}}</mat-label>
               <input matInput (dateInput)="applyStartDateFilter($event.target.value)" [matDatepicker]="picker">
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>

            </mat-form-field>
            <p></p>

            <mat-form-field>
               <mat-label>{{'MAPP-VMS-LOGBOOK.END-DATE' | translate}}</mat-label>
               <input matInput (dateInput)="applyEndDateFilter($event.target.value)" [matDatepicker]="pickerEnd">
               <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
               <mat-datepicker #pickerEnd></mat-datepicker>

            </mat-form-field>
            <p></p-->
            <!--
            <mat-form-field>
               <input matInput (keyup)="applyStartTimeFilter($event.target.value)"
                  placeholder="{{'MAPP-VMS-LOGBOOK.START-TIME' | translate}}">
               <mat-icon matSuffix>access_time</mat-icon>
            </mat-form-field>
            <p></p>
            <mat-form-field>
               <input matInput (keyup)="applyEndTimeFilter($event.target.value)"
                  placeholder="{{'MAPP-VMS-LOGBOOK.END-TIME' | translate}}">
               <mat-icon matSuffix>access_time</mat-icon>
            </mat-form-field>
            <p></p>
            -->
         </div>
      </mat-drawer>
      <form [formGroup]="formGroup" (ngSubmit)="onContinue()">
         <div class="header">
            <mat-form-field class="selectDate" appearance="outline">
               <mat-select placeholder="{{'MAPP-VMS-LOGBOOK.DATE' | translate}}" [formControl]="dateSearchControl">
                  <mat-option *ngFor="let ds of dateSearch" [value]="ds.value">
                     {{ds.viewValue | translate}}
                  </mat-option>
               </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="tenants.length>1" class="selectTenant" appearance="outline">
               <mat-select placeholder="{{'MAPP-VMS-LOGBOOK.TENANT' | translate}}" [formControl]="tenanSearchControl">
                  <mat-option [value]="allTenants">
                     {{'MAPP-VMS-LOGBOOK.ALL-TENANTS' | translate}}
                  </mat-option>
                  <mat-option *ngFor="let tenant of tenants" [value]="tenant.name">
                     {{tenant.name}}
                  </mat-option>
               </mat-select>
            </mat-form-field>

            <mat-card class="checkListVms">
               <mat-card-content>
                  <section>
                     <mat-label class="check">{{'MAPP-VMS-LOGBOOK.INS' | translate}}:</mat-label>
                     <mat-label class="result">{{inValue}}</mat-label>
                  </section>
                  <section>
                     <mat-progress-bar color="accent" mode="determinate" [value]="inValuePercent" bufferValue="100">
                     </mat-progress-bar>
                  </section>
               </mat-card-content>
            </mat-card>
            <mat-card class="checkListVms">
               <mat-card-content>
                  <section>
                     <mat-label class="check">{{'MAPP-VMS-LOGBOOK.OUTS' | translate}}:</mat-label>
                     <mat-label class="result">{{outValue}}</mat-label>
                  </section>
                  <section>
                     <mat-progress-bar color="accent" mode="determinate" [value]="outValuePercent" bufferValue="100">
                     </mat-progress-bar>
                  </section>
               </mat-card-content>
            </mat-card>

            <button class="expand" mat-button (click)="onExpand($event)" *ngIf="groupVisits && groupVisits.length > 1">
               {{allRowsExpanded ? ('MAPP-VMS-LOGBOOK.COLLAPSE' | translate) : ('MAPP-VMS-LOGBOOK.EXPAND' | translate)}}
            </button>

            <button mat-button (click)="reload()">
               <mat-icon matSuffix>autorenew</mat-icon>
            </button>

            <button mat-icon-button class="buttonExpandFil">
               <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
         </div>
      </form>
      <form [formGroup]="formCustomSearch" (ngSubmit)="customDateSearch()" *ngIf="dateSearchControl.value ==='custom'">
         <div class="subHeader">
            <mat-form-field class="customField">
               <mat-label>{{'MAPP-VMS-LOGBOOK.START-DATE' | translate}}</mat-label>
               <input matInput (dateInput)="changeStart($event)" [formControl]="dateSearchStart"
               [matDatepicker]="pickerStart">
               <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
               <mat-datepicker #pickerStart></mat-datepicker>
               <mat-error>{{'MAPP-VMS-LOGBOOK.DATE-ERROR' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="customField">
               <mat-label>{{'MAPP-VMS-LOGBOOK.END-DATE' | translate}}</mat-label>
               <input matInput [formControl]="dateSearchEnd" [matDatepicker]="pickerEnd">
               <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
               <mat-datepicker #pickerEnd></mat-datepicker>
               <mat-error>{{'MAPP-VMS-LOGBOOK.DATE-ERROR' | translate}}</mat-error>
            </mat-form-field>
            <button type="submit" class="customSearchBtn">
               <mat-icon>search</mat-icon>
            </button>
         </div>
      </form>
      <div class="table-container">
         <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort id='tablaListVms'>

            <ng-container matColumnDef="Tenant">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.TENANT' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.tenant.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Host">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.HOST' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.host.firstName}} {{visitGroup.host.lastName}}
               </mat-cell>
            </ng-container>


            <ng-container matColumnDef="Date">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.DATE' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.start_time | date:localDateFormat:forced_off_set}}
               </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Start">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.START-TIME' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.start_time | date:'HH:mm':forced_off_set}}
               </mat-cell>
            </ng-container>

            <ng-container matColumnDef="End">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.END-TIME' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.end_time | date:'HH:mm':forced_off_set}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="Visitors">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-LOGBOOK.VISITORS' | translate}}
               </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup"> {{visitGroup.visits.length}} <mat-icon class="icVisitor">group
                  </mat-icon>
               </mat-cell>
            </ng-container>


            <ng-container matColumnDef="Actions">
               <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.ACTIONS' | translate}} </mat-header-cell>
               <mat-cell *matCellDef="let visitGroup">
                  <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DETAIL-VISIT-GROUP' | translate}}" mat-icon-button
                     (click)="groupDetail(visitGroup)">
                     <mat-icon class="icActionList">account_box</mat-icon>
                  </button>
                  <button matTooltip="{{'MAPP-VMS-TOOLTIPS.EDIT-VISIT-GROUP' | translate}}" mat-icon-button
                     (click)="updateGroupVisit(visitGroup)"
                     *ngIf="visitGroup.owner && !visitGroup.expired_date && !visitGroup.read_only">
                     <mat-icon class="icActionList">edit
                     </mat-icon>
                  </button>
                  <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DELETE-VISIT-GROUP' | translate}}" mat-icon-button
                     (click)="groupDelete(visitGroup)"
                     *ngIf="visitGroup.owner && !visitGroup.expired_date && visitGroup.visits.length>1 && !visitGroup.read_only">
                     <mat-icon class="icActionList">delete</mat-icon>
                  </button>
               </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
               <td class="tdVisitors" mat-cell *matCellDef="let visitGroup"
                  [attr.colspan]="groupDisplayedColumns.length">
                  <div class="example-element-detail"
                     [@detailExpand]="visitGroup == expandedElement || allRowsExpanded ? 'expanded' : 'collapsed'">
                     <div class="example-element-diagram">
                        {{'MAPP-VMS-LOGBOOK.VISITOR' | translate}} :
                     </div>
                     <div class="example-element-description">
                        <mat-table class="tablaExpandVms" [dataSource]="visitGroup.dataSource">

                           <ng-container matColumnDef="img">
                              <mat-header-cell *matHeaderCellDef></mat-header-cell>
                              <mat-cell class="cellName" *matCellDef="let visit">
                                 <mat-icon *ngIf="visit.delivery_id"
                                    matTooltip="{{'MAPP-VMS-TOOLTIPS.DELIVERY' | translate}}"> delivery_dining
                                 </mat-icon>
                                 <div *ngIf="visit.visit_type=='NSV'">
                                    <mat-icon color="accent" *ngIf="visit.visitor_id"
                                       matTooltip="{{'MAPP-VMS-TOOLTIPS.NON_SCHEDULED_VISIT' | translate}}"> person
                                    </mat-icon>
                                 </div>
                                 <div *ngIf="visit.visit_type!='NSV'">
                                    <mat-icon *ngIf="visit.visitor_id"
                                       matTooltip="{{'MAPP-VMS-TOOLTIPS.SCHEDULED_VISIT' | translate}}"> person
                                    </mat-icon>
                                 </div>
                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                              <mat-cell class="cellName" *matCellDef="let visit">
                                 {{visit.first_name}}
                                 {{visit.last_name}}
                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="company">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.COMPANY' | translate}}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let visit"> {{visit.company}} </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="checkIn">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.CHECK-IN' | translate}}
                              </mat-header-cell>
                              <mat-cell class="cellCheck" *matCellDef="let visit">
                                 {{(!visit.check_in_time && auto_check_in_mode)?('MAPP-VMS-LOGBOOK.AUTO_CHECK_IN' |
                                 translate):(visit.check_in_time | date:'HH:mm':forced_off_set)}}
                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="checkOut">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.CHECK-OUT' | translate}}
                              </mat-header-cell>
                              <mat-cell class="cellCheck" *matCellDef="let visit">
                                 {{visit.auto_check_out?('MAPP-VMS-LOGBOOK.AUTO_CHECK_OUT' |
                                 translate):visit.check_out_time | date:'HH:mm':forced_off_set}} </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="in">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.IN' | translate}}
                              </mat-header-cell>
                              <mat-cell class="cellCheck" *matCellDef="let visit">
                                 {{visit.first_in_time | date:'HH:mm':forced_off_set}}
                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="out">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.OUT' | translate}}
                              </mat-header-cell>
                              <mat-cell class="cellCheck" *matCellDef="let visit">
                                 {{visit.last_out_time | date:'HH:mm':forced_off_set}} </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="extraData">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.EXTRA_DATA' | translate}}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let visit">

                                 <mat-icon class="success-wear-mask" *ngIf="visit.result_check_mask == true"
                                    matTooltip="{{'MAPP-VMS-TOOLTIPS.WEAR_MASK' | translate}}">
                                    masks
                                 </mat-icon>

                                 <mat-icon class="not-wear-mask" *ngIf="visit.result_check_mask == false"
                                    matTooltip="{{'MAPP-VMS-TOOLTIPS.NOT_WEAR_MASK' | translate}}">
                                    masks
                                 </mat-icon>

                                 <ng-container *ngIf="visit.result_check_temperature != null">
                                    <mat-icon matTooltip="{{'MAPP-VMS-TOOLTIPS.VISIT_TEMPERATURE' | translate}}">
                                       thermostat
                                    </mat-icon>
                                    {{visit.result_check_temperature | temperature}}
                                 </ng-container>

                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="parking">
                              <mat-header-cell *matHeaderCellDef>{{'MAPP-VMS-LOGBOOK.PARKING' | translate}}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let visit">
                                 <mat-icon class="icParkingOk"
                                    matTooltip="{{'MAPP-VMS-TOOLTIPS.PARKING_C' | translate}}"
                                    *ngIf="visit.complimentary_parking_status==='C'">
                                    local_parking
                                 </mat-icon>
                                 <mat-icon class="icParkingKo" matTooltip="{{visit.complimentary_parking_message}}"
                                    *ngIf="visit.complimentary_parking_status==='R'"> local_parking
                                 </mat-icon>
                                 <mat-icon class="icParkingKo"
                                    matTooltip="{{'MAPP-VMS-TOOLTIPS.PARKING_E' | translate}}"
                                    *ngIf="visit.complimentary_parking_status==='E'"> local_parking
                                 </mat-icon>
                                 <mat-icon class="icParkingPending"
                                    matTooltip="{{'PARKING_STATUS_CODE.PARKING_P' | translate}}"
                                    *ngIf="visit.complimentary_parking_status==='P'"> local_parking
                                 </mat-icon>
                              </mat-cell>
                           </ng-container>


                           <ng-container matColumnDef="notifications">
                              <mat-header-cell *matHeaderCellDef>{{'MAPP-VMS-LOGBOOK.NOTIFICATION' | translate}}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let visit">
                                 <mat-icon class="icParkingOk" *ngIf="visit.notification_status==='N'"> check
                                 </mat-icon>
                                 <mat-icon class="icParkingOk" *ngIf="visit.notification_status!=='N'"> close
                                 </mat-icon>
                              </mat-cell>
                           </ng-container>

                           <ng-container matColumnDef="actions">
                              <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.ACTIONS' | translate}}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let visit">
                                 <div *ngIf="visit.visitor_id || visit.delivery_id">
                                    <button mat-icon-button matTooltip="{{'MAPP-VMS-TOOLTIPS.NOTIFY' | translate}}"
                                       (click)="notify(visit)"
                                       *ngIf="visit.notification_status!=='N' && !visit.past_visit && visit.email">
                                       <mat-icon class="icActionList">forward_to_inbox</mat-icon>
                                    </button>
                                    <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DETAIL-VISITOR' | translate}}"
                                       mat-icon-button (click)="detailSingleVisit(visit)">
                                       <mat-icon class="icActionList">account_box</mat-icon>
                                    </button>
                                    <button mat-icon-button
                                       matTooltip="{{'MAPP-VMS-TOOLTIPS.EDIT-VISITOR' | translate}}"
                                       (click)="updateSingleVisit(visit)" *ngIf="visit.owner && !visit.past_visit && (visit.check_in_time==null || visit.check_in_time<1)
                                                 && (visit.check_out_time==null || visit.check_out_time<1)
                                                 && (visit.first_in_time==null || visit.first_in_time<1)">
                                       <mat-icon class="icActionList">edit
                                       </mat-icon>
                                    </button>
                                    <button mat-icon-button
                                       matTooltip="{{'MAPP-VMS-TOOLTIPS.DELETE-VISITOR' | translate}}"
                                       (click)="singleDelete(visit.id)" *ngIf="visit.owner && !visit.past_visit && (visit.check_in_time==null || visit.check_in_time<1)
                                                && (visit.check_out_time==null || visit.check_out_time<1)
                                                && (visit.first_in_time==null || visit.first_in_time<1)">
                                       <mat-icon class="icActionList">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button
                                       matTooltip="{{'MAPP-VMS-TOOLTIPS.CHECK_OUT-VISITOR' | translate}}"
                                       (click)="checkOut(visit.id)" *ngIf="visit.owner && can_check_in_out && !visit.past_visit &&
                                             (visit.check_out_time==null || visit.check_out_time<1) &&
                                             ((visit.check_in_time!=null && visit.check_in_time>0) ||
                                              (visit.first_in_time!=null && visit.first_in_time>0))">
                                       <mat-icon class="icActionList">exit_to_app</mat-icon>
                                    </button>
                                 </div>
                              </mat-cell>
                           </ng-container>

                           <mat-header-row *matHeaderRowDef="displayedColumns2" class="element-colum">
                           </mat-header-row>
                           <mat-row *matRowDef="let row; columns: displayedColumns2;" matRipple class="element-row">
                           </mat-row>
                        </mat-table>
                     </div>
                  </div>
               </td>
            </ng-container>

            <mat-header-row class="headerRowPri" *matHeaderRowDef="groupDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let element; columns: groupDisplayedColumns;" class="example-element-row"
               [class.example-expanded-row]="expandedElement === element"
               (click)="expandedElement = expandedElement === element ? null : element; allRowsExpanded = false">
            </mat-row>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
         </table>
      </div>
      <!-- <div [hidden]="true" class="button-newVisit">
         <button mat-fab color="primary" aria-label="Example icon button with a add icon">
            <mat-icon>add</mat-icon>
         </button>
      </div> -->
      <div class="footer-container">
         <div class="button-new-container" *ngIf="can_new_visit">
            <button class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="newVisitDialog()">
               <mat-icon>add </mat-icon>
               <span class='extend_text'>{{'MAPP-VMS-LOGBOOK.NEW-VISIT' | translate}}</span>
            </button>
         </div>
         <div class="paginator-container">
            <mat-paginator class="paginator" [length]="pageLength" [pageSizeOptions]="[20, 50, 10, 100]">
            </mat-paginator>
         </div>
      </div>
   </mat-drawer-container>

</div>