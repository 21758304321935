import { Component, ViewChild, ElementRef, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FaultService } from '../../client/fault.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { Issue } from '../../client/dto/issue';
import { LogIssue } from '../../client/dto/logIssue';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResolveDialog } from '../component/microapp-faultreport.component-resolve-fault.component';
import { CustomField } from '../../client/dto/customfield';
import { MailService } from '../../client/mail.service';
import { MatTableDataSource } from '@angular/material/table';
import { User, AuthenticationService, EmpusaQuestionDialog } from '@empusa/empusa-core';
import { MICROAPP_EDITDETAIL, MICROAPP_NAME } from '../../lib/microapp-faultreport.module';
import { ChangeassingDialog } from '../component/microapp-faultreport.component-changeassing-fault.component';
import { Subscription } from 'rxjs';


export interface MatTicket {
  ticket_id: string
}
@Component({
  selector: 'microapp-faultreport-fault-edit',
  templateUrl: './microapp-faultreport.edit-fault.component.html',
  styleUrls: ['./microapp-faultreport.edit-fault.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EditComponent implements OnDestroy {

  @ViewChild("fileUpload", { static: false }) fileInputElement: ElementRef;
  files = [];
  filesName = [];
  /** form to validate */
  public editForm: FormGroup;
  /**the user being edited */
  editIssue: Issue = new Issue();
  matcher = new MyErrorStateMatcher();
  listLogIssue: LogIssue[] = [];
  logIssue: LogIssue = new LogIssue();
  types: string[] = [];
  categorys: string[] = [];
  locations: string[] = [];
  priorities: Priority[] = [];
  towers: any[] = [];
  floors: any[] = [];
  subcategorys: any[] = [];
  listPriorities = [];
  owners: string[] = [];
  public dataSource = new MatTableDataSource<FilesShow>();
  public displayedColumns = ['filename', 'download', 'delete'];
  currentTicket;
  newGroup: string;
  newGroupComment: string;
  currentUser: User;
  comments: string;
  isNew: boolean = false;
  isAssigned: boolean = false;
  isClose: boolean = false;
  allowEdit: boolean;
  isChecked = false;
  textResolution: string;
  loading: boolean = true;
  subTower$: Subscription;
  subFloor$: Subscription;
  subFloor2$: Subscription;
  subCategory$: Subscription;
  refreshFiles: boolean = false
  displayLog: Boolean = false;
  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  isUser: boolean;
  isManagement: boolean;
  isSub: boolean;
  noTowers: boolean;

  constructor(private activatedRoute: ActivatedRoute, private faultService: FaultService,
    private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService, private router: Router,
    private formBuilder: FormBuilder, private location: Location, private mailService: MailService,
    private auth: AuthenticationService, public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public ticket_id: MatTicket) {
    this.loading = true;
    this.currentUser = this.auth.getCurrentUser();
    this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
    this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
    this.isSub = this.auth.hasCurrentUserRole("SUBCONTRACTORS-GENERAL_HELPDESK");
    this.isUser = this.auth.hasCurrentUserRole("USER");
    this.isManagement = this.auth.hasCurrentUserRole("MANAGEMENT");
    this.getAllCustomFields();
    this.getAllPriorities();
    this.getAllTowers();
    this.getAllCategories();
    this.getAllTypes();
    // this.owners = JSON.parse(localStorage.getItem('owners'));
    this.getAllOwners();
    this.editForm = this.formBuilder.group({
      location: ['', Validators.required],
      tower: ['', Validators.required],
      floor: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      type: ['', Validators.required],
      owner: ['', Validators.required],
      severity: [''],
      status: ['', Validators.required],
      date: ['', Validators.required],
      summary: ['', Validators.required],
      description: [''],
      reporter: ['', Validators.required],
      priority: ['', Validators.required],
      comments: ['', ''],
    });

    this.subTower$ = this.f.tower.valueChanges.subscribe(newValue => {
      this.faultService.getFloorsByTower(newValue).subscribe((data: any) => {
        this.floors = [];
        data.floors.forEach(element => {
          this.floors.push(element)
        });
      })
      this.subFloor$ = this.f.floor.valueChanges.subscribe(newValue => {
        if (newValue != null) {
          this.faultService.getLocationByFloorTower(this.f.tower.value, newValue).subscribe((data: any) => {
            this.locations = [];
            data.locations.forEach(element => {
              this.locations.push(element)
            });
          })
        }
      })
      this.subFloor2$ = this.f.tower.valueChanges.subscribe(newValue => {
        if (newValue != null) {
          this.faultService.getLocationByFloorTower(newValue, this.f.floor.value,).subscribe((data: any) => {
            this.locations = [];
            data.locations.forEach(element => {
              this.locations.push(element)
            });
          })
        }
      })
    })

    this.subCategory$ = this.f.category.valueChanges.subscribe(newValue => {
      this.faultService.getSubcategorieByCategorie(newValue).subscribe((data: any) => {
        this.subcategorys = [];
        data.subcategories.forEach(element => {
          this.subcategorys.push(element)

        });;
      })
    })

    let issue_id: string = ticket_id.ticket_id;
    this.faultService.getIssue(issue_id).subscribe((resp: Issue) => {

      this.loadEditIssue(resp[0], false);
    });
    this.allowEdit = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_EDITDETAIL);
  }

  ngOnDestroy(): void {
    if (this.subCategory$) {
      this.subCategory$.unsubscribe();
    }
    if (this.subFloor$) {
      this.subFloor$.unsubscribe();
    }
    if (this.subFloor2$) {
      this.subFloor2$.unsubscribe();
    }
    if (this.subTower$) {
      this.subTower$.unsubscribe();
    }
  }

  getAllPriorities() {
    this.faultService.allPriorities().subscribe((resp: string[]) => {
      this.listPriorities = resp;
    });
  }

  getAllTowers() {
    this.faultService.getTowers().subscribe((resp: any) => {
      if(resp.towers.length > 1){
        this.noTowers=false;
        resp.towers.forEach(element => {
          this.towers.push(element);
        });
        this.loading = false;
      }else{
        this.noTowers = true;
        this.f.tower.setValue(resp.towers[0]);
        this.loading = false;
      }
    })
  }

  getAllOwners() {
    let building = this.auth.getCurrentSite().uid;
    this.faultService.allOwners(building).subscribe((resp: string[]) => {
      this.owners = resp;
    });
  }

  loadEditIssue(resp: Issue, upload: boolean) {
    this.setDate(resp.date)
    this.editIssue = resp;
    this.f.status.setValue(resp.status);
    resp.owner = resp.owner.replace("ADMIN", "FACILITY MANAGER")
    if (resp.status === 'closed') {
      this.isNew = false;
      this.isAssigned = false;
      this.isClose = true;
      this.f.owner.setValue(resp.owner);
    } else if (resp.status === 'new') {
      this.isNew = true;
      this.isAssigned = false;
      this.isClose = false;
    } else if (resp.status === 'assigned') {
      this.isNew = false;
      this.isAssigned = true;
      this.isClose = false;
      this.f.owner.setValue(resp.owner);
    }
    
    this.f.summary.setValue(resp.summary);
    this.f.description.setValue(resp.description);
    this.f.reporter.setValue(resp.reporter);
    this.f.category.setValue(resp.category);
    this.f.subcategory.setValue(resp.subcategory);
    this.f.location.setValue(resp.location);
    this.f.tower.setValue(resp.tower);
    this.f.floor.setValue(resp.floor);
    this.f.type.setValue(resp.type);
    this.f.priority.setValue(resp.priority);
    if (this.allowEdit) {
      this.allowEdit = resp.status === 'closed' ? false : true;
    }
    this.currentTicket = resp.ticket_id;
    this.getEventLog(upload);
  }

  getEventLog(upload: boolean) {
    this.faultService.getChanges(this.currentTicket).subscribe((resp: LogIssue[]) => {
      let date: string = '';
      for (let i = 0; i < resp.length; i++) {
        let formatedBarra: String = resp[i].date.slice(0, 10);
        let formatesGuion = formatedBarra.replace(/\//g, '-');
        var reverseDate = formatesGuion.split("-").reverse().join("-");
        let formated = reverseDate + "T" + resp[i].date.slice(11) + "Z";
        let dateCreated = new Date(formated).toLocaleString();
        resp[i].date = dateCreated
      }
      let listComment: LogIssue[] = [];
      if (this.isUser || this.isManagement) {
        resp.forEach(comment => {
          if (comment.field == 'owner') {
            resp = resp.filter(obj => obj !== comment)
          }
        })
        this.listLogIssue = resp;
      } else {
        this.listLogIssue = resp;
      }

      if (!upload) {
        this.getFiles(upload);
      }
    });
  }

  getFiles(upload: boolean) {
    this.filesName = [];
    this.faultService.getFiles(this.currentTicket).subscribe((resp: FilesShow[]) => {
      this.filesName = resp;
      if (this.refreshFiles == true) {
        this.loading = false;
        this.refreshFiles = false;
      }
    });
  }

  getAllTypes() {
    this.faultService.allTypes().subscribe((resp: string[]) => {
      this.types = resp;
      this.loading = false;
      // this.getAllCustomFields();
    });
  }


  getAllCategories() {
    this.faultService.getCategory().subscribe((resp: any) => {
      resp.categories.forEach(element => {
        this.categorys.push(element);
      });
    })
  }

  getAllCustomFields() {
    this.faultService.allCustomFields().subscribe((resp: CustomField[]) => {
    });
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.editForm.controls; }

  /**
   * @name toBack
   * @description cancel the edition
   */
  toBack() {
    this.location.back();
  }

  //UPLOADS  
  changeAssign(): void {
    const dialogAssign = this.dialog.open(ChangeassingDialog, {
      width: '350px',
      data: { group: this.f.owner.value, list: this.owners }
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.loading = true;
      console.log('The dialog was closed');
      if (result) {
        this.faultService.updateIssueOwner(this.currentTicket, result.newGroup, result.newGroupComment,
          this.currentUser.mail).subscribe((resp: Issue) => {
            this.loading = false;
            this.loadEditIssue(resp[0], true);
            this.mailService.changeAsignIssue();
            this.snackBar.open(
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE")
              , {
                duration: 5000,
              });
          },
            error => {
              this.loading = false;
              this.snackBar.open(
                this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
                this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE")
                , {
                  duration: 5000,
                });
            }
          );
      } else {
        this.loading = false;
      }
    });
  }

  toAddComment() {
    const dialogAssign = this.dialog.open(ResolveDialog, {
      width: '448px'
    });
    dialogAssign.afterClosed().subscribe(newComment => {
      console.log('The dialog was closed');
      if (newComment != undefined) {
     /*let newComment = this.f.comments.value;
    if(newComment!=null && newComment!='' && newComment!=' '){
   */   this.faultService.updateIssueComment(this.currentTicket, newComment, this.currentUser.mail).subscribe((resp: Issue) => {
        this.loadEditIssue(resp[0], true);
        this.mailService.updateIssue();
        this.f.comments.setValue('');
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE")
          , {
            duration: 5000,
          });
      },
        error => {
          this.loading = false;
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE")
            , {
              duration: 5000,
            });
        });
      }
    });

  }

  commentDeleteFile(comment: string) {
    this.faultService.updateIssueComment(this.currentTicket, comment, this.currentUser.mail).subscribe((resp: Issue) => {
      this.loadEditIssue(resp[0], true);
      this.mailService.updateIssue();
      this.f.comments.setValue('');
    });
  }


  //FILES
  deleteFile(filename: string) {
    this.loading = true
    this.filesName.forEach((item, index) => {
      this.faultService.deleteFile(this.currentTicket, filename).subscribe((event: any) => {
        if (event === 'ok') {
          this.refreshFiles = true;
          this.getEventLog(false);
          this.getFiles(false);
          let deleteComment = "The file " + filename + " has been deleted"
          this.commentDeleteFile(deleteComment)
          this.mailService.updateIssue();
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.TITLE")
            , {
              duration: 5000,
            });
        }
      },
        error => {
          this.loading = false
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.TITLE")
            , {
              duration: 5000,
            });
        })
      if (item.filename === filename) {
        this.files.splice(index, 1);
        this.filesName.splice(index, 1);
      }
    });
  }

  downloadFile(filename: string) {
    this.faultService.downloadFile(this.currentTicket, filename).subscribe((resp) => {
      var a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(resp);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK_DOWNLOAD.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK_DOWNLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  onClick() {
    this.loading = true;
    const fileUpload = this.fileInputElement.nativeElement; fileUpload.onchange = () => {
      this.loading = true;
      let wait: boolean = false;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.filesName.forEach(searchFile => {
          if (searchFile.filename == file.name) {
            wait = true;
          }
        });
        if (file.size > 11322929) {
          this.snackBar.open(
            this.translate.instant("The file " + file.name + " is too big to upload"),
            this.translate.instant("Max Size is 10 MB")
            , {
              duration: 5000,
            });
        } else {
          this.files.push({ data: file, inProgress: false, progress: 0 });
        }
      }
      if (wait) {
        let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
          data: {
            title: this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_VERSION.TITLE"),
            question: this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_VERSION.MESSAGE"),
            icon: 'warning'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = false;
            console.log("same name");
            this.uploadFiles();
          } else {
            this.loading = false;
            this.files = [];
            dialogRef.close();
          }
        });
      } else {
        this.loading = false;
        this.uploadFiles();
      }

    };
    this.loading = false;
    fileUpload.click();
  }

  uploadFiles() {
    this.fileInputElement.nativeElement.value = '';
    for (let i = 0; i < this.files.length; i++) {
      let last = false;
      if (i == this.files.length - 1) {
        last = true;
      }
      this.uploadFile(this.files[i], last);
    }
  }

  uploadFile(file, last) {
    const formData: FormData = new FormData();
    formData.append('name', file.data.name);
    formData.append('file', file.data);
    file.inProgress = true;
    this.loading = true;
    this.faultService.upload(formData, this.currentTicket).subscribe((event: any) => {
      if (last == true) {
        if (event.body) {
          this.refreshFiles = true;
          // this.getFiles(false);
          this.getEventLog(false);
          // this.loading = false;
          this.mailService.updateIssue();
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.TITLE")
            , {
              duration: 5000,
            });
        }
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  /**
   * @name onSubmit
   * @description the info is being sent to the backend
   */
  onUpdate() {
    this.loading = true;
    var datas = new Issue;
    datas.date = this.f.date.value;
    datas.ticket_id = this.currentTicket;
    datas.status = this.f.status.value;
    datas.tower = this.f.tower.value;
    datas.floor = this.f.floor.value;
    datas.subcategory = this.f.subcategory.value;
    datas.priority = this.f.priority.value;
    datas.owner = this.f.owner.value;
    datas.summary = this.f.summary.value;
    datas.description = this.f.description.value;
    datas.type = this.f.type.value;
    datas.reporter = this.f.reporter.value;
    datas.location = this.f.location.value;
    datas.user = this.currentUser.mail;
    datas.email = this.currentUser.mail;
    datas.comment = this.f.comments.value;
    datas.category = this.f.category.value;
    datas.severity = "1";
    //lets update location, device and type
    let newLocation: string;
    let newcategory: string;
    let newType: string;
    let newPriority: string;
    let newSeverity: string;
    newLocation = this.f.location.value;
    newcategory = this.f.category.value;
    newType = this.f.type.value;
    newPriority = this.f.priority.value;
    newSeverity = "1"
    // this.faultService.getPriority(newcategory, newType, newLocation).subscribe((resp) => {
    //   newSeverity = resp.severity;
    //   datas.severity = newSeverity;
    if (this.isChecked) {
      if (this.textResolution == undefined || this.textResolution == '') {
        this.snackBar.open(
          this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOTHING.RESOLUTION.MESSAGE'),
          this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOTHING.RESOLUTION.TITLE')
          , {
            duration: 5000,
          });
      } else {
        console.log("resolve");
        datas.comment = this.textResolution;
        if (newLocation != null || newcategory != null || newType != null || newPriority != null) {
          this.faultService.updateIssueResolve(datas).subscribe((resp: Issue) => {
            // this.loadEditIssue(resp[0], true);
            this.closeDialog();
            this.loading = false;
            this.mailService.resolveIssue();
            this.snackBar.open(
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE")
              , {
                duration: 5000,
              });
          },
            error => {
              this.loading = false;
              this.snackBar.open(
                this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
                this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE")
                , {
                  duration: 5000,
                });
            });
        }
      }
    } else {
      // newPriority = this.f.priority.value;
      if (newLocation != null || newcategory != null || newType != null || newPriority != null) {
        // this.faultService.updateIssueEditable(this.currentTicket, newLocation, newcategory, newType, newPriority, this.currentUser.mail, this.f.owner.value,
        //    this.f.status.value )
        this.faultService.updateIssueEditable(datas)
          .subscribe(data => {
            this.loadEditIssue(data[0], true);
            this.mailService.updateIssue();
            this.closeDialog();
            this.loading = false;
            this.snackBar.open(
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
              this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE")
              , {
                duration: 5000,
              });
          },
            error => {
              this.loading = false;
              this.snackBar.open(
                this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE'),
                this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE')
                , {
                  duration: 5000,
                });
            }
          );
      } else {
        this.snackBar.open(
          this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOTHING.MESSAGE'),
          this.translate.instant('MAPP-FAULTREPORT.EDIT.UPDATE.NOTHING.TITLE')
          , {
            duration: 5000,
          });
      }
    }
    // });
  }

  closeDialog() {
    console.log('closeDialog');
    this.dialogRef.close();
  }

  setDate(date) {
    let formatedBarra: String = date.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + date.slice(11) + "Z";
    let dateCreated = new Date(formated).toLocaleString();
    this.f.date.setValue(dateCreated);
  }

  displayVisitLog() {
    this.displayLog = true;
  }
  closeVisitLog() {
    this.displayLog = false;
  }
}

export class FilesShow {
  filename: string;
}

export class Priority {
  name: string;
  value: number;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

