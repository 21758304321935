import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ViewEncapsulation, resolveForwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Issue } from '../../client/dto/issue';
import { FaultService } from '../../client/fault.service';
import { MatCalendar } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { EscalationDialog } from '../component/microapp-faultreport.component-escalation-fault.component';
import { MailService } from '../../client/mail.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MailComponent } from '../component/microapp-faultreport.component-mail-fault.component';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { EditComponent } from '../edit/microapp-faultreport.edit-fault.component';
import { NewIssueComponent } from '../new/microapp-faultreport.new-fault.component';
import { MICROAPP_ESCALATION, MICROAPP_NAME, MICROAPP_CREATE, MICROAPP_SHOWDETAIL, MICROAPP_EDITDETAIL } from '../../lib/microapp-faultreport.module';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MicroappFaultListFilter } from './microapp.faultreport.list.filter';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subscription } from 'rxjs';

@Component({
  selector: 'microapp-faultreport-fault-list',
  templateUrl: './microapp-faultreport.list-fault.component.html',
  styleUrls: ['./microapp-faultreport.list-fault.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ListFaultComponent implements OnInit {
  displayedColumns: string[] = ['select', 'date', 'summary', 'owner', 'priority', 'status', 'read', 'actions'];
  dataSource = new MatTableDataSource([]);
  currentIssue: Issue;
  currentUser: User;
  selection = new SelectionModel<Issue>(true, []);
  owners: string[] = [];
  listStatus: string[] = [];
  priorities: string[] = [];
  listPriorities = [];
  allowEscalate: boolean;
  allowCreate: boolean;
  // allowDetail: boolean;
  allowDetail: Promise<boolean>;
  allowSeverity: boolean;
  loading: boolean = true;
  filterValues = {};
  filterSelectObj = [];
  dateFilterStart;
  dateFilterEnd;
  ownerFilter;
  summaryFilter;
  userFilter = new MicroappFaultListFilter();
  filterGroup: FormGroup;
  today = new Date();
  count = 0;
  selectable: boolean = false;
  length: number;
  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  isUser: boolean;
  isManagement: boolean;
  searchForm: FormGroup;
  dateFrom: Date;
  dateTo: Date;
  maxSelectTo: Date;
  minSelectTo: Date;
  subDate$: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = mp;
    } 
  }
  @ViewChild(MatCalendar) datePicker: MatCalendar<Date>
  readonly localDateFormat = "dd/MM/yyyy HH:mm";

  constructor(private faultService: FaultService, private dialog: MatDialog,
    private snackBar: MatSnackBar, private mailService: MailService,
    private datepipe: DatePipe,
    private translate: TranslateService, private formBuilder: FormBuilder,
    private auth: AuthenticationService, private _adapter: DateAdapter<any>) {

    this.searchForm = this.formBuilder.group({
      FROM: ['', Validators.required],
      TO: ['', Validators.required]
    });

    this.auth.loadCurrentSite().then(site => {
      this.currentIssue = JSON.parse(localStorage.getItem('currentIssue'));
      this.currentUser = this.auth.getCurrentUser();
      this.allowEscalate = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_ESCALATION);
      this.allowCreate = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_CREATE);
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.isUser = this.auth.hasCurrentUserRole("USER");
      this.isManagement = this.auth.hasCurrentUserRole("MANAGEMENT");
      let d = new Date();
      let da = new Date();
      let month_before = new Date(d.setMonth(d.getMonth() - 1));
      this.maxSelectTo = da;
      this.minSelectTo = month_before;
      this.dateFrom = month_before;
      this.dateTo = da;
      this.s.FROM.setValue(month_before);
      this.s.TO.setValue(da);
      this.allowDetail = (this.auth.canUserExecuteAsync(MICROAPP_NAME, MICROAPP_SHOWDETAIL)
        || this.auth.canUserExecuteAsync(MICROAPP_NAME, MICROAPP_EDITDETAIL));
      this.allowSeverity = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_EDITDETAIL);
      // this._adapter.setLocale('en');
      this.getAllPriorities();
      this.updateFault();
    })

    this.subDate$ = this.s.FROM.valueChanges.subscribe((newValue : Date) => {
      let changeNew = new Date(newValue);
      let changeFromDate = new Date(changeNew.setMonth(changeNew.getMonth() + 6))
      let dateToday = new Date();
      if(changeFromDate > dateToday){
        this.s.TO.setValue(dateToday)
        this.maxSelectTo = dateToday;
      }else{
        this.s.TO.setValue(changeFromDate);
        this.maxSelectTo = changeFromDate;
      }
      this.minSelectTo = new Date(newValue);
    })
  }

  getAllPriorities() {
    this.faultService.allPriorities().subscribe((resp: string[]) => {
      this.listPriorities = resp;
    });
  }

  ngOnInit() {



    //we set the datasource of the table
    // this.updateFault();
    this.filterGroup = this.buildFilter();
  }

  searchReports() {
    this.loading= true;
    this.dateTo = this.s.TO.value;
    this.dateFrom = this.s.FROM.value;
    this.updateFault();

  }

  buildFilter() {

    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      START: new FormControl(""),
      END: new FormControl(""),
      PRIORITY: new FormControl(""),
      STATUS: new FormControl(""),
      READ: new FormControl(""),
    })
  }

  get s() { return this.searchForm.controls; }

  // loadPriorities(data: any) {
  //   this.faultService.allPriorities().subscribe((dataP) => {
  //     localStorage.setItem('priorities', JSON.stringify(dataP));
  //     for (let p = 0; p < dataP.length; p++) {
  //       this.priorities.push(dataP[p].name);
  //     }
  //     var o = 0;
  //     for (let i = 0; i < data.length; ++i) {
  //       if (!this.owners.includes(data[i].owner) && data[i].owner != null && data[i].owner != '') {
  //         this.owners[o] = data[i].owner;
  //         o++;
  //       }

  //     }
  //     localStorage.setItem('owners', JSON.stringify(this.owners));
  //     this.dataSource.data = data;
  //     this.dataSource.sort = this.sort;
  //     // this.dataSource.paginator = this.paginator;
  //   });


  // }

  /**
   * @name updateUsers
   * @description update users from the database
   */
  updateFault() {

    this.faultService.allIssues(this.currentUser, this.filterValues, this.dateFrom, this.dateTo).subscribe((data) => {
      this.loading = false;
      data.forEach(element => {
        element.date = this.formatDate(element.date);
      });

      // data.sort(function (a, b) {
      //   return (b.priority - a.priority)
      // })

      data.sort((a, b) =>
        b.date > a.date ? 1 :
          b.date < a.date ? -1 :
            0
      );

      let tempDataSource = new MatTableDataSource(data);
      tempDataSource.sort = this.sort;
      this.defineDataSourceFilter(tempDataSource);
      this.dataSource = tempDataSource;

      if (this.userFilter) {
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });

      });
  }



  //FILTERS
  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Issue, filter: string) => {
        const theFilter: MicroappFaultListFilter = JSON.parse(filter);
        let generalFilter = false;
        let priorityFilter = false;
        let statusFilter = false;
        let startDateFilter = false;
        let endDateFilter = false;
        let readFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.summary.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          // if (aGroup.owner.toLowerCase().indexOf(theFilter.general) !== -1) {
          //   generalFilter = true;
          // }
        } else generalFilter = true;

        //status filter

        if (theFilter.status) {
          if (aGroup.status.toLowerCase().indexOf(theFilter.status) !== -1) {
            statusFilter = true;
          }
        } else statusFilter = true;

        //type filter 
        if (theFilter.priority) {
          if (aGroup.priority.toLowerCase().indexOf(theFilter.priority) !== -1) {
            priorityFilter = true;
          }
        } else priorityFilter = true;


        if (theFilter.start_date) {

          const visitDate = this.datepipe.transform(aGroup.date, "yyyyMMdd");
          if (theFilter.start_date <= visitDate) {
            startDateFilter = true;
          }
        } else startDateFilter = true;

        if (theFilter.end_date) {

          const visitDate = this.datepipe.transform(aGroup.date, "yyyyMMdd");
          if (theFilter.end_date >= visitDate) {
            endDateFilter = true;
          }
        } else endDateFilter = true;

        if (theFilter.read != undefined) {
          if (aGroup.read == theFilter.read) {
            readFilter = true;
          }
        } else readFilter = true;

        return generalFilter && priorityFilter && statusFilter && startDateFilter && endDateFilter && readFilter;
      }

  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStatusFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.status = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  applyPriorityFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.priority = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStartDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.start_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyEndDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.end_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyReadFilter(filterValue: boolean) {
    // this.filterValues[filterValue] = filterValue;
    this.userFilter.read = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.end_date = undefined;
    this.userFilter.start_date = undefined;
    this.userFilter.status = undefined;
    this.userFilter.priority = undefined;
    this.userFilter.general = undefined;
    this.userFilter.read = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  // Get Uniqu values from columns to build filter
  // getFilterObject(fullObj, key) {
  //   const uniqChk = [];
  //   fullObj.filter((obj) => {
  //     if (!uniqChk.includes(obj[key])) {
  //       uniqChk.push(obj[key]);
  //     }
  //     return obj;
  //   });
  //   return uniqChk;
  // }


  updateData() {
    this.loading = true;
    this.faultService.allIssues(this.currentUser, this.filterValues, this.dateFrom, this.dateTo).subscribe((data) => {
      this.loading = false;
      for (let i = 0; i < data.length; ++i) {
        data[i].date = this.formatDate(data[i].date);
      }
      data.sort((a, b) =>
        b.date > a.date ? 1 :
          b.date < a.date ? -1 :
            0
      );
      this.dataSource.data = data;
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      }
    );
  }

  formatDate(date) {
    let formatedBarra: String = date.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + date.slice(11) + "Z";
    let dateNew = new Date(formated);
    return dateNew;
  }


  //ROW SELECTION
  selectedRow: any;
  selectedRowIndex: number;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Issue): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.ticket_id + 1}`;
  }

  create() {
    const dialogAssign = this.dialog.open(NewIssueComponent, {
      width: '800px',
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.updateData();
    });
  }

  getEdit(ticket_id) {
    const dialogAssign = this.dialog.open(EditComponent, {
      width: '850px',
      height: '700px',
      data: { ticket_id: ticket_id }
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.updateData();
    });
  }

  //ESCALATION POPUP 
  escalationManager() {
    const dialogAssign = this.dialog.open(EscalationDialog, {
      width: '448px',
      height: '280px'
    });
    dialogAssign.afterClosed().subscribe(result => {
      if (result != undefined && result != '') {
        let comment = result;
        let issues: Issue[] = this.selection.selected;
        let i = 0;
        for (var issue of issues) {
          this.faultService.getOwner(issue.category, issue.type, issue.location).subscribe(
            (resp) => {
              issue.owner = resp.group;
              i++;
              if (i >= issues.length) {
                this.issueUpdateComment(issues, comment);
              }
            })
        }
      }
    });
  }

  private issueUpdateComment(issues: Issue[], comment: any) {
    this.faultService.updateIssuesComment(issues, comment, this.currentUser.mail).subscribe((resp: Issue) => {
      this.mailService.escalateManagers(comment, issues);
      this.snackBar.open(
        this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
        this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE"),
        {
          duration: 5000,
        });
    });
  }

  templateEmail(comment: string, issues: Issue[]) {
    const dialogAssign = this.dialog.open(MailComponent, {
      width: '350px',
      data: {
        topTable: this.translate.instant("MAPP-FAULTREPORT.MAIL.BODY.TOP.NOTIFICATION_MANAGERS"),
        bottonTable: this.translate.instant("MAPP-FAULTREPORT.MAIL.BODY.BOTTON.NOT_REPLY"),
        comment: comment,
        list: issues
      }
    });
    dialogAssign.afterClosed().subscribe(result => {
      console.log("cerrado");

    });
  }

  chips = [
    { name: 'closed' },
    { name: 'assigned' },
    { name: 'new' }
  ];

  chipsState = [
    { icon: "done", state: false },
    { icon: "done_all", state: true }
  ];
}

export class Priority {
  name: string;
  value: number;
}
