import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AuthenticationService, EmpusaMicroApp, Site, User } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';
import { MicroappHomeTermsComponent } from './terms/microapp-home-terms/microapp-home-terms.component';

@Component({
  selector: 'microapp-homes',
  templateUrl: './microapp-home.home.component.html',
  styleUrls: ['microapp-home.home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappHomeComponent implements OnInit {

  user: User;
  username: String
  pictureURL: String

  /** selected site */
  site: Site;
  siteName: string;
  /** list of modules availables */
  modules: EmpusaMicroApp[] = [];

  /** list of available sites to be selected */
  sites: Site[];
  isAdmin: boolean;
  /** the current page selected */
  currentDynamicPage: string = "";

  /** the current selected lang */
  currentLang: string = "";
  bmsGrafana: string;
  incluideGrafana: boolean = false;

  selectedSiteControl = new FormControl();

  constructor(private translate: TranslateService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    @Inject('environment') private environment) {

    this.authService.loadCurrentUser().then((user: User) => {
      this.user = user;
      this.isAdmin = this.authService.hasCurrentUserRole('ADMIN');
      this.sites = user.sites;
      //the first site is selected by default
      this.site = this.sites[0];
      this.selectedSiteControl.setValue(this.site.uid);
      this.authService.setCurrentSite(this.site);
      this.siteName = this.site.name;
      this.bmsGrafana = this.environment.grafana;
      if(this.bmsGrafana != ''){
        this.incluideGrafana = true
      }
      let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;
      if (allModules && allModules != null) {
        allModules.forEach(module => {
          //******************QUITADO LA AUTORIZACIONES */
          //this.modules.push(module);

          this.authService.loadMicroappPermissions(module.name).then(() => {
            this.authService.canAccessMicroapp(module.name).then(canAccess => {
              if (canAccess) {

                if (module.name != "home"){
                  this.modules.push(module);
                  this.modules.sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
                          a.name.toLowerCase() < b.name.toLowerCase() ? -1 :
                              0
                  );
                }
              }
            })
          });
        });
      }


    });


  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(MicroappHomeTermsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}