import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { Question } from '../../../../client/dto/question';
import { MatTableDataSource } from '@angular/material/table';
import { Answer } from '../../../../client/dto/answer';

@Component({
  selector: 'microapp-quiz-edit-question',
  templateUrl: './microapp-quiz.edit-question.component.html',
  styleUrls: ['./microapp-quiz.edit-question.component.css']
})
export class EditQuestionComponent {

  /** form to validate */
  questionForm: FormGroup;
  /** the tenant being edited */
  editQuestion: Question = new Question();
  matcher = new MyErrorStateMatcher();

  /** Table of answer */
  displayedAnswerColumns: string[] = ['answer', 'actions'];
  answers = new MatTableDataSource([]);
  countRowAnswer: number = 0;

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<EditQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Question, public translate: TranslateService) {

      this.questionForm = new FormGroup({
        sid: new FormControl(),
        code: new FormControl(''),
        question: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        answers: new FormControl()
      });

      this.q.sid.setValue(data.sid);
      this.q.code.setValue(data.code);
      this.q.question.setValue(data.question);
      this.q.type.setValue(data.type);

      this.countRowAnswer = data.answers.length;
      this.answers = new MatTableDataSource(data.answers);
  }

  newAnswer(){
    this.countRowAnswer = this.countRowAnswer + 1;
    let answer: Answer = new Answer();
    answer.sid = this.countRowAnswer + '';
    answer.code = '----';
    answer.value = '0';
    answer.order = '' + this.countRowAnswer;
    this.answers.data.push(answer);
    this.answers.filter = "";
  }

  updateAnswer(sid: string, idex: number, value: string){
    let index = this.answers.data.findIndex(d => d.sid === sid);
    if(index != -1){
      if (idex == 2){
        this.answers.data[index].value = value;
      }
      if (idex == 3){
        this.answers.data[index].answer = value;
      }
    }
  }

  deleteAnswer(sid: string){
    let index = this.answers.data.findIndex(d => d.sid === sid);
    if(index != -1){
      this.answers.data.splice(index, 1);
      this.answers.filter = "";
    }
  }

  // convenience getter for easy access to form fields
  get q():any { 
    return this.questionForm.controls; 
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(){

    // stop here if form is invalid
    if (this.questionForm.invalid) {
      return;
    }

    this.q.answers.setValue(this.answers.data);
    this.dialogRef.close(this.questionForm.value);
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}