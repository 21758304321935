import { createAction, props } from "@ngrx/store";
import { EpsAssignedChargeType } from "../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model";
import { EpsChargeType } from "../model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsTenantTariff } from "../model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { EpsParkingSize } from "../model/microapp-eps.parking-size.model"
import { EpsSpotType } from "../model/microapp-eps.spot-type.model";
import { EpsTenantQuotas } from "../model/microapp-eps.tenan-quote.model";
import { EpsVehicleType } from "../model/microapp-eps.vehicle-type.model";
import { EpsReservationType } from "../model/reservation/microapp-eps.reservation-type.model";

export const epsGetParkinfData = createAction(
    '[Microapp eps] get parking data'
);

export const epsStoreParkingData = createAction(
    '[Microapp eps] Store parking data',
    props<{
        parkingSize: EpsParkingSize[],
        tenantQuotas: EpsTenantQuotas[],
        parkingVehicleTypes: EpsVehicleType[],
        chargesAndTariffs: EpsChargeType[],
        assignedCharges: EpsAssignedChargeType[],
        tenantTariffs: EpsTenantTariff[],
        reservationTypes: EpsReservationType[],
        spotTypes: EpsSpotType[],
    }>()
);

export const epsGetQuotas = createAction(
    '[Microapp eps] get the tenant quotas'
);

export const epsStoreQuotas = createAction(
    '[Microapp eps] storte quotas',
    props<{
        tenantQuotas: EpsTenantQuotas[],
    }>()
);

export const epsGetParkingSize = createAction(
    '[Microapp eps] get epsParkingSize'
);

export const epsStoreParkingSize = createAction(
    '[Microapp eps] epsStoreParkingSize',
    props<{
        parkingSize: EpsParkingSize[],
    }>()
);

export const epsStoreAssignedCharges = createAction(
    '[Microapp eps] storte assignedCharges',
    props<{
        assignedCharges: EpsAssignedChargeType[],
    }>()
);

export const epsGetTenantTariffs = createAction(
    '[Microapp eps] get Eps Get TenantTariffs'
);

export const epsStoreTenantTariffs = createAction(
    '[Microapp eps] storte Eps Store TenantTariffs',
    props<{
        tenantTariffs: EpsTenantTariff[],
    }>()
);



export const epsError = createAction(
    '[Microapp eps] error'
);
