import { createAction, props } from '@ngrx/store';
import { MicroappFbsUserModel } from '../../client/dto/microapp-fbs-user';
import { User } from '@empusa/empusa-core';
import { MicroappFbsResourceModel } from '../../client/dto/microapp-fbs-resource';
import { MicroappFbsChargeType } from '../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsReservationGroupModel } from '../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsSchedule } from '../../client/dto/microapp-fbs-schedule';


export const loadFbsUser = createAction(
    '[Microapp fbs] loadFbsUser',
    props<{ user: User }>());


export const fbsUserLoaded = createAction(
    '[Microapp fbs] fbsUserLoaded',
    props<{ fbsUser: MicroappFbsUserModel }>());

export const error = createAction(
    '[Microapp fbs] error',
    props<{ errorMsg: string }>());

export const resetError = createAction(
    '[Microapp fbs] resetError');

export const loadChargeTypes = createAction(
    '[Microapp fbs] loadChargeTypes');

export const chargeTypesLoaded = createAction(
    '[Microapp fbs] chargeTypesLoaded',
    props<{ chargeTypes: MicroappFbsChargeType[] }>());

export const loadData = createAction(
    '[Microapp fbs] loadData');

export const dataLoaded = createAction(
    '[Microapp fbs] dataLoaded',
    props<{
        resources: MicroappFbsResourceModel[],
        resources_grouped: MicroappFbsResourceGroup[],
        resources_grouped_for_admin: MicroappFbsResourceGroup[],
        myBookings: MicroappFbsReservationGroupModel[],
        schedule: MicroappFbsSchedule,
    }>());

export const checkAllFacilitiesAvailability = createAction(
    '[Microapp fbs] check all FacilitiesAvailability',
    props<{ startDateTime: Date, endDateTime: Date }>());

export const checkedAllFacilitiesAvailability = createAction(
    '[Microapp fbs] checkedAllFacilitiesAvailability',
    props<{ resources: MicroappFbsResourceModel[], resources_grouped: MicroappFbsResourceGroup[] }>());

export const loadMyReservations = createAction(
    '[Microapp fbs] loadMyReservations',
    props<{ startDateTime: Date, endDateTime: Date, tenant_dn?: string }>());

export const myReservationsLoaded = createAction(
    '[Microapp fbs] myReservationsLoaded',
    props<{ myBookings: MicroappFbsReservationGroupModel[] }>());

export const loadResources = createAction(
    '[Microapp fbs] loadResources');
    

export const resourcesLoaded = createAction(
    '[Microapp fbs] resourcesLoaded',
    props<{
        resources: MicroappFbsResourceModel[],
        resources_grouped: MicroappFbsResourceGroup[],
        resources_grouped_for_admin: MicroappFbsResourceGroup[],
    }>());

export const resetFacilitiesWithBookInfo = createAction(
    '[Microapp fbs] resetFacilitiesWithBookInfo');
