import { Pipe, PipeTransform } from '@angular/core';
import { MicroappFbsResourceSlotModel } from '../../client/dto/faciliti-slots';


@Pipe({
    name: 'slotStatus'
})
export class MicroAppFbsSlotStatusPipe implements PipeTransform {

    transform(slot: MicroappFbsResourceSlotModel): string {
        if (slot.reservation) {
            return "MAPP-FBS-RESERVATION-SLOTS.STATUS_RESERVED";
        }
        if (!slot.isReservable) {
            return "MAPP-FBS-RESERVATION-SLOTS.STATUS_NOT_RESERVABLE";
        }

        return "MAPP-FBS-RESERVATION-SLOTS.STATUS_FREE";
    }
}
