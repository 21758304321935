import { ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, Tenant, User } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Notifications } from '../../client/dto/notifications';
import { NotificationsService } from '../../client/notifications.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'empusa-microapp-notifications-new',
  templateUrl: './microapp-notifications.new.component.html',
  styleUrls: ['./microapp-notifications.new.component.css']
})
export class MicroappNewComponent implements OnInit {

  @ViewChild("fileUpload", { static: false }) fileInputElement: ElementRef;
  files = [];
  filesName = [];
  tenants = [];
  usersList: User[] = []
  // usersList:Promise<User[]>
  tenantsList: Tenant[] = [];
  currentTicket: string;
  newNotification = new Notifications();
  newNotificationForm: FormGroup;
  currentUser: User;
  contentFiles: boolean;
  subDestinatary$: Subscription;
  subDest$: Subscription;
  sendTo: string;
  listTag = [];
  loading: boolean;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredOptions: Observable<User[]>;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '130px',
    minHeight: '130px',
    placeholder: 'Enter your message here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'backgroundColor',
        'customClasses',
        'undo',
        'redo',]
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService,
    private datepipe: DatePipe,
    private auth: AuthenticationService, public dialogRef: MatDialogRef<MicroappNewComponent>,
    private service: NotificationsService, private snackBar: MatSnackBar) {
    this.newNotificationForm = this.formBuilder.group({
      date: [""],
      destiniy: ['', Validators.required],
      userMultiFilterCtrl: [''],
      summary: ['', Validators.required],
      message: ['', Validators.required],
      typeDestiny: [''],
    });

    this.setDate(true);

    this.auth.loadCurrentUser().then(user => {
      this.currentUser = user;
    })

    this.subDestinatary$ = this.f.typeDestiny.valueChanges.subscribe(newValue => {
      this.loading = true;
      if (newValue == "All") {
        this.sendTo = "All"
        let arrayAll = ["All"]
        this.f.destiniy.setValue(arrayAll)
        this.service.findUsersByBuilding(this.currentUser.sites[0].uid).subscribe((data) => {
          this.loading = false;
          this.usersList = data
        })
      }
      if (newValue == "Select Tenant") {
        this.sendTo = "Select Tenant"
        this.service.getSite(this.currentUser.sites[0].uid).subscribe(resp => {
          this.loading = false;
          resp.tenants.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 :
              0
        );
          this.tenantsList = resp.tenants
        });
      }
      if (newValue == "Select Users") {
        this.sendTo = "Select Users"
        this.service.findUsersByBuilding(this.currentUser.sites[0].uid).subscribe((data) => {
          this.loading = false;
          data.sort((a, b) =>
          a.mail.toLowerCase() > b.mail.toLowerCase() ? 1 :
            a.mail.toLowerCase() < b.mail.toLowerCase() ? -1 :
              0
        );
          this.usersList = data
        })
      }
    })
  }

  private _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.filteredOptions = this.f.destiniy.valueChanges
      .pipe(
        startWith(''),
        map(value =>
          this._filter(value)
        ))
  }

  get f() { return this.newNotificationForm.controls; }

  onSubmit() {
    this.loading = true;
    if (this.f.typeDestiny.value == "All") {
      this.newNotification.sendto = 'All'
      let userToSend = [];
      this.usersList.forEach(user => {
        userToSend.push(user.mail);
      })
      this.newNotification.destinatary = userToSend;
    } else if (this.f.typeDestiny.value == "Select Tenant") {
      let tenantToSend = []
      let tenantName = []
      tenantToSend = this.f.destiniy.value;
      tenantToSend.forEach(tenant => {
        this.tenants.push(tenant);
        tenantName.push(tenant.name);
      })
      this.newNotification.sendto = "Selected Tenants"
      this.newNotification.tenants = this.tenants;
    } else {
      this.newNotification.destinatary = this.listTag;
      this.newNotification.sendto = "Selected Users"
    }
    let newDate = new Date(this.f.date.value)
    this.newNotification.date = this.datepipe.transform(newDate, 'shortDate');
    this.newNotification.message = this.f.message.value;
    this.newNotification.title = this.f.summary.value;

    if (this.files.length > 0) {
      this.contentFiles = true;
    } else {
      this.contentFiles = false;
    }
    this.newNotification.files = this.contentFiles;
    this.createNotification();

  }

  createNotification() {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(this.newNotification))
    for (let i = 0; i < this.files.length; i++) {
      formData.append("file" + i, this.files[i].data, this.files[i].data.name)
    }
    this.service.createNotification(formData).subscribe(
      (data: any) => {
        if (data.body) {
          this.loading = false;
          this.dialogRef.close();
          this.snackBar.open(
            this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.OK.MESSAGE"),
            this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.OK.TITLE")
            , {
              duration: 5000,
            });
        }
      },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-NOTIFICATIONS.MESSAGE.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      })
  }




  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  deleteFile(filename: string) {
    this.files.forEach((item, index) => {
      if (item.data.name === filename) this.files.splice(index, 1);
    });
  }

  onClick() {
    const fileUpload = this.fileInputElement.nativeElement; fileUpload.onchange = () => {
      let wait: boolean = false;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];

        this.filesName.forEach(searchFile => {
          if (searchFile.filename == file.name) {
            wait = true;
          }
        });
        if (file.size > 11322929) {
          this.snackBar.open(
            this.translate.instant("The file " + file.name + " is too big to upload"),
            this.translate.instant("Max Size is 10 MB")
            , {
              duration: 5000,
            });
        } else {
          this.files.push({ data: file, inProgress: false, progress: 0 });
          this.filesName.push({ filename: file.name });
        }
        // this.files.push({ data: file, inProgress: false, progress: 0 });
        // this.filesName.push({ filename: file.name });
      }
    };
    fileUpload.click();
  }

  setDate(initOn: boolean) {
    let today = new Date();
    this.f.date.setValue(today);
  }

  /** tag selected event from the auto completion component */
  selectedTag(event: MatAutocompleteSelectedEvent): void {
    let index = this.listTag.indexOf(event.option.viewValue);
    if (index < 0) {
      this.listTag.push(event.option.viewValue);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  /** remove a tag from the list of tags */
  removeTag(tag: string) {
    let index = this.listTag.indexOf(tag)
    this.listTag.splice(index, 1);
  }

  /** add a tag to the list of tags */
  addTag(tag) {
    if (!this.listTag) {
      this.listTag = [];
    }
    this.listTag.push(tag.value);
    tag.input.value = '';
  }

  private _filter(value: string) {
    if (this.sendTo == "Select Users") {
      const filterValue = value.toLowerCase();
      return this.usersList.filter(option => option.mail.toLowerCase().includes(filterValue));
    }
  }

  typeDestinatary = [
    // { type: 'All' },
    { type: 'Select Tenant' },
    { type: 'Select Users' }
  ];
}
