import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MicroappFbsDeteleResourceComponent } from '../../management/delete-resource/microapp-fbs-detele-resource.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import { Subscription } from 'rxjs';
import { MicroappFbsResourceGroup } from '../../../client/dto/microapp-fbs-resource-group';
import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import { MicroappFbsResourceDetailComponent } from '../../common/resource-detail/microapp-fbs-resource-detail.component';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsNewUpdateResourceComponent } from '../facility/new-update-resource/microapp-fbs-new-update-resource.component';
import { MicroappFbsSetUnavailableComponent } from '../facility/set-unavailable/microapp-fbs-set-unavailable.component';
import { MicroappSetAvailableComponent } from '../facility/set-availabe/microapp-set-available.component';
import { MicroappFbsNewUpdateAccessoryComponent } from '../accessory/new-update-accessory/microapp-fbs-new-update-accessory.component';
import { MicroappFbsSetAccessoryAvailableComponent } from '../accessory/set-available/microapp-fbs-set-accessory-available.component';
import { MicroappFbsNewUpdateResourceGroupComponent } from '../facility/new-update-resource-group/microapp-fbs-new-update-resource-group.component';
import { SetGroupAvailableComponent } from '../facility/set-group-available/set-group-available.component';


@Component({
  selector: 'empusa-microapp-fbs-facility-list',
  templateUrl: './microapp-fbs-facility-list.component.html',
  styleUrls: ['./microapp-fbs-facility-list.component.css'],
  encapsulation:  ViewEncapsulation.None,
})
export class MicroappFbsFacilityListComponent implements OnInit, OnDestroy {

  public dataSource: MatTableDataSource<MicroappFbsResourceGroup>;
  displayedColumns: string[] = ['type', 'name', 'location', 'description', 'capacity', 'status', 'actions'];
  loading: boolean = false;
  group_rooms_enabled: boolean = false;
  storeSubscription: Subscription;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog,
    private store: Store<microappFbsState.MicroappFbsStates>,
    public fbsConfig: FbsConfig,
    private _snackBar: MatSnackBar,
    private facilityBookingService: MicroappFacilitiesbookingService) { 
      this.group_rooms_enabled = this.fbsConfig.GROUP_ROOMS_ENABLED;
    }


  ngOnInit(): void {
    this.storeSubscriptionFn();
  }


  public storeSubscriptionFn() {

    this.storeSubscription = this.store.select('fbs').subscribe(data => {
      if (data && data.resources) {

        this.dataSource = new MatTableDataSource(data.resources_grouped_for_admin);

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'capacity':
              if (item.type === 'accesory'){
                return item.availableResources;
              }else if (item.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP){
                return 'capacity';
              }else{
                return item.maxParticipants;
              };
            default:
              if (typeof item[property] === 'string')
                return item[property].toLocaleLowerCase();
              else
                return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  the_resource_detail(facility: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = facility;
    dialogConfig.width = '620px';
    const dialogRef = this.dialog.open(MicroappFbsResourceDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  the_resource_update(resource: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = resource;
    dialogConfig.width = '430px';

    let popUp;
    if (resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_FACILITY) {
      popUp = MicroappFbsNewUpdateResourceComponent;
    } else if(resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_ACCESSORY){
      popUp = MicroappFbsNewUpdateAccessoryComponent;
    }else if(resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP){
      popUp = MicroappFbsNewUpdateResourceGroupComponent;
    }
    const dialogRef = this.dialog.open(popUp, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.store.dispatch(fbsActions.loadResources());
    });
  }

  the_resource_delete(resource: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = resource;
    dialogConfig.width = '620px';
    const dialogRef = this.dialog.open(MicroappFbsDeteleResourceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.store.dispatch(fbsActions.loadResources());
      };
    });
  }



  the_resource_set_available(resource: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = resource;
    let popUp;

    if (resource.singleResourceId) {
      popUp = MicroappSetAvailableComponent;
    } else if (resource.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP) {
      popUp = SetGroupAvailableComponent;
    } else {
      popUp = MicroappFbsSetAccessoryAvailableComponent;
    }
    const dialogRef = this.dialog.open(popUp, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.store.dispatch(fbsActions.loadResources());
      };
    });
  }


  the_resource_set_unavailable(resource: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = resource;
    let popUp;
    if (resource.singleResourceId) {
      popUp = MicroappFbsSetUnavailableComponent;
    } else {
      popUp = MicroappFbsSetAccessoryAvailableComponent;
    }
    const dialogRef = this.dialog.open(popUp, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.store.dispatch(fbsActions.loadResources());
      };
    });
  }


  new_facility() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '430px';
    const dialogRef = this.dialog.open(MicroappFbsNewUpdateResourceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.store.dispatch(fbsActions.loadResources());
    });
  }

  new_grouped_facility() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '430px';
    const dialogRef = this.dialog.open(MicroappFbsNewUpdateResourceGroupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.store.dispatch(fbsActions.loadResources());
    });
  }


  new_accessory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '430px';
    const dialogRef = this.dialog.open(MicroappFbsNewUpdateAccessoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.store.dispatch(fbsActions.loadResources());
    });
  }


  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    };
  }
}
