import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FbsConfig } from './microapp-facilitiesbooking-config';
import { Observable } from 'rxjs';
import { MicroappFbsChargeType } from '../client/dto/microapp-fbs-charge-type';
import { FbsChargeTypeAdapter } from '../client/dto/microapp-fbs-charge-type.adapter';
import { FbsTarifAdapter } from '../client/dto/microapp-fbs-tarif-adapter';
import { MicroappFbsTarif } from '../client/dto/microapp-fbs-tarif';

@Injectable({
  providedIn: 'root',
})
export class MicroappFbsChargesService {

  constructor(private http: HttpClient,
    private chargeTypeAdapter: FbsChargeTypeAdapter,
    private fbsTariffAdapter: FbsTarifAdapter,
    private fbsConfig: FbsConfig) {
  }


  getChargeTypes(tenant_dn: string): Observable<MicroappFbsChargeType[]> {
    let id = encodeURIComponent(tenant_dn);
    //const tenant_dn = this.authservice.getCurrentUser().sites[0].tenants[0].dn;
    const url = this.fbsConfig.CHARGE_TYPES_SRV.replace('{tenant_id}', id);

    var the_data = { tags: ["FBS"] };
    return this.http.post<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map((data) =>  data.charge_codes_mapping.map((oneData) => this.chargeTypeAdapter.adapt(oneData))));
  }

  getTariffs(): Observable<MicroappFbsTarif[]> {
    const url = this.fbsConfig.ALL_TARIFF_SRV;
    var the_data = { tags: ["FBS"] };
    return this.http.post<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map((data) => data.tarifs.map((oneData) => this.fbsTariffAdapter.adapt(oneData))));
  }

  assignTenant(tenant, chargeId, tariff) {
    let id = encodeURIComponent(tenant);
    const url = this.fbsConfig.CHARGE_TYPES_SRV.replace('{tenant_id}', id);
    var the_data = {
      tags: ["FBS"],
      charges: [
        {
          chargeid: chargeId,
          tarifid: tariff,
        },
      ]
    };
    return this.http.put<any>(url, the_data).
      //pipe(map((data) => data));
      pipe(map(data =>  data ));
  }

}
