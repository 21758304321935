export const locale = {
    "lang": "en",
    "data": {
        "MAPP-FAULTREPORT": { //mandatory - microapp context
            "DESCRIPTION": "Fault Report",
            "TOOLTIP": {
                "EDIT": "Edit",
            },
            "GENERIC.CLEAN": "Clean Filter",
            "LIST": {
                "TABLE": {
                    "STATUS": "Status",
                    "PRIORITY": "Priority",
                    "GROUP": "Resolver Group",
                    "DATE": "Date",
                    "SEVERITY": "Severity",
                    "DESCRIPTION": "Description",
                    "TITLE": "Title",
                    "ACTIONS": "Actions",
                    "SEND": "Send",
                    "STARTDATE": "Start date",
                    "ENDDATE": "End date"
                },
                "HEAD": "Issues list",
                "REPORT": "Send a commentary to the manager:",
                "FILTER": 'Filter',
                "FILTERT":"Filter Title",
                "FILTERS": 'Filters'
            },
            "REPORT": {
                "HEAD": "Reporting",
                "TITLE": {
                    "YEAR": "Current Year",
                    "MONTH": "Current Month",
                    "WEEK": "Current Week"
                },
                "REPORTED": "Reported",
                "OPEN": "Open",
                "CLOSED": "Closed"

            },
            "ISSUE": {
                "HEAD": "NEW ISSUE",
                "STATUS": "Status",
                "PRIORITY": "Priority",
                "TITLE": "Title",
                "SEVERITY": "Severity",
                "INFORMANT": "Informant",
                "LOCATION": "Location",
                "ASSET": "Category",
                "TYPE": "Type",
                "DESCRIPTION": "Description",
                "ATTACHED": "Attached files",
                "NEW_ATTACHED": "Attach file",
                "GROUP_RESOLVING": "Change Resolver Group",
                "EVENT_LOG": "Event log:",
                "ADD_EVENT": "Add event:",
                "TOWER": "Tower",
                "SUBCATEGORY": "Subcategory",
                "FLOOR": "Floor",

            },
            "ADD": "Add",
            "UPDATE": "Update",
            "RESOLVE": "Resolve",
            "CHANGE": "Change",
            "EDIT": {
                "TITLE": "EDIT ISSUE",
                "INVALID": "Invalid",
                "REQUIRED": "Required",
                "ASSIGN": "Assign",
                "CONFIRM": "Confirm",
                "CANCEL": "Cancel",
                "CREATED": "Created",
                "SUBMIT": "Submit",
                "NEW_GROUP_ASSIGN": "New Resolver Group",
                "COMMENTS": "Comments",
                "COMMENTARY": "Commentary",
                "INTRODUCE": "Enter a commentary",
                "RESOLVE": 'Please describe the resolution',
                "RESOLUTION": "Resolution",
                "TOWER": "Tower",
                "SUBCATEGORY": "Subcategory",
                "FLOOR": "Floor",
                "TEXTRESOLUTION": "Enter the resolution of the issue.",
                "ADD_COMMENT": {
                    "TITLE": "Add Comment",
                    "BODY": "Assign a comment to this issue and it will be displayed in the log",
                },
                "UPDATE": {
                    "OK": {
                        "TITLE": 'Operation Successful',
                        "MESSAGE": 'The changes were saved correctly',
                    },
                    "NOT_OK": {
                        "TITLE": 'Operation Failed!',
                        "MESSAGE": 'An error ocurred while trying to save the changes!'
                    },
                    "FILES_OK": {
                        "TITLE": 'Operation Successful',
                        "MESSAGE": 'The file/s were saved correctly',
                    },
                    "FILES_NOT_OK": {
                        "TITLE": 'Operation Failed!',
                        "MESSAGE": 'An error ocurred while trying to save the file/s!'
                    },
                    "FILES_NOT_OK_DOWNLOAD": {
                        "TITLE": 'Operation Failed!',
                        "MESSAGE": 'An error ocurred while trying to download the file/s!'
                    },
                    "NOTHING": {
                        "RESOLUTION": {
                            "TITLE": 'Error',
                            "MESSAGE": 'Resolution description must be completed'
                        },
                        "TITLE": 'No changes',
                        "MESSAGE": 'No changes to update'
                    },
                    "FILES_VERSION": {
                        "TITLE": "Existing file with this name",
                        "MESSAGE": "This will replace an already existing file with the same name"
                    },
                    "FAILLOAD": {
                        "TITLE": 'Operation Failed!',
                        "MESSAGE": 'An error ocurred while trying to load data'
                    },
                }
            },
            "MAIL": {
                "SUBJECT": {
                    "NOTIFICATION_MANAGERS": "Escalation issue(s) to managers",
                    "EXPIRED": "Expired issues"
                },
                "BODY": {
                    "TOP": {
                        "NOTIFICATION_MANAGERS": "The following issues need your review:",
                        "EXPIRED": "The resolution time of the following issues has expired:"
                    },
                    "BOTTON": {
                        "NOT_REPLY": "*** This is an automatically generated email, please do not reply"

                    }
                }
            }
        }
    }

}