export const locale = {
    "lang": "en",
    "data": {
        "MAPP-VMS-PAGINATOR": {
            "ITEMS": "Items per page:",
        },
        "MAPP-VMS-TOOLTIPS": {
            "NOTIFY": "Notify",
            "DETAIL-VISIT": "Visit Detail",
            "EDIT-VISIT": "Visit Edit",
            "DELETE-VISIT": "Visit Delete",
            "DETAIL-VISITOR": "Visitor Detail",
            "EDIT-VISITOR": "Visitor Edit",
            "DELETE-VISITOR": "Visitor Delete",
            "DETAIL-VISIT-GROUP": "Visits Detail",
            "EDIT-VISIT-GROUP": "Visits Edit",
            "DELETE-VISIT-GROUP": "Visits Delete",
            "CHECK_OUT-VISITOR": "Check-out Visitor / Cancel Access",
            "SCHEDULED_VISIT": "Scheduled Visit",
            "NON_SCHEDULED_VISIT": "Non-Scheduled Visit",
            "DELIVERY": "Delivery",
            "PARKING_C": "Parking confirmed.",
            "PARKING_E": "The parking was reserved but could not be confirmed. Please request the parking again.",
            "PARKING_R": "EPS error.",
            "PARKING_P": "The parking will be reserved when all visitor details are completed.",
            "WEAR_MASK": "Wear mask",
            "NOT_WEAR_MASK": "Does not wear a mask",
            "VISIT_TEMPERATURE": "Temperature"
        },

        "MAPP-VMS-HOME": {
            "APP-NAME": "Visitor Management",
            "LOG-BOOK": "Visit List",
            "ADDRESS-BOOK": "Address Book",
            "TIMEZONE": "Date and time is in %1 time zone."
        },
        "MAPP-VMS-LOGBOOK": {
            "FILTER": "Filter",
            "EXPAND": "+ EXPAND ALL",
            "COLLAPSE": "- COLLAPSE",
            "START-DATE": "Start Date",
            "END-DATE": "End Date",
            "START-TIME": "Start Time",
            "END-TIME": "End Time",
            "COMP-PARKING": "Complimentary Parking",
            "ALL-TENANTS": "All tenants",
            "TENANT": "Tenant",
            "HOST": "Host",
            "DATE": "Date",
            "VISITORS": "Visitors",
            "VISITOR": "Visitor",
            "ACTIONS": "Actions",
            "NAME": "Name",
            "COMPANY": "Company",
            "CHECK-IN": "Check In",
            "AUTO_CHECK_IN": "Not Needed/Requied",
            "CHECK-OUT": "Check Out",
            "AUTO_CHECK_OUT": "Auto",
            "INS": "Turnstile Access",
            "OUTS": "Turnstile Exit",
            "IN": "Turnstile Access",
            "OUT": "Turnstile Exit",
            "EXTRA_DATA": "Extra data",
            "PARKING": "Parking",
            "NOTIFICATION": "Notified",
            "NEW-VISIT": "New Visit",
            "TOMORROW": "Tomorrow",
            "TODAY": "Today",
            "YESTERDAY": "Yesterday",
            "THIS-WEEK": "This Week",
            "THIS-MONTH": "This Month",
            "CUSTOM": "Custom",
            "DATE-ERROR": "Select a valid date"
        },
        "MAPP-VMS-DELETE": {
            "TITLE": "Delete visit?",
            "GROUP": "If you delete this visit, it will be removed from the visit list along with all visitors associated with it.",
            "SINGLE": "If you delete this visitor, they will be from this visit but will remain in the addressbook.",
            "CANCEL": "Cancel",
            "DELETE": "Delete",
        },
        "MAPP-VMS-CHECK-OUT": {
            "TITLE": "Check-out visit?",
            "MESSAGE": "If you perform a check-out, the access granted to the visitor will be cancelled.",
            "CANCEL": "Cancel",
            "OK": "Check-out",
        },
        "MAPP-VMS-NEW-VISIT": {
            "TITLE": "Create - New Visit",
            "STEP-1": "Add Visitors",
            "STEP-2": "Schedule",
            "STEP-3": "",
            "NEW-VISITOR": "New visitor",
            "BUTTON-NEXT": "Next",
            "BUTTON-CANCEL": "Cancel",
            "BUTTON-BACK": "Back",
        
        },
        "MAPP-VMS-NEW-VISIT-PARTICIPANTS": {
            "ALL": "All",
            "SELECT-TENANT": "Select tenant",
            "SELECT-VISITOR": "Select visitor",
            "NEW-VISITOR": "+ Add new visitor",
            "VISITOR-LIST": "Visitor",
            "DISABLED-PARKING": "The tenant has no rights to make parking reservations for Visitors. For further information contact the Facility Manager."
        },
        "MAPP-VMS-VISITOR-FORM": {
            "FIRST-NAME": "First name",
            "FIRST-NAME-ERROR": "Type a valid first name",
            "LAST-NAME": "Last name",
            "LAST-NAME-ERROR": "Type a valid last name",
            "COMPANY": "Company",
            "PHONE": "Phone",
            "PHONE-ERROR": "The phone number is not valid",
            "EMAIL": "Email*",
            "EMAIL-REQUIRED": "You must enter a value",
            "EMAIL-REPEATED": "This email is already in use",
            "EMAIL-NOT-VALID": "Not a valid email",
            "IU": "IU number",
            "IU_TOOLTIP": "Complimentary parking is chargeable and will be charged to the tenant’s company if issued out to visitor",
            "LICENSE_PLATE": "License Plate",
            "ABOUT": "About the visitor",
            "ADD": "Add visitor",
            "CANCEL": "Cancel visitor",
            "LICENSE_PLATE_MIN_LENGTH": "License plate length not valid",
            "LICENSE_PLATE_PATTERN": "License plate not valid",
            "IU_MIN_LENGTH": "IU length not valid",
            "VEHICLE_TYPE": "Vehicle type"
        },
        "MAPP-VMS-NEW-VISIT-NEW-SCHEDULE": {
            "CHOOSE-DATE": "Choose a date*",
            "DATE-ERROR": "Select a valid date",
            "START-TIME": "Start Time*",
            "START-TIME-ERROR": "Type a valid time",
            "END-TIME": "End Time*",
            "END-TIME-REQUIRED": "You must enter a value",
            "END-TIME-SMALLER": "The end time must be greater than the start time",
            "END-TIME-ERROR": "You must enter a valid value",
            "END-TIME-PASS": "The end date cannot be prior to current date",
            "ABOUT": "About the visit",
        },
        "MAPP-VMS-NEW-VISIT-NEW-RESUME": {
            "INFO-TEXT": "Review the details of your visit, you can edit it again by going back to the previous steps.",
            "APPOINTMENT": "Appointment:",
            "DATE": "Date",
            "START-TIME": "Start Time",
            "END-TIME": "End Time",
            "VISITORS": "Visitors:",
            "BUTTON-CREATE": "Create",
            "BUTTON-CANCEL": "Cancel",
            "BUTTON-BACK": "Back",
        },
        "MAPP-VMS-ADDRESSBOOK": {
            "FILTER": "Filter",
            "ALL-TENANTS": "All tenants",
            "TENANT": "Tenant",
            "EXPAND": "+ EXPAND ALL",
            "COLLAPSE": "- COLLAPSE",
            "NAME": "Name",
            "COMPANY": "Company",
            "PHONE": "Telephone",
            "EMAIL": "Email",
            "ACTIONS": "Actions",
            "VISITS": "Pending visits:",
            "DATE": "Date",
            "START-TIME": "Start Time",
            "END-TIME": "End Time",
            "PARKING": "Parking",
            "CHECK-IN": "Check In",
            "CHECK-OUT": "Check Out",
        },
        "MAPP-VMS-GROUP-VISIT-DETAIL": {
            "TITLE": "Visits details",
            "VISITORS": "Visitors:",
            "NAME": "Name",
            "COMPANY": "Company",
            "PARKING": "Parking",
        },
        "MAPP-VMS-SINGLE-VISIT-DETAIL": {
            "TITLE": "Visit detail",
            "ABOUT_VISIT": "About the visit:",
            "ABOUT_VISITOR": "About the visitor:",
            "CHECK-IN": "CHECK IN:",
            "CHECK-OUT": "CHECK OUT:",
            "IN": "First Turnstile Access:",
            "OUT": "Last Turnstile Exit:",
            "WEAR_MASK": "Wear mask",
            "NOT_WEAR_MASK": "Does not wear a mask",
        },
        "MAPP-VMS-VISITOR-DETAIL": {
            "TITLE": "Visitor detail",
            "ABOUT": "About the visitor:",
        },
        "MAPP-VMS-VISITOR-DELETE": {
            "TEXT": "If you delete this user, they will be removed from the address book.",
            "CANCEL": "Cancel",
            "DELETE": "Delete",
            "TITLE": "Delete Visitor?"
        },
        "MAPP-VMS-VISITOR-EDIT": {
            "TITLE": "EDIT - VISITOR",
            "CANCEL": "Cancel",
            "SAVE": "Update",
        },
        "MAPP-VMS-EDIT-SINGLE-VISIT": {
            "TITLE": "EDIT - VISIT",
            "FIRST-NAME": "First name",
            "FIRST-NAME-ERROR": "Type a valid first name",
            "LAST-NAME": "Last name",
            "LAST-NAME-ERROR": "Type a valid last name",
            "COMPANY": "Company",
            "PHONE": "Phone",
            "PHONE-ERROR": "The phone number is not valid",
            "EMAIL": "Email*",
            "EMAIL-REQUIRED": "You must enter a value",
            "EMAIL-REPEATED": "This email is already in use",
            "EMAIL-NOT-VALID": "Not a valid email",
            "LICENSE_PLATE": "License Plate",
            "IU": "IU number",
            "PARKING_AGAIN": "Request the complimentary parking again",
            "ABOUT": "About the visit",
            "SAVE": "Update",
            "CANCEL": "Cancel",
            "IU_TOOLTIP": "Complimentary parking is chargeable and will be charged to the tenant’s company if issued out to visitor",
            "LICENSE_PLATE_MIN_LENGTH": "License plate length not valid",
            "LICENSE_PLATE_PATTERN": "License plate not valid",
            "IU_MIN_LENGTH": "IU length not valid",
            "VEHICLE_TYPE": "Vehicle type",
        },
        "MAPP-VMS-EDIT-GROUP-VISIT": {
            "TITLE": "EDIT - VISIT",
            "SCHEDULE": "Schedule",
            "ADD-PARTICIPANT": "Visitors",
            "NEW-VISITOR": "+ Add new visitor",
            "SAVE": "Update",
            "CANCEL": "Cancel",
        },
        "MAPP-VMS-RESENT": {
            "TITLE": "SEND EMAIL",
            "CONTENT": "Please confirm the notification request",
            "CANCEL": "Cancel",
            "CONFIRM": "Confirm",
        },
        "MAPP-VMS-LOG": {
            "VISIT_CREATED": "Visit created.",
            "NSV_VISIT_CREATED": "Non-scheduled visit created.",
            "NEW_VISIT_NOTIFICATION_ERROR": "...",
            "UPDATE_VISIT_NOTIFICATION_ERROR": "....",
            "VISIT_SCHEDULE": "The scheduled was changed",
            "VISIT_ENTER": "The visitor has entered.",
            "VISIT_EXIT": "The visitor has exited.",
        },
        "MESSAGE_CODES": {
            "NEW_VISIT_CREATED": "The visit has been created.",
            "NEW_VISIT_CREATED_BUT_PARKING": "The visit has been created, but the parking requests were not successful.",
            "VISIT_UPDATED": "The visit has been updated.",
            "VISIT_NOT_CHANGE": "There are no changes in the visit",
            "GENERIC": "The operation could not be performed, please try again later.",
            "GENERIC_OK": "Operation Completed.",
            "EMAIL_DUPLICATED": "The email is already in use",
            "PARKING_1": "The parking request could not be completed due a issue in the EPS.",
            "PARKING_2": "The parking could not be cancelled due a issue in the EPS.",
            "EMAIL_FAULT": "The email could not be sent, please check that the visitor email is correct.",
            "EMAIL_SENT": "The email has been sent.",
        },
        "PARKING_STATUS_CODE": {
            "OK": "Pre-reserved",
            "NO_SPOTS_AVAILABLE": "Not available spot.",
            "NO_MULTIPLE_RESERVATIONS": "This IU has already a reservations and EPS does not allow multiple reservations.",
            "SPOT_RESERVED": "Reserved.",
            "BAD_TARIFFICATION": "Tariffication code error.",
            "DAILY_MIX": "This daily reservation can not include weekend and working days because has different tariffs.",
            "NO_TARIFF": "No tariff assigned.",
            "NO_CHARGES": "No charge type found.",
            "CONNECTION_ERROR": "Can't connect with remote EPS.",
            "EPS_RESERVATION_ERROR": "The season reservation has failed on the remote EPS.",
            "BAD_DATES": "Cannot reserve in the selected date.",
            "IU_DUPLICATED": "IU duplicated.",
            "LICENSE_PLATE_DUPLICATED": "License plate duplicated.",
            "PARKING_C": "Parking confirmed.",
            "PARKING_E": "The parking was reserved but could not be confirmed. Please request the parking again.",
            "PARKING_R": "EPS error.",
            "PARKING_P": "The parking will be reserved when all visitor details are completed.",
        },
        "GENERICS": {
            "NO_DATA": "No data available",
            "CLEAN": "Clean Filter",
        }

    }
}
