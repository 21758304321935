import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'empusa-microapp-vms-tool-bar-single-visit',
  templateUrl: './microapp-vms-tool-bar-single-visit.component.html',
  styleUrls: ['./microapp-vms-tool-bar-single-visit.component.css']
})
export class MicroappVmsToolBarSingleVisitComponent implements OnInit {

  displayLog: Boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
  ) { }


  displayNewLog() {
    this.displayLog = true;

  }
  closeNewLog() {

    this.displayLog = false;
  }

  onClose() {
    this.dialogRef.close(null);
  }

  ngOnInit(): void {
  }



}
