import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { select, Store } from '@ngrx/store';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../../lib/store/tenant/microapp-eps.tenant.actions';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../lib/services/user.service';
import { Subscription } from 'rxjs';
import { selectPaymentDone, selectSelectedUsers } from '../../../../lib/store/tenant/microapp-eps-tenan.selectors';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeReservationsEps } from './employee-reservation/microapp-eps.employee-reservation.component';

@Component({
  selector: 'empusa-microapp-eps-select-employees',
  templateUrl: './microapp-eps-select-employees.component.html',
  styleUrls: ['./microapp-eps-select-employees.component.css'],
})
export class MicroappEpsSelectEmployeesComponent implements OnInit, OnDestroy {

  usersDataSource: MatTableDataSource<User>;
  employeeDisplayedColumns: string[] = ['select', 'name', 'mail', 'buttonEmplo'];
  userSelection = new SelectionModel<User>(true, []);
  storeSubscription: Subscription;
  loading: boolean;
  tenant: string;

  @ViewChild(MatSort) employeesSort: MatSort;
  @ViewChild(MatPaginator) employeesPaginator: MatPaginator;


  constructor(private translate: TranslateService,
    private userService: UserService,
    private dialog: MatDialog,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.getDataEmployees();
    this.store.pipe(select(selectPaymentDone)).subscribe(pdone => {
      if (pdone == true) {
        this.store.pipe(select(selectSelectedUsers)).subscribe(users => {
          this.userSelection = new SelectionModel<User>(true, []);
          if (users && users.length > 0) {
            users.forEach(us => {
              this.userSelection.select(us);
            })
          }
        });
      }
    })

  }

  getDataEmployees() {
    this.loading = true;
    this.auth.loadCurrentUser().then(user => {
      this.tenant = user.sites[0].tenants[0].uid
      this.userService.findUsersByTenant().then(users => {
        this.usersDataSource = new MatTableDataSource(users);
        this.usersDataSource.sort = this.employeesSort;
        this.customSort();
        this.usersDataSource.paginator = this.employeesPaginator;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    })
  }


  customSort() {
    this.usersDataSource.sortingDataAccessor = (item, property) => {
      // property = this.sortBy;
      switch (property) {
        case 'name': {
          let data = ""
          if (item.givenName)
            data = data + item.givenName.trim();
          if (item.surname)
            data = data + item.surname.trim();
          return data.toLowerCase();
        }
        default: {
          return item[property];
        }
      }
    };
  }

  showReservations(element: User) {

    const dialogAssign = this.dialog.open(EmployeeReservationsEps, {
      width: '1400px',
      maxHeight: '900px',
      data: {
        user: element,
        tenant: this.tenant
      }
    });
    dialogAssign.afterClosed().subscribe(result => {
    })
  }

  areaAllEmployeesSelected() {
    const numSelected = this.userSelection.selected.length;
    const numRows = this.usersDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.areaAllEmployeesSelected() ?
      this.userSelection.clear() :
      this.usersDataSource.data.forEach(row => this.userSelection.select(row));
  }

  applyFilterToEmployees(filterValue: string) {
    this.usersDataSource.filter = filterValue.trim().toLowerCase();
  }


  changeSelection() {
    const users = this.userSelection.selected;
    this.store.dispatch(epsTenatActions.epsStoreSelectedEmployes({ users }))
  }

  reload() {
    this.getDataEmployees();
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();

    }
  }
}
