<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">

    <div class="header">
    </div>

    <div>
        <div class="cargGraphic">

            <mat-card class="cardStatus">
                <mat-card-header class="headerStatus">
                    <mat-card-title>
                        {{'MAPP-FAULTREPORT.REPORT.TITLE.YEAR' | translate}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="noDataYear == false" class="chart-wrapper">
                        <canvas *ngIf="customerBarChartData" class="canvasStatus" baseChart [data]="pieChartDataStatus"
                            [labels]="pieChartLabelsStatus" [chartType]="pieChartType" [options]="pieChartOptions"
                            [plugins]="pieChartPlugins" [legend]="pieChartLegend" [colors]="pieChartColors">
                        </canvas>
                    </div>
                    <div  class="noData" *ngIf="noDataYear == true">NO REPORTS</div>
                </mat-card-content>
            </mat-card>

            <mat-card class="cardStatus">
                <mat-card-header class="headerStatus">
                    <mat-card-title>
                        {{'MAPP-FAULTREPORT.REPORT.TITLE.WEEK' | translate}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="noDataWeek == false" class="chart-wrapper">
                        <canvas *ngIf="customerBarChartData" baseChart class="canvasPriority"
                            [data]="doughnutChartDataPriority" [labels]="doughnutChartLabelsPriority"
                            [options]="bdoughnutChartOptions" [chartType]="doughnutChartType"
                            [colors]="doughnutChartColors">
                        </canvas>
                    </div>
                    <div  class="noData" *ngIf="noDataWeek == true">NO REPORTS</div>
                </mat-card-content>
            </mat-card>
            <p></p>
            <mat-card class="cardStatusSecondLine">
                <mat-card-header class="headerStatus">
                    <mat-card-title>
                        {{'MAPP-FAULTREPORT.REPORT.TITLE.MONTH' | translate}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="noDataMonth == false" class="chart-wrapper">
                        <canvas *ngIf="customerBarChartData" baseChart class="canvasOwner"
                            [datasets]="barChartDataOwner" [labels]="barChartLabelsOwner" [options]="barChartOptions"
                            [legend]="barChartLegend" [plugins]="pieChartPlugins" [chartType]="barChartType"
                            [colors]="labChartColors">
                        </canvas>
                    </div>
                    <div  class="noData" *ngIf="noDataMonth == true">NO REPORTS</div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>