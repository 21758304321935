import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@empusa/empusa-core';
import { Store } from '@ngrx/store';
import { EpsServices } from '../lib/services/microapp-eps.service';
import * as microappEpsAppReducer from '../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../lib/store/tenant/microapp-eps.tenant.actions';

@Component({
  selector: 'microapp-eps',
  templateUrl: 'microapp-eps.home.component.html',
  styleUrls: ['microapp-eps.home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroappEpsComponent implements OnInit {

  MICROAPP_NAME: string = "eps";
  MICROAPP_FUNCTIONALITY_RESERVATION = "reservation";
  MICROAPP_FUNCTIONALITY_ADMIN = "admin";
  // loading: boolean = true;
  siteName: string;
  /** flag to check whether the reservation page should be shown */
  funcReservation: boolean;
  /** flag to  check whether the admin (passes, quotes) should be shown */
  funcAdmin: boolean;
  funcNothing: boolean;
  activateTab: number = 0;
  enabled_non_entitled: boolean;
  constructor(private auth: AuthenticationService, private router: Router,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private epsServices: EpsServices) {

    this.auth.loadCurrentUser().then(user => {
      this.siteName = user.sites[0].name;
      this.auth.canUserExecuteAsync(this.MICROAPP_NAME, this.MICROAPP_FUNCTIONALITY_RESERVATION).then(result => {
        this.funcReservation = result;
        this.funcAdmin = this.auth.canUserExecute(this.MICROAPP_NAME, this.MICROAPP_FUNCTIONALITY_ADMIN);
        if (this.funcAdmin) {
        } else if (this.funcReservation) {
        } else {
          this.funcNothing = true
        }
        // let tenantId = user.sites[0].tenants[0].uid;
        // this.epsServices.tenant_quotas(tenantId).subscribe(tenant_quota => {
        //   this.enabled_non_entitled = tenant_quota.quota[0].association_enabled;
        //   this.loading = false;
        // })

      });


    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activateTab = tabChangeEvent.index;
  }

  ngOnInit(): void {
    // this.auth.loadCurrentSite().then(site => {
    //   this.siteName = site.name;
    // });
  }


}
