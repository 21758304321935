import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empusa-microapp-home-terms',
  templateUrl: './microapp-home-terms.component.html',
  styleUrls: ['./microapp-home-terms.component.css']
})
export class MicroappHomeTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
