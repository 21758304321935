import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { FaultreportFakeBackend } from '../fake/fake.backend';
import { FaultreportModule } from '../pages/microapp-faultreport.home.module';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "faultreport";
export const MICROAPP_REPORTING:string = "reporting";
export const MICROAPP_ESCALATION:string = "escalation";
export const MICROAPP_SHOWDETAIL:string = "show_detail";
export const MICROAPP_EDITDETAIL:string = "edit_detail";
export const MICROAPP_CREATE:string = "create";
export const MICROAPP_FINDALL:string = "find_all";
export const MICROAPP_FINDASIGNED:string = "find_assigned";
export const MICROAPP_FINMINE:string = "find_mine";
export const MICROAPP_ACCESS:string = "access";
@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappFaultreportModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = "MAPP-FAULTREPORT.DESCRIPTION";
  icon: string = "announcement";
  page = FaultreportModule;
  homeApp: boolean = false;
  path: string = "faultreport";
  order: number = 3;
  fakebackend: EmpusaFakeBackend = new FaultreportFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves   
    (<any>window).empusa(this);
        //loading micro app translations
        this.translationLoader.loadTranslations(english);
  }

}
