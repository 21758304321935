export class Issue {
    ticket_id: string;
    status: string;
    priority: string;
    owner: string;
    severity: string;
    date: string;
    summary: string;
    description: string;
    reporter: string;
    type: string;
    location:string;
    category:string;
    user:string;
    comment:string;
    email:string;
    tower: string;
    floor: string;
    subcategory: string;
    read:boolean;
    constructor() {
    }
}

