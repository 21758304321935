import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EpsServices } from "../services/microapp-eps.service";
import * as epsActions from './microapp-eps.actions';
import * as epsState from './microapp-eps.reducer'
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { EpsParkingSize } from "../model/microapp-eps.parking-size.model";
import { EpsTenantQuotas } from "../model/microapp-eps.tenan-quote.model";
import { EpsVehicleType } from "../model/microapp-eps.vehicle-type.model";
import { EpsChargeType } from "../model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsAssignedChargeType } from "../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model";
import { EpsTenantTariff } from "../model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { EpsSpotType } from "../model/microapp-eps.spot-type.model";
import { EpsReservationType } from "../model/reservation/microapp-eps.reservation-type.model";


@Injectable()
export class MicroappEpsEffects {

    constructor(private actions$: Actions,
        private epsServices: EpsServices) { }

    loadData$ = createEffect(() => this.actions$.pipe(
        ofType(epsActions.epsGetParkinfData),
        switchMap((action) => {
            const parkingSize = this.epsServices.parkingSize();
            const quotas = this.epsServices.quotas();
            const vehicleTypes = this.epsServices.vehicleTypes();
            const chargesAndTariffs = this.epsServices.get_charges_and_tariffs()
            const assignedCharges = this.epsServices.get_assigned_charges();
            const tenantTariffs = this.epsServices.get_tenant_tariffs();
            const reservationTypes = this.epsServices.reservationTypes();
            const spotTypes = this.epsServices.spotTypes();
            return forkJoin([parkingSize, quotas, vehicleTypes, chargesAndTariffs, assignedCharges, tenantTariffs, reservationTypes, spotTypes]).pipe(
                map(results => {
                    const parkingSize: EpsParkingSize[] = results[0] as EpsParkingSize[];
                    let tenantQuotas: EpsTenantQuotas[] = results[1] as EpsTenantQuotas[];
                    tenantQuotas.sort(this.compareQuotas);
                    const parkingVehicleTypes: EpsVehicleType[] = results[2] as EpsVehicleType[];
                    const chargesAndTariffs: EpsChargeType[] = results[3] as EpsChargeType[];
                    const assignedCharges: EpsAssignedChargeType[] = results[4] as EpsAssignedChargeType[];
                    let tenantTariffs: EpsTenantTariff[] = results[5] as EpsTenantTariff[];
                    tenantTariffs.sort(this.compareTenantTariff);
                    const reservationTypes: EpsReservationType[] = results[6] as EpsReservationType[];
                    const spotTypes: EpsSpotType[] = results[7] as EpsSpotType[];
                    return epsActions.epsStoreParkingData({ parkingSize, tenantQuotas, parkingVehicleTypes, chargesAndTariffs, assignedCharges, tenantTariffs, reservationTypes, spotTypes });
                }), catchError(error => {
                    return of(epsActions.epsError());
                }));
        })
    ));

    epsGetTenantTariffs$ = createEffect(() => this.actions$.pipe(
        ofType(epsActions.epsGetTenantTariffs),
        switchMap((action) => {
            const tenantTariffs = this.epsServices.get_tenant_tariffs();
            return tenantTariffs.pipe(
                map(resultados => {
                    let tenantTariffs: EpsTenantTariff[] = resultados;
                    tenantTariffs.sort(this.compareTenantTariff);
                    return epsActions.epsStoreTenantTariffs({ tenantTariffs });
                }), catchError(error => {
                    return of(epsActions.epsError());
                }));
        })
    ));

    compareTenantTariff(a: EpsTenantTariff, b: EpsTenantTariff) {
        const aName = a.tenant.name.toUpperCase();
        const bName = b.tenant.name.toUpperCase();

        let comparison = 0;
        if (aName > bName) {
            comparison = 1;
        } else if (aName < bName) {
            comparison = -1;
        }
        return comparison;
    }


    compareQuotas(a: EpsTenantQuotas, b: EpsTenantQuotas) {
        const aName = a.name.toUpperCase();
        const bName = b.name.toUpperCase();

        let comparison = 0;
        if (aName > bName) {
            comparison = 1;
        } else if (aName < bName) {
            comparison = -1;
        }
        return comparison;
    }

    getQuotas$ = createEffect(() => this.actions$.pipe(
        ofType(epsActions.epsGetQuotas),
        switchMap((action) => {
            const quotas = this.epsServices.quotas();
            return quotas.pipe(
                map(resultados => {
                    const tenantQuotas: EpsTenantQuotas[] = resultados;
                    return epsActions.epsStoreQuotas({ tenantQuotas });
                }), catchError(error => {
                    return of(epsActions.epsError());
                }));
        })
    ));


    getParkingSize$ = createEffect(() => this.actions$.pipe(
        ofType(epsActions.epsGetParkingSize),
        switchMap((action) => {
            const quotas = this.epsServices.parkingSize();
            return quotas.pipe(
                map(resultados => {
                    const parkingSize: EpsParkingSize[] = resultados;
                    return epsActions.epsStoreParkingSize({ parkingSize });
                }), catchError(error => {
                    return of(epsActions.epsError());
                }));
        })
    ));
}
