import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend } from '@empusa/empusa-core';
import { HomeModule } from '../pages/microapp-home.home.module';
import { HomeFakeBackend } from '../fake/fake.backend';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappHomeModule implements EmpusaMicroApp {
  name: string = "home";
  description: string = "Home"
  icon: string = "home";
  page = HomeModule;
  homeApp: boolean = true;
  path: string = "home";
  order: number = 1;
  fakebackend: EmpusaFakeBackend = new HomeFakeBackend();

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
  }

}
