<div>
    <h2 class="titleNew">{{'MAPP-NOTIFICATIONS.DETAIL.DETAIL' | translate}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button></h2>
</div>
<form [formGroup]="detailForm">

    <mat-form-field class="fieldNotidication" appearance="none">
        <mat-label>{{'MAPP-NOTIFICATIONS.DETAIL.DATE' | translate}}</mat-label>
        <input class="inputDate" matInput readonly formControlName="date" name="reporter" mt-autofocus>
    </mat-form-field>
    <div>
        <mat-form-field class="fieldNotidication" appearance="none">
            <mat-label>{{'MAPP-NOTIFICATIONS.DETAIL.SUBJECT' | translate}}</mat-label>
            <input class="inputDate" matInput readonly formControlName="title" name="title" mt-autofocus>
        </mat-form-field>
    </div>
    <p></p>
    <div>
        <mat-form-field class="fieldNotidicationDest" appearance="none">
            <mat-label>{{'MAPP-NOTIFICATIONS.DETAIL.TO' | translate}}</mat-label>
            <mat-chip-list formControlName="destinatary" class="areaChip" #chipList>
                <mat-chip class="chipTag" *ngFor="let tag of lista">
                    {{tag}}
                </mat-chip>
            </mat-chip-list>


            <!--
            <input class="inputDate" matInput readonly formControlName="destinatary" name="destinatary"
            mt-autofocus> -->
        </mat-form-field>
    </div>
    <p></p>
    <!-- <mat-form-field class="fieldNotidicationMessage" appearance="none">
        <mat-label>{{'MAPP-NOTIFICATIONS.DETAIL.MESSAGE' | translate}}</mat-label>
        <textarea [readonly]="true" class="messageArea" matInput formControlName="message" name="message"
            mt-autofocus></textarea>
    </mat-form-field> -->
    <div class="messageHtml">
        <span [innerHtml]="htmlString"></span>
    </div>
    <div>
        <mat-list class="listFiles">
            <mat-list-item class="labelFileUpload" *ngFor="let fi of filesName">
                <button mat-stroked-button (click)="downloadFile(fi.filename)">
                    {{fi.filename}}<mat-icon>download</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </div>
</form>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>