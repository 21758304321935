export class DTOParkingQuote {
  Tenant:{
    Id:string;
    Name:string;
  }
  TenantId: string;
  TotalParkingSpaces: string;
  CarParkingSpaces: string;
  MotorBikesParkingSpaces: string;
  ConcessionParkingSpaces: string;
}