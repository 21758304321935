<div style="overflow-y: auto; height: 580px;">
    <div>
        <h2 class="titleNew"> {{'MAPP-NOTIFICATIONS.NEW.NEW' | translate}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
            </button></h2>
    </div>
    <form [formGroup]="newNotificationForm">
        <div>
            <mat-form-field class="fieldNotidication">
                <input required matInput formControlName="summary" placeholder="{{'MAPP-NOTIFICATIONS.NEW.SUBJECT' | translate}}" name="summary" mt-autofocus>
                <mat-error *ngIf="newNotificationForm.controls.summary.hasError('required')">{{'MAPP-NOTIFICATIONS.NEW.ERROR' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-select required formControlName="typeDestiny" placeholder="{{'MAPP-NOTIFICATIONS.NEW.SENDTO' | translate}}">
                    <mat-option *ngFor="let ty of typeDestinatary" [value]="ty.type">
                        {{ty.type}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="newNotificationForm.controls.typeDestiny.hasError('required')">{{'MAPP-NOTIFICATIONS.NEW.ERROR' | translate}}</mat-error>
            </mat-form-field>
           
        </div>
        <p></p>
        <div>
            <mat-form-field class="formDestinatary" *ngIf="sendTo == 'Select Tenant'">
                <mat-select required formControlName="destiniy" placeholder="{{'MAPP-NOTIFICATIONS.NEW.TO' | translate}}" [multiple]="true">
                    <mat-option *ngFor="let tenant of tenantsList" [value]="tenant">
                        {{tenant.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="newNotificationForm.controls.destiniy.hasError('required')">{{'MAPP-NOTIFICATIONS.NEW.ERROR' | translate}}</mat-error>
            </mat-form-field>
    
            <mat-form-field class="formDestinatary" *ngIf="sendTo == 'Select Users'">
                <mat-chip-list required class="areaChip" #chipList>
                    <mat-chip required class="chipTag" *ngFor="let tag of listTag" [selectable]="true" [removable]="true"
                        (removed)="removeTag(tag)">
                        {{tag}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input required class="inputChip" formControlName="destiniy" placeholder="{{'MAPP-NOTIFICATIONS.NEW.TO' | translate}}" #tagInput
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
                    <mat-option *ngFor="let user of filteredOptions | async" [value]="user.mail">
                        {{user.mail}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <p></p>
            <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
        <div>
            <button class="buttonUpload" mat-stroked-button (click)="onClick()">
                <mat-icon>file_upload</mat-icon>{{'MAPP-NOTIFICATIONS.NEW.FILE' | translate}}
            </button>
            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                accept="file_extension|image/*" style="display:none;" />
        </div>
        <div>
            <mat-list class="listFiles" role="list">
                <mat-list-item class="labelFileUpload" *ngFor="let fi of files">
                    {{fi.data.name}}
                    <button class="cancelFile" (click)="deleteFile(fi.data.name)">
                        <mat-icon class="ic_cancel">cancel</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
    </form>
    <p></p>
    <div mat-dialog-actions class="buttonFitting" align="end">
        <button class="buttonClose" mat-raised-button mat-dialog-close>
            {{'MAPP-NOTIFICATIONS.NEW.CANCEL' | translate}}</button>
        <button [disabled]="!newNotificationForm?.valid" class="buttonConfirm" mat-raised-button color="primary"
            (click)="onSubmit()">
            {{'MAPP-NOTIFICATIONS.NEW.SEND' | translate}}</button>
    </div>
    <empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
</div>