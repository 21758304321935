import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsReservationModel } from './microapp-fbs-reservation';
import { MicroappFbsOwnerModel } from './microapp-fbs-owner';

@Injectable({
  providedIn: "root",
})
export class FbsReservationAdapter implements Adapter<MicroappFbsReservationModel> {

  constructor(private fbsConfig: FbsConfig,) { }

  adapt(item: MicroappFbsReservationModel): MicroappFbsReservationModel {
    let reservation = new MicroappFbsReservationModel;
    reservation.description = item.description;
    reservation.endDate = new Date(item.endDate);
    reservation.startDate = new Date(item.startDate);
    reservation.referenceNumber = item.referenceNumber;
    let owner = new MicroappFbsOwnerModel;
    owner.emailAddress = item.owner.emailAddress;
    owner.firstName = item.owner.firstName;
    owner.lastName = item.owner.lastName;
    owner.userId = item.owner.userId;
    owner.tenant_name = item.owner.tenant_name;
    reservation.owner = owner;
    reservation.title = item.title;
    reservation.resourceName = item.resourceName;
    reservation.groupId = item.groupId;
    reservation.resourceId = item.resourceId;
    reservation.applied_charge_type = item.applied_charge_type;
    reservation.total_cost = item.total_cost;
    reservation.extra = item.extra;
    return reservation;
  }


}