<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersListNotification">{{'MAPP-NOTIFICATIONS.LIST.FILTER.FILTER' |
                        translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-NOTIFICATIONS.LIST.FILTER.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label>{{'MAPP-NOTIFICATIONS.LIST.FILTER.SEND' | translate}}</mat-label>
                    <mat-chip-list formControlName="SEND" class="order2" aria-label="sendChipsFilter">
                        <mat-chip *ngFor="let chip of chips" [selected]="chip.name==userFilter.send"
                            (click)="applySendFilter( chip.name)">{{chip.name}}</mat-chip>
                    </mat-chip-list>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-NOTIFICATIONS.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <form class="searchForm" [formGroup]="searchForm">
                <mat-form-field class="margen_cajas">
                    <mat-label>{{'MAPP-NOTIFICATIONS.LIST.SEARCH.SEARCHBY' | translate}}</mat-label>
                    <mat-select formControlName="SEARCHBY">
                        <mat-option *ngFor="let type of types" [value]="type.name">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="margen_cajas">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="FROM">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="margen_cajas">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="TO">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngIf="!noShow && !isUser" class="margen_cajas2">
                    <mat-label>{{'MAPP-NOTIFICATIONS.LIST.SEARCH.SEARCH' | translate}}</mat-label>
                    <input type="text" matInput formControlName="SEARCH" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnT">
                        <mat-option *ngFor="let option of filteredOption | async" [value]="option.name">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field *ngIf="!noShow && isUser" class="margen_cajas2">
                    <mat-label>{{'MAPP-NOTIFICATIONS.LIST.SEARCH.SEARCH' | translate}}</mat-label>
                    <input type="text" matInput formControlName="SEARCH" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOption | async" [value]="option.mail">
                            {{option.mail}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>



                <!-- <mat-form-field *ngIf="!noShow" class="margen_cajas">
                    <input formControlName="SEARCH" matInput
                        placeholder="{{'MAPP-NOTIFICATIONS.LIST.SEARCH.SEARCH' | translate}}">
                </mat-form-field> -->

                <button [disabled]="!searchForm?.valid" mat-flat-button class="buttonSearch" color="primary"
                    (click)="searchCiruclars()">
                    <mat-icon>search</mat-icon>
                </button>

                <!-- <button mat-flat-button class="buttonAll" color="primary" (click)="viewNotifications()">
                    {{'MAPP-NOTIFICATIONS.LIST.SEARCH.ALL' | translate}}
                </button> -->
            </form>
            <!-- <form class="searchForm" [formGroup]="searchForm">
           
            <button mat-flat-button class="buttonAll" color="primary" (click)="viewNotifications()">
                {{'MAPP-NOTIFICATIONS.LIST.SEARCH.ALL' | translate}}
            </button>
            </form> -->
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id="tablaFittingList">
                <ng-container matColumnDef="file">
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <mat-icon *ngIf="element.content_files" class="icFile">attach_file</mat-icon>
                        </div>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-NOTIFICATIONS.LIST.TABLE.DATE' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.date | date: localDateFormat }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="title">
                    <mat-header-cell mat-sort-header *matHeaderCellDef>{{'MAPP-NOTIFICATIONS.LIST.TABLE.TITLE' |
                        translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sendto">
                    <mat-header-cell mat-sort-header *matHeaderCellDef>{{'MAPP-NOTIFICATIONS.LIST.TABLE.SEND' |
                        translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.sendto}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-NOTIFICATIONS.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button (click)="detailNotification(element.notificationid)" mat-icon-button>
                                <mat-icon matTooltip="{{'MAPP-NOTIFICATIONS.TOOLTIP.DETAILS' | translate}}"
                                    class="icActions">description</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
            </table>
        </div>

        <div class="footer-container">
            <div class="button-new-container">
                <button class="extend" mat-fab color="primary" (click)="createNotification()"
                    aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'> {{'MAPP-NOTIFICATIONS.LIST.NEW' | translate}}</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
            </div>
        </div>
    </mat-drawer-container>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>