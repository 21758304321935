import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {floor, isEqual, round, truncate} from "lodash";


export interface SliderValueModel {
  value: any;
  label: string;
  color: string;
  tooltipLabel?: string;
}
export interface SliderSelectionModel {
  start?: any;
  end: any;
}


@Component({
  selector: 'empusa-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css']
})
export class RangeSliderComponent implements OnInit {

  @Input() rangeEnabled: boolean = true;
  @Input() initialValues: SliderSelectionModel;
  @Input() showTooltips: boolean = true;
  @Input() cssSliderStyles: string = 'background-color: black;';
  @Output() onSliderChange = new EventEmitter<SliderSelectionModel>();
  @Input() values: SliderValueModel[];
  @Input() allowStartAndEndEquals: boolean = false;


  @ViewChild("sliderElement") sliderElement: ElementRef;
  @ViewChild("pointerLElement") pointerLElement: ElementRef;
  @ViewChild("pointerRElement") pointerRElement: ElementRef;
  @ViewChild("selectedRangeElement") selectedRangeElement: ElementRef;

  slider: any;
  pointerL: any;
  pointerR: any;
  selected: any;

  sliderLeft = 0;
  sliderWidth = 0;
  pointerWidth = 0;
  step: number;

  selectedIndexes: any = {};
  previousSelectedValues: SliderSelectionModel;

  tooltipL: string | undefined = '';
  tooltipR: string | undefined = '';

  // insMargin: number;

  activePointer: any;

  constructor(private cdRef:ChangeDetectorRef) {

  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.slider = this.sliderElement.nativeElement;
    this.pointerL = this.pointerLElement.nativeElement;
    this.pointerR = this.pointerRElement.nativeElement;
    this.selected = this.selectedRangeElement.nativeElement;

    this.setInitialValues();
    this.calculateStep();
    this.setValues();
    this.cdRef.detectChanges();
  }

  public forceChangeDetection(){
    this.cdRef.detectChanges();
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMove(e: any){
    this.calculateStep();
    if (!this.activePointer) {
      return;
    }

    var coordX = e.type === "touchmove" ? e.touches[0].clientX : e.pageX,
      index = coordX - this.sliderLeft - this.pointerWidth / 2 + 12;

    index = Math.round(index / (this.step));

    if (index <= 0) index = 0;
    if (index > this.values.length - 1){
      index = this.values.length - 1;
    }


    if (this.rangeEnabled) {
      if (this.activePointer === this.pointerL)
        this.selectedIndexes.start = index;
      if (this.activePointer === this.pointerR)
        this.selectedIndexes.end = index;
    } else {
      this.selectedIndexes.end = index;
    }

    return this.setValues();

  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:touchcancel', ['$event'])
  drop(){
    this.activePointer = null;
  }

  drag(pointer: any){
    this.activePointer = pointer;
    return this.slider.classList.add("sliding");
  }

  // This function must not be comment, is used for listen window resize events.
  @HostListener('window:resize', ['$event'])
  onResize(){
    this.calculateStep();
    this.setValues();
  }

  calculateStep(){
    this.sliderLeft = this.slider.getBoundingClientRect().left;
    this.sliderWidth = this.slider.clientWidth;
    this.pointerWidth = this.pointerL.clientWidth;
    this.step = floor(this.sliderWidth / (this.values.length - 1), 1);
    this.cdRef.detectChanges();
  }

  public setValues(start?: any, end?: any){
    let activePointer = this.rangeEnabled ? "start" : "end";

    if (start && this.values.findIndex((item) => item.value == start) > -1)
      this.selectedIndexes[activePointer] = this.values.findIndex((item) => item.value == start);

    if (end && this.values.findIndex((item) => item.value == end) > -1)
      this.selectedIndexes.end = this.values.findIndex((item) => item.value == end)

    if (this.rangeEnabled && this.selectedIndexes.start > this.selectedIndexes.end)
      this.selectedIndexes.start = this.selectedIndexes.end;

    this.pointerL.style.left = this.selectedIndexes[activePointer] * this.step - this.pointerWidth / 2 + "px";

    if (this.rangeEnabled) {
      const startValue = this.values[this.selectedIndexes.start];
      const endValue = this.values[this.selectedIndexes.end]
      this.tooltipL = startValue.tooltipLabel ? startValue.tooltipLabel : startValue.label;
      this.tooltipR = endValue.tooltipLabel ? endValue.tooltipLabel : endValue.label;
      this.pointerR.style.left = this.selectedIndexes.end * this.step - this.pointerWidth / 2 + "px";
    } else {
      // if (this.conf.tooltip){
      //   this.tipL.innerHTML = this.conf.values[this.values.end];
      // }
      // this.input.value = this.conf.values[this.values.end];
    }

    if (this.selectedIndexes.end > this.values.length - 1)
      this.selectedIndexes.end = this.values.length - 1;
    if (this.selectedIndexes.start < 0) this.selectedIndexes.start = 0;

    this.selected.style.width = (this.selectedIndexes.end - this.selectedIndexes.start) * this.step + "px";
    this.selected.style.left = this.selectedIndexes.start * this.step + "px";

    const selectedValues = {start: this.values[this.selectedIndexes.start].value, end: this.values[this.selectedIndexes.end].value};

    if(!this.previousSelectedValues  || (!isEqual(selectedValues.start, this.previousSelectedValues.start) || !isEqual(selectedValues.end, this.previousSelectedValues.end))){
      this.previousSelectedValues = selectedValues;
      this.onSliderChange.emit(selectedValues);

    }

  }

  setInitialValues(){

    let startValueIndex;
    let endValueIndex;
    if (this.initialValues) {
      if (this.rangeEnabled) {
        startValueIndex = this.values.findIndex((item) => isEqual(item.value, this.initialValues.start));
        endValueIndex = this.values.findIndex((item) => isEqual(item.value, this.initialValues.end));
      } else {
        endValueIndex = this.values.findIndex((item) => isEqual(item.value, this.initialValues.end))
      }
    }
    this.checkAndSetSelectedIndexes(startValueIndex, endValueIndex);
  }

  checkAndSetSelectedIndexes(startValueIndex: number, endValueIndex: number){
    if (startValueIndex && startValueIndex >= 0 && startValueIndex < this.values.length){
      this.selectedIndexes.start = startValueIndex;
    } else {
      this.selectedIndexes.start = 0;
    }

    if (endValueIndex && endValueIndex >= 0 && endValueIndex < this.values.length){
      this.selectedIndexes.end = endValueIndex;
    } else {
      this.selectedIndexes.end = this.rangeEnabled ? this.values.length - 1 : 0;
    }
  }

}
