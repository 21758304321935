<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersListFault"> {{'MAPP-FAULTREPORT.LIST.FILTERS' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-FAULTREPORT.LIST.FILTERT' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label> {{'MAPP-FAULTREPORT.LIST.TABLE.STATUS' | translate}}</mat-label>
                    <mat-chip-list formControlName="STATUS" class="order" aria-label="statusChipsFilter">

                        <mat-chip *ngFor="let chip of chips" [selected]="chip.name==userFilter.status"
                            (click)="applyStatusFilter(chip.name)">{{chip.name}}</mat-chip>
                    </mat-chip-list>
                    <p></p>

                    <div *ngIf="isAdmin">
                        <mat-label> {{'MAPP-FAULTREPORT.LIST.TABLE.PRIORITY' | translate}}</mat-label>
                        <mat-chip-list formControlName="PRIORITY" class="order2" aria-label="priorityChipsFilter">
                            <mat-chip *ngFor="let prior of listPriorities" [selected]="prior.name==userFilter.priority"
                                (click)="applyPriorityFilter( prior.name)">{{prior.name}}</mat-chip>
                        </mat-chip-list>
                    </div>

                    <p></p>
                    <div *ngIf="isAdmin">
                        <mat-label>Read</mat-label>
                        <mat-chip-list formControlName="READ" class="order" aria-label="statusChipsFilter">
                            <!--[selectable]="true"-->
                            <mat-chip class="chipFilterStatus" *ngFor="let chipS of chipsState"
                                [selected]="chipS.state==userFilter.read" (click)="applyReadFilter(chipS.state)">
                                <mat-icon class="icActions">{{chipS.icon}}</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <p></p>
                    <mat-form-field>
                        <mat-label> {{'MAPP-FAULTREPORT.LIST.TABLE.STARTDATE' | translate}}</mat-label>
                        <input formControlName="START" matInput [matDatepicker]="pickerStart"
                            (dateInput)="applyStartDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="pickerStart">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>
                    <p></p>
                    <mat-form-field>
                        <mat-label> {{'MAPP-FAULTREPORT.LIST.TABLE.ENDDATE' | translate}}</mat-label>
                        <input matInput formControlName="END" [matDatepicker]="pickerEnd"
                            (dateInput)="applyEndDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="pickerEnd">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                    <p></p>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-FAULTREPORT.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>

        <div class="header">



            <button mat-stroked-button *ngIf="allowEscalate" [disabled]="selection.selected.length < 1"
                class="buttonSend" (click)="escalationManager()">
                <mat-icon>send</mat-icon>
                {{'MAPP-FAULTREPORT.LIST.TABLE.SEND' | translate}}
            </button>

            <form class="searchForm" [formGroup]="searchForm">
                <mat-form-field class="margen_cajas">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="FROM">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="margen_cajas">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="TO" [min]='minSelectTo'
                        [max]='maxSelectTo'>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <button [disabled]="!searchForm?.valid" mat-flat-button class="buttonSearch" color="primary"
                    (click)="searchReports()">
                    <mat-icon>search</mat-icon>
                </button>
            </form>
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>

        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id='primera'>
                <ng-container matColumnDef="select">
                    <mat-header-cell [ngClass]="{'hidden-item':!allowEscalate}" *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hidden-item':!allowEscalate}" *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell [ngClass]="{'paddingFirstDate':!allowEscalate}" *matHeaderCellDef mat-sort-header>
                        {{'MAPP-FAULTREPORT.LIST.TABLE.DATE' | translate}}</mat-header-cell>
                    <mat-cell [ngClass]="{'paddingFirstDate':!allowEscalate, 'notReadText': !element.read}"
                        *matCellDef="let element">{{element.date |
                        date:localDateFormat}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="summary">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-FAULTREPORT.LIST.TABLE.TITLE' | translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'notReadText': !element.read}" *matCellDef="let element">{{element.summary}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <mat-header-cell [ngClass]="{'hidden-item':(isUser || isManagement)}" *matHeaderCellDef
                        mat-sort-header>
                        {{'MAPP-FAULTREPORT.LIST.TABLE.GROUP' | translate}}</mat-header-cell>
                    <mat-cell [ngClass]="{'hidden-item':(isUser || isManagement), 'notReadText': !element.read}"
                        *matCellDef="let element">{{(element.owner).replace("MANAGEMENT","TENANT
                        MANAGER").replace("ADMIN","FACILITY MANAGER").replace("SUPERFACILITY MANAGER","SUPERADMIN")}}
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="severity">
                <mat-header-cell  [ngClass]="{'hidden-item':!allowSeverity}" *matHeaderCellDef mat-sort-header>
                    {{'MAPP-FAULTREPORT.LIST.TABLE.SEVERITY' | translate}}</mat-header-cell>
                    <mat-cell [ngClass]="{'hidden-item':!allowSeverity, 'cellSeverity':allowSeverity}" *matCellDef="let element">{{element.severity}}</mat-cell>  </ng-container> -->
                <ng-container matColumnDef="priority">
                    <mat-header-cell [ngClass]="{'hidden-item':(isUser || isManagement)}" *matHeaderCellDef
                        mat-sort-header>
                        {{'MAPP-FAULTREPORT.LIST.TABLE.PRIORITY' | translate}}</mat-header-cell>
                    <mat-cell [ngClass]="{'hidden-item':(isUser || isManagement), 'priority1': element.priority === 'urgent', 'priority2': element.priority ==='moderate',
                'priority3': element.priority ==='low', 'priority4': element.priority === 'critical'}"
                        *matCellDef="let element">
                        <!-- <mat-icon class="priority4" *ngIF="element.priority === 'critical'">warning</mat-icon> -->
                        {{element.priority}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-FAULTREPORT.LIST.TABLE.STATUS' | translate}}</mat-header-cell>
                    <mat-cell
                        [ngClass]="{'statusOpen':element.status === 'new', 'statusAssigned':element.status === 'assigned', 'statusClose':element.status === 'closed'}"
                        *matCellDef="let element">
                        <mat-chip color="primary" selected>{{element.status}}</mat-chip>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="read">
                    <mat-header-cell [ngClass]="{'hidden-item':(isUser || isManagement)}" *matHeaderCellDef
                        mat-sort-header>
                        Read</mat-header-cell>
                    <mat-cell
                        [ngClass]="{'hidden-item':(isUser || isManagement), 'doubleCheck': element.read ,'notReadText': !element.read}"
                        *matCellDef="let element">
                        <span *ngIf="!element.read">
                            <mat-icon>done</mat-icon>
                        </span>
                        <span *ngIf="element.read">
                            <mat-icon>done_all</mat-icon>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-FAULTREPORT.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-FAULTREPORT.TOOLTIP.EDIT' | translate}}" *ngIf="allowDetail"
                                mat-icon-button (click)="getEdit(element.ticket_id)">
                                <mat-icon class="icActions">edit</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row [ngClass]="{'notRead': !row.read}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>


        <!-- <div [hidden]="true" class="button-newVisit">
            <button mat-fab color="primary" aria-label="Example icon button with a add icon">
                <mat-icon>add</mat-icon>
            </button>
        </div> -->


        <div class="footer-container">
            <div class="button-new-container">
                <button (click)=create() class="extend" mat-fab color="primary" aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'> {{'MAPP-FAULTREPORT.ISSUE.HEAD' | translate}}</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
            </div>
        </div>
    </mat-drawer-container>

</div>