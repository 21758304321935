import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthenticationService } from "@empusa/empusa-core";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class SmartBmsService {
     url: string = this.environment.urlbase + 'smartbms/services/';
    // url: string = 'http://localhost:8000/smartbms/services/';

    constructor(private http: HttpClient,
        @Inject('environment') private environment,
        private auth: AuthenticationService) {
    }

    // getExtensionByTenantId(tenantId : string){
    //     return this.http.get(`${this.url}hvac/extensions/${tenantId}`).pipe(
    //         map(data => <any>data)
    //     );
    // }

    getExtensionByTenantId(tenantId: string, from, to) {
        let params = {
            'tenantid': tenantId,  
            "from": from,
            "to":to,
        };
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        let body = JSON.stringify(params);
        return this.http.post(`${this.url}hvac/extension/list`, body, { headers }).pipe(
            map(data => <any>data)
        );
    }

    deviceInfo(deviceId: string){
        let params = {};
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        let body = JSON.stringify(params);
        return this.http.post(`${this.url}hvac/devices/device/`+deviceId,  body, { headers }).pipe(
            map(data => <any>data)
        );
    }

    devicesBuilding(building){
        let params = {};
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        let body = JSON.stringify(params);
        return this.http.post(`${this.url}hvac/devices/building/`+building,  body, { headers }).pipe(
            map(data => <any>data)
        );
    }
}