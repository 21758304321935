import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, Tenant, User } from '@empusa/empusa-core';
import { MICRAPP_ACCESS_USERS, MICROAPP_NAME } from '../public-api';
import { SiteService } from '../client/site.service';
// import { MICROAPP_NAME, MICRAPP_ACCESS_USERS } from '../lib/microapp-usermanagement.module';


@Component({
  selector: 'microapp-usermanagement',
  templateUrl: './microapp-usermanagement.user.component.html',
  styleUrls: ['./microapp-usermanagement.user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  isManagement: boolean;
  siteName: string;
  loading: boolean;
  can_access: boolean = false;
  tenantList: Tenant[] = [];

  @Output("tenantList") onTenantListEvent = new EventEmitter();
  constructor(private auth: AuthenticationService, private router: Router, private siteService: SiteService,) {
  }

  ngOnInit() {
    // this.siteName = this.auth.getCurrentSite().name;
    this.can_access = this.auth.canUserExecute(MICROAPP_NAME, MICRAPP_ACCESS_USERS)
    this.loading = true;
    // this.auth.loadCurrentSite().then(site => {
    this.auth.loadCurrentUser().then((user: User) => {
      console.log("test", user.sites[0].tenants[0].name)
      this.isManagement = this.auth.hasCurrentUserRole("MANAGEMENT");
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      // this.loading = false;
      this.siteName = user.sites[0].name;
      if (!this.isManagement) {
        this.siteService.getSite(user.sites[0].uid).subscribe((data) => {
          this.loading = false;
          this.tenantList = data.tenants;
          this.tenantListEvent(this.tenantList);
        });
      } else {
        this.loading = false;
      }
    })


    // });
  }

  tenantListEvent(event: Tenant[]) {
    this.onTenantListEvent.emit(event);
  }
}
