<h3 mat-dialog-title class="tittleAddComment"> {{'MAPP-FITTINGOUT.FITTING.COMMENTS'| translate}}</h3>
<div mat-dialog-content>
    <mat-label class="labelAssign">{{'MAPP-FITTINGOUT.FITTING.ADD.TEXT'| translate}}
    </mat-label>
    <mat-form-field class="textCommentary">
        <mat-label>{{'MAPP-FITTINGOUT.FITTING.ADD.LABEL'| translate}}*</mat-label>
        <textarea matInput class="areaComentary" [(ngModel)]="comment" name="comment"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonCloseComment" mat-button color="primary" (click)="onNoClick()">
        {{'MAPP-FITTINGOUT.ACTIONS.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-stroked-button color="primary" [disabled]="comment===''"
        [mat-dialog-close]="comment">
        {{'MAPP-FITTINGOUT.FITTING.ADD.ADD'| translate}}</button>
</div>