<mat-list>
   <mat-list-item>
      <mat-icon class="iconDetail" mat-list-icon>event</mat-icon>
      <div class="principalEvent" mat-line>{{visitGroup.start_time| date: localDateFormat: offset }} </div>
      <div class="secundaryEvent" mat-line>{{visitGroup.start_time | date : 'HH:mm': offset}} -
         {{visitGroup.end_time | date : 'HH:mm':offset}} </div>
   </mat-list-item>
   <mat-list-item>
      <mat-icon class="iconDetail" mat-list-icon>location_on</mat-icon>
      <div class="principalLoacation" mat-line>{{visitGroup.host.firstName}} {{visitGroup.host.lastName}}</div>
      <div class="secundaryLoacation" mat-line>{{visitGroup.tenant.name}}</div>
   </mat-list-item>
   <mat-list-item class="tableVisit">
      <mat-icon class="iconDetail" mat-list-icon>people</mat-icon>
      <div class="principalVisitor" mat-line> {{'MAPP-VMS-GROUP-VISIT-DETAIL.VISITORS' | translate}}</div>
      <p></p>
   </mat-list-item>
   <div class="visitors">
      <mat-table class="tableVisitor" [dataSource]="visitDataSource">
         <ng-container matColumnDef="img">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="cellVisit" *matCellDef="let visit">
               <mat-icon>account_circle</mat-icon>
            </mat-cell>
         </ng-container>
         <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-GROUP-VISIT-DETAIL.NAME' | translate}} </mat-header-cell>
            <mat-cell class="cellVisit" *matCellDef="let visit"> <b>
                  {{visit.first_name}} {{visit.last_name}}
               </b></mat-cell>
         </ng-container>
         <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-GROUP-VISIT-DETAIL.COMPANY' | translate}} </mat-header-cell>
            <mat-cell class="cellVisit" *matCellDef="let visit"> {{visit.company}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="parking">
            <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-GROUP-VISIT-DETAIL.PARKING' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let visit">                                
               <mat-icon class="icParkingOk" matTooltip="{{'MAPP-VMS-TOOLTIPS.PARKING_C' | translate}}" *ngIf="visit.complimentary_parking_status==='C'">
                  local_parking
               </mat-icon>
               <mat-icon class="icParkingKo" matTooltip="{{visit.complimentary_parking_message}}" *ngIf="visit.complimentary_parking_status==='R'"> local_parking
               </mat-icon>
               <mat-icon class="icParkingKo" matTooltip="{{'MAPP-VMS-TOOLTIPS.PARKING_E' | translate}}" *ngIf="visit.complimentary_parking_status==='E'"> local_parking
               </mat-icon>
               <mat-icon class="icParkingPending" matTooltip="{{'MAPP-VMS-TOOLTIPS.PARKING_P' | translate}}" *ngIf="visit.complimentary_parking_status==='P'"> local_parking
               </mat-icon>
            </mat-cell>  
         </ng-container>
         <mat-header-row *matHeaderRowDef="displayedColumns" class="element-colum">
         </mat-header-row>
         <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row">
         </mat-row>
      </mat-table>
      
   </div>
</mat-list>