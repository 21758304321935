import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Survey } from './dto/survey';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {

  url: string = this.environment.urlbase + 'quiz/services/';

  // url: string = 'http://localhost:5000/quiz/services/';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  allSurvey(): any {
    return this.http.get(this.url + 'surveys').pipe(
      map(data => data)
    );
  }

  getSurvey(sid: String): any {
    return this.http.get(this.url + 'surveys/' + sid).pipe(
      map(data => data)
    );
  }

  addSurvey(survey: Survey) {
    let body = JSON.stringify(survey);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.url + 'surveys', body, { headers }).pipe(map(resp => resp));
  }

  activeSurvey(sid: string, active: boolean): any {
    let url: string = "surveys/" + sid + "/";
    if(active){
      url = url + "activate";
    }else{
      url = url + "deactivate";
    }
    return this.http.get(this.url + url).pipe(
      map(data => data)
    );
  }

  updateSurvey(survey: Survey) {
    let body = JSON.stringify(survey);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'surveys/' + survey.sid, body, { headers }).pipe(map(resp => resp));
  }

  deleteSurvey(sid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.delete(this.url + 'surveys/' + sid, { headers }).pipe(map(resp => resp));
  }

  resendSurvey(sid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.get(this.url + 'surveys/' + sid + '/remind', { headers }).pipe(map(resp => resp));
  }

  reportSurvey(sid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.get(this.url + 'surveys/' + sid + '/report', { headers }).pipe(map(resp => resp));
  }
  downloadReportSurvey(sid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.get(this.url + 'surveys/report/download/' + sid, { headers }).pipe(map(resp => resp));
  }

}

