<div>
    <h2 class="tittleEditVisitor" mat-dialog-title>{{'MAPP-VMS-VISITOR-EDIT.TITLE' | translate}} <span
            class="example-spacer"></span>
        <button class="closeEditVist" mat-icon-button (click)="onClose()">
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button>
    </h2>
</div>

<empusa-microapp-vms-visitor-form [mode]="'address-book'" [visitor]="visitor"></empusa-microapp-vms-visitor-form>

<!--
<mat-dialog-actions class="buttonEditVisitor" align="end">
    <button class="editVisitButton1" mat-raised-button mat-dialog-close>CANCEL</button>
    <button class="editVisitButton" mat-raised-button color="primary" [mat-dialog-close]="true">SAVE</button>
</mat-dialog-actions>
-->
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>