
<table mat-table [dataSource]="invoiceTableDataSource">
  <!-- Item Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> <strong> {{'MAPP-EPS.GENERIC.NAME' | translate}} </strong> </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)">
      <div>
        {{request.vehicle_owner_name}}
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)">
      <mat-icon> {{request.vehicle_icon}} </mat-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="ui">
    <th mat-header-cell *matHeaderCellDef> <strong> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.IU' | translate}} </strong>
    </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)"> {{request.vehicle_iu}} </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="plate">
    <th mat-header-cell *matHeaderCellDef> <strong> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLENO' | translate}}
      </strong> </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)"> {{request.license_plate}} </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> <strong> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.STATUS' | translate}} </strong>
    </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)">
      {{'MAPP-EPS.TENANT.RESERVATION_STATUS_CODE.' + request.status_code | translate}}</td>
    <td mat-footer-cell *matFooterCellDef class="invoice-total-footer no-border">
      <strong> {{'MAPP-EPS.TENANT.INVOICE.TOTAL' | translate}} </strong>
    </td>
  </ng-container>
  <ng-container matColumnDef="tariff">
    <th mat-header-cell *matHeaderCellDef> <strong> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.TARIFF' | translate}} </strong>
    </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)">
      <ng-container *ngIf="requestIsOK(request)">
        {{'MAPP-EPS.TENANT.INVOICE.TARIFF_' + request.reservation_types_code | translate}}
      </ng-container>
      <ng-container *ngIf="!requestIsOK(request)">
        -
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>{{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.ACTIONS' | translate}}
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="action">
        <button *ngIf="element.charge_to == 'Tenant'" matTooltip="Change Charge to User with Paypal"
          (click)="toPaypal(element.reservation_id)" mat-icon-button>
          <mat-icon class="icActions">paypal</mat-icon>
        </button>
        <button *ngIf="element.charge_to == 'User'" matTooltip="Change Charge to Tenant with Yardi"
          (click)="toYardi(element.reservation_id)" mat-icon-button>
          <mat-icon class="icActions">request_quote</mat-icon>
        </button>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="no-border"> </td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef style="width: 90px;"> <strong> {{'MAPP-EPS.TENANT.INVOICE.COST' | translate}}
      </strong> </th>
    <td mat-cell *matCellDef="let request" [class.color-red]="!requestIsOK(request)">
      <ng-container *ngIf="requestIsOK(request)">
        {{environment.currency}} {{request.price}}
      </ng-container>
      <ng-container *ngIf="!requestIsOK(request)">
        -
      </ng-container>
    </td>
    <ng-container>
      <td mat-footer-cell *matFooterCellDef class="no-border"> <strong> {{environment.currency}} {{calculateTotal()}}
        </strong> </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <ng-container *ngIf="showTotals">
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </ng-container>
</table>

<div class="notes-container">
  <h6>
    {{'MAPP-EPS.TENANT.PAYMENT.GST' | translate}}
  </h6>
</div>