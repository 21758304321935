<h2 class="titleNew">{{'MAPP-FBS-NEW-UPDATE-FACILITY.TITLE' | translate}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
    </button>
</h2>


<form [formGroup]="newResourceFormGroup" (ngSubmit)="onSubmit()">
    <div>
        <mat-form-field class="labelNewFacility">
            <mat-label>{{'MAPP-FBS-NEW-UPDATE-FACILITY.NAME' | translate}}</mat-label>
            <input matInput [formControl]="name">
            <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
                {{'MAPP-FBS-NEW-UPDATE-FACILITY.NAME_MANDATORY' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="labelNewFacility">
            <mat-label> {{'MAPP-FBS-NEW-UPDATE-FACILITY.LOCATION' | translate}}</mat-label>
            <input matInput [formControl]="location">
            <mat-error *ngIf="location.invalid && (location.dirty || location.touched)">
                {{'MAPP-FBS-NEW-UPDATE-FACILITY.LOCATION_MANDATORY' | translate}}</mat-error>
        </mat-form-field>
        <p></p>
        <mat-form-field class="labelNewFacility">
            <mat-label>{{'MAPP-FBS-NEW-UPDATE-FACILITY.CAPACITY' | translate}}</mat-label>
            <input matInput [formControl]="maxParticipants">
            <mat-error *ngIf="maxParticipants.invalid && (maxParticipants.dirty || maxParticipants.touched)">
                {{'MAPP-FBS-NEW-UPDATE-FACILITY.CAPACITY_ERROR' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="labelNewFacility">
            <mat-label>{{'MAPP-FBS-NEW-UPDATE-FACILITY.CHARGE_TYPE' | translate}}</mat-label>
            <mat-select [formControl]="chargeType">
                <mat-option *ngFor="let charge of selectChargeTypes" [value]="charge.application_charge_code">
                    {{charge.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p></p>
        <mat-form-field class="labelNewFacilityDescription">
            <mat-label>{{'MAPP-FBS-NEW-UPDATE-FACILITY.DESCRIPTION' | translate}}</mat-label>
            <textarea matInput [formControl]="description"></textarea>
            <mat-error *ngIf="description.invalid && (description.dirty || description.touched)">
                {{'MAPP-FBS-NEW-UPDATE-FACILITY.DESCRIPTION_MANDATORY' | translate}}</mat-error>
        </mat-form-field>

    </div>
    <div mat-dialog-actions class="buttonFitting" align="end">
        <button type="submit" class="buttonConfirm" [disabled]="!newResourceFormGroup.valid" mat-raised-button
            color="primary">{{'MAPP-FBS-NEW-UPDATE-FACILITY.CONFIRM' | translate}}</button>
    </div>
</form>

<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>