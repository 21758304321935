import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';
import { Subscription } from 'rxjs';
import { EpsParkingSize } from '../../../lib/model/microapp-eps.parking-size.model';
import { EpsTenantQuotas } from '../../../lib/model/microapp-eps.tenan-quote.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EpsVehicleType } from '../../../lib/model/microapp-eps.vehicle-type.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MicroappEpsQuotasEditComponent } from '../quotas-edit/microapp-eps-quotas-edit.component';
import { MicroappEpsQuotasDeleteComponent } from '../quotas-delete/microapp-eps-quotas-delete.component';
import { EpsQuote } from 'projects/microapp-eps/src/lib/model/microapp-eps.quote.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EpsSpotsToAssign } from 'projects/microapp-eps/src/lib/model/microapp-eps.spotsToAssign.model';
import { MicroappEpsQuotastFilter } from './microapp-eps-quotas.component.filter';

@Component({
  selector: 'empusa-microapp-eps-quotas',
  templateUrl: './microapp-eps-quotas.component.html',
  styleUrls: ['./microapp-eps-quotas.component.css']
})
export class MicroappEpsQuotasComponent implements OnInit, OnDestroy {


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.tenant_quotas_data) {
      this.tenant_quotas_data.paginator = mp;
    }
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (this.tenant_quotas_data) {
      this.tenant_quotas_data.sort = ms;
    }
  }

  storeSubscription: Subscription;
  loading: boolean = true;
  parkingDataLoaded: boolean = false;
  parkingSize: EpsParkingSize[];
  tenantQuotas: EpsTenantQuotas[] = [];
  parkingVehicleTypes: EpsVehicleType[];
  parking_size_data: MatTableDataSource<EpsParkingSize>;

  tenantNameFilter: MicroappEpsQuotastFilter = new MicroappEpsQuotastFilter;
  tenant_quotas_data: MatTableDataSource<EpsTenantQuotas>;
  public displayedColumns: string[] = [];
  public headerDisplayedColumns: string[] = [];

  first_header: boolean = false;
  span_first_header: number = 0;


  constructor(private epsServices: EpsServices,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
  ) { }


  ngOnInit(): void {
    //this.getInfo();
    this.storeSubscription = this.store.select('eps').subscribe(data => {
      this.loading = data.loading;
      if (data.parkingSize && data.tenantQuotas && data.parkingVehicleTypes) {
        this.parkingSize = data.parkingSize;
        this.tenantQuotas = data.tenantQuotas;
        this.parkingVehicleTypes = data.parkingVehicleTypes;
        this.parkingDataLoaded = data.parkingDataLoaded;
        this.displayedColumns = ['tenant_name'];

        if (data.parkingSize.length > 1) {
          this.first_header = true;
          this.span_first_header = data.parkingSize[0].vehicle_type.length;
          this.headerDisplayedColumns = ['header-space']
          for (var one_type of data.parkingSize) {
            this.headerDisplayedColumns.push("" + one_type.spot_type_id);
          }
        }

        for (var spot_type of data.parkingSize) {
          for (var vehicle_type of data.parkingVehicleTypes) {
            this.displayedColumns.push(spot_type.spot_type_id + "" + vehicle_type.vehicle_type_id);
          }
        }
      
        this.displayedColumns.push("total_spaces");
        this.displayedColumns.push("non_entitled_permission")
        this.displayedColumns.push("actions");
        this.tenant_quotas_data = new MatTableDataSource(data.tenantQuotas);
        this.sortingCriteria(this.tenant_quotas_data);
        this.defineDataSourceFilter(this.tenant_quotas_data)

        this.parking_size_data = new MatTableDataSource(data.parkingSize);
      }
    })
  }

  compare(a: EpsTenantQuotas, b: EpsTenantQuotas) {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }


  sortingCriteria(tempDataSource: MatTableDataSource<EpsTenantQuotas>) {
    //Sorting case insensitive
    tempDataSource.sortingDataAccessor = (data: EpsTenantQuotas, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case 'total_spaces':
          return this.totalTenantSpaces(data);
        case 'tenant_name':
          return data.name.toLowerCase();
        default:
          if (typeof data[sortHeaderId] === 'string')
            return data[sortHeaderId].toLocaleLowerCase();
          else
            return data[sortHeaderId];
      }
    };
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<EpsTenantQuotas>) {
    tempDataSource.filterPredicate =
      (aGroup: EpsTenantQuotas, filter: string) => {
        const theFilter: MicroappEpsQuotastFilter = JSON.parse(filter);
        let generalFilter = false;

        if (!theFilter) return true;

        //tenant filter
        if (theFilter.general) {

          if (aGroup.name != null) {
            if (aGroup.name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          }
        } else generalFilter = true;

        return generalFilter;
      }
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.tenantNameFilter.general = filterValue;
    this.tenant_quotas_data.filter =  JSON.stringify(this.tenantNameFilter);
  }

  findTenantData(element: EpsTenantQuotas, spot_type_id: number, vehicle_type_id: number) {
    for (var quota of element.quota) {
      if (quota.spot_type_id == spot_type_id && quota.vehicle_type_id == vehicle_type_id) {
        return quota.quota;
      }
    }
    return "--";
  }


  totalTenantSpaces(element: EpsTenantQuotas) {
    let sum = 0
    for (var quota of element.quota) {
      sum = sum + quota.quota;
    }
    return sum;
  }



  delete_quotas(element: EpsTenantQuotas) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      tenant_id: element.tenant_id,
      tenant_name: element.name,
    };
    const dialogRef = this.dialog.open(MicroappEpsQuotasDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getInfo();
    });
  }

  edit_quotas(element: EpsTenantQuotas) {
    this.loading = true;
    this.epsServices.quotas_that_can_be_assigned_to(element.tenant_id).subscribe(parkingSizeWithAvailableSpots => {
      this.loading = false;
      this.openEditQuotasDialog(element, parkingSizeWithAvailableSpots);
    }, error => {
      const txt = this.translate.instant('MAPP-EPS.QUOTAS.LOAD_ERROR');
      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }

  private openEditQuotasDialog(element: EpsTenantQuotas, parkingSizeWithAvailableSpots: EpsParkingSize[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      quotas: element,
      parkingSize: parkingSizeWithAvailableSpots,
      parkingVehicleTypes: this.parkingVehicleTypes,
    };
    dialogConfig.width = '850px';
    dialogConfig.maxHeight= '900px';
    const dialogRef = this.dialog.open(MicroappEpsQuotasEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getInfo();
    });
  }

  getInfo() {
    this.store.dispatch(epsActions.epsGetParkinfData());
  }


  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }

  checkEnabledNonEntitledAssociation(tenantQuotas: EpsTenantQuotas){
     return tenantQuotas.quota.find(quota=> quota.spot_type_id== 2 && quota.vehicle_type_id ==1).association_enabled;
  }
}
