<h2 class="titleNew">{{'MAPP-FAULTREPORT.ISSUE.HEAD' | translate}}</h2>
<form [formGroup]="newIssueForm">
    <div>
        <mat-form-field appearance="none" class="readReporterMail">
            <div class="divReporterMail">
                <input class="inputReporterMail" matInput [readonly]="true" formControlName="email" name="email"
                    mt-autofocus>
            </div>
        </mat-form-field>

        <mat-form-field appearance="none" class="readDate">
            <mat-label class="labelDate">{{'MAPP-FAULTREPORT.EDIT.CREATED' | translate}}:</mat-label>
            <input class="inputDate" matInput [readonly]="true" formControlName="date" name="date" mt-autofocus>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="formFieldNew">
            <input required matInput [errorStateMatcher]="matcher" formControlName="summary"
                placeholder="{{'MAPP-FAULTREPORT.ISSUE.TITLE' | translate}}" name="summary"
                [ngClass]="{ 'is-invalid': f.summary.errors }" mt-autofocus>
            <mat-error *ngIf="newIssueForm.controls.summary.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="isAdmin || isSuperAdmin" class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.PRIORITY' | translate}}</mat-label>
            <mat-select required formControlName="priority">
                <mat-option *ngFor="let priority of listPriorities" [value]="priority.name">{{priority.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.type.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>

    </div>
    <div>
        <mat-form-field class="formFieldNew" *ngIf='!noTowers'>
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.TOWER' | translate}}</mat-label>
            <mat-select required formControlName="tower">
                <mat-option *ngFor="let tower of towers" [value]="tower">{{tower}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.tower.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="formFieldNew" *ngIf='noTowers'>
            <mat-label>Building</mat-label>
            <input class="inputReporterMail" matInput [readonly]="true" formControlName="tower" name="tower"
                    mt-autofocus>
        </mat-form-field>

        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.FLOOR' | translate}}</mat-label>
            <mat-select required formControlName="floor">
                <mat-option *ngFor="let floor of floors" [value]="floor">{{floor}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.floor.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.LOCATION' | translate}}</mat-label>
            <mat-select required formControlName="location">
                <mat-option *ngFor="let location of locations" [value]="location">{{location}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.location.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.ASSET' | translate}}</mat-label>
            <mat-select required formControlName="category">
                <mat-option *ngFor="let category of categorys" [value]="category">{{category}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.category.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.SUBCATEGORY' | translate}}</mat-label>
            <mat-select required formControlName="subcategory">
                <mat-option *ngFor="let subcategory of subcategorys" [value]="subcategory">{{subcategory}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.category.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin || isSuperAdmin" class="formFieldNew">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.TYPE' | translate}}</mat-label>
            <mat-select required formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type">{{type}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newIssueForm.controls.type.hasError('required')">
                {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <p></p>

    <mat-form-field class="formDescriptionNew">
        <textarea matInput [errorStateMatcher]="matcher" formControlName="description" name="description"
            placeholder="{{'MAPP-FAULTREPORT.ISSUE.DESCRIPTION' | translate}}" mt-autofocus></textarea>
    </mat-form-field>


    <div class="editCommentUpdate">
      <div>
          <div >
            <button class='buttonUpload' mat-stroked-button (click)="onClick()">
              <mat-icon class="icComment">file_upload</mat-icon>
              {{'MAPP-FAULTREPORT.ISSUE.NEW_ATTACHED' | translate}}
          </button>
          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
              accept="file_extension|image/*" style="display:none;" />
          </div>
          <p></p>
          <div name="files">

            <mat-list class="listFiles">
              <mat-list-item class="labelFileUpload" *ngFor="let file of files">
                <button mat-stroked-button>
                  {{file.data.name}}
                </button>
                <button class="buttonCancel" mat-stroked-button (click)="deleteFile(file.data.name)">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-list-item>
            </mat-list>



          </div>
      </div>
  </div>
</form>

<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close (click)="clean()">
        {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
    <button [disabled]="!newIssueForm.valid" class="buttonConfirm" mat-raised-button color="primary"
        (click)="onSubmit()">
        {{'MAPP-FAULTREPORT.EDIT.SUBMIT' | translate}}</button>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
