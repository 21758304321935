import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaQuestionDialog, AuthenticationService } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackService } from '../../../client/feedback.service';
import { ShowFeedbackComponent } from './show/microapp-quiz.show-feedback.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MicroappQuitFeedbackFilter } from './microapp-quiz.list-feedback.component.filter';
import { Feedback } from 'projects/microapp-quiz/src/client/dto/feedback';
import { MicroappQuizGenericComponent } from '../../survey/generic/microapp-quiz-generic.component';

@Component({
  selector: 'microapp-quiz-feedback-list',
  templateUrl: './microapp-quiz.list-feedback.component.html',
  styleUrls: ['./microapp-quiz.list-feedback.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListFeedbackComponent implements OnInit {

  displayedColumns: string[] = ['date', 'time', 'type', 'description', 'state', 'actions'];
  dataSource = new MatTableDataSource([]);
  userFilter = new MicroappQuitFeedbackFilter();
  filterValues = {};
  filterGroup: FormGroup;
  loading: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  

  constructor(private feedbackService: FeedbackService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private translate: TranslateService, 
    private formBuilder: FormBuilder,
    private auth: AuthenticationService) {
      //
      /*
      auth.getUser().then(user => {
      this.currentUser = user;
      this.showBtnNew = this.hasRol(['SUPERADMIN','ADMIN','MANAGEMENT']);
      this.viewUsers();
      });
      */
    
  }

  ngOnInit() {
    //we set the datasource of the table
    this.loadFeedback();
    this.filterGroup = this.buildFilter();
  }

  buildFilter() {

    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      STATE: new FormControl(""),
      TYPE: new FormControl(""),
    })
  }

  /**
   * @name loadFeedback
   * @description update survey from the database
   */
  loadFeedback() {
    this.loading = true;
    this.feedbackService.allFeedback().subscribe((data) => {
      this.loading = false;
      this.dataSource = new MatTableDataSource(data);
      this.defineDataSourceFilter(this.dataSource);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  } 

  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * @name viewFeedback
   * @description delete the current selected Feedback
   * @param {string} the Feedback id to view
   */
  viewFeedback(sid: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    let data = this.dataSource.data.find(d => d.sid === sid);
    dialogConfig.data = {
      sid: data.sid,
      user: data.user,
      date: data.date,
      state: data.state,
      anonymized: data.anonymized,
      type: data.type,
      feedback: data.feedback,
      readBy: data.readBy
    };
    
    const dialogRef = this.dialog.open(ShowFeedbackComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null){
          // console.log("data", data)
          // let index = this.dataSource.data.findIndex(d => d.sid === data.sid);
          // this.dataSource.data[index].type = data.type;
          this.dataSource.filter = "";
        }else{
          this.snackBar.open(this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.DIALOG.ERROR"),
            this.translate.instant("MAPP-QUIZ.SHOWFEEDBACK.DIALOG.TITLE"), {
            duration: 5000,
          });
        }
        this.loadFeedback();
      }
    );
    return;
  }

  /**
   * @name deleteFeedback
   * @description delete the current selected Feedback
   * @param {string} the Feedback id to delete
   */
  deleteFeedback(sid: string) {
    let dialogRef = this.dialog.open(MicroappQuizGenericComponent, {
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.feedbackService.deleteFeedback(sid).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].sid === sid) {
                const data = this.dataSource.data;
                data.splice(i, 1);
                this.dataSource.data = data;
              }
            }
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          },
          error => {
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          }
        );
      }
      this.loadFeedback();
    });
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Feedback, filter: string) => {
        const theFilter: MicroappQuitFeedbackFilter = JSON.parse(filter);
        let generalFilter = false;
        let statusFilter = false;
        // let dateFilter = false;
        // let timeFilter = false;
        let typeFilter = false;
        if (!theFilter) return true;

       // general Filter
        if (theFilter.general) {
          if (aGroup.feedback.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.date.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
         
        } else generalFilter = true;

        //status filter

        if (theFilter.state >=0) {
          // if (aGroup.state.toString().indexOf(theFilter.status) !== -1) {
          if (aGroup.state == theFilter.state) {
            statusFilter = true;
          }
        } else statusFilter = true;

        if (theFilter.type >=0) {
          // if (aGroup.state.toString().indexOf(theFilter.status) !== -1) {
          if (aGroup.type == theFilter.type) {
            typeFilter = true;
          }
        } else typeFilter = true;
        return generalFilter && typeFilter && statusFilter;
      }

  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStatusFilter(filterValue: number) {
    this.filterValues[filterValue] = filterValue;
    this.userFilter.state = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyTypeFilter(filterValue: number) {
    this.filterValues[filterValue] = filterValue;
    this.userFilter.type = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.type = undefined;
    this.userFilter.state = undefined;
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  chipsType = [
    { name: 'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.COMMENT', state: 0 },
    // { name: 'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.QUESTION', state: 1 },
    { name: 'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.PROBLEM', state: 2 },
    { name: 'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.IMPROVEMENT', state: 3 },
    { name: 'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.RELATED', state: 4 }
  ];

  chipsState = [
    { icon: "done" , state: 1 },
    { icon: "done_all" , state: 2 }
  ];
}
