import { createReducer, on } from '@ngrx/store';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import * as logBookActions from './microapp-vms-logbook.actions';
import { User } from '@empusa/empusa-core';
import { MicroappVmsSearchVisitFilter } from '../../model/microapp-vms-visit-filter.model';
import { MicroappVmsVisitGroup } from '../../model/microapp-vms-visit-group.model';
import { VmsMasterConfiguration } from '../../model/microapp-vms-config.model';

export interface LogBookState {
    loggedUser: User,
    loading: boolean;
    visits_loaded: boolean;
    visit_loaded: boolean;
    filter_visit: MicroappVmsSearchVisitFilter;
    visits: MicroappVmsVisit[];
    error: boolean;
    message_code: string;
    groupVisitForUpdate: MicroappVmsVisitGroup;
    visitUpdated: boolean;
    config: VmsMasterConfiguration;
}

const initialState = {
    loggedUser: null,
    loading: false,
    visits_loaded: false,
    visit_loaded: false,
    filter_visit: null,
    visits: null,
    error: false,
    message_code: null,
    groupVisitForUpdate: null,
    visitUpdated: false,
    config: null,
}

const _counterReducer = createReducer(
    initialState,

    on(logBookActions.startLogBook, (state, action) => ({
        ...state,
        loggedUser: action.loggedUser,
        filter_visit: null,
        visits: null,
        error: false,
        groupVisitForUpdate: null,
    })),
    on(logBookActions.getConfig, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(logBookActions.storeConfig, (state, action) => ({
        ...state,
        loading: false,
        config:action.config
    })),
    on(logBookActions.storeFilter, (state, action) => ({
        ...state,
        loading: false,
        filter_visit: action.filter,
    })),
    on(logBookActions.getVisits, (state, action) => ({
        ...state,
        filter_visit: action.filter,
        loading: true,
        visits_loaded: false,
        visits: null,
        error: false,
        groupVisitForUpdate: null,
    })),
    on(logBookActions.visitList, (state, action) => ({
        ...state,
        visits: action.visits,
        visits_loaded: true,
        loading: false,
        error: false,
    })),
    on(logBookActions.updateSingleVisit, (state, action) => ({
        ...state,
        loading: true,
        visitUpdated: false,
        error: false,
    })),
    on(logBookActions.updateGroupVisit, (state, action) => ({
        ...state,
        loading: true,
        visitUpdated: false,
        error: false,
    })),
    on(logBookActions.updateSingleVisitDone, (state, action) => ({
        ...state,
        loading: false,
        visitUpdated: true,
        error: false,
        message_code: action.message_code,
    })),
    on(logBookActions.updateSingleVisitNoChanges, (state, action) => ({
        ...state,
        loading: false,
        visitUpdated: true,
        error: false,
        message_code: action.message_code,
    })),
    on(logBookActions.updateGroupVisitDone, (state, action) => ({
        ...state,
        loading: false,
        visits_loaded: false,
        visitUpdated: true,
        error: false,
    })),

    on(logBookActions.deleteVisitGroup, (state, action) => ({
        ...state,
        loading: true,
        error: false,
    })),
    on(logBookActions.deleteSingelVisit, (state, action) => ({
        ...state,
        loading: true,
        error: false,
    })),
    on(logBookActions.visitDeleted, (state, action) => ({
        ...state,
        visits: action.visits,
        loading: false,
        error: false,
    })),

    on(logBookActions.error, (state, action) => ({
        ...state,
        loading: false,
        error: true
    })),
    on(logBookActions.resetError, (state, action) => ({
        ...state,
        error: false
    })),
    on(logBookActions.resetMessageCode, (state, action) => ({
        ...state,
        message_code: null
    })),
);

export function counterReducer(state, action) {
    return _counterReducer(state, action);
}