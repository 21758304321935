import { createFeatureSelector, createSelector } from '@ngrx/store';
import { manageGroupVisitState } from './microapp-vms-group-visit-manage.reducer';


export const selectGroupVisitManageState =
createFeatureSelector<manageGroupVisitState>("groupVisitManage");

export const newVisitloggedUser = createSelector(
  selectGroupVisitManageState,
  state => state.loggedUser
);

export const newVisitSelectedTenant = createSelector(
  selectGroupVisitManageState,
  state => state.tenant
);

export const showNewParticipant = createSelector(
  selectGroupVisitManageState,
  state => state.showNewParticipant
);

export const newVisitLoading = createSelector(
  selectGroupVisitManageState,
  state => state.loading
);

export const scheduleValid = createSelector(
  selectGroupVisitManageState,
  state => state.scheduleValid
);

export const scheduledVisit = createSelector(
  selectGroupVisitManageState,
  state => state.visit
);

export const newVisitError = createSelector(
  selectGroupVisitManageState,
  state => state.error
);

export const groupVisitsMessageCode = createSelector(
  selectGroupVisitManageState,
  state => state.message_code
);

export const updateDone  = createSelector(
  selectGroupVisitManageState,
  state => state.updateDone
)

export const newVisitDone  = createSelector(
  selectGroupVisitManageState,
  state => state.newVisitDone
)

export const thereAreParticipants = createSelector(
  selectGroupVisitManageState,
  state => {
    if (!state.visit || !state.visit.visitors || state.visit.visitors.length < 1)
      return false;
    return true;
  }
);

