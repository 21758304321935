import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Pipe, PipeTransform, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaQuestionDialog, AuthenticationService, User, Rol, Tenant } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../client/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MicroappUserManListFilter } from './microapp-usermanagement.list-user.component.filter';
import { AddUserDTO } from 'projects/microapp-usermanagement/src/client/dto/adduser';
import { MicroappusermanagementGenericComponent } from '../../generic/microapp-usermanagement-generic.component';
import { EditUserComponent } from '../edit/microapp-usermanagement.edit-user.component';



@Component({
  selector: 'microapp-usermanagement-user-list',
  templateUrl: './microapp-usermanagement.list-user.component.html',
  styleUrls: ['./microapp-usermanagement.list-user.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ListUserComponent implements OnInit {

  displayedColumns: string[] = ['mail', 'fullname', 'tenantN', 'rol', 'defaultHost', 'actions'];
  dataSource = new MatTableDataSource([]);
  currentUser: User;
  showBtnNew: boolean = false;
  filterGroup: FormGroup;
  filterValues = {};
  loading: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  public tenantForm: FormGroup;
  tenantsToSelect: Tenant[] = [];
  tenant: string;
  _tenantList: Tenant[];
  userFilter = new MicroappUserManListFilter;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() set tenantList(tenantList: Tenant[]) {
    this._tenantList = tenantList
    this.defineListTenants(tenantList)

  };

  constructor(private userService: UserService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private translate: TranslateService, private formBuilder: FormBuilder, private auth: AuthenticationService) {
    // auth.getUser().then(user => {
    //   this.currentUser = user;
    //   this.showBtnNew = true;
    //   this.viewUsers();
    // });
    this.loading = true;
    this.tenantForm = this.formBuilder.group({
      tenants: ['', Validators.required],
    });
    this.auth.loadCurrentUser().then(user => {
      this.loading = false;
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.currentUser = user;
      this.showBtnNew = true;
      // this.viewUsers();
    })
    // this.currentUser = auth.getCurrentUser();


  }

  defineListTenants(tenants: any) {
    if (this.isAdmin || this.isSuperAdmin) {
      this.tenant = "";
      let allTenants = new Tenant();
      allTenants.name = 'ALL TENANTS';
      allTenants.uid = 'ALL TENANTS';
      this.loading = true
      // this.userService.findAllTenants().then(resp => {
      tenants.forEach(element => {
        if (!element.is_site) {
          this.tenantsToSelect.push(element);
        }

        });
        this.tenantsToSelect.sort(this.compareTenants);
        this.tenantsToSelect.unshift(allTenants);
        this.loading = false
      // });
    }
  }

  compareTenants(a: Tenant, b: Tenant) {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }

  ngOnInit() {
    //we set the datasource of the table
    this.filterGroup = this.buildFilter();
  }

  get f(): any { return this.tenantForm.controls; }

  tenantSelected() {
    this.tenant = this.f.tenants.value;
    this.viewUsers();
  }


  /**
   * @name updateUsers
   * @description update users from the database
   */
  viewUsers() {
    if (this.isAdmin || this.isSuperAdmin) {
      if (this.currentUser.sites != null && this.currentUser.sites.length > 0) {
        this.loading = true;
        if (this.tenant && this.tenant != null && this.tenant != undefined) {
          if (this.tenant == 'ALL TENANTS') {
            this.userService.findUsersByBuilding(this.currentUser.sites[0].uid).subscribe((data) => {
              this.createTable(data);
            },
              error => {
                this.loading = false;
                this.snackBar.open(
                  this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.MESSAGE"),
                  this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.TITLE")
                  , {
                    duration: 5000,
                  });
              });
          } else {
            this.userService.findUsersByTenant(this.tenant).subscribe((data) => {
              this.createTable(data);
            },
              error => {
                this.loading = false;
                this.snackBar.open(
                  this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.MESSAGE"),
                  this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.TITLE")
                  , {
                    duration: 5000,
                  });
              });
          }
        } else {
          this.loading = false;
        }
      }
    }
  }

  createTable(data) {
    this.loading = false;
    let listTempData = [];

    let tempData = data.users;

    data.users.forEach(element => {
      let usert = new UserT()
      element.tenants.forEach(tenats => {
        if (!tenats.is_site) {
          if (element.roles != "" && element.roles != null) {
            element.roles.forEach(rol => {
              if (listTempData.findIndex(user => user.uid == element.uid) == -1) {
                if (rol.name == "USER" || rol.name == "MANAGEMENT") {
                  console.log("data", element)
                  usert.uid = element.uid;
                  usert.givenName = element.givenName;
                  usert.mail = element.mail.toLowerCase();
                  usert.roles = element.roles;
                  usert.surname = element.surname;
                  usert.tenantN = element.tenants[0].name;
                  usert.dn = element.dn;
                  usert.default_visitor_host = element.default_visitor_host;
                  listTempData.push(usert)
                }
              }
            });
          }
        }
      });
    });
    // let tempData = data.users;
    // let tempDataSource = new MatTableDataSource(tempData);
    let tempDataSource = new MatTableDataSource(listTempData);
    tempDataSource.sort = this.sort;
    tempDataSource.paginator = this.paginator;
    this.defineDataSourceFilter(tempDataSource);
    this.dataSource = tempDataSource;

  }


  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */

  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl(''),
      ROL: new FormControl(''),
      TENANT: new FormControl(''),
    })
  }


  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: UserT, filter: string) => {
        const theFilter: MicroappUserManListFilter = JSON.parse(filter);
        let generalFilter = false;
        let rolFilter = false;
        let tenantFilter = false;
        let hostFilter = false;
        if (!theFilter) return true;
        //general Filter
        if (theFilter.general) {
          if (aGroup.mail.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.givenName.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.surname.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;

        if (theFilter.rol) {
          aGroup.roles.forEach(element => {
            if (element.name.toLowerCase().indexOf(theFilter.rol) !== -1) {
              rolFilter = true;
            }
          });

        } else rolFilter = true;

        if (theFilter.host) {
          if (aGroup.default_visitor_host == JSON.parse(theFilter.host)) {
            hostFilter = true;
          }
        } else hostFilter = true;


        if (theFilter.tenant) {
          // aGroup.tenants.forEach(tenant => {
          if (aGroup.tenantN.toLowerCase().indexOf(theFilter.tenant) !== -1) {
            tenantFilter = true;
          }
          // })
        } else tenantFilter = true;


        return generalFilter && rolFilter && tenantFilter && hostFilter;
      }
    //rol filter


  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyRolFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.rol = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  applyHostFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.host = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  applyTenantFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.tenant = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  resetFilters() {
    this.userFilter.rol = undefined;
    this.userFilter.general = undefined;
    this.userFilter.tenant = undefined;
    this.userFilter.host = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }



  /**
   * @name deleteUser
   * @description delete the current selected user
   * @param {string} the user id to delete
   */
  deleteUser(uid: string) {
    let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.userService.deleteUser(uid).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].uid === uid) {
                const data = this.dataSource.data;
                data.splice(i, 1);
                this.dataSource.data = data;
              }
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          }
        );
      }
    });
  }

  createOrEdit(ticket_id: string) {
    let dialogAssign = this.dialog.open(EditUserComponent, {
      width: '680px',
      data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      if (result) {
        this.viewUsers();
        this.resetFilters();
      }
    });
  }

  sendNot(user_id: string) {
    let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
      data: {
        title: 'Resend Email',
        question: 'Are you sure of resend email to the user?',
        icon: 'email'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.resentEmail(user_id).subscribe(data => {

          this.snackBar.open(this.translate.instant("Email resent"),
            this.translate.instant("OK"), {
            duration: 5000,
          });
        },
          error => {
            if (error.status === 409) {
              this.snackBar.open(this.translate.instant("User not found"),
                this.translate.instant("ERROR"), {
                duration: 5000,
              });

            } else {
              this.snackBar.open(this.translate.instant("Error resending the email"),
                this.translate.instant("ERROR"), {
                duration: 5000,
              });
            }
          }
        );
      }
    });
  }

  chips = [
    { name: 'user', map: 'employee' },
    { name: 'management', map: 'tenant manager' },

  ];

  chipsDefaultHost = [
    { result: 'true', icon: 'done' }
  ];
}

export class UserT {
  uid: string;
  mail: string;
  givenName: string;
  surname: string;
  roles: Rol[];
  tenantN: string;
  dn: string;
  default_visitor_host: boolean;
}
