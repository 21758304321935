<div *ngIf="_facility" class="divAccesoryTotal">
  <div *ngIf="isAccesory">
    <div class="izquierdaAccesory">
      <div>
        <div *ngIf="!_facility.image_urls || _facility.image_urls.length < 1" class="accesoryImage"></div>
        <div *ngIf="_facility.image_urls && _facility.image_urls.length > 0" class="carouselContainer">
          <mat-carousel
            timings="250ms ease-in"
            [autoplay]="true"
            interval="5000"
            color="primary"
            maxWidth="auto"
            proportion="100"
            slides="5"
            [loop]="true"
            [hideArrows]="true"
            [hideIndicators]="false"
            [useKeyboard]="false"
            [useMouseWheel]="false"
            [maintainAspectRatio]="true"
            orientation="ltr">
            <mat-carousel-slide
              #matCarouselSlide
              *ngFor="let slide of _facility.image_urls; let i = index"
              [image]="fbsConfig.BOOKED_IMAGES_URL + slide"
              [hideOverlay]="true"
            ></mat-carousel-slide>
          </mat-carousel>
        </div>
        <div class="marginWithImage">

          <mat-label class="resourceName">{{_facility.name}} </mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-FACILITY-DATA.RESOURCES' | translate}} :
            </b>{{_facility.totalResources}}
          </mat-label>
        </div>
      </div>
    </div>

    <div class="derechaAccesory">
      <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>
      <div class="forMarginLeft">
        <mat-label class="accesoryReservationLabel"> {{_facility.description}}</mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel" *ngIf="_facility.tarif"><b>{{'MAPP-FBS-FACILITY-DATA.PRICE' |
            translate}}</b>
          {{_facility.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}
        </mat-label>
      </div>
    </div>
  </div>

  <div *ngIf="!isAccesory">
    <div class="facility-top-container">
      <div class="izquierdaAccesory">
        <div>
          <div *ngIf="!_facility.image_urls || _facility.image_urls.length < 1" class="facilityImage"></div>
          <div *ngIf="_facility.image_urls && _facility.image_urls.length > 0" class="carouselContainer">
            <mat-carousel
              timings="250ms ease-in"
              [autoplay]="true"
              interval="5000"
              color="primary"
              maxWidth="auto"
              proportion="100"
              slides="5"
              [loop]="true"
              [hideArrows]="true"
              [hideIndicators]="false"
              [useKeyboard]="false"
              [useMouseWheel]="false"
              [maintainAspectRatio]="true"
              orientation="ltr">
              <mat-carousel-slide
                #matCarouselSlide
                *ngFor="let slide of _facility.image_urls; let i = index"
                [image]="fbsConfig.BOOKED_IMAGES_URL + slide"
                [hideOverlay]="true"
              ></mat-carousel-slide>
            </mat-carousel>
          </div>

          <div class="marginWithImage">
            <mat-label class="resourceName">{{_facility.name}} </mat-label>
            <p></p>
            <mat-label class="accesoryReservationLabel">
              <b> {{'MAPP-FBS-FACILITY-DATA.LOCATION' | translate}}:</b> {{_facility.location}}
            </mat-label>
            <p></p>
            <mat-label class="accesoryReservationLabel">
              <b>{{'MAPP-FBS-FACILITY-DATA.PARTICIPANTS' | translate}}:</b> {{_facility.maxParticipants}}
            </mat-label>
          </div>
        </div>
      </div>

      <div class="derechaAccesory">
        <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>
        <div class="forMarginLeft">
          <mat-label class="accesoryReservationLabel"> {{_facility.description}}</mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel" *ngIf="_facility.tarif"> <b> {{'MAPP-FBS-FACILITY-DATA.PRICE' |
              translate}}:</b> {{_facility.tarif.unit_price | currency :coin:'symbol-narrow'}}</mat-label>
        </div>
      </div>
    </div>
    <div>
      <empusa-microapp-fbs-mini-resource-table
        *ngIf="_facility.resourcesIds &&_facility.type == fbsConfig.RESOURCE_TYPE_ATTR_GROUP "
        [resources]="filterResourcesByIds(_facility.resourcesIds)">
      </empusa-microapp-fbs-mini-resource-table>
    </div>

  </div>

</div>
