<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<h2 class="titleNew"> {{'MAPP-FITTINGOUT.COMMENT.COMMENTFILE' | translate}}<button class="closeEditVist" mat-icon-button
        mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
    </button></h2>

<div *ngIf="!displayLog">
    <form class="formFittingNewEdit" [formGroup]="newFittingForm">
        <div>
            <mat-form-field appearance="none" class="readReporterMail">
                <input class="inputReporterMail" matInput [readonly]="true" formControlName="reporter" name="reporter"
                    mt-autofocus>
            </mat-form-field>

            <mat-form-field appearance="none" class="readDate">
                <mat-label class="labelDate">{{'MAPP-FITTINGOUT.NEW.CREATED' | translate}}:</mat-label>
                <input class="inputDate" matInput [readonly]="true" formControlName="date" name="date" mt-autofocus>
            </mat-form-field>
        </div>
        <p></p>
        <div class="divEditNewSecondline">
            <mat-form-field [ngClass]="{'readonly':!allowChangeDescriptions, 'formFieldNew': allowChangeDescriptions}">
                <mat-label>{{'MAPP-FITTINGOUT.FITTING.TITLE' | translate}}</mat-label>
                <input matInput [readonly]="!allowChangeDescriptions" [errorStateMatcher]="matcher"
                    formControlName="summary" placeholder="{{'MAPP-FITTINGOUT.FITTING.TITLE' | translate}}"
                    name="summary" [ngClass]="{ 'is-invalid': f.summary.errors }" mt-autofocus>
                <mat-error *ngIf="newFittingForm.controls.summary.hasError('required')">
                    {{'MAPP-FITTINGOUT.REQUIRED' | translate}}</mat-error>
            </mat-form-field>
        </div>
        <p></p>

        <mat-form-field class="formDescriptionEdit">
            <textarea matInput [readonly]="true" [errorStateMatcher]="matcher" formControlName="description"
                name="description" placeholder="{{'MAPP-FITTINGOUT.FITTING.DESCRIPTION' | translate}}"
                mt-autofocus></textarea>
        </mat-form-field>


        <!-- <div class="filesButton">
            <div class="buttonLeft">
                <div>
                    <button class="buttonUpload" mat-stroked-button (click)="addComment()">
                        <mat-icon class="icComment"> comment</mat-icon>
                        {{'MAPP-FITTINGOUT.FITTING.ADDCOMMENTS' | translate}}
                    </button>
                </div>
                <p></p>

                <div name="files"> -->
                    <!--name="files" -->
                    <!-- <div>
                        <button class="buttonUpload" mat-stroked-button (click)="onClick()">
                            <mat-icon>file_upload</mat-icon>
                            {{'MAPP-FITTINGOUT.FILE.NEW_FILE' | translate}}
                        </button>
                        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                            accept="file_extension|image/*" style="display:none;" />
                    </div>
                </div>

            </div>
            <div class="logRight">
                <mat-list class="listFiles" role="list">
                    <mat-list-item (click)="downloadFile(fi.filename)" class="labelFileUpload"
                        *ngFor="let fi of filesName">
                        {{fi.filename}}
                        <button *ngIf="!isClosed" class="cancelFile" (click)="deleteFile(fi.filename)">
                            <mat-icon class="ic_cancel">cancel</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
        <p></p> -->

        <div class="editCommentUpdate">
            <div>
                <div >
                    <button *ngIf="!isClosed" class="buttonUpload" mat-stroked-button (click)="addComment()">
                        <mat-icon class="icComment"> comment</mat-icon>
                        {{'MAPP-FITTINGOUT.FITTING.ADDCOMMENTS' | translate}}
                    </button>
                    <button mat-stroked-button class="buttonUpload" (click)="displayVisitLog()" mat-button>
                        <mat-icon>subject</mat-icon>
                        <mat-label>{{'MAPP-FITTINGOUT.FITTING.COMMENTS' | translate}}</mat-label>
                    </button>
                    <button *ngIf="!isClosed" class='buttonUpload' mat-stroked-button (click)="onClick()">
                        <mat-icon class="icComment">file_upload</mat-icon>
                        {{'MAPP-FITTINGOUT.FILE.NEW_FILE' | translate}}
                    </button>
                    <input *ngIf="!isClosed" type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                        accept="file_extension|image/*" style="display:none;" />
                </div>
                <p></p>
    
                <!-- <div name="files"> -->
                <!--name="files" -->
                <!-- <mat-list class="listFiles" role="list">
                    <mat-list-item (click)="allowDownloadFiles?downloadFile(fi.filename):false" class="labelFileUpload"
                        *ngFor="let fi of filesName">
                        {{fi.filename}}
                    </mat-list-item>
                </mat-list>
            </div> -->
    
                <div name="files">
    
                    <mat-list class="listFiles">
                        <mat-list-item class="labelFileUpload" *ngFor="let fi of filesName">
                            <button mat-stroked-button (click)="downloadFile(fi.filename)">
                                {{fi.filename}}<mat-icon>download</mat-icon>
                            </button>
                            <button *ngIf="!isClosed" class="buttonCancel" mat-stroked-button (click)="deleteFile(fi.filename)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
    
    
    
                </div>
    
                <p></p>
    
                <!-- <div>
                <h3 class="titleLog">
                    <mat-label>{{'MAPP-FITTINGOUT.FITTING.COMMENTS' | translate}}</mat-label>
                </h3>
                <mat-list class="listLog" role="list">
                    <mat-list-item class="itemLog" *ngFor="let item of listLogIssue">
                        <p *ngIf="item.new_value!=''">{{item.date}} - {{item.owner}} - {{item.new_value}}</p>
                    </mat-list-item>
                </mat-list>
            </div> -->
            </div>
        </div>

    </form>
    <form [formGroup]="resolutionForm">
        <div class="checkResolve" *ngIf="isDraft===false && isValidateRejected === false && isAllowResolve === true">
            <mat-form-field class="textResolveDiv">
                <mat-label>{{'MAPP-FITTINGOUT.FITTING.RESOLUTION' | translate}}*</mat-label>
                <textarea formControlName="resolution" [(ngModel)]="textResolution"
                    placeholder="{{'MAPP-FITTINGOUT.FITTING.RESOLVE' | translate}}" matInput></textarea>
            </mat-form-field>
        </div>
    </form>
    <div *ngIf="isDraft===false && isValidateRejected === false && isAllowResolve === true" mat-dialog-actions
        class="buttonFitting" align="end">
        <button [disabled]="!resolutionForm.valid" class="buttonClose" mat-raised-button mat-dialog-close
            (click)="changeResolution('rejected')">
            {{'MAPP-FITTINGOUT.COMMENT.REJECTED' | translate}}</button>
        <button [disabled]="!resolutionForm.valid" class="buttonConfirm" mat-raised-button color="primary"
            (click)="changeResolution('approved')">
            {{'MAPP-FITTINGOUT.COMMENT.APPROVED' | translate}}</button>
    </div>
    <div *ngIf="isDraft===true" mat-dialog-actions class="buttonFitting" align="end">
        <button class="buttonConfirm" mat-raised-button color="primary" (click)="validateResolution()">
            {{'MAPP-FITTINGOUT.COMMENT.VALIDATEDRAFT' | translate}}</button>
    </div>

    <div *ngIf="isDraft===false && isValidateRejected === true" mat-dialog-actions class="buttonFitting" align="end">
        <button class="buttonConfirm" mat-raised-button color="primary" (click)="validateResolution()">
            {{'MAPP-FITTINGOUT.COMMENT.VALIDATE' | translate}}</button>
    </div>
</div>
<div *ngIf="displayLog">
    <div>
        <h3 class="titleLog">
            <mat-label>{{'MAPP-FITTINGOUT.FITTING.COMMENTS' | translate}}</mat-label>
        </h3>
        <mat-list class="listLog" role="list">
            <mat-list-item class="itemLog" *ngFor="let item of listLogIssue">
                <p *ngIf="item.new_value!=''">{{item.date}} - {{item.owner}}:  {{item.new_value}}</p>
            </mat-list-item>
        </mat-list>
        <button class="buttonBack" (click)="closeVisitLog()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            <mat-label>BACK</mat-label>
        </button>
    
        <!-- </div> -->
    </div>
</div>