

<!-- ******************DIALOG ADD COMMENT************************* -->

<h3 mat-dialog-title class="tittleAddComment">{{'MAPP-FAULTREPORT.EDIT.ADD_COMMENT.TITLE' | translate}}</h3>

<div mat-dialog-content>
   <mat-label class="labelAssign">{{'MAPP-FAULTREPORT.EDIT.ADD_COMMENT.BODY' | translate}}</mat-label>
   <p></p>
   <mat-form-field class="textCommentary">
       <mat-label>{{'MAPP-FAULTREPORT.EDIT.COMMENTARY' | translate}}*</mat-label>
       <textarea class="areaComentary" placeholder="{{'MAPP-FAULTREPORT.EDIT.INTRODUCE' | translate}}" matInput [(ngModel)]="comment" name="comment"></textarea>
   </mat-form-field>
</div>

<div mat-dialog-actions class="buttonDetailFault" align="end">
   <button class="buttonCloseComment" mat-button color="primary" mat-dialog-close>
       {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
   <button class="buttonConfirm" mat-stroked-button color="primary" [disabled]="comment===''"
       [mat-dialog-close]="comment">{{'MAPP-FAULTREPORT.ADD' | translate}}</button>
</div> 