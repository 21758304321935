<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div>
    <h2 class="titleNew" *ngIf="!isEdit">{{'MAPP-FITTINGOUT.NEW.HEAD' | translate}}<button class="closeEditVist"
            mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button></h2>
</div>
<form class="" [formGroup]="newFittingForm">
    <div>
        <mat-form-field appearance="none" class="readReporterMail">
            <input class="inputReporterMail" matInput [readonly]="true" formControlName="reporter" name="reporter"
                mt-autofocus>
       </mat-form-field>

        <mat-form-field appearance="none" class="readDate">
            <mat-label class="labelDate">{{'MAPP-FITTINGOUT.NEW.CREATED' | translate}}:</mat-label>
            <input class="inputDate" matInput [readonly]="true" formControlName="date" name="date" mt-autofocus>
        </mat-form-field>
    </div>
    <p></p>
    <div class="divEditNewSecondline">
        <mat-form-field [ngClass]="{'readonly':!allowChangeDescriptions, 'formFieldNew': allowChangeDescriptions}">
            <mat-label>{{'MAPP-FITTINGOUT.FITTING.TITLE' | translate}}</mat-label>
            <input matInput [readonly]="!allowChangeDescriptions" [errorStateMatcher]="matcher" required
                formControlName="summary" placeholder="{{'MAPP-FITTINGOUT.FITTING.TITLE' | translate}}" name="summary"
                [ngClass]="{ 'is-invalid': f.summary.errors }" mt-autofocus>
            <mat-error *ngIf="newFittingForm.controls.summary.hasError('required')">
                {{'MAPP-FITTINGOUT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <p></p>

    <mat-form-field
        [ngClass]="{'formDescriptionEdit':!allowChangeDescriptions, 'formDescriptionNew': allowChangeDescriptions}">
        <textarea matInput [readonly]="!allowChangeDescriptions" [errorStateMatcher]="matcher"
            formControlName="description" name="description" required
            placeholder="{{'MAPP-FITTINGOUT.FITTING.DESCRIPTION' | translate}}" mt-autofocus></textarea>
    </mat-form-field>


    <div name="files">
        <!--name="files" -->
        <div>
            <button *ngIf="isAllowUpdate" class="buttonUpload" mat-stroked-button (click)="onClick()">
                <mat-icon>file_upload</mat-icon>
                {{'MAPP-FITTINGOUT.FILE.NEW_FILE' | translate}}
            </button>
            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                accept="file_extension|image/*" style="display:none;" />
        </div>
    </div>
    <div name="files">
        <mat-list class="listFiles">
            <mat-list-item class="labelFileUpload" *ngFor="let fi of filesName">
                <button mat-stroked-button>
                    {{fi.filename}}
                </button>
                <button class="buttonCancel" mat-stroked-button (click)="deleteFile(fi.filename)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </div>

</form>
<p></p>
<div mat-dialog-actions class="buttonFitting" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-FITTINGOUT.ACTIONS.CANCEL' | translate}}</button>
    <button [disabled]="!newFittingForm.valid" class="buttonConfirm" mat-raised-button color="primary"
        (click)="onSubmit()">
        {{'MAPP-FITTINGOUT.ACTIONS.SUBMIT' | translate}}</button>
</div>