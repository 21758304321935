export const locale = {
    "lang": "en",
    "data": {
        "MAPP-USERMANAGEMENT": { //mandatory - microapp context
            "HOME": {
                "TAB.USERS": "Tenants Users",
                "TAB.USERTENANT": "Tenant Users",
                "TAB.TENANTS": "Tenants Companies",
                "TAB.BUILDING": "Building Operators",
                "HEAD": "User Management",
                "TAB.TARIFF-ASSIGNMENT": "Tariffs Assignment"
            },
            "GENERIC.CLEAN": "Clean Filter",
            "TARIFF-ASSIGNMENT": {
                "USER-CREATION": "User Creation",
                "SEARCH": "Search...",
                "TENANTS": "Tenants",
                "ACTIONS": "Actions"

            },
            "TOOLTIP": {
                "TENANT.EDIT": "Edit Tenant",
                "TENANT.DELETE": "Delete Tenant",
                "USER.EDIT": "Edit User",
                "USER.DELETE": "Delete User",
            },
            "FILTER":{
                "FILTER":"Filter",
                "CLEAR": "Clear Filters"
            },
            "USERS": {
                "HEAD": "User Management",
                "LIST.TABLE.USERNAME": "Username",
                "LIST.TABLE.FULLNAME": "Full Name",
                "LIST.TABLE.EMAIL": "Email",
                "LIST.TABLE.ROL": "Role",
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LISTS.ACTION.NEW": "New User"
            },
            "EDITUSER": {
                "TITLE": "Edit User",
                "FIELD.PHOTO": "Photo",
                "FIELD.DESCRIPTION": "Description",
                "FIELD.PASSWORD": "Password",
                "FIELD.EMAIL": "Email",
                "FIELD.FULLNAME": "Fullname",
                "FIELD.FIRSTNAME": "First name",
                "FIELD.LASTNAME": "Last name",
                "FIELD.USERNAME": "Username",
                "FIELD.ROL": "Role",
                "FIELD.SITE": "Building",
                "FIELD.ACCESS": "Access Rights",
                "FIELD.TENANT": "Tenant",
                "FIELD.ACCESS.GROUP": "Access Group",
                "FIELD.ACCESS.RIGHTS.LIFT":"Access Lifts Rights",
                "FIELD.FLOOR.HOME":"Home Floor",
                "FIELD.FLOOR": "Lift Rights",
                "FIELD.NOCHARGE": "Do not apply charges",
                "ACTION.SAVE": "Save",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field",
                "INVALID": "The field has an invalid value",
                "OK.MESSAGE": "The record was saved correctly",
                "OK.TITLE": "Operation Successful",
                "ERROR.MESSAGE": "Operation Failed!",
                "ERROR.TITLE": "An error ocurred while trying to save the new record!",
                "ERROR.NO-TARIFF.TITLE": "This tenant has no assigned a User Creation Tariff.",
                "FIELD.HOST":"Default Host"
            },
            "NEWUSER": {
                "TITLE": "New User",
                "SUBTITLE": "Complete the form to create a new user",
            },
            "NEWUSERBUILDING":{
                "SUBTITLE": "Complete the form to create a new user associated to building"
            },
            "TENANTS": {
                "HEAD": "Tenant Management",
                "SUBTITLE": "Complete the form to create a new Tenant",
                "LISTS.ACTION.NEW": "New Tenant",
                "LIST.TABLE.NAME": "Name",
                "LIST.TABLE.DESCRIPTION":"Unit Info",
                "LIST.TABLE.FLOOR":"Lift Rights",
                "LIST.TABLE.TOWER":"Tower",
                "LIST.TABLE.BILLINGTENANTID": "BillingTenantID",
                "LIST.TABLES.USER":"Employee",
                "LIST.TABLES.MANAGEMENT":"Tenant Manager",
                "LIST.TABLES.ADMIN": "Facility Manager",
                "LIST.TABLES.TOTAL": "Total Users",

            },
            "SITES": {
                "HEAD": "Building Management",
                "LIST.TABLE.DESCRIPTION": "Description",
                "LIST.TABLE.NAME": "Name",
                "LIST.TABLE.BILLINGPROPERTYID": "BillingPropertyID",
                "LIST.TABLE.BILLINGTENANTID": "BillingTenantID",
                "LIST.TABLE.ACTIONS": "Actions",
                "LIST.TABLE.ACTION.EDIT": "Edit",
                "LIST.TABLE.ACTION.DELETE": "Delete",
                "LISTS.ACTION.NEW": "New"
            },
            "EDITSITE": {
                "TITLE": "Edit Building",
                "FIELD.NAME": "Name",
                "FIELD.DESCRIPTION": "Description",
                "FIELD.BILLINGPROPERTYID": "BillingPropertyID",
                "ACTION.SAVE": "Save",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field",
                "INVALID": "The field has an invalid value",
                "OK.MESSAGE": "The record was saved correctly",
                "OK.TITLE": "Operation Successful",
                "ERROR.MESSAGE": "Operation Failed!",
                "ERROR.TITLE": "An error ocurred while trying to save the new record!"
            },
            "NEWSITE": {
                "TITLE": "New Building",
                "SUBTITLE": "Complete the form to create a new building"
            },
            "EDITTENANT": {
                "TITLE": "Edit Tenant",
                "FIELD.NAME": "Name",
                "FIELD.DESCRIPTION": "Unit Info",
                "FIELD.BILLINGTENANTID": "Billing Tenant ID",
                "FIELD.FLOOR": "Lift Rights",
                "FIELD.TOWER": "Tower",
                "FIELD.ACCESS.GROUP": "Access Group",
                "FIELD.FLOOR.HOME":"Home Floor",
                "FIELD.ACCESS.RIGHTS.LIFT":"Access Lifts Rights",
                "FIELD.ACCESS": "Access Rights",
                "ACTION.SAVE": "Save",
                "ACTION.CANCEL": "Cancel",
                "REQUIRED": "This is a required field",
                "INVALID": "The field has an invalid value",
                "OK.MESSAGE": "The record was saved correctly",
                "OK.TITLE": "Operation Successful",
                "ERROR.MESSAGE": "Operation Failed!",
                "ERROR.TITLE": "An error ocurred while trying to save the new record!",
            },
            "NEWTENANT": {
                "TITLE": "New Tenant",
                "SUBTITLE": "Complete the form to create a new Tenant"
            },
            "FAILLOAD":{
                "TITLE":'Operation Failed!',
                "MESSAGE":'An error ocurred while trying to load data'
            },
            "ERROR":{
                "CANT-GET-FLOOR":"Failed to get Floor Info",
                "CANT-GET-ACCESS-RIGHTS":"Failed to get Access Rights Info",
                "CANT-SET.STAFF-EACS":"Failed to set Staff Eacs Info",
                "CANT-GENERATE-CHARGE":"The charge could not be generated",
                "USER-ALREADY-EXISTS": "This email is already registered in the system",
                "GROUPS-NOT-EXISTS":"The tenant does not exist",
                "ROLES-NOT-EXISTS":"The roles does not exist",
                "INVALID-PERMISSIONS":"The user does not have permissions to perform the operation",
                "LDAP-ERROR":"Errors occur performing LDAP operations",
                "NO-TARIFFS":"There are no tariffs for user creation in this tenant",
                "DUPLICATED-USER":"The user already exists in LDAP",
                "UNKNOWN":"Unknown error",
            }
        }
    }
}
