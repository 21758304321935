
    import { Injectable, Inject } from '@angular/core';
    import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
    import { map } from 'rxjs/operators';

    

    @Injectable({
      providedIn: 'root',
    })
    export class InternalService {
         private REST_TRAC_SERVER: string = this.environment.urlbase + 'faultreport/services/tickets';
     //private REST_TRAC_SERVER:string = 'http://trac.local:8000/faultreport/services/tickets';
     
     constructor(private http: HttpClient, @Inject('environment') private environment
     ) {
     }
      currentDate(){
        let dateNow:string= new Date().getTime().toString();
        return dateNow;
      }
      getUrl(path:string){
        return this.REST_TRAC_SERVER +  path ;
      }

      
  
      getOwner(data){
        let httpOptions = {
          headers: new HttpHeaders({
            'Accept':'text/plain'
          }),
          'responseType': 'text' as 'json',
          params:new HttpParams().set('id',this.currentDate())
        };
        let path:string = '/owner';
        return this.http.get(this.getUrl(path), httpOptions).pipe(
          map(data=>data)
        );  
      }
    }
