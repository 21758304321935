import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchDates} from "../../../../../client/microapp-fbs.interfaces";
import {MicroappFbsResourceModel} from "../../../../../client/dto/microapp-fbs-resource";
import {MicroappFbsResourceSlotModel} from "../../../../../client/dto/faciliti-slots";
import {
  RangeSliderComponent,
  SliderSelectionModel,
  SliderValueModel
} from "../../generics/range-slider/range-slider.component";
import {cloneDeep, last} from "lodash";
import * as moment from "moment";
import {MicroappFbsAccessoryGroupSlotsModel} from "../../../../../client/dto/Microapp-fbs-accessory-group-slots";
import {MicroappFbsResourceGroup} from "../../../../../client/dto/microapp-fbs-resource-group";
import {debug} from "ng-packagr/lib/utils/log";

@Component({
  selector: 'empusa-microapp-fbs-slider-accessory-slots',
  templateUrl: './microapp-fbs-slider-accessory-slots.component.html',
  styleUrls: ['./microapp-fbs-slider-accessory-slots.component.css']
})
export class MicroappFbsSliderAccessorySlotsComponent implements OnInit {
  readonly AVAILABLE_CSS_STYLE = 'background-color: #28a745;'
  readonly UNAVAILABLE_CSS_STYLE = 'background-color: #dc3545;'
  readonly AVAILABLE_COLOR = 'rgba(183, 251, 187, 1)';
  readonly UNAVAILABLE_COLOR = 'rgba(251, 183, 183, 1)';

  @Output() onSelectedPeriod: EventEmitter<SearchDates> = new EventEmitter();
  @Input() initialSliderValues: SliderSelectionModel;
  @Input()
  set accessory(accessory: MicroappFbsResourceGroup) {
    this.updateSlotsToShow(accessory);
  }

  @ViewChild('sliderComponent') sliderComponent: RangeSliderComponent;

  slotsToShow: MicroappFbsAccessoryGroupSlotsModel[] = [];
  parsedToSliderValues: SliderValueModel[];
  cssStylesForSlider;

  constructor() { }

  updateSlotsToShow(group: MicroappFbsResourceGroup){
    if (!group.combined_slots) {
      return;
    }
    this.slotsToShow = [];
    let lastSlot: MicroappFbsAccessoryGroupSlotsModel;
    const orderedSlots = cloneDeep(group.combined_slots).sort((slot1, slot2) => {
      return slot1.startDateTime.getTime() - slot2.startDateTime.getTime();
    });
    orderedSlots.forEach(slot => {
      if (slot && slot.isReservable){
        this.slotsToShow.push(slot);
      }
      if (slot) {
        lastSlot = slot;
      }
    });
    //Adding last slot to allow select last hour
    const lastSlotClone = cloneDeep(lastSlot);
    lastSlotClone.isReservable = last(this.slotsToShow).isReservable;
    lastSlotClone.slots = last(this.slotsToShow).slots;
    this.slotsToShow.push(lastSlotClone);

    this.parsedToSliderValues = this.slotsToSliderValueModels(this.slotsToShow);
  }

  slotsToSliderValueModels(slots: MicroappFbsAccessoryGroupSlotsModel[]){
    return slots.map((slot): SliderValueModel => {
      return  {
        value: slot.startDateTime,
        label: moment(slot.startDateTime).format('HH:mm'),
        color: slot.slots.length > 0 ? this.AVAILABLE_COLOR : this.UNAVAILABLE_COLOR,
        tooltipLabel: moment(slot.startDateTime).format('HH:mm')
      };

    });

  }

  onSliderSelection(selection: SliderSelectionModel){
    const slotsInSelectedInterval = this.slotsToShow.filter((slot) => {
      return selection.start <= slot.startDateTime && selection.end >= slot.endDateTime;
    });
    let isIntervalReservable = slotsInSelectedInterval.every((slot) => {
      return slot.slots.length > 0;
    });
    isIntervalReservable = isIntervalReservable && selection.start.getMinutes() == selection.end.getMinutes();
    this.cssStylesForSlider = isIntervalReservable ? this.AVAILABLE_CSS_STYLE : this.UNAVAILABLE_CSS_STYLE;

    this.onSelectedPeriod.emit({
      searchStartDate: selection.start,
      searchEndDate: selection.end
    });
  }

  ngOnInit(): void {
  }
}
