import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappVmsVisit } from '../../../../lib/model/microapp-vms-visit.model';
import { MicroappVmsVisitLog } from '../../../../lib/model/microapp-vms-visit-log.model';
import { VmsConfig } from '../../../../lib/microapp-vms-config';

@Component({
  selector: 'empusa-microapp-vms-visit-log',
  templateUrl: './microapp-vms-visit-log.component.html',
  styleUrls: ['./microapp-vms-visit-log.component.css']
})

export class MicroappVmsVisitLogComponent implements OnInit {
  visit: MicroappVmsVisit;

  displayedColumns: string[] = ['date', 'changes', 'extraData'];
  dataSource = new MatTableDataSource<MicroappVmsVisitLog>();
  offset: string;
  readonly localDateFormat = "dd/MM/yyyy";

  constructor(
    private translate: TranslateService,
    private vmsConfig: VmsConfig,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visit = data;
  }

  ngOnInit(): void {
      this.dataSource.data = this.visit.log;
      this.offset =  this.vmsConfig.MASTER_CONFIG.offset;
  }

}





