import { DTOTariffPass } from './dto/parking-passes.dto';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService, Site, Tenant } from '@empusa/empusa-core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChargesService {

  serviceURL = this.environment.urlbase + 'smartbilling/services/charges';

  public static get USR_MNGT_NEW_USER(): string { return 'USR_MNGT_NEW_USER'; }

  public static get USR_MNGT(): string { return 'USR_MNGT'; }

  constructor(private http: HttpClient, private auth: AuthenticationService, @Inject('environment') private environment) {

  }

  /**
   * @CHECKED
   * Get the passes info for a certain tenant
   */
  getDataPasesForTenant(tenant: Tenant): Observable<[DTOTariffPass]> {
    let id = encodeURIComponent(tenant.uid);
    const body = JSON.stringify({
      tags: [ChargesService.USR_MNGT]
    });
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    let url = `${this.serviceURL}/tenant/${id}/tarifs`;
    return this.http.post(url, body, { headers }).pipe(
      map(resp => (<any>resp).charge_codes_mapping)
    );
  }

  getAllDataPasesForTenant(): Observable<[DTOTariffPass]> {
    const body = JSON.stringify({
      tags: [ChargesService.USR_MNGT]
    });
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    let url = `${this.serviceURL}/tenants/tarifs`;
    return this.http.post(url, body, { headers }).pipe(
      map(resp => (<any>resp).charge_codes_mapping)
    );
  }

  /**
   * @CHECKED
   * Update the parking pass passes to the function for the indicated tenant
   * @param tenant  the tenant to update
   * @param passes  the list of passes to be updated
   */
  updatePasses(tenant: Tenant, passes: DTOTariffPass[]) {
    let charges = [];
    passes.forEach(pass => {
      if (pass.tarif && pass.tarif.tarif_id) {
        charges.push({
          chargeid: pass.id,
          tarifid: pass.tarif.tarif_id
        })
      }
    });

    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    let id = encodeURIComponent(tenant.uid);
    let url = `${this.serviceURL}/tenant/${id}/tarifs`;
    return this.http.put(url, { charges: charges }, { headers }).pipe(map(resp => resp));
  }


}
