
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthenticationService, Site, Tenant, User } from '@empusa/empusa-core';
import { UserService } from '../../../lib/services/user.service';
import { SiteService } from '../../../lib/services/site.service';

@Component({
  selector: 'empusa-microapp-eps-tenant-select',
  templateUrl: './microapp-eps-tenant-select.component.html',
  styleUrls: ['./microapp-eps-tenant-select.component.css']
})
export class MicroappEpsTenantSelectComponent implements OnInit {

  @Output() selectedTenant: EventEmitter<Tenant> = new EventEmitter();

  theSite: Site;
  public tenantForm: FormGroup;
  // tenants = new FormControl();
  tenantsToSelect: Tenant[] = [];
  currentUser: User;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,

    private authservice: AuthenticationService, private siteService: SiteService,) {

    this.tenantForm = this.formBuilder.group({
      tenants: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.theSite = this.authservice.getCurrentSite();
    this.authservice.loadCurrentUser().then(user => {
      this.currentUser = user;
      let theTenants: Tenant[] = [];

      const isAdmin = this.authservice.hasCurrentUserRole('ADMIN');
      if (isAdmin) {
        this.userService.findAllTenants().then(resp => {
          resp.forEach(element => {
            theTenants.push(element);
          });
          this.tenantsToSelect = theTenants.sort(this.compareTenants);
        });
      } else this.siteService.getSite(this.currentUser.sites[0].uid).subscribe(resp => {
        resp.tenants.forEach(element => {
          if (!element.is_site) {
            theTenants.push({ ...element });
          }
        });
        this.tenantsToSelect = theTenants.sort(this.compareTenants);
      });
    })
  }
  get f(): any { return this.tenantForm.controls; }

  tenantSelected() {
    const theTenant: Tenant = this.f.tenants.value;
    this.selectedTenant.emit(theTenant);
  }

  compareTenants(a: Tenant, b: Tenant) {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }

}

// ** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
