import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FbsConfig } from './microapp-facilitiesbooking-config';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@empusa/empusa-core';
import { FbsScheduleAdapter } from '../client/dto/microapp-fbs-schedule.adapter';
import { MicroappFbsSchedule } from '../client/dto/microapp-fbs-schedule';

@Injectable({
  providedIn: 'root',
})
export class MicroappFbsScheduleService {

  constructor(private http: HttpClient,
    private authservice: AuthenticationService,
    private fbsScheduleAdapter: FbsScheduleAdapter,
    private fbsConfig: FbsConfig) {
  }

  // NOTE: Booked do not give the schedule in a specified date
  getDefaultSchedule(): Observable<MicroappFbsSchedule> {
    return this.http.get<MicroappFbsSchedule>(this.fbsConfig.URL_REST_BASE + 'schedule').
    pipe(map((data) => {
      return this.fbsScheduleAdapter.adapt(data);      
    }));
  }


}
