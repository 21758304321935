import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService, Tenant } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DTOTariffPass } from './dto/parking-passes.dto';

@Injectable({
    providedIn: 'root',
})
export class ChargesService {
    context: string = "http://bousteadintegration.ddns.net:18080/";
    smartparking: string = "smartparking/services/";
    smartbilling: string = "smartbilling/services"

    //These are the different codes for the [complimentary|daily|monthly]-[user|tenant]-[car|moto] combinations
    public static get CODE_FAN(): string { return "FAN_EXT"; };
    public static get CODE_HVAC(): string { return "HVAC_EXT"; };

    constructor(private http: HttpClient, private auth: AuthenticationService,
        private translate: TranslateService, @Inject('environment') private environment) {
        this.context = this.environment.urlbase;
    }

    /**
     * @CHECKED
     * Get the passes info for a certain tenant
     */
    getDataPasesForTenant(tenant: Tenant): Observable<DTOTariffPass[]> {
        let body = JSON.stringify({
            "tags": [
                "HVAC"
            ]
        });
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        let id = encodeURIComponent(tenant.uid);
        let url = `${this.environment.urlbase}smartbilling/services/charges/tenant/${id}/tarifs`;
        return this.http.post(url, body, { headers }).pipe(
            map(resp => (<any>resp).charge_codes_mapping)
        );
    }

    /**
     * @CHECKED
     * Update the parking pass passes to the function for the indicated tenant
     * @param tenant  the tenant to update
     * @param passes  the list of passes to be updated
     */
    updatePasses(tenant: Tenant, passes: DTOTariffPass[]) {
        let charges = [];
        passes.forEach(pass => {
            if (pass.tarif && pass.tarif.tarif_id) {
                charges.push({
                    'chargeid': pass.id,
                    'tarifid': pass.tarif.tarif_id
                })
            }
        });

        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        let id = encodeURIComponent(tenant.uid);
        let url = `${this.environment.urlbase}smartbilling/services/charges/tenant/${id}/tarifs`;
        return this.http.put(url, { charges: charges }, { headers }).pipe(map(resp => resp));
    }
}