import { Time } from '@angular/common';
import { MicroappVmsVisitVisitor } from './microapp-vms-manage-group-visit-visitor.model';

export class MicroappVmsVisitWithVisitors {
    id: number;
    visitor_id: number;
	tenant_id: string;
	site_id: string;
	host_id : string;
	date: Date;
	start_time: Date;
	end_time: Date;
	check_in_time: Time;
	check_out_time: Time;
	about: string;
	group_id: string;
	visitors: MicroappVmsVisitVisitor[];
}