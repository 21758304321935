import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import * as logBookActions from '../../../../lib/logbook/store/microapp-vms-logbook.actions'
import { selectLogBookLoading, selectLogBookError, selectLogBookOk } from '../../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'empusa-microapp-vms-delete-single-visit',
  templateUrl: './microapp-vms-delete-single-visit.component.html',
  styleUrls: ['./microapp-vms-delete-single-visit.component.css']
})
export class MicroappVmsDeleteSingleVisitComponent implements OnInit, OnDestroy {

  loading: boolean;
  loading$: Subscription;
  visit_id: string;
  errorSubs$: Subscription;

  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visit_id = data;
  }


  ngOnInit(): void {
  }

  deleteVisit() {
    this.store.dispatch(logBookActions.deleteSingelVisit({ visit_id: this.visit_id}));

    this.loading$ = this.store.select(selectLogBookLoading).subscribe(loading => {
      this.loading = loading;
    });

    let saving$ = this.store.pipe(select(selectLogBookLoading)).subscribe(isLoading => {
      if (!isLoading) {
        this.errorSubs$ = this.store.pipe(select(selectLogBookError)).subscribe(error => {
          this.dialogRef.close(!error);
        });
        saving$.unsubscribe();
      }
    });

  }

  close(){
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    if (this.loading$)
      this.loading$.unsubscribe();
    if (this.errorSubs$)
      this.errorSubs$.unsubscribe();
  }

}
