import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectScheduled} from '../../../../lib/store/microapp-fbs-selectors';
import {take} from 'rxjs/operators';
import * as microappFbsState from '../../../../lib/store/microapp-fbs-app-reducer';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import {MicroappFbsPeriod, MicroappFbsSchedule} from '../../../../client/dto/microapp-fbs-schedule';
import {Subscription} from 'rxjs';
import {DayHours, SearchDates} from '../../../../client/microapp-fbs.interfaces';
import {FbsConfig} from '../../../../lib/microapp-facilitiesbooking-config';
import * as moment from 'moment';
import {ErrorStateMatcher} from '@angular/material/core';


@Component({
  selector: 'empusa-microapp-fbs-check-availability',
  templateUrl: './microapp-fbs-check-availability.component.html',
  styleUrls: ['./microapp-fbs-check-availability.component.css']
})
export class MicroappFbsCheckAvailabilityComponent implements OnInit {

  @Input() theBookDate: SearchDates;
  @Output() search: EventEmitter<SearchDates> = new EventEmitter();

  public checkAvailabilityForm: FormGroup;
  dateField = new FormControl();
  startTime = new FormControl();
  endTime = new FormControl();
  itError: boolean = false;
  startHoursCombo: DayHours[];
  endHoursCombo: DayHours[];
  memoCombo: DayHours[];

  dateFieldSubscription: Subscription;

  private schedule_for_today: MicroappFbsPeriod;
  private startDateFieldOldValue: string;
  private startTimeFieldSubscription: Subscription;
  private endDateFieldOldValue: string;
  private endTimeFieldSubscription: Subscription;
  calendarFilter: (d: Date | null) => boolean;
  the_schedule: MicroappFbsSchedule;
  changeFomTime: Subscription;

  constructor(
    private store: Store<microappFbsState.MicroappFbsStates>,
    private fbsConfig: FbsConfig,
    private formBuilder: FormBuilder,
    // private moment : Moment,
  ) {
    this.checkAvailabilityForm = this.formBuilder.group({
      dateField: this.dateField,
      startTime: this.startTime,
      endTime: this.endTime
    }, {validators: ValidateDate});

    this.changeFomTime = this.checkAvailabilityForm.controls['startTime'].valueChanges.subscribe(newValue => {

      let listFiterEndCombo = [];
      this.memoCombo.forEach(hour => {
        let starto = moment(this.checkAvailabilityForm.controls['startTime'].value, 'HHmm');
        let startoH = starto.get('minutes');
        let startHM = starto.get('hours');
        let endo = moment(hour.value, 'HHmm');
        let endoH = endo.get('minutes');
        let endoHM = endo.get('hours')
        if (endoH == startoH) {
          if (endoHM > startHM) {
            listFiterEndCombo.push(hour)
          }
        }
      })
      this.endHoursCombo = listFiterEndCombo;
    })
  }

  get f() {
    return this.checkAvailabilityForm.get('endTime');
  }

  ngOnInit(): void {
    this.store.pipe(select(selectScheduled)).subscribe(schedule => {
      if (schedule) {
        this.the_schedule = schedule;

        let the_date = new SearchDates;
        if (this.theBookDate) {
          the_date = this.theBookDate;
        } else {
          the_date.searchStartDate = new Date();
          let hour = the_date.searchStartDate.getHours() + 1;
          if (hour > 23) hour = 23;
          the_date.searchStartDate.setHours(hour, 0, 0, 0);
          the_date.searchEndDate = new Date();
          the_date.searchEndDate.setHours(hour, 30, 0, 0);
        }
        ;
        this.generateCalendarFilter(schedule);
        this.initHourCombo(schedule, the_date);
        // Set default values for filter
        this.checkAvailabilityForm.controls['dateField'].setValue(the_date.searchStartDate);
        this.startTime.setValidators([Validators.required]);
        this.endTime.setValidators([Validators.required]);
        this.suscribeToCalendar(the_date);
        this.suscribeToStartTime();
        this.suscribeToEndTime();
      }
    });
  }


  generateCalendarFilter(schedule: MicroappFbsSchedule) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let not_allowed_days: number[] = [];

    schedule.periods.forEach(period => {
      if (period.schedule_periods.findIndex(x => x.isReservable == true) == -1) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const periodDate = new Date(period.date);
        periodDate.setHours(0, 0, 0, 0);
        if (periodDate >= today) {
          not_allowed_days.push(periodDate.getDay());
        }
      }
    });

    this.calendarFilter = (d: Date | null): boolean => {
      const day = (d || new Date()).getDay();
      let retorno = not_allowed_days.findIndex(x => x == day) == -1;
      if (retorno) {
        let date1 = new Date();
        date1.setHours(0, 0, 0, 0);
        let date2 = new Date();
        date2.setHours(0, 0, 0, 0);
        date2.setDate(date2.getDate() + this.fbsConfig.DAYS_IN_ADVANCE);
        retorno = (d >= date1) && (d < date2);
      }
      ;
      return retorno;
    }
  }

  public setHourCombos(the_dates: SearchDates) {
    // Set default values for filter
    let hour = the_dates.searchStartDate.getHours();
    let min = the_dates.searchStartDate.getMinutes();
    if (min >= 30) {
      min = 30;
    } else {
      min = 0;
    }
    const st = String("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2) + ":00";

    hour = the_dates.searchEndDate.getHours();
    min = the_dates.searchStartDate.getMinutes();
    if (min >= 30) {
      hour = hour + 1;
      min = 30;
    } else {
      hour = hour + 1;
      min = 0;
    }
    const et = String("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2) + ":00";
    this.checkAvailabilityForm.controls['startTime'].setValue(st);
    this.checkAvailabilityForm.controls['endTime'].setValue(et);
  }

  initHourCombo(schedule: MicroappFbsSchedule, the_date: SearchDates) {
    let startHoursCombo: DayHours[] = [];
    let endHoursCombo: DayHours[] = [];
    const date = the_date.searchStartDate.toISOString().split("T")[0];
    // NOTE: Booked do not give the schedule in a specified date, so as a template
    //       we take today schedule.
    let schedule_for_today = schedule.periods.find(x => x.date == date);

    if (!schedule_for_today) {
      schedule_for_today = schedule.periods.find(x => new Date(x.date).getDay() == the_date.searchStartDate.getDay());
    }

    this.schedule_for_today = schedule_for_today;


    schedule_for_today.schedule_periods.forEach(one_period => {
      if (one_period.isReservable) {
        startHoursCombo.push({value: one_period.startTime, viewValue: one_period.startTime.slice(0, 5)});
        endHoursCombo.push({value: one_period.endTime, viewValue: one_period.endTime.slice(0, 5)});
      }
    });
    this.startHoursCombo = startHoursCombo;
    this.endHoursCombo = endHoursCombo;
    this.memoCombo = endHoursCombo;

    if (startHoursCombo.length == 0) {
      this.checkAvailabilityForm.controls['startTime'].setValue("");
      this.checkAvailabilityForm.controls['endTime'].setValue("");
      return;
    }

    let st = this.checkAvailabilityForm.controls['startTime'].value;
    let et = this.checkAvailabilityForm.controls['endTime'].value;
    if (!st) {
      // Set default values for filter
      let hour = the_date.searchStartDate.getHours();
      let min = the_date.searchStartDate.getMinutes();
      if (min >= 30) {
        min = 30;
      } else {
        min = 0;
      }
      st = String("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2) + ":00";

      hour = the_date.searchEndDate.getHours();
      min = the_date.searchEndDate.getMinutes();
      if (min >= 30) {
        hour = hour;
        min = 0;
      } else {
        hour = hour;
        min = 30;
      }
      et = String("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2) + ":00";
    }
    if (st < startHoursCombo[0].value) {
      st = startHoursCombo[0].value;
      et = endHoursCombo[0].value;
    } else if (st > startHoursCombo[startHoursCombo.length - 1].value) {
      st = startHoursCombo[startHoursCombo.length - 1].value;
      et = endHoursCombo[startHoursCombo.length - 1].value;
    }
    ;

    this.checkAvailabilityForm.controls['startTime'].setValue(st);
    this.checkAvailabilityForm.controls['endTime'].setValue(et);
  }


  suscribeToCalendar(the_date: SearchDates) {
    this.dateFieldSubscription = this.dateField.valueChanges.subscribe(newDate => {
      let schedule: MicroappFbsSchedule;
      this.store.pipe(select(selectScheduled)).pipe(take(1)).subscribe(valor => {
        schedule = valor;
      });
      if (schedule) {
        the_date.searchStartDate = newDate;
        this.initHourCombo(schedule, the_date);
      }
    });
  }

  suscribeToStartTime() {
    this.startTimeFieldSubscription = this.startTime.valueChanges.subscribe(newTime => {
      if (this.startDateFieldOldValue == newTime)
        return;
      this.startDateFieldOldValue = newTime
      const et = this.checkAvailabilityForm.controls['endTime'].value;
      if (newTime < et)
        return;
      const slot = this.schedule_for_today.schedule_periods.find(x => x.startTime == newTime);
      this.checkAvailabilityForm.controls['endTime'].setValue(slot.endTime);
    });

  }


  suscribeToEndTime() {
    this.endTimeFieldSubscription = this.endTime.valueChanges.subscribe(newTime => {
      if(newTime){
        if (this.endDateFieldOldValue == newTime)
        return;
      this.endDateFieldOldValue = newTime
      const st = this.checkAvailabilityForm.controls['startTime'].value;
      if (newTime > st)
        return;
      const slot = this.schedule_for_today.schedule_periods.find(x => x.endTime == newTime);
      this.checkAvailabilityForm.controls['startTime'].setValue(slot.startTime);
      }   
    });
  }

  toJSON(x){
    return JSON.stringify(x);
  }

  resetEndTime(){
    this.checkAvailabilityForm.get('endTime').setValue(undefined);
  }

  ngOnDestroy(): void {
    if (this.dateFieldSubscription) {
      this.dateFieldSubscription.unsubscribe();
    }
    if (this.startTimeFieldSubscription) {
      this.startTimeFieldSubscription.unsubscribe();
    }
    if (this.endTimeFieldSubscription) {
      this.endTimeFieldSubscription.unsubscribe();
    }
  }

  public checkFacilitieAvailability() {
    const dateValue = this.checkAvailabilityForm.controls.dateField.value;
    if (this.checkAvailabilityForm.invalid) {
      return;
    }
    let searchStartDate: Date;
    let searchEndDate: Date;

    if (dateValue != null && dateValue !== '') {
      searchStartDate = new Date(dateValue);
      searchEndDate = new Date(dateValue);
    } else {
      searchStartDate = new Date();
      searchEndDate = new Date();
    }
    const st = this.checkAvailabilityForm.controls['startTime'].value.split(":");
    searchStartDate.setHours(+st[0], +st[1], +st[2], 0);

    const et: string = this.checkAvailabilityForm.controls['endTime'].value.split(":");
    searchEndDate.setHours(+et[0], +et[1], +et[2], 0);

    let response: SearchDates = {searchStartDate, searchEndDate}
    this.search.emit(response);
  }
}

function ValidateDate(control: AbstractControl): { [key: string]: any } | null {
  // if (!control.get('startTime').value || control.get('endTime').value){
  //   return null;
  // }
  let starto = moment(control.get('startTime').value, 'HHmm');
  let startoN = starto.get('minutes')
  let endo = moment(control.get('endTime').value, 'HHmm');
  let endoN = endo.get('minutes')
  if (endoN != startoN) {
    control.get('endTime').setErrors({endTimeInvalid: true})
    return {'endTimeInvalid': true};

  }
  return null;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
