
<h3>
  <strong>
    {{'MAPP-FBS-FACILITY-LIST.GROUPED_FACILITIES_TITLE' | translate}}
  </strong>
</h3>
<div class="table-container">
  <table mat-table [dataSource]="resources" style="width: 100%;">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-MANAGEMENT.RESOURCE' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-MANAGEMENT.LOCATION' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-MANAGEMENT.DESCRIPTION' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
