import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';

@Component({
  selector: 'empusa-microapp-eps-quotas-delete',
  templateUrl: './microapp-eps-quotas-delete.component.html',
  styleUrls: ['./microapp-eps-quotas-delete.component.css']
})
export class MicroappEpsQuotasDeleteComponent implements OnInit {
  tenant_id: string;
  tenant_name: string;
  loading: boolean = false;

  constructor(
    private epsServices: EpsServices,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    public dialogRef: MatDialogRef<MicroappEpsQuotasDeleteComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tenant_id = data.tenant_id;
    this.tenant_name = data.tenant_name;
  }

  ngOnInit(): void {
  }


  doAction() {
    this.loading = true;
    this.epsServices.deleteQuotas(this.tenant_id).subscribe(tenantQuotas => {
      this.loading = false;
      this.store.dispatch(epsActions.epsStoreQuotas({ tenantQuotas }));
      this.dialogRef.close({ event: 'ok' });
    }, error => {
      this.loading = false;
      // TODO: SHOW Error message
    }, () => {
      this.loading = false;

    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }
}
