<h2 class="titleDel" >{{'MAPP-FBS-DELETE-RESOURCE.TITLE' | translate}}</h2>
<div>
    {{'MAPP-FBS-DELETE-RESOURCE.TEXT' | translate}}
</div>
<p></p>
<div class="bodyData">
    <empusa-microapp-fbs-facility-data [facility]="resource"></empusa-microapp-fbs-facility-data>
</div>

<div mat-dialog-actions class="buttonFitting" align="end">
    <button mat-raised-button class="buttonClose"
        (click)="close()">{{'MAPP-FBS-DELETE-RESOURCE.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary"
        (click)="deleteResource()">{{'MAPP-FBS-DELETE-RESOURCE.DELETE' | translate}}</button>
</div>

<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>