import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MicroappFbsResourceModel } from '../../../../client/dto/microapp-fbs-resource';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { selectChargeTypes, selectResources, selectResourceGroups } from '../../../../lib/store/microapp-fbs-selectors';
import * as microappFbsState from '../../../../lib/store/microapp-fbs-app-reducer';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FbsConfig } from '../../../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsChargeType } from '../../../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';
import { Subscription } from 'rxjs';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';


@Component({
  selector: 'empusa-microapp-fbs-new-update-resource-group',
  templateUrl: './microapp-fbs-new-update-resource-group.component.html',
  styleUrls: ['./microapp-fbs-new-update-resource-group.component.css']
})
export class MicroappFbsNewUpdateResourceGroupComponent implements OnInit {

  // resourceToUpdate: MicroappFbsResourceGroup;
  resourceToEditId: string;
  newResourceFormGroup: FormGroup;
  selectChargeTypes: MicroappFbsChargeType[];
  loading: boolean;
  storeSubscription: Subscription;
  listFacilities = [];

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private fbsConfig: FbsConfig,
    private formBuilder: FormBuilder,
    private messageHandler: MicroappFbsMessageHandlerService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    @Inject(MAT_DIALOG_DATA) data :MicroappFbsResourceGroup) {


    this.newResourceFormGroup = this.formBuilder.group({
      name: ['', Validators.required],

      description: ['', Validators.required],
      maxParticipants: ['', [Validators.required, Validators.pattern("^[0-9]*$"), , Validators.max(1000)]],
      chargeType: ['', Validators.required],
      rooms: ['', Validators.required],
    });
      //   if (this.resourceToUpdate) {
  //     this.newResourceFormGroup.patchValue(this.resourceToUpdate)
  //   }
    if (data && data.resourceGroupId){
      this.resourceToEditId = data.resourceGroupId;
      // this.isUpdate = true;
      this.f.name.setValue(data.name);
      this.f.description.setValue(data.description)
      this.f.maxParticipants.setValue(data.maxParticipants)
      this.f.chargeType.setValue(data.chargeType)
      this.f.rooms.setValue(data.resourcesIds)
    }

  }


  ngOnInit(): void {


    this.store.pipe(select(selectChargeTypes)).pipe(take(1)).subscribe(valor => {

      this.selectChargeTypes = valor;
    });


    // this.buildForm();
    this.storeSubscriptionFn();
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.newResourceFormGroup.controls; }

  public storeSubscriptionFn() {

    this.storeSubscription = this.store.select('fbs').subscribe(data => {
      if (data && data.resources) {
        data.resources_grouped_for_admin.forEach(element => {
          if (element.type === 'facility') {
            this.listFacilities.push(element)
          }
        })
      }
    });
  }

  compareResources(p1, p2){

    return Number(p1) === p2
  }

  onSubmit() {
    this.loading = true;
    let facility = new MicroappFbsResourceGroup(this.fbsConfig);
    facility.name = this.f.name.value;
    facility.description = this.f.description.value;
    facility.maxParticipants = this.f.maxParticipants.value;
    facility.chargeType = this.f.chargeType.value;
    facility.type = this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP;

    facility.resourcesIds = this.f.rooms.value;

    if (this.resourceToEditId) {
      facility.resourceGroupId = this.resourceToEditId;
      this.update(facility);
    } else {
      this.newFacility(facility);
    }

  }
  update(facility: MicroappFbsResourceGroup) {
    this.facilityBookingService.updateResourceGroup(facility, this.resourceToEditId).subscribe((data: any[]) => {
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

  newFacility(facility: MicroappFbsResourceGroup) {
    this.facilityBookingService.newResourceGroup(facility).subscribe((data: any[]) => {
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

}
