<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div *ngIf="editTenant.uid">
    <h2 class="titleNew">{{'MAPP-USERMANAGEMENT.EDITTENANT.TITLE' | translate}}<button class="closeEditVist"
            mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button></h2>
    <h5></h5>
</div>

<div *ngIf="!editTenant.uid">
    <h2 class="titleNew">{{'MAPP-USERMANAGEMENT.NEWTENANT.TITLE' | translate}}<button class="closeEditVist"
            mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button></h2>
    <h5>{{'MAPP-USERMANAGEMENT.NEWTENANT.SUBTITLE' | translate}}</h5>
</div>


<form class="container" [formGroup]="tenantForm" (ngSubmit)="onSubmit()">

    <div>
        <!-- <mat-form-field class="formTenantMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="name"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.NAME' | translate}}" name="name" required
                [ngClass]="{ 'is-invalid': g.name.errors }" mt-autofocus />
            <mat-error *ngIf="tenantForm.controls.name.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITTENANT.REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="tenantForm.controls.name.hasError('pattern')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.INVALID' | translate}}</mat-error>
        </mat-form-field> -->

        <!-- (onSelectionChange)="onSelectRol($event)" -->
        <mat-form-field *ngIf="!editTenant.uid" class="formTenantMan">
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.NAME' | translate}}</mat-label>
            <mat-select formControlName="name" required>
                <mat-option matTooltip="{{tenant.billingTenantName}}" *ngFor="let tenant of tenantBillingId" [value]="tenant.billingTenantName" (click)="selectedTenant(tenant.billingTenantId)">
                    {{tenant.billingTenantName}}</mat-option>
            </mat-select>
       
        </mat-form-field>
        <mat-form-field *ngIf="editTenant.uid" class="formTenantMan" appearance="none">
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.NAME' | translate}}</mat-label>
            <input readonly matInput [errorStateMatcher]="matcher" formControlName="name" name="name"mt-autofocus />
        </mat-form-field>
        <mat-form-field class="formTenantMan" appearance="none">
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.BILLINGTENANTID' | translate}}</mat-label>
            <input matInput [errorStateMatcher]="matcher" formControlName="billingTenantId" name="description"
                required readonly [ngClass]="{ 'is-invalid': g.billingTenantId.errors }" />
            <mat-error *ngIf="tenantForm.controls.billingTenantId.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITTENANT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        
    </div>
    <div>
        <mat-form-field class="formTenantMan">
            <mat-label  *ngIf='!showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.FLOOR' | translate}}</mat-label>
            <mat-label  *ngIf='showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.FLOOR.HOME' | translate}}</mat-label>
            <mat-select formControlName="floor" multiple required>
                <mat-option  *ngFor="let floor of floorList" [value]="floor.HomeFloorId">
                    {{floor.DisplayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="formTenantMan">
            <mat-label  *ngIf='!showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.ACCESS' | translate}}</mat-label>
            <mat-label  *ngIf='showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.ACCESS.RIGHTS.LIFT' | translate}}</mat-label>
            <mat-select formControlName="access" multiple required>
                <mat-option  *ngFor="let access of accessList" [value]="access.AccessRightId">
                    {{access.DisplayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf='showAccessGroup' class="formTenantMan">
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.ACCESS.GROUP' | translate}}</mat-label>
            <mat-select formControlName="accessGroup" multiple required>
                <mat-option  *ngFor="let accessG of accessGroupList" [value]="accessG.AccessGroupId">
                    {{accessG.DisplayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="formTenantDes">
            <input matInput [errorStateMatcher]="matcher" formControlName="description"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITTENANT.FIELD.DESCRIPTION' | translate}}" name="description"
               [ngClass]="{ 'is-invalid': g.description.errors }" mt-autofocus />
            <mat-error *ngIf="tenantForm.controls.description.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITTENANT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
</form>
<p></p>
<div mat-dialog-actions class="buttonUserMan" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-USERMANAGEMENT.EDITTENANT.ACTION.CANCEL' | translate}}</button>
    <button class="buttonConfirm" [disabled]="!tenantForm?.valid" mat-raised-button color="primary"
        (click)="onSubmit()">
        {{'MAPP-USERMANAGEMENT.EDITTENANT.ACTION.SAVE' | translate}}</button>
</div>

