import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService,User } from '@empusa/empusa-core';
import { MICROAPP_ACCESS_QUIZ, MICROAPP_NAME } from '../public-api';
// import { MICROAPP_ACCESS_QUIZ, MICROAPP_NAME } from '../lib/microapp-quiz.module';

@Component({
  selector: 'microapp-quiz',
  templateUrl: 'microapp-quiz.component.html',
  styleUrls: ['microapp-quiz.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuizComponent implements OnInit {

  siteName: string;
  loading: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  can_access: boolean = false;
  constructor(private translate: TranslateService, private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    // this.siteName = this.auth.getCurrentSite().name;
    this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_ACCESS_QUIZ)
    this.loading = true;
    this.auth.loadCurrentUser().then((user:User) => {
      this.loading = false;
      this.siteName = user.sites[0].name;
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      if(this.isAdmin || this.isSuperAdmin){
        this.can_access = true;
      }else{
        this.can_access = false;
      }
    });
  }
}
