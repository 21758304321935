import { MicroappEpsValidationUtils } from './../../../common/microapp-eps-validation-utils';
import { MatTableDataSource } from '@angular/material/table';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EpsParkingRequest } from '../../../../lib/model/reservation/microapp-eps.parking-request.model';

@Component({
  selector: 'empusa-mini-reservations-invoice',
  templateUrl: './mini-reservations-invoice.component.html',
  styleUrls: ['./mini-reservations-invoice.component.css']
})
export class MiniReservationsInvoiceComponent implements OnInit, OnChanges {
  OK_STATUS = 'ok';

  @Input() rawRequests: EpsParkingRequest[] = [];
  @Input() showActions: boolean = false;
  @Input() showTotals: boolean = false;
  @Output() onPaypalClick = new EventEmitter<number>();
  @Output() onYardiClick = new EventEmitter<number>();
  @Input() mode: 'employee' | 'visitor' = 'employee';

  invoiceTableDataSource: MatTableDataSource<EpsParkingRequest> = new MatTableDataSource();
  displayedColumns = ['name', 'icon', 'ui', 'plate', 'status', 'cost', 'tariff'];


  requestIsOK = MicroappEpsValidationUtils.requestIsOK;

  constructor(@Inject('environment') public environment) { }

  ngOnInit(): void {
    if (this.showActions && this.mode == 'employee') {
      this.displayedColumns.push('actions')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.invoiceTableDataSource.data = this.rawRequests;
  }

  calculateTotal() {
    if (!this.rawRequests) {
      return 0;
    }

    let total = 0;
    this.rawRequests.forEach(item => {
      if (MicroappEpsValidationUtils.requestIsOK(item)) {
        total += item.price;
      }
    });
    return total;
  }

  toPaypal(reservationId: number) {
    this.onPaypalClick.emit(reservationId);
  }

  toYardi(reservationId:number) {
    this.onYardiClick.emit(reservationId);
  }


}
