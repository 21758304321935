import { Injectable, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class MicroappVmsCustomMatPaginatorIntl extends MatPaginatorIntl  {
  txtxSubscription: Subscription;
  constructor(private translate: TranslateService) {
    super();
  }


  getRangeLabel = (page: number, pageSize: number, length: number) => {

    this.itemsPerPageLabel = this.translate.instant('MAPP-VMS-PAGINATOR.ITEMS');
    this.changes.next();

    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }
}