<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<h2 class="cabecera">
  <mat-icon class="iconoCabecera">announcement</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-FAULTREPORT.DESCRIPTION' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="headerFaultNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-FAULTREPORT.LIST.HEAD' | translate}}">
        <microapp-faultreport-fault-list></microapp-faultreport-fault-list>
      </mat-tab>
      <mat-tab label="{{'MAPP-FAULTREPORT.REPORT.HEAD' | translate}}" *ngIf="permissionReporting">
        <microapp-faultreport-fault-report></microapp-faultreport-fault-report>
      </mat-tab>
    </mat-tab-group>
    <div class="notAccess" *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>