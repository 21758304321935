import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  url: string = this.environment.urlbase + 'usermanagement/services/';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  findUsersByBuilding(uid: string): any {
    return this.http.get(this.url + 'sites/' + uid + '/users').pipe(
      map(data => data)
    );
  }

  findUsersByTenant(uid: string): any {
    let id = encodeURIComponent(uid);
    return this.http.get(this.url + 'tenant/' + id + '/users').pipe(
      map(data => data)
    );
  }
  
  getSite(uid: String): any {​​​​​​​​
    return this.http.get(this.url + 'sites/' + uid).pipe(
    map(data=>data)
        );
      }​​​​​​​​
}
