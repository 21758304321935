import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'microapp-hvac-facility',
  templateUrl: 'microapp-hvac.facility-hvac.component.html',
  styles: ['microapp-hvac.facility-hvac.component.css']
})
export class FacilityComponent implements OnInit {


  constructor(private translate: TranslateService, private auth: AuthenticationService) {
   
  }

  
  ngOnInit(): void {
    
  }


}