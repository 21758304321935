import { Reservation } from './../../../../lib/model/reservation/microapp-eps.reservation.model';
import { EpsVehicleType } from './../../../../lib/model/microapp-eps.vehicle-type.model';
import { EpsReservationType } from './../../../../lib/model/reservation/microapp-eps.reservation-type.model';
import { EpsSpotType } from './../../../../lib/model/microapp-eps.spot-type.model';
import { FormGroup } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import * as microappEpsAppActions from '../../../../lib/store/microapp-eps.actions';
import { EpsAssignedChargeType } from '../../../../lib/model/charges_and_tariffs/microapp-eps.assigned-charge-types.model';
import { EpsState } from '../../../../lib/store/microapp-eps.reducer';
import { EpsServices } from '../../../../lib/services/microapp-eps.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ChargeTypeAssociationUtils } from '../../../../lib/utils/charge-type-association-utils';

@Component({
  selector: 'empusa-charge-type-form',
  templateUrl: './charge-type-form.component.html',
  styleUrls: ['./charge-type-form.component.css']
})
export class ChargeTypeFormComponent implements OnInit, OnDestroy {


  storeSubscription: Subscription;
  epsState: EpsState;
  form: FormGroup;

  selectableVehicles: EpsVehicleType[];
  selectableSpotTypes: EpsSpotType[];
  selectableReservationTypes: EpsReservationType[];
  notExistingAssociations: EpsAssignedChargeType[] = [];

  isEditMode = true;
  loadingSubmit = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public chargeTypeFromParent: EpsAssignedChargeType,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private formBuilder: FormBuilder,
    private parentDilogRef: MatDialogRef<ChargeTypeFormComponent>,
    private epsServices: EpsServices,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    if (!chargeTypeFromParent) {
      this.isEditMode = false;
      chargeTypeFromParent = new EpsAssignedChargeType();
    }


    this.form = this.formBuilder.group({
      vehicle_type: [undefined, Validators.required],
      reservation_type: [undefined, Validators.required],
      spot_type: [, Validators.required],
      charge_type: [chargeTypeFromParent.charge_type, Validators.required],
      vehicle_spottype_reservationtype_id: [chargeTypeFromParent.vehicle_spottype_reservationtype_id]
    });
  }

  ngOnInit(): void {
    this.storeSubscription = this.store.select('eps').subscribe(data => {
      this.epsState = data;
      this.notExistingAssociations = ChargeTypeAssociationUtils.getNotExistingCombinationsForAssociations(data);
      this.setSelectableVehicleTypes();
      if (this.isEditMode) {
        this.selectableVehicles = this.epsState.parkingVehicleTypes;
        this.selectableSpotTypes = this.epsState.spotTypes;
        this.selectableReservationTypes = this.epsState.reservationTypes;
        this.initializeFormData(this.chargeTypeFromParent);
      }
    });
  }

  initializeFormData(data: EpsAssignedChargeType) {
    this.form.controls.vehicle_type.setValue(this.epsState.parkingVehicleTypes
      .find(element => element.vehicle_type_id === this.chargeTypeFromParent.vehicle_type.vehicle_type_id));

    this.form.controls.spot_type.setValue(this.epsState.spotTypes
      .find(element => element.spot_type_id === this.chargeTypeFromParent.spot_type.spot_type_id));

    this.form.controls.reservation_type.setValue(this.epsState.reservationTypes
      .find(element => (console.log(element), element.reservation_type_id === this.chargeTypeFromParent.reservation_type.reservation_type_id)));
  }

  submitForm() {
    if (this.form.valid) {
      this.loadingSubmit = true;
      if (this.isEditMode) {
        this.epsServices.put_assigned_charges(this.form.value).subscribe((assignedCharges) => {
          this.store.dispatch(microappEpsAppActions.epsStoreAssignedCharges({ assignedCharges }))
          this.closeDialog();
        }, error => {
          this.snackBar.open(this.translate.instant('MAPS-EPS.GENERIC.UNKNOWN_ERROR'), this.translate.instant('MAPP-EPS.GENERIC.UNKNOWN_ERROR_TITLE'), {
            duration: 2000,
          });
          this.loadingSubmit = false;
        });
      } else {
        this.epsServices.post_assigned_charges(this.form.value).subscribe((assignedCharges) => {
          this.store.dispatch(microappEpsAppActions.epsStoreAssignedCharges({ assignedCharges }))
          this.closeDialog();
        }, error => {
          this.snackBar.open(this.translate.instant("MAPP-EPS.GENERIC.UNKNOWN_ERROR"), this.translate.instant('MAPP-EPS.GENERIC.UNKNOWN_ERROR_TITLE'), {
            duration: 2000,
          });
          this.loadingSubmit = false;
        });
      }

    }
  }

  chargeTypesToStringArray(chargeTypes: any[]) {
    return chargeTypes.map(item => item.application_charge_code);
  }

  onVehicleTypeChange() {
    this.form.controls.spot_type.setValue(undefined);
    this.form.controls.reservation_type.setValue(undefined);
    this.setSelectableOptions();
  }

  onSpotTypeChange() {
    this.form.controls.reservation_type.setValue(undefined);
    this.setSelectableOptions();
  }

  setSelectableOptions() {
    const selectedVehicleType: EpsVehicleType = this.form.value.vehicle_type;
    const selectedSpotType: EpsSpotType = this.form.value.spot_type;

    if (selectedVehicleType) {
      this.selectableSpotTypes = [];
      this.epsState.spotTypes.forEach(spotType => {
        const filtered = ChargeTypeAssociationUtils.filterNotExistingCombinationsForAssociations(selectedVehicleType,
          spotType, null, this.notExistingAssociations);
        if (filtered.length > 0) {
          this.selectableSpotTypes.push(spotType);
        }
      });
    }

    if (selectedVehicleType && selectedSpotType) {
      this.selectableReservationTypes = [];
      this.epsState.reservationTypes.forEach(reservationType => {
        const filtered = ChargeTypeAssociationUtils.filterNotExistingCombinationsForAssociations(selectedVehicleType,
          selectedSpotType, reservationType, this.notExistingAssociations);
        if (filtered.length > 0) {
          this.selectableReservationTypes.push(reservationType);
        }
      });
    }

  }

  setSelectableVehicleTypes() {
    this.selectableVehicles = [];
    this.epsState.parkingVehicleTypes.forEach(vehicleType => {
      const filtered = ChargeTypeAssociationUtils.filterNotExistingCombinationsForAssociations(vehicleType,
        null, null, this.notExistingAssociations);
      if (filtered.length > 0) {
        this.selectableVehicles.push(vehicleType);
      }
    });
  }



  closeDialog() {
    this.parentDilogRef.close();
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

}
