import { Time } from '@angular/common';
import { Tenant } from '@empusa/empusa-core';

export class MicroappVmsSearchVisitFilter {
    id: number;
    visitor_id: number;
    tenants: Tenant[] = [];
	host_id : string;
	site_id: string;
	date_from: Date;
	date_to: Date;
	start_time: Time;
	end_time: Time;
	check_in_time: Time;
	check_out_time: Time;
	about: string;
	complimentary_parking: boolean;
	group_id: string;
}