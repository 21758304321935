<mat-toolbar class="toolbarDetail" color="primary">
    <mat-toolbar-row>
      <span class=tittleToolbar>{{'MAPP-VMS-VISITOR-DETAIL.TITLE' | translate}}</span>
      <span class="spacer"></span>
      <button class="closeEditVist" mat-icon-button (click)="onClose()"> 
          <mat-icon class="closeEditVisitIcon" aria-hidden="false" aria-label="Example heart icon">close
          </mat-icon>
      </button>
  </mat-toolbar-row>

  </mat-toolbar>