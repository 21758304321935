import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsPeriodSlot, MicroappFbsSchedule, MicroappFbsPeriod } from './microapp-fbs-schedule';
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class FbsScheduleAdapter implements Adapter<MicroappFbsSchedule> {

  constructor(private fbsConfig: FbsConfig,) { }

  adapt(item: any): MicroappFbsSchedule {
    let schedule = new MicroappFbsSchedule;
    item.periods.forEach(period => {
      const periodToAdd = new MicroappFbsPeriod;
      period.forEach( slot => {
        let period = new MicroappFbsPeriodSlot;
        period.isReservable = slot.isReservable;
        period.label = slot.label;
        period.start = new Date(slot.start);
        period.end = new Date(slot.end);
        period.startTime = moment(period.start).format("HH:mm:00");
        period.endTime = moment(period.end).format("HH:mm:00");
        periodToAdd.schedule_periods.push(period);
      });

      periodToAdd.schedule_periods.sort((p1, p2) => {
        return p1.start.getTime() - p2.start.getTime();
      });

      periodToAdd.date = periodToAdd.schedule_periods[0].end.toISOString().substring(0,10);
      schedule.periods.push(periodToAdd);
    });

    schedule.periods.sort((p1, p2) => {
      return p1.date > p2.date ? 1 : -1;
    });
    return schedule;
  }


}
