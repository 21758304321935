import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MicroappFbsTarif } from "../../../client/dto/microapp-fbs-tarif";
import { forkJoin, Observable } from "rxjs";
import { MicroappFbsChargesService } from "../../../lib/microapp-facilitiesbooking-charge.service";
import { SiteService } from "../../../client/site.service";
import { AuthenticationService, Tenant, User } from "@empusa/empusa-core";
import { MicroappFbsChargeType } from "../../../client/dto/microapp-fbs-charge-type";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { FbsConfig } from "../../../lib/microapp-facilitiesbooking-config";


@Component({
    selector: 'microapp-fbs-tariff-edtitTariffType',
    templateUrl: './microapp-fbs-tariff-editTariffType.component.html',
    styleUrls: ['./microapp-fbs-tariff-editTariffType.component.css']
})

export class EditTariffTypeComponent implements OnInit {

    public newTariffForm: FormGroup;
    public tarifList;
    tenantList: Tenant[] = [];
    currentUser: User;
    chargeType: MicroappFbsChargeType = new MicroappFbsChargeType();

    constructor(private authservice: AuthenticationService, private formBuilder: FormBuilder,
        private fbsChargesService: MicroappFbsChargesService, private siteService: SiteService,
        public dialogRef: MatDialogRef<EditTariffTypeComponent>, private snackBar: MatSnackBar,
        private translate: TranslateService, public fbsConfig: FbsConfig,
        @Inject(MAT_DIALOG_DATA) public dataLoad: EditTariffTypeComponentData) {



        this.newTariffForm = this.formBuilder.group({
            tariff: ['', Validators.required],
        });



        this.authservice.loadCurrentUser().then(user => {
            this.currentUser = user;
            if(dataLoad.resource.tarif){
                this.f.tariff.setValue(dataLoad.resource.tarif.tarif_id);
            }

            this.chargeTarif();
        })
    }

    get f(): any { return this.newTariffForm.controls; }

    onSubmit() {
        let tenant = this.dataLoad.tenant;
        let chargeId = this.dataLoad.resource.id;
        let tariff = this.f.tariff.value;
        this.fbsChargesService.assignTenant(tenant, chargeId, tariff).subscribe(result => {

            this.snackBar.open(this.translate.instant("MAPP-FBS-ASSIGN-UPDATE.OK.MESSAGE"),
                this.translate.instant("MAPP-FBS-ASSIGN-UPDATE.OK.TITLE"), {
                duration: 5000,
            });
            this.dialogRef.close();
        },
            error => {
                this.snackBar.open(this.translate.instant("MAPP-FBS-ASSIGN-UPDATE.ERROR.MESSAGE"),
                    this.translate.instant("MAPP-FBS-ASSIGN-UPDATE.ERROR.TITLE"), {
                    duration: 5000,
                });
            });
    }

    chargeTarif() {
        const allTarifs = this.fbsChargesService.getTariffs();

        return forkJoin([allTarifs]).subscribe(
            resultados => {


                this.tarifList = resultados[0];

            })
    }


    ngOnInit(): void {


    }

}

export interface EditTariffTypeComponentData {
    resource;
    tenant;
}

