import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MicroappFbsResourceModel } from '../../client/dto/microapp-fbs-resource';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MicroappBfsMakeAReservationComponent } from '../common/make-a-facility-reservation/microapp-bfs-make-a-reservation.component';
import {  Store } from '@ngrx/store';
import * as microappFbsState from '../../lib/store/microapp-fbs-app-reducer';
import * as fbsActions from '../../lib/store/microapp-fbs-actions';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { Subscription } from 'rxjs';
import { MicroappFbsMakeAAccessoryReservationComponent } from '../common/make-a-accesory-reservation/microapp-fbs-make-a-accessory-reservation.component';
import { LOCALE_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { SearchDates } from '../../client/microapp-fbs.interfaces';
import { AuthenticationService } from '@empusa/empusa-core';
import { MicroappFbsResourceDetailComponent } from '../common/resource-detail/microapp-fbs-resource-detail.component';
import { MicroappFbsMakeAFacilityGroupReservationComponent } from '../common/make-a-facility-group-reservation/microapp-fbs-make-a-facility-group-reservation.component';

interface DayHours {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'microapp-facilitiesbooking-facilitybooking-list',
  templateUrl: './microapp-facilitiesbooking.facilitybookinglist.html',
  styleUrls: ['./microapp-facilitiesbooking.facilitybookinglist.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappFacilityBookingListComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource([]);


  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  storeSubscription: Subscription;
  raw_resources: MicroappFbsResourceModel[];
  public facilitiesWithBookInfo: boolean = false;

  private lastSearchDates: SearchDates;
  can_resources_reservation: boolean;


  constructor(
    private dialog: MatDialog,
    private fbsConfig: FbsConfig,
    @Inject(LOCALE_ID) public locale: string,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private authservice: AuthenticationService,
    private translate: TranslateService) {

  }


  ngOnInit(): void {
    this.can_resources_reservation = this.authservice.canUserExecute(this.fbsConfig.APPLICATION,this.fbsConfig.ROLE_RESOURCES_RESERVATION);
    this.storeSubscriptionFn();
  }


  public storeSubscriptionFn() {

    this.storeSubscription = this.store.select('fbs').subscribe(data => {
      if (data && data.resources) {
        this.raw_resources = data.resources;
        this.dataSource = new MatTableDataSource(data.resources_grouped);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.facilitiesWithBookInfo = data.facilitiesWithBookInfo;
        if (data.facilitiesWithBookInfo) {
          this.displayedColumns = ['type', 'name', 'description', 'location', 'capacity', 'bookAction'];
        } else {
          this.displayedColumns = ['type', 'name', 'description', 'location', 'capacity', 'actions'];
        }
      };

    });
  }


  public initFacilities() {
    this.store.dispatch(fbsActions.loadData());
  }


  public search($event: SearchDates) {
    let startDate: Date = new Date($event.searchStartDate);
    let endDate: Date = new Date($event.searchEndDate);
    this.lastSearchDates = $event;

    this.store.dispatch(fbsActions.checkAllFacilitiesAvailability({ startDateTime: startDate, endDateTime: endDate }));
  }


  public applyFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  the_resource_detail(facility: MicroappFbsResourceGroup) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = facility;
    dialogConfig.width = '620px';
    const dialogRef = this.dialog.open(MicroappFbsResourceDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  public toBook(resourceToBook: MicroappFbsResourceGroup) {
    if (!this.can_resources_reservation){
      return;
    }
    if (resourceToBook.type === this.fbsConfig.RESOURCE_TYPE_ATTR_ACCESSORY) {
      this.bookAAccessory(resourceToBook);
    } else if (resourceToBook.type === this.fbsConfig.RESOURCE_TYPE_ATTR_FACILITY){
      const selectedFacility = this.raw_resources.find(x => x.resourceId == resourceToBook.singleResourceId);
      this.bookAFacility(selectedFacility);
    } else if (resourceToBook.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP){
      this.bookAGroup(resourceToBook);
    }
  }


  private bookAFacility(facilityToBook: MicroappFbsResourceModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [facilityToBook, {...this.lastSearchDates}];
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(MicroappBfsMakeAReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.search(this.lastSearchDates);
      }
    });
  }

  private bookAGroup(facilityGroupToBook: MicroappFbsResourceGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [facilityGroupToBook, {...this.lastSearchDates}];
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(MicroappFbsMakeAFacilityGroupReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.search(this.lastSearchDates);
      }
    });
  }



  private bookAAccessory(accessoryToBook: MicroappFbsResourceGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [accessoryToBook, this.lastSearchDates];
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(MicroappFbsMakeAAccessoryReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.search(this.lastSearchDates);
      }
    });
  }


  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    };
  }
}
