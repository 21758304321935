
import { MicroappFbsResourceModel } from "./microapp-fbs-resource";
import { MicroappFbsResourcesGroupedAsOneModel } from "./microapp-fbs-resources-grouped-as-one";

export class MicroappFbsResourceListModel{

  resources: MicroappFbsResourceModel[];
  grouped_resources: MicroappFbsResourceModel[];
  grouped_as_one_resources: MicroappFbsResourcesGroupedAsOneModel[];

}