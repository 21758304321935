import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { VmsConfig } from '../../lib/microapp-vms-config';

@Injectable({
  providedIn: 'root'
})
export class EpsServiceService {

  constructor(
    private http: HttpClient,
    private vmsConfig : VmsConfig
  ) { }

  tenant_quotas(tenant: string) {
    let id = encodeURIComponent(tenant);
    const url = this.vmsConfig.URL_REST_EPS_BASE + "tenant_quotas/" + id;
    return this.http.get(url).pipe(
        map(data => data)
    )
}
}
