<form class="tenantForm" [formGroup]="tenantForm" (ngSubmit)="tenantSelected()">
    <mat-form-field class="margen_cajas">
        <mat-label>Tenants</mat-label>
        <mat-select formControlName="tenants">
            <mat-option *ngFor="let tenant of tenantsToSelect" [value]="tenant.uid">
                {{tenant.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button [disabled]="!tenantForm.valid" mat-flat-button (click)="tenantSelected()" class="buttonSearchTenant" color="primary">
        <mat-icon >search</mat-icon>
     </button>
</form>