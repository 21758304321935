export const locale = {
    "lang": "es",
    "data": {
        "MAPP-GENERIC": { //mandatory - microapp context
            "HELLO": "HOLA MUNDO - GENERICO",
            "TESTLIST":"ESTO ES UN LISTADO DE PRUEBA DESDE UN DATASOURCE FAKE",
            "DESCRIPTION":"Circulares y Notificaciones",
            "LIST":{
                "HEAD":"Notificaciones"
            }
        }
    }
}