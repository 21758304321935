
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, Tenant, User } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Notifications } from '../../client/dto/notifications';
import { NotificationsService } from '../../client/notifications.service';
import { MicroappDetailsComponent } from '../details/microapp-notifications.details.component';
import { MicroappNewComponent } from '../new/microapp-notifications.new.component';
import { MicroappNotificationsListFilter } from './microapp-notifications.list.component.fiter';

@Component({
  selector: 'empusa-microapp-notifications-list',
  templateUrl: './microapp-notifications.list.component.html',
  styleUrls: ['./microapp-notifications.list.component.css']
})
export class MicroappListComponent implements OnInit {
  displayedColumns: string[] = ['file', 'date', 'title', 'sendto', 'actions']
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  // currentUser: any;
  isAllowCreate: boolean;
  listNotification: []
  userFilter = new MicroappNotificationsListFilter();
  filterValues = {};
  filterGroup: FormGroup;
  loading: boolean = true;
  searchForm: FormGroup;
  searchDate: FormGroup;
  toDate;
  fromDate;
  subDestinatary$: Subscription;
  noShow: boolean = true;
  listTenanr
  usersList: User[] = []
  tenantsList: Tenant[] = [];
  theList = [];
  currentUser: User;
  // filteredOptionsUser: Observable<User[]>;
  // filteredOptionsTenant: Observable<Tenant[]>;
  filteredOption: Observable<User[] | Tenant[]>
  isUser: boolean;
  readonly localDateFormat = "dd/MM/yyyy";
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog,
    private datepipe: DatePipe,
    private translate: TranslateService, private formBuilder: FormBuilder,
    private service: NotificationsService, private auth: AuthenticationService,
    private _adapter: DateAdapter<any>) {
    // this.currentUser = this.auth.getCurrentUser();
    this._adapter.setLocale('en');
    this.searchForm = this.formBuilder.group({
      SEARCH: ['', Validators.required],
      SEARCHBY: ['All', Validators.required],
      FROM: ['', Validators.required],
      TO: ['', Validators.required]
    });
    this.auth.loadCurrentUser().then(user => {
      this.currentUser = user;
      this.toDate = new Date();
      this.fromDate = new Date();
      let mont = this.fromDate.getMonth();
      let moto = this.fromDate.setMonth(mont - 1);
      this.fromDate = new Date(moto);
      this.toDate = this.datepipe.transform(this.toDate, 'shortDate');
      this.fromDate = this.datepipe.transform(this.fromDate, 'shortDate');
      this.service.findUsersByBuilding(this.currentUser.sites[0].uid).subscribe((data) => {
        data.sort((a, b) =>
          a.mail.toLowerCase() > b.mail.toLowerCase() ? 1 :
            a.mail.toLowerCase() < b.mail.toLowerCase() ? -1 :
              0
        );
        this.usersList = data;
      });
      this.service.getSite(this.currentUser.sites[0].uid).subscribe(resp => {
        resp.tenants.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 :
              0
        );
        this.tenantsList = resp.tenants
      });

      this.subDestinatary$ = this.s.SEARCHBY.valueChanges.subscribe(newValue => {
        if (newValue == "All") {
          this.s.SEARCH.setValue("All")
          this.noShow = true;
        }
        if (newValue == "Tenant Name" || newValue == "Email") {
          this.s.SEARCH.setValue("")
          this.noShow = false;
          if (newValue == "Tenant Name") {
            this.isUser = false
          } else if (newValue == "Email") {
            this.isUser = true
          }
        }
      })
      this.viewNotifications();
    })

  }

  ngOnInit(): void {
    // this.viewNotifications();
    this.filterGroup = this.buildFilter();
    this.filteredOption = this.s.SEARCH.valueChanges
      .pipe(
        startWith(''),
        map(value =>
          this._filter(value)
        ))

  }

  viewNotifications() {
    this.searchForm.reset();
    this.loading = true;
    this.service.allNotfications(this.fromDate, this.toDate).subscribe(data => {
      this.loading = false;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.defineDataSourceFilter(this.dataSource);
      if (this.userFilter) {
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    })
  }

  buildFilter() {

    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      SEND: new FormControl(""),
    })
  }

  get s() { return this.searchForm.controls; }

  searchCiruclars() {
    let searchBy = this.s.SEARCHBY.value;
    let search = this.s.SEARCH.value;
    this.fromDate = this.s.FROM.value;
    this.toDate = this.s.TO.value;
    this.toDate = this.datepipe.transform(this.toDate, 'shortDate');
    this.fromDate = this.datepipe.transform(this.fromDate, 'shortDate');
    if (searchBy == 'Email') {
      this.getCircularsByEmail(search)
    } else if (searchBy == 'Tenant Name') {
      this.getCircularsByTenant(search)
    } else if (searchBy == 'All') {
      this.viewNotifications()
    }
  }

  /*Get Circulars by the email of the user */
  getCircularsByEmail(email) {
    this.loading = true;
    let from = this.fromDate;
    let to = this.toDate
    this.service.getNotificationsByEmail(email, from, to).subscribe(result => {
      this.loading = false;
      result.sort((a, b) =>
        a.date.toLowerCase() > b.date.toLowerCase() ? 1 :
          a.date.toLowerCase() < b.date.toLowerCase() ? -1 :
            0
      );
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.defineDataSourceFilter(this.dataSource);
      if (this.userFilter) {
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    })
  }

  /*Get Circulars by the name of the tenant */
  getCircularsByTenant(nameT) {
    this.loading = true;
    let from = this.fromDate;
    let to = this.toDate
    this.service.getNotificationsByTenant(nameT, from, to).subscribe(result => {
      this.loading = false;
      result.sort((a, b) =>
        a.date.toLowerCase() > b.date.toLowerCase() ? 1 :
          a.date.toLowerCase() < b.date.toLowerCase() ? -1 :
            0
      );
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.defineDataSourceFilter(this.dataSource);
      if (this.userFilter) {
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    })
  }

  //FILTERS
  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Notifications, filter: string) => {
        const theFilter: MicroappNotificationsListFilter = JSON.parse(filter);
        let generalFilter = false;
        let sendFilter = false;
        let expireDateFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.date.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.title.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;
        if (theFilter.send) {
          if (aGroup.sendto.indexOf(theFilter.send) !== -1) {
            sendFilter = true;
          }
        } else sendFilter = true;
        return generalFilter && sendFilter
      }
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applySendFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    // filterValue = filterValue.trim();
    // filterValue = filterValue.toLowerCase();
    this.userFilter.send = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.general = undefined;
    this.userFilter.send = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  createNotification() {
    let dialogAssign = this.dialog.open(MicroappNewComponent, {
      width: '760px',
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.viewNotifications();
    });
  }

  detailNotification(ticket_id) {
    let dialogAssign = this.dialog.open(MicroappDetailsComponent, {
      width: '760px',
      data: { ticket_id: ticket_id }
    });
    dialogAssign.afterClosed().subscribe(result => {
    });
  }

  chips = [
    { name: 'Selected Tenants' },
    { name: 'Selected Users' }
  ];

  types = [
    { name: 'All' },
    { name: 'Email' },
    { name: 'Tenant Name' }
  ];

  displayFn(user): string {
    return user;
  }

  displayFnT(tenant): string {
    return tenant;
  }

  private _filter(value: string) {
    if (this.s.SEARCHBY.value == "Email") {
      const filterValue = value.toLowerCase();
      return this.usersList.filter(option => option.mail.toLowerCase().includes(filterValue));
    }
    else if (this.s.SEARCHBY.value == "Tenant Name") {
      const filterValue = value.toLowerCase();
      return this.tenantsList.filter(option => option.name.toLowerCase().includes(filterValue));
    }
  }

}
