export class Participant {
    sid: string;
    firstname: string;
    lastname: string;
    email: string;
    external?: boolean;
    new?: boolean;
    language: string = 'en';
    tenant_name: string;

    constructor() {
    }
}