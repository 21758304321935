import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as addressBookActions from './microapp-vms-address-book.actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { VmsConfig } from '../../microapp-vms-config';
import { MicroappVmsVisitorWithPendingVisits } from '../../model/microapp-vms-visitor-pending-visits.model';
import { MicroappVmsVisitAdapter } from '../../model/microapp-vms-visit-model-adapter';



@Injectable()
export class MicroappVmsAddressbookEffects implements OnInitEffects {

    private getVisitors = this.vmsConfig.URL_REST_BASE_VISITOR;

    constructor(private actions$: Actions,
        private store: Store<vmsReducer.MicroappVmsStates>,
        private authservice: AuthenticationService,
        private vmsConfig: VmsConfig,
        private visitAdapter: MicroappVmsVisitAdapter,
        private http: HttpClient) { }

    ngrxOnInitEffects(): import("@ngrx/store").Action {
        return { type: 'DUMMY' }
    }


    addressBookSearchingVisitors$ = createEffect(() => this.actions$.pipe(
        ofType(addressBookActions.searchingVisitors),
        switchMap((action) => {
            console.log("Action " + action.type);
            let parameters: string = "?pending_visit=true";
            //TODO: Pending to add more filters
            if (action.filter.tenants) {
                let tenantLis = '["';
                tenantLis = tenantLis + action.filter.tenants.map(x => x.uid).join('","');
                tenantLis = tenantLis + '"]';
                parameters = parameters + "&tenant_id=" + tenantLis + "&off_set=" + new Date().getTimezoneOffset();
            }
            return this.http.get<MicroappVmsVisitorWithPendingVisits[]>(this.getVisitors + parameters)
                .pipe(
                    map(theVisitors => {
                        let visitors = theVisitors as MicroappVmsVisitorWithPendingVisits[];
                        visitors.forEach(vtor => {
                            if (vtor.pending_visits)
                                vtor.pending_visits = vtor.pending_visits.map ((visit) => this.visitAdapter.adapt(visit));
                        });
                        return addressBookActions.storeTheVisitors({ visitors });
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 404) { //Not found
                                return of(addressBookActions.storeTheVisitors({ visitors: [] }));
                            }
                        }
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(addressBookActions.error());
                    })
                )

        })
    ));


    addressBookUpdatingAVisitor$ = createEffect(() => this.actions$.pipe(
        ofType(addressBookActions.updateVisitor),
        withLatestFrom(this.store.select('addressBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            return this.http.put<null>(this.getVisitors, action.visitor)
                .pipe(
                    map(response => {
                        let visitorListCopy: MicroappVmsVisitorWithPendingVisits[];
                        if (state.visitors) {
                            visitorListCopy = state.visitors.slice();
                            const whereIs = state.visitors.findIndex(visitor => visitor.id == action.visitor.id);
                            if (whereIs >= 0) {
                                let visitorfind = state.visitors.find(visitor => visitor.id == action.visitor.id);
                                let visitor = { ...visitorfind };
                                visitor.first_name = action.visitor.first_name;
                                visitor.last_name = action.visitor.last_name;
                                visitor.company = action.visitor.company;
                                visitor.email = action.visitor.email;
                                visitor.phone = action.visitor.phone;
                                visitor.license_plate = action.visitor.license_plate;
                                visitor.about = action.visitor.about;
                                visitor.iu = action.visitor.iu;
                                visitor.vehicle_type = action.visitor.vehicle_type;
                                
                                visitorListCopy.splice(whereIs, 1, visitor);
                            }
                        }
                        return addressBookActions.visitorUpdated({ visitors: visitorListCopy })
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 204) { //Nothing to update
                                return of( { type: 'DUMMY' });
                            }
                        }
                        console.log("error: ", error);
                        return of(addressBookActions.error());
                    })
                )
        })
    ));


    deletePendingVisit$ = createEffect(() => this.actions$.pipe(
        ofType(addressBookActions.deletePendingVisit),
        withLatestFrom(this.store.select('addressBook')),
        map(([action, state]) => {
            console.log("Action " + action.type);
            let visitors: MicroappVmsVisitorWithPendingVisits[] = [];
            state.visitors.forEach(visitor => {
                visitors.push({ ...visitor });
            });
            let theVisitor = visitors.find(visitor => visitor.id == action.visitor_id);
            if (theVisitor) {
                let pendingVisits: MicroappVmsVisit[] = [];
                theVisitor.pending_visits.forEach(visit => {
                    if (visit.id !== action.visit_id) {
                        pendingVisits.push(visit);
                    }
                });
                theVisitor.pending_visits = pendingVisits;
            }
            return addressBookActions.storeTheVisitors({ visitors });
        })
    ));

    deleteVisitor$ = createEffect(() => this.actions$.pipe(
        ofType(addressBookActions.deleteVisitor),
        withLatestFrom(this.store.select('addressBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            let parameters: string = "/" + action.visitor_id;
            return this.http.delete<void>(this.vmsConfig.URL_REST_BASE_VISITOR + parameters)
                .pipe(
                    map(() => {
                        // Delete visit from logbook
                        let visitors: MicroappVmsVisitorWithPendingVisits[] = [];
                        state.visitors.forEach(visitor => {
                            if (visitor.id !== action.visitor_id)
                                visitors.push({ ...visitor });
                        });
                        return addressBookActions.storeTheVisitors({ visitors });
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(addressBookActions.error());
                    })
                )
        })
    ));

    refreshVisit$ = createEffect(() => this.actions$.pipe(
        ofType(addressBookActions.refreshVisit),
        withLatestFrom(this.store.select('addressBook')),
        map(([action, state]) => {
            console.log("Action ", action.type);
            let visitors: MicroappVmsVisitorWithPendingVisits[] = [];
            state.visitors.forEach(storeVisitor => {
                const index = storeVisitor.pending_visits.findIndex(v => v.id == action.visit.id);
                if (index < 0) {
                    visitors.push(storeVisitor);
                } else {
                    let visitor_cloned = { ...storeVisitor };
                    visitor_cloned.pending_visits = [];
                    storeVisitor.pending_visits.forEach(storeVisit => {
                        if (storeVisit.id == action.visit.id) {
                            let clon = { ...storeVisit };
                            if (action.visit.first_name) {
                                clon.first_name = action.visit.first_name
                            };
                            if (action.visit.last_name) {
                                clon.last_name = action.visit.last_name
                            };
                            if (action.visit.company) {
                                clon.company = action.visit.company
                            };
                            if (action.visit.email) {
                                clon.email = action.visit.email
                            };
                            if (action.visit.phone) {
                                clon.phone = action.visit.phone
                            };
                            if (action.visit.iu) {
                                clon.iu = action.visit.iu
                            };
                            if (action.visit.complimentary_parking_status) {
                                clon.complimentary_parking_status = action.visit.complimentary_parking_status
                            };
                            if (action.visit.license_plate) {
                                clon.license_plate = action.visit.license_plate
                            };
                            if (action.visit.about) {
                                clon.about = action.visit.about
                            };
                            if (action.visit.complimentary_parking !== undefined) {
                                clon.complimentary_parking = action.visit.complimentary_parking
                            };
                            if (action.visit.notification_status !== undefined) {
                                clon.notification_status = action.visit.notification_status
                            };
                            visitor_cloned.pending_visits.push(clon);
                        } else
                            visitor_cloned.pending_visits.push(storeVisit);
                    });
                    visitors.push(visitor_cloned);
                }
            });
            return addressBookActions.storeTheVisitors({ visitors });

        })
    ));
}

