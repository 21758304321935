import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import * as newVisitActions from '../../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MicroappVmsVisitWithVisitors } from '../../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit.model';
import { VmsConfig } from '../../../../lib/microapp-vms-config';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'empusa-microapp-vms-schedule-visit',
  templateUrl: './microapp-vms-schedule-visit.component.html',
  styleUrls: ['./microapp-vms-schedule-visit.component.css']
})
export class MicroappVmsScheduleVisitComponent implements OnInit {

  @Input() mode: string = "create-visit";
  //Store
  storeSuscripcion: Subscription;
  visit: MicroappVmsVisitWithVisitors;

  //form
  minScheduleDate: Date;
  maxScheduleDate: Date;
  formGroup: FormGroup;
  scheduleDateControl = new FormControl('', [Validators.required]);
  startTimeControl = new FormControl('', [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]);
  endTimeControl = new FormControl('', [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]);
  about = new FormControl();
  server_request: boolean;
  initDone = false;

  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private datepipe: DatePipe,
    public vmsConfig: VmsConfig) { }

  ngOnInit(): void {

    // Allows to schedule a visit from today till one year
    this.minScheduleDate = new Date();
    this.maxScheduleDate = new Date();
    this.maxScheduleDate.setFullYear(this.maxScheduleDate.getFullYear() + 1);
    
    // suscripcion al store
    this.storeSuscripcion = this.store.select('groupVisitManage').subscribe(datos => {
      this.visit = datos.visit;
      this.server_request = datos.loading;
      if (!datos.loading && !this.initDone && datos.visit) {
        this.initDone = true;
        switch (this.mode) {
          case 'update-visit': {
            if (this.visit.start_time) {
              const theDate = this.datepipe.transform(this.visit.start_time, 'short',this.vmsConfig.MASTER_CONFIG.offset);          
              console.log("theDate",new Date(theDate));
              this.formGroup.controls['scheduleDateControl'].setValue(new Date(theDate));
            };
            if (this.visit.start_time) {
              const time = this.datepipe.transform(this.visit.start_time, 'HH:mm',this.vmsConfig.MASTER_CONFIG.offset);
              console.log("time:", time),
              this.formGroup.controls['startTimeControl'].setValue(time);
            };
            if (this.visit.end_time) {
              const time = this.datepipe.transform(this.visit.end_time, 'HH:mm',this.vmsConfig.MASTER_CONFIG.offset);
              this.formGroup.controls['endTimeControl'].setValue(time);
            };
            if (this.visit.about) {
              this.formGroup.controls['about'].setValue(this.visit.about);
            }
            break;
          }
          default: { //create-visit
            //statements; 
            break;
          }
        }
      }
    });

    this.buildForm();
    this.formGroup.valueChanges.subscribe(x => {
      if (this.mode == "update-visit" && !this.initDone) return;
      this.onSubmit();
    })

  }

  private buildForm() {
    this.formGroup = new FormGroup({
      scheduleDateControl: this.scheduleDateControl,
      startTimeControl: this.startTimeControl,
      endTimeControl: this.endTimeControl,
      about: this.about
    });
  }

  onBack() {
    this.store.dispatch(newVisitActions.backToParticipants());
  }

  onSubmit() {
    this.removeError(this.formGroup.controls['endTimeControl'], 'smaller');
    this.removeError(this.formGroup.controls['endTimeControl'], 'pass_visit');

    if (!this.formGroup.valid) {
      this.store.dispatch(newVisitActions.scheduleValid({ valid: false }));
      return;
    }
    const date: Date = this.formGroup.controls['scheduleDateControl'].value;

    let st: string = this.formGroup.controls['startTimeControl'].value;
    let et: string = this.formGroup.controls['endTimeControl'].value;
    let hours = st.split(":", 2)[0];
    let minutes = st.split(":", 2)[1];
    let start_time: Date = new Date(date)
    start_time.setHours(+hours, +minutes);
    hours = et.split(":", 2)[0];
    minutes = et.split(":", 2)[1];
    let end_time: Date = new Date(date);
    end_time.setHours(+hours, +minutes);
    console.log(date, start_time,end_time)
    if (start_time >= end_time) {
      this.formGroup.controls['endTimeControl'].setErrors({ 'smaller': true });
      this.store.dispatch(newVisitActions.scheduleValid({ valid: false }));
      return;
    }

    if (this.dateLessThanNow(end_time)){
      this.formGroup.controls['endTimeControl'].setErrors({ 'pass_visit': true });
      this.store.dispatch(newVisitActions.scheduleValid({ valid: false }));
      return;
    }
    const about = this.formGroup.controls['about'].value;

    this.store.dispatch(newVisitActions.scheduleValid({ valid: this.formGroup.valid }));
    this.store.dispatch(newVisitActions.scheduleTheVisit({ date, start_time, end_time, about }))
  }

  dateLessThanNow(end_time: Date){
      let now = new Date();
      let localoffset = now.getTimezoneOffset();
      this.vmsConfig.MASTER_CONFIG.offset_to_utc;
      var MS_PER_MINUTE = 60000;
      var referenceDate = end_time.valueOf() + 
                          (this.vmsConfig.MASTER_CONFIG.offset_to_utc * MS_PER_MINUTE) -
                          (localoffset * MS_PER_MINUTE);
      let ret = referenceDate < now.valueOf();
      return ret;
  }

  removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }

  getEndTimeMessage() {
    //if (!this.formGroup.controls['eMail']) return "Na";
    if (this.formGroup.controls['endTimeControl'].hasError('required')) {
      return 'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME-REQUIRED';
    }
    if (this.formGroup.controls['endTimeControl'].hasError('smaller')) {
      return 'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME-SMALLER';
    }
    if (this.formGroup.controls['endTimeControl'].hasError('pass_visit')) {
      return 'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME-PASS';
    }
    return this.formGroup.controls['endTimeControl']
      .hasError('pattern') ? 'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME-ERROR' : '--';
  }

  ngOnDestroy(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
  }

}
