import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Site, Tenant } from '@empusa/empusa-core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  url: string = this.environment.urlbase + 'usermanagement/services/';
  urlKFloor: string = this.environment.urlbase + 'smartbuilding/lift/services/security/homefloor/list';
  urlKAccess: string = this.environment.urlbase + 'smartbuilding/lift/services/security/accessrights/list';
  urlRGroup: string = this.environment.urlbase + 'smartbuilding/lift/services/security/accessgroups/list';
  urlLeases: string = this.environment.urlbase + 'smartbilling/services/leases/';
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  addTenant(tenant: Tenant, buildingDN: string) {
    tenant['buildingDN'] = buildingDN;
    let body = JSON.stringify(tenant);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.url + 'tenants/tenant', body, { headers }).pipe(map(resp => resp));
  }

  updateTenant(tenant: Tenant, buildingDN: string) {
    let id = encodeURIComponent(tenant.uid);
    tenant['buildingDN'] = buildingDN;
    let body = JSON.stringify(tenant);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'tenants/' + id, body, { headers }).pipe(map(resp => resp));
  }

  deleteTenant(uid: string) {
    let id = encodeURIComponent(uid);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.delete(this.url + 'tenants/tenant/' + id, { headers }).pipe(map(resp => resp));
  }

  getTenant(uid: string) {
    let id = encodeURIComponent(uid);
    return this.http.get(this.url + 'tenants/' + id).pipe(
      map(data => <Tenant>data)
    );
  }

  getFloor() : any{
    return this.http.get(this.urlKFloor).pipe(
      map(data => data)
    );
  }

  getAccess() : any{
    return this.http.get(this.urlKAccess).pipe(
      map(data => data)
    );
  }

  getAccessGroup() : any{
    return this.http.get(this.urlRGroup).pipe(
      map(data => data)
    );
  }

  getTenantsYardi(uid: string): any {
    return this.http.get(this.urlLeases + uid).pipe(
      map(data => data)
    );
  }
}
