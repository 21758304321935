import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MicroappEpsPaypalListFilter } from './microapp-eps-paypal-list-filter';
import { TariffService } from '../../../../client/tariff.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../lib/services/user.service';
import { EpsServices } from '../../../../lib/services/microapp-eps.service';
import { forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Tenant } from '@empusa/empusa-core';
import { PaypalOptions } from '../../../../lib/model/microapp-eps.paypalConfig.model';
@Component({
  selector: 'empusa-microapp-eps-paypal-list',
  templateUrl: './microapp-eps-paypal-list.component.html',
  styleUrls: ['./microapp-eps-paypal-list.component.css']
})
export class MicroappEpsPaypalListComponent implements OnInit {

  displayedColumns = ['tenant_name', 'paypal_enabled', 'actions'];
  dataSource = new MatTableDataSource([]);
  loading: boolean = true;
  filterGroup: FormGroup;
  userFilter = new MicroappEpsPaypalListFilter;
  listOption: PaypalOptions[] = []
  allowEdit: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public vatForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private tariffService: TariffService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private epsService: EpsServices,
    private userService: UserService) {
    this.vatForm = this.formBuilder.group({
      vat: ['', [Validators.required, Validators.max(100), Validators.min(0), Validators.pattern(/^[0-9]\d*$/)]]
    })

    this.tariffService.getVat().subscribe((resp: any) => {
      if (resp) {
        this.f.vat.setValue(resp.taxes.gst);
      }
    })

    this.viewOptions();
  }

  ngOnInit(): void {
    this.filterGroup = this.buildFilter();
  }

  get f(): any { return this.vatForm.controls; }


  viewOptions() {this.listOption = []
    const tenantListService = this.userService.findAllTenants();
    const tenantOptionsService = this.epsService.paypalTenantConfig();
    forkJoin([tenantListService, tenantOptionsService]).subscribe(results => {
      this.loading = false;
      const tenant_list: Tenant[] = results[0];
      const tenant_options: any[] = results[1] as any[];
      tenant_options.forEach(option => {
        let paypalOption = new PaypalOptions();
        paypalOption.flag_editable = false;
        paypalOption.paypal_enabled = option.paypal_enabled;
        paypalOption.tenant_id = option.tenant_id;
        let tenant = tenant_list.find(tenant => tenant.uid == option.tenant_id);
        paypalOption.tenant_name = tenant.name;
        this.listOption.push(paypalOption)
      });
      let tempDataSource = new MatTableDataSource(this.listOption);
      tempDataSource.sort = this.sort;
      tempDataSource.paginator = this.paginator;
      this.defineDataSourceFilter(tempDataSource);
      this.dataSource = tempDataSource;
      this.loading = false;

    })
  }

  onOptionSelected(element: PaypalOptions, selection) {
    //setting the Option selected
    element.paypal_enabled = selection.value;
  }

  vatIsEdit() {
    this.allowEdit = true;
  }

  editVat() {
    let newVat: number = +this.f.vat.value;
    this.allowEdit = false;
    this.tariffService.putVat(newVat).subscribe((resp: any) => {
      this._snackBar.open(
        this.translate.instant("MAPP-EPS.GENERIC.GST.MESSAGE_OK"),
        this.translate.instant("MAPP-EPS.GENERIC.GST.TITLE_OK")
        , {
          duration: 5000,
        });
    },
      error => {
        this._snackBar.open(
          this.translate.instant("MAPP-EPS.GENERIC.GST.MESSAGE_KO"),
          this.translate.instant("MAPP-EPS.GENERIC.GST.TITLE_KO")
          , {
            duration: 5000,
          });
      }
    );

  }


  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl('')
    })
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: PaypalOptions, filter: string) => {
        const theFilter: MicroappEpsPaypalListFilter = JSON.parse(filter);
        let generalFilter = false;
        let rolFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.tenant_name.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;
        return generalFilter;
      }
  }

  /** set a row editable */
  setEditable(element: PaypalOptions) {
    element.flag_editable = true;
  }

  /** save the changes */
  saveChanges(element: PaypalOptions) {
    this.loading = true;
    this.epsService.updatePaypalTenantConfig(element.tenant_id, element.paypal_enabled).subscribe(result =>{
      element.flag_editable = false;
      this.loading = false;
    })
   

  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }


  options = [
    { op: true, icon:"done" },
    { op: false, icon: "cancel" }
  ]

}




