import { createReducer, on } from '@ngrx/store';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import * as addressBookActions from './microapp-vms-address-book.actions';
import { MicroappVmsVisitor } from '../../model/microapp-vms-visitor.model';
import { MicroappVmsVisitorWithPendingVisits } from '../../model/microapp-vms-visitor-pending-visits.model';


export interface AddressBookState {
    loading: boolean;
    visitors_loaded: boolean;
    filter_visitor: MicroappVmsVisitor;
    visitors: MicroappVmsVisitorWithPendingVisits[];
    visitor_detail_visits: MicroappVmsVisit[];
    error: boolean;
}

const initialState = {
    loading:false,
    visitors_loaded: false,
    filter_visitor: null,
    visitors: null,
    visitor_detail_visits: null,
    error: false
}

const _counterReducer = createReducer(
    initialState,
    on(addressBookActions.resetAddressBook, (state, action) => ({
        ...state,
        loading:false,
        visitors_loaded: false,
        filter_visitor: null,
        visitors: null,
        visitor_detail_visits: null,
        error: false
    })),
    on(addressBookActions.searchingVisitors, (state, action) => ({
        ...state,
        loading:true,
        visitors_loaded: false,
        filter_visitor: action.filter,
        error: false,
    })),
    on(addressBookActions.storeTheVisitors, (state, action) => ({
        ...state,
        loading:false,
        visitors_loaded: true,
        visitors: action.visitors,
    })),
    on(addressBookActions.updateVisitor, (state, action) => ({
        ...state,
        loading:true,
        error: false,
    })),
    on(addressBookActions.visitorUpdated, (state, action) => ({
        ...state,
        visitors: action.visitors,
        loading:false
    })),
    on(addressBookActions.deleteVisitor, (state) => ({
        ...state,
        loading: true,
        error: false,
    })),
    on(addressBookActions.error, (state) => ({
        ...state,
        loading: false,
        error: true,
    })),
    on(addressBookActions.resetError, (state) => ({
        ...state,
        error: false,
    })),

);

export function counterReducer(state, action) {
    return _counterReducer(state, action);
}