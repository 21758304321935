import { Component, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, Site } from '@empusa/empusa-core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Participant } from '../../../../client/dto/participant';
import { User } from '../../../../client/dto/user';
import { UserService } from '../../../../client/user.service';

@Component({
  selector: 'microapp-quiz-edit-participant',
  templateUrl: './microapp-quiz.edit-participant.component.html',
  styleUrls: ['./microapp-quiz.edit-participant.component.css']
})
export class EditParticipantComponent {

  site: Site;
  /** form to validate */
  participantForm: FormGroup;
  /** the participant being edited */

  editParticipants: Array<Participant> = [];
  matcher = new MyErrorStateMatcher();
  external: boolean = false;
  sid: string;
  new: boolean;
  modalType: string;

  /** Table of users */
  displayedUserColumns: string[] = ['givenName', 'surname', 'mail'];
  displayedTenantColumns: string[] = ['name', 'description'];
  dataParticipant = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  dataTenant = new MatTableDataSource<any>([]);
  selectionTenant = new SelectionModel<any>(false, []);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading: boolean;

  constructor(private userService: UserService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<EditParticipantComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any, public translate: TranslateService, private auth: AuthenticationService) {

    this.external = data.external;
    this.sid = data.sid;
    this.new = data.new;
    this.modalType = data.modalType;
    if (!this.external) {
      this.site = auth.getCurrentSite();


      if (this.modalType === 'participants') {
        this.loading = true;
        this.userService.findUsersByBuilding(this.site.dn).subscribe((data: any) => {
          this.loading = false;
          this.dataParticipant = new MatTableDataSource(data.users);
          this.dataParticipant.sort = this.sort;
          this.dataParticipant.paginator = this.paginator;
        });
      } else if (this.modalType === 'tenants') {
        this.loading = true;
        this.userService.getSite(this.site.dn).subscribe((data: any) => {
          this.loading = false;
          this.dataTenant = new MatTableDataSource(data.tenants);
          this.dataTenant.sort = this.sort;
          this.dataTenant.paginator = this.paginator;
        });
      }
    } else {
      this.participantForm = new FormGroup({
        firstname: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email])
      });

      this.p.firstname.setValue(data.firstname);
      this.p.lastname.setValue(data.lastname);
      this.p.email.setValue(data.email);
    }
  }

  // convenience getter for easy access to form fields
  get p(): any {
    return this.participantForm.controls;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    if (!this.external) {
      if (this.modalType === 'participants') {

        // stop here if form is invalid
        if (this.selection.selected.length < 1) {
          return;
        }
        this.selection.selected.forEach(element => {
          let editParticipant: Participant = new Participant();
          editParticipant.new = this.new;
          //this.editParticipant.sid = this.sid;
          editParticipant.sid = element.mail;
          editParticipant.firstname = element.givenName;
          editParticipant.lastname = element.surname;
          editParticipant.email = element.mail;
          editParticipant.tenant_name = element.tenants && element.tenants[0] && element.tenants[0].name ? element.tenants[0].name : '';

          this.editParticipants.push(editParticipant);
        });
        this.dialogRef.close(this.editParticipants);
      } else if (this.modalType === 'tenants') {
        if (this.selectionTenant.selected.length < 1) {
          return;
        }
        this.loading = true;
        this.userService.findUsersByTenant(this.selectionTenant.selected[0].uid).subscribe((data: any) => {
          this.loading = false;
          if (data.success) {
             data.users.forEach(element => {
               let editParticipant: Participant = new Participant();
               editParticipant.new = this.new;
               
               editParticipant.firstname = element.givenName;
               editParticipant.lastname = element.surname;
               editParticipant.sid = element.mail;
               editParticipant.email = element.mail;
               editParticipant.tenant_name = this.selectionTenant.selected[0].name;
               this.editParticipants.push(editParticipant);
             });
          }
          this.dialogRef.close(this.editParticipants);
        });
      }
    } else {
      let editParticipant: Participant = new Participant();
      editParticipant.new = this.new;
      // stop here if form is invalid
      if (this.participantForm.invalid) {
        return;
      }
      //this.editParticipant.sid = this.p.sid.value;
      editParticipant.sid = this.p.email.value;
      editParticipant.firstname = this.p.firstname.value;
      editParticipant.lastname = this.p.lastname.value;
      editParticipant.email = this.p.email.value;

      this.editParticipants.push(editParticipant);
      this.dialogRef.close(this.editParticipants);
    }

  }

  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */
  applyFilter(filterValue: string) {
    this.dataParticipant.filter = filterValue.trim().toLowerCase();
  }
  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */
  applyTenantFilter(filterValue: string) {
    this.dataTenant.filter = filterValue.trim().toLowerCase();
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}