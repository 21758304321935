import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MicroappVmsManageVisitService } from '../../../lib/services/microapp-vms-manage-visit.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'empusa-microapp-vms-check-out-visit',
  templateUrl: './microapp-vms-check-out-visit.component.html',
  styleUrls: ['./microapp-vms-check-out-visit.component.css']
})
export class MicroappVmsCheckOutVisitComponent implements OnInit {

  loading: boolean;
  checkOut$: Subscription;
  visit_id: string;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private manageVisitService: MicroappVmsManageVisitService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.visit_id = data;
  }

  ngOnInit(): void {
  }

  doCheckOut() {
    this.loading = true;
    console.log("Do checkout");
    this.checkOut$ = this.manageVisitService.checkOutVisit(this.visit_id).subscribe(response => {
      this.loading = false;
      this.dialogRef.close(0);
      this.show_operation_done();

    }, error => {
      this.loading = false;
      this.dialogRef.close(1);
      this.show_operation_error();
    })
  }

  close() {
    this.loading = false;
    this.dialogRef.close(2);
  }

  ngOnDestroy(): void {
    if (this.checkOut$)
      this.checkOut$.unsubscribe();
  }

  show_operation_done() {
    this.showMessage("MESSAGE_CODES.GENERIC_OK");
  }

  show_operation_error() {
    this.showMessage("MESSAGE_CODES.GENERIC");
  }

  private showTranslatedMessage(message: string) {
    this.snackBar.open(
      message,
      "X"
      , {
        duration: 5000,
      });
  }

  private showMessage(message: string) {
    this.snackBar.open(
      this.translate.instant(message),
      "X"
      , {
        duration: 5000,
      });
  }

}
