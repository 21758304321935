import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'microapp-fittingout-components-warning-fittingout',
    templateUrl: './microapp-fittingout.components-warning-fittingout.component.html',
    styleUrls: ['./microapp-fittingout.components-warning-fittingout.component.css']
})
export class WarningDialog {
    constructor(
        public dialogRef: MatDialogRef<WarningDialog>,
        @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
        private translate: TranslateService) {
            
    }
}


export interface WarningDialogData {
    title: string;
    question: string;
    icon: string;
}
