import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { FittingoutModule } from '../pages/microapp-fittingout.home.module';
import { FittingoutFakeBackend } from '../fake/fake.backend';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';
import { TranslateService } from '@ngx-translate/core';

export const MICROAPP_NAME: string = "fittingout";
export const MICROAPP_CREATE:string= "create";
export const MICROAPP_UPDATE:string = "update";
export const MICROAPP_RESOLVE:string = "resolve";
export const MICROAPP_FINDALL:string = "find_all";
export const MICROAPP_FINDASIGNED:string = "find_assigned";
export const MICROAPP_FINMINE:string = "find_mine";
export const MICROAPP_ACCESS:string = "access";
//status
export const MICROAPP_STATUS_OPEN = "open";
export const MICROAPP_STATUS_CLOSED = "closed";
export const MICROAPP_STATUS_CLOSED_APPROVED = "approved";
export const MICROAPP_STATUS_CLOSED_REJECTED = "rejected";
export const MICROAPP_TEXT_REOPEN = "reopen";


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappFittingoutModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = this.translate.instant('MAPP-FITTINGOUT.DESCRIPTION');
  icon: string = "library_books";
  page = FittingoutModule;
  homeApp: boolean = false;
  path: string = "fittingout";
  order: number = 5;
  fakebackend: EmpusaFakeBackend = new FittingoutFakeBackend();
 
  constructor(private translationLoader: TranslationLoaderService, private translate: TranslateService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }

}

