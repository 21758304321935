export const locale = {
    "lang": "en",
    "data": {
        "MAPP-NOTIFICATIONS": { //mandatory - microapp context
            "HELLO": "HELLO WORLD GENERIC",
            "TESTLIST":"THIS IS A TEST LIST FROM FAKE DATASOURCE",
            "DESCRIPTION":"Circulars",
            "GENERIC.CLEAN": "Clean Filter",
            "LIST":{
                "HEAD":"Circulars",
                "FILTER":{
                    "FILTER": "Filter",
                    "SEND":"Send To",
                    "CLEAR": "Reset Filters"
                },
                "SEARCH":{
                    "SEARCH":"Search",
                    "SEARCHBY":"Search By",
                    "ALL":"All Circulars"
                },
                "TABLE":{
                    "DATE": "Date",
                    "TITLE": "Subject",
                    "SEND": "Send To",
                    "ACTIONS": "Actions"
                },
                "NEW": "New Circular"
            },
            "NEW":{
                "NEW":"New Circular",
                "SUBJECT": "Subject",
                "SENDTO": "Send to",
                "TO":"To",
                "FILE":"New File",
                "CANCEL":"CANCEL",
                "SEND":"SEND",
                "ERROR":"This field is Required"
            },
            "DETAIL":{
                "DETAIL": "Details of the Circular",
                "DATE": "Date",
                "SUBJECT": "Subject",
                "TO":"To",
                "MESSAGE":"Message Sent"
            },
            "MESSAGE":{
                "OK":{
                    "TITLE":'Operation succeeded',
                    "MESSAGE":'The circular was send correctly',
                },
                "NOT_OK":{
                    "TITLE":'Operation Failed!',
                    "MESSAGE":'An error occurred while trying to send the circular!'
                },
                "FILE":{
                    "OK":{
                        "TITLE":'Operation successful',
                        "MESSAGE":'The circular was sent correctly',
                    },
                    "NOT_OK":{
                        "TITLE":'Operation Failed!',
                        "MESSAGE":'An error ocurred while trying to download the File!'
                    }
                }
            },
            "TOOLTIP":{
                "DETAILS":"Details of the Circular"
            }
        }
    }
}