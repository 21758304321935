import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MicroappFbsResourceModel} from "../../../../../client/dto/microapp-fbs-resource";
import {cloneDeep} from "lodash";
import {MicroappFbsResourceSlotModel} from "../../../../../client/dto/faciliti-slots";
import {SliderSelectionModel, SliderValueModel} from "../../generics/range-slider/range-slider.component";
import * as moment from "moment";
import {SearchDates} from "../../../../../client/microapp-fbs.interfaces";

@Component({
  selector: 'empusa-microapp-fbs-slider-facility-slots',
  templateUrl: './microapp-fbs-slider-facility-slots.component.html',
  styleUrls: ['./microapp-fbs-slider-facility-slots.component.css']
})
export class MicroappFbsSliderFacilitySlotsComponent implements OnInit {
  readonly AVAILABLE_CSS_STYLE = 'background-color: #28a745;'
  readonly UNAVAILABLE_CSS_STYLE = 'background-color: #dc3545;'
  readonly AVAILABLE_COLOR = 'rgba(183, 251, 187, 1)';
  readonly UNAVAILABLE_COLOR = 'rgba(251, 183, 183, 1)';

  @Output() onSelectedPeriod: EventEmitter<SearchDates> = new EventEmitter();
  @Input() initialSliderValues: SliderSelectionModel;
  @Input()
  set facility(facility: MicroappFbsResourceModel) {
    this.updateSlotsToShow(facility);
  }

  slotsToShow: MicroappFbsResourceSlotModel[] = [];
  parsedToSliderValues: SliderValueModel[];
  cssStylesForSlider = this.UNAVAILABLE_CSS_STYLE;

  constructor() { }

  updateSlotsToShow(facility: MicroappFbsResourceModel){
    if (!facility.slots) {
      return;
    }
    this.slotsToShow = [];
    let lastSlot;

    const orderedSlots = cloneDeep(facility.slots).sort((slot1, slot2) => {
      return slot1.startDateTime.getTime() - slot2.startDateTime.getTime();
    });

    orderedSlots.forEach(slot => {
      if (slot && (slot.isBooked || slot.isReservable)){
        this.slotsToShow.push(slot);
      }
      if (slot) {
        lastSlot = slot;
      }
    });
    //Adding last slot to allow select last hour
    const lastSlotClone = cloneDeep(lastSlot);
    lastSlotClone.isReservable = true;
    this.slotsToShow.push(lastSlotClone);
    this.parsedToSliderValues = this.slotsToSliderValueModels(this.slotsToShow);
  }

  slotsToSliderValueModels(slots: MicroappFbsResourceSlotModel[]){
    return slots.map((slot): SliderValueModel => {
      return  {
        value: slot.startDateTime,
        label: moment(slot.startDateTime).format('HH:mm'),
        color: slot.isReservable ? this.AVAILABLE_COLOR : this.UNAVAILABLE_COLOR,
        tooltipLabel: moment(slot.startDateTime).format('HH:mm')
      };

    });

  }

  onSliderSelection(selection: SliderSelectionModel){
    const slotsInSelectedInterval = this.slotsToShow.filter((slot) => {
      return selection.start <= slot.startDateTime && selection.end >= slot.endDateTime;
    });
    let isIntervalReservable = slotsInSelectedInterval.every((slot) => {
      return slot.isReservable;
    });
    isIntervalReservable = isIntervalReservable && (moment(selection.start).minutes() == moment(selection.end).minutes())


    this.cssStylesForSlider = isIntervalReservable ? this.AVAILABLE_CSS_STYLE : this.UNAVAILABLE_CSS_STYLE;

    this.onSelectedPeriod.emit({
      searchStartDate: selection.start,
      searchEndDate: selection.end
    });
  }

  ngOnInit(): void {
  }

}
