<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mcUserList">
                    <mat-card-content class="filtersUserList"> {{'MAPP-USERMANAGEMENT.FILTER.FILTER' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder=" {{'MAPP-USERMANAGEMENT.FILTER.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-USERMANAGEMENT.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z8"
                id="tablaTenantMagList">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLE.NAME' | translate}}</mat-header-cell>
                    <mat-cell matTooltip="{{element.tenant.name}}" matTooltipClass="tooltipName"
                        *matCellDef="let element">
                        {{element.tenant.name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLE.DESCRIPTION' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tenant.description}}</mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="floor_label">
                <mat-header-cell *matHeaderCellDef> {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLE.FLOOR' | translate}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tenant.floor_label}}</mat-cell>
            </ng-container> -->
                <ng-container matColumnDef="billingTenantId">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLE.BILLINGTENANTID' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tenant.billingTenantId}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-USERMANAGEMENT.TOOLTIP.TENANT.EDIT' | translate}}"
                                (click)="createOrEdit(element.tenant.uid)" mat-icon-button>
                                <mat-icon class="icActions">edit</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-USERMANAGEMENT.TOOLTIP.TENANT.DELETE' | translate}}"
                                mat-icon-button (click)="deleteTenant(element.tenant.uid)">
                                <mat-icon class="icActions">delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td class="tdUsersTenant" mat-cell *matCellDef="let element"
                        [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="example-element-description">
                                <mat-table class="tablaExpandTenant" [dataSource]="element.dataSource">
                                    <ng-container matColumnDef="users">
                                        <mat-header-cell *matHeaderCellDef>
                                            {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLES.USER' | translate}}
                                        </mat-header-cell>
                                        <mat-cell class="cellName" *matCellDef="let element">
                                            {{element.user}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="admins">
                                    <mat-header-cell *matHeaderCellDef>  {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLES.ADMIN' | translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.admin}} </mat-cell>
                                </ng-container> -->

                                    <ng-container matColumnDef="managements">
                                        <mat-header-cell *matHeaderCellDef>
                                            {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLES.MANAGEMENT' | translate}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.management}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="total">
                                        <mat-header-cell *matHeaderCellDef>
                                            {{'MAPP-USERMANAGEMENT.TENANTS.LIST.TABLES.TOTAL' | translate}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.total}} </mat-cell>
                                    </ng-container>


                                    <mat-header-row *matHeaderRowDef="displayedColumns2" class="element-colum">
                                    </mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns2;" matRipple
                                        class="element-row">
                                    </mat-row>
                                </mat-table>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns" class="headerRowPri"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"
                    [class.example-expanded-row]="expandedElement === row"
                    (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
        <div class="footer-container">
            <div *ngIf="showBtnNew" class="button-new-container">
                <button (click)="createOrEdit('')" class="extend" mat-fab color="primary" aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'>{{'MAPP-USERMANAGEMENT.TENANTS.LISTS.ACTION.NEW' | translate}}
                    </span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </mat-drawer-container>
</div>