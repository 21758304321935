import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaQuestionDialog, AuthenticationService } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SurveyService } from '../../../client/survey.service';
import { EditSurveyComponent } from '../edit/microapp-quiz.edit-survey.component';
import { MicroappQuizGenericComponent } from '../generic/microapp-quiz-generic.component';
import { MicroappQuitListFilter } from './microapp-quiz.list-survey.component.filter';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Survey } from 'projects/microapp-quiz/src/client/dto/survey';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReportSurveyComponent } from '../report/microapp-quiz.report-survey.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'microapp-quiz-survey-list',
  templateUrl: './microapp-quiz.list-survey.component.html',
  styleUrls: ['./microapp-quiz.list-survey.component.css'],

  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None
})
export class ListSurveyComponent implements OnInit {

  displayedColumns: string[] = ['sid', 'title', 'expires', 'state', 'actions'];
  dataSource = new MatTableDataSource([]);
  loading: boolean = true;
  summaryFilter;
  userFilter = new MicroappQuitListFilter();
  filterValues = {};
  filterGroup: FormGroup;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = paginator;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private surveyService: SurveyService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private translate: TranslateService, private auth: AuthenticationService) {
    //
    /*
    auth.getUser().then(user => {
    this.currentUser = user;
    this.showBtnNew = this.hasRol(['SUPERADMIN','ADMIN','MANAGEMENT']);
    this.viewUsers();
    });
    */

  }

  ngOnInit() {
    this.viewSurvey();
    this.filterGroup = this.buildFilter();
    //we set the datasource of the table
  }

  /**
   * @name viewSurvey
   * @description update survey from the database
   */
  viewSurvey() {
    this.loading = true;
    this.surveyService.allSurvey().subscribe((data) => {
      this.loading = false;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.defineDataSourceFilter(this.dataSource);
      if (this.userFilter) {
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    });
  }

  buildFilter() {

    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      EXPIRE: new FormControl(""),
      STATUS: new FormControl("")
    })
  }

  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * @name deleteSurvey
   * @description delete the current selected Survey
   * @param {string} the Survey id to active
   */
  activeSurvey(sid: string, active: boolean) {
    let title: string = "";
    let question: string = "";
    let confirmation: string = "";
    let errors: string = "";
    let icon: string = "";
    if (active) {
      title = "MAPP-QUIZ.DIALOG.AVTIVE.TITLE";
      question = "MAPP-QUIZ.DIALOG.AVTIVE.QUESTION";
      confirmation = "MAPP-QUIZ.DIALOG.AVTIVE.CONFIRMATION";
      errors = "MAPP-QUIZ.DIALOG.AVTIVE.ERROR";
      icon = "check_box";
    } else {
      title = "MAPP-QUIZ.DIALOG.INACTIVE.TITLE";
      question = "MAPP-QUIZ.DIALOG.INACTIVE.QUESTION";
      confirmation = "MAPP-QUIZ.DIALOG.INACTIVE.CONFIRMATION";
      errors = "MAPP-QUIZ.DIALOG.INACTIVE.ERROR";
      icon = "check_box_outline_blank";
    }

    let dialogRef = this.dialog.open(MicroappQuizGenericComponent, {
      width: '500px',
      data: { title: title, question: question, icon: icon }
    });

    dialogRef.afterClosed().subscribe(result => {
    
      if (result) {
          this.loading = true;
        this.surveyService.activeSurvey(sid, active).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].sid === sid) {
                this.dataSource.data[i].state = active ? 1 : 2;
              }
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant(confirmation),
              this.translate.instant(title), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            this.snackBar.open(this.translate.instant(errors),
              this.translate.instant(title), {
              duration: 5000,
            });
          });
      }
    });
  }

  /**
 * @name resendSurvey
 * @description delete the current selected Survey
 * @param {string} the Survey id to delete
 */
  resendSurvey(sid: string) {
    let dialogRef = this.dialog.open(MicroappQuizGenericComponent, {
      width: '500px',
      data: {
        title: 'MAPP-QUIZ.DIALOG.RESEND.TITLE',
        question: 'MAPP-QUIZ.DIALOG.RESEND.QUESTION',
        icon: 'send'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.surveyService.resendSurvey(sid).subscribe(
          data => {
            this.loading = false;
            this.snackBar.open(this.translate.instant("MAPP-QUIZ.DIALOG.RESEND.CONFIRMATION"),
              this.translate.instant("MAPP-QUIZ.DIALOG.RESEND.TITLE"), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            this.snackBar.open(this.translate.instant("MAPP-QUIZ.DIALOG.RESEND.ERROR"),
              this.translate.instant("MAPP-QUIZ.DIALOG.RESEND.TITLE"), {
              duration: 5000,
            });
          }
        );
      }
    });
  }

  /**
   * @name deleteSurvey
   * @description delete the current selected Survey
   * @param {string} the Survey id to delete
   */
  deleteSurvey(sid: string) {
    let dialogRef = this.dialog.open(MicroappQuizGenericComponent, {
      width: '500px',
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.surveyService.deleteSurvey(sid).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].sid === sid) {
                const data = this.dataSource.data;
                data.splice(i, 1);
                this.dataSource.data = data;
              }
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          }
        );
      }
    });
  }

  createOrEdit(ticket_id: string) {
    let dialogAssign = this.dialog.open(EditSurveyComponent, {
      width: '900px',
      data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.viewSurvey();
    });
  }

  viewReporter(ticket_id: string) {
    let dialogAssign = this.dialog.open(ReportSurveyComponent, {
      width: '1000px',
      data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      console.log("Close Reporter")
    });
  }



  //FILTERS
  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Survey, filter: string) => {
        const theFilter: MicroappQuitListFilter = JSON.parse(filter);
        let generalFilter = false;
        let statusFilter = false;
        let expireDateFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.sid.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.title.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;

        //status filter

        if (theFilter.status >=0) {
          // if (aGroup.state.toString().indexOf(theFilter.status) !== -1) {
          if (aGroup.state == theFilter.status) {
            statusFilter = true;
          }
        } else statusFilter = true;

        //Expire Filter
        if (theFilter.expire) {
          if (theFilter.expire == aGroup.dateExpired) {
            expireDateFilter = true;
          }
        } else expireDateFilter = true;

        return generalFilter && statusFilter && expireDateFilter;
      }

  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStatusFilter(filterValue: number) {
    this.filterValues[filterValue] = filterValue;
    this.userFilter.status = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  applyExpireDateFilter(filterValue: string) {

    const theDate = this.datepipe.transform(filterValue, "dd/MM/yy");
    this.userFilter.expire = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.expire = undefined;
    this.userFilter.status = undefined;
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  chips = [
    { name: 'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.INACTIVE', state: 0 },
    { name: 'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.ACTIVE', state: 1 },
    { name: 'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.EXPIRED', state: 2 }
  ];
}
