<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<!--editting user-->
<h2 class="titleNew" *ngIf="external && sid">{{'MAPP-QUIZ.EDITPARTICIPANT.TITLE' | translate}}</h2>
<!--new user externo-->
<h2 class="titleNew" *ngIf="external && !sid">{{'MAPP-QUIZ.NEWPARTICIPANT.TITLE.EXT' | translate}}</h2>
<!--new user interno-->
<h2 class="titleNew" *ngIf="!external && !sid && modalType === 'participants'">{{'MAPP-QUIZ.NEWPARTICIPANT.TITLE.INT' |
    translate}}</h2>
<!--new user interno-->
<h2 class="titleNew" *ngIf="!external && !sid && modalType === 'tenants'">{{'MAPP-QUIZ.NEWPARTICIPANT.TITLE.TEN' |
    translate}}</h2>

<form [formGroup]="participantForm" *ngIf="external">
    <div>
        <mat-form-field class="formExternalParti">
            <input matInput [errorStateMatcher]="matcher" formControlName="firstname"
                placeholder="{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.FIRSTNAME' | translate}}" name="firstname" required
                [ngClass]="{ 'is-invalid': p.firstname.errors }" mt-autofocus />
            <mat-error *ngIf="participantForm.controls.firstname.hasError('required')">
                {{'MAPP-QUIZ.EDITPARTICIPANT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="formExternalParti">
            <input matInput [errorStateMatcher]="matcher" formControlName="lastname"
                placeholder="{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.LASTNAME' | translate}}" name="lastname" required
                [ngClass]="{ 'is-invalid': p.lastname.errors }" />
            <mat-error *ngIf="participantForm.controls.lastname.hasError('required')">
                {{'MAPP-QUIZ.EDITPARTICIPANT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="formExternalPartiMail">
            <input matInput [errorStateMatcher]="matcher" formControlName="email"
                placeholder="{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.EMAIL' | translate}}" name="email" required
                [ngClass]="{ 'is-invalid': p.email.errors }" />
            <mat-error *ngIf="participantForm.controls.email.hasError('required')">
                {{'MAPP-QUIZ.EDITPARTICIPANT.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
</form>

<div *ngIf="!external && modalType === 'participants'">
    <div class="filter">
        <button type="button"> {{'MAPP-QUIZ.EDITPARTICIPANT.ACTION.FILTER' | translate}}</button>
        <input type="text" (keyup)="applyFilter($event.target.value)" />
        <mat-icon>filter_list</mat-icon>
    </div>
    <table mat-table [dataSource]="dataParticipant" matSort class="mat-elevation-z8" id="participants">
        <ng-container matColumnDef="givenName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.FIRSTNAME' |
                translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.givenName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="surname">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.LASTNAME' |
                translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.surname}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="mail">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.EMAIL' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.mail}}</mat-cell>
        </ng-container>
        <mat-header-row mat-header-row *matHeaderRowDef="displayedUserColumns"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedUserColumns;" (click)="selection.toggle(row)"
            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false">
        </mat-row>
    </table>
    <mat-paginator class="paginator" [pageSizeOptions]="[6]" showFirstLastButtons></mat-paginator>
</div>
<div *ngIf="!external && modalType === 'tenants'">
    <div class="filter">
        <button type="button"> {{'MAPP-QUIZ.EDITPARTICIPANT.ACTION.FILTER' | translate}}</button>
        <input type="text" (keyup)="applyTenantFilter($event.target.value)" />
        <mat-icon>filter_list</mat-icon>
    </div>
    <table mat-table [dataSource]="dataTenant" matSort class="mat-elevation-z8" id="participants">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.FIRSTNAME' |
                translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.EDITPARTICIPANT.FIELD.LASTNAME' |
                translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
        </ng-container>

        <mat-header-row mat-header-row *matHeaderRowDef="displayedTenantColumns"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedTenantColumns;" (click)="selectionTenant.toggle(row)"
            [ngClass]="{hovered: row.hovered, highlighted: selectionTenant.isSelected(row)}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
        </mat-row>
    </table>
    <mat-paginator class="paginator" [pageSizeOptions]="[6]" showFirstLastButtons></mat-paginator>
</div>

<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close (click)="close()">
        {{'MAPP-QUIZ.EDITPARTICIPANT.ACTION.CANCEL' | translate}}
    </button>
    <button class="buttonConfirm" mat-raised-button color="primary" (click)="onSubmit()">
        {{'MAPP-QUIZ.EDITPARTICIPANT.ACTION.ADD' | translate}}
    </button>

</div>