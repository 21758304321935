import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappVmsVisit } from '../../../../lib/model/microapp-vms-visit.model';
import { TranslateService } from '@ngx-translate/core';
import { VmsConfig } from '../../../../lib/microapp-vms-config';
import { VehicleType } from '../../../../lib/model/microapp-vms-vehicle';

@Component({
  selector: 'empusa-microapp-vms-single-visit',
  templateUrl: './microapp-vms-single-visit.component.html',
  styleUrls: ['./microapp-vms-single-visit.component.css']
})
export class MicroappVmsSingleVisitComponent implements OnInit {

  visit: MicroappVmsVisit;
  offset: string;
  available_vehicles: VehicleType[];

  readonly localDateFormat = "dd/MM/yyyy";
  constructor(
    private translate: TranslateService,
    public vmsConfig: VmsConfig,
    @Inject(MAT_DIALOG_DATA) data) {
    this.visit = data;
    this.offset = this.vmsConfig.MASTER_CONFIG.offset;
  }

  ngOnInit(): void {
    this.available_vehicles = this.vmsConfig.MASTER_CONFIG.available_vehicles;

  }


  getVehicleIcon(vehicle_type) {
    const vt = this.available_vehicles.find(v => v.vehicle_code === vehicle_type);
    if (vt) {
      return vt.vehicle_icon
    }
    return "drive_eta"
  }

}
