<!--editting question-->
<h2 class="titleNew" *ngIf="editQuestion.sid">{{'MAPP-QUIZ.EDITQUESTION.TITLE' | translate}}</h2>
<!--new question-->
<h2 class="titleNew" *ngIf="!editQuestion.sid">{{'MAPP-QUIZ.NEWQUESTION.TITLE' | translate}}</h2>

<form class="container"  [formGroup]="questionForm">
    <div>
        <mat-form-field class="fieldsQuestionFormTitle">
            <input matInput [errorStateMatcher]="matcher" formControlName="question"
                placeholder="{{'MAPP-QUIZ.EDITQUESTION.FIELD.QUESTION' | translate}}" name="question" required
                [ngClass]="{ 'is-invalid': q.question.errors }" />
            <mat-error *ngIf="questionForm.controls.question.hasError('required')">
                {{'MAPP-QUIZ.EDITQUESTION.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="fieldsQuestionForm">
            <mat-label>{{'MAPP-QUIZ.EDITQUESTION.FIELD.TYPE' | translate}}</mat-label>
            <mat-select formControlName="type" name="type" required [ngClass]="{ 'is-invalid': q.type.errors }">
                <mat-option value="">{{'MAPP-QUIZ.EDITQUESTION.TYPE.SELECT' | translate}}</mat-option>
                <mat-option value="L">{{'MAPP-QUIZ.EDITQUESTION.TYPE.ONE' | translate}}</mat-option>
                <mat-option value="M">{{'MAPP-QUIZ.EDITQUESTION.TYPE.MULTI' | translate}}</mat-option>
                <!-- <mat-option value="U">{{'MAPP-QUIZ.EDITQUESTION.TYPE.FREE' | translate}}</mat-option> -->
            </mat-select>
            <mat-error *ngIf="questionForm.controls.type.hasError('required')">
                {{'MAPP-QUIZ.EDITQUESTION.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <button *ngIf="questionForm.controls.type.value != '' && questionForm.controls.type.value != 'U'" class="extend"
            mat-raised-button color="primary"  aria-label="FAB new answer" (click)="newAnswer()">
            <mat-icon>add</mat-icon>   {{'MAPP-QUIZ.EDITQUESTION.NEW' | translate}}
        </button>
        <!-- <div class="buttons">
       
    </div> -->
    </div>
   
</form>
<mat-label *ngIf="questionForm.controls.type.value != '' && questionForm.controls.type.value != 'U'">{{'MAPP-QUIZ.EDITQUESTION.FIELD.ANSWERS' | translate}}</mat-label>
<div *ngIf="questionForm.controls.type.value != '' && questionForm.controls.type.value != 'U'" class="list_answers">
    <!-- <mat-label>{{'MAPP-QUIZ.EDITQUESTION.FIELD.ANSWERS' | translate}}</mat-label> -->

    <table mat-table [dataSource]="answers" class="mat-elevation-z8" id="answers">
        <!-- <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.ANSWER.LIST.TABLE.CODE' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.code}}</td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.ANSWER.LIST.TABLE.VALUE' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <input type="text" class="mat-input-element" value="{{element.value}}" maxlength="5"
                    (change)="updateAnswer(element.sid, 2, $event.target.value)" />
            </td>
        </ng-container> -->
        <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.ANSWER.LIST.TABLE.ANSWER' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <input type="text" class="mat-input-element" value="{{element.answer}}"
                    (change)="updateAnswer(element.sid, 3, $event.target.value)" />
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.ANSWER.LIST.TABLE.ACTIONS' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <div class="action">
                    <button mat-icon-button (click)="deleteAnswer(element.sid)">
                        <mat-icon class="icActions">delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedAnswerColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedAnswerColumns;"></tr>
    </table>

    <!-- <div class="buttons">
        <button class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="newAnswer()">
            <mat-icon>add</mat-icon>
        </button>
    </div> -->

</div>
<div mat-dialog-actions class="buttonDetailFault" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close (click)="close()">
        {{'MAPP-QUIZ.EDITQUESTION.ACTION.CANCEL' | translate}}
    </button>
    <button class="buttonConfirm" [disabled]="!questionForm.valid" mat-raised-button color="primary" (click)="onSubmit()">
        {{'MAPP-QUIZ.EDITQUESTION.ACTION.SAVE' | translate}}
    </button>
</div>