import { DTOTarif } from './tarif.dto';

export class DTOTariffPass {
  application_charge_code: string;
  billing_system_charge_code: string;
  description: string;
  id: string;
  tags: string[];
  tarif: DTOTarif;
  tenant_id?: string;

  constructor(
    tarif?: DTOTarif
  ) {
    this.tarif = tarif ? tarif : new DTOTarif();
  }


}
