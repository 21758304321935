<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<h2 class="cabecera">
  <mat-icon class="iconoCabecera">library_books</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-FITTINGOUT.DESCRIPTION' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="centerHeaderNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-FITTINGOUT.LIST.HEAD' | translate}}" >
        <microapp-fittingout-fittingout-list></microapp-fittingout-fittingout-list>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!can_access">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>