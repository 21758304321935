import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-eps.adapter';
import { EpsAssignedChargeType } from './microapp-eps.assigned-charge-types.model';
import { EpsAssignedChargeTypeDB } from './microapp-eps.assigned-charge-types-db.model';


@Injectable({
  providedIn: "root",
})
export class EpsAssignedChargeTypeDBAdapter implements Adapter<EpsAssignedChargeTypeDB> {

  constructor() { };

  adapt(item: EpsAssignedChargeType): EpsAssignedChargeTypeDB {
    let chargeAdapted = new EpsAssignedChargeTypeDB;

    chargeAdapted.vehicle_spottype_reservationtype_id = item.vehicle_spottype_reservationtype_id;
    chargeAdapted.vehicle_type_id = item.vehicle_type.vehicle_type_id;
    chargeAdapted.spot_type_id = item.spot_type.spot_type_id;
    chargeAdapted.reservation_type_id = item.reservation_type.reservation_type_id;
    chargeAdapted.charge_type = item.charge_type;

    return chargeAdapted
  }
}
