<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral" *ngIf="isAdmin || isSuperAdmin">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mcUserList">
                    <mat-card-content class="filtersUserList"> {{'MAPP-USERMANAGEMENT.FILTER.FILTER' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-USERMANAGEMENT.FILTER.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-form-field>
                        <input formControlName="TENANT" matInput (keyup)="applyTenantFilter($event.target.value)"
                            placeholder="Tenant Filter">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label>{{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.ROL' | translate}}</mat-label>
                    <mat-chip-list formControlName="ROL" class="statusChipsFilter" aria-label="statusChipsFilter">
                        <!--[selectable]="true"-->
                        <mat-chip class="chipsRol" *ngFor="let chip of chips" [selected]="chip.name==userFilter.rol"
                            (click)="applyRolFilter(chip.name)">{{chip.map}}</mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <mat-label>Default Host</mat-label>
                    <p></p>
                    <mat-chip *ngFor="let chip of chipsDefaultHost" [selected]="chip.result==userFilter.host"
                        (click)="applyHostFilter(chip.result)">
                        <mat-icon>{{chip.icon}}</mat-icon>
                    </mat-chip>
                    <p></p>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-USERMANAGEMENT.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <div class="checkInHeader">
                <form class="tenantForm" [formGroup]="tenantForm">
                    <mat-form-field *ngIf="isAdmin || isSuperAdmin" class="margen_cajas">
                        <mat-label>Tenants</mat-label>
                        <mat-select formControlName="tenants">
                            <mat-option *ngFor="let tenant of tenantsToSelect" [value]="tenant.uid">
                                {{tenant.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button [disabled]="!tenantForm.valid" mat-flat-button (click)="tenantSelected()"
                        class="buttonSearchTenant" color="primary">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" id="tablaUserMagList">
                <ng-container matColumnDef="mail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.EMAIL' | translate}}</mat-header-cell>
                    <mat-cell matTooltip="{{element.mail}}" matTooltipClass="tooltipMail" *matCellDef="let element">
                        {{element.mail}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fullname">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.FULLNAME' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.givenName}} {{element.surname}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tenantN">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tenant
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.tenantN}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="rol">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.ROL' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-chip-list class="rolChipList" aria-label="rol">
                            <mat-chip class="chipsRol" *ngFor="let rol of element.roles">
                                {{(rol.name).replace("USER","EMPLOYEE").replace("MANAGEMENT","TENANT
                                MANAGER").replace("ADMIN","FACILITY MANAGER").replace("SUPERFACILITY
                                MANAGER","SUPERADMIN")}}</mat-chip>
                        </mat-chip-list>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="defaultHost">
                    <mat-header-cell *matHeaderCellDef>Default Host
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="justify-content: center;">
                        <mat-icon matTooltip="This user is the Default Host" *ngIf="element.default_visitor_host">
                            check_circle</mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-USERMANAGEMENT.USERS.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-USERMANAGEMENT.TOOLTIP.USER.EDIT' | translate}}"
                                (click)="createOrEdit(element.uid)" mat-icon-button>
                                <mat-icon class="icActions">edit</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-USERMANAGEMENT.TOOLTIP.USER.DELETE' | translate}}"
                                mat-icon-button (click)="deleteUser(element.uid)"
                                *ngIf="currentUser.uid != element.uid ">
                                <mat-icon class="icActions">delete</mat-icon>
                            </button>
                            <button matTooltip="Resend e-mail" mat-icon-button (click)="sendNot(element.dn)"
                                *ngIf="currentUser.uid != element.uid ">
                                <mat-icon class="icActions">email</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns" class="headerRowPri"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <div *ngIf="showBtnNew" class="button-new-container">
                <button (click)="createOrEdit('')" class="extend" mat-fab color="primary" aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'> {{'MAPP-USERMANAGEMENT.USERS.LISTS.ACTION.NEW' | translate}}</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </mat-drawer-container>
</div>