import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { Tariff } from './dto/tariff';


@Injectable({
  providedIn: 'root'
})
export class TariffService {

  context: string = "http://bousteadintegration.ddns.net:18080/smartbilling/services/";

    constructor(private http: HttpClient, private auth: AuthenticationService,
        private translate: TranslateService, @Inject('environment') private environment) {
        this.context = this.environment.urlbase + "smartbilling/services/";
    }
    
    getVat() {
        let path: string = `${this.environment.urlbase}smartbilling/services/tarifs/taxes/paypal/eps`;
        return this.http.get(path).pipe(
            map(data => data)
        );
    }

    putVat(vat: Number) {
        let path: string = `${this.environment.urlbase}smartbilling/services/tarifs/taxes/paypal/eps`;
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        
        let vatJ = {
            "gst": vat
        }
        return this.http.put(path, vatJ, { headers }).pipe(
                map(data => data))
    }

}
