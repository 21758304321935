import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Notifications } from './dto/notifications';
import { Observable } from 'rxjs';
import { AuthenticationService, User } from '@empusa/empusa-core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private REST_TRAC_SERVER: string = this.environment.urlbase + 'circulars/services/circulars';
  private urlUser: string = this.environment.urlbase + 'usermanagement/services/';
  // private REST_TRAC_SERVER: string = 'http://localhost:5000/circulars/services/circulars';


  constructor(private http: HttpClient, @Inject('environment') private environment,
    private auth: AuthenticationService) {
  }

  currentDate() {
    let dateNow: string = new Date().getTime().toString();
    return dateNow;
  }

  getUrl(path: string) {
    return this.REST_TRAC_SERVER + path;
  }

  allNotfications(fromDate, toDate): any {
    let params = new HttpParams().set("fromDate",fromDate).set("toDate", toDate);
    let path: string = '/list';
    return this.http.get(this.getUrl(path), {params}).pipe(
      map(data => data)
    );
  }

  createNotification(formData) {
    let path: string = '/new';
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.getUrl(path), formData, { reportProgress: true, observe: 'events', responseType: 'text', params: new HttpParams().set('id', this.currentDate()) }).pipe(map(resp => resp));

  }

  sendNotification(data: Notifications) {
    let path: string = '/send';
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.getUrl(path), JSON.stringify(data), { headers }).pipe(map(resp => resp));

  }

  getNotifications(uid: String): any {
    let path: string = '/circulars/';
    return this.http.get(this.getUrl(path) + uid).pipe(
      map(data => data)
    );
  }

  getDestinataries(uid: String): any {
    let path: string = '/destinataries/';
    return this.http.get(this.getUrl(path) + uid).pipe(
      map(data => data)
    );
  }

  getTenants(uid: String): any {
    let path: string = '/tenants/';
    return this.http.get(this.getUrl(path) + uid).pipe(
      map(data => data)
    );
  }

  getNotificationsByEmail(email: String, fromDate, toDate): any {
    let params = new HttpParams().set("fromDate",fromDate).set("toDate", toDate);
    let path: string = '/email/';
    return this.http.get(this.getUrl(path) + email, {params}).pipe(
      map(data => data)
    );
  }

  getNotificationsByTenant(tenant: string, fromDate, toDate): any {
    let id = encodeURIComponent(tenant);
    let params = new HttpParams().set("fromDate",fromDate).set("toDate", toDate);
    let path: string = '/tenants/name/';
    return this.http.get(this.getUrl(path) + id, {params}).pipe(
      map(data => data)
    );
  }


  upload(formData, ticket_id) {
    let path: string = '/files/add/';
    return this.http.post(this.getUrl(path) + ticket_id, formData, { reportProgress: true, observe: 'events', responseType: 'text', params: new HttpParams().set('id', this.currentDate()) }).pipe(map(resp => (console.log("n"), resp)));
  }

  getFilenames(uid: String): any {
    let path: string = '/files/list/';
    return this.http.get(this.getUrl(path) + uid).pipe(
      map(data => data)
    );
  }

  findUsersByBuilding(uid: string): any {
    return this.http.get(`${this.urlUser}sites/${uid}/users`).pipe(
      map(data => (<any>data).users)
    );
  }

  getSite(uid: String): any {
    return this.http.get(this.urlUser + 'sites/' + uid).pipe(
      map(data => data)
    );
  }

  findUsersByTenant(uid: string): Observable<User[]> {
    let id = encodeURIComponent(uid);
    return this.http.get<any>(`${this.urlUser}tenant/${id}/users`).pipe(
      map(data => data.users as User[])
    );
  }

  downloadFile(ticket_id, filename) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/octect-stream'
      }),
      'responseType': 'blob' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };
    let path: string = '/files/';
    return this.http.get(this.getUrl(path) + ticket_id + '/' + filename, httpOptions).pipe(
      map(data => data)
    );
  }
}