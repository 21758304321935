import { MicroappFbsOwnerModel } from './microapp-fbs-owner';
import { MicroappFbsReservationModel } from './microapp-fbs-reservation';

export class MicroappFbsReservationGroupModel{
    owner?: MicroappFbsOwnerModel;
    startDate: Date;
    endDate: Date;
    title:string;
    description: string;
    resourceName: string;
    reservations: MicroappFbsReservationModel[];
    reservationGroupId: string;
    applied_charge_type: string;
    total_cost:string;   
    extra: any; 
}
