import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmpusaQuestionDialog } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TranslationLoaderService } from '@empusa/empusa-core';
import { locale as english } from '../translations/translation.en';
//import { locale as spanish } from '../translations/translation.es';
import {MatTabsModule} from '@angular/material/tabs';
import { MicroappFacilityBookingComponent } from './microapp-facilitiesbooking.facilitybooking.component';
import { MicroappFacilityBookingListComponent } from './list/microapp-facilitiesbooking.facilitybookinglist.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MicroappFacilitiesbookingManagementComponent } from './management/microapp-facilitiesbooking-management.component';
import { MicroappFbsDeteleResourceComponent } from './management/delete-resource/microapp-fbs-detele-resource.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MicroappFbsResourceDetailComponent } from './common/resource-detail/microapp-fbs-resource-detail.component';
import {MatListModule} from '@angular/material/list';
import { MicroappFbsMyReservationsComponent } from './my-reservations/microapp-fbs-my-reservations.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fbsAppReducer from '../lib/store/microapp-fbs-app-reducer';
import { MicroappFbsEffects } from '../lib/store/microapp-fbs-effects';
import { MicroappFbsReservationDetailComponent } from './common/reservation-detail/microapp-fbs-reservation-detail.component';
import { MicroappFbsReservationDeleteComponent } from './common/reservation-delete/microapp-fbs-reservation-delete.component';
import { MicroappBfsMakeAReservationComponent } from './common/make-a-facility-reservation/microapp-bfs-make-a-reservation.component';
import { MicroappFbsSlotsComponent } from './common/components/slots/facility-slots/microapp-fbs-slots.component';
import { MicroappFbsFacilityDataComponent } from './common/components/facility-data/microapp-fbs-facility-data.component';
import { MicroappFbsFacilityReservationsComponent } from './common/components/facility-reservations/microapp-fbs-facility-reservations.component';
import { MicroAppFbsSlotStatusPipe } from '../lib/pipes/microapp-fbs-slot-status.pipe';
import { MicroAppFbsGroupStatusPipe } from '../lib/pipes/microapp-fbs-group-status.pipe';
import { MicroAppFbsGroupCapacityPipe } from '../lib/pipes/microapp-fbs-group-capacity.pipe';
import { MicroappFbsFacilityListComponent } from './management/facility-list/microapp-fbs-facility-list.component';
import { MicroappFbsNewUpdateResourceComponent } from './management/facility/new-update-resource/microapp-fbs-new-update-resource.component';
import { MicroappFbsSetUnavailableComponent } from './management/facility/set-unavailable/microapp-fbs-set-unavailable.component';
import { MicroappSetAvailableComponent } from './management/facility/set-availabe/microapp-set-available.component';
import { MicroappFbsNewUpdateAccessoryComponent } from './management/accessory/new-update-accessory/microapp-fbs-new-update-accessory.component';
import { MicroappFbsMakeAAccessoryReservationComponent } from './common/make-a-accesory-reservation/microapp-fbs-make-a-accessory-reservation.component';
import { MicroappFbsSetAccessoryAvailableComponent } from './management/accessory/set-available/microapp-fbs-set-accessory-available.component';
import { MicroappFbsSpinnerComponent } from './common/spinner/microapp-fbs-spinner.component';
import { MicroappFbsCheckAvailabilityComponent } from './common/components/check-availability/microapp-fbs-check-availability.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MicroappFbsMessageHandlerService } from '../lib/microapp-fbs-message-handler.service';
import { MicroappFbsReservationConfirmComponent } from './common/components/reservation-confirm/microapp-fbs-reservation-confirm.component';
import { MicroappFbsAccessorySlotsComponent } from './common/components/slots/accessory-slots/microapp-fbs-accessory-slots.component';
import { MicroAppFbsAccessorySlotStatusPipe } from '../lib/pipes/microapp-fbs-accessory-slot-status.pipe';
import {MicroappFbsTariffManagementComponent} from './tariff-management/microapp-fbs-tariff-management.component';
import {MicroappFbsTenantSelectComponent} from './common/components/tenant-select/microapp-fbs-tenant-select.component';
import { EditNewChargeComponent } from './tariff-management/newEdit/microapp-fbs-tariff-newEdit.component';
import { EditTariffTypeComponent } from './tariff-management/editTarifType/microapp-fbs-tariff-editTariffType.component';
import { MicroappFbsNewUpdateResourceGroupComponent } from './management/facility/new-update-resource-group/microapp-fbs-new-update-resource-group.component';
import { SetGroupAvailableComponent } from './management/facility/set-group-available/set-group-available.component';
import { MiniResourceTableComponent } from './common/components/mini-resource-table/mini-resource-table.component';
import { MicroappFbsMakeAFacilityGroupReservationComponent } from './common/make-a-facility-group-reservation/microapp-fbs-make-a-facility-group-reservation.component';
import {MatCarouselModule} from "@ngbmodule/material-carousel";
import {RangeSliderComponent} from "./common/components/generics/range-slider/range-slider.component";
import { MicroappFbsSliderFacilitySlotsComponent } from './common/components/slots/microapp-fbs-slider-facility-slots/microapp-fbs-slider-facility-slots.component';
import { MicroappFbsSliderAccessorySlotsComponent } from './common/components/slots/microapp-fbs-slider-accesory-slots/microapp-fbs-slider-accessory-slots.component';

@NgModule({
  entryComponents: [
    EmpusaQuestionDialog,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatListModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSortModule,
    StoreModule.forRoot(fbsAppReducer.microappVmsReducer), // Declaro el reducer con la clave que quiera
    EffectsModule.forRoot([MicroappFbsEffects]), // Registro las classes con efectos.
    RouterModule.forChild([
      {
        path: '', component: MicroappFacilityBookingComponent,
      },
    ]),
    MatCarouselModule.forRoot(),
  ],
  declarations: [
    MicroappFacilityBookingComponent,
    MicroappFacilityBookingListComponent,
    MicroappFacilitiesbookingManagementComponent,
    MicroappFbsFacilityListComponent,
    MicroappFbsDeteleResourceComponent,
    MicroappFbsNewUpdateResourceComponent,
    MicroappFbsResourceDetailComponent,
    MicroappFbsFacilityDataComponent,
    MicroappFbsFacilityReservationsComponent,
    MicroappFbsMyReservationsComponent,
    MicroappFbsReservationDetailComponent,
    MicroappFbsReservationDeleteComponent,
    MicroappBfsMakeAReservationComponent,
    MicroappFbsSlotsComponent,
    MicroappFbsNewUpdateAccessoryComponent,
    MicroappFbsMakeAAccessoryReservationComponent,
    MicroAppFbsSlotStatusPipe,
    MicroAppFbsAccessorySlotStatusPipe,
    MicroAppFbsGroupStatusPipe,
    MicroAppFbsGroupCapacityPipe,
    MicroappFbsSetUnavailableComponent,
    MicroappSetAvailableComponent,
    MicroappFbsSetAccessoryAvailableComponent,
    MicroappFbsSpinnerComponent,
    MicroappFbsCheckAvailabilityComponent,
    MicroappFbsReservationConfirmComponent,
    MicroappFbsAccessorySlotsComponent,
    MicroappFbsTariffManagementComponent,
    MicroappFbsTenantSelectComponent,
    EditNewChargeComponent,
    EditTariffTypeComponent,
    MicroappFbsNewUpdateResourceGroupComponent,
    SetGroupAvailableComponent,
    MiniResourceTableComponent,
    MicroappFbsMakeAFacilityGroupReservationComponent,
    RangeSliderComponent,
    MicroappFbsSliderFacilitySlotsComponent,
    MicroappFbsSliderAccessorySlotsComponent
  ],
  providers: [
    DatePipe,
    MatSnackBar,
    MicroappFbsMessageHandlerService,
  ],
})
export class FacilityBookingModule {

  constructor(private translationLoader: TranslationLoaderService) {

    //loading micro app translations
    this.translationLoader.loadTranslations(english);

  }

}
