<h2 class="cabecera">
  <mat-icon class="iconoCabecera">directions_car</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-EPS.DESCRIPTION' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="headerFaultNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" (selectedTabChange)="tabChanged($event)">
      <ng-container *ngIf="funcReservation">
        <mat-tab label="{{'MAPP-EPS.TABS.EMPLOYEE' | translate}}">
          <empusa-microapp-eps-make-reservation-stepper mode="employee" *ngIf="activateTab==0">
          </empusa-microapp-eps-make-reservation-stepper>
        </mat-tab>
        <mat-tab label="{{'MAPP-EPS.TABS.VISITOR' | translate}}">
          <empusa-microapp-eps-make-reservation-stepper mode="visitor" *ngIf="activateTab==1">
          </empusa-microapp-eps-make-reservation-stepper>
        </mat-tab>
        <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.RESERVATION' | translate}}">
          <microapp-eps-reservationsTenant-eps-component *ngIf="activateTab==2">
          </microapp-eps-reservationsTenant-eps-component>
        </mat-tab>
      </ng-container>

      <ng-container *ngIf="funcAdmin">
        <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.QUOTES' | translate}}">
          <!-- <microapp-eps-facility-quotes></microapp-eps-facility-quotes>-->
          <empusa-microapp-eps-quotas></empusa-microapp-eps-quotas>
          <ng-template matTabContent>
            <mat-tab-group mat-align-tabs="left" class="microappNavBar">
              <mat-tab class="tabQuota" label="{{'MAPP-EPS.FACILITY.TABS.PARKING' | translate}}">
                <empusa-microapp-eps-quotas-spaces></empusa-microapp-eps-quotas-spaces>
              </mat-tab>
              <mat-tab class="tabQuota" label="{{'MAPP-EPS.FACILITY.TABS.QUOTAS' | translate}}">
                <empusa-microapp-eps-quotas></empusa-microapp-eps-quotas>
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.CHARGESTARIFF' | translate}}">
          <ng-template matTabContent>
            <mat-tab-group mat-align-tabs="center" class="microappNavBar">
              <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.CHARGE_TYPES' | translate}}">
                <empusa-microapp-eps-charge-list></empusa-microapp-eps-charge-list>
              </mat-tab>
              <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.PASSES' | translate}}">
                <empusa-microapp-eps-tariffs-assigned></empusa-microapp-eps-tariffs-assigned>
              </mat-tab>
              <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.TARIFFS' | translate}}">
                <empusa-microapp-eps-tariffs></empusa-microapp-eps-tariffs>
              </mat-tab>
              <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.PAYPAL' | translate}}">
                <empusa-microapp-eps-paypal-list></empusa-microapp-eps-paypal-list>
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'MAPP-EPS.FACILITY.TABS.RESERVATION' | translate}}">
          <microapp-eps-reservationsTenant-eps-component></microapp-eps-reservationsTenant-eps-component>
        </mat-tab>
      </ng-container>

    </mat-tab-group>

    <div class="notAccess" *ngIf="funcNothing">
      <h3>You can´t access to this Module</h3>
    </div>

  </div>
  <router-outlet></router-outlet>
</div>

<!-- <empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay> -->