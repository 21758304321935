<mat-horizontal-stepper linear #stepper (selectionChange)="selectionChange($event)" *ngIf="!storeState.supererror && ((mode == 'visitor' && enabledNonEntitled) || mode == 'employee')" >
  <mat-step [completed]="usersSelectedCompleted">
    <ng-template matStepLabel>{{'MAPP-EPS.TENANT.EMPLOYEE.SELECT_USERS' | translate}}</ng-template>
    <div>
      <empusa-microapp-eps-select-employees *ngIf="mode == 'employee'"></empusa-microapp-eps-select-employees>
      <empusa-microapp-eps-add-visits *ngIf="mode == 'visitor'"></empusa-microapp-eps-add-visits>
    </div>



    <button class="buttonNext" mat-raised-button matStepperNext color="primary" md-direction="right"
      style="float: right;" [disabled]="!usersSelectedCompleted"> {{'MAPP-EPS.TENANT.STEPPER_NEXT' |
      translate}}
    </button>

  </mat-step>

  <mat-step [completed]="parkingPassFilled">
    <ng-template matStepLabel>{{'MAPP-EPS.TENANT.PASS_SELECT' | translate}}</ng-template>
    <div class="stepper-content">
      <empusa-microapp-eps-selectg-reservation-type [mode]="mode" *ngIf='usersSelectedCompleted'>
      </empusa-microapp-eps-selectg-reservation-type>
    </div>
    <button mat-raised-button class="buttonNext" matStepperPrevious> {{'MAPP-EPS.TENANT.STEPPER_BACK' | translate}}
    </button>
    <button class="buttonNext" mat-raised-button matStepperNext color="primary" md-direction="right"
      style="float: right;" [disabled]="!parkingPassFilled"> {{'MAPP-EPS.TENANT.STEPPER_NEXT' | translate}}
    </button>
  </mat-step>

  <mat-step [completed]="reservationDone">
    <ng-template matStepLabel>{{'MAPP-EPS.TENANT.AVAILABILITY' | translate}}</ng-template>
    <div class="stepper-content">
      <empusa-microapp-eps-availability [mode]="mode" *ngIf="usersSelectedCompleted"></empusa-microapp-eps-availability>
    </div>
    <button mat-raised-button class="buttonNext" matStepperPrevious> {{'MAPP-EPS.TENANT.STEPPER_BACK' | translate}}
    </button>
    <button class="buttonNext" mat-raised-button matStepperNext color="primary" md-direction="right"
      style="float: right;" [disabled]="!reservationDone || !someReservationRequestIsCorrectResult">
      <mat-icon>payment</mat-icon>
      {{'MAPP-EPS.TENANT.PAYMENT.PAY' | translate}}
    </button>
  </mat-step>

  <mat-step [completed]="paymentDone">
    <ng-template matStepLabel>{{'MAPP-EPS.TENANT.PAYMENT_STEP' | translate}}</ng-template>
    <div class="stepper-content">
      <empusa-microapp-eps-payment-status [mode]="mode" *ngIf="paymentDone">
      </empusa-microapp-eps-payment-status>
    </div>
    <button mat-raised-button class="buttonNext" matStepperPrevious *ngIf="!allReservationConfirmationOKResult">
      {{'MAPP-EPS.TENANT.STEPPER_BACK' | translate}} </button>
    <button *ngIf="someReservationConfirmationIsOKResult" class="buttonNext" mat-raised-button (click)="stepper.reset()"
      color="primary" md-direction="right" style="float: right;">
      {{'MAPP-EPS.TENANT.NEW_RESERVATION' | translate}}
    </button>
  </mat-step>
</mat-horizontal-stepper>

<div *ngIf="storeState.supererror" class="supererror-container">
  <h1 class="supererror-text">
    {{'MAPP-EPS.GENERIC.SUPERERROR' | translate}}
  </h1>
</div>

<div *ngIf="!storeState.supererror && (mode == 'visitor' && !enabledNonEntitled)" class="supererror-container">
  <h1 class="supererror-text">
    {{'MAPP-EPS.TENANT.DISABLED-VISITOR' | translate}}
  </h1>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
