export const locale = {
    "lang": "en",
    "data": {
        "MAPP-FITTINGOUT": { //mandatory - microapp context
            "HELLO": "HELLO WORLD GENERIC",
            "TESTLIST":"THIS IS A TEST LIST FROM FAKE DATASOURCE",
            "DESCRIPTION":"Fitting Out",
            "INVALID":"Invalid",
            "GENERIC.CLEAN": "Clean Filter",
            "REQUIRED": "Required",
            "NEW":{
                "HEAD":"New Fitting out",
                "CREATED": "Created"
            },
            "TOOLTIP":{
                "COMMENT":"Comments/Files",
                "DETAIL": "Details"
            },
            "EDIT":{
                "HEAD":"Edit Fitting out"
            },
            "LIST":{
                "HEAD":"Fitting Out",
                "DETAIL": "Detail Fitting Out",
                "TABLE":{
                    "ACTIONS":"Actions",
                    "FILTER":"Filter",
                    "CLEAR": "Clear Filters"
                }

            },
            "FITTING":{
                "TITLE":"Title",
                "STATUS":"Status",
                "GROUP":"Group",
                "TYPE":"Type",
                "DATE":"Date",
                "DESCRIPTION":"Description",
                "COMMENT":"Comment",
                "COMMENTS":"Comments",
                "SUMMARY":"Title",
                "REPORTER": "Reporter",
                "STARTDATE":"Start date",
                "ENDDATE":"End date",
                "ADDCOMMENTS": "Add Comments",
                "RESOLVE": "Enter the resolution of the issue.",
                "RESOLUTION": "Resolution",
                "APPROVED":"Approved",
                "CLOSED":"Closed",
                "ADD":{
                    "TEXT":"Assign a comment to this issue and it will be displayed in the log",
                    "LABEL":"Commentary",
                    "ADD":"ADD"
                }
            },
            "FILE":{
                "NEW_FILE":"New file",
                "FILES":"Files",
                "VERSION":{
                    "TITLE":"Warning",
                    "MESSAGE":"Existing file with this name. Do you want to replace?"
                }
            },
            "COMMENT":{
                "COMMENTFILE":"Add Comment & Files",
                "APPROVED":"Approved",
                "REJECTED":"Rejected",
                "VALIDATE":"Reopen",
                "VALIDATEDRAFT": "Draft Validation"
            },
            "UPDATE":{
                "OK":{
                    "TITLE":'Operation Successful',
                    "MESSAGE":'The changes were saved correctly',
                },
                "NOT_OK":{
                    "TITLE":'Operation Failed!',
                    "MESSAGE":'An error ocurred while trying to save the changes!'
                },
                "FILES_OK":{
                    "TITLE":'Operation Successful',
                    "MESSAGE":'The file/s were saved correctly',
                },
                "FILES_NOT_OK":{
                    "TITLE":'Operation Failed!',
                    "MESSAGE":'An error ocurred while trying to save the file/s!'
                },
                "FILES_NOT_OK_DOWNLOAD":{
                    "TITLE":'Operation Failed!',
                    "MESSAGE":'An error ocurred while trying to download the file/s!'
                },
                "NOTHING":{
                    "TITLE":'No changes',
                    "MESSAGE":'No changes to update'
                },
                "CHANGESSAVE":{
                    "TITLE":'Changes to update',
                    "MESSAGE":'Unsaved comments will be lost'
                },
                "FAILLOAD":{
                    "TITLE":'Operation Failed!',
                    "MESSAGE":'An error ocurred while trying to load data'
                },
            },
            "ACTIONS":{
                "CANCEL":"Cancel",
                "SUBMIT":"Submit",
                "RESOLVE":"Resolve",
                "ACCEPT":"Accept"
            },
            "CLEAN":{
                "TITLE": "Are you sure to cancel?",
                "MESSAGE": "Unsaved changes will be lost"
            },
            "COMPONENTS":{
                "RESOLVE":{
                    "TITLE":"Resolve fitting out"
                }
            }
        }
    }
}