import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DTOTarif } from '../../../client/dto/tarif.dto';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TarifsService } from '../../../client/tarifs.service';


@Component({
  selector: 'microapp-tariff-price-tariff-component',
  templateUrl: './microapp-tariff.components-price-tariff.component.html',
  styleUrls: ['./microapp-tariff.components-price-tariff.component.css']
})
export class PriceComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  public newForm: FormGroup;
  allowedTags: Promise<string[]>; //list of allowed tags to be selected
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(public dialogRef: MatDialogRef<PriceComponent>, private formBuilder: FormBuilder,
    private translate: TranslateService, private tarifService: TarifsService,
    @Inject(MAT_DIALOG_DATA) public data: DTOTarif) {

    this.getAllowedTags();
    this.newForm = this.formBuilder.group({
      name:['', Validators.required],
      tags:['', Validators.required],
      units:['', Validators.required],
      price:['', Validators.required],
       })
  }

  /** Obtain all the allowed tags to be selected */
  getAllowedTags() {
    this.allowedTags = this.tarifService.findAllowedTags().toPromise();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  changeNumberUnit() {
    if (this.data.number_of_units != undefined && this.data.unit_price != undefined) {
      this.data.total_price = this.data.number_of_units * this.data.unit_price;
    }
  }

  changeUnitPrice() {
    if (this.data.number_of_units != undefined && this.data.unit_price != undefined) {
      this.data.total_price = this.data.number_of_units * this.data.unit_price;
    }
  }

  /** tag selected event from the auto completion component */
  selectedTag(event: MatAutocompleteSelectedEvent): void {
    let index = this.data.tags.indexOf(event.option.viewValue);
    if (index < 0) {
      this.data.tags.push(event.option.viewValue);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  /** remove a tag from the list of tags */
  removeTag(tag: string) {
    let index = this.data.tags.indexOf(tag)
    this.data.tags.splice(index, 1);
  }

  /** add a tag to the list of tags */
  addTag(tag) {
    if (!this.data.tags) {
      this.data.tags = [];
    }
    this.data.tags.push(tag.value);
    tag.input.value = '';
  }

}
