<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersListFault"> {{'MAPP-QUIZ.SURVEY.FILTERS' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <!-- <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'MAPP-QUIZ.SURVEY.FILTER' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field> -->

                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-QUIZ.SURVEY.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.TITLE' | translate}}</mat-label>
                    <mat-chip-list formControlName="STATUS" class="order" aria-label="statusChipsFilter">
                        <!--[selectable]="true"-->
                        <mat-chip class="chipFilterStatus" *ngFor="let chip of chips"
                            [selected]="chip.state==userFilter.status" (click)="applyStatusFilter(chip.state)">
                            {{chip.name | translate}}</mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <p></p>
                    <mat-form-field>
                        <mat-label>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.EXPIRES' | translate}}</mat-label>
                        <input formControlName="EXPIRE" matInput [matDatepicker]="pickerStart"
                            (dateInput)="applyExpireDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="pickerStart">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>

                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-QUIZ.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>

        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id='primera'>

                <ng-container matColumnDef="sid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.ID' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.sid}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.TITLE' |
                        translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="expires">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.EXPIRES' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.dateExpired}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.TITLE' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-chip *ngIf="element.state == 0" color="primary" selected>
                            {{'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.INACTIVE' | translate}}</mat-chip>
                        <mat-chip *ngIf="element.state == 1" color="accent" selected>
                            {{'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.ACTIVE' | translate}}</mat-chip>
                        <mat-chip *ngIf="element.state == 2" color="warn" selected>
                            {{'MAPP-QUIZ.SURVEY.LIST.TABLE.STATE.EXPIRED' | translate}}</mat-chip>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.SURVEY.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.EDIT' | translate}}"
                                *ngIf="element.state != 2" mat-icon-button (click)="createOrEdit(element.sid)">
                                <mat-icon class="icActions">edit</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.ACTIVATE' | translate}}"
                                *ngIf="element.state == 0" mat-icon-button (click)="activeSurvey(element.sid, true)">
                                <mat-icon class="icActions">timer</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.EXPIRED' | translate}}"
                                *ngIf="element.state == 1" mat-icon-button (click)="activeSurvey(element.sid, false)">
                                <mat-icon class="icActions">timer_off</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.REPORT' | translate}}"
                                *ngIf="element.state != 0" mat-icon-button (click)="viewReporter(element.sid)">
                                <mat-icon class="icActions">pie_chart</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.RESEND' | translate}}"
                                *ngIf="element.state == 1" mat-icon-button (click)="resendSurvey(element.sid)">
                                <mat-icon class="icActions">send</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.SURVEY.DELETE' | translate}}"
                                *ngIf="element.state == 0" mat-icon-button (click)="deleteSurvey(element.sid)">
                                <mat-icon class="icActions">delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <div class="button-new-container">
                <button class="extend" mat-fab color="primary" aria-label="FAB new visit" (click)="createOrEdit('')">
                    <mat-icon>add</mat-icon>
                    <span class='extend_text'>{{'MAPP-QUIZ.SURVEY.NEW' | translate}}</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
            </div>
        </div>
    </mat-drawer-container>

</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>