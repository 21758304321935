import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { SurveyService } from '../../../client/survey.service';
import { Report } from '../../../client/dto/report';

@Component({
  selector: 'microapp-quiz-survey-report',
  templateUrl: './microapp-quiz.report-survey.component.html',
  styleUrls: ['./microapp-quiz.report-survey.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportSurveyComponent implements OnInit {

  report: Report;
  loading: boolean;
  public title: string = "";
  public subtitle: string = "";
  public total: number;
  public completed: number;
  uid: string;
  public charts = [];

  chartLegend = true;
  chartType: ChartType = 'doughnut';
  chartOptions: any = {
    legend: { position: 'top' },
    layout: { padding: { top: 5, bottom: 5 } }
  };
  chartColors: Array<any> = [{
    backgroundColor: ['rgb(11, 61, 113)', 'rgb(18, 94, 175)', 'rgb(118, 221, 251)', 'rgb(219, 236, 248)', 'rgb(239, 239, 239)'],
    borderColor: ['white', 'white', 'white', 'white', 'white']
  }];

  constructor(private activatedRoute: ActivatedRoute, private surveyService: SurveyService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private translate: TranslateService, private auth: AuthenticationService, private location: Location, @Inject(MAT_DIALOG_DATA) data) {
      this.loading = true;
      //getting the user info from backend
      // this.activatedRoute.params.subscribe(params => {
      //   let survey_id: string = params['id'];
      if (data) {
        this.uid = data;
        this.surveyService.getSurvey(data).subscribe(resp => {
          this.loading = false;
          this.title = resp.title;
          this.total = resp.participants.length;
          this.surveyService.reportSurvey(data).subscribe(resp => {
            console.log("Respuesta", resp)
            //this.total = parseInt(resp['total']);
            this.completed = parseInt(resp['completed']);
            let questions = resp['questions'];
            questions.forEach(question => {
  
              let chartLabels: Label[] = [];
              let chartData: number[] = [];
              question['answers'].forEach(answer => {
                let answerTitle :string = answer['title'];
                let sliced : string;
                if(answerTitle.includes("(SQ")){
                  var indexSQ = answerTitle.indexOf( "(SQ" ); 
                  sliced = answerTitle.slice(0, indexSQ); 
                } else if(answerTitle.includes("(AO")){
                  var indexAO = answerTitle.indexOf( "(AO" ); 
                  sliced = answerTitle.slice(0, indexAO); 
                } else{
                  sliced = answer['title'];
                }
                chartLabels.push(sliced)
                chartData.push(parseInt(answer['count']));
              });
  
              let chart = {
                'title': question['title'],
                'chartData': chartData,
                'chartLabels': chartLabels,
                'chartType': this.chartType,
                'chartLegend': this.chartLegend,
                'chartOptions': this.chartOptions,
                'chartColors': this.chartColors
              };
              this.charts.push(chart);
            });
          });
        });  
    }
  }

  ngOnInit() {
  }

  downloadReport() {
    this.loading = true;
    this.surveyService.downloadReportSurvey(this.uid).subscribe((report) => {
       const linkSource = 'data:application/pdf;base64,' + report;
        const downloadLink = document.createElement("a");
        const fileName = this.uid + "_" + this.title+".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loading = false;
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-QUIZ.DOWNLOAD.KO.MESSAGE"),
          this.translate.instant("MAPP-QUIZ.DOWNLOAD.KO.TITLE")
          , {
            duration: 5000,
          });
      });
  }
}
