<mat-drawer-container class="drawContainer">
    <div class="header">
        <div>
            <mat-form-field class="fieldInputSearch">

                <input matInput (keyup)="applyFilterToEmployees($event.target.value)" placeholder="Search">

                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <button mat-button class="buttonReload" (click)="reload()">
                <mat-icon matSuffix>autorenew</mat-icon>
            </button>
        </div>
    </div>

    <!-- EMPLOYEES TABLE -->
    <table mat-table matSort [dataSource]="usersDataSource" class="selection_step_table mat-elevation-z8">
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <!--
                <mat-checkbox color='primary' (change)="$event ? masterToggle() : null"
                    [checked]="userSelection.hasValue() && areaAllEmployeesSelected()"
                    [indeterminate]="userSelection.hasValue() && !areaAllEmployeesSelected()"
                    (change)="changeSelection()">
                </mat-checkbox>
                &nbsp;{{'MAPP-EPS.TENANT.EMPLOYEE.TABLE.SELECT_ALL' | translate}}
            -->
                &nbsp;
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox color='primary' (click)="$event.stopPropagation()"
                    (change)="$event ? userSelection.toggle(row) : null; changeSelection()"
                    [checked]="userSelection.isSelected(row)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell mat-sort-header *matHeaderCellDef>{{'MAPP-EPS.TENANT.EMPLOYEE.TABLE.NAME' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.givenName}} {{element.surname}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mail">
            <mat-header-cell mat-sort-header *matHeaderCellDef>{{'MAPP-EPS.TENANT.EMPLOYEE.TABLE.EMAIL' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.mail}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttonEmplo">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button class="action" mat-icon-button (click)="showReservations(element)"
                    matTooltip="{{'MAPP-EPS.TENANT.EMPLOYEE.CHECK_RESERVATIONS' | translate}}">
                    <mat-icon class="icActions">confirmation_number</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="employeeDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: employeeDisplayedColumns;"></mat-row>

    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
</mat-drawer-container>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>