
import { createAction, props } from '@ngrx/store';

import { MicroappVmsVisitVisitor } from '../model/microapp-vms-manage-group-visit-visitor.model';
import { MicroappVmsVisitWithVisitors } from '../model/microapp-vms-manage-group-visit.model';
import { User, Site, Tenant } from '@empusa/empusa-core';


export const resetNewVisit = createAction(
    '[Microapp vms new visit] resetNewVisit');

export const startNewVisit = createAction(
    '[Microapp vms new visit] Loading visitors',
    props<{ site: Site, tenant: Tenant, host: User, user: User}>());

export const loadVisitorsForSelection = createAction(
    '[Microapp vms new visit] loadVisitorsForSelection',
    props<{ visit: MicroappVmsVisitWithVisitors }>());

export const updateAVisit = createAction(
    '[Microapp vms new visit] updateAVisit',
    props<{ visit: MicroappVmsVisitWithVisitors }>());

export const updateDone = createAction(
    '[Microapp vms new visit] updateDone',
    props<{ message_code: string }>());

export const performVisitUpdate = createAction(
    '[Microapp vms new visit] performVisitUpdate',
    props<{ off_set_to_utc: number }>());


export const visitorsLoaded = createAction(
    '[Microapp vms new visit] Visitors loaded',
    props<{ visit: MicroappVmsVisitWithVisitors, tenantVisitors: MicroappVmsVisitVisitor[] }>());

export const showNewParticipant = createAction(
    '[Microapp vms new visit] showNewParticipant');

export const hideNewParticipant = createAction(
    '[Microapp vms new visit] hideNewParticipant');

export const newParticipant = createAction(
    '[Microapp vms new visit] newParticipant');

export const emailRepeated = createAction(
    '[Microapp vms new visit] emailRepeated');

export const addingAParticipant = createAction(
    '[Microapp vms new visit] Adding a participant',
    props<{ visitor: MicroappVmsVisitVisitor }>());

export const deleteVisitorForSelection = createAction(
    '[Microapp vms new visit] deleteVisitorFromSelection',
    props<{ visitors: MicroappVmsVisitVisitor[] }>());

export const updateVisitorForSelection = createAction(
    '[Microapp vms new visit] updateVisitorForSelection',
    props<{ availableTenantVisitors: MicroappVmsVisitVisitor[] }>());

export const deletingAParticipant = createAction(
    '[Microapp vms new visit] deletingAParticipant',
    props<{ visitor: MicroappVmsVisitVisitor }>());

export const backToParticipants = createAction(
    '[Microapp vms new visit] back to participants');

export const complimentaryParkingRequest = createAction(
    '[Microapp vms new visit] complimentaryParkingRequest',
    props<{ visitor: MicroappVmsVisitVisitor, c_parking: boolean }>());

export const complimentaryParkingRequest4All = createAction(
    '[Microapp vms new visit] complimentaryParkingRequest4All',
    props<{ c_parking: boolean }>());

export const updateVisitAndVisitors = createAction(
    '[Microapp vms new visit] UpdateVisitAndVisitors',
    props<{ visit: MicroappVmsVisitWithVisitors }>());

export const participantListUpdated = createAction(
    '[Microapp vms new visit] Participant Added',
    props<{ visitWithParticipants: MicroappVmsVisitWithVisitors, tenantVisitors: MicroappVmsVisitVisitor[] }>());

export const goToSchedule = createAction(
    '[Microapp vms new visit] goToSchedule');

export const scheduleValid = createAction(
    '[Microapp vms new visit] scheduleStatus',
    props<{ valid: boolean }>());

export const scheduleTheVisit = createAction(
    '[Microapp vms new visit] Scheduling the visit',
    props<{ date: Date, start_time: Date, end_time: Date, about: string }>());

export const confirmTheNewVisit = createAction(
    '[Microapp vms new visit] confirmTheNewVisit');

export const visitStored = createAction(
    '[Microapp vms new visit] visitStored',
    props<{ visit: MicroappVmsVisitWithVisitors, message_code: string }>());

export const error = createAction(
    '[Microapp vms new visit] error');

export const resetError = createAction(
    '[Microapp vms new visit] resetError');

export const resetMessageCode = createAction(
    '[Microapp vms new visit] resetMessageCode');