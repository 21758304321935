
<!-- <a class="imageButton" mat-fab aria-label="Visitor Image">
    <mat-icon>person</mat-icon>
  </a> -->
  <empusa-microapp-vms-tool-bar-single-visit ></empusa-microapp-vms-tool-bar-single-visit>

  
  <mat-dialog-content class="contentVisitorDetail">
    <empusa-microapp-vms-single-visit *ngIf="displayLog === false"></empusa-microapp-vms-single-visit>
    <empusa-microapp-vms-visit-log *ngIf="displayLog === true"></empusa-microapp-vms-visit-log> 

  </mat-dialog-content>
  
  <mat-dialog-actions *ngIf="displayLog === true" align="start">
    <button class="buttonBack" (click)="closeVisitLog()" mat-button>
      <mat-icon>arrow_back</mat-icon> <mat-label>BACK</mat-label>
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="displayLog === false && this.visit.log" align="end">
    <button class="buttonLog" (click)="displayVisitLog()" mat-button>
      <mat-icon>subject</mat-icon> <mat-label>LOG</mat-label>
    </button>
   </mat-dialog-actions>