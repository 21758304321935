export class Feedback {
    sid: string;
    user: string;
    date: string;
    type: number;
    state: number;
    anonymized: boolean;
    feedback: string;
    readBy: string;

    constructor() {
    }
}
