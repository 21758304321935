import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";
import { EpsParkingSize } from "../model/microapp-eps.parking-size.model";

export function maxValueValidator(maxValue: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const ctlValue = control.value;
        return ctlValue > maxValue ? { maxValue: { value: control.value } } : null;
    };
}



export function newParkingSizeMinValueValidator(epsParkingSize: EpsParkingSize): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        if (group.controls == undefined)
            return

        Object.keys(group.controls).forEach(key => {
            var splitted = key.split("#", 2);
            const prefix = splitted[0];
            const vid = Number(splitted[1]);
            if (prefix == 'vt') {
                const control = group.controls['vt#' + vid];
                control.setErrors(null);
                const number_of_spots = Number(control.value);
                const controlOversell = group.controls['oversell#' + vid];
                if (controlOversell == undefined) {
                    //still not created
                    return
                }
                const oversell_ratio = Number(controlOversell.value);
                // %
                // const totalSpotsRequest = number_of_spots + Math.floor(number_of_spots * oversell_ratio / 100);
                // suma directa
                const totalSpotsRequest =  number_of_spots + oversell_ratio
                const ps = epsParkingSize.vehicle_type.find(vt => vt.vehicle_type_id == vid)
                if (ps == undefined) {
                    control.setErrors({ notFound: true });
                }
                 // %
                // const actualAssigned = ps.number_of_spots + Math.floor(ps.number_of_spots * ps.oversell_ratio / 100) - ps.can_assign;
                // suma directa
                const actualAssigned = (ps.number_of_spots + ps.oversell_ratio) - ps.can_assign
                if (totalSpotsRequest < actualAssigned) {
                    control.setErrors({ underAssign: true });
                }
            }
        });
        return;
    };
}