/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {APPLICATION_MODULE_PROVIDERS as Éµangular_packages_core_core_s,_iterableDiffersFactory as Éµangular_packages_core_core_p,_keyValueDiffersFactory as Éµangular_packages_core_core_q,_localeFactory as Éµangular_packages_core_core_r,zoneSchedulerFactory as Éµangular_packages_core_core_t} from './src/application_module';
export {_appIdRandomProviderFactory as Éµangular_packages_core_core_g} from './src/application_tokens';
export {DefaultIterableDifferFactory as Éµangular_packages_core_core_n} from './src/change_detection/differs/default_iterable_differ';
export {DefaultKeyValueDifferFactory as Éµangular_packages_core_core_o} from './src/change_detection/differs/default_keyvalue_differ';
export {DebugElement__PRE_R3__ as Éµangular_packages_core_core_l,DebugNode__PRE_R3__ as Éµangular_packages_core_core_k,getDebugNodeR2__PRE_R3__ as Éµangular_packages_core_core_m} from './src/debug/debug_node';
export {isForwardRef as Éµangular_packages_core_core_a} from './src/di/forward_ref';
export {NullInjector as Éµangular_packages_core_core_c,injectInjectorOnly as Éµangular_packages_core_core_b} from './src/di/injector_compatibility';
export {ReflectiveInjector_ as Éµangular_packages_core_core_d} from './src/di/reflective_injector';
export {ReflectiveDependency as Éµangular_packages_core_core_e,resolveReflectiveProviders as Éµangular_packages_core_core_f} from './src/di/reflective_provider';
export {USD_CURRENCY_CODE as Éµangular_packages_core_core_u} from './src/i18n/localization';
export {getModuleFactory__PRE_R3__ as Éµangular_packages_core_core_j} from './src/linker/ng_module_factory_loader';
export {SCHEDULER as Éµangular_packages_core_core_x} from './src/render3/component_ref';
export {injectAttributeImpl as Éµangular_packages_core_core_y} from './src/render3/di';
export {TElementNode as Éµangular_packages_core_core_be} from './src/render3/interfaces/node';
export {LView as Éµangular_packages_core_core_bp} from './src/render3/interfaces/view';
export {pureFunction1Internal as Éµangular_packages_core_core_bf,pureFunction2Internal as Éµangular_packages_core_core_bg,pureFunction3Internal as Éµangular_packages_core_core_bh,pureFunction4Internal as Éµangular_packages_core_core_bi,pureFunctionVInternal as Éµangular_packages_core_core_bj} from './src/render3/pure_function';
export {getBindingRoot as Éµangular_packages_core_core_bc,getLView as Éµangular_packages_core_core_ba,getPreviousOrParentTNode as Éµangular_packages_core_core_bb,instructionState as Éµangular_packages_core_core_z,nextContextImpl as Éµangular_packages_core_core_bd} from './src/render3/state';
export {getRootContext as Éµangular_packages_core_core_bq} from './src/render3/util/view_traversal_utils';
export {createElementRef as Éµangular_packages_core_core_h,createTemplateRef as Éµangular_packages_core_core_i} from './src/render3/view_engine_compatibility';
export {getUrlSanitizer as Éµangular_packages_core_core_bk} from './src/sanitization/sanitization';
export {noSideEffects as Éµangular_packages_core_core_bo} from './src/util/closure';
export {makeParamDecorator as Éµangular_packages_core_core_bl,makePropDecorator as Éµangular_packages_core_core_bm} from './src/util/decorators';
export {getClosureSafeProperty as Éµangular_packages_core_core_bn} from './src/util/property';
export {_def as Éµangular_packages_core_core_v} from './src/view/provider';
export {DebugContext as Éµangular_packages_core_core_w} from './src/view/types';