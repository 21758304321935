<h4 mat-dialog-title>
    <mat-icon>{{data.icon}}</mat-icon>{{data.title | translate}}
</h4>
<div mat-dialog-content>
    <h3>{{data.question | translate}}</h3>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button color="basic" [mat-dialog-close]="false">{{'MAPP-FITTINGOUT.ACTIONS.CANCEL' | translate}}</button>
    <button mat-stroked-button color="primary" class="accept" [mat-dialog-close]="true" cdkFocusInitial>{{'MAPP-FITTINGOUT.ACTIONS.ACCEPT' | translate}}</button>
  
</div>