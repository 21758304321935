import { Observable, of, from, throwError } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmpusaFakeBackend, Tenant } from '@empusa/empusa-core';
import { MICROAPPFAKEDATABASE } from './fake.database';
import { DTOTarif } from '../client/dto/tarif.dto';
import { User } from '@empusa/empusa-core';

/**
 * EPS Fake Backend
 */
export class TariffFakeBackend extends EmpusaFakeBackend {
    microappFakeData = MICROAPPFAKEDATABASE;

    handleRoute(url: string, method: string, headers, body, database: any): Observable<HttpEvent<any>> {
        switch (true) {
            case url.endsWith('tarifs') && method === 'POST':
                return this.getTarifs(database);
            case url.endsWith('allowedtags') && method === 'GET':
                return this.getAllowedTags(database);
            case url.match(/\/tarifs\/.*$/) && method === 'PUT':
                return this.updateTarif(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('tarif') && method === 'POST':
                return this.createTarif(database, body);
            case url.match(/\/tarifs\/.*$/) && method === 'DELETE':
                return this.deleteTarif(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('tarifs/filter/tag') && method === 'POST':
                return this.getTarifs(database);
            default:
                //otherwise, we don't handle this request
                return null;
        }

    }

    getTarifs(database): Observable<HttpResponse<any>> {
        let result: DTOTarif[] = [];
        //creating generics object
        database.tarifs.forEach((x) => {
            let tarif: DTOTarif = new DTOTarif();
            Object.assign(tarif, x);
            result.push(tarif);
        });
        return EmpusaFakeBackend.ok({ 'tarifs': result });
    }

    getAllowedTags(database): Observable<HttpResponse<any>> {
        let result: string[] = [];
        //creating generics object
        return EmpusaFakeBackend.ok({ 'tags': database.allowedtags });
    }

    updateTarif(database, body, sid) {
        let tarif: DTOTarif = JSON.parse(body);
        database.tarifs = database.tarifs.map(x => (x.tarif_id == sid ? tarif : x));
        return EmpusaFakeBackend.ok(body);
    }

    createTarif(database, body) {
        let tarif: DTOTarif = JSON.parse(body);
        tarif.tarif_id = "" + Math.random() * 100;
        database.tarifs.push(tarif);
        return EmpusaFakeBackend.ok(body);
    }

    deleteTarif(database, body, sid) {
        let index = database.tarifs.indexOf(tarif => tarif.tarif_id == sid);
        database.tarifs.splice(index, 1);
        return EmpusaFakeBackend.ok(body);
    }

}