import { Feedback } from './dto/feedback';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  url: string = this.environment.urlbase + 'quiz/services/';

  // url: string = 'http://localhost:5000/quiz/services/';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  allFeedback(): any {
    return this.http.get(this.url + 'feedbacks').pipe(
      map((data: Feedback[]) => {
        console.log(data);
        for (let i in data){

          const dt = this.createUTCDate(new Date(data[i].date));

          const newDateString = `${
            dt.getFullYear().toString().padStart(4,'0')}-${
            (dt.getMonth() + 1).toString().padStart(2,'0')}-${
            dt.getDate().toString().padStart(2,'0')} ${
            dt.getHours().toString().padStart(2,'0')}:${
            dt.getMinutes().toString().padStart(2,'0')}:${
            dt.getSeconds().toString().padStart(2,'0')}`
          data[i].date = newDateString;
        }
        console.log(data);
        return data;
      })
    );
  }

  readFeedback(sid: string, mail:string): any {
    let body = JSON.stringify(mail);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'feedbacks/read/' + sid, body, { headers }).pipe(map(resp => resp));
  }

  deleteFeedback(sid: string): any {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.delete(this.url + 'feedbacks/' + sid, { headers }).pipe(map(resp => resp));
  }

  createUTCDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

}
