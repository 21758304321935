import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { FbsConfig } from '../microapp-facilitiesbooking-config';


@Pipe({
    name: 'groupCapacity'
})
export class MicroAppFbsGroupCapacityPipe implements PipeTransform {

    constructor(private fbsConfig: FbsConfig,
        private translate: TranslateService) { }

    transform(group: MicroappFbsResourceGroup, bookInfo: boolean = false): string {

        if (group.singleResourceId || group.type == this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP) {
            if (group.maxParticipants == 1) {
                return 'MAPP-FBS-GROUP-CAPACITY-PIPE.PARTICIPANT';

            }
            return "MAPP-FBS-GROUP-CAPACITY-PIPE.PARTICIPANTS";
        };

        let txt: string = "MAPP-FBS-GROUP-CAPACITY-PIPE.UNITS";
        if (bookInfo) {
            if (group.availableResourcesToBook == 0) {
                txt = 'MAPP-FBS-GROUP-CAPACITY-PIPE.NO_UNITS_AVAILABLE';
            } else {
  /*              txt = this.translate.instant('MAPP-FBS-GROUP-CAPACITY-PIPE.CAN_BOOK')  
                    + " " + group.availableResourcesToBook + " "
                    + this.translate.instant('MAPP-FBS-GROUP-CAPACITY-PIPE.FROM')
                    + " " + group.availableResources;
                    */
                   txt = 'MAPP-FBS-GROUP-CAPACITY-PIPE.MIX';
            }
        }
        return txt;
    }
}