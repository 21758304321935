import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { DTOTarif } from './dto/tarif.dto';

@Injectable({
    providedIn: 'root',
})
export class TarifsService {
    context: string = "http://bousteadintegration.ddns.net:18080/smartbilling/services/";

    constructor(private http: HttpClient, private auth: AuthenticationService,
        private translate: TranslateService, @Inject('environment') private environment) {
        this.context = this.environment.urlbase + "smartbilling/services/";
    }


    /**
     * @CHECKED
     * Get all the EPS Tarifs
     */
    findHVACTarifs(): Observable<DTOTarif[]> {
        let filter = {
            "tags": ["HVAC"],
            "add_default": "true"
        }
        let body = JSON.stringify(filter);
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });

        let url = `${this.environment.urlbase}smartbilling/services/tarifs/filter/tag`;
        return this.http.post(url, body, { headers }).pipe(
            map(resp => (<any>resp).tarifs)
        );
    }

 


}