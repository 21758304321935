<div *ngIf="reservationsDataSource">
   <table mat-table [dataSource]="reservationsDataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="startDate">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
         <td mat-cell *matCellDef="let element"> {{element.startDate | date:'M/d/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
         <td mat-cell *matCellDef="let element"> {{element.endDate | date:'M/d/yy, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="title">
         <th mat-header-cell *matHeaderCellDef>Title</th>
         <td mat-cell *matCellDef="let element">{{element.title}}</td>
      </ng-container>
      <ng-container matColumnDef="description">
         <th mat-header-cell *matHeaderCellDef>Description</th>
         <td mat-cell *matCellDef="let element">{{element.description}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
         <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
         <mat-cell *matCellDef="let reservation">
            <mat-icon (click)="reservation_detail(reservation)">account_box</mat-icon>
            <mat-icon (click)="reservation_update(reservation)">edit</mat-icon>
            <mat-icon (click)="reservation_delete(reservation)">delete</mat-icon>
         </mat-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </table>
   <mat-paginator [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
</div>