import { VehicleType } from "./microapp-vms-vehicle"

export class VmsMasterConfiguration {
    check_in_mode: string
    request_license_plate: boolean
    offset: string
    timezone: string
    offset_to_utc: number
    first_day_week: number
    localTimeZone: string
    visitor_mandatory_fields: string[]
    parking_active: boolean = false
    temperature_units: string
    available_vehicles: VehicleType[]
}
