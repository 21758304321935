
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService, User, Tenant } from '@empusa/empusa-core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    /** the base url */
    url: string = this.environment.urlbase + 'usermanagement/services/';

    constructor(private http: HttpClient,
        @Inject('environment') private environment,
        private auth: AuthenticationService) {
    }

    /**
     * Find all the users for a certain building
     * @param uid building uid
     */
    findUsersByBuilding(uid: string): Observable<User> {
        return this.http.get(`${this.url}sites/${uid}/users`).pipe(
            map(data => (<any>data).users)
        );
    }

    /**
     * Find all the users for a certain tenant
     */
    async findUsersByTenant(): Promise<User[]> {
        let user = await this.auth.loadCurrentUser();
        let id = encodeURIComponent(user.sites[0].tenants[0].uid);
        let httpget = this.http.get(`${this.url}tenant/${id}/users`).pipe(
            map(data => (<any>data).users)
        );
        return <Promise<User[]>>httpget.toPromise();
        /*.subscribe(users => {
            return users;
        });*/
    }

    /**
     * Retrieve all the tenants for the current site
     */
    async findAllTenants(): Promise<Tenant[]> {
        let currentSite = await this.auth.loadCurrentSite();
        let httpget = this.http.get(`${this.url}sites/${currentSite.uid}`).pipe(
            map(data => (<any>data).tenants)
        );
        return httpget.toPromise();
    }

    getUser(uid: string): any {
        return this.http.get(this.url + 'users/user/' + uid).pipe(
          map(data => (<any>data).userInfo)
        );
      }
}
