export class Fitting {
    ticket_id: string;
    status: string;
    priority: string;
    owner: string;
    date: string;
    summary: string;
    description: string;
    reporter: string;
    type: string;
    comment:string;
    user:string;
    email:string;
    resolution:string;
    
    constructor() {
    }
}
