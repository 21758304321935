import { Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MicroappFbsUserModel } from '../../client/dto/microapp-fbs-user';
import * as microappFbsState from '../../lib/store/microapp-fbs-app-reducer';
import * as fbsActions from '../../lib/store/microapp-fbs-actions';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MicroappFbsReservationDeleteComponent } from '../common/reservation-delete/microapp-fbs-reservation-delete.component';
import { MicroappFbsReservationDetailComponent } from '../common/reservation-detail/microapp-fbs-reservation-detail.component';
import { MicroappFbsReservationGroupModel } from '../../client/dto/microapp-fbs-reservation-group';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MicroappBfsMakeAReservationComponent } from '../common/make-a-facility-reservation/microapp-bfs-make-a-reservation.component';
import { selectResourceGroups, selectResources } from '../../lib/store/microapp-fbs-selectors';
import { take } from 'rxjs/operators';
import { SearchDates } from '../../client/microapp-fbs.interfaces';
import { MicroappFbsMakeAAccessoryReservationComponent } from '../common/make-a-accesory-reservation/microapp-fbs-make-a-accessory-reservation.component';
import { MatPaginator } from '@angular/material/paginator';
import { MicroappFbsMyReservationsFilter } from './microapp-fbs-my-reservations.filter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { SiteService } from '../../client/site.service';
import { MicroappFbsMakeAFacilityGroupReservationComponent } from '../common/make-a-facility-group-reservation/microapp-fbs-make-a-facility-group-reservation.component';


@Component({
  selector: 'empusa-microapp-fbs-my-reservations',
  templateUrl: './microapp-fbs-my-reservations.component.html',
  styleUrls: ['./microapp-fbs-my-reservations.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappFbsMyReservationsComponent implements OnInit, OnDestroy {
  fbsUser: MicroappFbsUserModel;
  fbsUserSubscription: Subscription;
  public reservationsDataSource: MatTableDataSource<MicroappFbsReservationGroupModel>;
  displayedColumns: string[] = ['resourceName', 'title', 'date', 'startDate', 'endDate'];
  allResources: MicroappFbsResourceGroup[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser: User;
  tenantsToSelect = [];
  selectedTenantControl = new FormControl(undefined);
  myBookingList: MicroappFbsReservationGroupModel[] = [];
  //Form
  formGroup: FormGroup;
  start = new FormControl();
  end = new FormControl();

  now = new Date();

  maxDate: Date;

  expandedElement: MicroappFbsReservationGroupModel;
  allRowsExpanded: boolean = false;
  userFilter: MicroappFbsMyReservationsFilter = new MicroappFbsMyReservationsFilter();

  coin: string;
  can_resources_reservation: boolean;
  can_see_all_reservations: boolean;

  constructor(private store: Store<microappFbsState.MicroappFbsStates>,
    private authservice: AuthenticationService,
    public fbsConfig: FbsConfig,
    public dialog: MatDialog,
    private siteService: SiteService
  ) {
    this.coin = fbsConfig.CURRENCY;
    this.maxDate = new Date(new Date().getTime() + (this.fbsConfig.DAYS_IN_ADVANCE * (24 * 60 * 60 * 1000)));
  }


  ngOnInit(): void {

    this.authservice.loadCurrentUser().then(user => {
      this.currentUser = user;
      this.siteService.getSite(this.currentUser.sites[0].uid).subscribe(resp => {
        resp.tenants.forEach(element => {
          if (!element.is_site) {
            this.tenantsToSelect.push(element);
          }
        });
      });
    })

    this.can_resources_reservation = this.authservice.canUserExecute(this.fbsConfig.APPLICATION, this.fbsConfig.ROLE_RESOURCES_RESERVATION);

    this.can_see_all_reservations = this.authservice.canUserExecute(this.fbsConfig.APPLICATION, this.fbsConfig.ROLE_SEE_ALL_RESERVATIONS);
    if (this.can_see_all_reservations) {
      this.displayedColumns.unshift("tenant_name");
      this.displayedColumns.unshift("owner");
    } else {
      this.displayedColumns.push("actions");
    }
    this.fbsUserSubscription = this.store.select('fbs').subscribe(datos => {
      if (datos && datos.myBookings) {
        this.fbsUser = datos.fbsUser;
        this.reservationsDataSource = new MatTableDataSource(datos.myBookings);
        this.defineDataSourceFilter(this.reservationsDataSource);
        this.myBookingList = datos.myBookings;
        this.reservationsDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'date': return item.startDate.toISOString();
            case 'startDate': return item.startDate.toISOString();
            case 'endDate': return item.startDate.toISOString();
            default:
              if (typeof item[property] === 'string')
                return item[property].toLocaleLowerCase();
              else
                return item[property];
          }
        };
        this.reservationsDataSource.sort = this.sort;
        this.reservationsDataSource.paginator = this.paginator;
      }
      if (datos && datos.resources_grouped_for_admin) {
        this.allResources = datos.resources_grouped_for_admin;
      }
    });
    this.formGroup = new FormGroup({
      start: this.start,
      end: this.end,
    });

    let d = new Date();
    let month_before = new Date(d.setMonth(d.getMonth() - 1));
    let e = new Date();
    let month_after = new Date(d.setMonth(e.getMonth() + 1));
    this.formGroup.controls['start'].patchValue(month_before);
    this.formGroup.controls['end'].patchValue(month_after);
    if (!this.can_see_all_reservations) {
      this.search_dates();
    }

  }

  reservation_update(reservation: MicroappFbsReservationGroupModel) {
    let dateChecked = new SearchDates;
    dateChecked.searchStartDate = reservation.startDate;
    dateChecked.searchEndDate = reservation.endDate;
    if (!reservation.reservationGroupId) {
      this.update_facility_reservation(dateChecked, reservation);
    } else {
      if (reservation.extra) {
        this.update_facility_group_reservation(dateChecked, reservation);
      } else {
        this.update_accessory_reservation(dateChecked, reservation);
      }
    }
  }


  private update_accessory_reservation(dateChecked: SearchDates, reservation: MicroappFbsReservationGroupModel) {
    this.store.pipe(select(selectResourceGroups)).pipe(take(1)).subscribe(resourceGroups => {
      //Look for accesory group that has a resource with id one resource_id from the reservation
      let accessory_group;
      resourceGroups.forEach(x => {
        x.getResourcesIds().forEach(y => {
          if (y == reservation.reservations[0].resourceId) {
            accessory_group = x;
            return;
          }
          if (accessory_group) return;
        });
      });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [accessory_group, dateChecked, reservation];
      dialogConfig.width = '1000px';
      const dialogRef = this.dialog.open(MicroappFbsMakeAAccessoryReservationComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
        }
      });
    });

  }


  private update_facility_reservation(dateChecked: SearchDates, reservation: MicroappFbsReservationGroupModel) {

    this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {
      let the_facility = resources.find(x => +x.resourceId == reservation.reservations[0].resourceId);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [the_facility, dateChecked, reservation];
      dialogConfig.width = '1000px';
      const dialogRef = this.dialog.open(MicroappBfsMakeAReservationComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          // this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
        }

      });
    });
  }

  private update_facility_group_reservation(dateChecked: SearchDates, reservation: MicroappFbsReservationGroupModel) {

    this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {
      let the_facility = resources.find(x => +x.resourceId == reservation.reservations[0].resourceId);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [the_facility, dateChecked, reservation];
      dialogConfig.width = '1000px';
      const dialogRef = this.dialog.open(MicroappFbsMakeAFacilityGroupReservationComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          // this.store.dispatch(fbsActions.resetFacilitiesWithBookInfo());
        }

      });
    });
  }

  reservation_delete(reservation: MicroappFbsReservationGroupModel) {
    if (!reservation.reservationGroupId) {
      this.delete_facility(reservation);
    } else {
      this.delete_accessory(reservation);
    }

  }
  private delete_facility(reservation: MicroappFbsReservationGroupModel) {
    this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {
      let the_facility = resources.find(x => +x.resourceId == reservation.reservations[0].resourceId);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [reservation, the_facility];
      dialogConfig.width = '1000px';
      const dialogRef = this.dialog.open(MicroappFbsReservationDeleteComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {

      });
    });
  }

  private delete_accessory(reservation: MicroappFbsReservationGroupModel) {
    this.store.pipe(select(selectResourceGroups)).pipe(take(1)).subscribe(resourceGroups => {
      //Look for accesory group that has a resource with id one resource_id from the reservation
      let accessory_group: MicroappFbsResourceGroup;
      resourceGroups.forEach(x => {
        x.getResourcesIds().forEach(y => {
          if (y == reservation.reservations[0].resourceId) {
            accessory_group = x;
            return;
          };
          if (accessory_group) return;
        });
      });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [reservation, accessory_group];
      dialogConfig.width = '1000px';
      const dialogRef = this.dialog.open(MicroappFbsReservationDeleteComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {

      });
    });
  }

  reservation_detail(reservation: MicroappFbsReservationGroupModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = reservation;
    dialogConfig.width = '620px';
    const dialogRef = this.dialog.open(MicroappFbsReservationDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onExpand($event) {
    $event.preventDefault();
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }


  search_dates() {
    let from = new Date(this.formGroup.controls['start'].value);
    from.setHours(0, 0, 0, 0);
    let to: Date = null;
    if (this.formGroup.controls['end'].value) {
      to = new Date(this.formGroup.controls['end'].value);
      to.setHours(23, 59, 59, 999);
      if (from.getTime() > to.getTime()) {

        return
      }
    }

    if (this.can_see_all_reservations) {
      if (this.selectedTenantControl.valid) {
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: from, endDateTime: to, tenant_dn: this.selectedTenantControl.value }));
      }
    } else {
      this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: from, endDateTime: to }));
    }
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.reservationsDataSource.filter = JSON.stringify(this.userFilter);
  }

  onContinue() {

  }


  defineDataSourceFilter(tempDataSource: MatTableDataSource<MicroappFbsReservationGroupModel>) {
    tempDataSource.filterPredicate =
      (aGroup: MicroappFbsReservationGroupModel, filter: string) => {
        const theFilter: MicroappFbsMyReservationsFilter = JSON.parse(filter);
        let generalFilter = false;

        if (!theFilter) return true;

        //tenant filter
        if (theFilter.general) {

          if (aGroup.description != null) {
            if (aGroup.description.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            else if (aGroup.resourceName.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (aGroup.title.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          } else if (aGroup.resourceName.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          } else if (aGroup.title.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }

        } else generalFilter = true;

        return generalFilter;
      }
  }

  ngOnDestroy(): void {
    if (this.fbsUserSubscription) {
      this.fbsUserSubscription.unsubscribe();
    }
  }


  filterResourcesByIds(extra: any) {
    const filteredResources: MicroappFbsResourceGroup[] = [];

    extra.resource_group.resources.forEach((id) => {
      this.allResources.forEach((resource) => {
        if (id.toString() === resource.singleResourceId) {
          filteredResources.push(resource);
        }
      })
    })

    return filteredResources;
  }
}
