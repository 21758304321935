import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MicroappFbsTarif } from "../../../client/dto/microapp-fbs-tarif";
import { forkJoin, Observable } from "rxjs";
import { MicroappFbsChargesService } from "../../../lib/microapp-facilitiesbooking-charge.service";
import { SiteService } from "../../../client/site.service";
import { AuthenticationService, Tenant, User } from "@empusa/empusa-core";
import { MicroappFbsChargeType } from "../../../client/dto/microapp-fbs-charge-type";


@Component({
    selector: 'microapp-fbs-tariff-newEdit',
    templateUrl: './microapp-fbs-tariff-newEdit.component.html',
    styleUrls: ['./microapp-fbs-tariff-newEdit.component.css']
})

export class EditNewChargeComponent implements OnInit {

    public newChargeForm: FormGroup;
    public tarifList;
    tenantList : Tenant [] = [];
    currentUser: User;
    chargeType: MicroappFbsChargeType = new MicroappFbsChargeType();

    constructor(private authservice: AuthenticationService, private formBuilder: FormBuilder, private fbsChargesService: MicroappFbsChargesService, private siteService: SiteService) {

        this.newChargeForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            tariff: ['', Validators.required],
            tenants: ['', Validators.required],
        });
        this.authservice.loadCurrentUser().then(user => {
            this.currentUser = user;
            this.siteService.getSite(this.currentUser.sites[0].uid).subscribe(resp => {
                resp.tenants.forEach(element => {
                  if (!element.is_site) {

                    this.tenantList.push(element);
                  }
                });

              });
            this.chargeTarif();
        })
    }

    get f(): any { return this.newChargeForm.controls; }

    onSubmit() {

        let tenant = this.f.tenants.value;
        this.chargeType.application_charge_code = this.f.name.value;
        this.chargeType.billing_system_charge_code ="";
        this.chargeType.description =this.f.description.value;
        this.chargeType.tarif = this.f.tariff.value;

    }

    chargeTarif() {
        const allTarifs = this.fbsChargesService.getTariffs();

        return forkJoin([allTarifs]).subscribe(
            resultados => {


                this.tarifList = resultados[0];
            })
    }


    ngOnInit(): void {


    }

    tariffs = [
        { name: "1" },
        { name: "3" }
    ]

    tenants = [
        { name: "A" },
        { name: "B" }
    ]

}

