import { Component, Input, OnInit } from '@angular/core';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';

@Component({
  selector: 'empusa-microapp-fbs-mini-resource-table',
  templateUrl: './mini-resource-table.component.html',
  styleUrls: ['./mini-resource-table.component.css']
})
export class MiniResourceTableComponent implements OnInit {

  @Input() resources: MicroappFbsResourceGroup[];

  displayedColumns: string[] = ['name', 'location', 'description'];

  constructor() { }

  ngOnInit(): void {
  }

}
