
export class MicroappFbsPeriod {
    date: string;
    schedule_periods: MicroappFbsPeriodSlot[] = [];
}

export class MicroappFbsPeriodSlot {
    start: Date;
    end: Date
    label: string;
    startTime: string;
    endTime: string;
    isReservable: boolean;
}

export class MicroappFbsSchedule {
    periods: MicroappFbsPeriod[] = [];
}
