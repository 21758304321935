
import { Actions, ofType, createEffect, act, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as logBookActions from './microapp-vms-logbook.actions';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { config, of } from 'rxjs';
import { MicroappVmsSearchVisitFilter } from '../../model/microapp-vms-visit-filter.model';
import { MICROAPP_VMS_NAME, VmsConfig } from '../../microapp-vms-config';
import { MicroappVmsVisitAdapter } from '../../model/microapp-vms-visit-model-adapter';
import { MicroappVmsResponse } from '../../model/microapp-vms-response.model';
import { AuthenticationService } from '@empusa/empusa-core';
import { MicroappVmsConfigService } from '../../services/microapp-vms-config.service';


@Injectable()
export class MicroappVmsLogbookEffects {

    private getVisits = this.vmsConfig.URL_REST_BASE_VISIT;

    constructor(private actions$: Actions,
        private http: HttpClient,
        private datepipe: DatePipe,
        private authservice: AuthenticationService,
        private vmsConfig: VmsConfig,
        private visitAdapter: MicroappVmsVisitAdapter,
        private configService: MicroappVmsConfigService,
        private store: Store<vmsReducer.MicroappVmsStates>) { }


    logbookstartLogBook$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.startLogBook),
        map((action) => {
            console.log("Action " + action.type);
            let filter: MicroappVmsSearchVisitFilter = new MicroappVmsSearchVisitFilter();
            const userSite = action.loggedUser.sites.find(site => site.uid == action.site.uid);

            const is_fm = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_FACILITY_SCOPE);
            if (is_fm){
                filter.tenants = null;
            }else{
                filter.tenants = userSite.tenants;
            }

            filter.date_from = new Date();
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to = new Date();
            filter.date_to.setHours(23, 59, 59, 999);
            filter.site_id = action.site.uid;
            //return logBookActions.getVisits({ filter });
            return logBookActions.storeFilter({ filter });
        })
    ));


    getConfig$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.getConfig),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            return this.configService.getConfiguration()
                .pipe(
                    map(config => {
                        return logBookActions.storeConfig({config});
                    }),
                    catchError(error => {
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));



    logbookSearchingVisits$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.getVisits),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action ", action.type);
            let parameters: string = "?off_set=" + new Date().getTimezoneOffset();
            if (action.filter.date_from) {
                let tempDate = action.filter.date_from;
                parameters = parameters + "&date_from=" + tempDate.toISOString();
            }
            if (action.filter.date_to) {
                //date is in local 
                let tempDate = action.filter.date_to;
                parameters = parameters + "&date_to=" + tempDate.toISOString();
            }
            if (action.filter.site_id) {
                parameters = parameters + "&site_id=" + action.filter.site_id;
            }
            parameters = parameters + "&lite";
            if (action.filter.tenants) {
                let tenantLis = '["';
                tenantLis = tenantLis + action.filter.tenants.map(x => x.uid).join('","');
                tenantLis = tenantLis + '"]';
                parameters = parameters + "&tenant_id=" + tenantLis;
            }
            return this.http.get<any[]>(this.getVisits + parameters)
                .pipe(
                    map(theVisits => {
                        let visits: MicroappVmsVisit[];
                        console.debug("theVisits", theVisits);
                        visits = theVisits.map((visit) => this.visitAdapter.adapt(visit))
                        visits.sort((a,b) => {
                            if (a.id > b.id) return -1
                            else return 1
                        })
                        return logBookActions.visitList({ visits });
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 404) { //Not found
                                return of(logBookActions.visitList({ visits: [] }));
                            }
                        }
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));


    logbookUpdateSingleVisit$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.updateSingleVisit),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            let queryParams = "?id=" + action.visit.id + "&off_set=";
            queryParams = queryParams + new Date().getTimezoneOffset();

            return this.http.put<MicroappVmsResponse>(this.getVisits + queryParams, action.visit)
                .pipe(
                    map(response => {
                        let message_code = "MESSAGE_CODES.VISIT_UPDATED";
                        if (response.parking_request != 0) {
                            message_code = "MESSAGE_CODES.PARKING_" + response.parking_request;
                        }
                        return logBookActions.updateSingleVisitDone({ visit: action.visit, message_code })
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 304) { //Not modified
                                let message_code = "MESSAGE_CODES.VISIT_NOT_CHANGE";
                                return of(logBookActions.updateSingleVisitNoChanges({ message_code }));
                            }
                        }
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));

    updateSingleVisitDone$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.updateSingleVisitDone),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action ", action.type);
            let queryParams = "?&off_set=" + new Date().getTimezoneOffset();
            return this.http.get<any>(this.getVisits + "/" + action.visit.id + queryParams)
                .pipe(
                    map(theVisit => {
                        let visit = this.visitAdapter.adapt(theVisit);
                        let visits: MicroappVmsVisit[] = [];
                        state.visits.forEach(storeVisit => {
                            if (storeVisit.id == action.visit.id) {

                                visits.push(visit);
                            } else
                                visits.push(storeVisit);
                        });

                        return logBookActions.visitList({ visits });
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));


    mergeSingleVisit$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.refreshSingleVisit),
        withLatestFrom(this.store.select('logBook')),
        map(([action, state]) => {
            console.log("Action ", action.type);
            let visits: MicroappVmsVisit[] = [];
            state.visits.forEach(storeVisit => {
                if (storeVisit.id == action.visit.id) {
                    let clon = { ...storeVisit };
                    if (action.visit.first_name) {
                        clon.first_name = action.visit.first_name
                    };
                    if (action.visit.last_name) {
                        clon.last_name = action.visit.last_name
                    };
                    if (action.visit.company) {
                        clon.company = action.visit.company
                    };
                    if (action.visit.email) {
                        clon.email = action.visit.email
                    };
                    if (action.visit.phone) {
                        clon.phone = action.visit.phone
                    };
                    if (action.visit.license_plate) {
                        clon.license_plate = action.visit.license_plate
                    };
                    if (action.visit.about) {
                        clon.about = action.visit.about
                    };
                    if (action.visit.notification_status) {
                        clon.notification_status = action.visit.notification_status
                    };
                    visits.push(clon);
                } else
                    visits.push(storeVisit);
            });
            return logBookActions.visitList({ visits });
        })
    ));


    logbookUpdateGroupVisit$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.updateGroupVisit),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            const queryParams = "?group_id=" + action.visit.group_id;
            return this.http.put<null>(this.getVisits + queryParams, action.visit)
                .pipe(
                    map(response => {
                        return logBookActions.updateGroupVisitDone()
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));

    deleteVisitGroup$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.deleteVisitGroup),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            let parameters: string = "/" + action.group_id;
            parameters = parameters + "?off_set=";
            parameters = parameters + new Date().getTimezoneOffset();
            return this.http.delete<void>(this.vmsConfig.URL_REST_BASE_VISIT_GROUP + parameters)
                .pipe(
                    map(() => {
                        let visits: MicroappVmsVisit[] = [...state.visits];
                        let index = visits.findIndex(v => v.group_id === action.group_id);
                        while (index != -1) {
                            visits.splice(index, 1);
                            index = visits.findIndex(v => v.group_id === action.group_id);
                        }
                        return logBookActions.visitDeleted({ visits });
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));

    deleteSingelVisit$ = createEffect(() => this.actions$.pipe(
        ofType(logBookActions.deleteSingelVisit),
        withLatestFrom(this.store.select('logBook')),
        switchMap(([action, state]) => {
            console.log("Action " + action.type);
            let parameters: string = "/" + action.visit_id;
            parameters = parameters + "?off_set=";
            parameters = parameters + new Date().getTimezoneOffset();

            return this.http.delete<void>(this.vmsConfig.URL_REST_BASE_VISIT + parameters)
                .pipe(
                    map(() => {
                        // Delete visit from logbook
                        let visits: MicroappVmsVisit[] = [...state.visits];
                        console.log("Visits clone", visits);
                        let index = visits.findIndex(v => v.id === action.visit_id);
                        while (index != -1) {
                            visits.splice(index, 1);
                            index = visits.findIndex(v => v.id === action.visit_id);
                        }
                        return logBookActions.visitDeleted({ visits });
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.log("error: ", error);
                        return of(logBookActions.error());
                    })
                )
        })
    ));


}