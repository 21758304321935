<h2 class="titleNew"> <strong>{{'MAPP-QUIZ.REPORT.HEAD' | translate}}:</strong> {{title}}
  <button  mat-stroked-button (click)="downloadReport()">Download<mat-icon>download</mat-icon></button>
  <button class="closeReporter"
    mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button></h2>

<div class="questionCard">
  <div class="head">
    <strong>{{'MAPP-QUIZ.REPORT.TOTAL' | translate}}</strong> {{total}}
    <strong>{{'MAPP-QUIZ.REPORT.COMPLETED' | translate}}</strong> {{completed}}
  </div>

  <mat-grid-list cols="3" rowHeight="350px">
    <mat-grid-tile *ngFor="let chart of charts; let i = index">

      <mat-card class="card_chart">
        <mat-card-header>
          <mat-card-title>{{i + 1}}. {{chart.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas baseChart 
            [data]="chart.chartData" 
            [labels]="chart.chartLabels" 
            [chartType]="chart.chartType"
            [legend]="chart.chartLegend" 
            [options]="chart.chartOptions" 
            [colors]="chart.chartColors">
          </canvas>
        </mat-card-content>
      </mat-card>

    </mat-grid-tile>
  </mat-grid-list>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>