import { Component, ViewChild, ElementRef, Inject, ViewEncapsulation, resolveForwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FaultService } from '../../client/fault.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { Issue } from '../../client/dto/issue';
import { LogIssue } from '../../client/dto/logIssue';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { CustomField } from '../../client/dto/customfield';
import { MailService } from '../../client/mail.service';
import { InternalService } from '../../client/internal.service';
import { stringify } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';



@Component({
  selector: 'microapp-faultreport-fault-new',
  templateUrl: './microapp-faultreport.new-fault.component.html',
  styleUrls: ['./microapp-faultreport.new-fault.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewIssueComponent {

  @ViewChild("fileUpload", { static: false }) fileInputElement: ElementRef;
  files = [];
  /** form to validate */
  public newIssueForm: FormGroup;
  /**the user being edited */
  newIssue: Issue = new Issue();
  matcher = new MyErrorStateMatcher();
  listLogIssue: LogIssue[] = [];
  logIssue: LogIssue = new LogIssue();
  types: string[] = [];
  categorys = [];
  locations: string[] = [];
  towers: any[] = [];
  floors: any[] = [];
  subcategorys: any[] = [];
  priorities: Priority[] = [];
  listFiles: FilesShow[] = [];
  currentTicket: number;
  currentUser: User = new User();
  username: string;
  listPriorities = [];
  subTower: Subscription;
  subFloor: Subscription;
  subCategory: Subscription;
  loading: boolean = false;
  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  noTowers: boolean;

  constructor(private activatedRoute: ActivatedRoute, private faultService: FaultService,
    private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService,
    private router: Router, private mailService: MailService, private internalService: InternalService,
    private formBuilder: FormBuilder, private location: Location,
    public dialogRef: MatDialogRef<NewIssueComponent>, private auth: AuthenticationService) {

    this.newIssueForm = this.formBuilder.group({

      location: ['', Validators.required],
      tower: ['', Validators.required],
      floor: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      type: [''],
      date: ['', Validators.required],
      summary: ['', Validators.required],
      description: [''],
      // reporter: ['', Validators.required],
      priority: [''],
      email: [''],


    });
      this.loading = true;
     
      this.currentUser = this.auth.getCurrentUser();
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.getAllPriorities();
      this.getAllTowers();
      this.getAllCategories();
      this.setDate(true);
  

    this.subTower = this.f.tower.valueChanges.subscribe(newValue => {
      this.faultService.getFloorsByTower(newValue).subscribe((data: any) => {
        this.floors = [];
        this.f.floor.reset();
        this.f.location.reset();
        data.floors.forEach(element => {
          this.floors.push(element)
        });;
      })
    })
    this.subFloor = this.f.floor.valueChanges.subscribe(newValue => {
      if (newValue != null) {
        this.faultService.getLocationByFloorTower(this.f.tower.value, newValue).subscribe((data: any) => {
          this.locations = [];
          data.locations.forEach(element => {
            this.locations.push(element)
          });;
        })
      }
    })

    this.subCategory = this.f.category.valueChanges.subscribe(newValue => {
      this.faultService.getSubcategorieByCategorie(newValue).subscribe((data: any) => {
        this.subcategorys = [];
        data.subcategories.forEach(element => {
          this.subcategorys.push(element)

        });;
      })
    })

  }

  setDate(initOn: boolean) {
    // this.f.reporter.setValue(this.currentUser.mail);
    this.f.email.setValue(this.currentUser.mail);
    let today = new Date().toLocaleString();
    this.f.date.setValue(today);
    if (initOn) {
      this.getAllTypes();
    }
  }

  getAllPriorities() {
    this.faultService.allPriorities().subscribe((resp: string[]) => {
      this.listPriorities = resp;
    });
  }

  getAllTowers() {
    this.faultService.getTowers().subscribe((resp: any) => {
      if(resp.towers.length > 1){
        this.noTowers=false;
        resp.towers.forEach(element => {
          this.towers.push(element);
        });
        this.loading = false;
      }else{
        this.noTowers = true;
        this.f.tower.setValue(resp.towers[0]);
        this.loading = false;
      }
    })
  }

  getAllCategories() {
    this.faultService.getCategory().subscribe((resp: any) => {
      resp.categories.forEach(element => {
        this.categorys.push(element);
      });
    })
  }

  getAllTypes() {
    this.faultService.allTypes().subscribe((resp: string[]) => {
      this.types = resp;
      // this.getAllCustomFields();
    });
  }

  getAllCustomFields() {
    this.faultService.allCustomFields().subscribe((resp: CustomField[]) => {
      let index = 1;
      // resp[0].options.forEach(ele => {
      //   this.categorys.push({ name: ele, value: index });
      //   index++;
      // })
      // this.locations = resp[1].options;
    });
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.newIssueForm.controls; }

  deleteFile(filename: string) {
    this.files.forEach((item, index) => {
      if (item.data.name === filename) this.files.splice(index, 1);
    });
  }
  onClick() {
    this.loading = true;
    const fileUpload = this.fileInputElement.nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];

        if (file.size > 11322929) {
          this.snackBar.open(
            this.translate.instant("The file " + file.name + " is too big to upload"),
            this.translate.instant("Max Size is 10 MB")
            , {
              duration: 5000,
            });
        } else {
          this.files.push({ data: file, inProgress: false, progress: 0 });
        }
      }
      this.loading = false;
    };
    fileUpload.click();
  }

  uploadFiles() {
    this.fileInputElement.nativeElement.value = '';
    for (let i = 0; i < this.files.length; i++) {
      let last = false;
      if (i == this.files.length - 1) {
        last = true;
      }
      this.uploadFile(this.files[i], last);
    }
  }

  uploadFile(file, last) {
    const formData: FormData = new FormData();
    formData.append('name', file.data.name);
    formData.append('file', file.data);
    file.inProgress = true;
    this.faultService.upload(formData, this.currentTicket).subscribe((event: any) => {
      if (typeof (event) === 'object' && event.body === file.data.name) {
        if (last) {
          this.mailService.createIssue();
          this.loading = true;
          this.closeDialog();
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_OK.TITLE")
            , {
              duration: 5000,
            });
        }
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.FILES_NOT_OK.TITLE")
          , {
            duration: 5000,
          });

      });

  }




  /**
   * @name onSubmit
   * @description the info is being sent to the backend
   */
  onSubmit() {
    // stop here if form is invalid
    if (this.newIssueForm.invalid) {
      return;
    }
    this.loading = true;

    if (this.isAdmin == true || this.isSuperAdmin == true) {
      this.newIssue.type = this.f.type.value;
      this.newIssue.priority = this.f.priority.value;
    } else {
      this.newIssue.type = "--";
      this.newIssue.priority = "moderate";
    }
    this.newIssue.reporter = this.f.email.value;
    this.newIssue.email = this.f.email.value;
    this.newIssue.date = this.f.date.value;
    this.newIssue.location = this.f.location.value;
    this.newIssue.category = this.f.category.value;
    this.newIssue.summary = this.f.summary.value;
    this.newIssue.description = this.f.description.value;
    // this.newIssue.priority = this.f.priority.value;
    this.newIssue.subcategory = this.f.subcategory.value;
    this.newIssue.floor = this.f.floor.value;
    this.newIssue.tower = this.f.tower.value;
    this.newIssue.severity = "1";
    this.getOwner();
  }

  getOwner() {
    this.faultService.getOwner(this.newIssue.category, this.newIssue.type, this.newIssue.location).subscribe(
      (resp) => {
        this.newIssue.owner = resp.group;
        this.createIssue();
      },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  createIssue() {
    this.faultService.createIssue(this.newIssue).subscribe(
      data => {
        this.currentTicket = Number(data);
        if (this.files.length > 0) {
          this.uploadFiles();
        } else {
          this.loading = false;
          this.closeDialog();
          this.snackBar.open(
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.MESSAGE"),
            this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.OK.TITLE")
            , {
              duration: 5000,
            });
          this.clean();
        }

      },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      });


  }
  clean() {
    this.newIssueForm.reset();
    this.setDate(false);

  }

  closeDialog() {
    console.log('closeDialog');
    this.dialogRef.close();
  }
}
export class FilesShow {
  filename: string;
}

export class Priority {
  name: string;
  value: number;
}

export class Towers {
  tower: string;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


