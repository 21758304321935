<h2 class="titleDel" mat-dialog-title>{{'MAPP-FBS-SET-AVAILABLE-ACCESSORY.TITLE' | translate}}</h2>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        
        <div>
            <mat-form-field class="example-full-width">
                <mat-label>{{'MAPP-FBS-SET-AVAILABLE-ACCESSORY.NUMBER' | translate}}</mat-label>
                <input matInput [formControl]="numberOfAccessories">
                <mat-error *ngIf="numberOfAccessories.invalid && (numberOfAccessories.dirty || numberOfAccessories.touched)">
                    {{'MAPP-FBS-SET-AVAILABLE-ACCESSORY.NUMBER_MANDATORY' | translate}}</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="close($event)">{{'MAPP-FBS-SET-AVAILABLE-ACCESSORY.CANCEL' | translate}}</button>
        <button type="submit" [disabled]="!formGroup.valid" mat-raised-button color="primary">{{'MAPP-FBS-SET-AVAILABLE-ACCESSORY.CONFIRM' | translate}}</button>
    </mat-dialog-actions>
</form>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>