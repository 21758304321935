<form class = "searchFacilitiesForm" [formGroup] = "checkAvailabilityForm" (ngSubmit) = "checkFacilitieAvailability()">

  <mat-form-field class="margen_cajas">
      <mat-label>{{'MAPP-FBS-CHECK_AVAILABILITY.DATE' | translate}}</mat-label>
      <input matInput [matDatepickerFilter]="calendarFilter"
                      [matDatepicker]="picker2" formControlName = "dateField">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

      <mat-form-field class="margen_cajas">
        <mat-label>{{'MAPP-FBS-CHECK_AVAILABILITY.FROM' | translate}}</mat-label>
        <mat-select [formControl] = "startTime" (selectionChange)="resetEndTime()">
          <mat-option *ngFor="let hour of startHoursCombo" [value]="hour.value">
            {{hour.viewValue}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field class="margen_cajas">
        <mat-label>{{'MAPP-FBS-CHECK_AVAILABILITY.TO' | translate}}</mat-label>
        <mat-select [formControl] = "endTime">
          <mat-option *ngFor="let hour of endHoursCombo" [value]="hour.value">
            {{hour.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="checkAvailabilityForm.get('endTime').invalid && checkAvailabilityForm.get('endTime').touched">
         {{'MAPP-FBS-CHECK_AVAILABILITY.NOPERIOD' | translate}}</mat-error>
      </mat-form-field>
      <button mat-flat-button class="buttonCheckAvailability"
        [disabled]="!checkAvailabilityForm.valid"
        color = "primary" type="submit">
        {{'MAPP-FBS-CHECK_AVAILABILITY.CHECK' | translate}}</button>
  </form>
