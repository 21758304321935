import { ChargesService } from './charges.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DTOTarif } from './dto/tarif.dto';


@Injectable({
  providedIn: 'root',
})
export class TariffService {
  serviceURL = this.environment.urlbase + 'smartbilling/services/tarifs';

  constructor(private http: HttpClient, private auth: AuthenticationService,
    @Inject('environment') private environment) {
  }

  findUSR_MNGTTariffs(): Observable<DTOTarif[]> {
    let body = JSON.stringify({
      tags: [ChargesService.USR_MNGT],
      add_default: 'true'
    });

    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(`${this.serviceURL}/filter/tag`, body, {headers}).pipe(
      map(resp => (<any>resp).tarifs)
    );
  }




}
