import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';
import { MicroappHomeComponent } from './microapp-home.home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MicroappHomeTermsComponent } from './terms/microapp-home-terms/microapp-home-terms.component';


@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    TranslateModule,
    MatDialogModule,
    RouterModule.forChild([
      {
        path: '', component: MicroappHomeComponent
      },
    ])
  ],
  declarations: [
    MicroappHomeComponent,
    MicroappHomeTermsComponent
  ],
  providers: [
    MatSnackBar
  ]
})
export class HomeModule {

  constructor(private translationLoader: TranslationLoaderService) {
    console.log("home management lazy constructor");
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }

}
