import { Component, OnInit, Inject } from '@angular/core';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsVisitor } from '../../../lib/model/microapp-vms-visitor.model';
import { selectAddressBookLoading } from '../../../lib/address-book/store/microapp-vms-address-book-selectors';


@Component({
  selector: 'empusa-microapp-vms-update-visitor-container',
  templateUrl: './microapp-vms-update-visitor-container.component.html',
  styleUrls: ['./microapp-vms-update-visitor-container.component.css']
})
export class MicroappVmsUpdateVisitorContainerComponent implements OnInit {
  visitor: MicroappVmsVisitor;
  loading$: any;
  loading: any;

  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visitor = data;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select(selectAddressBookLoading).subscribe(loading => {
      this.loading = loading;
    });
  }

  onClose() {
    this.dialogRef.close(null);
  }

}
