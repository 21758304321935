import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as newVisitActions from './microapp-vms-group-visit-manage.actions'
import * as vmsReducer from '../../microapp-vms.reducer';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { MicroappVmsVisitWithVisitors } from '../model/microapp-vms-manage-group-visit.model';
import { MicroappVmsVisitVisitor } from '../model/microapp-vms-manage-group-visit-visitor.model';
import { VmsConfig } from '../../microapp-vms-config';
import { MicroappVmsResponse } from '../../model/microapp-vms-response.model';



@Injectable()
export class MicroappVmsGroupVisitManageEffects {

    postVisit = this.vmsConfig.URL_REST_BASE_VISIT;

    constructor(private actions$: Actions,
        private store: Store<vmsReducer.MicroappVmsStates>,
        private vmsConfig: VmsConfig,
        private http: HttpClient) { }



    newVisitStartNewVisit$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.startNewVisit),
        map((action) => {
            console.log("Action " + action.type);
            let visit = new MicroappVmsVisitWithVisitors();
            visit.site_id = action.site.uid;
            visit.tenant_id = action.tenant.uid;
            visit.host_id = action.host.mail;
            return newVisitActions.loadVisitorsForSelection({ visit });
        })
    ));

    loadVisitorsForSelection = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.loadVisitorsForSelection),
        switchMap((action) => {
            console.log("Action " + action.type);
            let id = encodeURIComponent(action.visit.tenant_id);
            let parameters: string = "/" + id + "/visitor"
            return this.http.get<MicroappVmsVisitVisitor[]>(this.vmsConfig.URL_REST_BASE_TENANT + parameters)
                .pipe(
                    map(theVisitors => {
                        let tenantVisitors = theVisitors as MicroappVmsVisitVisitor[];
                        return newVisitActions.visitorsLoaded({ visit: action.visit, tenantVisitors });
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 404) { //Not found
                                return of(newVisitActions.visitorsLoaded({ visit: action.visit, tenantVisitors: [] }));;
                            }
                        }
                        console.error("error: ", error);
                        let tenantVisitors: MicroappVmsVisitVisitor[] = [];
                        return of(newVisitActions.visitorsLoaded({ visit: action.visit, tenantVisitors }));
                    })
                )
        })
    ));

    newVisitAddingAParticipant$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.addingAParticipant),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);

            let participants: MicroappVmsVisitVisitor[] = [];
            if (storeState.visit && storeState.visit.visitors)
                participants = storeState.visit.visitors.slice();
            let tenantVisitors = storeState.tenantVisitors.slice()

            //check email not aready in the group
            const whereIsEmail = participants.findIndex(visitor => visitor.email.toLowerCase() == action.visitor.email.toLowerCase());
            if (whereIsEmail >= 0) {
                return newVisitActions.emailRepeated();
            }
            //add participant to visit is not aready added
            if (action.visitor.id) { //Is a registered participant
                const alreadyAdded = participants.findIndex(participant => participant.id == action.visitor.id);
                if (alreadyAdded < 0) {
                    participants.push({ ...action.visitor });
                }
            } else { // Is a new visitor not in the addressbook                
                participants.push({ ...action.visitor });
            }
            //Remove participan from selection list
            const whereIs = tenantVisitors.findIndex(visitor => visitor.id == action.visitor.id);
            if (whereIs >= 0) {
                tenantVisitors.splice(whereIs, 1);
            }
            let visitWithParticipants = { ...storeState.visit };
            visitWithParticipants.visitors = participants;
            return newVisitActions.participantListUpdated({ visitWithParticipants, tenantVisitors });
        })
    ));


    newVisitDeletingAParticipant$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.deletingAParticipant),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);

            let participants: MicroappVmsVisitVisitor[] = [];
            if (storeState.visit && storeState.visit.visitors)
                participants = storeState.visit.visitors.slice();

            let tenantVisitors = [];
            if (storeState.tenantVisitors)
                tenantVisitors = storeState.tenantVisitors.slice();

            if (action.visitor.id)
                tenantVisitors.push(action.visitor);

            const whereIs = participants.findIndex(visitor => visitor.id == action.visitor.id);
            if (whereIs >= 0) {
                participants.splice(whereIs, 1);
            }

            let visitWithParticipants = { ...storeState.visit };
            visitWithParticipants.visitors = participants;
            return newVisitActions.participantListUpdated({ visitWithParticipants, tenantVisitors });
        })
    ));

    deleteVisitorFromSelection$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.deleteVisitorForSelection),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);

            let participants: MicroappVmsVisitVisitor[] = [];
            if (storeState.visit && storeState.visit.visitors)
                participants = action.visitors;
            let availableTenantVisitors = storeState.tenantVisitors.slice();

            participants.forEach(participan => {
                const whereIs = availableTenantVisitors.findIndex(visitor => visitor.id == participan.id);
                if (whereIs >= 0) {
                    availableTenantVisitors.splice(whereIs, 1);
                }
            });

            return newVisitActions.updateVisitorForSelection({ availableTenantVisitors });
        })
    ));


    newVisitUpdateComplimentaryParking$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.complimentaryParkingRequest),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);
            /*if (action.visitor.iu == null || action.visitor.iu.length < 1) {
                console.debug("visitor with out iu");
                return ({ type: 'DUMMY' });
            }*/
            let participants: MicroappVmsVisitVisitor[] = [];
            if (storeState.visit && storeState.visit.visitors)
                participants = storeState.visit.visitors.slice();


            const whereIs = participants.findIndex(visitor => visitor.email.toLowerCase() == action.visitor.email.toLowerCase());

            if (whereIs < 0)
                return ({ type: 'DUMMY' });

            let theVisitor = { ...participants[whereIs] };
            theVisitor.complimentary_parking = action.c_parking;
            participants[whereIs] = theVisitor;
            let visit = { ...storeState.visit };
            visit.visitors = participants;
            return newVisitActions.updateVisitAndVisitors({ visit });
        })
    ));

    complimentaryParkingRequest4All$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.complimentaryParkingRequest4All),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);
            let participants: MicroappVmsVisitVisitor[] = [];

            if (!storeState.visit.visitors || storeState.visit.visitors.length < 1)
                return ({ type: 'DUMMY' });
            storeState.visit.visitors.forEach(visitor => {
                let visitorClon = { ...visitor };
               /* if (visitorClon.iu == null || visitorClon.iu.length < 1) {
                    visitorClon.complimentary_parking = false;
                } else {*/
                    visitorClon.complimentary_parking = action.c_parking;
               // }
                participants.push(visitorClon);
            })
            let visit = { ...storeState.visit };
            visit.visitors = participants;
            return newVisitActions.updateVisitAndVisitors({ visit });
        })
    ));


    newVisitSchedulingTheVisit$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.scheduleTheVisit),
        withLatestFrom(this.store.select('groupVisitManage')),
        map(([action, storeState]) => {
            console.log("Action " + action.type);
            let visit = { ...storeState.visit };
            visit.date = action.date;
            visit.start_time = action.start_time;
            visit.end_time = action.end_time;
            visit.about = action.about;
            return newVisitActions.updateVisitAndVisitors({ visit });
        })
    ));


    confirmTheNewVisit$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.confirmTheNewVisit),
        withLatestFrom(this.store.select('groupVisitManage')),
        switchMap(([action, storeState]) => {
            console.log("Action " + action.type);
            let visit = { ...storeState.visit };
            let url = this.postVisit + "?off_set=";
            url = url + new Date().getTimezoneOffset();
            console.log("url:", url);
            return this.http.post<MicroappVmsResponse>(url, visit)
                .pipe(
                    map(response => {
                        console.log("response ", response);
                        let message_code = "MESSAGE_CODES.NEW_VISIT_CREATED";
                        if (response.parking_request != 0) {
                            message_code = "MESSAGE_CODES.NEW_VISIT_CREATED_BUT_PARKING";
                        }
                        return newVisitActions.visitStored({ visit, message_code });
                    }),
                    catchError(error => {
                        //TODO Revisar
                        console.error("error: ", error);
                        let tenantVisitors: MicroappVmsVisitVisitor[] = [];
                        return of(newVisitActions.error());
                    })
                )
        })
    ));

    updateAVisit$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.updateAVisit),
        map((action) => {
            console.log("Action " + action.type);
            return newVisitActions.loadVisitorsForSelection({ visit: action.visit });
        })
    ));

    performVisitUpdate$ = createEffect(() => this.actions$.pipe(
        ofType(newVisitActions.performVisitUpdate),
        withLatestFrom(this.store.select('groupVisitManage')),
        switchMap(([action, state]) => {
            console.log("Action ", action.type);
            let queryParams = "?group_id=" + state.visit.group_id + "&off_set=";
            queryParams = queryParams + new Date().getTimezoneOffset();
            return this.http.put<MicroappVmsResponse>(this.vmsConfig.URL_REST_BASE_VISIT + queryParams, state.visit)
                .pipe(
                    map(response => {
                        console.log("response ", response);
                        let message_code = "MESSAGE_CODES.VISIT_UPDATED";
                        if (response.parking_request != 0) {
                            message_code = "MESSAGE_CODES.PARKING_1";
                        }
                        return newVisitActions.updateDone({ message_code });
                    }),
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 304) { //Not modified
                                let message_code = "MESSAGE_CODES.VISIT_NOT_CHANGE";
                                return of(newVisitActions.updateDone({ message_code }));
                            }
                        }
                        console.log("error: ", error);
                        return of(newVisitActions.error());
                    })
                )
        })
    ));

}

