
import { Pipe, PipeTransform } from '@angular/core';
import { VmsConfig } from '../microapp-vms-config';

@Pipe({
  name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {

  constructor(private vmsConfig: VmsConfig,){

  }

  transform(temperature: unknown, ...args: unknown[]): string {
    return `${temperature}${this.vmsConfig.MASTER_CONFIG.temperature_units}`;
  }

}
