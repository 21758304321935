import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { VmsMasterConfiguration } from './model/microapp-vms-config.model';
import { VehicleType } from './model/microapp-vms-vehicle';
import { MicroappVmsConfigService } from './services/microapp-vms-config.service';


export const MICROAPP_VMS_NAME: string = "vms";
export const MICROAPP_EPS_NAME: string = "eps";

@Injectable({
    providedIn: 'root',
})
export class VmsConfig {

    PARKING = false;
    /** IMPORTANT DETERMINE IF REQUEST LICENSE PLATE */
    REQUEST_LICENSE_PLATE = false;
    MASTER_CONFIG: VmsMasterConfiguration = null;
    URL_REST_EPS_PREFIX = "smartparking/services/";
    URL_REST_EPS_BASE = this.environment.urlbase + this.URL_REST_EPS_PREFIX;
    // Config for cloud deploiment
    URL_REST_PREFIX = "visitormanagement/services/";
    URL_REST_BASE: string = this.environment.urlbase + this.URL_REST_PREFIX;
    // URL_REST_BASE: string = 'http://localhost:5100/';
    // END: Config for cloud deploiment

    // Config for local develop
    //URL_REST_PREFIX = "";
    //URL_REST_BASE = "http://192.168.77.28:80/" + this.URL_REST_PREFIX;
    //URL_REST_BASE = "http://192.168.77.25:5100/" + this.URL_REST_PREFIX;
    // END: Config for local develop

    URL_REST_BASE_VISITOR = this.URL_REST_BASE + "visitor";

    URL_REST_BASE_VISIT = this.URL_REST_BASE + "visit";
    URL_REST_BASE_VISIT_GROUP = this.URL_REST_BASE + "visit/group";
    URL_REST_BASE_TENANT = this.URL_REST_BASE + "tenant";
    URL_REST_BASE_CONFIG = this.URL_REST_BASE + "config";
    URL_REST_CHECK_OUT = this.URL_REST_BASE + "visit/$1/check-out"


    // Role
    F_NEW_VISIT = "new_visit";
    F_EDIT_VISIT = "edit_visit";
    F_EDIT_VISITOR = "edit_visitor";
    F_DELETE_VISITOR = "delete_visitor";
    F_ADDRESS_BOOK = "address_book";
    F_SINGLE_SCOPE = "single_scope";
    F_TENANT_SCOPE = "tenant_scope";
    F_FACILITY_SCOPE = "facility_scope";
    F_CHECK_IN_OUT = "check_in_out";
    F_CAN_REQUEST_PARKING = "can_request_parking";
    F_EPS_RESERVATION = "reservation";
    F_CAN_ACCESS ="access"

    //Fields sizeMAPP-VMS-VISITOR-FORM
    SIZE_FIRST_NAME = 30;
    SIZE_LAST_NAME = 30;
    SIZE_COMPANY = 30;
    SIZE_EMAIL = 50;
    SIZE_PHONE = 30;
    SIZE_IU = 15;
    SIZE_LICENSE_PLATE = 20;
    SIZE_IU_MIN = 9;
    SIZE_IU_MAX = 10;
    SIZE_LICENSE_PLATE_MAX = 10;
    SIZE_LICENSE_PLATE_MIN = 6;
    SIZE_TIME = 5;

    /*vehicle types allowed by backend*/
    VEHICLE_CAR = "Car";
    VEHICLE_MOTORBIKE = "Motorbike";
    VEHICLE_HANDICAP = "Handicap";
    default_vehicles: VehicleType[] = [
        { vehicle_code: this.VEHICLE_CAR, vehicle_desc: this.VEHICLE_CAR, vehicle_icon: "directions_car" },
        { vehicle_code: this.VEHICLE_MOTORBIKE, vehicle_desc: this.VEHICLE_MOTORBIKE, vehicle_icon: "two_wheeler" }
    ]

    //Phone regex
    PHONE_PATTERN = "^[+]?[0-9]{3," + this.SIZE_PHONE + "}$"

    // Range (months) in datepickers for custom visits search
    CUSTOMSEARCH_MIN_RANGE_MONTHS = 12;
    CUSTOMSEARCH_MAX_RANGE_MONTHS = 12;
    CUSTOMSEARCH_SEARCH_RANGE_MONTHS = 1;


    constructor(@Inject('environment') private environment) { }
}
