import { TariffService } from './../../client/tariff.service';
import { ChargesService } from './../../client/charges.service';
import { SiteService } from './../../client/site.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TenantTariffAssociations } from '../../client/dto/tenant-tariff-associations.dto';
import { DTOTariffPass } from '../../client/dto/parking-passes.dto';
import { DTOTarif } from '../../client/dto/tarif.dto';
import { MatPaginator } from '@angular/material/paginator';
import { Tenant } from '@empusa/empusa-core';

@Component({
  selector: 'empusa-tariff-assignment',
  templateUrl: './tariff-assignment.component.html',
  styleUrls: ['./tariff-assignment.component.css']
})
export class TariffAssignmentComponent implements OnInit {

  displayedColumns: string[] = ['tenants', 'user-creation', 'actions'];
  tenantSource = new MatTableDataSource([]);
  tarifList = [];
  loading = false;
  _tenantList: Tenant[];
  @Input() set tenantList(tenantList: Tenant[]){
    this._tenantList= tenantList;
    this.getDataTenants();

  };
  
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.tenantSource) {
      this.tenantSource.paginator = mp;
    }
  }

  constructor(private siteService: SiteService,
    private chargesService: ChargesService,
    private tariffService: TariffService) {
    this.tariffService.findUSR_MNGTTariffs().subscribe(tarifs => {
      this.tarifList = tarifs;
    });
  }


  ngOnInit(): void {
    // this.getDataTenants();
  }

  getDataTenants() {
    // this.tenantSource.sort = this.sort;
    // this.tenantSource.paginator = this.paginator;

    let tenantTariffAssociationsList: TenantTariffAssociations[] = [];
    let promises = [];
    this.loading = true;




    // this.siteService.findAllTenants().then(tenants => {
      this.chargesService.getAllDataPasesForTenant().subscribe(tariffs => {
        this._tenantList.forEach(tenant => {
          const tenantTariffs = new TenantTariffAssociations(tenant,
            tariffs,
            false,
            tariffs.find(tariff => tariff.application_charge_code == ChargesService.USR_MNGT_NEW_USER && tariff.tenant_id == tenant.dn)
          );

          tenantTariffAssociationsList.push(tenantTariffs);
        });
        this.tenantSource.data = tenantTariffAssociationsList;
        this.loading = false;
        this.tenantSource.filterPredicate = ((data, filter) => {
          if (data.tenant.name.toLowerCase().includes(filter)) {
            return true;
          }
          if (data.newUser.tarif.tarif_name && data.newUser.tarif.tarif_name.toLowerCase().includes(filter)) {
            return true;
          }
          return false;
        });
      });
    // });
  }

  searchText(filterValue: string) {
    this.tenantSource.filter = filterValue.trim().toLowerCase();
  }

  onTarifSelected(element: DTOTariffPass, selection) {
    element.tarif = this.tarifList.find(tarif => tarif.tarif_id === selection.value);
  }

  setEditable(element: TenantTariffAssociations) {
    element.flag_editable = true;
  }

  saveChanges(element: TenantTariffAssociations) {
    this.loading = true;
    this.chargesService.updatePasses(element.tenant, element.dtos).subscribe(data => {
      this.loading = false;
      element.flag_editable = false;
    });
  }

}
