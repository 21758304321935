<h2 class="cabecera">
  <mat-icon class="iconoCabecera">supervisor_account</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-VMS-HOME.APP-NAME' | translate}}</mat-label>
</h2>
<div class="fondo">
  <div class="headerVmsHome">
    <nav mat-tab-nav-bar class="vmsNavBar" *ngIf="can_access">
      <a class="tabLabel" mat-tab-link [routerLink]="['./logbook']" routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">{{'MAPP-VMS-HOME.LOG-BOOK' | translate}}</a>

      <a class="tabLabel" mat-tab-link [routerLink]="['./address-book']" routerLinkActive #rla2="routerLinkActive"
        [active]="rla2.isActive" *ngIf="can_address_book">
        {{'MAPP-VMS-HOME.ADDRESS-BOOK' | translate}}</a>
    </nav>
    <div class="notAccess" *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <empusa-mat-spinner-overlay *ngIf="loading || loading_cfg" overlay="true"></empusa-mat-spinner-overlay>
  <router-outlet></router-outlet>
</div>
<div class="timezone" *ngIf="can_access && timezone && difftz">{{('MAPP-VMS-HOME.TIMEZONE' | translate).replace('%1',timezone)}}</div>