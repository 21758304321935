<h2 class="titleGeneric" mat-dialog-title> {{data.title | translate}}</h2>
<div class="genericDialog">
    {{data.question | translate}}
</div>

<div mat-dialog-actions class="genericButton" align="end">
    <button class="buttonClose" mat-raised-button
        [mat-dialog-close]="false">{{'DIALOG.QUESTION.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary"
        [mat-dialog-close]="true">{{'DIALOG.QUESTION.OK' | translate}}</button>
</div>