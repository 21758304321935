import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsResourceGroup } from './microapp-fbs-resource-group';
import { MicroappFbsPeriod } from './microapp-fbs-schedule';
import { MicroappFbsAccessoryGroupSlotsModel } from './Microapp-fbs-accessory-group-slots';
import { MicroappFbsResourceModel } from './microapp-fbs-resource';

@Injectable({
  providedIn: "root",
})
export class FbsResourceGroupAdapter implements Adapter<MicroappFbsResourceGroup> {

  constructor(private fbsConfig: FbsConfig) { }

  adapt(item: MicroappFbsResourceModel, schedule_for_today: MicroappFbsPeriod): MicroappFbsResourceGroup {
    let resourceGroup = new MicroappFbsResourceGroup(this.fbsConfig);
    resourceGroup.totalResources = 1;

    switch (item.statusId) {
      case this.fbsConfig.RESOURCE_STATUS_AVAILABLE: {
        resourceGroup.hiddenResources = 0;
        resourceGroup.availableResources = 1
        resourceGroup.unAvailableResources = 0;
        resourceGroup.canBookInSelectedPeriod = item.canBookInSelectedPeriod;
        resourceGroup.availableResourcesToBook = (item.canBookInSelectedPeriod ? 1 : 0);
        break;
      }
      case this.fbsConfig.RESOURCE_STATUS_HIDDEN: {
        resourceGroup.hiddenResources = 1;
        resourceGroup.availableResources = 0;
        resourceGroup.unAvailableResources = 0;
        resourceGroup.canBookInSelectedPeriod = false;
        resourceGroup.availableResourcesToBook = 0;
        break;
      }
      default: {
        //this.fbsConfig.RESOURCE_STATUS_UNAVAILABLE
        resourceGroup.hiddenResources = 0;
        resourceGroup.availableResources = 0;
        resourceGroup.unAvailableResources = 1;
        resourceGroup.canBookInSelectedPeriod = false;
        resourceGroup.availableResourcesToBook = 0;
        break;
      }
    }

    resourceGroup.description = item.description;
    resourceGroup.location = item.location;
    resourceGroup.maxParticipants = item.maxParticipants;
    resourceGroup.name = item.name;
    resourceGroup.type = item.type;
    resourceGroup.statusId = item.statusId;
    resourceGroup.image_urls = item.image_urls;
    if (resourceGroup.canBookInSelectedPeriod) {
      resourceGroup.slots = item.slots;
    }
    resourceGroup.resourceGroupId = item.resourceGroup;
    if (resourceGroup.resourceGroupId)
      resourceGroup.addResourceToResourcesID(item);
    else
      resourceGroup.singleResourceId = item.resourceId;

    resourceGroup.tarif = item.tarif;
    resourceGroup.chargeType = item.chargeType;

    if (schedule_for_today) {
      resourceGroup.combined_slots = [];
      schedule_for_today.schedule_periods.forEach(period => {
        let one_slot = new MicroappFbsAccessoryGroupSlotsModel;
        one_slot.label = period.label;
        one_slot.startDateTime = period.start;
        one_slot.endDateTime = period.end;
        one_slot.isReservable = period.isReservable;
        one_slot.slots = [];
        if (item.slots) {
          item.slots.forEach(item_one_slot => {
            if (one_slot.startDateTime.getTime() === item_one_slot.startDateTime.getTime() &&
              one_slot.endDateTime.getTime() === item_one_slot.endDateTime.getTime() &&
              item_one_slot.isReservable && !item_one_slot.isBooked) {
              one_slot.slots.push(item_one_slot);
              return;
            }
          })
        }
        resourceGroup.combined_slots.push(one_slot);
      });
      resourceGroup.combined_slots.sort((p1, p2) => {
        return p1.endDateTime > p2.endDateTime ? 1 : -1;
      });
    }


    return resourceGroup;
  }


}
