import { Component, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { Fitting } from '../../client/dto/fitting';
import { LogIssue } from '../../client/dto/logIssue';
import { InternalService } from '../../client/internal.service';
import { FittingService } from '../../client/fitting.service';
import { CommentComponent } from '../components/microapp-fittingout.components-comment-fittingout.component';
import { MICROAPP_UPDATE, MICROAPP_NAME, MICROAPP_RESOLVE, MICROAPP_STATUS_OPEN, MICROAPP_STATUS_CLOSED, MICROAPP_STATUS_CLOSED_REJECTED, MICROAPP_STATUS_CLOSED_APPROVED, MICROAPP_TEXT_REOPEN } from '../../lib/microapp-fittingout.module';
import { MailService } from '../../client/mail.service';
import { WarningDialog } from '../components/microapp-fittingout.components-warning-fittingout.component';
import { NewFittingComponent } from '../new/microapp-fittingout.new-fittingout.component';



@Component({
  selector: 'microapp-fittingout-fittingout-comment',
  templateUrl: './microapp-fittingout.comment-fittingout.component.html',
  styleUrls: ['./microapp-fittingout.comment-fittingout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CommentFittingComponent {

  @ViewChild("fileUpload", { static: false }) fileInputElement: ElementRef;
  files = [];
  filesName = [];
  /** form to validate */
  public newFittingForm: FormGroup;
  public resolutionForm: FormGroup;
  /**the user being edited */
  newFitting = new Fitting();
  matcher = new MyErrorStateMatcher();
  listTypes: string[] = [];
  listFiles: FilesShow[] = [];
  listLogIssue: LogIssue[] = [];
  currentUser: User = new User();
  username: string;
  isEdit: boolean;
  isEditAndComents: boolean;
  allowDownloadFiles: boolean;
  allowChangeDescriptions: boolean;
  allowComments: boolean;
  isClosed: boolean;
  isAllowUpdate: boolean;
  isAllowResolve: boolean;
  comment: string;
  isDraft = false;
  isClose: boolean = false;
  isAdmin = false;
  isValidateRejected = false;
  isChecked = false;
  textResolution: string;
  displayLog: Boolean = false;
  loading: Boolean = true;
  resolution: string = MICROAPP_STATUS_CLOSED_APPROVED;
  constructor(private translate: TranslateService,
    private interalService: InternalService,
    private service: FittingService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewFittingComponent>,
    private snackBar: MatSnackBar, private dialog: MatDialog,
    private auth: AuthenticationService,
    private mailService: MailService,
    @Inject(MAT_DIALOG_DATA) public ticket_id: String) {


    this.resolutionForm = this.formBuilder.group({
      resolution: ['', Validators.required]
    })

    this.newFittingForm = this.formBuilder.group({
      type: ['', Validators.required],
      date: ['', Validators.required],
      summary: ['', Validators.required],
      description: ['', Validators.required],
      reporter: ['', Validators.required],
      comment: ['', Validators.required]
    });
    this.currentUser = this.auth.getCurrentUser();

    if (ticket_id != '') {
      //edit
      this.loadFitting(ticket_id);
    }
  }

  loadFitting(ticket_id) {
    this.service.getFitting(ticket_id).subscribe((fit: Fitting) => {
      this.newFitting = fit[0];
      console.log("load fitting");
      this.f.reporter.setValue(this.newFitting.reporter);
      this.setDateEdit();
      this.f.type.setValue(this.newFitting.type);
      this.f.summary.setValue(this.newFitting.summary);
      this.f.description.setValue(this.newFitting.description);
      this.loadFiles(true);
      if (this.newFitting.owner !== "FACILITY MANAGER") {
        this.isDraft = true;
      }
      if (this.newFitting.owner === "FACILITY MANAGER" && this.newFitting.resolution === "rejected") {
        this.isValidateRejected = true;
      }
      this.isEdit = true;
      this.allowDownloadFiles = true;
      this.isClosed = false;
      if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_APPROVED) {
        this.isClosed = true;
        this.allowComments = false;
        this.isAllowUpdate = false;
        this.isAllowResolve = false;
      } else {
        this.isAllowUpdate = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_UPDATE);
        this.allowComments = this.isAllowUpdate;
        this.isAllowResolve = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_RESOLVE);
      }
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  setDate(initOn: boolean) {
    let today = new Date().toLocaleString();
    this.f.date.setValue(today);
  }

  setDateEdit() {
    var dateEdit = this.newFitting.date;
    let formatedBarra: String = dateEdit.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + dateEdit.slice(11) + "Z";
    let dateNew = new Date(formated).toLocaleString();
    this.f.date.setValue(dateNew);
  }

  // convenience getter for easy access to form fields
  get f() { return this.newFittingForm.controls; }

  loadComments() {
    this.loading = true;
    this.service.getChanges(this.newFitting.ticket_id).subscribe((dataLog: LogIssue[]) => {
      this.loading = false;
      for (let dateComment of dataLog) {
        let dateFormated = dateComment.date;
        let formatedBarra: String = dateFormated.slice(0, 10);
        let formatesGuion = formatedBarra.replace(/\//g, '-');
        var reverseDate = formatesGuion.split("-").reverse().join("-");
        let formated = reverseDate + "T" + dateFormated.slice(11) + "Z";
        let dateNew = new Date(formated).toLocaleString();
        dateComment.date = dateNew;
      }
      this.listLogIssue = dataLog;
      if (this.listLogIssue.length > 0) {
        this.isEditAndComents = true;
      } else {
        this.isEditAndComents = false;
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  validateResolution() {
    this.newFitting.owner = 'FACILITY MANAGER';
    this.resolution = "";
    this.textResolution = "";
    this.onSubmit();
  }

  //FILES
  loadFiles(loadComments: boolean) {
    this.loading = true;
    this.filesName = [];
    this.service.getFiles(this.newFitting.ticket_id).subscribe((resp: FilesShow[]) => {
      this.loading = false;
      this.filesName = resp;
      if (loadComments) this.loadComments();
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  downloadFile(filename: string) {
    console.log("download file: " + filename);
    this.loading = true;
    this.service.downloadFile(this.newFitting.ticket_id, filename).subscribe((resp) => {
      this.loading = false;
      var a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(resp);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)

    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK_DOWNLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK_DOWNLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  commentDeleteFile(comment:string){
    this.service.updateFittingComment(this.newFitting, comment, this.currentUser.mail).subscribe((resp) => {
      this.loadFitting(this.newFitting.ticket_id);
      this.f.comments.setValue('');
    });
  }

  deleteFile(filename: string) {
    this.loading = true;
    this.filesName.forEach((item, index) => {
      if (this.newFitting.ticket_id != undefined && (item.filename === filename)) {
        this.service.deleteFile(this.newFitting, filename).subscribe((event: any) => {
          if (event == 'ok') {
            if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_REJECTED) {
              this.service.updateFittingComment(this.newFitting, MICROAPP_TEXT_REOPEN, this.auth.getCurrentUserName()).subscribe(ok => {
                this.loading = false;
                this.loadFitting(this.newFitting.ticket_id);
              });
              this.loadFitting(this.newFitting.ticket_id);
            } else {
              this.loading = true;
              this.loadFiles(false);
            }
            let deleteComment = "The file " + filename + " has been deleted"
            this.commentDeleteFile(deleteComment)
            this.snackBar.open(
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_OK.MESSAGE"),
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_OK.TITLE")
              , {
                duration: 5000,
              });
          }
        },
          error => {
            this.loadFiles(false);
            this.loading = false;
            this.snackBar.open(
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.MESSAGE"),
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.TITLE")
              , {
                duration: 5000,
              });
          })
      }
      if (item.filename === filename) {
        this.files.splice(index, 1);
        this.filesName.splice(index, 1);
      }
    });
  }

  onClick() {
    const fileUpload = this.fileInputElement.nativeElement; fileUpload.onchange = () => {
      let wait: boolean = false;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.filesName.forEach(searchFile => {
          if (searchFile.filename == file.name) {
            wait = true;
          }
        });
        if (file.size > 11322929) {
          this.snackBar.open(
            this.translate.instant("The file " + file.name + " is too big to upload"),
            this.translate.instant("Max Size is 10 MB")
            , {
              duration: 5000,
            });
        } else {
          this.files.push({ data: file, inProgress: false, progress: 0 });
          if (!this.isEdit) {
            this.filesName.push({ filename: file.name });
          }
        }
      }
      if (this.isEdit) {
        if (wait) {
          let dialogRef = this.dialog.open(WarningDialog, {
            data: {
              title: this.translate.instant("MAPP-FITTINGOUT.FILE.VERSION.TITLE"),
              question: this.translate.instant("MAPP-FITTINGOUT.FILE.VERSION.MESSAGE"),
              icon: 'warning'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              console.log("same name");
              this.uploadFiles();
            } else {
              this.files = [];
              dialogRef.close();
            }
          });
        } else {
          this.uploadFiles();
        }
      }
    };
    fileUpload.click();
  }

  uploadFiles() {
    this.fileInputElement.nativeElement.value = '';
    for (let i = 0; i < this.files.length; i++) {
      let last = false;
      if (i == this.files.length - 1) {
        last = true;
      }
      this.uploadFile(this.files[i], last);
    }
  }

  uploadFile(file, last) {
    const formData: FormData = new FormData();
    formData.append('name', file.data.name);
    formData.append('file', file.data);
    file.inProgress = true;
    this.service.upload(formData, this.newFitting).subscribe((event: any) => {
      if (typeof (event) === 'object' && event.body === file.data.name) {

        if (last) {
          if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_REJECTED) {
            this.service.updateFittingComment(this.newFitting, MICROAPP_TEXT_REOPEN, this.auth.getCurrentUserName()).subscribe(ok => {
              this.loadFitting(this.newFitting.ticket_id);
            });
          }
          this.loadFiles(false);
          this.snackBar.open(
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.MESSAGE"),
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.TITLE")
            , {
              duration: 5000,
            });
          if (this.isEdit) {
            this.mailService.updateFittingout();
          }
        }
      }
    },
      error => {
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.TITLE")
          , {
            duration: 5000,
          });
        this.loadFiles(false);
      });
  }

  /**
   * @name onSubmit
   * @description the info is being sent to the backend
   */
  onSubmit() {
    // stop here if form is invalid
    this.loading = true;
    //EDIT
    this.service.updateFittingResolve(this.newFitting.ticket_id, this.textResolution, this.resolution, this.currentUser.mail, this.newFitting.owner, this.newFitting.reporter).subscribe((resp: Fitting) => {
      this.mailService.updateFittingout();
      this.loading = false;
      this.snackBar.open(
        this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.MESSAGE"),
        this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.TITLE")
        , {
          duration: 5000,
        });
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      }

    );

    this.closeDialog();
  }

  addComment() {
    const dialogAssign = this.dialog.open(CommentComponent, {
      width: '448px',
      height: '327px',
    });
    dialogAssign.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined && result != '') {
        this.service.updateFittingComment(this.newFitting, result, this.auth.getCurrentUserName()).subscribe(
          (resp) => {
            if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_REJECTED) {
              this.loadFitting(this.newFitting.ticket_id);
            }
            this.loadComments();
            this.snackBar.open(
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.MESSAGE"),
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.TITLE")
              , {
                duration: 5000,
              });

          },
          error => {
            this.snackBar.open(
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.MESSAGE"),
              this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.TITLE")
              , {
                duration: 5000,
              });
          });
      }
    });
  }

  changeResolution(resolutionName) {
    this.resolution = resolutionName;
    this.onSubmit();
  }

  // getOwner() {

  //   this.newFitting.owner = this.interalService.getOwner(this.currentUser);
  // }

  displayVisitLog() {
    this.displayLog = true;
  }
  closeVisitLog() {
    this.displayLog = false;
  }

  closeDialog() {
    console.log('closeDialog');
    this.dialogRef.close();
  }

  chips = [
    { name: MICROAPP_STATUS_CLOSED_APPROVED },
    { name: MICROAPP_STATUS_CLOSED_REJECTED }
  ];

}
export class FilesShow {
  filename: string;
}



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
