import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpsTenantQuotas } from '../../../lib/model/microapp-eps.tenan-quote.model';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';
import { Store } from '@ngrx/store';
import { EpsParkingSize } from '../../../lib/model/microapp-eps.parking-size.model';
import { EpsVehicleType } from '../../../lib/model/microapp-eps.vehicle-type.model';
import { EpsQuote } from '../../../lib/model/microapp-eps.quote.model';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { maxValueValidator, newParkingSizeMinValueValidator } from "../../../lib/utils/form-validations.directive"
import { MatTableDataSource } from '@angular/material/table';
import { EpsVehicleTypeSize } from '../../../lib/model/microapp-eps.vehicle-type-size.model';
import { EpsSpotsToAssign } from '../../../lib/model/microapp-eps.spotsToAssign.model';
import { EpsNewParkingSize } from '../../../lib/model/microapp-eps.new-parking-size.model';


@Component({
  selector: 'empusa-microapp-eps-parking-size-edit',
  templateUrl: './microapp-eps-parking-size-edit.component.html',
  styleUrls: ['./microapp-eps-parking-size-edit.component.css']
})
export class MicroappEpsParkingSizeEditComponent implements OnInit {
  oneTypeParkingSize: EpsParkingSize;
  parkingVehicleTypes: EpsVehicleType[];
  formGroup: FormGroup;
  loading: boolean = false;

  constructor(
    private epsServices: EpsServices,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    public dialogRef: MatDialogRef<MicroappEpsParkingSizeEditComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.oneTypeParkingSize = { ...data.oneTypeParkingSize };
    this.parkingVehicleTypes = data.parkingVehicleTypes;
  }


  ngOnInit(): void {
    this.formGroup = new FormGroup({});
    this.oneTypeParkingSize.vehicle_type.forEach(vt => {
      let control = new FormControl(vt.number_of_spots, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.maxLength(999)]);
      this.formGroup.addControl("vt#" + vt.vehicle_type_id, control);
      control = new FormControl(vt.oversell_ratio, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.maxLength(999)]);
      this.formGroup.addControl("oversell#" + vt.vehicle_type_id, control);
    })
    this.formGroup.setValidators(newParkingSizeMinValueValidator(this.oneTypeParkingSize));
  }

  getErrorMessage(controlName: string) {
    if (this.formGroup == undefined) {
      return
    }
    const hasError = this.formGroup.controls[controlName].errors;
    let errorTxt = ""
    if (hasError !== null) {
      const firstError = Object.keys(hasError)[0];
      switch (firstError) {
        case 'underAssign': {
          errorTxt = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.UNDER_ASSIGN');
          break
        }
        case 'required': {
          errorTxt = this.translate.instant('MAPP-EPS.VALIDATIONS.MANDATORY');
          break
        }
        default: {
          errorTxt = this.translate.instant('MAPP-EPS.VALIDATIONS.BAD_VALUE');
        }
      }
    }
    return errorTxt;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }


  getMinPossibleValue(vehicle_type_id: number) {
    const ps = this.oneTypeParkingSize.vehicle_type.find(vt => vt.vehicle_type_id == vehicle_type_id)
     // %
    // const actualAssigned = ps.number_of_spots + Math.floor(ps.number_of_spots * ps.oversell_ratio / 100) - ps.can_assign;
    // suma directa
    const actualAssigned = (ps.number_of_spots + ps.oversell_ratio) - ps.can_assign;
    return actualAssigned
  }

  getValue(vehicle_type_id: number) {
    const vtControl = this.formGroup.controls["vt#" + vehicle_type_id];
    const oversellControl = this.formGroup.controls["oversell#" + vehicle_type_id];
    if (vtControl && vtControl.valid &&
      oversellControl && oversellControl.valid) {
      const vtValue: number = parseInt(this.formGroup.controls["vt#" + vehicle_type_id].value);
      const vtOverSell: number = parseInt(this.formGroup.controls["oversell#" + vehicle_type_id].value);
       // %
      // return vtValue + Math.floor(vtValue * vtOverSell / 100);
      // suma directa   
      return vtValue + vtOverSell;
    }

    return "--"
  }

  doAction() {
    let newsizeSpots: EpsNewParkingSize[] = [];

    this.oneTypeParkingSize.vehicle_type.forEach(vt => {
      let newSize = new EpsNewParkingSize();
      newSize.spot_type_id = this.oneTypeParkingSize.spot_type_id;
      newSize.vehicle_type_id = vt.vehicle_type_id;
      newSize.number_of_spots = Number(this.formGroup.controls['vt#' + vt.vehicle_type_id].value);
      newSize.oversell_ratio = Number(this.formGroup.controls['oversell#' + vt.vehicle_type_id].value);
      newsizeSpots.push(newSize)
    })


    this.loading = true;
    this.epsServices.setParkingSize(newsizeSpots).subscribe(parkingSize => {
      this.loading = false;
      this.store.dispatch(epsActions.epsStoreParkingSize({ parkingSize }));
      this.dialogRef.close({ event: 'ok' });
      // TODO: SHOW ok message
    }, error => {
      this.loading = false;
      let txt = this.translate.instant('MAPP-EPS.GENERIC.UNKNOWN_ERROR');
      if (error instanceof HttpErrorResponse) {
        if (error.status === 409) { //oversize parking.
          txt = this.translate.instant('MAPP-EPS.DIALOG_NEW_PARKING_SIZE.LOW_ERROR');
        }
      }
      this.openSnackBar(txt, "X");
    }, () => {
      this.loading = false;
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }
}
