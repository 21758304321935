<form class="example-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div fxLayout="column">
        <div fxLayout="row" fxFlex="100%" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="33%">
                <mat-form-field>
                    <mat-label>{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.CHOOSE-DATE' | translate}}</mat-label>
                    <input matInput [formControl]="scheduleDateControl" [min]="minScheduleDate" [max]="maxScheduleDate"
                        [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="scheduleDateControl.invalid && (scheduleDateControl.dirty || scheduleDateControl.touched)">
                        {{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.DATE-ERROR' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="33%" style>
                <mat-form-field>
                    <mat-label>{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.START-TIME' | translate}}</mat-label>
                    <input matInput [ngxTimepicker]="toggleTimepickerStart" [format]="24" [disableClick]="true"
                        [formControl]="startTimeControl">
                    <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepickerStart" style="color:gray">
                    </ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #toggleTimepickerStart></ngx-material-timepicker>
                    <mat-error *ngIf="startTimeControl.invalid && (startTimeControl.dirty || startTimeControl.touched)">
                        {{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.START-TIME-ERROR' | translate}}
                    </mat-error>
                </mat-form-field>

                <!--mat-form-field>
                    <input matInput [maxLength]="vmsConfig.SIZE_TIME" [formControl]="startTimeControl"
                        placeholder="{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.START-TIME' | translate}}">
                    <mat-icon matSuffix>access_time</mat-icon>
                    <mat-error *ngIf="startTimeControl.invalid && (startTimeControl.dirty || startTimeControl.touched)">
                        {{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.START-TIME-ERROR' | translate}}</mat-error>
                </mat-form-field-->
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="33%">
                <mat-form-field>
                    <mat-label>{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME' | translate}}</mat-label>
                    <input matInput [ngxTimepicker]="toggleTimepickerEnd" [format]="24" [disableClick]="true"
                        [formControl]="endTimeControl">
                    <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepickerEnd" style="color:gray">
                    </ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #toggleTimepickerEnd></ngx-material-timepicker>
                    <mat-error *ngIf="endTimeControl.invalid  && (endTimeControl.dirty || endTimeControl.touched)">
                        {{getEndTimeMessage() | translate}}</mat-error>
                    <!--input matInput [maxLength]="vmsConfig.SIZE_TIME" [formControl]="endTimeControl"
                        placeholder="{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.END-TIME' | translate}}">
                    <mat-icon matSuffix>access_time</mat-icon>
                    <mat-error *ngIf="endTimeControl.invalid  && (endTimeControl.dirty || endTimeControl.touched)">
                        {{getEndTimeMessage() | translate}}</mat-error-->
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxFlex="100%" *ngIf="mode!='update-visit'">
            <mat-form-field class="example-textArea">
                <mat-label>{{'MAPP-VMS-NEW-VISIT-NEW-SCHEDULE.ABOUT' | translate}}</mat-label>
                <textarea matInput [formControl]="about"></textarea>
            </mat-form-field>
        </div>
    </div>
</form>