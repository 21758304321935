<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mcFittingList">
                    <mat-card-content class="filtersFittingList"> {{'MAPP-FITTINGOUT.LIST.TABLE.FILTER' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-FITTINGOUT.LIST.TABLE.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label> {{'MAPP-FITTINGOUT.FITTING.STATUS' | translate}}</mat-label>
                    <mat-chip-list formControlName="STATUS" class="statusChipsFilter" aria-label="statusChipsFilter">
                        <!--[selectable]="true"-->
                        <mat-chip *ngFor="let chip of chips" [selected]="chip.name==userFilter.status"
                            (click)="applyStatusFilter(chip.name)">{{chip.name}}</mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <mat-form-field>
                        <mat-label>{{'MAPP-FITTINGOUT.FITTING.STARTDATE' | translate}}</mat-label>
                        <input formControlName="START" matInput [matDatepicker]="basicPicker"
                            (dateInput)="applyStartDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="basicPicker">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #basicPicker></mat-datepicker>
                    </mat-form-field>
                    <p></p>
                    <mat-form-field>
                        <mat-label>{{'MAPP-FITTINGOUT.FITTING.ENDDATE' | translate}}</mat-label>
                        <input matInput formControlName="END" [matDatepicker]="basicPicker2"
                            (dateInput)="applyEndDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="basicPicker2">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #basicPicker2></mat-datepicker>
                    </mat-form-field>
                    <p></p>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-FITTINGOUT.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id="tablaFittingList">
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-FITTINGOUT.FITTING.DATE' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.date | date:localDateFormat}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="summary">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-FITTINGOUT.FITTING.SUMMARY' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.summary}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="reporter">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-FITTINGOUT.FITTING.REPORTER' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.reporter}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-FITTINGOUT.FITTING.STATUS' | translate}}</mat-header-cell>
                    <mat-cell [ngClass]="{'statusOpenDraft':(element.status === 'open'  && element.owner!=='FACILITY MANAGER'), 
                        'statusOpenValidate': (element.status === 'open'  && element.owner==='FACILITY MANAGER'),
                        'statusCloseApproved':(element.status === 'closed' && element.resolution==='approved'),
                        'statusCloseRejected':(element.status === 'closed' && element.resolution==='rejected')}"
                        *matCellDef="let element">
                        <mat-chip *ngIf="element.status === 'open'  && element.owner!=='FACILITY MANAGER'"
                            color="primary" selected>draft</mat-chip>
                        <mat-chip *ngIf="element.status === 'open'  && element.owner==='FACILITY MANAGER'"
                            color="primary" selected>validating</mat-chip>
                        <mat-chip *ngIf="element.status=='closed' && element.owner==='FACILITY MANAGER'" color="primary"
                            selected>{{element.resolution}}</mat-chip>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-FITTINGOUT.LIST.TABLE.ACTIONS' | translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-FITTINGOUT.TOOLTIP.COMMENT' | translate}}" mat-icon-button
                                (click)="commentFile(element.ticket_id)">
                                <mat-icon class="icActions">library_books</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <div *ngIf="isAllowCreate === true" class="button-new-container">
                <button (click)="createOrEdit('')" class="extend" mat-fab color="primary" aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'> {{'MAPP-FITTINGOUT.NEW.HEAD' | translate}}</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
            </div>
        </div>
    </mat-drawer-container>
</div>