export const MICROAPPFAKEDATABASE = {
    microappSecurityTree: {
        'tariff': {
            'access': {
                'USER': true,
                'ADMIN': true,
            }
        }
    },
    tarifs: [
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de1",
            "tarif_name": "Tarif-1",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de2",
            "tarif_name": "Tarif-2",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de3",
            "tarif_name": "Tarif-3",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de4",
            "tarif_name": "Tarif-4",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de5",
            "tarif_name": "Tarif-5",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de6",
            "tarif_name": "Tarif-6",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de7",
            "tarif_name": "Tarif-7",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de8",
            "tarif_name": "Tarif-8",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 1,
            "tags": [
                "Tag1"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6de9",
            "tarif_name": "Tarif-9",
            "total_price": 1.15,
            "unit_price": 1.15
        },
        {
            "number_of_units": 10,
            "tags": [
                "Tag2",
                "Tag3"
            ],
            "tarif_id": "36467ad3-4ad3-42c2-8c8f-976c4f98c6dA",
            "tarif_name": "Tarif-10",
            "total_price": 55.5,
            "unit_price": 5.55
        }
    ],
    allowedtags: [
        'tag1', 'tag2', 'tag3'
    ]
}