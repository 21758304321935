import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsChargeType } from './microapp-fbs-charge-type';
import { FbsTarifAdapter } from './microapp-fbs-tarif-adapter';

@Injectable({
  providedIn: "root",
})
export class FbsChargeTypeAdapter implements Adapter<MicroappFbsChargeType> {

  constructor(private fbsConfig: FbsConfig,
    private fbsTariffAdapter: FbsTarifAdapter,
  ) { }

  adapt(item: any): MicroappFbsChargeType {
    let chargeTypeAdapted = new MicroappFbsChargeType;
    chargeTypeAdapted.application_charge_code = item.application_charge_code;
    chargeTypeAdapted.billing_system_charge_code = item.billing_system_charge_code;
    chargeTypeAdapted.description = item.description;
    chargeTypeAdapted.id = item.id;
    if (item.tarif) {
      chargeTypeAdapted.tarif = this.fbsTariffAdapter.adapt(item.tarif);
    }
    return chargeTypeAdapted;
  }

}