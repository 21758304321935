import { MicroappVmsHost } from './microapp-vms-host.model';
import { Tenant } from '@empusa/empusa-core';

export class MicroappVmsVisitLog {
	date: Date;
	user: string;
	action: string;
	before: string;
	after: string;
	logText: string;
  result_check_mask: boolean;
  result_check_passive_liveness: boolean;
  result_check_temperature: number;
}
