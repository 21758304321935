import { MicroappFbsOwnerModel } from './microapp-fbs-owner';

export class MicroappFbsReservationModel {
    owner: MicroappFbsOwnerModel;
    referenceNumber: string;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    resourceId: number;
    groupId: string;
    resourceName: string;
    applied_charge_type: string;
    total_cost:string;    
    extra: any;
}