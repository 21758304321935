export const locale = {
  "lang": "en",
  "data": {
      "MAPP-TARIFF": { //mandatory - microapp context
          "DESCRIPTION": "Tariffs",
          "CLEAN": "Clean Filter",
          "CALENDAR": {
            "SELECT": "Select event type",
            "CAPTION": "Caption",
            "TYPES": {
              "ALL": "All",
              "GENERIC_PUBLIC_HOLIDAY": "Public Holidays"
            }
          },
          "FACILITY": {
              "TABS": {
                  "PRICES": "Tariffs",
                  "CALENDAR": "Calendar"
              },
              "SEARCH": "Search ...",
              "TENANTS": "TENANTS",
              "ACTIONS": "Actions",
              "NAME": "Name",
              "TAGS": "Tags",
              "NUMBERUNIT": "Number of Units",
              "UNITPRICE": "Unit Price",
              "TOTALPRICE": "Total Price",
              "CANCEL": "CANCEL",
              "CREATE": "CREATE",
              "TITLE": "NEW TARIFF",
              'NOTDELETEA':'Tariff selected can not be deleted. It is being used by: ',
              'NOTDELETEB':'Please remove existing dependencies before deletion. Thanks.',
              "GST": "*GST not included in this cost. GST will be applied additionally at the invoicing of this service."
          }
      }
  }
}
