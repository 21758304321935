import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@empusa/empusa-core';
import { VmsMasterConfiguration } from '../model/microapp-vms-config.model';
import { VmsConfig } from '../microapp-vms-config';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { VehicleType } from '../model/microapp-vms-vehicle';

@Injectable({
  providedIn: 'root',
})
export class MicroappVmsConfigService {

  constructor(private http: HttpClient,
    private vmsConfig: VmsConfig,
    @Inject(LOCALE_ID) public locale: string,
    private authservice: AuthenticationService,) {
  }

  getConfiguration(): Observable<VmsMasterConfiguration> {
    return this.http.get<VmsMasterConfiguration>(this.vmsConfig.URL_REST_BASE_CONFIG).
      pipe(map((data) => {
        let config = new VmsMasterConfiguration();
        config.check_in_mode = data.check_in_mode;
        config.visitor_mandatory_fields = data.visitor_mandatory_fields;
        config.request_license_plate = data.request_license_plate;
        config.temperature_units = data.temperature_units;
        config.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (data.parking_active) {
          config.parking_active = data.parking_active;
        }
        if (data.timezone && data.timezone != 'user') {
          config.timezone = data.timezone;
          config.offset = data.offset;
          config.offset_to_utc = data.offset_to_utc;
          config.first_day_week = data.first_day_week;
        } else {
          config.timezone = config.localTimeZone;
          config.offset_to_utc = new Date().getTimezoneOffset();
          config.first_day_week = getLocaleFirstDayOfWeek(this.locale);
          config.offset = undefined; // user's offset
        }
        config.available_vehicles = []
        let vlist = this.vmsConfig.default_vehicles;
        if (data.available_vehicles && data.available_vehicles.length > 0) {
          vlist = data.available_vehicles
        }
        vlist.forEach(v => {
          let vt = new VehicleType()
          vt.vehicle_code = v.vehicle_code;
          vt.vehicle_desc = v.vehicle_desc;
          vt.vehicle_icon = v.vehicle_icon;
          config.available_vehicles.push(vt);
        })
        console.debug("config", config);
        return config;
      }));
  }


}
