import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MicroappVmsVisit } from '../../../../lib/model/microapp-vms-visit.model';
import { MicroappVmsVisitGroup } from '../../../../lib/model/microapp-vms-visit-group.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VmsConfig } from '../../../../lib/microapp-vms-config';
import { Store } from '@ngrx/store';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import { getMasterConfig } from '../../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { take } from 'rxjs/operators';
import { VmsMasterConfiguration } from '../../../../lib/model/microapp-vms-config.model';


@Component({
  selector: 'empusa-microapp-vms-visit-detail',
  templateUrl: './microapp-vms-visit-detail.component.html',
  styleUrls: ['./microapp-vms-visit-detail.component.css']
})
export class MicroappVmsVisitDetailComponent implements OnInit {

  displayedColumns: string[] = ['img','name', 'company', 'parking'];
  visitDataSource : MatTableDataSource<MicroappVmsVisit>;
  visitGroup: MicroappVmsVisitGroup;
  offset: string;
  masterCfg: VmsMasterConfiguration;
  readonly localDateFormat = "dd/MM/yyyy";
  constructor(
    public vmsConfig: VmsConfig,
    private store: Store<vmsReducer.MicroappVmsStates>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visitGroup = data;
      this.store.select(getMasterConfig).pipe(take(1)).subscribe(masterCfg => {
        this.masterCfg = masterCfg;
        if (!this.masterCfg.parking_active){
          let i = this.displayedColumns.indexOf('parking');
          this.displayedColumns.splice(i,1);
        }
      })

      this.offset = this.vmsConfig.MASTER_CONFIG.offset;
  }

  ngOnInit(): void {
    this.visitDataSource = new MatTableDataSource<MicroappVmsVisit>(this.visitGroup.visits);
    console.log(this.visitGroup.visits);
  }
}
