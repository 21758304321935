<h2 class="titleNew">New Charge</h2>
<form [formGroup]="newChargeForm">

    <div>
        <mat-form-field class="formFieldNew">
            <input required matInput formControlName="name" placeholder="Name" name="name" mt-autofocus>
        </mat-form-field>

        <mat-form-field class="formFieldNew">
            <input required matInput formControlName="description" placeholder="Description" name="descrition" mt-autofocus>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="formFieldNew">
            <mat-label>Tariff Type</mat-label>
            <mat-select required formControlName="tariff">
                <mat-option *ngFor="let tarif of tarifList" [value]="tarif">{{tarif.tarif_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="formFieldNew">
            <mat-label>Tenants</mat-label>
            <mat-select required formControlName="tenants">
                <mat-option *ngFor="let tenant of tenantList" [value]="tenant.dn">{{tenant.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</form>

<div mat-dialog-actions class="buttonDetailCharge" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        Close</button>
    <button [disabled]="!newChargeForm.valid" (click)="onSubmit()" class="buttonConfirm" mat-raised-button
        color="primary">
        Save</button>
</div>