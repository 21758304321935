import { Injectable } from "@angular/core";
import { EpsConfig } from "../../../microapp-eps-cfg";
import { Adapter } from "../../microapp-eps.adapter";
import { EpsChargeType } from "../microapp-eps.charge-type.model";
import { EpsTarifAdapter } from "./microapp-eps-tarif-assign.adapter";

@Injectable({
    providedIn: "root",
  })
  export class EpsChargeTypeAdapter implements Adapter<EpsChargeType> {
  
    constructor(private epsConfig: EpsConfig,
      private epsTariffAdapter: EpsTarifAdapter,
    ) { }
  
    adapt(item: any): EpsChargeType {
      let chargeTypeAdapted = new EpsChargeType;
      chargeTypeAdapted.application_charge_code = item.application_charge_code;
      chargeTypeAdapted.description = item.description;
      chargeTypeAdapted.id = item.id;
      if (item.tarif) {
        chargeTypeAdapted.tarif = this.epsTariffAdapter.adapt(item.tarif);
      }
      return chargeTypeAdapted;
    }
  
  }