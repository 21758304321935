import { Component, Inject, OnInit, Optional } from '@angular/core';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EpsChargeType } from '../../../lib/model/charges_and_tariffs/microapp-eps.charge-type.model';
import { MicroappEpsChargesService } from '../../../lib/services/microapp-eps-charge.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';
import { EpsTariff } from '../../../lib/model/charges_and_tariffs/microapp-eps.tariff.model';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import * as microappEpsAppActions from '../../../lib/store/microapp-eps.actions';
import { EpsTenantTariff } from '../../../lib/model/charges_and_tariffs/microapp-eps.tenant-tariff.model';
import { EpsChargeTariffTenant } from '../../../lib/model/charges_and_tariffs/microapp-eps-charge-tariff-tenant.model';

@Component({
  selector: 'empusa-microapp-eps-tariff-update',
  templateUrl: './microapp-eps-tariff-update.component.html',
  styleUrls: ['./microapp-eps-tariff-update.component.css']
})
export class MicroappEpsTariffUpdateComponent implements OnInit {

  chargesAndTariffs: EpsChargeType[];
  formGroup: FormGroup;
  loading: boolean = false;
  public newTariffForm: FormGroup;
  chargeId: string;
  tenantId: string;
  newTariffId: string;
  assignTariffList: EpsTariff[];

  constructor(
    private chargeEps: MicroappEpsChargesService,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private formBuilder: FormBuilder,
    private epsService: EpsServices,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<MicroappEpsTariffUpdateComponent>,
    //@Optional() is used tocrip prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EpsChargeTariffTenant) {

    this.newTariffForm = this.formBuilder.group({
      tariff: ['', Validators.required],
    });
    if(data?.tariff?.tarif_id){
      this.f.tariff.setValue(data.tariff.tarif_id);
    }
    this.tenantId = data.tenant.uid;
    this.chargeId = data.charge.id;
    let tariff_void = new EpsTariff();
    tariff_void.tarif_id = 'unassign';
    tariff_void.tarif_name = 'Unassign tariff';
    tariff_void.unit_price = "--"
    let tempTariffList: EpsTariff[]= [];
    data.charge.available_tariffs.forEach(available_tariff =>{
      tempTariffList.push(available_tariff)
    })
    tempTariffList.unshift(tariff_void)
    this.assignTariffList = tempTariffList;
   
    
  }

  get f(): any { return this.newTariffForm.controls; }

  ngOnInit(): void { }

  onSubmit() {
    this.loading = true;
    this.newTariffId = this.f.tariff.value;
    this.chargeEps.assignTenant(this.tenantId, this.chargeId, this.newTariffId).subscribe(result => {
      this.loading = false;
      this.store.dispatch(microappEpsAppActions.epsGetTenantTariffs())
      this.snackBar.open(this.translate.instant("MAPP-EPS.TARIFF.OKTITLE"), "X", {
        duration: 5000,
      });
      this.dialogRef.close({ event: 'ok' });
    },
      error => {
        this.loading = false;
        this.snackBar.open(this.translate.instant("MAPP-EPS.TARIFF.ERRORMESSAGE"), "X", {
          duration: 5000,
        });
      }, () => {
        this.loading = false;
      });
  }



  compareTenantTariff(a: EpsTenantTariff, b: EpsTenantTariff) {
    const aName = a.tenant.name.toUpperCase();
    const bName = b.tenant.name.toUpperCase();

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }
}
