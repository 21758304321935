import { Injectable } from '@angular/core';
import { EpsVehicleType } from '../microapp-eps.vehicle-type.model';
import { Adapter } from '../microapp-eps.adapter';
import { EpsReservationType } from '../reservation/microapp-eps.reservation-type.model';
import { EpsSpotType } from '../microapp-eps.spot-type.model';
import { EpsAssignedChargeType } from './microapp-eps.assigned-charge-types.model';


@Injectable({
  providedIn: "root",
})
export class EpsAssignedChargeTypeAdapter implements Adapter<EpsAssignedChargeType> {

  constructor() { };


  adapt(item: any): EpsAssignedChargeType {
    let chargeAdapted = new EpsAssignedChargeType;

    let vehicle_type = new EpsVehicleType;
    vehicle_type.vehicle_type_id = item.vehicle_type_id;
    vehicle_type.vehicle_short_desc = item.vehicle_short_desc;
    vehicle_type.vehicle_icon = item.vehicle_icon;
    vehicle_type.vehicle_desc = item.vehicle_desc;
    chargeAdapted.vehicle_type = vehicle_type

    let spot_type = new EpsSpotType;
    spot_type.spot_type_id = item.spot_type_id;
    spot_type.spot_short_desc = item.spot_short_desc;
    spot_type.spot_desc = item.spot_desc;
    chargeAdapted.spot_type = spot_type;

    let reservation_type = new EpsReservationType;
    reservation_type.reservation_type_id = item.reservation_type_id;
    reservation_type.reservation_types_short_desc = item.reservation_types_short_desc;
    reservation_type.reservation_types_desc = item.reservation_types_desc;
    chargeAdapted.reservation_type = reservation_type;

    chargeAdapted.charge_type = item.charge_type;
    chargeAdapted.vehicle_spottype_reservationtype_id = item.vehicle_spottype_reservationtype_id;
    return chargeAdapted
  }
}
