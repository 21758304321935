import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService, AuthenticationService } from '@empusa/empusa-core';
import { UserModule } from '../pages/microapp-usermanagement.user.module';
import { UserManagementFakeBackend } from '../fake/fake.backend';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME = "users";
export const MICRAPP_ACCESS_USERS = "access"

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: []

})
export class MicroappUsermanagementModule implements EmpusaMicroApp {
  name: string = "users";
  description: string = 'MAPP-USERMANAGEMENT.HOME.HEAD'
  icon: string = "supervised_user_circle";
  page = UserModule;
  homeApp: boolean = false;
  path: string = "users";
  order: number = 2;
  fakebackend: EmpusaFakeBackend = new UserManagementFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    this.translationLoader.loadTranslations(english);
  }
}