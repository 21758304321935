import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FbsConfig } from './microapp-facilitiesbooking-config';
import { Observable } from 'rxjs';
import { MicroappFbsReservationModel } from '../client/dto/microapp-fbs-reservation';
import { FbsResourceAdapter } from '../client/dto/microapp-fbs-resource-adapter';
import { MicroappFbsAccessoryReservationModel } from '../client/dto/microapp-fbs-reserve-accessory';
import { MicroappFbsResponse } from '../client/dto/microapp-fbs-response';
import { MicroappFbsReservationGroupModel } from '../client/dto/microapp-fbs-reservation-group';
import { FbsReservationAdapter } from '../client/dto/microapp-fbs-reservation-adapter';
import { FbsReservationGroupAdapter } from '../client/dto/microapp-fbs-reservation-group-adapter';
import { MicroappFbsReservationSlotModel } from '../client/dto/microapp-fbs-reservation-slot';
import { MicroappFbsResourceModel } from '../client/dto/microapp-fbs-resource';
import { FbsResourceListAdapter } from '../client/dto/microapp-fbs-resource-list-adapter';
import { MicroappFbsResourceListModel } from '../client/dto/microapp-fbs-resource-list';
import { MicroappFbsResourceGroup } from '../client/dto/microapp-fbs-resource-group';

@Injectable({
  providedIn: 'root',
})
export class MicroappFacilitiesbookingService {

  constructor(private http: HttpClient,
    private facilityAdapter: FbsResourceAdapter,
    private fbsResourceListAdapter: FbsResourceListAdapter,
    private reservationAdapter: FbsReservationAdapter,
    private reservationGroupAdapter: FbsReservationGroupAdapter,
    private fbsConfig: FbsConfig) {
  }

  /**
   * Gets all facilities
   * @returns MicroappFbsFacilityModel[]
   */
  getAllFacilities(): Observable<MicroappFbsResourceListModel> {
    return this.http.get<MicroappFbsResourceListModel>(this.fbsConfig.URL_REST_BASE + 'facilities').
      //pipe(map((data) => data));
      pipe(map((data) => this.fbsResourceListAdapter.adapt(data, null, null, null)));
  }


  // passing the user, if a slot is reserved by the user it is marked as available for booking
  public checkAvailableResource(startDate: Date, endDate: Date,
    facility_id: string,  referenceNumber: string): Observable<MicroappFbsResourceListModel> {

    let params = new HttpParams();
    let searchStartDate = new Date(startDate);
    searchStartDate.setHours(0, 0, 0, 0);
    params = params.set('startDateTime', searchStartDate.toISOString());

    let searchEndDate = new Date(endDate);
    searchEndDate.setHours(23, 59, 59, 0);
    params = params.set('endDateTime', searchEndDate.toISOString());

    let facilitiesUrl = 'facilities/availability';
    if (facility_id != null) {
      facilitiesUrl += '/' + facility_id;
    }

    return this.http.get<MicroappFbsResourceListModel>(this.fbsConfig.URL_REST_BASE + facilitiesUrl, { params: params })
      .pipe(map((data) => this.fbsResourceListAdapter.adapt(data, startDate, endDate,[referenceNumber])));
  }

    // passing the user, if a slot is reserved by the user it is marked as available for booking
  public getAvailableAccessory(startDate: Date, endDate: Date,
    group_id: string, referenceNumber: string[]): Observable<MicroappFbsResourceModel[]> {

    let params = new HttpParams();
    let searchStartDate = new Date(startDate);
    searchStartDate.setHours(0, 0, 0, 0);
    params = params.set('startDateTime', searchStartDate.toISOString());

    let searchEndDate = new Date(endDate);
    searchEndDate.setHours(23, 59, 59, 0);
    params = params.set('endDateTime', searchEndDate.toISOString());

    let facilitiesUrl = 'accessory/availability';
    facilitiesUrl += '/' + group_id;

    return this.http.get<MicroappFbsResourceListModel>(this.fbsConfig.URL_REST_BASE + facilitiesUrl, { params: params })
      .pipe(map((data) => {
        return data.resources.map((oneData) => this.facilityAdapter.adapt(oneData, startDate, endDate, referenceNumber));
      }));
  }


  public getFacility(facilityId: string) {
    const param: string = '?param=' + facilityId;
    return this.http.get(this.fbsConfig.URL_REST_BASE + 'facilities/getFacility' + param).pipe(
      map((data) => data));
  }



  newResource(facility: MicroappFbsResourceModel): Observable<any> {
    return this.http.post<any>(this.fbsConfig.URL_REST_BASE + 'resources', facility).pipe(
      map((response) => response as any)
    );
  }

  updateResource(resource: MicroappFbsResourceModel, ids: number[]): Observable<any> {
    return this.http.put<any>(this.fbsConfig.URL_REST_BASE + 'resources/' + ids, resource).pipe(
      map((response) => response as any)
    );
  }

  newResourceGroup(facility: MicroappFbsResourceGroup): Observable<any> {
    return this.http.post<any>(this.fbsConfig.URL_REST_BASE + 'resource_groups', facility).pipe(
      map((response) => response as any)
    );
  }

  updateResourceGroup(resource: MicroappFbsResourceGroup, id: string): Observable<any> {
    return this.http.put<any>(this.fbsConfig.URL_REST_BASE + 'resource_groups/' + id, resource).pipe(
      map((response) => response as any)
    );
  }

  deleteFacilityGroup(resourceId: string): Observable<any> {
    return this.http.delete(this.fbsConfig.URL_REST_BASE + 'resource_groups/' + resourceId).
      pipe(map((response) => response as any));
  }

  reescaleAccessores(availables: number, resourceGroupId: string): Observable<any> {
    return this.http.put<any>(this.fbsConfig.URL_REST_BASE + 'resources/resize/accessories/' + resourceGroupId, { availableResources: availables }).pipe(
      map((response) => response as any)
    );
  }

  reescaleFacility(availables: number, resourceId: string): Observable<MicroappFbsResponse> {
    return this.http.put<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'resources/resize/facility/' + resourceId, { availableResources: availables }).pipe(
      map((response) => response as MicroappFbsResponse)
    );
  }

  deleteFacility(resourceId: string): Observable<any> {
    return this.http.delete(this.fbsConfig.URL_REST_BASE + 'resources/' + resourceId).
      pipe(map((response) => response as any));
  }

  deleteAccessory(resourcesIds: number[]): Observable<MicroappFbsResponse> {
    return this.http.delete(this.fbsConfig.URL_REST_BASE + 'resources/' + resourcesIds).
      pipe(map((response) => response as MicroappFbsResponse));
  }

  setResourceUnavailable(resourcesIds: number[]): Observable<any> {
    return this.http.put(this.fbsConfig.URL_REST_BASE + 'resources/unavailable/' + resourcesIds, null).
      pipe(map((response) => response as any));
  }

  getResevations(resourceId: string, startDateTime?: Date): Observable<MicroappFbsReservationGroupModel[]> {
    let params = "?resourceId=" + resourceId;
    if (startDateTime) {
      params += "&startDateTime=" + startDateTime;
    }
    return this.http.get<MicroappFbsReservationModel[]>(this.fbsConfig.URL_REST_BASE + 'reservations' + params).
      pipe(map((data) => {
        return this.generateReservationGroups(data);
      }));
  }

  getMyResevations(userId: string, startDateTime?: Date, endDateTime?: Date, tenant_dn?: string): Observable<MicroappFbsReservationGroupModel[]> {

    let params = new HttpParams();

    params = params.set('userId', userId)

    if(startDateTime){
      params = params.set('startDateTime', startDateTime.toISOString());
    }else {
      let from = new Date();
      from.setHours(0,0,0,0);
      params = params.set('startDateTime', from.toISOString());
    };

    if(endDateTime){
      params = params.set('endDateTime', endDateTime.toISOString());
    }

    if(tenant_dn){
      params = params.set('tenant_dn', tenant_dn);
    }

    return this.http.get<MicroappFbsReservationModel[]>(this.fbsConfig.URL_REST_BASE + 'reservations', {params}).
      pipe(map((data) => {
        return this.generateReservationGroups(data);
      }));
  }

  postFacilityReservation(reservation: MicroappFbsReservationSlotModel): Observable<MicroappFbsResponse> {
    return this.http.post<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/facility', reservation).pipe(
      map((response) => response as any)
    );
  };

  putFacilityReservation(reservation: MicroappFbsReservationSlotModel): Observable<any> {
    return this.http.put<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/facility/' + reservation.referenceNumber, reservation).pipe(
      map((response) => response as any)
    );
  };

  postAccessoryReservation(reservation: MicroappFbsAccessoryReservationModel): Observable<MicroappFbsResponse> {
    return this.http.post<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/accessory', reservation).pipe(
      map((response) => response as MicroappFbsResponse)
    );
  }

  postGroupReservation(reservation: MicroappFbsReservationGroupModel): Observable<MicroappFbsResponse> {
    return this.http.post<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/facilities-group', reservation).pipe(
      map((response) => response as MicroappFbsResponse)
    );
  }

  putAccessoryReservation(reservationGroupId: string, reservation: MicroappFbsAccessoryReservationModel): Observable<MicroappFbsResponse> {
    return this.http.put<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/accessory/' + reservationGroupId, reservation).pipe(
      map((response) => response as MicroappFbsResponse)
    );
  }

  putGroupReservation(reservationGroupId: string, reservation: MicroappFbsReservationGroupModel): Observable<MicroappFbsResponse> {
    return this.http.put<MicroappFbsResponse>(this.fbsConfig.URL_REST_BASE + 'reservations/facilities-group/' + reservationGroupId, reservation).pipe(
      map((response) => response as MicroappFbsResponse)
    );
  }

  deleteReservationAccessory(reservation_group_id: string): Observable<any> {
    return this.http.delete(this.fbsConfig.URL_REST_BASE + 'reservation/accessory/' + reservation_group_id).
      pipe(map((data) => data as any));
  }

  deleteReservationFacility(reservation_reference_number: string): Observable<any> {
    return this.http.delete(this.fbsConfig.URL_REST_BASE + 'reservation/facility/' + reservation_reference_number).
      pipe(map((data) => data as any));
  }

  private generateReservationGroups(reservations: MicroappFbsReservationModel[]): MicroappFbsReservationGroupModel[] {
    let reservations_grouped: MicroappFbsReservationGroupModel[] = [];

    reservations.forEach(one_reservation => {
      const reservation = this.reservationAdapter.adapt(one_reservation);
      if (reservation.groupId) {
        let group = reservations_grouped.find(x => x.reservationGroupId === reservation.groupId);
        if (group) {
          group.reservations.push(reservation);
        } else {
          reservations_grouped.push(this.reservationGroupAdapter.adapt(one_reservation));
        }
      } else {
        reservations_grouped.push(this.reservationGroupAdapter.adapt(one_reservation));
      }
    });
    return reservations_grouped;
  }



}
