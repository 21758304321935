<h2 class="titleNew">{{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TITLE' | translate}}<button class="closeSizeParking"
        mat-icon-button mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
    </button></h2>
<p>{{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.TYPE' | translate}} {{oneTypeParkingSize.spot_short_desc}}</p>
<div>
    <form [formGroup]="formGroup" (ngSubmit)="doAction()">
        <div class="vehicleTypeSpot" *ngFor="let vehicle_type of oneTypeParkingSize.vehicle_type">
            <mat-form-field>
                <mat-label>{{vehicle_type.vehicle_short_desc}}</mat-label>
                <input matInput type="number" formControlName="vt#{{vehicle_type.vehicle_type_id}}" min="0">
                <span matPrefix>
                    <mat-icon>{{vehicle_type.vehicle_icon}}</mat-icon>
                </span>
                <mat-error>
                    {{getErrorMessage("vt#"+vehicle_type.vehicle_type_id) | translate}}
                </mat-error>
            </mat-form-field>
            {{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.PRODUCT' | translate}}
            <mat-form-field>
                <mat-label>{{vehicle_type.vehicle_short_desc}} {{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.OVERSELL' | translate}}</mat-label>
                <input matInput type="number" formControlName="oversell#{{vehicle_type.vehicle_type_id}}" min="0">
                <!-- si es suma directa quitar el % -->
                <!-- <span matSuffix>&nbsp;%</span> -->
                <mat-error>
                    {{'MAPP-EPS.VALIDATIONS.BAD_VALUE' | translate}}
                </mat-error>
            </mat-form-field>
            {{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.EQUIAL' | translate}}
            {{getValue(vehicle_type.vehicle_type_id)}} ({{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.NOT_LESS_THAN' |
            translate}}{{getMinPossibleValue(vehicle_type.vehicle_type_id)}})
        </div>
        <p></p>
    </form>
</div>
<div class="buttonDetail" mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()">{{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.CANCEL' | translate}}</button>
    <button class="buttonConfirm" [disabled]="!formGroup.valid || !formGroup.touched" mat-raised-button color="primary"
        (click)="doAction()">{{'MAPP-EPS.DIALOG_NEW_PARKING_SIZE.UPDATE' | translate}}</button>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>