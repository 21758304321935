import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsReservationGroupModel } from './microapp-fbs-reservation-group';
import { MicroappFbsReservationModel } from './microapp-fbs-reservation';
import { FbsReservationAdapter } from './microapp-fbs-reservation-adapter';
import { MicroappFbsOwnerModel } from './microapp-fbs-owner';

@Injectable({
  providedIn: "root",
})
export class FbsReservationGroupAdapter implements Adapter<MicroappFbsReservationGroupModel> {

  constructor(private fbsConfig: FbsConfig,
    private reservationAdapter: FbsReservationAdapter,
  ) { }

  adapt(reservation: MicroappFbsReservationModel): MicroappFbsReservationGroupModel {

    const item = this.reservationAdapter.adapt(reservation);

    let reservationGroup = new MicroappFbsReservationGroupModel;
    reservationGroup.description = item.description;
    reservationGroup.endDate = new Date(item.endDate);
    reservationGroup.startDate = new Date(item.startDate);
    let owner = new MicroappFbsOwnerModel;
    owner.emailAddress = item.owner.emailAddress;
    owner.firstName = item.owner.firstName;
    owner.lastName = item.owner.lastName;
    owner.userId = item.owner.userId;
    owner.tenant_name = item.owner.tenant_name;
    reservationGroup.owner = owner;
    reservationGroup.title = item.title;
    reservationGroup.applied_charge_type = item.applied_charge_type;
    reservationGroup.total_cost = item.total_cost;
    reservationGroup.reservationGroupId = item.groupId;
    reservationGroup.resourceName = item.resourceName;
    reservationGroup.reservations = [];
    reservationGroup.reservations.push(item);
    reservationGroup.extra = item.extra;
    if (item.extra && item.extra.resource_group) {
      reservationGroup.resourceName = item.extra.resource_group.resource_group_name;
    }
    return reservationGroup;
  }


}