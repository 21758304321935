<div class="header">
  <div class="visitor_container">
    <form class="add_visitor_container" *ngIf="visitorsFormGroup" [formGroup]="visitorsFormGroup" #formDirective="ngForm"
      (ngSubmit)="addVisitor(visitorsFormGroup,formDirective)">
      <mat-form-field class="text">
        <mat-label>{{'MAPP-EPS.TENANT.VISITOR.SURNAME' | translate}}</mat-label>
        <input required #visitor_surname matInput type="text" formControlName="surname">
        <mat-error *ngIf="visitorsFormGroup.controls.surname.errors && visitorsFormGroup.controls.surname.errors.required">
          {{'MAPP-EPS.TENANT.VISITOR.REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="text">
        <mat-label>{{'MAPP-EPS.TENANT.VISITOR.GIVENNAME' | translate}}</mat-label>
        <input required #visitor_givenname matInput type="text" formControlName="givenName">
        <mat-error *ngIf="visitorsFormGroup.controls.givenName.errors && visitorsFormGroup.controls.givenName.errors.required">
          {{'MAPP-EPS.TENANT.VISITOR.REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>


      <mat-form-field class="textEmail">
        <mat-label>{{'MAPP-EPS.TENANT.VISITOR.EMAIL' | translate}}</mat-label>
        <input required #visitor_email matInput type="text" formControlName="mail">
        <mat-error *ngIf="visitorsFormGroup.controls.mail.errors && visitorsFormGroup.controls.mail.errors.email">
          {{'MAPP-EPS.TENANT.VISITOR.INVALID_EMAIL' | translate}}
        </mat-error>
      </mat-form-field>
      <!--
      <button [disabled]="visitorsFormGroup.invalid" mat-raised-button color="primary"
        (click)="addVisitor(visitorsFormGroup,formDirective)">{{'MAPP-EPS.TENANT.VISITOR.ADD_VISITOR'
        | translate}}</button>
-->
      <button [disabled]="visitorsFormGroup.invalid" mat-raised-button color="primary"
        type="submit">{{'MAPP-EPS.TENANT.VISITOR.ADD_VISITOR' | translate}}</button>
    </form>
  </div>

  <!-- VISITORS TABLE -->
  <table mat-table matSort [dataSource]="selectedUsersDataSource" class="selection_step_table">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>{{'MAPP-EPS.TENANT.VISITOR.TABLE.NAME' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon>person</mat-icon>
        {{element.surname}}, {{element.givenName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="mail">
      <mat-header-cell *matHeaderCellDef>{{'MAPP-EPS.TENANT.VISITOR.TABLE.EMAIL' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.mail}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element;">
        <mat-icon class="delete-visitor" (click)="deleteVisitor(element)">delete</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedUsersDisplayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedUsersDisplayedColumns;"></mat-row>0

  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
</div>
