import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empusa-microapp-vms-visitor-container',
  templateUrl: './microapp-vms-visitor-container.component.html',
  styleUrls: ['./microapp-vms-visitor-container.component.css']
})
export class MicroappVmsVisitorContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  displayLog: Boolean = false;
   

  displayNewLog(){
    this.displayLog = true;

  }
  closeNewLog(){

    this.displayLog = false;
  }


}
