import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'toolbarVisit',
    styleUrls: ['./toolbarVisit.css'],
    templateUrl: 'toolbarVisit.html',
  })
  export class ToolbarVisit {

    displayLog: Boolean = false;

    constructor(
      private dialogRef: MatDialogRef<any>,
    ) { }
  

    displayNewLog(){
        this.displayLog = true;
  
      }
      closeNewLog(){
  
        this.displayLog = false;
      }

      onClose() {
        this.dialogRef.close(null);
      }
  }