import { EpsState } from './../store/microapp-eps.reducer';
import { EpsAssignedChargeType } from '../model/charges_and_tariffs/microapp-eps.assigned-charge-types.model';
import { EpsVehicleType } from '../model/microapp-eps.vehicle-type.model';
import { EpsReservationType } from '../model/reservation/microapp-eps.reservation-type.model';
import { EpsSpotType } from '../model/microapp-eps.spot-type.model';

export class ChargeTypeAssociationUtils {
  static getNotExistingCombinationsForAssociations( epsState: EpsState): EpsAssignedChargeType[] {
    const currentAssociations = epsState.assignedCharges;
    const notExistingAssociations: EpsAssignedChargeType[] = [];

    epsState.parkingVehicleTypes.forEach(vehicleType => {
      epsState.spotTypes.forEach(spotType => {
        epsState.reservationTypes.forEach(reservationType => {
          const selectedAssociation = currentAssociations.find(association => {
            return association.vehicle_type.vehicle_type_id === vehicleType.vehicle_type_id &&
            association.spot_type.spot_type_id === spotType.spot_type_id &&
            association.reservation_type.reservation_type_id === reservationType.reservation_type_id;
          });
          if (!selectedAssociation) {
            const newNotExistingAssociation = new EpsAssignedChargeType();
            newNotExistingAssociation.vehicle_type = vehicleType;
            newNotExistingAssociation.spot_type = spotType;
            newNotExistingAssociation.reservation_type = reservationType;
            notExistingAssociations.push(newNotExistingAssociation);
          }
        });
      });
    });
    return notExistingAssociations;
  }

  static filterNotExistingCombinationsForAssociations(vehicleType: EpsVehicleType,
                                                      spotType: EpsSpotType,
                                                      reservationType: EpsReservationType,
                                                      notExistingAssociations: EpsAssignedChargeType[]): EpsAssignedChargeType[]{
    return notExistingAssociations.filter(association => {
      return (!vehicleType || association.vehicle_type.vehicle_type_id === vehicleType.vehicle_type_id) &&
             (!spotType || association.spot_type.spot_type_id === spotType.spot_type_id) &&
             (!reservationType || association.reservation_type.reservation_type_id === reservationType.reservation_type_id);
    });
  }

}
