import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Site } from '@empusa/empusa-core';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  url: string = this.environment.urlbase + 'usermanagement/services/';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  allSite(): any {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.get(this.url + 'sites', { headers }).pipe(
      map(data => data)
    );
  }

  getSite(uid: String) {
    return this.http.get(this.url + 'sites/' + uid).pipe(
      map(data => data as Site)
    );
  }

  addSite(site: Site) {
    let body = JSON.stringify(site);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.post(this.url + 'sites', body, { headers }).pipe(map(resp => resp));
  }

  updateUser(site: Site) {
    let body = JSON.stringify(site);
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'sites/' + site.uid, body, { headers }).pipe(map(resp => resp));
  }

  deleteSite(uid: String) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.delete(this.url + 'sites/' + uid, { headers }).pipe(map(resp => resp));
  }
}
