import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'microapp-faultreport-escalation-fault-component',
    templateUrl: './microapp-faultreport.component-escalation-fault.component.html',
    styleUrls: ['./microapp-faultreport.component-escalation-fault.component.css']
  })
  export class EscalationDialog{
      comment:string;
      
      constructor(public dialogRef:MatDialogRef<EscalationDialog>,
       private translate: TranslateService){}
  
        onNoClick():void{
          this.dialogRef.close();
        }
  
  }
  