import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceModel } from '../../../client/dto/microapp-fbs-resource';
import { MicroappFbsResourceSlotModel } from '../../../client/dto/faciliti-slots';
import { select, Store } from '@ngrx/store';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import { take } from 'rxjs/operators';
import { selectFbsUser } from '../../../lib/store/microapp-fbs-selectors';
import { MicroappFbsUserModel } from '../../../client/dto/microapp-fbs-user';
import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import { MicroappFbsReservationSlotModel } from '../../../client/dto/microapp-fbs-reservation-slot';
import { SearchDates } from '../../../client/microapp-fbs.interfaces';
import { MicroappFbsReservationGroupModel } from '../../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsMessageHandlerService } from '../../../lib/microapp-fbs-message-handler.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';
import { FbsResourceAdapter } from '../../../client/dto/microapp-fbs-resource-adapter';
import { MicroappFbsCheckAvailabilityComponent } from '../components/check-availability/microapp-fbs-check-availability.component';
import { MicroappFbsResourceListModel } from '../../../client/dto/microapp-fbs-resource-list';
import { Subscription } from 'rxjs';
import {SliderSelectionModel} from "../components/generics/range-slider/range-slider.component";
import {MicroappFbsSliderFacilitySlotsComponent} from "../components/slots/microapp-fbs-slider-facility-slots/microapp-fbs-slider-facility-slots.component";
import {clone, cloneDeep} from "lodash";


@Component({
  selector: 'empusa-microapp-bfs-make-a-reservation',
  templateUrl: './microapp-bfs-make-a-reservation.component.html',
  styleUrls: ['./microapp-bfs-make-a-reservation.component.css']
})
export class MicroappBfsMakeAReservationComponent implements OnInit, OnDestroy {

  facility: MicroappFbsResourceModel;
  resourceImages: string[];
  theBookDate: SearchDates;

  dateChecked: SearchDates;
  unitaryCost: number;

  fbsUser: MicroappFbsUserModel;

  mode_confirm = false;
  loading: boolean;
  reservation_mode_new: boolean = true;
  reservation_to_update: MicroappFbsReservationGroupModel;
  availability_checked = true;

  public reservationForm: FormGroup;
  title = new FormControl("", [Validators.required, Validators.minLength(1)]);
  description = new FormControl();
  reservation: MicroappFbsReservationSlotModel;

  todayDate = new Date();
  avalibilityStartDate;
  selectedDay = new FormControl();
  selectedDaySub$: Subscription;
  selectedDayObject: SearchDates;

  initialSliderValues: SliderSelectionModel;

  renderSliderComponent = true;

  @ViewChild('checkAvailability') checkAvailabilityComponent: MicroappFbsCheckAvailabilityComponent;
  @ViewChild('sliderFacilitySlots') sliderFacilitySlots: MicroappFbsSliderFacilitySlotsComponent;
  maxDay: Date;


  constructor(private dialogRef: MatDialogRef<any>,
    public fbsConfig: FbsConfig,
    private facilityAdapter: FbsResourceAdapter,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    private cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data) {

    let d = new Date();
    this.maxDay = new Date(d.setDate(d.getDate() + this.fbsConfig.DAYS_IN_ADVANCE));
    this.selectedDay.setValue('');
    this.facility = cloneDeep(data[0]);
    this.resourceImages = this.facility.image_urls;
    if (data[1]) {
      this.theBookDate = cloneDeep(data[1]);
      this.avalibilityStartDate = data[1].searchStartDate;
      this.selectedDay.setValue(data[1].searchStartDate)
      this.initialSliderValues = {start: data[1].searchStartDate, end: data[1].searchEndDate}
      // if (!data[0].slots) {
        let newDateObject = new SearchDates();
        newDateObject.searchStartDate = data[1].searchStartDate;
        newDateObject.searchEndDate = data[1].searchEndDate;
        this.selectedDayObject = newDateObject;
        this.calculateCost(this.selectedDayObject);
        this.checkFacilitieAvailability(this.selectedDayObject);
      // }
    }
    else {
      this.availability_checked = false;
    }

    this.reservationForm = new FormGroup({
      title: this.title,
      description: this.description,
    });

    if (data[2]) {
      this.reservation_mode_new = false;
      this.reservation_to_update = { ...data[2] };
      this.availability_checked = false;
      this.title.patchValue(this.reservation_to_update.title);
      this.description.patchValue(this.reservation_to_update.description);
    }
    this.dateChecked = this.theBookDate;

    this.selectedDaySub$ = this.selectedDay.valueChanges.subscribe(newValue => {
      let newDate = new Date(newValue);
      let newDateObject = new SearchDates();
      let newDateEnd = new Date(newValue);
      newDate.setHours(5);
      newDateEnd.setHours(10);
      newDateObject.searchStartDate = newDate;
      newDateObject.searchEndDate = newDateEnd;
      this.selectedDayObject = newDateObject;
      this.checkFacilitieAvailability(this.selectedDayObject);
    })
  }



  ngOnInit(): void {
    this.store.pipe(select(selectFbsUser)).pipe(take(1)).subscribe(valor => {
      this.fbsUser = valor;
    });


  }

  checkInDayFacilitieAvailability($event: SearchDates) {
    let reservationsList: string[] = [];
    if (this.reservation_to_update) {
      reservationsList.push(this.reservation_to_update.reservations[0].referenceNumber);
    }

    const newCheck = this.facilityAdapter.adapt(this.facility, $event.searchStartDate,
      $event.searchEndDate,
      reservationsList);
    this.facility = newCheck;
    this.dateChecked = $event;
    let sendo = $event.searchEndDate.getMinutes();
    let starto = $event.searchStartDate.getMinutes();
($event);


    if (sendo == starto && $event.searchStartDate.getTime() != $event.searchEndDate.getTime()) {
      this.availability_checked = true;
      this.calculateCost($event);
    } else {
      this.availability_checked = false;
    }
    this.cdRef.detectChanges();
  }


  public checkFacilitieAvailability($event: SearchDates) {


    let startDate = $event.searchStartDate;
    let endDate = $event.searchEndDate;
    this.loading = true;

    let call = this.facilityBookingService.checkAvailableResource(startDate, endDate, this.facility.resourceId, null);
    if (!this.reservation_mode_new) {
      call = this.facilityBookingService.checkAvailableResource(startDate, endDate, this.facility.resourceId, this.reservation_to_update.reservations[0].referenceNumber);
    }

    //call.subscribe((data: MicroappFbsResourceModel[]) => {
    call.subscribe((data: MicroappFbsResourceListModel) => {

      this.facility = data.resources[0];
      this.dateChecked = $event;
      this.availability_checked = true;
      this.calculateCost($event);
      this.reloadSliderComponent();
    }, error => {

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }



  calculateCost(day : SearchDates) {

    // if (!this.dateChecked) {
    //   return;
    // }
    // if (!this.facility.slots) {
    //   return;
    // }
    // let slots_to_book = 0;
    // this.facility.slots.forEach(slot => {

    //   if (slot.startDateTime >= this.dateChecked.searchStartDate && slot.endDateTime <= this.dateChecked.searchEndDate) {
    //     if(slot.reservation != null){
    //       let startDateReservation = new Date(slot.reservation.startDate);
    //       let endDateReservation = new Date(slot.reservation.endDate);
    //       let startDateNumber = startDateReservation.getHours();
    //       let endDateNumber = endDateReservation.getHours();
    //       let diffDateRservation = endDateNumber - startDateNumber;
    //       let bookedSlot = diffDateRservation * 2;
    //       slots_to_book = slots_to_book + bookedSlot;
    //     } else {
    //     slots_to_book++;
    //     }
    //   }
    // })
    // console.log("slots to book", slots_to_book, " - tariff ", this.facility.tarif.unit_price )
    // this.unitaryCost = ((slots_to_book / 2) * (this.facility.tarif.unit_price * 100)) / 100;
    let diffhour = day.searchEndDate.getHours() - day.searchStartDate.getHours();
    this.unitaryCost = (diffhour * (this.facility.tarif.unit_price * 100)) / 100;

  }

  close() {
    this.dialogRef.close(false);
  }


  go_to_confirm() {
    this.reservation = new MicroappFbsReservationSlotModel();
    this.reservation.userId = +this.fbsUser.id;
    if (this.availability_checked) {
      this.initialSliderValues = {start : new Date(), end: new Date()};
      this.reservation.startDate = this.dateChecked.searchStartDate;
      this.reservation.endDate = this.dateChecked.searchEndDate;
      this.initialSliderValues.end = this.dateChecked.searchEndDate;
      this.initialSliderValues.start= this.dateChecked.searchStartDate;
    }
    this.reservation.resourceId = +this.facility.resourceId;
    this.reservation.title = this.title.value;
    this.reservation.description = this.description.value;
    this.mode_confirm = true;
  }

  confirmBooking() {
    this.loading = true;
    if (this.reservation_mode_new) {
      this.facilityBookingService.postFacilityReservation(this.reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message();
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    } else {
      this.reservation.referenceNumber = this.reservation_to_update.reservations[0].referenceNumber;
      this.facilityBookingService.putFacilityReservation(this.reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message();
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    }
  }

  reloadSliderComponent(){
    this.renderSliderComponent = false;
    this.cdRef.detectChanges();
    this.renderSliderComponent = true;
  }

  ngOnDestroy(): void {
  }

}
