import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule,  FormsModule  } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FittingoutComponent } from './microapp-fittingout.home.component';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import {HttpClientModule} from '@angular/common/http';
import { MatListModule} from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { ListFittingoutComponent } from './list/microapp-fittingout.list-fittingout.component';
import { NewFittingComponent } from './new/microapp-fittingout.new-fittingout.component';
import {CommentComponent} from './components/microapp-fittingout.components-comment-fittingout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { WarningDialog } from './components/microapp-fittingout.components-warning-fittingout.component';
import { CommentFittingComponent } from './fittingCommentFiles/microapp-fittingout.comment-fittingout.component';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-fittingout.mat-spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';


export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    FormsModule,
    HttpClientModule,
    MatSortModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '', component: FittingoutComponent
      //   , children: [
      //     { path: '', redirectTo: "list", pathMatch: 'full' },
      //     { path: 'list', component: ListFittingoutComponent},
      //     { path: 'new', component: NewFittingComponent},
      //     { path: 'comment', component: CommentFittingComponent},
      //  ]
      }])
  ],
  declarations: [
   FittingoutComponent,
    ListFittingoutComponent,
    NewFittingComponent,
    MatSpinnerOverlayComponent,
    CommentFittingComponent,
    CommentComponent,
    WarningDialog
  ],
  providers: [DatePipe,
    MatSnackBar,
    { provide: DateAdapter, useClass: DateFormat }
  ]
})
export class FittingoutModule {

  constructor(private translationLoader: TranslationLoaderService, private dateAdapter: DateAdapter<Date>) {
    console.log("FittingOut Microapp - lazy constructor");
    dateAdapter.setLocale("en-SG"); // DD/MM/YYYY
  }

}
