<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filtersTypeList"> FILTER
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>
            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="searchText($event.target.value)" placeholder="Search">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>

        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>

        <!-- <div class="search_container">
            <form class="filterform">
                <mat-form-field>
                    <mat-label>{{'MAPP-TARIFF.FACILITY.SEARCH' | translate}}</mat-label>
                    <input matInput placeholder="Search" value="" (keyup)="searchText($event.target.value)">
                    <button mat-button matSuffix mat-icon-button aria-label="Search">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </form>
        </div> -->

        <!-- <button mat-mini-fab (click)="createTarif()">
        <mat-icon>add</mat-icon>
    </button> -->
        <div class="table-container">
            <table mat-table [dataSource]="tenantSource" matSort id="tablaTariff" class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.NAME' |translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <input class="input_editable tarif_name" *ngIf="element.flag_editable" matInput
                            placeholder="{{element.dto.tarif_name}}" [(ngModel)]="element.dto.tarif_name">
                        <mat-label *ngIf="!element.flag_editable">{{element.dto.tarif_name}}</mat-label>
                    </mat-cell>

                </ng-container>
                <ng-container matColumnDef="tags">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.TAGS' |translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <mat-chip-list *ngIf="!element.flag_editable">
                            <mat-chip *ngFor="let tag of element.dto.tags">{{tag}}</mat-chip>
                        </mat-chip-list>


                        <mat-form-field *ngIf="element.flag_editable">
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let tag of element.dto.tags" [selectable]="true" [removable]="true"
                                    (removed)="removeTag(element, tag)">
                                    {{tag}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="New tag..." #tagInput [formControl]="tagCtrl"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addTag(element, $event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag(element, $event)">
                                <mat-option *ngFor="let tag of allowedTags | async" [value]="tag">
                                    {{tag}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>



                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="numberUnit">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.NUMBERUNIT' |translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <input class="input_editable" *ngIf="element.flag_editable" matInput type="number" min="1"
                            step="any" placeholder="{{element.dto.number_of_units}}"
                            [(ngModel)]="element.dto.number_of_units">

                        <mat-label *ngIf="!element.flag_editable">{{element.dto.number_of_units}}</mat-label>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="unitPrice">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.UNITPRICE' |translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <input class="input_editable" *ngIf="element.flag_editable" matInput type="number" min="1"
                            step="any" placeholder="{{element.dto.unit_price}}" [(ngModel)]="element.dto.unit_price">

                        <mat-label *ngIf="!element.flag_editable">{{currency}} {{element.dto.unit_price}} </mat-label>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalPrice">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.TOTALPRICE' |translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        {{currency}} {{(element.dto.unit_price*element.dto.number_of_units) | number:'1.2-2' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{'MAPP-TARIFF.FACILITY.ACTIONS' |translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <mat-icon matTooltip="Edit" class="editrow" (click)="setEditable(element)"
                            *ngIf="!element.flag_editable">edit
                        </mat-icon>
                        <button mat-flat-button (click)="saveChanges(element)" *ngIf="element.flag_editable"
                            color="primary">Save</button>
                        <mat-icon matTooltip="Delete" class="removerow" (click)="deleteTariff(element)"
                            *ngIf="!element.flag_editable">delete
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </table>
        </div>
        <div class="footer-container">
            <div class="notes-container">
                <h6>
                  {{'MAPP-TARIFF.FACILITY.GST' | translate}}
                </h6>
              </div>
            <div class="button-new-container">
                <button (click)="createTarif()" class="extend" mat-fab color="primary" aria-label="FAB new visit">
                    <mat-icon>add </mat-icon>
                    <span class='extend_text'>New Tariff</span>
                </button>
            </div>
            <div class="paginator-container">
                <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
            </div>
        </div>
    </mat-drawer-container>
</div>