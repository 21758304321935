import { createAction, props } from '@ngrx/store';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import { User, Site } from '@empusa/empusa-core';
import { MicroappVmsSearchVisitFilter } from '../../model/microapp-vms-visit-filter.model';
import { VmsMasterConfiguration } from '../../model/microapp-vms-config.model';



export const startLogBook = createAction(
    '[Microapp vms logbook] startLogBook',
    props<{ site: Site, loggedUser: User }>());

export const getConfig = createAction(
    '[Microapp vms logbook] getConfig');

export const storeConfig = createAction(
    '[Microapp vms logbook] storeConfig',
    props<{ config: VmsMasterConfiguration }>());

export const storeFilter = createAction(
    '[Microapp vms logbook] storeFilter',
    props<{ filter: MicroappVmsSearchVisitFilter }>());

export const getVisits = createAction(
    '[Microapp vms logbook] getVisits',
    props<{ filter: MicroappVmsSearchVisitFilter }>());

export const visitList = createAction(
    '[Microapp vms logbook] visitList',
    props<{ visits: MicroappVmsVisit[] }>());

export const updateSingleVisit = createAction(
    '[Microapp vms logbook] UpdateSingleVisit',
    props<{ visit: MicroappVmsVisit }>());

export const updateSingleVisitDone = createAction(
    '[Microapp vms logbook] updateSingleVisitDone',
    props<{ visit: MicroappVmsVisit, message_code: string }>());

export const refreshSingleVisit = createAction(
    '[Microapp vms logbook] refreshSingleVisit',
    props<{ visit: MicroappVmsVisit }>());

export const updateSingleVisitNoChanges = createAction(
    '[Microapp vms logbook] updateSingleVisitNoChanges',
    props<{ message_code: string }>());

export const updateGroupVisit = createAction(
    '[Microapp vms logbook] updateGroupVisit',
    props<{ visit: MicroappVmsVisit }>());

export const updateGroupVisitDone = createAction(
    '[Microapp vms logbook] updateDone');

export const deleteVisitGroup = createAction(
    '[Microapp vms logbook] deleteVisitGroup',
    props<{ group_id: string }>());

export const deleteSingelVisit = createAction(
    '[Microapp vms logbook] deleteSingelVisit',
    props<{ visit_id: string }>());

export const visitDeleted = createAction(
    '[Microapp vms logbook] visitDeleted',
    props<{ visits: MicroappVmsVisit[] }>());

export const error = createAction(
    '[Microapp vms logbook] error');

export const resetError = createAction(
    '[Microapp vms logbook] resetError');

export const resetMessageCode = createAction(
    '[Microapp vms logbook] resetMessageCode');
