<mat-card >
    <mat-card-header>
      <div mat-card-avatar></div>
      <mat-card-title>Reservation detail</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list *ngIf="reservation">
            <mat-list-item>
                <mat-icon mat-list-icon>room</mat-icon>
                <div mat-line>{{reservation.reservations.length}} {{reservation.resourceName}}</div>
             </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon>schedule</mat-icon>
                <div mat-line>{{reservation.startDate | date:'shortDate' }} {{reservation.startDate | date:'HH:mm' }} {{reservation.endDate | date:'HH:mm' }}</div>
             </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-icon>label</mat-icon>
               <div mat-line>{{reservation.owner.firstName}} {{reservation.owner.lastName}} </div>
               <div mat-line>{{reservation.owner.emailAddress}}</div>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-icon>title</mat-icon>
               <div mat-line>{{reservation.title}}</div>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon>subject</mat-icon>
                <div mat-line>{{reservation.description}}</div>
             </mat-list-item>
         </mat-list>
         

    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="close()">close</button>
    </mat-card-actions>
  </mat-card>