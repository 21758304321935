<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersListFault"> {{'MAPP-QUIZ.FEEDBACK.FILTERS' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <!-- <mat-form-field>
                    <input matInput  (keyup)="applyFilter($event.target.value)" placeholder="{{'MAPP-QUIZ.FEEDBACK.FILTER' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field> -->

                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-QUIZ.FEEDBACK.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.STATE' | translate}}</mat-label>
                    <mat-chip-list formControlName="STATE" class="order" aria-label="statusChipsFilter">
                        <!--[selectable]="true"-->
                        <mat-chip class="chipFilterStatus" *ngFor="let chipS of chipsState"
                            [selected]="chipS.state==userFilter.state" (click)="applyStatusFilter(chipS.state)">
                            <mat-icon class="icActions">{{chipS.icon}}</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <mat-label>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.TITLE' | translate}}</mat-label>
                    <mat-chip-list formControlName="TYPE" class="order" aria-label="statusChipsFilter">
                        <!--[selectable]="true"-->
                        <mat-chip class="chipFilterStatus" *ngFor="let chipT of chipsType"
                            [selected]="chipT.state==userFilter.type" (click)="applyTypeFilter(chipT.state)">
                            {{chipT.name | translate}}</mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-QUIZ.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>

        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id='primera'>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.DATE' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.date | date : "dd/MM/y" }}</td>
                </ng-container>
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TIME' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{element.date | date : "HH:mm" }}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.TITLE' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.type == 0">{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.COMMENT' |
                            translate}}</span>
                        <!-- <span *ngIf="element.type == 1">{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.QUESTION' | translate}}</span> -->
                        <span *ngIf="element.type == 2">{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.PROBLEM' |
                            translate}}</span>
                        <span *ngIf="element.type == 3">{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.IMPROVEMENT' |
                            translate}}</span>
                        <span *ngIf="element.type == 4">{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.TYPE.RELATED' |
                            translate}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.DESCRIPTION' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.feedback | truncate : "60"}}</td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.STATE' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.state != 2">
                            <mat-icon>done</mat-icon>
                        </span>
                        <span *ngIf="element.state == 2">
                            <mat-icon>done_all</mat-icon>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'MAPP-QUIZ.FEEDBACK.LIST.TABLE.ACTIONS' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="action">
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.FEEDBACK.FEEDBACK' | translate}}" mat-icon-button
                                (click)="viewFeedback(element.sid)">
                                <mat-icon class="icActions">assignment</mat-icon>
                            </button>
                            <button matTooltip="{{'MAPP-QUIZ.TOOLTIP.FEEDBACK.DELETE' | translate}}" mat-icon-button
                                (click)="deleteFeedback(element.sid)">
                                <mat-icon class="icActions">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div class="footer-container">
            <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
        </div>
    </mat-drawer-container>

</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>