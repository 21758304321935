import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { User, AuthenticationService, EmpusaQuestionDialog } from '@empusa/empusa-core';
import { SelectionModel } from '@angular/cdk/collections';
import { Fitting } from '../../client/dto/fitting';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NewFittingComponent } from '../new/microapp-fittingout.new-fittingout.component';
import { MatCalendar } from '@angular/material/datepicker';
import { FittingService } from '../../client/fitting.service';
import { MICROAPP_NAME, MICROAPP_STATUS_OPEN,MICROAPP_STATUS_CLOSED, MICROAPP_STATUS_CLOSED_REJECTED, MICROAPP_STATUS_CLOSED_APPROVED } from '../../lib/microapp-fittingout.module';
import { MICROAPP_CREATE } from '../../lib/microapp-fittingout.module';
import { DateAdapter } from '@angular/material/core';
import { WarningDialog } from '../components/microapp-fittingout.components-warning-fittingout.component';
import { MicroappFittingoutListFilter } from './microapp-fittingout.list.filter';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CommentFittingComponent } from '../fittingCommentFiles/microapp-fittingout.comment-fittingout.component';

@Component({
  selector: 'microapp-fittingout-fittingout-list',
  templateUrl: './microapp-fittingout.list-fittingout.component.html',
  styleUrls: ['./microapp-fittingout.list-fittingout.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ListFittingoutComponent implements OnInit {
  displayedColumns: string[] = ['date', 'summary', 'reporter', 'status', 'actions'];
  dataSource = new MatTableDataSource([]);
  currentIssue: Fitting;
  currentUser: User;
  selection = new SelectionModel<Fitting>(true, []);
  listTypes: string[];
  isAllowCreate: boolean;
  filterGroup: FormGroup;
  summaryFilter;
  statusFilter;
  loading: boolean;
  typeFilter;
  dateFilterStart;
  dateFilterEnd;
  isApproved = false;
  filterValues = {};
  userFilter = new MicroappFittingoutListFilter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatCalendar) datePicker: MatCalendar<Date>;
  readonly localDateFormat = "dd/MM/yyyy HH:mm";
  today = new Date();

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog,
    private translate: TranslateService, private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private service: FittingService, private auth: AuthenticationService,
    private _adapter: DateAdapter<any>) {
    this.currentUser = this.auth.getCurrentUser();
    this.isAllowCreate = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_CREATE);
    // this._adapter.setLocale('es');
  }

  ngOnInit() {
    // this.loadFilterValues();
    this.updateData();
    this.filterGroup = this.buildFilter();
  }

  buildFilter(){
    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      START: new FormControl(""),
      END: new FormControl(""),
      STATUS: new FormControl("")
    })
  }

  updateData() {
    this.loading = true;
    this.service.allFitting(this.auth.getCurrentUser(), this.filterValues).subscribe(data => {
      this.loading = false;
      data.forEach(element => {
        element.date = this.formatDate(element.date);
      });
      let tempDataSource = new MatTableDataSource(data);
      tempDataSource.sort = this.sort;
      tempDataSource.paginator = this.paginator;
      this.defineDataSourceFilter(tempDataSource);
      this.dataSource = tempDataSource;
      if (this.userFilter){
      this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      })
  }

  createOrEdit(ticket_id: string) {
    let dialogAssign = this.dialog.open(NewFittingComponent, {
      panelClass: 'panelfitting',
      width: '760px',
      // height: '490px',
      data:ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.updateData();
    });
  }

  commentFile(ticket_id: string) {
    let dialogAssign = this.dialog.open(CommentFittingComponent, {
      panelClass: 'panelfitting',
      width: '760px',
      // height: '560px',
      data:ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.updateData();
    });
  }
  
  //FILTERS

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Fitting, filter: string) => {
        const theFilter: MicroappFittingoutListFilter = JSON.parse(filter);
        let generalFilter = false;
        let statusFilter = false;
        let startDateFilter = false;
        let endDateFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
            if (aGroup.summary.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } 
            if(aGroup.reporter.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
        } else generalFilter = true;

        //status filter

        if(theFilter.status){
          if(aGroup.status.toLowerCase().indexOf(theFilter.status) !== -1) {
            statusFilter = true;
          }
          if(aGroup.resolution.toLowerCase().indexOf(theFilter.status) !== -1) {
            statusFilter = true;
          }
        } else statusFilter = true;

        if (theFilter.start_date) {
         
            const visitDate = this.datepipe.transform(aGroup.date, "yyyyMMdd");
            if (theFilter.start_date <= visitDate) {
              startDateFilter = true;
            }
        } else startDateFilter = true;

        if (theFilter.end_date) {
         
          const visitDate = this.datepipe.transform(aGroup.date, "yyyyMMdd");
          if (theFilter.end_date >= visitDate) {
            endDateFilter = true;
          }
      } else endDateFilter = true;

        return generalFilter && statusFilter && startDateFilter && endDateFilter;
    }
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStatusFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.status = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  applyStartDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.start_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyEndDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.end_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  // loadFilterValues() {

  //   this.service.allTypes().subscribe(resp => {
  //     this.listTypes = resp;
  //     localStorage.setItem('types', JSON.stringify(resp));
  //     this.updateData();
  //   },
  //     error => {
  //       this.snackBar.open(
  //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
  //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
  //         , {
  //           duration: 5000,
  //         });
  //       this.updateData();
  //     })
  // }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.end_date = undefined;
    this.userFilter.start_date = undefined;
    this.userFilter.status = undefined;
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  formatDate(date) {
    let formatedBarra : String = date.slice(0,10);
    let formatesGuion = formatedBarra.replace(/\//g,'-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated= reverseDate + "T" + date.slice(11)+"Z";
    let dateNew = new Date(formated);
    return dateNew;
  }

  chips = [
    { name: MICROAPP_STATUS_CLOSED_APPROVED, filter:'resolution' },
    { name: MICROAPP_STATUS_CLOSED_REJECTED, filter:'resolution' },
    {name: MICROAPP_STATUS_OPEN, filter:'status'}
  ];
 
}
export class Priority {
  name: string;
  value: number;
}


export interface Edificio {
  nameEdificio: string;
}






