<h2 class="cabecera">
  <mat-icon class="iconoCabecera">ac_unit</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-HVAC.DESCRIPTION' | translate}}</mat-label>
</h2>

<div class="fondo">
  <div class="headerFaultNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-HVAC.FACILITY.TABS.PASSES' | translate}}">
        <microapp-hvac-facility-pases></microapp-hvac-facility-pases>
      </mat-tab>
      <mat-tab label=" {{'MAPP-HVAC.FACILITY.EXTENSIONS' | translate}}">
        <microapp-hvac-reservationsTenant-hvac-component></microapp-hvac-reservationsTenant-hvac-component>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>