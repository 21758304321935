import { MicroappVmsTime } from '../../../lib/model/microapp-vms-time.model';

export class MicroappVmsVisitsUserFilter{
    general: string;
    start_date: string;
    end_date: string;
    start_time: MicroappVmsTime = null;
    end_Time: MicroappVmsTime = null;
    parking: boolean
    tenant_name: string = null;
}