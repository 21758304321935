<div>
    <h2 class="tittleEditVisit" mat-dialog-title>{{'MAPP-VMS-EDIT-GROUP-VISIT.TITLE' | translate}}<span
            class="example-spacer"></span>
        <button class="closeEditVist" mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button>
    </h2>
</div>
<mat-dialog-content class="contentVisit">
    <div class="editContainer">
        <div>
            <div class="container">
                <mat-label class="divisionsVisit">{{'MAPP-VMS-EDIT-GROUP-VISIT.SCHEDULE' | translate}} </mat-label>
                <div class="line">
                    <mat-divider [inset]="true"></mat-divider>
                </div>
            </div>
            <empusa-microapp-vms-schedule-visit mode="update-visit"></empusa-microapp-vms-schedule-visit>
        </div>
        <div class="addVisitorDivision">
            <div class="container">
                <mat-label class="divisionsVisit">{{'MAPP-VMS-EDIT-GROUP-VISIT.ADD-PARTICIPANT' | translate}}
                </mat-label>
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
            </div>

            <empusa-microapp-vms-select-participants mode="update-visit"></empusa-microapp-vms-select-participants>
        </div>
    </div>
    <div *ngIf="(displayNewVisit$ | async) === true" class="newVisitorDivision">
        <div class="container">
            <mat-label class="divisionsVisit">{{'MAPP-VMS-EDIT-GROUP-VISIT.NEW-VISITOR' | translate}}</mat-label>
            <div class="line">
                <mat-divider></mat-divider>
            </div>
        </div>
        <empusa-microapp-vms-visitor-form></empusa-microapp-vms-visitor-form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="buttonEditVisit" *ngIf="(displayNewVisit$ | async) === false" align="end">
    <button class="editVisitButton" mat-raised-button mat-dialog-close>{{'MAPP-VMS-EDIT-GROUP-VISIT.CANCEL' |
        translate}}</button>
    <button class="editVisitButton" mat-raised-button color="primary" (click)="onConfirm()"
        [disabled]="!(scheduleValid$ | async) || !(thereAreParticipants$ | async)">{{'MAPP-VMS-EDIT-GROUP-VISIT.SAVE' |
        translate}}</button>
</mat-dialog-actions>

<empusa-mat-spinner-overlay *ngIf="loading$ | async" overlay="true"></empusa-mat-spinner-overlay>