import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceModel } from '../../../../client/dto/microapp-fbs-resource';
import { FbsConfig } from '../../../../lib/microapp-facilitiesbooking-config';
import { selectChargeTypes } from '../../../../lib/store/microapp-fbs-selectors';
import * as microappFbsState from '../../../../lib/store/microapp-fbs-app-reducer';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { MicroappFbsChargeType } from '../../../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';


@Component({
  selector: 'empusa-microapp-fbs-new-update-accessory',
  templateUrl: './microapp-fbs-new-update-accessory.component.html',
  styleUrls: ['./microapp-fbs-new-update-accessory.component.css']
})
export class MicroappFbsNewUpdateAccessoryComponent implements OnInit {

  resourceToUpdate: MicroappFbsResourceGroup;

  newResourceFormGroup: FormGroup;
  name = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  chargeType = new FormControl('', [Validators.required]);


  selectChargeTypes: MicroappFbsChargeType[];
  loading: boolean;


  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private fbsConfig: FbsConfig,
    private messageHandler: MicroappFbsMessageHandlerService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.resourceToUpdate = data;
  }


  ngOnInit(): void {
    this.store.pipe(select(selectChargeTypes)).pipe(take(1)).subscribe(valor => {

      this.selectChargeTypes = valor;
    });

    this.buildForm();
  }

  buildForm() {
    this.newResourceFormGroup = new FormGroup({
      name: this.name,
      description: this.description,
      chargeType: this.chargeType,
    });
    if (this.resourceToUpdate) {
      this.newResourceFormGroup.patchValue(this.resourceToUpdate)
    }
  }

  onSubmit() {
    this.loading = true;
    let accessory = new MicroappFbsResourceModel();
    accessory.name = this.name.value;
    accessory.description = this.description.value;
    accessory.chargeType = this.chargeType.value;
    accessory.type = this.fbsConfig.RESOURCE_TYPE_ATTR_ACCESSORY;
    if (this.resourceToUpdate) {
      this.update(accessory);
    } else {
      this.newAccessory(accessory);
    }
  }

  update(accessory: MicroappFbsResourceModel) {
    this.facilityBookingService.updateResource(accessory, this.resourceToUpdate.getResourcesIds()).subscribe((data: any[]) => {
      this.loading = false;
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

  newAccessory(accessory: MicroappFbsResourceModel) {
    this.facilityBookingService.newResource(accessory).subscribe((data: any[]) => {
      this.loading = false;
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

}
