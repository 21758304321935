import { Tenant } from "@empusa/empusa-core";
import { DTOTariffPass } from "./parking-passes.dto";

export class TenantTariffAssociations {
  tenant: Tenant;
  dtos: DTOTariffPass[];
  flag_editable: boolean;
  newUser: DTOTariffPass;

  constructor(tenant?: Tenant, dtos?: DTOTariffPass[], flag_editable?: boolean, newUser?: DTOTariffPass){
    this.tenant = tenant ? tenant : new Tenant();
    this.dtos = dtos ? dtos : [];
    this.flag_editable = flag_editable ? flag_editable : false;
    this.newUser = newUser ? newUser : new DTOTariffPass();
  }
}
