import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MicroappFbsResourceModel } from '../../../../client/dto/microapp-fbs-resource';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MatTableDataSource } from '@angular/material/table';
import { MicroappFbsReservationModel } from '../../../../client/dto/microapp-fbs-reservation';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MicroappFbsUserModel } from '../../../../client/dto/microapp-fbs-user';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MicroappFbsReservationDetailComponent } from '../../reservation-detail/microapp-fbs-reservation-detail.component';
import { MicroappFbsReservationDeleteComponent } from '../../reservation-delete/microapp-fbs-reservation-delete.component';
import { MicroappFbsReservationGroupModel } from '../../../../client/dto/microapp-fbs-reservation-group';


@Component({
  selector: 'empusa-microapp-fbs-facility-reservations',
  templateUrl: './microapp-fbs-facility-reservations.component.html',
  styleUrls: ['./microapp-fbs-facility-reservations.component.css']
})
export class MicroappFbsFacilityReservationsComponent implements OnInit {

  _facility: MicroappFbsResourceModel;
  _user: MicroappFbsUserModel;
  _showActions = false;
  loading = false;
  public reservationsDataSource: MatTableDataSource<MicroappFbsReservationGroupModel>;
  displayedColumns: string[] = ['startDate', 'endDate', 'title', 'description'];


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.reservationsDataSource) {
      this.reservationsDataSource.paginator = mp;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  set facility(facility: MicroappFbsResourceModel) {
    this._facility = facility;
    if (facility){
      this.loadData();
    }
  }

  @Input()
  set fbsUser(user: MicroappFbsUserModel) {
    this._user = user;
    if (user){
      this.loadData();
    }
  }

  @Input()
  set showActions (showActions: boolean){
    this._showActions = showActions
  }


  constructor(
    public dialog: MatDialog,
    private facilityBookingService: MicroappFacilitiesbookingService) { }


  ngOnInit(): void {

  }

  loadData(){
    if (this._showActions){
      this.displayedColumns.push('actions');
    }
    if (this._user){
      this.loading = true;
      let from = new Date();
      from.setHours(0,0,0,0);
      this.facilityBookingService.getMyResevations(this._user.id,from).subscribe((data: MicroappFbsReservationGroupModel[]) => {
        this.reservationsDataSource = new MatTableDataSource(data);
        this.reservationsDataSource.sort = this.sort;
        this.loading = false;
      });
    }else if (this._facility){
      this.loading = true;
      this.facilityBookingService.getResevations(this._facility.resourceId).subscribe((data: MicroappFbsReservationGroupModel[]) => {
        this.reservationsDataSource = new MatTableDataSource(data);
        this.reservationsDataSource.sort = this.sort;
        this.loading = false;
      });
    }
  }

  reservation_detail(reservation: MicroappFbsReservationModel ){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = reservation;
    const dialogRef = this.dialog.open(MicroappFbsReservationDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.loadData();
    });
  }

  reservation_update(reservation: MicroappFbsReservationModel ){

  }

  reservation_delete(reservation: MicroappFbsReservationModel ){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = reservation;
    const dialogRef = this.dialog.open(MicroappFbsReservationDeleteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result)
        this.loadData();
    });
  }

}
