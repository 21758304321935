<h2 class="cabecera">
  <mat-icon class="iconoCabecera">sms</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-NOTIFICATIONS.DESCRIPTION' | translate}}</mat-label>
</h2>


<div class="fondo">
  <div class="centerHeaderNavBar">
    <mat-tab-group mat-stretch-tabs class="microappNavBar" *ngIf="can_access">
      <mat-tab label="{{'MAPP-NOTIFICATIONS.LIST.HEAD' | translate}}">
        <empusa-microapp-notifications-list></empusa-microapp-notifications-list>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>