import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'microapp-usermanagement-generic',
  templateUrl: './microapp-usermanagement-generic.component.html',
  styleUrls: ['./microapp-usermanagement-generic.component.css']
})


export class MicroappusermanagementGenericComponent {
    constructor(
        public dialogRef: MatDialogRef<MicroappusermanagementGenericComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: MicroappusermanagementGenericComponentgData) { 
  }
}

export interface MicroappusermanagementGenericComponentgData {
    title: string;
    question: string;
    icon: string;
}