import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { DTOTarif } from './dto/tarif.dto';

@Injectable({
    providedIn: 'root',
})
export class TarifsService {
    context: string = "http://bousteadintegration.ddns.net:18080/smartbilling/services/";

    constructor(private http: HttpClient, private auth: AuthenticationService,
        private translate: TranslateService, @Inject('environment') private environment) {
        this.context = this.environment.urlbase + "smartbilling/services/";
    }

    /** 
     * @CHECKED
     * Obtain all the Tarifs allowed to be selected 
     */
    findAllowedTags(): Observable<string[]> {
        let path: string = this.context + 'tarifs/allowedtags';
        return this.http.get(path).pipe(
            map(data => (<any>data).tags)
        );
    }

    /**
     * @CHECKED
     * List all tariffs available at the DB
     */
    findAllTarifs(): Observable<DTOTarif[]> {
        let path: string = this.context + 'tarifs';
        let body = {}
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });

        return this.http.post(path, body, { headers }).pipe(
            map(resp => (<any>resp).tarifs)
        );
    }

    /**
     * @CHECKED
     * Update the tarif passed
     * @param <DTOTarif> tarif the tarif modified
     */
    updateTarif(tarif: DTOTarif) {
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });

        let body = {
            "number_of_units": "" + tarif.number_of_units,
            "tags": tarif.tags,
            "tarif_name": tarif.tarif_name,
            "unit_price": "" + tarif.unit_price
        };

        let url = `${this.environment.urlbase}smartbilling/services/tarifs/tarif/${tarif.tarif_id}`;
        return this.http.put(url, body, { headers }).pipe(
            map(resp => resp)
        );
    }

    /**
     * @CHECKED
     * Remove a certain tarif
     * @param <DTOTarif> tarif the tarif to be removed
     */
    deleteTarif(tarif: DTOTarif) {
        let url = `${this.environment.urlbase}smartbilling/services/tarifs/tarif/${tarif.tarif_id}`;
        return this.http.delete(url).pipe(
            map(resp => resp)
        );

    }

    checkDelete(tarif: DTOTarif){
        let url = `${this.environment.urlbase}smartbilling/services/charges/tenant/tarif/${tarif.tarif_id}`;
        return this.http.get(url).pipe(
            map(data => data)
        );
    }

    /**
     * @CHECKED
     * Create the tarif passed
     * @param <DTOTarif> tarif the tarif modified
     */
    createTarif(tarif: DTOTarif) {
        let headers = new HttpHeaders({
            'Content-type': 'application/json'
        });

        let body = {
            "number_of_units": "" + tarif.number_of_units,
            "tags": tarif.tags,
            "tarif_name": tarif.tarif_name,
            "unit_price": "" + tarif.unit_price
        };

        let url = `${this.environment.urlbase}smartbilling/services/tarifs/tarif`;
        return this.http.post(url, body, { headers }).pipe(
            map(resp => resp)
        );

    }


}