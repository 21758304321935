import { Injectable } from '@angular/core';
import { Adapter } from '../microapp-fbs-adapter';
import { MicroappFbsTarif } from './microapp-fbs-tarif';
import { FbsConfig } from '../../lib/microapp-facilitiesbooking-config';

@Injectable({
  providedIn: "root",
})
export class FbsTarifAdapter implements Adapter<MicroappFbsTarif> {

  constructor(private fbsConfig: FbsConfig,
  ) { }

  adapt(item: any): MicroappFbsTarif {
    let tarifAdapted = new MicroappFbsTarif;
    tarifAdapted.number_of_units = +item.number_of_units;
    tarifAdapted.tarif_id = item.tarif_id;
    tarifAdapted.tarif_name = item.tarif_name;
    tarifAdapted.unit_price = +item.unit_price;
    return tarifAdapted;
  }

}