import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../lib/microapp-facilitiesbooking.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MicroappFbsResourceGroup } from '../../../client/dto/microapp-fbs-resource-group';
import { FbsResourceGroupAdapter } from '../../../client/dto/microapp-fbs-resource-group-adapter';
import { MicroappFbsAccessoryReservationModel } from '../../../client/dto/microapp-fbs-reserve-accessory';
import { select, Store } from '@ngrx/store';
import { selectFbsUser, selectResources, selectScheduled } from '../../../lib/store/microapp-fbs-selectors';
import { take } from 'rxjs/operators';

import { cloneDeep } from "lodash";
import * as fbsActions from '../../../lib/store/microapp-fbs-actions';
import * as microappFbsState from '../../../lib/store/microapp-fbs-app-reducer';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';
import { SearchDates } from '../../../client/microapp-fbs.interfaces';
import { MicroappFbsUserModel } from '../../../client/dto/microapp-fbs-user';
import { MicroappFbsReservationGroupModel } from '../../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsMessageHandlerService } from '../../../lib/microapp-fbs-message-handler.service';
import { MicroappFbsPeriod, MicroappFbsPeriodSlot, MicroappFbsSchedule } from '../../../client/dto/microapp-fbs-schedule';


import { MicroappFbsCheckAvailabilityComponent } from '../components/check-availability/microapp-fbs-check-availability.component';
import { FbsResourceAdapter } from '../../../client/dto/microapp-fbs-resource-adapter';
import { MicroappFbsResourceModel } from '../../../client/dto/microapp-fbs-resource';
import { MicroappFbsResourceListModel } from '../../../client/dto/microapp-fbs-resource-list';
import { Subscription } from 'rxjs';
import { MicroappFbsResourceSlotModel } from '../../../client/dto/faciliti-slots';
import { SliderSelectionModel } from '../components/generics/range-slider/range-slider.component';
import {MicroappFbsSliderFacilitySlotsComponent} from "../components/slots/microapp-fbs-slider-facility-slots/microapp-fbs-slider-facility-slots.component";

interface DayHours {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'empusa-microapp-fbs-make-a-facility-group-reservation',
  templateUrl: './microapp-fbs-make-a-facility-group-reservation.component.html',
  styleUrls: ['./microapp-fbs-make-a-facility-group-reservation.component.css']
})
export class MicroappFbsMakeAFacilityGroupReservationComponent implements OnInit {


  resourceGroup: MicroappFbsResourceGroup;
  resourceImages: string[];
  theBookDate: SearchDates;

  dateChecked: SearchDates;
  unitaryCost: number;

  fbsUser: MicroappFbsUserModel;

  mode_confirm = false;
  loading: boolean;
  reservation_mode_new = true;
  reservation_to_update: MicroappFbsReservationGroupModel;
  availability_checked = true;

  reservation: MicroappFbsReservationGroupModel;

  public reservationForm: FormGroup;
  amount = new FormControl(1);
  title = new FormControl("", [Validators.required, Validators.minLength(1)]);
  description = new FormControl();
  schedule_for_today: MicroappFbsPeriod;

  todayDate = new Date();
  avalibilityStartDate;
  selectedDay = new FormControl();
  selectedDaySub$: Subscription;
  selectedDayObject: SearchDates;
  maxDay : Date;
  initialSliderValues: SliderSelectionModel;
  data;
  renderSliderComponent = true;

  @ViewChild('checkAvailability') checkAvailabilityComponent: MicroappFbsCheckAvailabilityComponent;
  @ViewChild('sliderFacilitySlots') sliderFacilitySlots: MicroappFbsSliderFacilitySlotsComponent;
  schedule: MicroappFbsSchedule;
  raw_resources: MicroappFbsResourceModel[] = [];


  constructor(private dialogRef: MatDialogRef<any>,
    public fbsConfig: FbsConfig,
    private resourceGroupAdapter: FbsResourceGroupAdapter,
    private resourceAdapter: FbsResourceAdapter,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data) {

    this.data = data;
    let d = new Date();
    this.maxDay = new Date(d.setDate(d.getDate() + this.fbsConfig.DAYS_IN_ADVANCE));
    this.selectedDay.setValue('');
    this.resourceGroup = cloneDeep(data[0])
    this.resourceImages = this.resourceGroup.image_urls;
    if (data[1]) {
      this.theBookDate = { ...data[1] };
      this.avalibilityStartDate = data[1].searchStartDate;
      this.selectedDay.setValue(data[1].searchStartDate);
      this.initialSliderValues = {start: data[1].searchStartDate, end: data[1].searchEndDate}
      let newDateObject = new SearchDates();
      newDateObject.searchStartDate = data[1].searchStartDate;
      newDateObject.searchEndDate = data[1].searchEndDate;
      this.selectedDayObject = newDateObject;
      this.calculateCost(this.selectedDayObject);
      if(!data[2]){
        this.checkFacilitieAvailability(this.selectedDayObject);
      }
    } else {
      this.availability_checked = false;
    }
    this.dateChecked = this.theBookDate;

    this.reservationForm = new FormGroup({
      amount: this.amount,
      title: this.title,
      description: this.description,
    });


    this.selectedDaySub$ = this.selectedDay.valueChanges.subscribe(newValue => {
       this.onDateChange(newValue)
    });

    this.amount.setValidators([Validators.required,
    Validators.min(1),
    Validators.max(this.resourceGroup.availableResourcesToBook)])

    if (data[2]) {
      this.reservation_mode_new = false;
      this.reservation_to_update = { ...data[2] };
      this.availability_checked = false;
      this.amount.patchValue(this.reservation_to_update.reservations.length);
      this.title.patchValue(this.reservation_to_update.title);
      this.description.patchValue(this.reservation_to_update.description);
    }



    this.store.pipe(select(selectResources)).pipe(take(1)).subscribe(resources => {

      resources.forEach(one => {
        if (one.resourceGroup == this.resourceGroup.resourceGroupId) {
          this.raw_resources.push(one);
        }
      });
    });
  }

  onDateChange(newValue){
    let newDate = new Date(newValue);
    let newDateObject = new SearchDates();
    let newDateEnd = newDate;
    newDateEnd.setHours(10);
    newDateObject.searchStartDate = newDate;
    newDateObject.searchEndDate = newDateEnd;
    this.selectedDayObject = newDateObject;
    this.checkFacilitieAvailability(this.selectedDayObject);
  }

  ngOnInit(): void {
    this.store.pipe(select(selectFbsUser)).pipe(take(1)).subscribe(valor => {
      this.fbsUser = valor;
    });
    this.store.pipe(select(selectScheduled)).subscribe(schedule => {
      if (schedule) {
        this.schedule = schedule;
        if (this.dateChecked && this.selectedDayObject) {
          this.calculate_schedule_for_today();
        }
      }
    });
  }

  private calculate_schedule_for_today() {
    // NOTE: Booked do not give the schedule in a specified date, so as a template
    //       we take week day

    const date = this.selectedDayObject.searchEndDate.toISOString().split("T")[0];
    let schedule_for_today = this.schedule.periods.find(x => x.date == date);

    if (!schedule_for_today) {
      // Find same day of week and get las occurrence. First is incorrect in some cases.
      schedule_for_today = this.schedule.periods.filter(x => new Date(x.date).getDay() == this.dateChecked.searchStartDate.getDay()).pop();
      schedule_for_today = cloneDeep(schedule_for_today);
      schedule_for_today.schedule_periods.forEach((schedulePeriod) => {
        const dateObject = new Date(date);
        schedulePeriod.start.setDate(dateObject.getDate());
        schedulePeriod.end.setDate(dateObject.getDate());
        schedulePeriod.start.setMonth(dateObject.getMonth());
        schedulePeriod.end.setMonth(dateObject.getMonth());
        schedulePeriod.start.setFullYear(dateObject.getFullYear());
        schedulePeriod.end.setFullYear(dateObject.getFullYear());
      });
      schedule_for_today.date = date;
    }

    this.schedule_for_today = schedule_for_today;


  }


  checkInDayFacilitieAvailability($event: SearchDates) {
    // this.checkAvailabilityComponent.setHourCombos($event);

    let reference_number_list: string[] = []
    if (this.reservation_to_update) {
      this.reservation_to_update.reservations.forEach(reservation => {
        reference_number_list.push(reservation.referenceNumber)
      });
    };

    let the_resources = [];
    this.raw_resources.forEach(one_resource => {
      const checked_resource = this.resourceAdapter.adapt(one_resource, $event.searchStartDate, $event.searchEndDate, reference_number_list);
      the_resources.push(checked_resource);

    });
    this.dateChecked = $event;
    let sendo = $event.searchEndDate.getMinutes();
    let starto = $event.searchStartDate.getMinutes();


    if (sendo == starto && the_resources && the_resources.length > 0 && $event.searchStartDate.getTime() != $event.searchEndDate.getTime()) {
      this.availability_checked = true;
      this.calculate_schedule_for_today();
      this.resourceGroup = this.generateView(the_resources, this.schedule_for_today);
      this.calculateCost($event);
      this.amount.setValidators([Validators.required, Validators.min(1),
      Validators.max(this.resourceGroup.availableResourcesToBook)]);
      this.reservationForm.updateValueAndValidity();
    } else {
      // This clone is necessary to update selected period (uncheck it)
      this.resourceGroup = cloneDeep(this.resourceGroup);
      this.availability_checked = false;
      this.resourceGroup.canBookInSelectedPeriod = true;
    }
    this.cdRef.detectChanges();
  }

  public checkFacilitieAvailability($event: SearchDates) {

    let startDate = $event.searchStartDate;
    let endDate = $event.searchEndDate;
    this.avalibilityStartDate = startDate;
    this.loading = true;

    let call;
    if (this.reservation_mode_new) {
      call = this.facilityBookingService.checkAvailableResource(startDate, endDate, null, null)
    } else {
      let reference_number_list: string[] = []
      this.reservation_to_update.reservations.forEach(reservation => {
        reference_number_list.push(reservation.referenceNumber)
      });
      call = this.facilityBookingService.checkAvailableResource(startDate, endDate, null, null);
    }

    call.subscribe((data : MicroappFbsResourceListModel) => {
      if(this.resourceGroup.resourcesIds){
        this.raw_resources = data.resources.filter((r) => this.resourceGroup.resourcesIds.some((id) => id.toString() === r.resourceId && r.type === this.fbsConfig.RESOURCE_TYPE_ATTR_FACILITY));
        this.loading = false;
        this.dateChecked = $event;
        this.availability_checked = true;
        this.calculate_schedule_for_today();
        this.resourceGroup = this.generateView(this.raw_resources, this.schedule_for_today);
        this.calculateCost($event);
        this.reloadSliderComponent();
        this.amount.setValidators([Validators.required, Validators.min(1),
        Validators.max(this.resourceGroup.availableResourcesToBook)]);
      }
    }, error => {

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }




  generateView(resources: MicroappFbsResourceModel[], schedule_for_today: MicroappFbsPeriod): MicroappFbsResourceGroup {
    const checkedSlotsForToday: MicroappFbsResourceSlotModel[] = [];
    schedule_for_today.schedule_periods.forEach((periodSlot) => {
      const filteredSlotsForCurrentPeriod = this.filterSlotsForCurrentPeriod(periodSlot, resources);
      const notReservableSlot = filteredSlotsForCurrentPeriod.find((slot) => {
        return slot.isBooked || !slot.isReservable;
      });
      if (notReservableSlot){
        checkedSlotsForToday.push(notReservableSlot);
      } else {
        checkedSlotsForToday.push(filteredSlotsForCurrentPeriod[0]);
      }

    });

    const newGroup = cloneDeep(this.resourceGroup)
    newGroup.slots = checkedSlotsForToday;
    newGroup.canBookInSelectedPeriod = resources.every((r) => r.canBookInSelectedPeriod);
    return newGroup;
  }

  filterSlotsForCurrentPeriod(period: MicroappFbsPeriodSlot, resources: MicroappFbsResourceModel[]){
    const filteredSlots: MicroappFbsResourceSlotModel[] = [];
    resources.forEach((resource) => {
      const slot = this.createSlotFromDatetimeRanges(period.start, period.end, resource);
      if (slot) {
        filteredSlots.push(slot);
      }
    });
    return filteredSlots;
  }

  createSlotFromDatetimeRanges(start: Date, end: Date, resource: MicroappFbsResourceModel) {
    const rangeSlot = resource.slots.find((slot) => {
      // Check if range is between slot (or is same as slot)
      return slot.startDateTime.getTime() <= start.getTime() && end.getTime() <= slot.endDateTime.getTime();
    });

    if (!rangeSlot) {
      return undefined;
    }

    const newSlot = new MicroappFbsResourceSlotModel();
    newSlot.startDateTime = start;
    newSlot.endDateTime = end;
    newSlot.isBooked = rangeSlot.isBooked;
    newSlot.isReservable = rangeSlot.isReservable;
    newSlot.label = rangeSlot.label;
    newSlot.reservation = rangeSlot.reservation;
    return newSlot;
  }

  calculateCost(day : SearchDates) {

    let diffhour = day.searchEndDate.getHours() - day.searchStartDate.getHours();
    this.unitaryCost = (diffhour * (this.resourceGroup.tarif.unit_price * 100)) / 100;
  }

  close() {

    this.dialogRef.close(false);
  }


  go_to_confirm() {
    this.reservation = new MicroappFbsReservationGroupModel();
    this.reservation.reservationGroupId = this.resourceGroup.resourceGroupId;
    if (this.availability_checked) {
      this.initialSliderValues = {start : new Date(), end: new Date()};
      this.reservation.startDate = this.dateChecked.searchStartDate;
      this.reservation.endDate = this.dateChecked.searchEndDate;
      this.initialSliderValues.end = this.dateChecked.searchEndDate;
      this.initialSliderValues.start= this.dateChecked.searchStartDate;
    }
    this.reservation.title = this.title.value;
    this.reservation.description = this.description.value;
    this.reservation.total_cost = this.amount.value;
    this.mode_confirm = true;
  }


  confirmBooking() {
    this.loading = true;
    let reservation: MicroappFbsReservationGroupModel = new MicroappFbsReservationGroupModel();
    this.reservation.reservationGroupId = this.resourceGroup.resourceGroupId;
    if (this.availability_checked) {
      reservation.startDate = this.dateChecked.searchStartDate;
      reservation.endDate = this.dateChecked.searchEndDate;
    }
    reservation.reservationGroupId = this.reservation.reservationGroupId;
    // reservation.availableResourceIds = this.resourceGroup.getResourcesIds();
    reservation.title = this.title.value;
    reservation.description = this.description.value;
    reservation.total_cost = this.amount.value;
    if (this.reservation_mode_new) {
      this.facilityBookingService.postGroupReservation(reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message();
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    } else {
      this.facilityBookingService.putGroupReservation(this.reservation_to_update.reservationGroupId, reservation).subscribe(response => {
        let d = new Date();
        let month_before = new Date(d.setMonth(d.getMonth() - 1));
        this.store.dispatch(fbsActions.loadMyReservations({ startDateTime: month_before, endDateTime: null }));
        this.messageHandler.show_reservation_message();
        this.loading = false;
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.messageHandler.show_reservation_error(error.status);
        this.dialogRef.close(false);
      });
    }
  }

  reloadSliderComponent(){
    this.renderSliderComponent = false;
    this.cdRef.detectChanges();
    this.renderSliderComponent = true;
  }

  ngOnDestroy(): void {
  }

}
