import { StringMap } from "@angular/compiler/src/compiler_facade_interface";

export class DTOCalendarEvent{
  id: number;
  start_date: string;
  end_date: string;
  name: string;
  description: string;
  type: number

  constructor(start_date: string, end_date: string, name: string, description: string, type: number){
    this.start_date = start_date;;
    this.end_date = end_date;
    this.name = name;
    this.description = description;
    this.type = type;
  }

}
