import { Component, ViewChild, Inject, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PriceComponent } from './addprice/microapp-tariff.components-price-tariff.component';
import { TarifsService } from '../../client/tarifs.service';
import { VOTarif } from '../../client/vo/tarif.vo';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { DTOTarif } from '../../client/dto/tarif.dto';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { EmpusaQuestionDialog } from '@empusa/empusa-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MicroappTariffGenericComponent } from '../generic/microapp-tariff-generic.component';

@Component({
  selector: 'microapp-tariff-facility-prices',
  templateUrl: 'microapp-tariff.facility-prices-tariff.component.html',
  styleUrls: ['microapp-tariff.facility-prices-tariff.component.css']
})
export class FacilityPricesComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['name', 'tags', 'numberUnit', 'unitPrice', 'totalPrice', 'actions'];
  tenantSource = new MatTableDataSource([]);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  loading: boolean = true;
  allowedTags: Promise<string[]>; //list of allowed tags to be selected
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  // the currency used
  currency: string;

  /** constructor */
  constructor(private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private tarifService: TarifsService,
    @Inject('environment') private environment
  ) {
    this.getTarifs();
    this.currency = this.environment.currency;
    this.getAllowedTags();
  }

  /** Obtain all the allowed tags to be selected */
  getAllowedTags() {
    this.allowedTags = this.tarifService.findAllowedTags().toPromise();
  }

  /** obtain all the available tarifs from server */
  getTarifs() {
    this.tarifService.findAllTarifs().subscribe(tarifs => {
      this.loading = false;
      let data: VOTarif[] = [];
      tarifs.forEach(tarif => {
        let vo = new VOTarif();
        vo.dto = tarif;
        vo.flag_editable = false;
        data.push(vo);
      })
      this.refresh(data);

      this.tenantSource.filterPredicate = ((data, filter) => {
        if (data.dto.tarif_name.toLowerCase().includes(filter)) {
          return true;
        }
        for (let i = 0; i < data.dto.tags.length; i++) {
          if (data.dto.tags[i].toLowerCase().includes(filter)) {
            return true;
          }
        }
        if (("" + data.dto.number_of_units).includes(filter)) {
          return true;
        }
        if (("" + data.dto.unit_price).includes(filter)) {
          return true;
        }
        if (("" + (data.dto.unit_price * data.dto.number_of_units)).includes(filter)) {
          return true;
        }

        return false;
      });
    });
  }

  /** tag selected event from the auto completion component */
  selectedTag(element: VOTarif, event: MatAutocompleteSelectedEvent): void {
    let index = element.dto.tags.indexOf(event.option.viewValue);
    if (index < 0) {
      element.dto.tags.push(event.option.viewValue);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  /** method to filter the tabled based on a text */
  searchText(filterValue) {
    this.tenantSource.filter = filterValue.trim().toLowerCase();
  }

  /** set a row editable */
  setEditable(element: VOTarif) {
    element.flag_editable = true;
  }

  /** remove a tag from the list of tags */
  removeTag(element: VOTarif, tag: string) {
    let index = element.dto.tags.indexOf(tag)
    element.dto.tags.splice(index, 1);
  }

  /** add a tag to the list of tags */
  addTag(element, tag) {
    let index = element.dto.tags.indexOf(tag.value);
    if (index < 0) {
      element.dto.tags.push(tag.value);
    }
    tag.input.value = '';
  }

  /** save the changes performed in the tarif */
  saveChanges(element: VOTarif) {
    this.tarifService.updateTarif(element.dto).subscribe(data => {
      element.flag_editable = false;
    });
  }

  /** remove the tarif selected */
  delete(element) {
    this.tarifService.checkDelete(element.dto).subscribe(data => {
      let ob = data['tenants'];
      let check = Object.keys(ob);
      if (Object.entries(check).length === 0) {
        this.tarifService.deleteTarif(element.dto).subscribe(data => {
          element.flag_editable = false;
        });
        let index = this.tenantSource.data.findIndex(tarif => (<VOTarif>tarif).dto.tarif_id == element.dto.tarif_id);
        let data = this.tenantSource.data;
        data.splice(index, 1);
        this.refresh(data);
        console.log("delete")
      } else {
        let dialogRef = this.dialog.open(MicroappTariffGenericComponent, {
          data: {
            title: element.dto.tarif_name + ' can´t be deleted',
            question: 'MAPP-TARIFF.FACILITY.NOTDELETEA',
            list: check,
            message: 'MAPP-TARIFF.FACILITY.NOTDELETEB',
            icon: 'delete'
          }
        });
      }
    });
  }

  /** Refresh the datasource */
  refresh(data) {
    this.tenantSource = new MatTableDataSource<VOTarif>(data);
    this.tenantSource.sort = this.sort;
    this.tenantSource.paginator = this.paginator;
  }

  /** Creating a new tarif */
  createTarif() {
    const dialogAssign = this.dialog.open(PriceComponent, {
      width: '710px',
      data: new DTOTarif()
    });
    dialogAssign.afterClosed().subscribe(result => {
      if (result != undefined && result != '') {
        this.tarifService.createTarif(result).subscribe(created => {
          this.getTarifs();
        });
      }
    });
  }


  deleteTariff(element) {
    let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
      width: '500px',
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(element);
      }
    })
  }
}

export class Check {
  success: Object
  tenants: Object

}

