import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FbsConfig {

    // Config for cloud deploiment
    URL_REST_PREFIX = "facilitiesbooking/services/";
    URL_REST_BASE: string = this.environment.urlbase + this.URL_REST_PREFIX;
    // END: Config for cloud deploiment

    // Config for local develop
    // URL_REST_PREFIX = "";
    //URL_REST_BASE = "http://192.168.77.28:80/" + this.URL_REST_PREFIX;
    // URL_REST_BASE = "http://localhost:5100/" + this.URL_REST_PREFIX;
    //URL_REST_BASE = "http://localhost:80/" + this.URL_REST_PREFIX;
     // END: Config for local develop

    //TARIF_SRV = "/smartbilling/services/charges/tenant/{tenant_id}/tarif";
    CHARGE_TYPES_SRV = this.environment.urlbase + "smartbilling/services/charges/tenant/{tenant_id}/tarifs";
    ALL_TARIFF_SRV = this.environment.urlbase + "smartbilling/services/tarifs/filter/tag"

    // BOOKED_IMAGES_URL = this.environment.urlbase + 'booked/Web/uploads/images/';
    BOOKED_IMAGES_URL = this.environment.urlbase + 'BookedScheduler/Web/uploads/images/';

    // Booked custom attibutes
    RESOURCE_TYPE_ATTR_LABEL = "ResourceType"
    RESOURCE_TYPE_ATTR_FACILITY = "facility";
    RESOURCE_TYPE_ATTR_ACCESSORY = "accessory";
    RESOURCE_TYPE_ATTR_GROUP = "groupfacility";

    RESOURCE_GROUP_ATTR_LABEL = "Group";
    RESOURCE_CHARGE_TYPE_ATTR_LABEL = "ChargeType";

    RESERVATION_GROUP_ATTR_LABEL = "facility_group";

    RESOURCE_STATUS_HIDDEN = 0;
    RESOURCE_STATUS_AVAILABLE = 1;
    RESOURCE_STATUS_UNAVAILABLE = 2;

    //Days in advance to book
    DAYS_IN_ADVANCE: number = 60;

    //ISO 4217 Currency code
    CURRENCY = 'SGD';

    //LDAP
    APPLICATION = "fbs";
    ROLE_RESOURCES_MANAGEMENT = "resources_management";
    ROLE_RESOURCES_RESERVATION = "resources_reservation";
    ROLE_SEE_ALL_RESERVATIONS ="see_all_reservations";
    ROLE_CAN_ACCESS="access"

    //GROUP ROOMS ENABLED
    GROUP_ROOMS_ENABLED= false;

    constructor(@Inject('environment') private environment) {
    }

}
