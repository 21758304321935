
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Issue } from './dto/issue';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { MICROAPP_NAME, MICROAPP_FINDALL, MICROAPP_FINDASIGNED, MICROAPP_FINMINE } from '../lib/microapp-faultreport.module';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class FaultService {

  private REST_TRAC_SERVER: string = this.environment.urlbase + 'faultreport/services/tickets';
  private urlLocation: string = this.environment.urlbase + 'building-operational/services/locations'
  private urlCategories: string = this.environment.urlbase + 'building-operational/services/categories'
  // private REST_TRAC_SERVER: string = 'http://localhost:8000/faultreport/services/tickets';


  constructor(private http: HttpClient, @Inject('environment') private environment,
    private auth: AuthenticationService) {
  }
  currentDate() {
    let dateNow: string = new Date().getTime().toString();
    return dateNow;
  }
  getUrl(path: string) {
    return this.REST_TRAC_SERVER + path;
  }


  //ISSUES  
  allIssues(currentUser: User, filterValues, from: Date, to: Date): any {
    if (!this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDALL)) {
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDASIGNED)) {
        filterValues['owner'] = currentUser.roles[0].name;
      }
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINMINE)) {
        filterValues['reporter'] = currentUser.mail;
      }
    }
    let fromOneMore = new Date(from)
    let toOneMore = new Date(to)
    fromOneMore = new Date(fromOneMore.setHours(23));
    toOneMore = new Date(toOneMore.setHours(23));
    toOneMore = new Date(toOneMore.setDate(toOneMore.getDate() + 1));
    if (to != null) {
      filterValues['time'] = fromOneMore.toISOString().split("T")[0] + '..' + toOneMore.toISOString().split("T")[0];
    } else {
      filterValues['time'] = fromOneMore.toISOString().split("T")[0];
    }
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path = '/list';
    return this.http.post(this.getUrl(path),
      JSON.stringify(filterValues), opts).pipe(
        map(data => data)
      );

  }


  getIssue(id) {
    let path: string = '/id/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  createIssue(data) {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path: string = '/new';
    return this.http.post(this.getUrl(path),
      JSON.stringify(data), opts).pipe(
        map(data => data)
      );
  }

  getOwner(category, type, location): any {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      // 'responseType': 'text' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };
    let escapeLocation = escape(location)
    let path: string = '/defaultgroup/' + category + "/" + type + "/" + escapeLocation;
    return this.http.get(this.getUrl(path), httpOptions).pipe(
      map(data => { console.log("getOwner"); return data })
    );
  }

  updateIssue(data) {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path: string = '/update';
    return this.http.put(this.getUrl(path),
      JSON.stringify(data), opts).pipe(
        map(data => data)
      );
  }

  updateIssuesComment(issues, comment, username) {
    var datas: Issue[] = [];
    issues.forEach(iss => {
      var data: Issue = new Issue();
      data.ticket_id = iss.ticket_id;
      data.user = username;
      data.comment = comment;
      data.owner = iss.owner;
      datas.push(data);
    });
    return this.updateIssue(datas);
  }

  updateIssueOwner(ticket_id, owner, comment, username) {
    var datas: Issue[] = [];
    var data: Issue = new Issue();
    data.ticket_id = ticket_id;
    data.user = username;
    data.comment = comment;
    data.owner = owner;
    data.status = "assigned"
    datas.push(data);
    return this.updateIssue(datas);
  }

  updateIssueComment(ticket_id, comment, username) {
    var datas: Issue[] = [];
    var data: Issue = new Issue();
    data.ticket_id = ticket_id;
    data.user = username;
    data.comment = comment;
    datas.push(data);
    return this.updateIssue(datas);
  }

  updateIssueEditable(data) {
    var datas: Issue[] = [];
    datas.push(data);
    return this.updateIssue(datas)
  }

  updateIssueResolve(dat) {
    var datas: Issue[] = [];
    dat.status = "closed";
    datas.push(dat);
    return this.updateIssue(datas);
  }

  //STATIC FIELDS  



  allPriorities(): any {
    let path: string = '/priority/list';
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  allTypes(): any {
    let path: string = '/type/list';
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  allComponents(): any {
    let path: string = '/component/list';
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  allCustomFields(): any {
    let path: string = '/customfields';
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  allOwners(building): any {
    let userPath = this.environment.urlbase + 'usermanagement/services/tracgroups/'
    let path: string = userPath + building;
    return this.http.get(path, { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }


  getChanges(id) {
    let path: string = '/changelog/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }


  // '/faultreport/services/tickets/severity/<category>/<type>/<location>'



  getPriority(category, type, location): any {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    };
    let escapeLocation = escape(location)
    let path = "/severity/" + category + "/" + type + "/" + escapeLocation;
    return this.http.get(this.getUrl(path), httpOptions).pipe(
      map(data => { return data })
    );
    /*  let groups:string[]=['High', 'Medium', 'Low'];
      return groups[Math.floor(Math.random()*groups.length)];*/
  }
  //FILES   

  getFiles(id) {
    let path: string = '/files/list/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );
  }

  upload(formData, ticket_id) {
    let path: string = '/files/upload/' + ticket_id;
    return this.http.post(this.getUrl(path), formData, { reportProgress: true, observe: 'events', responseType: 'text', params: new HttpParams().set('id', this.currentDate()) });

  }

  deleteFile(ticket_id, filename) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain'
      }),
      'responseType': 'text' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };

    let path: string = '/files/delete/' + ticket_id + '/' + filename;
    return this.http.delete(this.getUrl(path), httpOptions).pipe(
      map(data => data)
    );
  }

  downloadFile(ticket_id, filename) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/octect-stream'
      }),
      'responseType': 'blob' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };
    let path: string = '/files/' + ticket_id + '/' + filename;
    return this.http.get(this.getUrl(path), httpOptions).pipe(
      map(data => data)
    );
  }
  //REPORTING
  getReporting(currentUser: User, filterValues) {
    let query = "/time=" + filterValues.startDate + ".." + filterValues.endDate.toISOString().split("T")[0];
    if (!this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDALL)) {
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDASIGNED)) {
        filterValues['owner'] = currentUser.roles[0].name;
        query = query + "&owner=" + currentUser.roles[0].name;
      }
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINMINE)) {
        filterValues['reporter'] = currentUser.mail;
        query = query + "&reporter=" + currentUser.mail;
      }
    }
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path = '/reporting' + query;
    return this.http.get(this.getUrl(path)).pipe(
      map(data => data)
    );
  }


  // LOCATIONS

  getTowers() {
    return this.http.get(this.urlLocation + '/towers').pipe(
      map(data => data)
    );
  }

  getFloorsByTower(tower) {
    return this.http.get(this.urlLocation + '/towers/' + tower + '/floors').pipe(
      map(data => data)
    );
  }

  getLocationByFloorTower(tower, floor) {
    return this.http.get(this.urlLocation + '/towers/' + tower + '/floors/' + floor + '/locations').pipe(
      map(data => data)
    );
  }

  // CATEGORIES

  getCategory() {
    return this.http.get(this.urlCategories + '/categories').pipe(
      map(data => data)
    );
  }

  getSubcategorieByCategorie(categorie) {
    return this.http.get(this.urlCategories + '/categories/' + categorie + '/subcategory').pipe(
      map(data => data)
    );
  }
}


