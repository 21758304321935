import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressBookState } from '../../address-book/store/microapp-vms-address-book.reducer';
import * as fromAddressBook from '../../address-book/store/microapp-vms-address-book.reducer';


export const selectAddressBookState =
  createFeatureSelector<AddressBookState>("addressBook");

export const selectAddressBookLoading = createSelector(
  selectAddressBookState,
  state => state.loading
);

export const selectAddressBookError= createSelector(
  selectAddressBookState,
  state => state.error
);

export const selectVisitorListLoadedOrError = createSelector(
  selectAddressBookState,
  state => state.visitors_loaded || state.error
);




