import { MicroappFbsUserModel } from '../../client/dto/microapp-fbs-user';
import { createReducer, on } from '@ngrx/store';
import * as fbsActions from './microapp-fbs-actions'
import { MicroappFbsResourceModel } from '../../client/dto/microapp-fbs-resource';
import { MicroappFbsChargeType } from '../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsReservationGroupModel } from '../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsSchedule } from '../../client/dto/microapp-fbs-schedule';


export interface FbsState {
    fbsUser: MicroappFbsUserModel;
    error: boolean;
    errorMsg: string;
    resources: MicroappFbsResourceModel[];
    resources_grouped: MicroappFbsResourceGroup[];
    resources_grouped_for_admin: MicroappFbsResourceGroup[];
    facilitiesWithBookInfo: boolean;
    myBookings: MicroappFbsReservationGroupModel[];
    chargeTypes: MicroappFbsChargeType[];
    schedule: MicroappFbsSchedule;
    loading_user: boolean;
    loading_ChargeTypes: boolean;
    loading_data:boolean;
    loading_resources:boolean;
    loading_facilitiesAvailability: boolean;
    loading_my_reservations: boolean;
}


const initialState = {
    fbsUser: null,
    error: false,
    errorMsg: null,
    resources: null,
    resources_grouped: null,
    resources_grouped_for_admin: null,
    facilitiesWithBookInfo: false,
    myBookings: null,
    chargeTypes: null,
    schedule: null,
    loading_user: false,
    loading_ChargeTypes: false,
    loading_data: false,
    loading_resources: false,
    loading_facilitiesAvailability: false,
    loading_my_reservations: false,
}


const _counterReducer = createReducer(
    initialState,
    on(fbsActions.loadFbsUser, (state) => ({
        ...state,
        loading_user: true,
    })),
    on(fbsActions.fbsUserLoaded, (state, action) => ({
        ...state,
        fbsUser: action.fbsUser,
        loading_user: false,
        errorMsg: null,
        error: false
    })),
    on(fbsActions.loadChargeTypes, (state, action) => ({
        ...state,
        chargeTypes: null,
        loading_ChargeTypes: true,
    })),
    on(fbsActions.chargeTypesLoaded, (state, action) => ({
        ...state,
        chargeTypes: action.chargeTypes,
        loading_ChargeTypes: false,
        errorMsg: null,
        error: false
    })),
    on(fbsActions.loadData, (state, action) => ({
        ...state,
        loading_data: true,
        errorMsg: null,
        error: false,
        resources: null,
        resources_grouped: null,
        resources_grouped_for_admin: null,
        myBookings: null,
        schedule: null,
    })),
    on(fbsActions.dataLoaded, (state, action) => ({
        ...state,
        loading_data: false,
        errorMsg: null,
        error: false,
        facilitiesWithBookInfo: false,
        resources: action.resources,
        resources_grouped: action.resources_grouped,
        resources_grouped_for_admin: action.resources_grouped_for_admin,
        myBookings: action.myBookings,
        schedule: action.schedule,
    })),
    on(fbsActions.loadResources, (state, action) => ({
        ...state,
        loading_resources: true,
        errorMsg: null,
        error: false,
        resources: null,
        resources_grouped: null,
    })),
    on(fbsActions.resourcesLoaded, (state, action) => ({
        ...state,
        loading_resources: false,
        errorMsg: null,
        error: false,
        facilitiesWithBookInfo: false,
        resources: action.resources,
        resources_grouped: action.resources_grouped,
        resources_grouped_for_admin: action.resources_grouped_for_admin,
    })),
    on(fbsActions.checkAllFacilitiesAvailability, (state, action) => ({
        ...state,
        loading_facilitiesAvailability: true,
        errorMsg: null,
        error: false,
    })),
    on(fbsActions.checkedAllFacilitiesAvailability, (state, action) => ({
        ...state,
        loading_facilitiesAvailability: false,
        errorMsg: null,
        error: false,
        facilitiesWithBookInfo: true,
        resources: action.resources,
        resources_grouped: action.resources_grouped
    })),
    on(fbsActions.loadMyReservations, (state, action) => ({
        ...state,
        loading_my_reservations: true,
        errorMsg: null,
        error: false,
    })),
    on(fbsActions.myReservationsLoaded, (state, action) => ({
        ...state,
        loading_my_reservations: false,
        errorMsg: null,
        error: false,
        myBookings: action.myBookings,
    })),
    on(fbsActions.error, (state, action) => ({
        ...state,
        loading_user: false,
        loading_ChargeTypes: false,
        loading_data: false,
        loading_resources: false,
        loading_facilitiesAvailability: false,
        loading_my_reservations: false,
        errorMsg: action.errorMsg,
        error: true,
    })),
    on(fbsActions.resetError, (state, action) => ({
        ...state,
        errorMsg: null,
        error: false,
    })),
    on(fbsActions.resetFacilitiesWithBookInfo, (state, action) => ({
        ...state,
        facilitiesWithBookInfo: false,
    })),
);

export function fbsCounterReducer(state, action) {
    return _counterReducer(state, action);
}