import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, User, Site } from '@empusa/empusa-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { MICROAPP_ACCESS_NOTIFICATIONS, MICROAPP_NAME } from '../public-api';
// import { MICROAPP_ACCESS_NOTIFICATIONS, MICROAPP_NAME } from '../lib/microapp-notifications.module';

@Component({
  selector: 'empusa-microapp-notifications-home',
  templateUrl: 'microapp-notifications.home.component.html',
  styleUrls: ['microapp-notifications.home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroappNotificationsComponent implements OnInit {
  siteName: string;
  user: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  can_access: boolean = false;
  constructor(private translate: TranslateService, private auth: AuthenticationService,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {

    this.auth.loadCurrentUser().then((user: User) => {
      this.siteName = user.sites[0].name;
      this.can_access = this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_ACCESS_NOTIFICATIONS)
      this.isAdmin = this.auth.hasCurrentUserRole("ADMIN");
      this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
    })
  
  }
}