import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpsTenantQuotas } from '../../../lib/model/microapp-eps.tenan-quote.model';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';
import { Store } from '@ngrx/store';
import { EpsParkingSize } from '../../../lib/model/microapp-eps.parking-size.model';
import { EpsVehicleType } from '../../../lib/model/microapp-eps.vehicle-type.model';
import { EpsQuote } from '../../../lib/model/microapp-eps.quote.model';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { maxValueValidator } from "../../../lib/utils/form-validations.directive"

@Component({
  selector: 'empusa-microapp-eps-quotas-edit',
  templateUrl: './microapp-eps-quotas-edit.component.html',
  styleUrls: ['./microapp-eps-quotas-edit.component.css']
})
export class MicroappEpsQuotasEditComponent implements OnInit {

  quotas: EpsTenantQuotas;
  parkingSize: EpsParkingSize[];
  parkingVehicleTypes: EpsVehicleType[];
  //Form
  formGroup: FormGroup;
  loading: boolean = false;
  disabled: boolean = false;
  constructor(
    private epsServices: EpsServices,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    public dialogRef: MatDialogRef<MicroappEpsQuotasEditComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.quotas = { ...data.quotas };
    this.parkingSize = data.parkingSize;
    this.parkingVehicleTypes = data.parkingVehicleTypes;
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.formGroup = new FormGroup({});
    for (var spot_type of this.parkingSize) {
      for (var vehicle_type of spot_type.vehicle_type) {
        //Search control value
        let value = 0;
        var result = this.quotas.quota.find(o => o.spot_type_id === spot_type.spot_type_id &&
          o.vehicle_type_id === vehicle_type.vehicle_type_id);
        if (result) {
          value = result.quota;
        }
        //Create control
        let maximunValue = 0;
        const spotFound = this.parkingSize.find(ps => ps.spot_type_id === spot_type.spot_type_id);
        if (spotFound) {
          const quota_found = spotFound.vehicle_type.find(vt => vt.vehicle_type_id === vehicle_type.vehicle_type_id);
          if (quota_found && quota_found.can_assign) {
            maximunValue = quota_found.can_assign;
          }
        }
        let association_enabled_initial = this.quotas.quota.find(quota=> quota.spot_type_id== 2 && quota.vehicle_type_id ==1).association_enabled;
        let control = new FormControl(value, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(999), maxValueValidator(maximunValue)]);
        this.formGroup.addControl(spot_type.spot_type_id + "#" + vehicle_type.vehicle_type_id, control);
        this.formGroup.addControl('association_enabled', new FormControl(association_enabled_initial));
        if(this.formGroup.controls['association_enabled'].value){
          this.disabled = false;
        }else{
          this.disabled = true;
        }
      }
    }
  }

  doAction() {
    let new_quotas = new EpsTenantQuotas();
    new_quotas.tenant_id = this.quotas.tenant_id;
    new_quotas.non_entitled_associations_enabled =  this.formGroup.controls['association_enabled'].value;
    new_quotas.quota = [];
    Object.keys(this.formGroup.controls).forEach(key => {
      let a_quote = new EpsQuote();
      var splitted = key.split("#", 2);
      a_quote.spot_type_id = Number(splitted[0])
      a_quote.vehicle_type_id = Number(splitted[1])
      a_quote.quota = Number(this.formGroup.controls[key].value);
      a_quote.association_enabled = this.formGroup.controls['association_enabled'].value;
      if (key != 'association_enabled'){
        new_quotas.quota.push(a_quote)
      }
    });
    this.loading = true;

    this.epsServices.setQuotas(new_quotas).subscribe(tenantQuotas => {
      this.loading = false;
      this.store.dispatch(epsActions.epsStoreQuotas({ tenantQuotas }));
      this.store.dispatch(epsActions.epsGetParkingSize());
      this.dialogRef.close({ event: 'ok' });
      // TODO: SHOW ok message
    }, error => {
      this.loading = false;
      let txt = this.translate.instant('MAPP-EPS.GENERIC.UNKNOWN_ERROR');
      if (error instanceof HttpErrorResponse) {
        if (error.status === 409) { //oversize parking.
          txt = this.translate.instant('MAPP-EPS.QUOTAS.UDPATE_QUOTA_ERROR');
        }
      }
      this.openSnackBar(txt, "X");
    }, () => {
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }

  getErrorMessage(controlName: string) {
    const hasError = this.formGroup.controls[controlName].errors;
    let errorTxt = ""
    if (hasError !== null) {
      const firstError = Object.keys(hasError)[0];
      switch (firstError) {
        case 'maxValue': {
          errorTxt = this.translate.instant('MAPP-EPS.DIALOG_ASSIGN_QUOTAS.MAX_VALUE');
          break
        }
        case 'required': {
          errorTxt = this.translate.instant('MAPP-EPS.VALIDATIONS.MANDATORY');
          break
        }
        default: {
          errorTxt = this.translate.instant('MAPP-EPS.VALIDATIONS.BAD_VALUE');
        }
      }
    }
    return errorTxt;
  }

  changeSelection(event){
    if(event.checked){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }
}



