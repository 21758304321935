import { AccessRigths } from "./accessRights";
import { Floor } from "./floor";
import { AccessGroup } from "./accessGroup";

export class Tenant {
    uid: string;
    name: string;
    description: string;
    billingTenantId:string;
    cn:string;
    dn:string;
    ou:string;
    floor: Floor [];
    type:string;
    accessRights: AccessRigths[];
    is_site: boolean;
    accessGroups: AccessGroup[];

    constructor() {
    }
}
​
