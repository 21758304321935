import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthenticationService, EmpusaQuestionDialog, Tenant, User } from "@empusa/empusa-core";
import { TranslateService } from "@ngx-translate/core";
// import { EpsService } from "../../../client/eps.service";
// import { UserService } from "../../../client/user.service";
import * as moment_ from 'moment';
// import { DatePipe } from "@angular/common";
// import { ReservationsTenantFilter } from "./microapp-eps.tenant-reservation-eps.componentfilter";
// import { Reservation } from "../../../client/dto/reservation.dto";
import { MatDialog } from "@angular/material/dialog";
import { ReservationsTariffFilter } from "./microapp-hvac.tenant-reservation-hvac.componentfilter";
import { UserService } from "../../client/user.service";
import { SmartBmsService } from "../../client/smartbms.service";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
const moment = moment_;
@Component({
  selector: 'microapp-hvac-reservationsTenant-hvac-component',
  templateUrl: './microapp-hvac.tenant-reservation-hvac.component.html',
  styleUrls: ['./microapp-hvac.tenant-reservation-hvac.component.css']
})
export class ReservationsTenantComponent implements OnInit {
  displayedColumns: string[] = ['user', 'date', 'extension_start', 'extension_end', 'hours', 'devices'];
  dataSource = new MatTableDataSource([]);
  currentUser: User;
  loading: boolean = true;
  tenant: string;
  from: string;
  to: string;
  filterGroup: FormGroup;
  userFilter = new ReservationsTariffFilter();
  filterValues = {};
  currentDateTime;
  isCar: boolean;
  isAdmin: boolean;
  public tenantForm: FormGroup;
  tenantsToSelect: Tenant[] = [];
  building: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly localDateFormat = "dd/MM/yyyy";
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private bmsservice: SmartBmsService, private datepipe: DatePipe,
    private translate: TranslateService, private formBuilder: FormBuilder, private auth: AuthenticationService, private userService: UserService) {
    this.tenantForm = this.formBuilder.group({
      tenants: ['', Validators.required],
      FROM: ['', Validators.required],
      TO: ['', Validators.required],
    });
    this.auth.loadCurrentSite().then(site => {
      this.building = site.uid;
    })
    auth.loadCurrentUser().then(user => {
      this.currentUser = user;
      this.tenant = "";
      this.userService.findAllTenants().then(resp => {
        resp.forEach(element => {
          this.tenantsToSelect.push(element);
        });
      });
      this.tenant = this.currentUser.sites[0].tenants[0].uid;

      this.viewReservation();
    })
  }

  ngOnInit() {
    this.filterGroup = this.buildFilter();
  }

  get f(): any { return this.tenantForm.controls; }

  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl(""),
    })
  }

  viewReservation() {
    this.loading = true;
    this.f.tenants.setValue(this.tenant);
    let extensionList = []
    const extensionTenant = this.bmsservice.getExtensionByTenantId(this.tenant, this.from, this.to);
    const buildingDevices = this.bmsservice.devicesBuilding(this.building);
    console.log("buildingDevices", buildingDevices)
    forkJoin([extensionTenant, buildingDevices]).subscribe(resultados => {
      console.log("resultados", resultados)
      // this.loading = false;
     

      let extensions = resultados[0].extensions
      let devicesList = resultados[1].devices
      console.log("ext", extensions)
      console.log("devices", devicesList)
      extensions.forEach(extension => {
        let ext: Ext = new Ext();
        this.userService.getUser(extension.userid).subscribe(user => {
          ext.user = user.mail;
        })
        let deviceH = 0;
        let deviceF = 0;
        let deviceN = "";
        extension.devices.forEach(deviceExt => {
          // let deviceIdExt = deviceExt.deviceid;
          console.log("deviceIdExt", deviceExt)
          let dev = devicesList.find(device => device.deviceid == deviceExt)
          console.log("dev", dev);
          if (dev) {
            if (dev.device_type === "HVAC") {
              deviceH++;
            } else if (dev.device_type === "FAN") {
              deviceF++;
            }
          }
        });
        console.log("h ", deviceH, " f ", deviceF)
        if (deviceH > 0) {
          deviceN = deviceH + "(AHU)"
        } if (deviceH > 0 && deviceF > 0) {
          deviceN = deviceN + " - "
        } if (deviceF > 0) {
          deviceN = deviceN + deviceF + "(FCU)"
        }
        ext.devices = deviceN;
        ext.extension_start = this.formatDate(extension.startdate).toString();
        ext.extension_end = this.formatDate(extension.enddate).toString();
        ext.date = this.formatDate(extension.startdate).toString();
        ext.hours = extension.num_hours
        extensionList.push(ext)
      });

      console.log("list", extensionList)
      let tempDataSource = new MatTableDataSource(extensionList);
      this.loading = false;
      tempDataSource.sort = this.sort;
      tempDataSource.paginator = this.paginator;
      this.defineDataSourceFilter(tempDataSource);
      this.dataSource = tempDataSource;
    },
      error => {
        this.loading = false;
        this.snackBar.open(this.translate.instant("MAPP-HVAC.FACILITY.RESERVATIONS.ERROR.TITLE"),
          this.translate.instant("MAPP-HVAC.FACILITY.RESERVATIONS.ERROR.MESSAGE"), {
          duration: 5000,
        });
      })
  }

  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: Ext, filter: string) => {
        const theFilter: ReservationsTariffFilter = JSON.parse(filter);
        let generalFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.user != null) {
            if (aGroup.extension_start.toLowerCase().trim().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.extension_end.toLowerCase().trim().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.user.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.hours.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.date.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.devices.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          } else {
            if (aGroup.extension_start.toLowerCase().trim().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.extension_end.toLowerCase().trim().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.hours.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.date.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
            if (aGroup.devices.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }
          }
        } else generalFilter = true;
        return generalFilter;
      }
  }

  formatDate(date) {
    let formatedBarra: String = date.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + date.slice(11) + "Z";
    let dateNew = new Date(formated);
    return dateNew;
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    console.log("reset filters");
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  tenantSelected() {
    this.tenant = this.f.tenants.value;

    this.from = this.datepipe.transform(this.f.FROM.value, 'dd/MM/yyyy');
    this.to = this.datepipe.transform(this.f.TO.value, 'dd/MM/yyyy');
    this.viewReservation();
  }
}

export class Ext {
  extension_start: string
  extension_end: string
  user: string
  hours: string
  date: string
  devices: string
}

