import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MailService } from '../../client/mail.service';
import { Issue } from '../../client/dto/issue';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface MailData{
  topTable:string;
  bottonTable:string;
  list:Issue[];
  comment:string;
}

@Component({
    selector: 'microapp-faultreport-mail-fault-component',
    templateUrl: './microapp-faultreport.component-mail-fault.component.html',
    styleUrls: ['./microapp-faultreport.component-mail-fault.component.css']
  })
  export class MailComponent{
    displayedColumns: string[] = ['status', 'priority',  'date', 'owner','summary'];
    dataSource = new MatTableDataSource([]);

      constructor(private emailService:MailService, 
         private translate: TranslateService, 
          @Inject(MAT_DIALOG_DATA) public data:MailData){
          this.dataSource = new MatTableDataSource(data.list);

         }
  
       
  
  }
  