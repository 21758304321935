<h2 class="titleDel" mat-dialog-title> {{'MAPP-FBS-DELETE-RESERVATION.TITLE' | translate}}</h2>
<div>
  {{'MAPP-FBS-DELETE-RESERVATION.TEXT' | translate}}
</div>
<p></p>
<div class="divAccesoryTotal">
  <div *ngIf="isAccesory">
    <div class="izquierdaAccesory">
      <div>
        <div class="accesoryImage"></div>
        <div class="marginWithImage">
          <mat-label class="resourceName">{{resource.name}} </mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-DELETE-RESERVATION.RESOURCES' | translate}} : {{resource.availableResources}}</b>
          </mat-label> 
          <p></p>
          <mat-label class="accesoryReservationLabel"> {{resource.description}}</mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel"><b>  {{'MAPP-FBS-DELETE-RESERVATION.PRICE' | translate}}:</b>       
            {{resource.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}
          </mat-label>
        </div>
      </div>
    </div>

    <div class="derechaAccesory">
      <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>
      <div class="forMarginLeft">
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.TITLE' | translate}}</b>
          {{reservation.title}}
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.DESCRIPTION' | translate}}</b>
          {{reservation.description}}
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.DATE' | translate}}</b>
          {{reservation.startDate | date:'shortDate'}}
          {{reservation.startDate | date:'HH:mm'}} -
          {{reservation.endDate | date:'HH:mm'}}.
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.COST' | translate}}</b>
          {{reservation.total_cost | currency :fbsConfig.CURRENCY:'symbol-narrow'}}
        </mat-label>
      </div>
    </div>
  </div>


  
  <div *ngIf="!isAccesory">
    <div class="izquierdaAccesory">
      <div>
        <div class="facilityImage"></div>
        <div class="marginWithImage">
          <mat-label class="resourceName">{{resource.name}} </mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel">
            <b>{{'MAPP-FBS-DELETE-RESERVATION.LOCATION' | translate}}:</b> {{resource.location}}
          </mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel">
            <b>{{'MAPP-FBS-DELETE-RESERVATION.PARTICIPANTS' | translate}}:</b> {{resource.maxParticipants}}
          </mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel"> {{resource.description}}</mat-label>
          <p></p>
          <mat-label class="accesoryReservationLabel"><b>  {{'MAPP-FBS-DELETE-RESERVATION.PRICE' | translate}}:</b> {{resource.tarif.unit_price | currency :coin:'symbol-narrow'}}</mat-label>
        </div>
      </div>
    </div>

    <div class="derechaAccesory">
      <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>
      <div class="forMarginLeft">
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.TITLE' | translate}}</b>
          {{reservation.title}}
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.DESCRIPTION' | translate}}</b>
          {{reservation.description}}
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.DATE' | translate}}</b>
          {{reservation.startDate | date:'shortDate'}}
          {{reservation.startDate | date:'HH:mm'}} -
          {{reservation.endDate | date:'HH:mm'}}.
        </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-RESERVATION-CONFIRM.COST' | translate}}</b>
          {{reservation.total_cost | currency :coin:'symbol-narrow'}}
        </mat-label>
      </div>
    </div>
  </div>

</div>

























<!-- 


<div class="divAccesoryTotalConfirm">
  <div class="izquierdaAccesory">
    <div>
      <div class="accesoryImage"></div>
      <mat-label class="resourceName"> {{reservation.location}} </mat-label>
      <p></p> -->
<!-- <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-FACILITY-RESERVATION.CAPACITY' | translate}}:</b>
          {{reservation.maxParticipants}}.
        </mat-label> -->
<!-- <p></p>
      <mat-label class="accesoryReservationLabel">
        <b> {{'MAPP-FBS-FACILITY-RESERVATION.UNITARY_COST' | translate}}</b>
        {{reservation.total_cost}}.</mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        {{reservation.description}}
      </mat-label>
      <p></p>
    </div>
  </div>
  <div class="derechaAccesory">
    <mat-divider class="dividerAccesoryConfirm" [vertical]="true"></mat-divider> -->
<!-- <empusa-microapp-fbs-reservation-confirm [reservation]="reservation" [dates]="dateChecked" [cost]="unitaryCost">
      </empusa-microapp-fbs-reservation-confirm> -->
<!-- </div>
</div>

<div mat-dialog-actions class="buttonFitting" align="end">
  <button mat-raised-button color="primary" (click)="close()">
    {{'MAPP-FBS-DELETE-RESERVATION.CANCEL' | translate}}</button>
  <button class="buttonConfirm" mat-raised-button color="primary" (click)="deleteReservation()">
    {{'MAPP-FBS-DELETE-RESERVATION.CONFIRM' | translate}}</button>
</div>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner> -->


<!-- 
<h2 *ngIf="mode_confirm" class="titleNew">Confirm<button class="closeEditVist" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<div *ngIf="mode_confirm" class="divAccesoryTotalConfirm">
  <div class="izquierdaAccesory">
    <div>
      <div class="accesoryImage"></div>
      <mat-label class="resourceName"> {{facility.location}} </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-FACILITY-RESERVATION.CAPACITY' | translate}}:</b>
        {{facility.maxParticipants}}.
      </mat-label>{{facility.maxParticipants}}.
      <p></p>
      <mat-label class="accesoryReservationLabel">
        <b> {{'MAPP-FBS-FACILITY-RESERVATION.UNITARY_COST' | translate}}</b>
        {{facility.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}.</mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        {{facility.description}}
      </mat-label>
      <p></p>
    </div>
  </div>
  <div class="derechaAccesory">
    <mat-divider class="dividerAccesoryConfirm" [vertical]="true"></mat-divider>
    <empusa-microapp-fbs-reservation-confirm [reservation]="reservation" [dates]="dateChecked" [cost]="unitaryCost">
    </empusa-microapp-fbs-reservation-confirm>
  </div>
</div>
<div mat-dialog-actions class="buttonFitting" align="end" *ngIf="mode_confirm">
  <button mat-raised-button color="primary" (click)="mode_confirm=false">
    {{'MAPP-FBS-FACILITY-RESERVATION.BACK' | translate}}</button>
  <button class="buttonConfirm" mat-raised-button color="primary" (click)="confirmBooking()">
    {{'MAPP-FBS-FACILITY-RESERVATION.CONFIRM' | translate}}
  </button>
</div>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner> -->

<div mat-dialog-actions class="buttonFitting" align="end">
    <button mat-raised-button color="primary" (click)="close()"> {{'MAPP-FBS-DELETE-RESERVATION.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary" (click)="deleteReservation()"> {{'MAPP-FBS-DELETE-RESERVATION.CONFIRM' | translate}}</button>
  </div>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>
