import { Observable, of, from, throwError } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmpusaFakeBackend } from '@empusa/empusa-core';
import { MICROAPPFAKEDATABASE } from './fake.database';
import { Generic } from '../client/dto/generic';

/**
 * This is CRUD example for the Generic Fake Backend
 */
export class FaultreportFakeBackend extends EmpusaFakeBackend {
    microappFakeData = MICROAPPFAKEDATABASE;

    handleRoute(url: string, method: string, headers, body, database: any): Observable<HttpEvent<any>> {
        switch (true) {
            case url.endsWith('generics') && method === 'GET':
                return this.getGenerics(database);
            case url.match(/\/generics\/.*$/) && method === 'DELETE':
                return this.deleteGeneric(database, EmpusaFakeBackend.idFromUrl(url));
            case url.match(/\/generics\/.*$/) && method === 'GET':
                return this.getGenericById(database, EmpusaFakeBackend.idFromUrl(url));
            case url.match(/\/generics\/.*$/) && method === 'PUT':
                return this.updateGeneric(database, body, EmpusaFakeBackend.idFromUrl(url));
            case url.endsWith('generics') && method === 'POST':
                return this.createGeneric(database, body);
            default:
                //otherwise, we don't handle this request
                return null;
        }

    }

    createGeneric(database, body) {
        let generic: Generic = JSON.parse(body);
        generic.sid = "" + Date.now();
        let generici = {
            sid: generic.sid,
            name: generic.name
        }

        database.generics.push(generici);
        return EmpusaFakeBackend.ok(generici);
    }

    getGenerics(database): Observable<HttpResponse<Generic[]>> {
        let result: Generic[] = [];
        //creating generics object
        database.generics.forEach((x) => {
            let generic: Generic = new Generic();
            generic.sid = x.sid;
            generic.name = x.name;
            result.push(generic);
        });
        return EmpusaFakeBackend.ok(result);
    }

    getGenericById(database, sid) {
        const generici = database.generics.find(x => x.sid == sid);
        let generic: Generic = new Generic();
        generic.sid = generici.sid;
        generic.name = generici.name;
        return EmpusaFakeBackend.ok(generic);
    }

    updateGeneric(database, body, sid) {
        let generic: Generic = JSON.parse(body);
        let generici = {
            sid: generic.sid,
            name: generic.name
        }

        database.generics = database.generics.map(x => (x.sid == sid ? generici : x));
        return EmpusaFakeBackend.ok(body);
    }

    deleteGeneric(database, sid) {
        database.generics = database.generics.filter(x => x.sid !== sid);
        return EmpusaFakeBackend.ok();
    }







}