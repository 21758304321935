import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmpusaQuestionDialog } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatListModule} from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { TranslationLoaderService } from '@empusa/empusa-core';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';
import { MicroappFaultreportComponent } from './microapp-faultreport.home.component';
import { ListFaultComponent } from './list/microapp-faultreport.list-fault.component';
import { ReportComponent} from './report/microapp-faultreport.report-fault.component';
import { ChangeassingDialog } from './component/microapp-faultreport.component-changeassing-fault.component';
import { ResolveDialog } from './component/microapp-faultreport.component-resolve-fault.component';
import { EscalationDialog } from './component/microapp-faultreport.component-escalation-fault.component';
import { MailComponent } from './component/microapp-faultreport.component-mail-fault.component';
import { NewIssueComponent } from './new/microapp-faultreport.new-fault.component';
import { MatDialogModule } from '@angular/material/dialog';
import {HttpClientModule} from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatGridListModule} from '@angular/material/grid-list';
import { EditComponent } from './edit/microapp-faultreport.edit-fault.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/microapp-faultreport.mat-spinner-overlay.component';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';


export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}


@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    TranslateModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDialogModule,
    MatListModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    FormsModule,
    HttpClientModule,
    MatTooltipModule,
    ChartsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatGridListModule,
    RouterModule.forChild([
      {
        path: '', component: MicroappFaultreportComponent
        // , children: [
        //   { path: '', redirectTo: "list", pathMatch: 'full' },
        //   { path: 'list', component: ListFaultComponent},
        //   { path : 'report', component: ReportComponent, pathMatch: 'full' },
          
        // ]
      },
    ])
  ],
  declarations: [
    MicroappFaultreportComponent,
    ListFaultComponent,
    ReportComponent,
    EditComponent,
    ChangeassingDialog,
    MatSpinnerOverlayComponent,
    ResolveDialog,
    EscalationDialog,
    NewIssueComponent,
    MailComponent
  ],
  providers: [DatePipe,
    MatSnackBar,
    { provide: DateAdapter, useClass: DateFormat }
  ]
})
export class FaultreportModule {

  constructor(private translationLoader: TranslationLoaderService, private dateAdapter: DateAdapter<Date>) {
    console.log("home management lazy constructor");
    dateAdapter.setLocale("en-SG"); // DD/MM/YYYY
    
  }

}
