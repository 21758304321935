<div class="table-container">
  <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8"
    id="tablaReservationTenat">
    <ng-container matColumnDef="tenant_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.TENANT' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.tenant_name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="vehicle_owner_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLEOWNER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.vehicle_owner_name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="license_plate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLENO' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon>{{element.vehicle_type.vehicle_icon}}</mat-icon> {{element.license_plate}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="from_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.EFFECTIVE' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.from_date | date:'fullDate'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="to_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.EXPIRED' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.to_date | date:'fullDate'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ReservationType">
      <mat-header-cell *matHeaderCellDef>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.RESERVATIONTYPE' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.reservation_type.reservation_types_short_desc}} -
        {{element.spot_type.spot_short_desc}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="vehicle_iu">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.IU' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.vehicle_iu}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.STATUS' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-chip [ngClass]="{'chip-paypal_pending':element.status== 'PAYPAL_PAYMENT_PENDING', 'chip-paypal_done':element.status== 'PAYPAL_PAID',
        'chip-paypal_done_yardi':element.status== 'PAYPAL_PAID_YARDI_SYNC',
        'chip-pre-reserved':element.status== 'PRE-RESERVED','chip-confirmed':element.status== 'PAYMENT_DONE', 'chip-consumed':element.status== 'CONSUMED',
        'chip-canceled':element.status== 'CANCELED', 'chip-payment_pending':element.status=='YARDI_PAYMENT_PENDING', 'chip-expired':element.status=='EXPIRED' }">
        {{'MAPP-EPS.TENANT.RESERVATION_STATUS.' + element.status |
        translate}}</mat-chip></mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell [ngClass]="{'hidden-item':isAdmin}" *matHeaderCellDef>
        {{'MAPP-EPS.TENANT.RESERVATIONTENANT.ACTIONS' | translate}}
      </mat-header-cell>
      <mat-cell [ngClass]="{'hidden-item':isAdmin}" *matCellDef="let element">
        <div class="action">
          <button
            *ngIf="element.from_date.getTime() > currentDateTime && (element.status=='YARDI_PAYMENT_PENDING' || element.status=='PRE-RESERVED' || element.status=='PAYPAL_PAYMENT_PENDING')"
            matTooltip="{{'MAPP-EPS.TENANT.RESERVATIONTENANT.CANCEL' | translate}}" mat-icon-button
            (click)="deleteReservation(element.id)">
            <mat-icon class="icActions">delete</mat-icon>
          </button>
          <button *ngIf="element.from_date.getTime() > currentDateTime && element.status=='PRE-RESERVED'"
            matTooltip="{{'MAPP-EPS.TENANT.RESERVATIONTENANT.CONFIRM' | translate}}" mat-icon-button
            (click)="confirmReservation(element.id)">
            <mat-icon class="icActions">done_outline</mat-icon>
          </button>
          <button *ngIf="(element.status=='PAYMENT_DONE' && element.is_recurrent === true) || (element.status=='PAYPAL_PAID_YARDI_SYNC' && element.is_recurrent === true)"  
            matTooltip="Cancel Recurrent Reserve" mat-icon-button (click)="cancelRenewed(element.id)">
            <mat-icon class="icActions">cancel</mat-icon>
          </button>
          <!-- <mat-icon class="icActions">cancel</mat-icon> -->
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td class="tdVisitors" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail"
          [@detailExpand]="element == expandedElement || allRowsExpanded ? 'expanded' : 'collapsed'">
          <div class="example-element-diagram">
          </div>
          <div class="example-element-description">
            <span>
              S${{element.price}}{{'MAPP-EPS.TENANT.RESERVATIONTENANT.PRICEIS' | translate}}
              <span *ngIf="element.reservation_tariffication_type==='Daily'">
                {{element.units}}
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.DAY' | translate}}.
              </span>
              <span *ngIf="element.reservation_tariffication_type==='Monthly'">
                {{element.units}}
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.MONTH' | translate}}.
                <span>
                  {{'MAPP-EPS.TENANT.INVOICE.TARIFF_' + element.reservation_type.reservation_types_code |
                  translate}}
                  {{'MAPP-EPS.TENANT.RESERVATIONTENANT.TARIFF_APPLIED' | translate}}
                </span>
                <span *ngIf="element.is_recurrent === true">
                  <ng-container *ngIf="!element.recurrent_done">
                    {{'MAPP-EPS.TENANT.RESERVATIONTENANT.RECURRENT' | translate}}.
                  </ng-container>
                  <ng-container *ngIf="element.recurrent_done">
                    {{'MAPP-EPS.TENANT.RESERVATIONTENANT.RENEW_DONE' | translate}}.
                  </ng-container>
                </span>
              </span>
              <p></p>
              <span *ngIf="element.charge_to==='Tenant'">
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.COMPLIMENTARY' | translate}}</span>
              <span *ngIf="element.user_reservation_type ==='employee'">
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.EMPLOYEE' | translate}}.</span>
              <span *ngIf="element.user_reservation_type ==='visitor'">
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VISITOR' | translate}}.</span>
              <p></p>
              <span *ngIf="element.source_system ==='EPS'">{{'MAPP-EPS.TENANT.RESERVATIONTENANT.EPS' | translate}}.</span>
              <span *ngIf="element.source_system ==='VMS'">
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VMS' | translate}}.
              </span>
              <p></p>
              <span *ngIf="element.create_date"> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.CONFIRMATION_DATE' | translate}} {{dateToTimezone(element.create_date)}}. </span>
              <p></p>
              <span
                *ngIf="element.is_recurrent === true && element.recurrent_status_code && element.recurrent_status_code !== 'OK'">
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.RENEW_ERROR' | translate}}
                {{'MAPP-EPS.TENANT.RESERVATION_STATUS_CODE.' + element.recurrent_status_code | translate}}.
                {{'MAPP-EPS.TENANT.RESERVATIONTENANT.WIL_BE_TRY' | translate}}
              </span>
            </span>
          </div>
        </div>
      </td>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="headerRowPri"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === row"
      (click)="expandedElement = expandedElement === row ? null : row; allRowsExpanded = false">
    </mat-row>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
<div class="footer-container">
  <mat-paginator class="paginator" [pageSizeOptions]="[10, 50, 20, 100]" showFirstLastButtons></mat-paginator>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
