
<div>

        <label>{{data.topTable}}</label>

</div>
<table mat-table [dataSource]="dataSource"  class="mat-elevation-z8">
     
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell 
            [ngClass]="{'statusOpen':element.status != 'closed', 'statusClose':element.status === 'closed'}"
            *matCellDef="let element"></td>
    </ng-container>
    <ng-container matColumnDef="priority">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell class="priority" 
            [ngClass]="{'priority1': element.priority === 1, 'priority2': element.priority ===2,
            'priority3': element.priority ===3}" 
            *matCellDef="let element"> {{element.priority}} </td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell *matCellDef="let element">{{element.owner}}</td>
    </ng-container>
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell *matCellDef="let element">{{element.date}}</td>
    </ng-container>
    <ng-container matColumnDef="summary">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell *matCellDef="let element">{{element.summary}}</td>
    </ng-container>
    <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div>
    <label *ngIf="data.comment!=null">"{{data.comment}}"</label>
</div>
<div>
    
        <label>"{{data.bottonTable}}"</label>
    
</div>