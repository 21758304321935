import { Pipe, PipeTransform } from '@angular/core';
import { MicroappVmsTime } from '../model/microapp-vms-time.model';


@Pipe({
    name: 'displayTime'
})
export class MicroAppVmsDisplayTime implements PipeTransform {



    transform(value: any): string {

        if (typeof (value) === 'string') {
            return value.substring(0,5)+"h";
        }

        if (value instanceof MicroappVmsTime) {

            if (!value) return "--:--";

            let s = "" + value.hour;
            if (value.hour < 10) {
                s = "0" + s;
            }
            if (value.minutes < 10) {
                s = s + ":0" + value.minutes;
            } else {
                s = s + ":" + value.minutes;
            }

            return s + "h";
        }

        return "--:--"
    }

}
