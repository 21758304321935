import { Tenant } from "@empusa/empusa-core";
import { EpsChargeType } from "./microapp-eps.charge-type.model";
import { EpsTariff } from "./microapp-eps.tariff.model";

export class EpsChargeTariffTenant {
    tenant: Tenant;
    tariff: EpsTariff;
    charge: EpsChargeType;

    constructor(tenant: Tenant, tariff: EpsTariff, charge: EpsChargeType){
        this.tenant = tenant;
        this.tariff = tariff;
        this.charge = charge;
    }
}
