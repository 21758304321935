import { Component, Input, OnInit } from '@angular/core';
import { FbsConfig } from '../../../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsReservationSlotModel } from '../../../../client/dto/microapp-fbs-reservation-slot';
import { MicroappFbsAccessoryReservationModel } from '../../../../client/dto/microapp-fbs-reserve-accessory';
import { SearchDates } from '../../../../client/microapp-fbs.interfaces';

@Component({
  selector: 'empusa-microapp-fbs-reservation-confirm',
  templateUrl: './microapp-fbs-reservation-confirm.component.html',
  styleUrls: ['./microapp-fbs-reservation-confirm.component.css']
})
export class MicroappFbsReservationConfirmComponent implements OnInit {

  constructor(public fbsConfig: FbsConfig,
  ) { }

  _reservation: MicroappFbsReservationSlotModel | MicroappFbsAccessoryReservationModel;
  _cost: number
  _dates: SearchDates

  @Input()
  set reservation(reservation: MicroappFbsReservationSlotModel | MicroappFbsAccessoryReservationModel) {
    this._reservation = reservation;
  }

  @Input()
  set dates(dates: SearchDates) {
    this._dates = dates;
  }

  @Input()
  set cost(cost: number) {
    this._cost = cost;
  }

  ngOnInit(): void {
  }

}
