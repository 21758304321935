import { Component, OnInit, Input } from '@angular/core';
import { FbsConfig } from '../../../../lib/microapp-facilitiesbooking-config';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';
import { Store } from '@ngrx/store';
import * as microappFbsState from '../../../../lib/store/microapp-fbs-app-reducer';

@Component({
  selector: 'empusa-microapp-fbs-facility-data',
  templateUrl: './microapp-fbs-facility-data.component.html',
  styleUrls: ['./microapp-fbs-facility-data.component.css']
})
export class MicroappFbsFacilityDataComponent implements OnInit {

  _facility: MicroappFbsResourceGroup;
  isAccesory: boolean;
  coin: string;

  allResources: MicroappFbsResourceGroup[] = [];

  @Input()
  set facility(facility: MicroappFbsResourceGroup) {
    this._facility = facility;
  }

  constructor(
    public fbsConfig: FbsConfig,
    private store: Store<microappFbsState.MicroappFbsStates>){

    this.coin = fbsConfig.CURRENCY;

    this.store.select('fbs').subscribe(data => {
      if (data && data.resources_grouped_for_admin){
        this.allResources = data.resources_grouped_for_admin;
      }
    });
  }

  ngOnInit(): void {
    if (this._facility.type == "accessory"){
      this.isAccesory = true;
    }else{
      this.isAccesory = false;
    }
  }

  filterResourcesByIds(ids: number[]){
    const filteredResources: MicroappFbsResourceGroup[] = [];

    ids.forEach((id) => {
      this.allResources.forEach((resource) => {
        if (id.toString() === resource.singleResourceId){
          filteredResources.push(resource);
        }
      })
    })
    return filteredResources;
  }

}
