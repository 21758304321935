<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div *ngIf="editUser.uid">
    <div class="headerEditNew">
        <h2 class="titleNew">{{'MAPP-USERMANAGEMENT.EDITUSER.TITLE' | translate}}</h2>
        <h5>({{editUser.mail}})</h5>
    </div>
    <div class="headerImg"> <img class="imgSize" [src]="url"></div>
</div>
<div *ngIf="!editUser.uid">
    <div class="headerEditNew">
        <h2 class="titleNew">{{'MAPP-USERMANAGEMENT.NEWUSER.TITLE' | translate}}</h2>
        <h5>{{'MAPP-USERMANAGEMENT.NEWUSER.SUBTITLE' | translate}}</h5>
    </div>
    <div class="headerImg"> <img class="imgSize" [src]="url"></div>
</div>

<form class="container" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div>
        <mat-form-field class="formUserMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="firstname"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.FIRSTNAME' | translate}}" name="firstname" required
                [ngClass]="{ 'is-invalid': f.firstname.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.firstname.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="userForm.controls.firstname.hasError('pattern')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.INVALID' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="formUserMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="lastname"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.LASTNAME' | translate}}" name="lastname" required
                [ngClass]="{ 'is-invalid': f.lastname.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.lastname.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="userForm.controls.lastname.hasError('pattern')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.INVALID' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div>
        <!-- <mat-form-field *ngIf="editUser.uid" class="formUserMan">
            <input matInput readonly [errorStateMatcher]="matcher" formControlName="email"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.EMAIL' | translate}}" name="email" required
                [ngClass]="{ 'is-invalid': f.email.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.email.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.REQUIRED' | translate}}</mat-error>
            <mat-error
                *ngIf="userForm.controls.email.hasError('email') && !userForm.controls.email.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.INVALID' | translate}}</mat-error>
        </mat-form-field> -->
        <mat-form-field *ngIf="!editUser.uid" class="formUserMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="email"
                placeholder="{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.EMAIL' | translate}}" name="email" required
                [ngClass]="{ 'is-invalid': f.email.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.email.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.REQUIRED' | translate}}</mat-error>
            <mat-error
                *ngIf="userForm.controls.email.hasError('email') && !userForm.controls.email.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.INVALID' | translate}}</mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="host" color="primary" class="hostCheck">
            {{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.HOST' | translate}}</mat-checkbox>
    </div>
    <div>
        <mat-form-field class="formUserMan">
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.ROL' | translate}}</mat-label>
            <mat-select formControlName="rol" multiple required>
                <mat-option *ngFor="let rol of roles" [value]="rol.uid" (onSelectionChange)="onSelectRol($event)">
                    {{(rol.name).replace("USER","EMPLOYEE").replace("MANAGEMENT","TENANT
                    MANAGER").replace("ADMIN","FACILITY MANAGER")}}</mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls.rol.hasError('required')">
                {{'MAPP-USERMANAGEMENT.EDITUSER.REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="formUserMan" *ngIf="viewTenant">
            <mat-label *ngIf='!showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.ACCESS' | translate}}</mat-label>
            <mat-label *ngIf='showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.ACCESS.RIGHTS.LIFT' | translate}}
            </mat-label>
                <mat-select required formControlName="access">
                    <mat-option name="access" mt-autofocus *ngFor="let access of accessList"
                        [value]="access.AccessRightId">
                        {{access.DisplayName}}</mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field class="formUserMan" *ngIf="viewTenant">
            <mat-label *ngIf='!showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.FLOOR' | translate}}</mat-label>
            <mat-label *ngIf='showAccessGroup'>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.FLOOR.HOME' | translate}}</mat-label>
            <mat-select formControlName="floor" required>
                <mat-option *ngFor="let floor of floorList" [value]="floor.HomeFloorId">
                    {{floor.DisplayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="formUserMan" *ngIf='showAccessGroup && viewTenant'>
            <mat-label>{{'MAPP-USERMANAGEMENT.EDITUSER.FIELD.ACCESS.GROUP' | translate}}</mat-label>
            <mat-select formControlName="accessGroup" required>
                <mat-option *ngFor="let accessG of accessGroupList" [value]="accessG.AccessGroupId">
                    {{accessG.DisplayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
<p></p>
<div mat-dialog-actions class="buttonUserMan" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-USERMANAGEMENT.EDITUSER.ACTION.CANCEL' | translate}}</button>
    <button class="buttonConfirm" [disabled]="!userForm?.valid" mat-raised-button color="primary" (click)="onSubmit()">
        {{'MAPP-USERMANAGEMENT.EDITUSER.ACTION.SAVE' | translate}}</button>
</div>