<div class="fondoGeneral">

   <form [formGroup]="formGroup">
      <div class="header">

         <mat-form-field class="searchAdress" appearance="outline">
            <input matInput (keyup)="applyGenericFilter($event.target.value)"
               placeholder="{{'MAPP-VMS-ADDRESSBOOK.FILTER' | translate}}" type="text">
            <mat-icon matSuffix class="ic_search">search</mat-icon>
         </mat-form-field>

         <mat-form-field *ngIf="tenants.length>1" class="selectTenant" appearance="outline">
            <mat-select placeholder="{{'MAPP-VMS-ADDRESSBOOK.TENANT' | translate}}" [formControl]="tenanSearchControl">
               <mat-option [value]="allTenants">
                  {{'MAPP-VMS-ADDRESSBOOK.ALL-TENANTS' | translate}}
               </mat-option>
               <mat-option *ngFor="let tenant of tenants" [value]="tenant.uid">
                  {{tenant.name}}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <!--
      <div class="orderDiv">
         <mat-label class="orderLabel">order by:</mat-label>


         <mat-chip-list class="order" aria-label="Order">
            <mat-chip color="primary" selected="true" (click)="sortingByName()">First Name</mat-chip>
            <mat-chip color="primary" (click)="sortingByLastName()">Last Name</mat-chip>
         </mat-chip-list>
      </div>
      -->
         <button class="expand" mat-button (click)="onExpand($event)" *ngIf="visitors_with_visits">
            {{allRowsExpanded ? ('MAPP-VMS-ADDRESSBOOK.COLLAPSE' | translate) : ('MAPP-VMS-ADDRESSBOOK.EXPAND' |
            translate)}}
         </button>
      </div>
   </form>

   <div class="table-container">
      <table mat-table [dataSource]="visitorsDataSource" multiTemplateDataRows matSort id='addressBookTable'>
         <ng-container matColumnDef="img">
            <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
            <mat-cell class="cellName" *matCellDef="let visitor">
               <mat-icon> person</mat-icon>
            </mat-cell>
         </ng-container>
         <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-ADDRESSBOOK.NAME' | translate}}
            </mat-header-cell>
            <mat-cell class="cellName" *matCellDef="let visitor">
               {{visitor.visitor.first_name}} {{visitor.visitor.last_name}}
            </mat-cell>
         </ng-container>
         <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-VMS-ADDRESSBOOK.COMPANY' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let visitor"> {{visitor.visitor.company}} </mat-cell>
         </ng-container>

         <ng-container matColumnDef="telphone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAPP-VMS-ADDRESSBOOK.PHONE' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let visitor"> {{visitor.visitor.phone}} </mat-cell>
         </ng-container>

         <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'MAPP-VMS-ADDRESSBOOK.EMAIL' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let visitor"> {{visitor.visitor.email}} </mat-cell>
         </ng-container>

         <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.ACTIONS' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let visitor">

               <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DETAIL-VISITOR' | translate}}" mat-icon-button
                  (click)="detailVisitor(visitor)">
                  <mat-icon class="icActions">account_box</mat-icon>
               </button>
               <button matTooltip="{{'MAPP-VMS-TOOLTIPS.EDIT-VISITOR' | translate}}" mat-icon-button
                  (click)="updateVisitor(visitor)" *ngIf="can_edit">
                  <mat-icon class="icActions">edit</mat-icon>
               </button>
               <button mat-icon-button matTooltip="{{'MAPP-VMS-TOOLTIPS.DELETE-VISITOR' | translate}}"
                  (click)="deleteVisitor(visitor)" *ngIf="can_delete">
                  <mat-icon class="icActions">delete</mat-icon>
               </button>

            </mat-cell>
         </ng-container>

         <br>
         <ng-container matColumnDef="expandedVisits">
            <td mat-cell *matCellDef="let visitor" [attr.colspan]="visitorDisplayedColumns.length">
               <div class="example-element-detail"
                  [@detailExpand]="visitor == expandedVisits && visitor.visitor.pending_visits || allRowsExpanded && visitor.visitor.pending_visits ? 'expanded' : 'collapsed'">
                  <div class="example-element-diagram">
                     {{'MAPP-VMS-ADDRESSBOOK.VISITS' | translate}}
                  </div>
                  <div class="example-element-description">
                     <mat-table [dataSource]="visitor.dataSource" id='segunda'>

                        <ng-container matColumnDef="host">
                           <mat-header-cell *matHeaderCellDef> Host </mat-header-cell>
                           <mat-cell *matCellDef="let visit"> {{visit.host.firstName}} {{visit.host.lastName}}
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.DATE' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit"> {{visit.date | date:'mediumDate':timezone}}
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.START-TIME' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit"> {{visit.start_time | date:'HH:mm':timezone}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.END-TIME' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit"> {{visit.end_time | date:'HH:mm':timezone}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="parking">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.PARKING' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit">
                              <mat-icon class="icParkingOk" matTooltip="{{'PARKING_STATUS_CODE.PARKING_C' | translate}}"
                                 *ngIf="visit.complimentary_parking_status==='C'">
                                 local_parking
                              </mat-icon>
                              <mat-icon class="icParkingKo"
                                 matTooltip="{{'PARKING_STATUS_CODE.'+ visit.complimentary_parking_message | translate}}"
                                 *ngIf="visit.complimentary_parking_status!=='C' && visit.complimentary_parking_status!=='P'
                                 && visit.complimentary_parking == true">
                                 local_parking
                              </mat-icon>
                              <mat-icon class="icParkingPending"
                                 matTooltip="{{'PARKING_STATUS_CODE.PARKING_P' | translate}}"
                                 *ngIf="visit.complimentary_parking_status==='P'"> local_parking
                              </mat-icon>
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="notifications">
                           <mat-header-cell *matHeaderCellDef>{{'MAPP-VMS-LOGBOOK.NOTIFICATION' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit">
                              <mat-icon class="icParkingOk" *ngIf="visit.notification_status==='N'"> check
                              </mat-icon>
                              <mat-icon class="icParkingOk" *ngIf="visit.notification_status!=='N'"> close
                              </mat-icon>
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="checkIn">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.CHECK-IN' | translate}}
                           </mat-header-cell>
                           <mat-cell class="cellCheck" *matCellDef="let visit"> {{visit.check_in_time |
                              date:'HH:mm':timezone}}
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="checkOut">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.CHECK-OUT' | translate}}
                           </mat-header-cell>
                           <mat-cell class="cellCheck" *matCellDef="let visit"> {{visit.check_out_time |
                              date:'HH:mm':timezone}}
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="in">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.IN' | translate}}
                           </mat-header-cell>
                           <mat-cell class="cellCheck" *matCellDef="let visit">
                              {{visit.first_in_time | date:'HH:mm':timezone}}
                           </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="out">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-LOGBOOK.OUT' | translate}}
                           </mat-header-cell>
                           <mat-cell class="cellCheck" *matCellDef="let visit">
                              {{visit.last_out_time | date:'HH:mm':timezone}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="duration">
                           <mat-header-cell *matHeaderCellDef> Duration </mat-header-cell>
                           <mat-cell *matCellDef="let visit"> -- </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                           <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-ADDRESSBOOK.ACTIONS' | translate}}
                           </mat-header-cell>
                           <mat-cell *matCellDef="let visit">
                              <button matTooltip="{{'MAPP-VMS-TOOLTIPS.NOTIFY' | translate}}" mat-icon-button
                                 (click)="notify(visit)"
                                 *ngIf="visit.notification_status!=='N' && (visit.first_in_time==null || visit.first_in_time<1)">
                                 <mat-icon class="icActionList">forward_to_inbox</mat-icon>
                              </button>
                              <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DETAIL-VISIT' | translate}}" mat-icon-button
                                 (click)="detailSingleVisit(visit)">
                                 <mat-icon class="icActions">account_box</mat-icon>
                              </button>
                              <button matTooltip="{{'MAPP-VMS-TOOLTIPS.EDIT-VISIT' | translate}}" mat-icon-button
                                 (click)="updateSingleVisit(visit)" *ngIf="!visit.past_visit && (visit.check_in_time==null || visit.check_in_time<1)
                                       && (visit.first_in_time==null || visit.first_in_time<1)">
                                 <mat-icon class="icActions">edit</mat-icon>
                              </button>
                              <button matTooltip="{{'MAPP-VMS-TOOLTIPS.DELETE-VISIT' | translate}}" mat-icon-button
                                 (click)="singleDelete(visit)" *ngIf="!visit.past_visit && (visit.check_in_time==null || visit.check_in_time<1) 
                                    && (visit.first_in_time==null || visit.first_in_time<1) 
                                    && (visit.check_out_time==null || visit.check_out_time<1)">
                                 <mat-icon class="icActions">delete</mat-icon>
                              </button>
                              <button mat-icon-button matTooltip="{{'MAPP-VMS-TOOLTIPS.CHECK_OUT-VISITOR' | translate}}"
                                 (click)="checkOut(visit.id)" *ngIf="can_check_in_out && !visit.past_visit && 
                                    (visit.check_out_time==null || visit.check_out_time<1) &&
                                    ((visit.check_in_time!=null && visit.check_in_time>0) || 
                                     (visit.first_in_time!=null && visit.first_in_time>0))">
                                 <mat-icon class="icActionList">exit_to_app</mat-icon>
                              </button>
                           </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedVisitColumns" class="element-colum">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedVisitColumns;" matRipple class="element-row">
                        </mat-row>
                     </mat-table>
                  </div>
               </div>
            </td>
         </ng-container>



         <mat-header-row class="headerRowPri" *matHeaderRowDef="visitorDisplayedColumns"></mat-header-row>
         <mat-row *matRowDef="let element; columns: visitorDisplayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedVisits === element"
            (click)="expandedVisits = (expandedVisits === element) ? null : element; allRowsExpanded = false">
         </mat-row>
         <tr mat-row *matRowDef="let row; columns: ['expandedVisits']" class="example-detail-row"></tr>
      </table>
   </div>
   <!--
   <div class="button-newVisit">
      <button mat-fab color="primary" aria-label="FAB new visit">
         <mat-icon>add </mat-icon>
      </button>
   </div>-->
   <div class="footer-container">
      <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
   </div>
</div>