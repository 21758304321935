import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QuizComponent } from './microapp-quiz.component';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatNativeDateModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
//import { MatMomentDateModule } from "@angular/material/core";
import { MatGridListModule } from '@angular/material/grid-list';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';
import { ChartsModule } from 'ng2-charts';
import { MatSortModule } from '@angular/material/sort';
import { TruncatePipe } from '../pipes/truncate.pipe'
import {MatTooltipModule} from '@angular/material/tooltip';
import { ListSurveyComponent } from './survey/list/microapp-quiz.list-survey.component';
import { ReportSurveyComponent } from './survey/report/microapp-quiz.report-survey.component';
import { EditSurveyComponent } from './survey/edit/microapp-quiz.edit-survey.component';
import { EditParticipantComponent } from './survey/edit/participant/microapp-quiz.edit-participant.component';
import { EditQuestionComponent } from './survey/edit/question/microapp-quiz.edit-question.component';
import { ListFeedbackComponent } from './feedback/list/microapp-quiz.list-feedback.component';
import { ShowFeedbackComponent } from './feedback/list/show/microapp-quiz.show-feedback.component';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';
import { MicroappQuizGenericComponent } from './survey/generic/microapp-quiz-generic.component';


@NgModule({
  entryComponents: [
    EmpusaQuestionDialog,
    EditParticipantComponent,
    EditQuestionComponent,
    ShowFeedbackComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    TranslateModule,
    ChartsModule,
    RouterModule.forChild([
      {
        path: '', component: QuizComponent
        // , children: [
        //   { path: '', redirectTo: "survey/list", pathMatch: 'full' },
        //   { path: 'survey/list', component: ListSurveyComponent },
        //   { path: 'survey/report/:id', component: ReportSurveyComponent },
        //   { path: 'survey/new', component: EditSurveyComponent, pathMatch: 'full' },
        //   { path: 'survey/edit/:id', component: EditSurveyComponent },
        //   { path: 'feedback/list', component: ListFeedbackComponent, pathMatch: 'full' }
        // ]
      },
    ])
  ],
  declarations: [
    QuizComponent,
    ListSurveyComponent,
    ReportSurveyComponent,
    EditSurveyComponent,
    MatSpinnerOverlayComponent,
    EditParticipantComponent,
    EditQuestionComponent,
    ListFeedbackComponent,
    ShowFeedbackComponent,
    TruncatePipe,
    MicroappQuizGenericComponent
  ],
  providers: [DatePipe,
    MatSnackBar
  ]
})
export class QuizModule {
  constructor(private translationLoader: TranslationLoaderService) {
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }
}