import { MicroappVmsHost } from './microapp-vms-host.model';
import { MicroappVmsVisit } from './microapp-vms-visit.model';
import { MatTableDataSource } from '@angular/material/table';
import { Tenant } from '@empusa/empusa-core';


export class MicroappVmsVisitGroup {
	tenant_id: string;
	host_id: string;
	host: MicroappVmsHost;
	date: Date;
	start_time: Date;
	end_time: Date;
	group_id: string;
	visits: MicroappVmsVisit[];
	dataSource: MatTableDataSource<MicroappVmsVisit>;
	tenant: Tenant;
	expired_date: boolean = true;
	read_only: boolean = false;
	owner: boolean;


	constructor(visit: MicroappVmsVisit) {
		this.tenant_id = visit.tenant_id;
		if (visit.tenant) {
			this.tenant = visit.tenant;
		}
		this.host_id = visit.host_id;
		this.host = visit.host;
		this.date = visit.date;
		this.start_time = visit.start_time;
		this.end_time = visit.end_time;
		this.group_id = visit.group_id;
		this.visits = [];
		let visit_copy = { ...visit };
		this.visits.push(visit_copy);
		this.dataSource = new MatTableDataSource<MicroappVmsVisit>(this.visits);
		var today = new Date();
		today.setHours(0, 0, 0, 0);
		this.expired_date = visit.past_visit;
		if (visit.first_in_time && visit.first_in_time != null) {
			this.read_only = true;
		};
		if (visit.check_in_time && visit.check_in_time != null) {
			this.read_only = true;
		};
		if (visit.check_out_time && visit.check_out_time != null) {
			this.read_only = true;
		};
		this.owner = visit.owner;
	}

	addVisit(visit: MicroappVmsVisit) {
		let visit_copy = { ...visit };
		if (visit.first_in_time && visit.first_in_time != null) {
			this.read_only = true;
		};
		if (visit.check_in_time && visit.check_in_time != null) {
			this.read_only = true;
		};
		if (visit.check_out_time && visit.check_out_time != null) {
			this.read_only = true;
		};
		if (this.owner) {
			this.owner = visit_copy.owner;
		}
		visit_copy.tenant = this.tenant;
		this.visits.push(visit_copy);
		this.dataSource = new MatTableDataSource<MicroappVmsVisit>(this.visits);
	}
}