<!--MODE BOOKING -->

<h2 *ngIf="!mode_confirm" class="titleNew">{{'MAPP-FBS-FACILITY-RESERVATION.BOOKING' | translate}} - {{resourceGroup.name}} -
  {{resourceGroup.location}}<button class="closeEditVist" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<div *ngIf="!mode_confirm" class="divAccesoryTotal">
  <div class="izquierdaAccesory">
    <div class="firstPart">
      <div *ngIf="!resourceImages || resourceImages.length < 1" class="accesoryImage"></div>
      <div *ngIf="resourceImages && resourceImages.length > 0" class="carouselContainer">
        <mat-carousel
          timings="250ms ease-in"
          [autoplay]="true"
          interval="5000"
          color="primary"
          maxWidth="auto"
          proportion="100"
          slides="5"
          [loop]="true"
          [hideArrows]="true"
          [hideIndicators]="false"
          [useKeyboard]="false"
          [useMouseWheel]="false"
          [maintainAspectRatio]="true"
          orientation="ltr">
          <mat-carousel-slide
            #matCarouselSlide
            *ngFor="let slide of resourceImages; let i = index"
            [image]="fbsConfig.BOOKED_IMAGES_URL + slide"
            [hideOverlay]="true"
          ></mat-carousel-slide>
        </mat-carousel>
      </div>
      <!-- <mat-label class="resourceName"> {{resourceGroup.location}} </mat-label> -->
      <p></p>
      <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-FACILITY-RESERVATION.CAPACITY' | translate}}:</b>
        {{resourceGroup.maxParticipants}}.
      </mat-label>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        <b> {{'MAPP-FBS-FACILITY-RESERVATION.UNITARY_COST' | translate}}</b>
        {{resourceGroup.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}.
      </mat-label>
    </div>
    <div>
      <p></p>
      <mat-label class="accesoryReservationLabel">
        {{resourceGroup.description}}
      </mat-label>
      <p></p>
      <div *ngIf="!reservation_mode_new">
        <mat-label class="accesoryReservationLabel"><b>Your resrvation:
            {{dateChecked.searchStartDate | date:'shortDate'}}
            {{dateChecked.searchStartDate | date:'HH:mm'}} -
            {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
        </mat-label>
      </div>
      <p></p>
      <div *ngIf="!reservation_mode_new">
        <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-FACILITY-RESERVATION.COST' | translate}}:</b>
          {{unitaryCost | currency :fbsConfig.CURRENCY:'symbol-narrow'}}</mat-label>
      </div>
    </div>

  </div>

  <div class="derechaAccesory">
    <mat-divider class="dividerAccesory" [vertical]="true"></mat-divider>

    <!-- <div>
        <empusa-microapp-fbs-check-availability #checkAvailability [theBookDate]="theBookDate"
          (search)="checkFacilitieAvailability($event)">
        </empusa-microapp-fbs-check-availability> -->
    <!-- </div> -->
    <form [formGroup]="reservationForm">
      <div>
        <mat-form-field class="formFieldAccesoryTitle">
          <mat-label>*{{'MAPP-FBS-FACILITY-RESERVATION.TITLE' | translate}}</mat-label>
          <input matInput [formControl]="title">
          <mat-error *ngIf="title.invalid && (title.dirty || title.touched)">
            {{'MAPP-FBS-FACILITY-RESERVATION.TITLE_NOT_VALID' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="formFieldAccesoryDescr">
          <mat-label>{{'MAPP-FBS-FACILITY-RESERVATION.DESCRIPTION' | translate}}</mat-label>
          <textarea matInput [formControl]="description"></textarea>
        </mat-form-field>
      </div>
    </form>
    <div *ngIf='reservation_mode_new == true'>
      <div>
        <mat-form-field class="formFieldAccesoryPicker">
          <mat-label>{{'MAPP-EPS.GENERIC.CALENDAR.DAY' | translate}}</mat-label>

          <input matInput [min]="todayDate" [max]="maxDay" [formControl]="selectedDay" [matDatepicker]="pickerEnd" >
          <mat-datepicker-toggle matSuffix [for]="pickerEnd" data-date-format="dd/mm/yyyy">
            <mat-icon class="matCalendarIcon"></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>

        </mat-form-field>
      </div>
      <div class="changeDate">
        <h5> {{'MAPP-FBS-FACILITY-RESERVATION.CHANGE_DATE' | translate}}</h5>
      </div>
    </div>
    <!-- <div *ngIf='reservation_mode_new == true'>
      <empusa-microapp-fbs-slots [facility]="resourceGroup"
      *ngIf="availability_checked || (dateChecked && !availability_checked)"
      (search)="checkInDayFacilitieAvailability($event)"></empusa-microapp-fbs-slots>
    </div> -->
  </div>
</div>
<div *ngIf="!mode_confirm" style="margin: 0 15px 0 15px;">
  <empusa-microapp-fbs-slider-facility-slots [facility]="resourceGroup"
                                          *ngIf="renderSliderComponent && reservation_mode_new && (availability_checked || resourceGroup.combined_slots || (dateChecked && !availability_checked))"
                                          (onSelectedPeriod)="checkInDayFacilitieAvailability($event)"
                                          [initialSliderValues]="initialSliderValues">
  </empusa-microapp-fbs-slider-facility-slots>
</div>
<div *ngIf="!mode_confirm  && reservation_mode_new" class="reservationGroupResolution">
  <ng-container>
    <div class="timeReservation" *ngIf="resourceGroup.canBookInSelectedPeriod && dateChecked && availability_checked && reservation_mode_new">
      <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-FACILITY-RESERVATION.AVAILABLE_TO_BOOK' | translate}}
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.
      </b></mat-label>
    </div>
    <p></p>
    <div *ngIf="(resourceGroup.canBookInSelectedPeriod && dateChecked && availability_checked)">
      <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-FACILITY-RESERVATION.COST' | translate}}:</b>
        {{unitaryCost | currency :fbsConfig.CURRENCY:'symbol-narrow'}}</mat-label>
    </div>
    <p></p>
  </ng-container>
  <ng-container>
    <div *ngIf="!resourceGroup.canBookInSelectedPeriod && dateChecked && reservation_mode_new">
      <mat-label class="accesoryReservationLabel"> <b>{{'MAPP-FBS-FACILITY-RESERVATION.NOT_POSSIBLE' | translate}}:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
        <span class="intervalHour">{{'MAPP-FBS-FACILITY-RESERVATION.NOT_AVAILABLE' | translate}}</span>
      </mat-label>
    </div>
  </ng-container>
  <ng-container>
    <div *ngIf="resourceGroup.canBookInSelectedPeriod && dateChecked && !availability_checked && reservation_mode_new">
      <mat-label class="accesoryReservationLabel"><b>{{'MAPP-FBS-FACILITY-RESERVATION.NOT_POSSIBLE' | translate}}:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
        <span class="intervalHour"> {{'MAPP-FBS-CHECK_AVAILABILITY.NOPERIOD' | translate}}</span>
      </mat-label>
    </div>
  </ng-container>


  <!-- <div *ngIf="!reservation_mode_new">
    <mat-label class="accesoryReservationLabel"><b>Your resrvation:
        {{dateChecked.searchStartDate | date:'shortDate'}}
        {{dateChecked.searchStartDate | date:'HH:mm'}} -
        {{dateChecked.searchEndDate | date:'HH:mm'}}.</b>
    </mat-label>
  </div> -->
</div>
<div mat-dialog-actions class="buttonFitting" align="end" *ngIf="!mode_confirm">
  <button mat-raised-button class="buttonClose" (click)="close()">
    {{'MAPP-FBS-FACILITY-RESERVATION.CANCEL' | translate}}</button>

  <button mat-raised-button color="primary" class="buttonConfirm" (click)="go_to_confirm()" *ngIf="reservation_mode_new"
    [disabled]="!resourceGroup.canBookInSelectedPeriod ||
                !availability_checked ||
                !reservationForm.touched ||
                !reservationForm.valid">
    {{'MAPP-FBS-FACILITY-RESERVATION.RESERVE' | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="go_to_confirm()" class="buttonConfirm"
    *ngIf="!reservation_mode_new" [disabled]="!reservationForm.valid || !reservationForm.touched">
    {{'MAPP-FBS-FACILITY-RESERVATION.UPDATE' | translate}}
  </button>
</div>

<!-- MODE CONFIRM-->

<h2 *ngIf="mode_confirm" class="titleNew">{{'MAPP-FBS-FACILITY-RESERVATION.CONFIRMBOOKING' | translate}}<button
    class="closeEditVist" mat-icon-button mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
  </button>
</h2>
<div *ngIf="mode_confirm" class="divAccesoryTotalConfirm">
  <div class="izquierdaAccesory">
    <div>
      <div class="firstPart">
        <mat-label class="resourceName"> {{resourceGroup.location}} </mat-label>
        <p></p>
        <mat-label class="accesoryReservationLabel"> <b> {{'MAPP-FBS-FACILITY-RESERVATION.CAPACITY' | translate}}:</b>
          {{resourceGroup.maxParticipants}}.
        </mat-label>
        <div *ngIf="!resourceImages || resourceImages.length < 1" class="accesoryImage"></div>
        <div *ngIf="resourceImages && resourceImages.length > 0" class="carouselContainer">
          <mat-carousel
            timings="250ms ease-in"
            [autoplay]="true"
            interval="5000"
            color="primary"
            maxWidth="auto"
            proportion="100"
            slides="5"
            [loop]="true"
            [hideArrows]="true"
            [hideIndicators]="false"
            [useKeyboard]="false"
            [useMouseWheel]="false"
            [maintainAspectRatio]="true"
            orientation="ltr">
            <mat-carousel-slide
              #matCarouselSlide
              *ngFor="let slide of resourceImages; let i = index"
              [image]="fbsConfig.BOOKED_IMAGES_URL + slide"
              [hideOverlay]="true"
            ></mat-carousel-slide>
          </mat-carousel>
        </div>
        <p></p>
        <mat-label class="accesoryReservationLabel">
          <b> {{'MAPP-FBS-FACILITY-RESERVATION.UNITARY_COST' | translate}}</b>
          {{resourceGroup.tarif.unit_price | currency :fbsConfig.CURRENCY:'symbol-narrow'}}.
        </mat-label>
      </div>
      <div>
        <p></p>
        <mat-label class="accesoryReservationLabel">
          {{resourceGroup.description}}
        </mat-label>
        <p></p>
      </div>
    </div>
  </div>
  <div class="derechaAccesory">
    <mat-divider class="dividerAccesoryConfirm" [vertical]="true"></mat-divider>
    <empusa-microapp-fbs-reservation-confirm [reservation]="reservation" [dates]="dateChecked" [cost]="unitaryCost">
    </empusa-microapp-fbs-reservation-confirm>
  </div>
</div>
<div mat-dialog-actions class="buttonFitting" align="end" *ngIf="mode_confirm">
  <button mat-raised-button color="primary" (click)="mode_confirm=false">
    {{'MAPP-FBS-FACILITY-RESERVATION.BACK' | translate}}</button>
  <button class="buttonConfirm" mat-raised-button color="primary" (click)="confirmBooking()">
    {{'MAPP-FBS-FACILITY-RESERVATION.CONFIRM' | translate}}
  </button>
</div>
<empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>


