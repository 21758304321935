import { filter } from 'rxjs/operators';
import { EpsTenantState } from './../../../lib/store/tenant/microapp-eps-tenant.reducer';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsTenatActions from '../../../lib/store/tenant/microapp-eps.tenant.actions';
import { Subscription } from 'rxjs';
import { AuthenticationService, EmpusaQuestionDialog } from '@empusa/empusa-core';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import { EpsTenantParkingRequest } from '../../../lib/model/reservation/microapp-eps.tenant-parking-request.model';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { EpsParkingRequest } from 'projects/microapp-eps/src/lib/model/reservation/microapp-eps.parking-request.model';
import { MicroappEpsValidationUtils } from '../../common/microapp-eps-validation-utils';

@Component({
  selector: 'empusa-microapp-eps-make-reservation-stepper',
  templateUrl: './make-reservation-stepper.component.html',
  styleUrls: ['./make-reservation-stepper.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MakeReservationStepperComponent implements OnInit, OnDestroy {

  @Input() mode: 'employee' | 'visitor' = 'employee';
  @ViewChild('stepper') private myStepper: MatStepper;


  storeSubscription: Subscription;

  usersSelectedCompleted: boolean = false;
  loading: boolean = true;
  parkingPassFilled: boolean = false;
  reservationDone: boolean = false
  parkingRequestReservationStatus: EpsTenantParkingRequest;
  someReservationRequestIsCorrectResult = false;
  allReservationConfirmationOKResult = false;
  someReservationConfirmationIsOKResult = false;
  stepNum: any;
  enabledNonEntitled : boolean;
  paymentDone: boolean = false;
  storeState: EpsTenantState;

  constructor(private translate: TranslateService,
    private auth: AuthenticationService,
    private epsServices: EpsServices,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.auth.loadCurrentUser().then(user => {
      const tenant = user.sites[0].tenants[0].uid;
      this.store.dispatch(epsTenatActions.epsGetMasterData({ tenant }))
    });

    this.storeSubscription = this.store.select('tenant').subscribe(data => {
      this.storeState = data;
      this.loading = data.loading;
      if (data.selectedUsers == undefined || data.selectedUsers.length == 0) {
        this.usersSelectedCompleted = false;
      } else {
        this.usersSelectedCompleted = true;
      };
      this.reservationDone = data.reservationDone;
      this.parkingRequestReservationStatus = data.parkingRequestReservationStatus;

      this.parkingPassFilled = data.parkingRequestPasses ? true : false;
      this.paymentDone = data.paymentDone;
      this.enabledNonEntitled = data.nonEntitledEnabledForThisTenant;
      this.someReservationRequestIsCorrectResult = this.someReservationRequestIsCorrect(data);
      this.allReservationConfirmationOKResult = this.allReservationConfirmationOK(data);
      this.someReservationConfirmationIsOKResult = this.someReservationConfirmationIsOK(data);

      if (data.paymentDone && this.allReservationConfirmationOKResult && this.allReservationConfirmationOKResult) {
        this.parkingPassFilled = false;
      }

    });

  }


  selectionChange(step) {
    this.stepNum = step.selectedIndex;
    if (step.selectedIndex == 2 && step.previouslySelectedIndex != 3) {
      this.store.dispatch(epsTenatActions.epsDoReservation());
    }
    if ((step.previouslySelectedIndex == 2 || step.previouslySelectedIndex == 3) &&
      (step.selectedIndex != 2 && step.selectedIndex != 3)
      && this.parkingRequestReservationStatus != undefined && this.parkingRequestReservationStatus.reservation_request.length > 0) {
      //TODO clear availability data from store
      this.loading = true;
      this.epsServices.delete_reservation(this.parkingRequestReservationStatus).subscribe(resp => {
        this.loading = false;
        this.store.dispatch(epsTenatActions.epsStoreParkingPasses({ parkingRequest: undefined }));
        this.store.dispatch(epsTenatActions.epsSetReservationDoneStatus({ reservationDone: false }));
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      })
    }

    if (step.selectedIndex == 3) {
      let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
        data: {
          title: this.translate.instant("MAPP-EPS.GENERICDIALOG.QUESTION"),
          question: this.translate.instant("MAPP-EPS.GENERICDIALOG.NOTICE"),
          icon: 'warning'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(epsTenatActions.epsConfirmAndPay());
        } else {
          this.myStepper.selectedIndex = step.selectedIndex - 1;
        }
      });
    }

    if (step.selectedIndex === 0 && this.storeState.paymentDone) {
      this.store.dispatch(epsTenatActions.epsTenantResetSteps());
    }

    if (step.selectedIndex === 1 && (this.storeState.paymentDone || this.storeState.reservationDone)) {
      this.store.dispatch(epsTenatActions.epsTenantResetAvailabilityAndPayment());
    }

  }

  someReservationRequestIsCorrect(storeState: EpsTenantState): boolean {
    return this.someReservationIsOK(storeState.parkingRequestReservationStatus);
  }

  allReservationConfirmationOK(storeState: EpsTenantState): boolean {
    if (storeState.parkingRequestConfirmationStatus && storeState.parkingRequestConfirmationStatus.reservation_request) {
      for (const item of storeState.parkingRequestConfirmationStatus.reservation_request) {
        if (!MicroappEpsValidationUtils.requestIsOK(item)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  someReservationConfirmationIsOK(storeState: EpsTenantState): boolean {
    return this.someReservationIsOK(storeState.parkingRequestConfirmationStatus);
  }

  someReservationIsOK(request: EpsTenantParkingRequest) {
    if (request && request.reservation_request) {
      return request.reservation_request.some(item => {
        return MicroappEpsValidationUtils.requestIsOK(item);
      });
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

}
