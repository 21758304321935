
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService, User, Tenant } from '@empusa/empusa-core';
import { Observable } from 'rxjs';
import { EpsConfig } from '../microapp-eps-cfg';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    /** the base url */

    constructor(private http: HttpClient,
        private epsConfig: EpsConfig,
        private auth: AuthenticationService) {
    }


    /**
     * Find all the users for a certain tenant
     */
    async findUsersByTenant(): Promise<User[]> {
        let user = await this.auth.loadCurrentUser();
        let id = encodeURIComponent(user.sites[0].tenants[0].uid);
        let httpget = this.http.get(`${this.epsConfig.USER_MANAGEMENT_CONTEXT}tenant/${id}/users`).pipe(
            map(data => (<any>data).users)
        );
        return <Promise<User[]>>httpget.toPromise();
    }


    /**
     * Retrieve all the tenants for the current site
     */
    async findAllTenants(): Promise<Tenant[]> {
        let currentSite = await this.auth.loadCurrentSite();
        let httpget = this.http.get(`${this.epsConfig.USER_MANAGEMENT_CONTEXT}sites/${currentSite.uid}`).pipe(
            map(data => (<any>data).tenants)
        );
        return httpget.toPromise();
    }
}
