import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { HvacModule } from '../pages/microapp-hvac.home.module';
import { HVACFakeBackend } from '../fake/fake.backend';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "hvac";
export const MICROAPP_ACCESS: string = "access";



@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappHvacModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = "MAPP-HVAC.DESCRIPTION"
  icon: string = "ac_unit";
  page = HvacModule;
  homeApp: boolean = false;
  path: string = "hvac";
  order: number = 9;
  fakebackend: EmpusaFakeBackend = new HVACFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }

}

