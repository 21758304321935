import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DTOCalendarEvent } from './dto/calendar-event.dto';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  //context = "http://localhost:5100";
  context = this.environment.urlbase + 'calendar/services';

  constructor(private http: HttpClient, @Inject('environment') private environment) { }

  getAllEvents(){
    return this.http.get<DTOCalendarEvent[]>(`${this.context}/calendar/events`);
  }

  postEvent(event: DTOCalendarEvent){
    return this.http.post<DTOCalendarEvent[]>(`${this.context}/calendar/events/`, event);
  }

  deleteEvent(id: number){
    return this.http.delete<DTOCalendarEvent[]>(`${this.context}/calendar/events/${id}`);
  }


  getAllEventTypes(){
    return this.http.get<any[]>(`${this.context}/calendar/event-types`);
  }
}
