<mat-drawer-container class="drawer">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field class="generic-form-field">
        <mat-label> {{'MAPP-EPS.CHARGE_TYPES_ASSOCIATION.SPOT_TYPE' | translate}} </mat-label>
        <mat-select formControlName="parkingSpotTypeControl" (selectionChange)="spotTypeChange()">
          <mat-option *ngFor="let sType of spotTypes" [value]="sType.spot_type_id">
            {{sType.spot_short_desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="generic-form-field">
        <mat-label>{{'MAPP-EPS.TENANT.PASS_TYPE' | translate}}</mat-label>
        <mat-select formControlName="reservationTypeControl" (selectionChange)="reservationTypeChange()">
          <mat-option *ngFor="let rType of reservationTypes" [value]="rType.reservation_type_id">
            {{rType.reservation_types_desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- {{selectedTarificationType}} -->
      <mat-form-field *ngIf="selectedTarificationType && selectedTarificationType=='Daily'" class="generic-form-field">
        <mat-label>  {{'MAPP-EPS.GENERIC.FORM_LABELS.CHOSE_DATE' | translate}} </mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startDateControl" [min]="minSelectableDay">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="selectedTarificationType && selectedTarificationType=='Monthly'"
        class="generic-form-field">
        <mat-label> {{'MAPP-EPS.GENERIC.FORM_LABELS.CHOSE_MONTH' | translate}} </mat-label>
        <input matInput [matDatepicker]="dp" formControlName="startDateControlMonth" [min]="minSelectableMonth">
        <mat-datepicker-toggle matSuffix [for]="dp" data-date-format="MM/yyyy">
        </mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="closeDatePicker($event, dp)"
          (daySelected)="closeDatePicker($event, dp)" panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>

      <mat-checkbox *ngIf="selectedTarificationType && selectedTarificationType=='Monthly'" class="generic-form-field"
        formControlName="recurringMonthly" [indeterminate]="false" labelPosition="before">
        {{'MAPP-EPS.GENERIC.FORM_LABELS.AUTORENEW' | translate}}
      </mat-checkbox>
    </div>

    <div *ngIf="activateUsers && parkingRequests">
      <mat-divider></mat-divider>
      <div *ngFor="let one_parking_request of parkingRequests.reservation_request">
        <div class="vehicle-owner-name-container">
          {{one_parking_request.vehicle_owner_name}}
        </div>
        <mat-form-field class="generic-form-field">
          <mat-label> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLE_TYPE' | translate}} </mat-label>
          <mat-select formControlName="{{one_parking_request.vehicle_owner_id}}vehicleType">
            <mat-option *ngFor="let aQuote of availableQuote" [value]="aQuote">
              {{aQuote.vehicle.vehicle_short_desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="generic-form-field" >
          <mat-label> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.IU' | translate}} </mat-label>
          <input matInput formControlName="{{one_parking_request.vehicle_owner_id}}iu"
          mask="0000000009"
            [minlength]="uiFieldLength(one_parking_request.vehicle_owner_id + 'vehicleType')"
            [maxlength]="uiFieldLength(one_parking_request.vehicle_owner_id + 'vehicleType')">
          <mat-error>  {{'MAPP-EPS.TENANT.IU_LENGTH_TITLE' | translate}} </mat-error>
        </mat-form-field>
        <mat-form-field class="generic-form-field">
          <mat-label> {{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLENO' | translate}}  </mat-label>
          <input matInput formControlName="{{one_parking_request.vehicle_owner_id}}licensePlate"
            [minlength]="epsConfig.SIZE_LICENSE_PLATE_MIN" [maxLength]="epsConfig.SIZE_LICENSE_PLATE_MAX">
          <mat-error> {{'MAPP-EPS.TENANT.PLATE_FORMAT_ERROR' | translate}} </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-drawer-container>
