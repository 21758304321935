import { Injectable } from '@angular/core';
import { EpsVehicleType } from '../microapp-eps.vehicle-type.model';
import { Adapter } from '../microapp-eps.adapter';
import { EpsReservationType } from './microapp-eps.reservation-type.model';
import { EpsSpotType } from '../microapp-eps.spot-type.model';
import { Reservation } from './microapp-eps.reservation.model';


@Injectable({
  providedIn: "root",
})
export class EpsReservationAdapter implements Adapter<Reservation> {

  constructor() { };


  adapt(item: any): Reservation {
    let reservation = new Reservation;

    let vehicle_type = new EpsVehicleType;
    vehicle_type.vehicle_type_id = item.vehicle_type_id;
    vehicle_type.vehicle_short_desc = item.vehicle_short_desc;
    vehicle_type.vehicle_icon = item.vehicle_icon;
    vehicle_type.vehicle_desc = item.vehicle_desc;
    vehicle_type.vehicle_code = item.vehicle_code;
    reservation.vehicle_type = vehicle_type

    let spot_type = new EpsSpotType;
    spot_type.spot_type_id = item.spot_type_id;
    spot_type.spot_short_desc = item.spot_short_desc;
    spot_type.spot_desc = item.spot_desc;
    spot_type.spot_type_code = item.pot_type_code;
    reservation.spot_type = spot_type;

    let reservation_type = new EpsReservationType;
    reservation_type.reservation_type_id = item.reservation_type_id;
    reservation_type.reservation_types_short_desc = item.reservation_types_short_desc;
    reservation_type.reservation_types_desc = item.reservation_types_desc;
    reservation_type.reservation_types_code = item.reservation_types_code;
    reservation_type.sub_type_of_type_id = item.sub_type_of_type_id;
    reservation_type.reservation_tariffication_type = item.reservation_tariffication_type;
    reservation.reservation_type = reservation_type;

    reservation.id = item.id;
    reservation.tenant_id = item.tenant_id;
    reservation.from_date = new Date(item.from_date);
    reservation.to_date = new Date(item.to_date);
    reservation.license_plate = item.license_plate;
    reservation.vehicle_owner_id = item.vehicle_owner_id;
    reservation.vehicle_owner_name = item.vehicle_owner_name;
    reservation.vehicle_iu = item.vehicle_iu;
    reservation.price = item.price;
    reservation.units = item.units;
    reservation.charge_type = item.charge_type;
    reservation.charge_id = item.charge_id;
    reservation.status = item.status;
    reservation.charge_to = item.charge_to;
    reservation.create_date = this.createUTCDate(new Date(item.create_date));
    reservation.status_code = item.status_code
    reservation.reservation_tariffication_type = item.reservation_tariffication_type;
    reservation.user_reservation_type = item.user_reservation_type;
    reservation.source_system = item.source_system;
    reservation.is_recurrent = item.is_recurrent;
    reservation.recurrent_status_code = item.recurrent_status_code;
    reservation.recurrent_child = item.recurrent_child;
    reservation.recurrent_done = item.recurrent_done;
    reservation.recurrent_attempts = item.recurrent_attempts;
    reservation.booked_by = item.booked_by;

    return reservation;
  }


  createUTCDate(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
}
