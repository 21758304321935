import { Adapter } from './microapp-vms-adapter';
import { MicroappVmsVisitLog } from './microapp-vms-visit-log.model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { VmsConfig } from '../microapp-vms-config';


export class MicroappVmsVisitLogAdapter  implements Adapter<MicroappVmsVisitLog>  {

    constructor(private translate: TranslateService,
        private vmsConfig: VmsConfig,
        private datepipe: DatePipe) {
    }

    adapt(item: any): MicroappVmsVisitLog {
        let visitLogAdapted = new MicroappVmsVisitLog;

        visitLogAdapted.user = item.user;
        visitLogAdapted.date = this.formatDateTime(item.date);
        visitLogAdapted.result_check_mask = item.result_check_mask;
        visitLogAdapted.result_check_passive_liveness = item.result_check_passive_liveness;
        visitLogAdapted.result_check_temperature = item.result_check_temperature;
        let txt = this.translate.instant("MAPP-VMS-LOG." + item.action);
        switch (item.action) {
            case "VISIT_SCHEDULE": {
                var before = item.before.split(":");
                var after = item.after.split(":");
                let scheduleChanges = ", from " +  this.datepipe.transform(this.formatDateTime(+before[0]),'short',this.vmsConfig.MASTER_CONFIG.offset);
                scheduleChanges = scheduleChanges + " - " + this.datepipe.transform(this.formatDateTime(+before[1]),'shortTime',this.vmsConfig.MASTER_CONFIG.offset);
                scheduleChanges = scheduleChanges +" to " +  this.datepipe.transform(this.formatDateTime(+after[0]),'short',this.vmsConfig.MASTER_CONFIG.offset);
                scheduleChanges = scheduleChanges + " - " + this.datepipe.transform(this.formatDateTime(+after[1]),'shortTime',this.vmsConfig.MASTER_CONFIG.offset);
                visitLogAdapted.logText = txt + scheduleChanges;
                break;
            }
            default: {
                visitLogAdapted.logText = txt;
                break;
            }
        }

        return visitLogAdapted;
    }

    formatDateTime(date: number): Date {
        if (date == null) return null;
        return new Date(date * 1000);
    }
}
