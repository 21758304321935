import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MicroappFbsAccessoryGroupSlotsModel } from '../../../../../client/dto/Microapp-fbs-accessory-group-slots';
import { MicroappFbsResourceSlotModel } from '../../../../../client/dto/faciliti-slots';
import { MicroappFbsResourceGroup } from '../../../../../client/dto/microapp-fbs-resource-group';
import { SearchDates } from '../../../../../client/microapp-fbs.interfaces';
import {cloneDeep, last} from "lodash";
import {MatSelectionList} from "@angular/material/list";

@Component({
  selector: 'empusa-microapp-fbs-accessory-slots',
  templateUrl: './microapp-fbs-accessory-slots.component.html',
  styleUrls: ['./microapp-fbs-accessory-slots.component.css']
})
export class MicroappFbsAccessorySlotsComponent implements OnInit {
  @ViewChild('slotList') slotList: MatSelectionList;

  displayedColumns: string[] = ['time', 'status'];

  selected_period: SearchDates = new SearchDates;
  @Output() search: EventEmitter<SearchDates> = new EventEmitter();

  slots_to_show: MicroappFbsAccessoryGroupSlotsModel[];
  selected;
  constructor() { }

  @Input()
  set resource_group(resource_group: MicroappFbsResourceGroup) {
    if (!resource_group.slots) {
      return;
    }
    this.slots_to_show = [];
    let lastSlot: MicroappFbsAccessoryGroupSlotsModel;

    const orderedSlots = cloneDeep(resource_group.combined_slots).sort((slot1, slot2) => {
      return slot1.startDateTime.getTime() - slot2.startDateTime.getTime();
    });

    orderedSlots.forEach(slot => {
      if (slot && slot.isReservable){
        this.slots_to_show.push(slot);
      }
      if (slot) {
        lastSlot = slot;
      }
    });
    //Adding last slot to allow select last hour

    const lastSlotClone = cloneDeep(lastSlot);
    lastSlotClone.isReservable = last(this.slots_to_show).isReservable;
    lastSlotClone.slots = last(this.slots_to_show).slots;

    this.slots_to_show.push(lastSlotClone);


  }

  ngOnInit(): void {

  }

  onChangeSlot(value) {

    if (value.length > 1) {
      value.forEach(element => {
        if (!this.selected_period.searchStartDate || this.selected_period.searchEndDate) {
           this.selected_period.searchStartDate = element.value.startDateTime;
          this.selected_period.searchEndDate = undefined;
        } else {
          this.selected_period.searchEndDate = element.value.startDateTime;
          if (this.selected_period.searchEndDate < this.selected_period.searchStartDate) {
            const temp = this.selected_period.searchEndDate;
            this.selected_period.searchEndDate = this.selected_period.searchStartDate;
            this.selected_period.searchStartDate = temp;
          };
        }
      });
     if (this.selected_period.searchEndDate != undefined) {
        this.slotList.deselectAll();
        this.search.emit({ ...this.selected_period });
      }
    }
  }
}
