import { EpsChargeType } from './../../../lib/model/charges_and_tariffs/microapp-eps.charge-type.model';
import { EpsReservationType } from './../../../lib/model/reservation/microapp-eps.reservation-type.model';
import { EpsVehicleType } from './../../../lib/model/microapp-eps.vehicle-type.model';
import { EpsSpotType } from './../../../lib/model/microapp-eps.spot-type.model';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as microappEpsAppActions from '../../../lib/store/microapp-eps.actions';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EpsAssignedChargeType } from '../../../lib/model/charges_and_tariffs/microapp-eps.assigned-charge-types.model';
import { ChargeTypeFormComponent } from './charge-type-form/charge-type-form.component';
import { EpsState } from '../../../lib//store/microapp-eps.reducer';
import { EmpusaQuestionDialog } from '@empusa/empusa-core';
import { ChargeTypeAssociationUtils } from '../../../lib/utils/charge-type-association-utils';

class TableFilter {
  spotTypes: EpsSpotType[] = [];
  vehicleTypes: EpsVehicleType[] = [];
  reservationTypes: EpsReservationType[] = [];
  chargesAndTariffs: EpsChargeType[] = [];
}

@Component({
  selector: 'empusa-microapp-eps-charge-list',
  templateUrl: './microapp-eps-charge-list.component.html',
  styleUrls: ['./microapp-eps-charge-list.component.css']
})
export class MicroappEpsChargeListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.epsState.assignedCharges) {
      this.assignedChargesDataSource.paginator = mp;
    }
  }
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['vehicle_short_desc', 'spot_short_desc', 'reservation_types_short_desc', 'charge_type', 'actions'];
  storeSubscription: Subscription;
  loading: boolean = false;
  assignedChargesDataSource: MatTableDataSource<EpsAssignedChargeType>;
  notExistingAssociations: EpsAssignedChargeType[] = [];
  rawDataTableList: EpsAssignedChargeType[];
  epsState: EpsState;

  filters: TableFilter = new TableFilter();

  constructor(private epsServices: EpsServices,
    public dialog: MatDialog,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
  ) {
    this.assignedChargesDataSource = new MatTableDataSource();

    // this.assignedChargesDataSource.sortData = this.sortDataTableObject;
  }
  ngAfterViewInit(): void {
    this.assignedChargesDataSource.sort = this.sort;
    this.assignedChargesDataSource.sortData = this.sortDataTableObject;
  }

  ngOnInit(): void {
    this.storeSubscription = this.store.select('eps').subscribe(data => {
      this.loading = data.loading;
      this.epsState = data;
      let assignChargeList = [];
      if (data && data.assignedCharges) {
        data.assignedCharges.forEach(charge => {
          let assignChargeType = new EpsAssignedChargeType();
          const exist = data.chargesAndTariffs.find(rt => rt.application_charge_code == charge.charge_type);
          assignChargeType.description_charge = exist.description;
          assignChargeType.charge_type = charge.charge_type;
          assignChargeType.reservation_type = charge.reservation_type;
          assignChargeType.spot_type = charge.spot_type;
          assignChargeType.vehicle_spottype_reservationtype_id = charge.vehicle_spottype_reservationtype_id;
          assignChargeType.vehicle_type = charge.vehicle_type;
          assignChargeList.push(assignChargeType);
        })
        this.rawDataTableList = assignChargeList.sort(this.compareCharges);
        this.applyFilters();
        this.notExistingAssociations = ChargeTypeAssociationUtils.getNotExistingCombinationsForAssociations(data);
      }
    });
  }


  compareCharges(a: EpsAssignedChargeType, b: EpsAssignedChargeType) {
    let aName = a.vehicle_type.vehicle_type_id;
    let bName = b.vehicle_type.vehicle_type_id;

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }



  sortDataTableObject(data: EpsAssignedChargeType[], sort: MatSort): EpsAssignedChargeType[] {
    if (!data) {
      return [];
    }
    if (sort.active === 'spot_short_desc' && sort.direction === "asc") {
      return data.sort((a, b) => {
        return a.spot_type.spot_short_desc.localeCompare(b.spot_type.spot_short_desc)
      })
    } else if (sort.active === 'reservation_types_short_desc' && sort.direction === "asc") {
      return data.sort((a, b) => {
        return a.reservation_type.reservation_types_desc.localeCompare(b.reservation_type.reservation_types_desc)
      })
    } else if (sort.active === 'vehicle_short_desc' && sort.direction === "asc") {
      return data.sort((a, b) => {
        return a.vehicle_type.vehicle_short_desc.localeCompare(b.vehicle_type.vehicle_short_desc)
      })
    } else if (sort.active === 'charge_type' && sort.direction === "asc") {
      return data.sort((a, b) => {
        return a.charge_type.localeCompare(b.charge_type)
      })
    } else if (sort.active === 'spot_short_desc' && sort.direction === "desc") {
      return data.sort((a, b) => {
        return b.spot_type.spot_short_desc.localeCompare(a.spot_type.spot_short_desc)
      })
    } else if (sort.active === 'reservation_types_short_desc' && sort.direction === "desc") {
      return data.sort((a, b) => {
        return b.reservation_type.reservation_types_desc.localeCompare(a.reservation_type.reservation_types_desc)
      })
    } else if (sort.active === 'vehicle_short_desc' && sort.direction === "desc") {
      return data.sort((a, b) => {
        return b.vehicle_type.vehicle_short_desc.localeCompare(a.vehicle_type.vehicle_short_desc)
      })
    } else if (sort.active === 'charge_type' && sort.direction === "desc") {
      return data.sort((a, b) => {
        return b.charge_type.localeCompare(a.charge_type)
      })
    } else if (sort.active === 'spot_short_desc' && sort.direction === "") {
      return data
    } else if (sort.active === 'reservation_types_short_desc' && sort.direction === "") {
      return data
    } else if (sort.active === 'vehicle_short_desc' && sort.direction === "") {
      return data
    } else if (sort.active === 'charge_type' && sort.direction === "") {
      return data
    }
    return data;
  }

  deleteAssociation(id: number) {
    let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
      width: '500px',
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.epsServices.delete_assigned_charges(id).subscribe((assignedCharges) => {
          this.store.dispatch(microappEpsAppActions.epsStoreAssignedCharges({ assignedCharges }))
        });
      }
    })
  }

  editAssociation(association: EpsAssignedChargeType) {
    const dialogAssign = this.dialog.open(ChargeTypeFormComponent, {
      width: '500px',
      maxHeight: '900px',
      data: association
    });
    dialogAssign.afterClosed().subscribe(result => {
      this.store.dispatch(microappEpsAppActions.epsGetTenantTariffs());
    });
  }

  applyFilters() {
    this.assignedChargesDataSource.data = this.rawDataTableList.filter((item) => {
      return (!this.filters.vehicleTypes.length || this.filters.vehicleTypes.some(types => item.vehicle_type.vehicle_type_id === types.vehicle_type_id)) &&
        (!this.filters.spotTypes.length || this.filters.spotTypes.some(types => item.spot_type.spot_type_id === types.spot_type_id)) &&
        (!this.filters.reservationTypes.length || this.filters.reservationTypes.some(types => item.reservation_type.reservation_type_id === types.reservation_type_id)) &&
        (!this.filters.chargesAndTariffs.length || this.filters.chargesAndTariffs.some(types => item.charge_type === types.application_charge_code)) &&
        (!this.filters.chargesAndTariffs.length || this.filters.chargesAndTariffs.some(types => item.description_charge === types.description));
    });
  }

  cleanFilters() {
    this.filters = new TableFilter();
    this.applyFilters();
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  createNewChargeType() {
    const dialogAssign = this.dialog.open(ChargeTypeFormComponent, {
      width: '500px',
      maxHeight: '900px',
      data: undefined
    });
  }

}

export class EpsAssignedChargeTypeTableList {
  vehicle_type: string;
  spot_type: string;
  reservation_type: string;
  charge_type: string;
  vehicle_spottype_reservationtype_id: number;
  description_charge: string;
}
