import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as addresBookActions from '../../../lib/address-book/store/microapp-vms-address-book.actions';
import { Store, select } from '@ngrx/store';
import { selectAddressBookLoading, selectAddressBookError } from '../../../lib/address-book/store/microapp-vms-address-book-selectors';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'empusa-microapp-vms-delete-visitor',
  templateUrl: './microapp-vms-delete-visitor.component.html',
  styleUrls: ['./microapp-vms-delete-visitor.component.css']
})
export class MicroappVmsDeleteVisitorComponent implements OnInit {
  visitor_id: number;
  loading$: Subscription;
  loading: boolean;

  constructor(
    private store: Store<vmsReducer.MicroappVmsStates>,
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visitor_id = data;
  }


  ngOnInit(): void {
  }


  deleteVisitor() {
    this.store.dispatch(addresBookActions.deleteVisitor({visitor_id: this.visitor_id}));

    this.loading$ = this.store.select(selectAddressBookLoading).subscribe(loading => {
      this.loading = loading;
    });

    let saving$ = this.store.pipe(select(selectAddressBookLoading)).subscribe(isLoading => {
      if (!isLoading) {
        this.store.pipe(select(selectAddressBookError)).subscribe(hayError => {
          this.dialogRef.close(hayError);
        });
        saving$.unsubscribe();
      }
    });

  }

  ngOnDestroy(): void {
    if (this.loading$)
      this.loading$.unsubscribe();
  }

}
