import { EpsTenantState } from './../../../lib/store/tenant/microapp-eps-tenant.reducer';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthenticationService, EmpusaQuestionDialog, Tenant, User } from "@empusa/empusa-core";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../../lib/services/user.service";
import * as moment_ from 'moment';
import { DatePipe } from "@angular/common";
import { ReservationsTenantFilter } from "./microapp-eps.tenant-reservation-eps.componentfilter";
import { MatDialog } from "@angular/material/dialog";
import { EpsServices } from "../../../lib/services/microapp-eps.service";
import { Reservation } from "../../../lib/model/reservation/microapp-eps.reservation.model";
import { select, Store } from "@ngrx/store";
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import { selectVehicleTypes } from "../../../lib/store/microapp-eps-selectors";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ThrowStmt } from "@angular/compiler/src/output/output_ast";
import { deleteTimeZone } from "../../common/microapp-eps-date.util";
import * as epsTenatActions from '../../../lib/store/tenant/microapp-eps.tenant.actions';
import { DialogStatusHelpComponent } from '../../common/dialog-status-help/dialog-status-help.component';
import { forkJoin } from 'rxjs';
import { CountLots } from '../generics/reservation/microapp-eps.reservation-table.component';

@Component({
  selector: 'microapp-eps-reservationsTenant-eps-component',
  templateUrl: './microapp-eps.tenant-reservation-eps.component.html',
  styleUrls: ['./microapp-eps.tenant-reservation-eps.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class ReservationsTenantComponent implements OnInit {
  displayedColumns: string[] = ['vehicle_owner_name', 'vehicle_iu', 'license_plate', 'ReservationType', 'from_date', 'to_date', 'status', 'actions'];
  dataSource = new MatTableDataSource([]);
  currentUser: User;
  loading: boolean = true;
  tenant: string;
  filterGroup: FormGroup;
  userFilter = new ReservationsTenantFilter();
  filterValues = {};
  currentDateTime;
  isAdmin: boolean;
  isNotAdmin: boolean = false;
  public tenantForm: FormGroup;
  tenantsToSelect: Tenant[] = [];
  allRowsExpanded: boolean = false;
  from: Date;
  to: Date;
  dataToTableReservation;
  filter;
  epsState: EpsTenantState;
  maxDateRange: Date;
  minDateRange: Date;
  myDate = new Date();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  chips = [];
  expandedElement: Reservation;
  countLotsInterface: CountLots = {
    countEntitledLotsAssigned: 0,
    countEntitledReservations: 0,
    countNonEntitledLotsAssigned: 0,
    countNonEntitledReservations: 0
  };
  countEntitledLotsAssigned: number;
  countNonEntitledLotsAssigned: number;
  downloadCsvRequestBoolean: boolean = false;
  constructor(private snackBar: MatSnackBar,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    private epsServices: EpsServices,
    private datepipe: DatePipe, private dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private userService: UserService) {
    this.store.dispatch(epsTenatActions.epsGetMasterConfig());
    this.tenantForm = this.formBuilder.group({
      tenants: ['', Validators.required],
      from: [''],
      to: ['']
    });
    auth.loadCurrentUser().then(user => {
      this.currentUser = user;
      this.isAdmin = this.auth.hasCurrentUserRole('ADMIN');
      if (this.isAdmin) {
        this.tenant = "";
        let allTenants = new Tenant();
        allTenants.name = 'ALL TENANTS'
        allTenants.uid = 'ALL TENANTS'
        this.userService.findAllTenants().then(resp => {
          this.tenantsToSelect.push(allTenants)
          resp.forEach(element => {
            this.tenantsToSelect.push(element);
          });
          this.tenantsToSelect.sort(this.compareTenants);
        });
      } else {
        this.isNotAdmin = true;
        this.tenant = this.currentUser.sites[0].tenants[0].uid;
        this.f.tenants.setValue(this.tenant)
      }
      this.tenant = this.currentUser.sites[0].tenants[0].uid;
      this.currentDateTime = new Date().getTime()
      this.from = new Date;
      this.to = undefined;
      this.viewReservation();
    })
  }

  compareTenants(a: Tenant, b: Tenant) {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    let comparison = 0;
    if (aName > bName) {
      comparison = 1;
    } else if (aName < bName) {
      comparison = -1;
    }
    return comparison;
  }

  ngOnInit() {
    this.filterGroup = this.buildFilter();
    this.store.pipe(select(selectVehicleTypes)).subscribe(v_types => {
      this.chips = [];
      v_types.forEach(vt => {
        const one_chip = { name: vt.vehicle_code, icon: vt.vehicle_icon };
        this.chips.push(one_chip)
      });
    })
    this.store.pipe(select('tenant')).subscribe(data => {
      this.epsState = data;
      if (data.tenantQuotas) {

        this.countEntitledLotsAssigned = 0;
        this.countNonEntitledLotsAssigned = 0;
        data.tenantQuotas.quota.forEach(tentantQuouta => {
          if (tentantQuouta.spot_type_id == 1) {
            this.countEntitledLotsAssigned += tentantQuouta.quota;
          } else {
            this.countNonEntitledLotsAssigned += tentantQuouta.quota;
          }
        })
      }
      if (data.parkingVehicleTypes) {
        this.chips = [];
        data.parkingVehicleTypes.forEach(vt => {
          const one_chip = { name: vt.vehicle_code, icon: vt.vehicle_icon };
          this.chips.push(one_chip)
        });
      }
    })
  }

  reload() {
    this.viewReservation();
  }

  viewReservation() {
    this.loading = true;
    this.f.tenants.setValue(this.tenant);
    const tenantListService = this.userService.findAllTenants();
    const tenantReservationsService = this.epsServices.get_tenant_reservations(this.tenant, this.from, this.to);
    forkJoin([tenantListService, tenantReservationsService]).subscribe(results => {
      this.loading = false;
      const tenants_list: Tenant[] = results[0];
      const tenant_reservation_List: Reservation[] = results[1] as Reservation[];
      tenant_reservation_List.forEach(tenant_reservation => {
        tenant_reservation.tenant_name = tenants_list.find(tenant => tenant.uid == tenant_reservation.tenant_id).name;
      })
      this.createDataTable(tenant_reservation_List);
    })
  }

  private createDataTable(data: Reservation[]) {
    this.dataToTableReservation = data;
    this.loading = false;
  }

  get f(): any { return this.tenantForm.controls; }


  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl(""),
      EFFECTIVE: new FormControl(""),
      EXPIRE: new FormControl(""),
      // CREATE: new FormControl(""),
      VEHICLETYPE: new FormControl(""),
      IU: new FormControl(""),
      STATUS: new FormControl("")
    })
  }


  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  applyVehicleTypeFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.vehicleType = filterValue;
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  applyExpiredDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.to_date = theDate;
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  applyStatusFilter(filterValue: string){
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.status= filterValue;
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  applyEffectiveDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.from_date = theDate;
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  resetFilters() {
    // this.userFilter.create_date = undefined;
    this.userFilter.from_date = undefined;
    this.userFilter.to_date = undefined;
    this.userFilter.vehicleType = undefined;
    this.userFilter.status = undefined;
    // this.userFilter.iu = undefined;
    this.userFilter.general = undefined;
    this.filterGroup.reset();
    // this.dataSource.filter = JSON.stringify(this.userFilter);
    this.filter = JSON.stringify(this.userFilter);
  }

  tenantSelected() {
    if (this.isAdmin) {
      this.tenant = this.f.tenants.value;
    }
    this.from = this.f.from.value;
    this.from = deleteTimeZone(this.from)
    this.to = this.f.to.value;
    this.to = deleteTimeZone(this.to)
    this.viewReservation();
  }

  onModalSuccessfullyOperation() {
    this.viewReservation()
  }

  onFromDateChange(event) {
    const fromDate: Date = event.value;
    const toDate = new Date(fromDate);
    toDate.setMonth(toDate.getMonth() + this.epsState.masterConfig.max_search_range);
    this.minDateRange = fromDate;
    this.maxDateRange = toDate;
  }

  helpStatus() {
    let dialogRef = this.dialog.open(DialogStatusHelpComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  countLots(event: CountLots) {
    this.countLotsInterface = {
      countEntitledLotsAssigned: this.countEntitledLotsAssigned,
      countEntitledReservations: event.countEntitledReservations,
      countNonEntitledLotsAssigned: this.countNonEntitledLotsAssigned,
      countNonEntitledReservations: event.countNonEntitledReservations
    }
    // }
  }

  exportTable(exporter): void {
    exporter.exportTable('csv', {
      fileName: 'EPS-RESERVATION-' + this.datepipe.transform(this.myDate, 'dd-MM-yy'),
    })
    this.downloadCsvRequestBoolean = false;
  }

  downloadCsvRequest() {
    this.downloadCsvRequestBoolean = true;
  }
  statusChips = [
    {name: "PAYPAL_PAYMENT_PENDING" , map: "Paypal payment pending"  },
    {name: "PAYPAL_PAID" , map: "Paypal payment done" },
    {name: "PRE-RESERVED" , map: "Pre-reserved"  },
    {name: "PAYMENT_DONE" , map: "Yardi payment generated" },
    {name: "CONSUMED" , map: "Consumed" },
    {name: "YARDI_PAYMENT_PENDING" , map: "Processed"  },
    {name: "PAYPAL_PAID_YARDI_SYNC" , map: "PayPal Payment Done - Billing notified"  },
    {name: "CANCELED" , map:  "Cancelled"},
  ]
}
