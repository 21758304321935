<h2 class="cabecera" *ngIf="siteName">
  <mat-icon class="iconoCabecera">book</mat-icon>
  <mat-label class="textCabecera"><b>/{{siteName}}</b> {{'MAPP-FBS-HOME.APP-NAME' | translate}}</mat-label>
</h2>

<div class="fondoFBS" *ngIf="fbsUser">
  <div class="headerFbsHome">
    <mat-tab-group mat-stretch-tabs class="groupFbsHome" (selectedTabChange)="tabChanged($event)" *ngIf="can_access">
      <mat-tab label="{{'MAPP-FBS-MENU.FACILITY_LIST' | translate}}"  *ngIf="!isAdmin && !isSuperAdmin">
        <microapp-facilitiesbooking-facilitybooking-list></microapp-facilitiesbooking-facilitybooking-list>
      </mat-tab>
      <mat-tab label="{{(can_see_all_reservations?'MAPP-FBS-MENU.ALL_BOOKINGS':'MAPP-FBS-MENU.MY_BOOKINGS') | translate}}" >
        <empusa-microapp-fbs-my-reservations #tabMyReservation></empusa-microapp-fbs-my-reservations>
      </mat-tab>
      <mat-tab label="{{'MAPP-FBS-MENU.MANAGEMENT' | translate}}" *ngIf="can_manage_resources">
        <empusa-microapp-facilitiesbooking-management></empusa-microapp-facilitiesbooking-management>
      </mat-tab>
      <mat-tab label="{{'MAPP-FBS-MENU.TARIFFS' | translate}}" *ngIf="can_manage_resources">
        <empusa-microapp-fbs-tariff-management></empusa-microapp-fbs-tariff-management>
      </mat-tab>
    </mat-tab-group>
    <div class="notAccess" *ngIf="!can_access" style="width: 100%; justify-content: center;
    display: flex;">
      <h3>You can´t access to this Module</h3>
    </div>
  </div>
  <empusa-microapp-fbs-spinner *ngIf="loading" overlay="true"></empusa-microapp-fbs-spinner>
</div>