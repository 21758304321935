<h2 class="titleNew">{{'MAPP-TARIFF.FACILITY.TITLE' | translate}}
    <button class="closeNewVisit" mat-icon-button mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
    </button>
</h2>

<form [formGroup]="newForm">
    <div mat-dialog-content class="content">
        <div>
            <mat-form-field class="formTarif">
                <mat-label>{{'MAPP-TARIFF.FACILITY.NAME' | translate}}</mat-label>
                <input matInput formControlName="name" [(ngModel)]="data.tarif_name">
            </mat-form-field>

            <mat-form-field class="formTarif">
                <mat-label>{{'MAPP-TARIFF.FACILITY.NUMBERUNIT' | translate}}</mat-label>
                <input formControlName="units" matInput type="number" [(ngModel)]="data.number_of_units"
                    (change)="changeNumberUnit()">
            </mat-form-field>

            <mat-form-field class="formTarif">
                <mat-label>{{'MAPP-TARIFF.FACILITY.UNITPRICE' | translate}}</mat-label>
                <input matInput formControlName="price" type="number" [(ngModel)]="data.unit_price"
                    (change)="changeUnitPrice()">
            </mat-form-field>
        </div>
        <div>

            <mat-form-field class="formTarifTag">
                <mat-chip-list #chipList>
                    <mat-chip class="chipTag" *ngFor="let tag of data.tags" [selectable]="true" [removable]="true"
                        (removed)="removeTag(tag)">
                        {{tag}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input formControlName="tags" placeholder="New tag..." #tagInput [formControl]="tagCtrl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
                    <mat-option *ngFor="let tag of allowedTags | async" [value]="tag">
                        {{tag}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</form>
<div mat-dialog-actions class="buttonTarif" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-TARIFF.FACILITY.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary" [disabled]="!newForm.valid"
        [mat-dialog-close]="data">
        {{'MAPP-TARIFF.FACILITY.CREATE' | translate}}</button>
</div>