import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaQuestionDialog, AuthenticationService, User } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../client/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MicroappUserTenantFilter } from './microapp-usermanagement.list-userTenant.componentfilter';
import { AddUserDTO } from 'projects/microapp-usermanagement/src/client/dto/adduser';
import { MicroappusermanagementGenericComponent } from '../../generic/microapp-usermanagement-generic.component';
import { TenantService } from '../../../client/tenant.service';
import { SiteService } from '../../../client/site.service';
import { EditUserTenantComponent } from '../edit/microapp-usermanagement.edit-userTenant.component';

@Component({
  selector: 'microapp-usermanagement-userTenant-list',
  templateUrl: './microapp-usermanagement.list-userTenant.component.html',
  styleUrls: ['./microapp-usermanagement.list-userTenant.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ListUserTenantComponent implements OnInit {

  displayedColumns: string[] = ['mail', 'fullname', 'defaultHost','actions'];
  dataSource = new MatTableDataSource([]);
  currentUser: User;
  showBtnNew: boolean = false;
  filterGroup: FormGroup;
  filterValues = {};
  loading: boolean;
  // isTenant: boolean;
  uidTenant: string;
  tenant: string;

  userFilter = new MicroappUserTenantFilter;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private siteService: SiteService, private userService: UserService, private tenantService: TenantService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private translate: TranslateService, private formBuilder: FormBuilder, private auth: AuthenticationService) {

    this.loading = true;
    this.auth.loadCurrentUser().then(user => {
      this.loading = false;
      this.currentUser = user;
      this.showBtnNew = true;

      this.tenant = this.currentUser.sites[0].tenants[0].uid;
      this.viewUsers();
    });
  }

  ngOnInit() {
    //we set the datasource of the table
    this.filterGroup = this.buildFilter();
  }

  /**
   * @name updateUsers
   * @description update users from the database
   */
  viewUsers() {
    if (this.currentUser.sites != null && this.currentUser.sites.length > 0) {
      this.loading = true;
      this.userService.findUsersByTenant(this.tenant).subscribe(data => {
        this.loading = false;
        console.log("data users", data.users)
        data.users.forEach(user =>{
          user.mail = user.mail.toLowerCase();
        })
        let tempData = data.users;
        let tempDataSource = new MatTableDataSource(tempData);
        tempDataSource.sort = this.sort;
        tempDataSource.paginator = this.paginator;
        this.defineDataSourceFilter(tempDataSource);
        this.dataSource = tempDataSource;
      },
        error => {
          this.loading = false;
          this.snackBar.open(
            this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.MESSAGE"),
            this.translate.instant("MAPP-USERMANAGEMENT.FAILLOAD.TITLE")
            , {
              duration: 5000,
            });
        });
    }
  }


  /**
   * @name applyFilter
   * @description apply a filter to the table
   * @param {string} filterValue the value of the filter
   */

  buildFilter() {
    return this.formBuilder.group({
      GENERAL: new FormControl(''),
      ROL: new FormControl('')
    })
  }


  defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
    tempDataSource.filterPredicate =
      (aGroup: User, filter: string) => {
        const theFilter: MicroappUserTenantFilter = JSON.parse(filter);
        let generalFilter = false;
        let hostFilter = false;
        if (!theFilter) return true;

        //general Filter
        if (theFilter.general) {
          if (aGroup.mail.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.givenName.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
          if (aGroup.surname.toLowerCase().indexOf(theFilter.general) !== -1) {
            generalFilter = true;
          }
        } else generalFilter = true;

        if (theFilter.host) {
          if (aGroup.default_visitor_host == JSON.parse(theFilter.host)) {
            hostFilter = true;
          }
        } else hostFilter = true;
        return generalFilter && hostFilter ;
      }
    //rol filter


  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyHostFilter(filterValue: string) {
    this.filterValues[filterValue] = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.host = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);

  }

  resetFilters() {
    this.userFilter.general = undefined;
    this.userFilter.host = undefined;
    this.filterGroup.reset();
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }



  /**
   * @name deleteUser
   * @description delete the current selected user
   * @param {string} the user id to delete
   */
  deleteUser(uid: string) {6
    let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
      data: {
        title: 'DIALOG.DELETE.TITLE',
        question: 'DIALOG.DELETE.QUESTION',
        icon: 'delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.userService.deleteUser(uid).subscribe(
          data => {
            for (let i = 0; i < this.dataSource.data.length; ++i) {
              if (this.dataSource.data[i].uid === uid) {
                const data = this.dataSource.data;
                data.splice(i, 1);
                this.dataSource.data = data;
              }
            }
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.CONFIRMATION"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          },
          error => {
            this.loading = false;
            this.snackBar.open(this.translate.instant("DIALOG.DELETE.ERROR"),
              this.translate.instant("DIALOG.DELETE.TITLE"), {
              duration: 5000,
            });
          }
        );
      }
    });
  }

  createOrEdit(ticket_id: string) {
    let dialogAssign = this.dialog.open(EditUserTenantComponent, {
      width: '680px',
      data: ticket_id
    });
    dialogAssign.afterClosed().subscribe(result => {
      if(result){
        this.viewUsers();
        this.resetFilters();
      }
    });
  }

  sendNot(user_id: string) {
    let dialogRef = this.dialog.open(MicroappusermanagementGenericComponent, {
      data: {
        title: 'Resend Email',
        question: 'Are you sure of resend email to the user?',
        icon: 'email'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.resentEmail(user_id).subscribe(data => {

          this.snackBar.open(this.translate.instant("Email resent"),
            this.translate.instant("OK"), {
            duration: 5000,
          });
        },
          error => {
            if (error.status === 409) {
              this.snackBar.open(this.translate.instant("User not found"),
                this.translate.instant("ERROR"), {
                duration: 5000,
              });

            } else {
              this.snackBar.open(this.translate.instant("Error resending the email"),
                this.translate.instant("ERROR"), {
                duration: 5000,
              });
            }
          }
        );
      }
    });
  }
  chipsDefaultHost = [
    { result: 'true', icon: 'done' }
  ]
}
