import { Tenant } from './tenant';

export class Site {
    uid: string;
    name: string;
    description: string;
    billingPropertyId: string;
    ou: string;
    cn: string;
    dn: string;
    isBuildingStaff: boolean;
    type: string;
    tenants?: Tenant[];

    constructor() {
    }
}



