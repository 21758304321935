import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import * as microappFbsState from '../../lib/store/microapp-fbs-app-reducer';
import * as fbsActions from '../../lib/store/microapp-fbs-actions';
import { Store } from '@ngrx/store';
import { MicroappFbsChargeType } from '../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsFacilityListComponent } from './facility-list/microapp-fbs-facility-list.component';
import { MicroappFbsNewUpdateResourceComponent } from './facility/new-update-resource/microapp-fbs-new-update-resource.component';
import { MicroappFbsNewUpdateAccessoryComponent } from './accessory/new-update-accessory/microapp-fbs-new-update-accessory.component';

@Component({
  selector: 'empusa-microapp-facilitiesbooking-management',
  templateUrl: './microapp-facilitiesbooking-management.component.html',
  styleUrls: ['./microapp-facilitiesbooking-management.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MicroappFacilitiesbookingManagementComponent implements OnInit {

  chargeTypes: MicroappFbsChargeType[];

  constructor(public dialog: MatDialog,
    private store: Store<microappFbsState.MicroappFbsStates>,) { }

  @ViewChild(MicroappFbsFacilityListComponent) facilityList: MicroappFbsFacilityListComponent


  ngOnInit(): void {
    this.store.dispatch(fbsActions.loadChargeTypes());
  }
}
