import { Component, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { Fitting } from '../../client/dto/fitting';
import { LogIssue } from '../../client/dto/logIssue';
import { InternalService } from '../../client/internal.service';
import { FittingService } from '../../client/fitting.service';
import { CommentComponent } from '../components/microapp-fittingout.components-comment-fittingout.component';
import { MICROAPP_UPDATE, MICROAPP_NAME, MICROAPP_RESOLVE, MICROAPP_STATUS_OPEN, MICROAPP_STATUS_CLOSED, MICROAPP_STATUS_CLOSED_REJECTED, MICROAPP_STATUS_CLOSED_APPROVED, MICROAPP_TEXT_REOPEN } from '../../lib/microapp-fittingout.module';
import { MailService } from '../../client/mail.service';
import { WarningDialog } from '../components/microapp-fittingout.components-warning-fittingout.component';



@Component({
  selector: 'microapp-fittingout-fittingout-new',
  templateUrl: './microapp-fittingout.new-fittingout.component.html',
  styleUrls: ['./microapp-fittingout.new-fittingout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewFittingComponent {

  @ViewChild("fileUpload", { static: false }) fileInputElement: ElementRef;
  files = [];
  filesName = [];
  /** form to validate */
  public newFittingForm: FormGroup;
  /**the user being edited */
  newFitting = new Fitting();
  matcher = new MyErrorStateMatcher();
  listTypes: string[] = [];
  listFiles: FilesShow[] = [];
  listLogIssue: LogIssue[] = [];
  currentUser: User = new User();
  username: string;
  isEdit: boolean;
  isEditAndComents: boolean;
  allowDownloadFiles: boolean;
  allowChangeDescriptions: boolean;
  allowComments: boolean;
  isClosed: boolean;
  isAllowUpdate: boolean;
  isAllowResolve: boolean;
  comment: string;
  isChecked = false;
  textResolution: string;
  resolution: string = MICROAPP_STATUS_CLOSED_APPROVED;
  loading: Boolean;

  constructor(private translate: TranslateService,
    private interalService: InternalService,
    private service: FittingService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewFittingComponent>,
    private snackBar: MatSnackBar, private dialog: MatDialog,
    private auth: AuthenticationService,
    private mailService: MailService,
    @Inject(MAT_DIALOG_DATA) public ticket_id: String) {

    this.newFittingForm = this.formBuilder.group({
      date: ['', Validators.required],
      summary: ['', Validators.required],
      description: ['', Validators.required],
      reporter: ['', Validators.required],
    });
    this.currentUser = this.auth.getCurrentUser();
    this.getAllTypes();
    // if (ticket_id != '') {
    //   //edit
    //   this.loadFitting(ticket_id);
    // } else {
    //new
    this.setDate(true);
    this.f.reporter.setValue(this.currentUser.mail);
    this.isEdit = false;
    this.allowDownloadFiles = false;
    this.allowChangeDescriptions = true;
    this.isAllowUpdate = true;
    this.allowComments = false;
    this.isAllowResolve = false;
  }

  setDate(initOn: boolean) {
    let today = new Date().toLocaleString();
    this.f.date.setValue(today);
  }

  setDateEdit() {
    var dateEdit = this.newFitting.date;
    let formatedBarra: String = dateEdit.slice(0, 10);
    let formatesGuion = formatedBarra.replace(/\//g, '-');
    var reverseDate = formatesGuion.split("-").reverse().join("-");
    let formated = reverseDate + "T" + dateEdit.slice(11) + "Z";
    let dateNew = new Date(formated).toLocaleString();
    this.f.date.setValue(dateNew);
  }

  getAllTypes() {
    this.listTypes = JSON.parse(localStorage.getItem('types'));
  }

  // convenience getter for easy access to form fields
  get f() { return this.newFittingForm.controls; }

  loadComments() {
    this.service.getChanges(this.newFitting.ticket_id).subscribe((dataLog: LogIssue[]) => {

      for (let dateComment of dataLog) {
        let dateFormated = dateComment.date;

        let formatedBarra: String = dateFormated.slice(0, 10);
        let formatesGuion = formatedBarra.replace(/\//g, '-');
        var reverseDate = formatesGuion.split("-").reverse().join("-");
        let formated = reverseDate + "T" + dateFormated.slice(11) + "Z";
        let dateNew = new Date(formated).toLocaleString();
        dateComment.date = dateNew;
      }
      this.listLogIssue = dataLog;
      if (this.listLogIssue.length > 0) {
        this.isEditAndComents = true;
      } else {
        this.isEditAndComents = false;
      }
    },
      error => {
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FAILLOAD.TITLE")
          , {
            duration: 5000,
          });
      });
  }


  deleteFile(filename: string) {
    this.filesName.forEach((item, index) => {
      // if (this.newFitting.ticket_id != undefined && (item.filename === filename)) {
      //   this.service.deleteFile(this.newFitting, filename).subscribe((event: any) => {
      //     if (event == 'ok') {
      //       if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_REJECTED) {
      //         this.service.updateFittingComment(this.newFitting, MICROAPP_TEXT_REOPEN, this.auth.getCurrentUserName()).subscribe(ok => {
      //           this.loadFitting(this.newFitting.ticket_id);
      //         });
      //         this.loadFitting(this.newFitting.ticket_id);
      //       } else {
      //         this.loadFiles(false);
      //       }
      //       this.snackBar.open(
      //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_OK.MESSAGE"),
      //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_OK.TITLE")
      //         , {
      //           duration: 5000,
      //         });
      //     }
      //   },
      //     error => {
      //       this.loadFiles(false);
      //       this.snackBar.open(
      //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.MESSAGE"),
      //         this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.TITLE")
      //         , {
      //           duration: 5000,
      //         });
      //     })
      // }
      if (item.filename === filename) {
        this.files.splice(index, 1);
        this.filesName.splice(index, 1);
      }
    });
  }

  onClick() {
    const fileUpload = this.fileInputElement.nativeElement; fileUpload.onchange = () => {
      let wait: boolean = false;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.filesName.forEach(searchFile => {
          if (searchFile.filename == file.name) {
            wait = true;
          }
        });
        if (file.size > 11322929) {
          this.snackBar.open(
            this.translate.instant("The file " + file.name + " is too big to upload"),
            this.translate.instant("Max Size is 10 MB")
            , {
              duration: 5000,
            });
        } else {
          this.files.push({ data: file, inProgress: false, progress: 0 });
          if (!this.isEdit) {
            this.filesName.push({ filename: file.name });
          }
        }
      }
    };
    fileUpload.click();
  }

  uploadFiles() {
    this.fileInputElement.nativeElement.value = '';
    for (let i = 0; i < this.files.length; i++) {
      let last = false;
      if (i == this.files.length - 1) {
        last = true;
      }
      this.uploadFile(this.files[i], last);
    }
  }

  uploadFile(file, last) {
    const formData: FormData = new FormData();
    formData.append('name', file.data.name);
    formData.append('file', file.data);
    file.inProgress = true;
    this.service.upload(formData, this.newFitting).subscribe((event: any) => {
      if (typeof (event) === 'object' && event.body === file.data.name) {

        if (last) {
          // if (this.newFitting.status == MICROAPP_STATUS_CLOSED && this.newFitting.resolution == MICROAPP_STATUS_CLOSED_REJECTED) {
          //   this.service.updateFittingComment(this.newFitting, MICROAPP_TEXT_REOPEN, this.auth.getCurrentUserName()).subscribe(ok => {
          //     this.loadFitting(this.newFitting.ticket_id);
          //   });
          // }
          // this.loadFiles(false);
          this.snackBar.open(
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.MESSAGE"),
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.TITLE")
            , {
              duration: 5000,
            });
          if (!this.isEdit) {
            this.loading = false;
            this.mailService.createFittingout();
            this.closeDialog();
          } else {
            this.loading = false;
            this.mailService.updateFittingout();
          }
        }
      }
    },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.FILES_NOT_OK.TITLE")
          , {
            duration: 5000,
          });
        // this.loadFiles(false);
      });
  }

  /**
   * @name onSubmit
   * @description the info is being sent to the backend
   */
  onSubmit() {
    // stop here if form is invalid

    //CREATE

    if (this.newFittingForm.invalid) {
      return;
    }
    this.loading = true;
    this.newFitting.reporter = this.f.reporter.value;
    this.newFitting.date = this.f.date.value;
    this.newFitting.type = "Fitting Out";
    this.newFitting.resolution = "";
    this.newFitting.summary = this.f.summary.value;
    this.newFitting.description = this.f.description.value;
    this.newFitting.email = this.currentUser.mail;
    this.getOwner();

  }


  getOwner() {

    this.newFitting.owner = this.interalService.getOwner(this.currentUser);
    this.createFitting();
  }

  createFitting() {

    this.service.createFitting(this.newFitting).subscribe(
      (data: string) => {
        this.newFitting.ticket_id = data;
        if (this.files.length > 0) {
          this.uploadFiles();
        } else {
          this.loading = false;
          this.closeDialog();
          this.mailService.createFittingout();
          this.snackBar.open(
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.MESSAGE"),
            this.translate.instant("MAPP-FITTINGOUT.UPDATE.OK.TITLE")
            , {
              duration: 5000,
            });
        }
      },
      error => {
        this.loading = false;
        this.snackBar.open(
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.MESSAGE"),
          this.translate.instant("MAPP-FITTINGOUT.UPDATE.NOT_OK.TITLE")
          , {
            duration: 5000,
          });
      });
  }

  closeDialog() {
    console.log('closeDialog');
    this.dialogRef.close();
  }

  chips = [
    { name: MICROAPP_STATUS_CLOSED_APPROVED },
    { name: MICROAPP_STATUS_CLOSED_REJECTED }
  ];

}
export class FilesShow {
  filename: string;
}



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
