export class Notifications {
    sid: string;
    title: string;
    message: string;
    date: string;
    destinatary: string[];
    files : boolean;
    tenants: string[];
    sendto: string;
}
