import { Injectable } from '@angular/core';
import { EpsTariff } from '../microapp-eps.tariff.model';
import { EpsConfig } from '../../../microapp-eps-cfg';
import { Adapter } from '../../microapp-eps.adapter';

@Injectable({
  providedIn: "root",
})
export class EpsTarifAdapter implements Adapter<EpsTariff> {

  constructor(private epsConfig: EpsConfig,
  ) { }

  adapt(item: any): EpsTariff {
    let tarifAdapted = new EpsTariff;
    tarifAdapted.tarif_id = item.tarif_id;
    tarifAdapted.tarif_name = item.tarif_name;
    tarifAdapted.unit_price = item.unit_price;
    return tarifAdapted;
  }

}