import { Observable, of, from, throwError } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Defines an EMPUSA Fake Backend.
 * A Fake backed interface allows to define the way to deal with fake data, simulating a backend
 */
export abstract class EmpusaFakeBackend {
    /** 
     * A generic json object with all the instances to work with in the fake backend
     * this is just the microapp scoped database (don use it during the intercept), 
     * it will be merged with all the fake databases running in the empusa macro app.
     */
    abstract microappFakeData: any;

    /**
      * method should handle a route, in the case it is handled by this fake backend.
      * IMPORTANT: If the route is not handled by this backend, must return null.
      * @param <string> url the url to handle
      * @param <string> method the method used to request (GET POST PUT ...)
      * @param headers the headers of the request
      * @param body the body of the request
      * @param database the global database to work with (this database is the general database with all microapps databased merged)
      */
    abstract handleRoute(url: string, method: string, headers, body, database: any): Observable<HttpEvent<any>>;

    /**
     * Return a OK response (200) with the body passed
     * @param body? optional 
     */
    static ok(body?) {
        return of(new HttpResponse({ status: 200, body }))
    }

    /**
     * Return an unauthorized response (401)
     */
    static unauthorized() {
        return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    /**
     * Throw an error message
     * @param message 
     */
    static error(message) {
        return throwError({ error: { message } });
    }

    /**
     * extract the id passed to the url (last part of the url)
     * @param url the url
     */
    static idFromUrl(url: string): string {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }

}