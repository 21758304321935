<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersList"> {{'MAPP-EPS.GENERIC.FILTER' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder=" {{'MAPP-EPS.GENERIC.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <p></p>
                    <mat-label>{{'MAPP-EPS.TENANT.RESERVATIONTENANT.VEHICLE_TYPE' | translate}}</mat-label>
                    <mat-chip-list formControlName="VEHICLETYPE" class="order" aria-label="vehicleTypeChipsFilter">
                        <mat-chip *ngFor="let chip of chips" [selected]="chip.name==userFilter.vehicleType"
                            (click)="applyVehicleTypeFilter(chip.name)">
                            <mat-icon>{{chip.icon}}</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <p></p>
                    <p></p>
                    <p></p>
                    <mat-form-field>
                        <mat-label>{{'MAPP-EPS.TENANT.RESERVATIONTENANT.EFFECTIVE' | translate}}</mat-label>
                        <input formControlName="EFFECTIVE" matInput [matDatepicker]="pickerEffective"
                            (dateInput)="applyEffectiveDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="pickerEffective">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEffective></mat-datepicker>
                    </mat-form-field>

                    <p></p>
                    <mat-form-field>
                        <mat-label>{{'MAPP-EPS.TENANT.RESERVATIONTENANT.EXPIRED' | translate}}</mat-label>
                        <input formControlName="EXPIRE" matInput [matDatepicker]="pickerExpired"
                            (dateInput)="applyExpiredDateFilter($event.target.value)">
                        <mat-datepicker-toggle class="matCalendarIcon" matSuffix [for]="pickerExpired">
                            <mat-icon class="matCalendarIcon"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerExpired></mat-datepicker>
                    </mat-form-field>
                    <p></p>
                    <mat-form-field>
                        <mat-label>Status</mat-label>
                        <mat-chip-list formControlName="STATUS" class="statusChipsFilter" aria-label="statusChipsFilter">
                            <!--[selectable]="true"-->
                            <mat-chip class="chipsStatus" *ngFor="let chip of statusChips" [selected]="chip.name==userFilter.status"
                                (click)="applyStatusFilter(chip.name)">{{chip.map}}</mat-chip>
                        </mat-chip-list>
                    </mat-form-field>
                    

                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-EPS.GENERIC.CLEAN' | translate}}</button>



                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <div class="checkInHeader">
                <form class="tenantForm" [formGroup]="tenantForm">
                    <mat-form-field *ngIf="isAdmin" class="margen_cajas">
                        <mat-label>Tenants</mat-label>
                        <mat-select formControlName="tenants">
                            <mat-option *ngFor="let tenant of tenantsToSelect" [value]="tenant.uid">
                                {{tenant.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="margen_cajas">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="from" (dateChange)="onFromDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="margen_cajas">
                        <mat-label>To</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="to" [min]="minDateRange"  [max]="maxDateRange">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <button [disabled]="!tenantForm.valid" mat-flat-button (click)="tenantSelected()"
                        class="buttonSearchTenant" color="primary">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
                <div *ngIf="!isAdmin" style="display: flex;">
                    <div style="margin: 2px 20px;">
                        <span>Entitled Lots Assigned to this tenant: </span>{{countLotsInterface.countEntitledLotsAssigned}}
                        <p></p>
                        <span>Non-Entitled Lots Assigned to this tenant: </span>{{countLotsInterface.countNonEntitledLotsAssigned}}
                    </div>
                    <div style="margin: 2px 20px;">
                        <span>Entitled Reservations: </span>{{countLotsInterface.countEntitledReservations}}
                        <p></p>
                        <span>Non-Entitled Reservations: </span>{{countLotsInterface.countNonEntitledReservations}}
                    </div>
                </div>
            </div>
            <div align="end" style="width: 30%;">
                <button mat-icon-button class="buttonExpandFil">
                    <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
                </button>
                <button matTooltip="{{'MAPP-EPS.TENANT.RESERVATIONTENANT.RELOAD' | translate}}" mat-button class="buttonReload" (click)="reload()">
                    <mat-icon matSuffix>autorenew</mat-icon>
                </button>
                <button matTooltip="{{'MAPP-EPS.TENANT.RESERVATIONTENANT.DOWNLOAD' | translate}}" mat-icon-button (click)="downloadCsvRequest()" class="buttonReload">
                    <mat-icon>cloud_download</mat-icon>
                </button>
                <button matTooltip="{{'MAPP-EPS.TENANT.RESERVATIONTENANT.HELP' | translate}}" mat-icon-button (click)="helpStatus()" class="buttonReload">
                    <mat-icon>help</mat-icon>
                </button>
            </div>
        </div>
        <microapp-eps-reservation-table-component (tableExport)="exportTable($event)" (countLots)="countLots($event)" (onModalSuccessfullyOperation)="onModalSuccessfullyOperation()" [filter]="filter" [downloadCsvRequestBoolean]="downloadCsvRequestBoolean" [currentDateTime]="currentDateTime" [dataToTableReservation]="dataToTableReservation"></microapp-eps-reservation-table-component>
    </mat-drawer-container>
</div>
