import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { Fitting } from './dto/fitting';
import { MICROAPP_NAME, MICROAPP_FINDALL, MICROAPP_FINDASIGNED, MICROAPP_FINMINE, MICROAPP_STATUS_CLOSED, MICROAPP_STATUS_OPEN } from '../lib/microapp-fittingout.module';



@Injectable({
  providedIn: 'root',
})
export class FittingService {
  private REST_TRAC_SERVER: string = this.environment.urlbase + 'fittingout/services/tickets';

  // private REST_TRAC_SERVER: string = 'http://localhost:8000/fittingout/services/tickets';


  constructor(private http: HttpClient, @Inject('environment') private environment,
    private auth: AuthenticationService) {
  }
  currentDate() {
    let dateNow: string = new Date().getTime().toString();
    return dateNow;
  }
  getUrl(path: string) {
    return this.REST_TRAC_SERVER + path;
  }
  //FITTING  
  allFitting(currentUser: User, filterValues): any {
    if (!this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDALL)) {
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINDASIGNED)) {
        // filterValues['owner']=currentUser.mail;
        filterValues['owner'] = "FACILITY MANAGER";
      }
      if (this.auth.canUserExecute(MICROAPP_NAME, MICROAPP_FINMINE)) {
        filterValues['reporter'] = currentUser.mail;
      }
    }
    console.log(filterValues);

    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path = '/list';
    return this.http.post(this.getUrl(path),
      JSON.stringify(filterValues), opts).pipe(
        map(data => data)
      );
  }


  getFitting(id) {
    let path: string = '/id/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );

  }

  createFitting(data) {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path: string = '/new';
    return this.http.post(this.getUrl(path),
      JSON.stringify(data), opts).pipe(
        map(data => data)
      );
  }

  updateFitting(data) {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      }),
      params: new HttpParams().set('id', this.currentDate())
    }
    let opts = Object.assign({}, OPTIONS, OPTIONS.params);
    let path: string = '/update';
    return this.http.put(this.getUrl(path),
      JSON.stringify(data), opts).pipe(
        map(data => data)
      );
  }


  updateFittingResolve(ticket_id, comment, resolution, user, owner, reporter) {
    var datas: Fitting[] = [];
    var data: Fitting = new Fitting();
    data.ticket_id = ticket_id;
    data.comment = comment;
    data.user = user;
    data.resolution = resolution;
    data.owner = owner;
    data.reporter = reporter;
    if (resolution === "rejected" || resolution === "approved") {
      data.status = MICROAPP_STATUS_CLOSED;
    } else {
      data.status = MICROAPP_STATUS_OPEN;
    }

    ;
    datas.push(data);
    return this.updateFitting(datas);
  }

  updateFittingComment(ticket: Fitting, comment, user) {
    var datas: Fitting[] = [];
    var data: Fitting = new Fitting();
    data.ticket_id = ticket.ticket_id;
    data.comment = comment;
    data.user = user;
    data.reporter = ticket.reporter;
    if (ticket.status == MICROAPP_STATUS_CLOSED) {
      data.status = MICROAPP_STATUS_OPEN;
      data.resolution = '';
    }
    datas.push(data);
    return this.updateFitting(datas);
  }

  //STATIC FIELDS  


  allTypes(): any {
    let path: string = '/type/list';
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );

  }



  getChanges(id) {
    let path: string = '/changelog/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );

  }
  //FILES   

  getFiles(id) {
    let path: string = '/files/list/' + id;
    return this.http.get(this.getUrl(path), { params: new HttpParams().set('id', this.currentDate()) }).pipe(
      map(data => data)
    );

  }

  upload(formData, ticket: Fitting) {
    let path: string = '/files/upload/' + ticket.ticket_id;
    return this.http.post(this.getUrl(path), formData, { reportProgress: true, observe: 'events', responseType: 'text', params: new HttpParams().set('id', this.currentDate()) });
  }

  deleteFile(ticket: Fitting, filename) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain'
      }),
      'responseType': 'text' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };
    let path: string = '/files/delete/' + ticket.ticket_id + '/' + filename;
    return this.http.delete(this.getUrl(path), httpOptions).pipe(
      map(data => data)
    );
  }

  downloadFile(ticket_id, filename) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/octect-stream'
      }),
      'responseType': 'blob' as 'json',
      params: new HttpParams().set('id', this.currentDate())
    };
    let path: string = '/files/' + ticket_id + '/' + filename;
    return this.http.get(this.getUrl(path), httpOptions).pipe(
      map(data => data)
    );
  }




}


