import { Observable, forkJoin } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappFacilitiesbookingService } from '../../../../lib/microapp-facilitiesbooking.service';
import { MicroappFbsResourceGroup } from '../../../../client/dto/microapp-fbs-resource-group';
import { MicroappFbsMessageHandlerService } from '../../../../lib/microapp-fbs-message-handler.service';
import { MicroappFbsResponse } from '../../../../client/dto/microapp-fbs-response';

@Component({
  selector: 'empusa-set-group-available',
  templateUrl: './set-group-available.component.html',
  styleUrls: ['./set-group-available.component.css']
})
export class SetGroupAvailableComponent implements OnInit {
  resource: MicroappFbsResourceGroup;
  loading: boolean;

  constructor(private dialogRef: MatDialogRef<any>,
    private facilityBookingService: MicroappFacilitiesbookingService,
    private messageHandler: MicroappFbsMessageHandlerService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.resource = data;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.loading = true;

    const setAssociatedFacilitiesAvailableSubscriptions: Observable<MicroappFbsResponse>[] = [];

    this.resource.resourcesIds.forEach((id) => {
      setAssociatedFacilitiesAvailableSubscriptions.push(this.facilityBookingService.reescaleFacility(1, id.toString()));
    });

    forkJoin(setAssociatedFacilitiesAvailableSubscriptions).subscribe(response => {
      this.loading = false;
      this.messageHandler.show_operation_done();
      this.dialogRef.close(true);
    }, error => {
      this.loading = false;
      this.messageHandler.show_management_error(error.status);
      this.dialogRef.close(false);
    });
  }

}
