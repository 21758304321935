<h2 class="titleNew">{{'MAPP-EPS.DIALOG_ASSIGN_QUOTAS.TITLE' | translate}}<button class="closeEditVist" mat-icon-button
        mat-dialog-close>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
    </button></h2>
<p>{{quotas.name}}</p>
<div>
    <form [formGroup]="formGroup" (ngSubmit)="doAction()">
        <div *ngFor="let spot_type of parkingSize">
            <div class="vehicleTypeSpot" *ngFor="let vehicle_type of spot_type.vehicle_type">
                <mat-form-field style="width: 30%;"  *ngIf="(!disabled && spot_type.spot_type_id==2) || spot_type.spot_type_id==1" class="fieldVehicleSpot">
                    <mat-label>{{spot_type.spot_short_desc}}
                        {{vehicle_type.vehicle_short_desc}}</mat-label>
                    <input [ngClass]="{'readOnlyInput':disabled && spot_type.spot_type_id==2}" [readonly]="disabled && spot_type.spot_type_id==2" matInput formControlName="{{spot_type.spot_type_id}}#{{vehicle_type.vehicle_type_id}}">
                    <span matSuffix>{{'MAPP-EPS.DIALOG_ASSIGN_QUOTAS.SUFIX' |
                        translate}}{{vehicle_type.can_assign}}</span>
                    <mat-error>
                        {{getErrorMessage(spot_type.spot_type_id +"#"+vehicle_type.vehicle_type_id) | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <p></p>
        </div>
        <mat-checkbox  (change)="changeSelection($event)" formControlName="association_enabled" color="primary" class="hostCheck">
            {{'MAPP-EPS.QUOTAS.ENABLED.NONENTITLED' | translate}}</mat-checkbox>
    </form>
</div>
<div class="buttonDetail" mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()">{{'MAPP-EPS.GENERICDIALOG.CANCEL' | translate}}</button>
    <button class="buttonConfirm" [disabled]="!formGroup.valid" mat-raised-button color="primary"
        (click)="doAction()">{{'MAPP-EPS.QUOTAS.UPDATEQUOTA' | translate}}</button>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>