<div class="generalCard">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filtersTypeList">  {{'MAPP-EPS.GENERIC.FILTER' | translate}}
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>
            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="searchText($event.target.value)" placeholder="Search">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>
        <div class="header">
            <form [formGroup]="newSelectTariffForm">
                <div class="selector_container">
                    <label id="example-radio-group-label">{{'MAPP-EPS.QUOTAS.SPOTTYPES' | translate}}:</label>
                    <p></p>
                    <mat-radio-group labelPosition="before" aria-labelledby="example-radio-group-label"
                        formControlName="spot" class="example-radio-group" [(ngModel)]="selectedSpotType">
                        <mat-radio-button class="example-radio-button" *ngFor="let spotType of spotTypes"
                            [value]="spotType.spot_type_id">
                            {{spotType.spot_short_desc}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="selector_container" *ngIf="selectedSpotType">
                    <label id="example-radio-group-label">{{'MAPP-EPS.QUOTAS.RESERVATIONTYPES' | translate}}:</label>
                    <p></p>
                    <mat-radio-group labelPosition="before" aria-labelledby="example-radio-group-label"
                        formControlName="reservation" class="example-radio-group" [(ngModel)]="selectedReservationType">
                        <mat-radio-button class="example-radio-button"
                            *ngFor="let reservationType of reservationType; index as i"
                            [value]="reservationType.reservation_type_id">
                            {{reservationType.reservation_types_short_desc}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </form>
        </div>

        <!-- TABLE -->
        <table mat-table [dataSource]="tenantTariffsDataSource" *ngIf="vehicleType && tenantTariffsDataSource" matSort
            class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef='tenant_name'>
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'MAPP-EPS.QUOTAS.TNAME' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.tenant.name}} </td>
            </ng-container>
            <ng-container *ngFor="let vehicle_type of vehicleType"
                matColumnDef='Vehicle{{vehicle_type.vehicle_type_id}}'>
                <th mat-header-cell *matHeaderCellDef>
                    <mat-icon>{{vehicle_type.vehicle_icon}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{findTenantTariff(element, vehicle_type.vehicle_type_id)}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <div class="footer-container">
            <mat-paginator class="paginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>

    </mat-drawer-container>
</div>