<!-- <h1 mat-dialog-title>
    <mat-icon class="icon">{{data.icon}}</mat-icon>{{data.title | translate}}
</h1>
<div mat-dialog-content>
    <p>{{data.question | translate}}</p>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="warn" [mat-dialog-close]="true">{{'DIALOG.QUESTION.OK' | translate}}</button>
    <button mat-stroked-button color="primary" class="cancel" [mat-dialog-close]="false" cdkFocusInitial>{{'DIALOG.QUESTION.CANCEL' | translate}}</button>
</div>

// -->



<h2 class="titleGeneric" mat-dialog-title> {{data.title | translate}}</h2>
<div class="genericDialog">
    {{data.question | translate}}
</div>

<div mat-dialog-actions class="genericButton" align="end">
    <button class="buttonClose" mat-raised-button
        [mat-dialog-close]="false">{{'DIALOG.QUESTION.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary"
        [mat-dialog-close]="true">{{'DIALOG.QUESTION.OK' | translate}}</button>
</div>