import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappVmsVisitor } from '../../../../lib/model/microapp-vms-visitor.model';
import { MicroappVmsVisitorWithPendingVisits } from '../../../../lib/model/microapp-vms-visitor-pending-visits.model';
import { TranslateService } from '@ngx-translate/core';
import { VmsConfig } from '../../../../lib/microapp-vms-config';

@Component({
  selector: 'empusa-microapp-vms-visitor',
  templateUrl: './microapp-vms-visitor.component.html',
  styleUrls: ['./microapp-vms-visitor.component.css']
})
export class MicroappVmsVisitorComponent implements OnInit {
  visitor: MicroappVmsVisitor | MicroappVmsVisitorWithPendingVisits;

  constructor(
    private translate: TranslateService,
    public vmsConfig: VmsConfig,
    @Inject(MAT_DIALOG_DATA) data) {
      this.visitor = data;
  }


  ngOnInit(): void {
  }

}
