import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as xBookActions from '../../../lib/logbook/store/microapp-vms-logbook.actions'
import { Store, select } from '@ngrx/store';
import { MicroappVmsVisitor } from '../../../lib/model/microapp-vms-visitor.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroappVmsVisit } from '../../../lib/model/microapp-vms-visit.model';
import { delay } from 'rxjs/operators';
import { deleteTimeZone } from '../../../lib/common/microapp-vms-date.util';
import { selectLogBookLoading, selectLogBookError } from '../../../lib/logbook/store/microapp-vms-logbook-selectors';


@Component({
  selector: 'empusa-microapp-vms-visit-edit',
  templateUrl: './microapp-vms-visit-edit.component.html',
  styleUrls: ['./microapp-vms-visit-edit.component.css']
})
export class MicroappVmsVisitEditComponent implements OnInit, OnDestroy {

  //Store
  storeSuscripcion: Subscription;
  visitor_details: MicroappVmsVisitor;

  //Form
  minScheduleDate = new Date();
  maxScheduleDate = new Date();

  formGroup: any;
  date = new FormControl();
  start_time = new FormControl();
  end_time = new FormControl();
  complimentary_parking = new FormControl();
  about = new FormControl();
  modifyAllGroupedVisits = new FormControl();

  mode: 'create' | 'update';
  dialogTitle: string;
  loading$: Observable<boolean>;
  visit: MicroappVmsVisit;
  groupedVisit: boolean = false;


  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private dialogRef: MatDialogRef<MicroappVmsVisitEditComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dialogTitle = data.dialogTitle;
    this.visit = data.visit;
    this.mode = data.mode;
  }

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectLogBookLoading));

    this.buildForm();
    this.maxScheduleDate.setFullYear(this.maxScheduleDate.getFullYear() + 1);
    if (this.mode == 'update') {
      this.formGroup.patchValue({ ...this.visit });
    }
    this.groupedVisit = (this.visit.group_id && this.visit.group_id.length > 0);

  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {

    const date_z = deleteTimeZone(this.date.value)

    const visit: MicroappVmsVisit = {
      ...this.visit,
      date: date_z,
      start_time: this.start_time.value,
      end_time: this.end_time.value,
      complimentary_parking: this.complimentary_parking.value,
      about: this.about.value
    }

    console.log("visit", visit);
    const changeAll = this.modifyAllGroupedVisits.value;
    console.log("changeAll", changeAll);
    if (changeAll)
      this.store.dispatch(xBookActions.updateGroupVisit({ visit: visit }));
    else
      this.store.dispatch(xBookActions.updateSingleVisit({ visit: visit }));

    let saving$ = this.store.pipe(select(selectLogBookLoading)).subscribe(isLoading => {
      if (!isLoading) {
        console.log("loading:", isLoading);
        this.store.pipe(select(selectLogBookError)).subscribe(hayError => {
          console.log("*****************")
          this.dialogRef.close(hayError);
          saving$.unsubscribe();
        });
      }
    });
  }


  private buildForm() {
    this.formGroup = new FormGroup({
      date: this.date,
      start_time: this.start_time,
      end_time: this.end_time,
      complimentary_parking: this.complimentary_parking,
      about: this.about,
      modifyAllGroupedVisits: this.modifyAllGroupedVisits
    });
  }


  ngOnDestroy(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
  }
}

