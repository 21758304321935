import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MicroappEpsChargesService } from "../../../lib/services/microapp-eps-charge.service";
import { MicroappEpsTariffUpdateComponent } from "../tariff-update/microapp-eps-tariff-update.component";
import { EpsChargeType } from "../../../lib/model/charges_and_tariffs/microapp-eps.charge-type.model";
import { EpsAssignedChargeType } from "../../../lib/model/charges_and_tariffs/microapp-eps.assigned-charge-types.model";
import { EpsTenantTariff } from "../../../lib/model/charges_and_tariffs/microapp-eps.tenant-tariff.model";
import { AssignTariffFilter } from "./microapp-eps.tenant-tariff_assigned-eps.componentfilter";
import * as microappEpsAppActions from '../../../lib/store/microapp-eps.actions';
import { isLoading } from "../../../lib/store/microapp-eps-selectors";
import { EpsChargeTariffTenant } from "../../../lib/model/charges_and_tariffs/microapp-eps-charge-tariff-tenant.model";
import { Tenant } from "@empusa/empusa-core";
import { EpsTariff } from "../../../lib/model/charges_and_tariffs/microapp-eps.tariff.model";

@Component({
    selector: 'empusa-microapp-eps-tariffs-assigned',
    templateUrl: './microapp-eps-tariff-assigned.component.html',
    styleUrls: ['./microapp-eps-tariff-assigned.component.css']
})
export class MicroappEpsTariffsAssignedComponent implements OnInit, OnDestroy {

    storeSubscription: Subscription;
    displayedColumns = ['chargeType', 'tariff', 'actions'];
    dataSource = new MatTableDataSource([]);
    tenant;
    loading = false;

    assignedCharges: EpsAssignedChargeType[];
    chargesAndTariffs: EpsChargeType[];
    userFilter = new AssignTariffFilter();
    filterGroup: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    isLoadingSubscription: Subscription;

    constructor(private epsChargeServices: MicroappEpsChargesService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private store: Store<microappEpsAppReducer.MicroappEpsStates>,
    ) { }



    ngOnInit(): void {
        this.filterGroup = this.buildFilter();
        this.isLoadingSubscription = this.store.pipe(select(isLoading)).subscribe(loading => {
            this.loading = loading;
        })
    }

    selectedTenant(theTenant) {
        this.tenant = theTenant;
      
        this.storeSubscription = this.store.select('eps').subscribe(data => {

            if (data && data.chargesAndTariffs) {
                this.chargesAndTariffs = data.chargesAndTariffs;
            }
            if (data && data.assignedCharges) {
                this.assignedCharges = data.assignedCharges;
            }
            const currentTenantTariff = data.tenantTariffs.find(item => item.tenant.uid == theTenant);
            const listCharges =data.chargesAndTariffs.map(chargeAndTariff => {
                const chargeWithTariff = currentTenantTariff.charge_types.find(tariff => 
                    tariff.id == chargeAndTariff.id
                )
                return new EpsChargeTariffTenant(currentTenantTariff.tenant as Tenant, chargeWithTariff?.tarif as EpsTariff, chargeAndTariff as EpsChargeType)
            })
            this.dataSource.data = listCharges;
            this.defineDataSourceFilter(this.dataSource);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        })
    }

    defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
        tempDataSource.filterPredicate =
            (aGroup: EpsChargeTariffTenant, filter: string) => {
                const theFilter: AssignTariffFilter = JSON.parse(filter);
                let generalFilter = false;
                if (!theFilter) return true;
                //general Filter
                if (theFilter.general) {
                    if (aGroup?.tariff?.tarif_name) {
                        if (aGroup.charge.application_charge_code.toLowerCase().indexOf(theFilter.general) !== -1) {
                            generalFilter = true;
                        }
                        if (aGroup.charge.description.toLowerCase().indexOf(theFilter.general) !== -1) {
                            generalFilter = true;
                        }
                        if (aGroup.tariff.tarif_name.toLowerCase().indexOf(theFilter.general) !== -1) {
                            generalFilter = true;
                        }
                    } else {
                        if (aGroup.charge.application_charge_code.toLowerCase().indexOf(theFilter.general) !== -1) {
                            generalFilter = true;
                        }
                        if (aGroup.charge.description.toLowerCase().indexOf(theFilter.general) !== -1) {
                            generalFilter = true;
                        }
                    }
                } else generalFilter = true;

                return generalFilter;
            }
    }

    buildFilter() {
        return this.formBuilder.group({
            GENERAL: new FormControl(""),
        })
    }


    applyGenericFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.userFilter.general = filterValue;
        this.dataSource.filter = JSON.stringify(this.userFilter);
    }

    resetFilters() {
        this.userFilter.general = undefined;
        this.filterGroup.reset();
        this.dataSource.filter = JSON.stringify(this.userFilter);
    }

    editTariff(tenantTariff: EpsChargeTariffTenant) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = tenantTariff;
        const dialogRef = this.dialog.open(MicroappEpsTariffUpdateComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
        });
    }


    ngOnDestroy(): void {
        if (this.isLoadingSubscription) {
            this.isLoadingSubscription.unsubscribe()
        }
    }
}
