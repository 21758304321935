<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>

<div class="fondoGeneral">


    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <mat-card class="mc">
                <mat-card-content class="filtersTypeList"> FILTER
                </mat-card-content>
                <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                    <mat-icon class="closeIcFilter">close</mat-icon>
                </button>
            </mat-card>
            <mat-divider></mat-divider>
            <p></p>
            <div class="filtrosEmergentes">
                <mat-form-field>
                    <input matInput (keyup)="searchText($event.target.value)"
                        placeholder="{{'MAPP-HVAC.FACILITY.SEARCH' | translate}}">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <p></p>
            </div>
        </mat-drawer>

        <div class="header">
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <!-- <div class="search_container">

        <form class="filterform">
            <mat-form-field>
                <mat-label>{{'MAPP-HVAC.FACILITY.SEARCH' | translate}}</mat-label>
                <input matInput placeholder="Search" value="" (keyup)="searchText($event.target.value)">
                <button mat-button matSuffix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </form>

    </div> -->
        <div class="table-container">
            <table mat-table [dataSource]="tenantSource" matSort class="mat-elevation-z8" id="tableHvac"
                style="width: 100%;">

                <ng-container matColumnDef="tenants">
                    <mat-header-cell *matHeaderCellDef class="column_show">{{'MAPP-HVAC.FACILITY.TENANTS' |translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="column_show" [class.editable]="element.flag_editable">
                        {{element.tenant.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="cuc">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-HVAC.FACILITY.PASS.HVAC' |translate}}<mat-icon>ac_unit</mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <mat-select *ngIf="element.flag_editable" class="combobox" [value]="element.hvac.tarif.tarif_id"
                            (selectionChange)="onTarifSelected(element.hvac,$event)">
                            <mat-option *ngFor="let tarif of tarifList" [value]="tarif.tarif_id">{{tarif.tarif_name}}
                            </mat-option>
                        </mat-select>
                        <mat-label *ngIf="!element.flag_editable">{{element.hvac.tarif.tarif_name}}</mat-label>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cum">
                    <mat-header-cell *matHeaderCellDef>
                        {{'MAPP-HVAC.FACILITY.PASS.FAN' |translate}}<mat-icon>air</mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [class.editable]="element.flag_editable">
                        <mat-select *ngIf="element.flag_editable" class="combobox" [value]="element.fan.tarif.tarif_id"
                            (selectionChange)="onTarifSelected(element.fan,$event)">
                            <mat-option *ngFor="let tarif of tarifList" [value]="tarif.tarif_id">{{tarif.tarif_name}}
                            </mat-option>
                        </mat-select>
                        <mat-label *ngIf="!element.flag_editable">{{element.fan.tarif.tarif_name}}</mat-label>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="column_show">{{'MAPP-HVAC.FACILITY.ACTIONS' |translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="column_show" [class.editable]="element.flag_editable">
                        <mat-icon matTooltip="Edit" class="editrow" (click)="setEditable(element)"
                            *ngIf="!element.flag_editable">edit
                        </mat-icon>
                        <button mat-flat-button (click)="saveChanges(element)" *ngIf="element.flag_editable"
                            color="primary">Save</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]"></mat-paginator>
        </div>
    </mat-drawer-container>
</div>