import { MicroappVmsHost } from './microapp-vms-host.model';
import { Tenant } from '@empusa/empusa-core';
import { MicroappVmsVisitLog } from './microapp-vms-visit-log.model';

export class MicroappVmsVisit {
    id: string;
	visitor_id: number;
	delivery_id: number;
	site_id: string;
	tenant_id: string;
	tenant:Tenant;
	host_id : string;
	host: MicroappVmsHost;
	date: Date;
	start_time: Date;
	end_time: Date;
	check_in_time: Date;
	check_out_time: Date;
	auto_check_out: boolean;
	first_in_time: Date;
	last_out_time: Date;
	about: string;
	complimentary_parking: boolean;
	complimentary_parking_status: string;
	complimentary_parking_message: string;
	group_id: string;
	first_name: string;
  last_name: string;
    company: string;
    email: string;
    phone: string;
	license_plate: string;
	iu: string;
	notification_status: string;
	log:MicroappVmsVisitLog[];
	past_visit: boolean;
	visit_type: string;
  	owner: boolean;
	vehicle_type: string;
  result_check_mask: boolean;
  result_check_passive_liveness: boolean;
  result_check_temperature: number;
}
