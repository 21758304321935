import { ɵbypassSanitizationTrustResourceUrl } from '@angular/core';

export const MICROAPPFAKEDATABASE = {
    users: [
        {
            uid: "100",
            userName: "superadmin",
            userPassword: "superadmin",
            firstName: "first",
            lastName: "last",
            mail: "superadmin@user.com",
            sites: [
                {
                    uid: "2",
                    name: "Generic Build",
                    description: "A generic build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                },
                {
                    uid: "3",
                    name: "Test Build",
                    description: "A test build to test",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                }

            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                }
            ]
        },

        {
            sid: "1000",
            username: "genericuser",
            password: "genericpass",
            description: "A Generic Test User to add to the current database",
            fk_rol: "superadmin"
        }
    ],
    microappSecurityTree: {
        'faultreport': {
            'access': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': true,
            },
            'create': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': true,
            },
            'reporting': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': false,
            },
            'escalation': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': true,
            },
            'show_detail': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': true,
            },
            'edit_detail': {
                'ADMIN': true,
                'MANAGEMENT': true,
                'USER': false,
            },
            'find_all': {
                'ADMIN': true,
                'MANAGEMENT': false,
                'USER': false,
            },
            'find_assigned': {
                'ADMIN': false,
                'MANAGEMENT': true,
                'USER': false,
            },
            'find_mine': {
                'ADMIN': false,
                'MANAGEMENT': false,
                'USER': true,
            }
        }
    },
    generics: [
        {
            sid: "1",
            name: "generic1"
        },
        {
            sid: "2",
            name: "generic2"
        },
        {
            sid: "3",
            name: "generic3"
        },
    ]
}