import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'empusa-microapp-fbs-spinner',
  templateUrl: './microapp-fbs-spinner.component.html',
  styleUrls: ['./microapp-fbs-spinner.component.css']
})
export class MicroappFbsSpinnerComponent implements OnInit {

  constructor() { }

  @Input() value : number = 100;
  @Input() diameter: number = 100;
  @Input() mode : string ="indeterminate";
  @Input() strokeWidth : number = 10;
  @Input() overlay: boolean = false;
  @Input() color: string = "primary";

  ngOnInit() {

  }

}