import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { SearchDates } from '../../../../../client/microapp-fbs.interfaces';
import { MicroappFbsResourceSlotModel } from '../../../../../client/dto/faciliti-slots';
import { MicroappFbsResourceModel } from '../../../../../client/dto/microapp-fbs-resource';
import { skipUntil } from 'rxjs/operators';
import {cloneDeep} from "lodash";
import {MatSelectionList} from "@angular/material/list";

@Component({
  selector: 'empusa-microapp-fbs-slots',
  templateUrl: './microapp-fbs-slots.component.html',
  styleUrls: ['./microapp-fbs-slots.component.css']
})
export class MicroappFbsSlotsComponent implements OnInit {
  @ViewChild('slotList') slotList: MatSelectionList;

  displayedColumns: string[] = ['time', 'status'];

  selected_period: SearchDates = new SearchDates;
  @Output() search: EventEmitter<SearchDates> = new EventEmitter();


  slots_to_show: MicroappFbsResourceSlotModel[] = [];

  constructor() { }

  @Input()
  set facility(facility: MicroappFbsResourceModel) {
    if (!facility.slots) {
      return;
    }
    this.slots_to_show = [];
    let lastSlot;

    const orderedSlots = cloneDeep(facility.slots).sort((slot1, slot2) => {
      return slot1.startDateTime.getTime() - slot2.startDateTime.getTime();
    });

    orderedSlots.forEach(slot => {
      if (slot && (slot.isBooked || slot.isReservable)){
        this.slots_to_show.push(slot);
      }
      if (slot) {
        lastSlot = slot;
      }
    });
    //Adding last slot to allow select last hour

    const lastSlotClone = cloneDeep(lastSlot);
    lastSlotClone.isReservable = true;

    // This will set last slot with same data that last - 1
    // lastSlotClone.isReservable = last(this.slots_to_show).isReservable;
    // lastSlotClone.reservation = last(this.slots_to_show).reservation;
    this.slots_to_show.push(lastSlotClone);
  }

  ngOnInit(): void {
  }

  onChangeSlot(value) {
    if (value.length > 1) {
      value.forEach(element => {
        if (!this.selected_period.searchStartDate || this.selected_period.searchEndDate) {
          this.selected_period.searchStartDate = element.value.startDateTime;
          this.selected_period.searchEndDate = undefined;
        } else {
          this.selected_period.searchEndDate = element.value.startDateTime;
          if (this.selected_period.searchEndDate < this.selected_period.searchStartDate) {
            const temp = this.selected_period.searchEndDate;
            this.selected_period.searchEndDate = this.selected_period.searchStartDate;
            this.selected_period.searchStartDate = temp;
          }
        }
      });
      if (this.selected_period.searchEndDate) {
        this.slotList.deselectAll();
        this.search.emit({ ...this.selected_period });
      }
    }
  }
}
