<div class="fondoGeneral">
  <mat-drawer-container class="drawContainer">
    <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
      <mat-card class="mc">
        <mat-card-content class="filtersTypeList"> {{'MAPP-FBS-MY-RESERVATIONS.FILTER' | translate}}</mat-card-content>
        <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
          <mat-icon class="closeIcFilter">close</mat-icon>
        </button>
      </mat-card>
      <mat-divider></mat-divider>
      <p></p>
      <div class="filtrosEmergentes">
        <mat-form-field>
          <input matInput (keyup)="applyGenericFilter($event.target.value)"
            placeholder="{{'MAPP-FBS-MY-RESERVATIONS.GENERIC_PLACE_HOLDER' | translate}}">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <p></p>
      </div>

    </mat-drawer>
    <div class="header">
      <empusa-microapp-fbs-tenant-select class="checkInHeader" (selectedTenant)="selectedTenant($event)">
      </empusa-microapp-fbs-tenant-select>
      <button mat-icon-button class="buttonExpandFil">
        <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
      </button>
    </div>


    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort id="tablaTariff" class="mat-elevation-z8"
      *ngIf="dataSource">

      <ng-container matColumnDef="chargeType">
        <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-ASSIGN.LIST.CHARGE' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.application_charge_code}} - {{element.description}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="tariff">
        <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-ASSIGN.LIST.TARIFF' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.tarif ? element.tarif.tarif_name : ''}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{'MAPP-FBS-ASSIGN.LIST.ACTIONS' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button matTooltip="{{'MAPP-FBS-TOOLTIP.ASSIGN.TARIFF' | translate}}" mat-icon-button
            (click)="the_tariffType_update(element)">
            <mat-icon class="icActions">edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row class="headerRowPri" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
    </table>
    <!-- <div class="button-newCharge">
      <button (click)="createOrEdit()" class="extend" mat-fab color="primary" aria-label="FAB new visit">
        <mat-icon>add </mat-icon>
        <span class='extend_text'>New Charge </span>
      </button>
    </div> -->
    <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
  </mat-drawer-container>
</div>