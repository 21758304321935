import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { MicroappVmsVisitVisitor } from '../../../lib/group-visit-manage/model/microapp-vms-manage-group-visit-visitor.model';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as newVisitActions from '../../../lib/group-visit-manage/store/microapp-vms-group-visit-manage.actions';
import * as addresBookActions from '../../../lib/address-book/store/microapp-vms-address-book.actions';
import { MicroappVmsNewVisitServices } from '../../../lib/services/microapp-vms-new-visit.services';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsVisitor } from '../../../lib/model/microapp-vms-visitor.model';
import { MatDialogRef } from '@angular/material/dialog';
import { selectAddressBookLoading, selectAddressBookError } from '../../../lib/address-book/store/microapp-vms-address-book-selectors';
import { VmsConfig } from '../../../lib/microapp-vms-config';
import { VehicleType } from '../../../lib/model/microapp-vms-vehicle';


@Component({
  selector: 'empusa-microapp-vms-visitor-form',
  templateUrl: './microapp-vms-visitor-form.component.html',
  styleUrls: ['./microapp-vms-visitor-form.component.css']
})
export class MicroappVmsVisitorFormComponent implements OnInit, OnDestroy {

  @Input() mode: string = "create-visit";
  @Input() visitor: MicroappVmsVisitor;

  //Store
  storeSuscripcion: Subscription;

  //Form
  formGroup: FormGroup;

  first_name = new FormControl();
  last_name = new FormControl();
  company = new FormControl();
  phone = new FormControl('', [Validators.pattern(this.vmsConfig.PHONE_PATTERN)]);
  email = new FormControl('', [Validators.required, Validators.email]);
  license_plate = new FormControl('', [Validators.minLength(this.vmsConfig.SIZE_LICENSE_PLATE_MIN), Validators.pattern('^[a-zA-Z0-9]*$')]);
  iu = new FormControl('', [Validators.minLength(this.vmsConfig.SIZE_IU_MIN)]);
  about = new FormControl();
  tenant_id: string;
  emailCheck: Subscription;
  currentEmailValue: string = "";
  emailValidated: boolean = false;
  loading$: Subscription;
  vehicle_type: string;
  available_vehicles: VehicleType[];


  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<any>,
    public vmsConfig: VmsConfig,
    private newVisitServices: MicroappVmsNewVisitServices) { }

  ngOnInit(): void {
    console.log("Visitor form mode:", this.mode);
    this.available_vehicles = this.vmsConfig.MASTER_CONFIG.available_vehicles;
    this.buildForm();
    this.formGroup.markAsDirty();
    this.vehicle_type = this.visitor && this.visitor.vehicle_type ? this.visitor.vehicle_type : "";
    // suscripcion al store

    switch (this.mode) {
      case 'create-visit': {
        this.storeSuscripcion = this.store.select('groupVisitManage').subscribe(datos => {
          if (datos && datos.visit)
            this.tenant_id = datos.visit.tenant_id;
        });
        break;
      }
      case 'address-book': {
        this.tenant_id = this.visitor.tenant_id;
        this.emailValidated = true;
        break;
      }
      case 'log-book': {
        //statements; 
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }


  onSubmit() {
    console.log("Submit");
    let newVisitor: MicroappVmsVisitVisitor | MicroappVmsVisitor;
    switch (this.mode) {
      case 'create-visit': {
        newVisitor = new MicroappVmsVisitVisitor();
        break;
      }
      case 'address-book': {
        newVisitor = new MicroappVmsVisitor();
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

    newVisitor.tenant_id = this.tenant_id;
    newVisitor.first_name = this.formGroup.controls['first_name'].value;
    newVisitor.last_name = this.formGroup.controls['last_name'].value;
    newVisitor.company = this.formGroup.controls['company'].value;
    newVisitor.email = this.formGroup.controls['email'].value;
    newVisitor.iu = this.formGroup.controls['iu'].value;

    if (this.formGroup.controls['phone'].value !== "") {
      newVisitor.phone = this.formGroup.controls['phone'].value;
    }
    if (this.formGroup.controls['license_plate'].value !== "") {
      newVisitor.license_plate = this.formGroup.controls['license_plate'].value;
    }
    if (this.formGroup.controls['about'].value !== "")
      newVisitor.about = this.formGroup.controls['about'].value;

    newVisitor.vehicle_type = this.vehicle_type;


    switch (this.mode) {
      case 'create-visit': {

        this.store.dispatch(newVisitActions.addingAParticipant({ visitor: newVisitor as MicroappVmsVisitVisitor }));
        break;
      }
      case 'address-book': {
        newVisitor.id = this.visitor.id;
        this.store.dispatch(addresBookActions.updateVisitor({ visitor: newVisitor as MicroappVmsVisitor }));

        let saving$ = this.store.pipe(select(selectAddressBookLoading)).subscribe(isLoading => {
          if (!isLoading) {
            this.store.pipe(select(selectAddressBookError)).subscribe(hayError => {
              this.dialogRef.close(hayError);
            });
            saving$.unsubscribe();
          }
        });
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

  }

  private buildForm() {
    this.formGroup = new FormGroup({
      first_name: this.first_name,
      last_name: this.last_name,
      company: this.company,
      phone: this.phone,
      email: this.email,
      iu: this.iu,
      license_plate: this.license_plate,
      about: this.about,
    });
    if (this.visitor) {
      this.formGroup.patchValue(this.visitor);
    }
    const mandatories = this.vmsConfig.MASTER_CONFIG.visitor_mandatory_fields;
    mandatories.forEach(field => {
      this.formGroup.controls[field].setValidators([Validators.required])
    })

  }

  public dynamicRequiredMark(field: string): string {
    const mandatories = this.vmsConfig.MASTER_CONFIG.visitor_mandatory_fields;
    if (mandatories.findIndex(a => a == field) == -1)
      return "";
    return "*";
  }

  getEmailErrorMessage() {
    if (this.formGroup.controls['email'].hasError('required')) {
      return 'MAPP-VMS-VISITOR-FORM.EMAIL-REQUIRED';
    }
    if (this.formGroup.controls['email'].hasError('repeated')) {
      return 'MAPP-VMS-VISITOR-FORM.EMAIL-REPEATED';
    }
    return this.formGroup.controls['email'].hasError('email') ? 'MAPP-VMS-VISITOR-FORM.EMAIL-NOT-VALID' : '';
  }


  onEmailKey() {
    this.emailValidated = false;
  }

  onEmailBlur() {
    if (!this.formGroup.controls['email'].valid) return;
    if (!this.tenant_id) return;
    if (this.currentEmailValue == this.formGroup.controls['email'].value) return;
    this.currentEmailValue = this.formGroup.controls['email'].value;
    if (this.emailCheck) {
      this.emailCheck.unsubscribe();
    }
    this.emailCheck = this.newVisitServices.getVisitorByTenantAndEmail(this.tenant_id, this.formGroup.controls['email'].value)
      .subscribe(visitorsFound => {
        if (visitorsFound && visitorsFound.length > 0) {
          console.log("Check email:", visitorsFound);
          switch (this.mode) {
            case 'create-visit': {
              this.formGroup.controls['email'].setErrors({ 'repeated': true });
              break;
            }
            case 'address-book': {
              //The email is from the actula user???
              const visitorFind = visitorsFound.find(eachVisitor => eachVisitor.id === this.visitor.id);
              if (!visitorFind) { //Not found so email is from other user
                this.formGroup.controls['email'].setErrors({ 'repeated': true });
              }
              break;
            }
            case 'log-book': {
              //statements; 
              break;
            }
            default: {
              //statements; 
              break;
            }
          }
        }
        else
          this.removeError(this.formGroup.controls['email'], 'repeated');
        this.emailValidated = true;
      }, error => {
        this.emailValidated = true;
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) { //Not found OK
            this.removeError(this.formGroup.controls['email'], 'repeated');
            return;
          }
        }
        console.error("Error emailExistCheck", error);
        this.formGroup.controls['email'].setErrors({ 'repeated': true });
      });
  }

  removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }


  onCancelAddVisitor($event) {
    $event.preventDefault();
    switch (this.mode) {
      case 'create-visit': {
        console.log("Cancel onCancelAddVisitor create-visit ", this.mode);
        this.store.dispatch(newVisitActions.hideNewParticipant());
        break;
      }
      case 'address-book': {
        console.log("Cancel onCancelAddVisitor mode address-book");
        this.dialogRef.close();
        break;
      }
      case 'log-book': {
        //statements;
        console.log("Cancel onCancelAddVisitor ", this.mode);
        break;
      }
      default: {
        //statements; 
        console.log("Cancel default ", this.mode);
        break;
      }
    }

  }


  ngOnDestroy(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
  }
  changeVehicle(type: string) {
    this.vehicle_type = type;
    this.updateVehicleValidations();
  }
  updateVehicleValidations() {
    if (this.vehicle_type === this.vmsConfig.VEHICLE_CAR || this.vehicle_type === this.vmsConfig.VEHICLE_HANDICAP) {
      this.formGroup.controls['iu'].setValidators([Validators.minLength(this.vmsConfig.SIZE_IU_MAX), Validators.maxLength(this.vmsConfig.SIZE_IU_MAX)]);

    } else if (this.vehicle_type === this.vmsConfig.VEHICLE_MOTORBIKE) {
      this.formGroup.controls['iu'].setValidators([Validators.minLength(this.vmsConfig.SIZE_IU_MIN), Validators.maxLength(this.vmsConfig.SIZE_IU_MIN)]);
    }
    this.formGroup.controls['iu'].updateValueAndValidity();
    this.formGroup.markAsDirty();
  }

}

