<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>
<div class="fondoGeneral">
    <mat-drawer-container class="drawContainer">
        <mat-drawer #drawer position="end" class="example-sidenav" mode="side">
            <form [formGroup]="filterGroup">
                <mat-card class="mc">
                    <mat-card-content class="filtersList">{{'MAPP-HVAC.FACILITY.FILTER' | translate}}
                    </mat-card-content>
                    <button mat-icon-button (click)="drawer.toggle()" class="closeFilter">
                        <mat-icon class="closeIcFilter">close</mat-icon>
                    </button>
                </mat-card>
                <mat-divider></mat-divider>
                <p></p>
                <div class="filtrosEmergentes">
                    <mat-form-field>
                        <input formControlName="GENERAL" matInput (keyup)="applyGenericFilter($event.target.value)"
                            placeholder="{{'MAPP-HVAC.FACILITY.FILTER' | translate}}">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>

                    <div class="divClear">
                        <button mat-button class="custom-form-content"
                            (click)=" resetFilters()">{{'MAPP-HVAC.GENERIC.CLEAN' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-drawer>
        <div class="header">
            <div class="checkInHeader">
                <form class="tenantForm" [formGroup]="tenantForm">
                    <mat-form-field class="margen_cajas">
                        <mat-label>Tenants</mat-label>
                        <mat-select formControlName="tenants">
                            <mat-option *ngFor="let tenant of tenantsToSelect" [value]="tenant.uid">
                                {{tenant.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="margen_cajas">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="FROM">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="margen_cajas">
                        <mat-label>To</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="TO">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <button [disabled]="!tenantForm.valid" mat-flat-button (click)="tenantSelected()"
                        class="buttonSearchTenant" color="primary">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
            <button mat-icon-button class="buttonExpandFil">
                <mat-icon class="expandFil" (click)="drawer.toggle()">tune</mat-icon>
            </button>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" id="tablaReservationTenat">
                <ng-container matColumnDef="user">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.USER' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.user}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.DATE' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.date | date: localDateFormat}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="extension_start">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.START' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.extension_start | date: 'shortTime' }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="extension_end">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.END' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.extension_end | date: 'shortTime'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="hours">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.HOURS' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.hours}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="devices">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'MAPP-HVAC.FACILITY.RESERVATIONS.DEVICES' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.devices}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns" class="headerRowPri"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></mat-row>
            </table>
        </div>
        <div class="footer-container">
            <mat-paginator class="paginator" [pageSizeOptions]="[20, 50, 10, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-drawer-container>
</div>