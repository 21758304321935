import { AccessGroup } from './accessGroup';
import { AccessRigths } from './accessRights';
import { Floor } from './floor';
import { Rol } from './rol';
import { Site } from './site';
import { Tenant } from './tenant';

export class User {
    uid: string;
    mail: string;
    userPassword: string;
    givenName: string;
    surname: string;
    accessCard:String;
    type:string;
    cn: string;
    dn: string;
    roles: Rol[];
    sites?: Site[];
    floor: Floor;
    tenants: Tenant[];
    accessRights: AccessRigths;
    default_visitor_host: boolean;
    accessGroups : AccessGroup;

    constructor() {
    }
}

​
