import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EmpusaQuestionDialog } from '../components/question/empusa-question.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [EmpusaQuestionDialog],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: []
})
export class EmpusaMicroAppModule { }
