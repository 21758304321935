import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicroappVmsVisitor } from '../model/microapp-vms-visitor.model';
import { VmsConfig } from '../microapp-vms-config';
import { map } from 'rxjs/operators';
import { MicroappVmsVisitLog } from '../model/microapp-vms-visit-log.model';

@Injectable({
    providedIn: 'root'
})
export class MicroappVmsNewVisitServices {

    forced_off_set = this.vmsConfig.MASTER_CONFIG.offset_to_utc;

    constructor(private http: HttpClient,
        private vmsConfig: VmsConfig,) { }


    getSingleVisitLog(visit_id: string): Observable<MicroappVmsVisitLog[]> {
        let parameters: string = this.vmsConfig.URL_REST_BASE_VISIT + '/' + visit_id + '/log';
        return this.http.get(parameters).pipe(
            map( (response) => response as MicroappVmsVisitLog[])
        );
    }

    getVisitorByTenantAndEmail(tenant_id: string, email: string): Observable<MicroappVmsVisitor[]> {
        let id = encodeURIComponent(tenant_id);
        let parameters: string = this.vmsConfig.URL_REST_BASE_VISITOR + '?tenant_id=["' + id + '"]&email=' + email;
        return this.http.get(parameters).pipe(map((response) => response as MicroappVmsVisitor[]));
    }

    postVisitReNotify(visit_id: string): Observable<null> {
        let parameters: string = this.vmsConfig.URL_REST_BASE_VISIT + '/' + visit_id + '/notify';
        parameters = parameters + "?off_set=";
        if (this.forced_off_set != null) {
            parameters = parameters + this.forced_off_set;
        } else {
            parameters = parameters + new Date().getTimezoneOffset();
        }
        return this.http.post(parameters,null).pipe(map(() => null ));
    }
}