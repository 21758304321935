import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { VmsFakeBackend } from '../fake/fake.backend'
import { MicroappVmsHomeModule } from '../pages/microapp-vms-home.module';
import { MICROAPP_VMS_NAME } from './microapp-vms-config';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappVmsModule implements EmpusaMicroApp {
  name: string = MICROAPP_VMS_NAME;
  description: string = "MAPP-VMS-HOME.APP-NAME";
  icon: string = "event";
  page = MicroappVmsHomeModule;
  homeApp: boolean = false;
  path: string = "vms";
  order: number = 6;
  fakebackend: EmpusaFakeBackend = new VmsFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }
}

