<h2 class="titleAssign">{{'MAPP-EPS.TARIFF.ASSIGNTARIFF' | translate}}</h2>
<form [formGroup]="newTariffForm">
    <div>
        <mat-form-field class="formFieldNew">
            <mat-label>{{'MAPP-EPS.TARIFF.ASSIGNTARIFF' | translate}}</mat-label>
            <mat-select required formControlName="tariff">
                <mat-option *ngFor="let tariff of assignTariffList" [value]="tariff.tarif_id">
                    <span *ngIf="tariff.tarif_id">
                        {{tariff.tarif_name}} - (${{tariff.total_price}})
                    </span>
                    <span *ngIf="tariff.tarif_id == 'unassign'">
                        {{tariff.tarif_name}}
                    </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
<div mat-dialog-actions class="buttonDetailCharge" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-EPS.TARIFF.CLOSE' | translate}}</button>
    <button [disabled]="!newTariffForm.valid" (click)="onSubmit()" class="buttonConfirm" mat-raised-button
        color="primary">
        {{'MAPP-EPS.TARIFF.ASSIGN' | translate}}</button>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>