import { animate, state, style, transition, trigger } from "@angular/animations";
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthenticationService, EmpusaQuestionDialog } from "@empusa/empusa-core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as microappEpsAppReducer from '../../../../lib/store/microapp-eps.app-reducer';
import { Reservation } from "../../../../lib/model/reservation/microapp-eps.reservation.model";
import { EpsServices } from "../../../../lib/services/microapp-eps.service";
import { ReservationsTenantFilter } from "../../tenant-reservation/microapp-eps.tenant-reservation-eps.componentfilter";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import { EpsTenantState } from "../../../../lib/store/tenant/microapp-eps-tenant.reducer";
import { MatTableExporterDirective } from "mat-table-exporter";

@Component({
    selector: 'microapp-eps-reservation-table-component',
    templateUrl: './microapp-eps.reservation-table.component.html',
    styleUrls: ['./microapp-eps.reservation-table.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
    ],
})



export class ReservationTableComponent implements OnChanges, OnDestroy, OnInit {

    @Input() dataToTableReservation: Reservation[];
    @Input() currentDateTime;
    @Input() downloadCsvRequestBoolean: boolean;
    @Input() filter;
    @Output() onModalSuccessfullyOperation = new EventEmitter<void>();
    @Output() countLots = new EventEmitter<CountLots>();
    @Output() tableExport = new EventEmitter<MatTableExporterDirective>()
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
    storeSubscription: Subscription;
    epsState: EpsTenantState;
    countNonEntitledReservations: number;
    countEntitledReservations: number;
    countNonEntitledLotsAssigned: number;
    countEntitledLotsAssigned: number;
    // countLotsInterface: CountLots;
    displayedColumns: string[] = ['vehicle_owner_name', 'vehicle_iu', 'license_plate', 'ReservationType', 'from_date', 'to_date', 'status', 'actions'];
    dataSource = new MatTableDataSource();
    loading: boolean = true;
    allRowsExpanded: boolean = false;
    isAdmin: boolean;
    expandedElement: Reservation;
    // exporter: MatTableExporterDirective;

    constructor(
        private auth: AuthenticationService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private epsServices: EpsServices,
        private datepipe: DatePipe,
        private snackBar: MatSnackBar,
        private store: Store<microappEpsAppReducer.MicroappEpsStates>
    ) {
        this.isAdmin = this.auth.hasCurrentUserRole('ADMIN');
        if (this.isAdmin) {
            this.displayedColumns.unshift('tenant_name')
        }
        this.storeSubscription = this.store.select('tenant').subscribe(data => {
            this.epsState = data;
        });

    }
    ngOnChanges(changes: SimpleChanges) {
        this.createDataTable(this.dataToTableReservation);
    }

    ngOnInit(): void {

    }

    createDataTable(data: Reservation[]) {
        if (!this.isAdmin && this.dataToTableReservation != undefined) {
            this.countEntitledReservations = 0;
            this.countNonEntitledReservations = 0;
            this.dataToTableReservation.forEach(dataReservation => {
                if (dataReservation.spot_type.spot_type_id == 1) {
                    this.countEntitledReservations += 1;
                } else {
                    this.countNonEntitledReservations += 1;
                }
            })
            let countLotsInterface: CountLots;
            countLotsInterface = {
                countEntitledLotsAssigned: 0,
                countEntitledReservations: this.countEntitledReservations,
                countNonEntitledLotsAssigned: 0,
                countNonEntitledReservations: this.countNonEntitledReservations
            }
            this.countLots.emit(countLotsInterface)
        };
        let tempDataSource = new MatTableDataSource(this.dataToTableReservation);
        this.loading = false;
        tempDataSource.sort = this.sort;
        tempDataSource.paginator = this.paginator;
        this.defineDataSourceFilter(tempDataSource);
        tempDataSource.filter = this.filter;
        // this.defineDataSourceFilter(tempDataSource);
        this.dataSource = tempDataSource;
        if(this.downloadCsvRequestBoolean == true){
            this.tableExport.emit(this.exporter);
        }
    }


    defineDataSourceFilter(tempDataSource: MatTableDataSource<any>) {
        tempDataSource.filterPredicate =
            (aGroup: Reservation, filter: string) => {
                const theFilter: ReservationsTenantFilter = JSON.parse(filter);
                let generalFilter = false;
                let vehicleTypeFilter = false;
                let expireDateFilter = false;
                let effectiveDateFilter = false;
                let stattusFilter = false;
                if (!theFilter) return true;

                //general Filter
                if (theFilter.general) {
                    if (aGroup.reservation_type.reservation_types_short_desc.toLowerCase().indexOf(theFilter.general) !== -1) {
                        generalFilter = true;
                    }
                    if (aGroup.vehicle_iu.toLowerCase().indexOf(theFilter.general) !== -1) {
                        generalFilter = true;
                    }
                    if (aGroup.license_plate.toLowerCase().indexOf(theFilter.general) !== -1) {
                        generalFilter = true;
                    }
                    if (aGroup.vehicle_owner_name.toLowerCase().indexOf(theFilter.general) !== -1) {
                        generalFilter = true;
                    }
                    // if (aGroup.status.toLowerCase().indexOf(theFilter.general) !== -1) {
                    //     generalFilter = true;
                    // }
                    if (aGroup.spot_type.spot_short_desc.toLowerCase().indexOf(theFilter.general) !== -1) {
                        generalFilter = true;
                    }
                } else generalFilter = true;

                //type filter
                if (theFilter.vehicleType) {
                    if (aGroup.vehicle_type.vehicle_code.toLowerCase().indexOf(theFilter.vehicleType) !== -1) {
                        vehicleTypeFilter = true;
                    }
                } else vehicleTypeFilter = true;

                if (theFilter.status) {
                    if(theFilter.status == "CANCELED"){
                        if (aGroup.status.toLowerCase().indexOf(theFilter.status && "EXPIRED") !== -1) {
                            stattusFilter = true;
                        }
                    }else{
                        if (aGroup.status.toLowerCase().indexOf(theFilter.status) !== -1) {
                            stattusFilter = true;
                        }
                    }
                   
                } else stattusFilter = true;

                const reservation_from_date = this.datepipe.transform(aGroup.from_date, "yyyyMMdd");
                const reservation_to_date = this.datepipe.transform(aGroup.to_date, "yyyyMMdd");

                if (theFilter.from_date) {
                    if (theFilter.from_date <= reservation_from_date) {
                        effectiveDateFilter = true;
                    }
                } else effectiveDateFilter = true;

                if (theFilter.to_date) {
                    if (theFilter.to_date >= reservation_to_date) {
                        expireDateFilter = true;
                    }
                } else expireDateFilter = true;

                return generalFilter && vehicleTypeFilter && stattusFilter && expireDateFilter && effectiveDateFilter;
            }

    }

    deleteReservation(uid: number) {
        let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
            data: {
                title: this.translate.instant('MAPP-EPS.DIALOG_CANCEL_RESERVATION.TITLE'),
                question: this.translate.instant('MAPP-EPS.DIALOG_CANCEL_RESERVATION.QUESTION'),
                icon: 'delete'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loading = true;
                this.epsServices.cancel_reservation(uid, new Date(), undefined).subscribe(data => {
                    // this.createDataTable(data);
                    this.onModalSuccessfullyOperation.emit();
                    this.loading = false;
                    this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.DELETE"),
                        this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGEDELETE"), {
                        duration: 5000,
                    });
                },
                    error => {
                        this.loading = false;
                        this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERRORDELETE"),
                            this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGEERRORDELTE"), {
                            duration: 5000,
                        });
                    }
                );
            }
        })
    }

    confirmReservation(uid: number) {
        let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
            data: {
                title: this.translate.instant('MAPP-EPS.TENANT.RESERVATIONTENANT.TITTLECONFIRM'),
                question: this.translate.instant('MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGECONFIRM'),
                icon: 'delete'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loading = true;
                this.epsServices.confirm_and_pay_reservations_by_id(uid).subscribe(data => {
                    this.onModalSuccessfullyOperation.emit();
                    // this.createDataTable(data);
                    // this.createDataTable(data);
                    this.loading = false;
                    this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.TITLECONFIRMED"),
                        this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGECONFIRMED"), {
                        duration: 5000,
                    });
                },
                    error => {
                        this.loading = false;
                        this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERRORCONFIRMED"),
                            this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERRORCMESSAGEONFIRMED"), {
                            duration: 5000,
                        });
                    }
                );
            }
        })
    }

    cancelRenewed(uid: number) {
        let dialogRef = this.dialog.open(EmpusaQuestionDialog, {
            data: {
                title: this.translate.instant('MAPP-EPS.DIALOG_CANCEL_RENEW.TITLE'),
                question: this.translate.instant('MAPP-EPS.DIALOG_CANCEL_RENEW.QUESTION'),
                icon: 'delete'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loading = true;
                this.epsServices.cancelRenewd(uid).subscribe(data => {
                    this.onModalSuccessfullyOperation.emit();
                    // this.viewReservation()
                    // this.createDataTable(data);
                    this.loading = false;
                    this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.TITLECANCELRENEW"),
                        this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.MESSAGECANCELRENEW"), {
                        duration: 5000,
                    });
                },
                    error => {
                        this.loading = false;
                        this.snackBar.open(this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERRORCANCELRENEW"),
                            this.translate.instant("MAPP-EPS.TENANT.RESERVATIONTENANT.ERRORCMESSAGECANCELRENEW"), {
                            duration: 5000,
                        });
                    }
                );
            }
        });
    }

    canDoActions(reservation: Reservation) {
        return this.epsState.masterConfig.can_manage_reservations_from_apps.filter(system => system === reservation.source_system).length > 0;
    }

    dateToTimezone(date: Date) {

        return date.toLocaleString('en-US');
    }

    ngOnDestroy(): void {
        if (this.storeSubscription) {
            this.storeSubscription.unsubscribe();
        }
    }

 
}

export interface CountLots {
    countNonEntitledReservations: number;
    countEntitledReservations: number;
    countNonEntitledLotsAssigned: number;
    countEntitledLotsAssigned: number;
}

