export  type ChargeToOption = 'Tenant' | 'User';

export class EpsParkingRequest {
    reservation_tariffication_type: string;
    license_plate: string;
    vehicle_type_code: string;
    spot_type_code: string;
    vehicle_iu: string;
    vehicle_owner_id: string;
    vehicle_owner_name: string;
    status: string;
    status_code: string;

    unit_size_desc: string;
    charge_type: string;
    price: number;
    units: number;
    unit_price: number;
    unit_size: string;
    available_to_book: boolean;
    spot_type_id: number;
    reservation_type_id: number;
    vehicle_type_id: number;
    message: string;
    reservation_id: number;
    
    charge_to: ChargeToOption;

    // Not set by backend
    vehicle_icon?: string;
}
