<h2 mat-dialog-title class="titleNewVisit">{{'MAPP-VMS-NEW-VISIT.TITLE' | translate}}
  <button mat-icon-button mat-dialog-close class="icClose">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content class="contentStep">
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step class="steperNewVisit">
      <ng-template matStepLabel>{{'MAPP-VMS-NEW-VISIT.STEP-1' | translate}}</ng-template>
      <div class="divSelect">
        <empusa-microapp-vms-select-participants class="selectParticipans"></empusa-microapp-vms-select-participants>
      </div>

      <div *ngIf="(displayNewVisit$ | async) === true" class="newVisitorDivision">
        <div class="container">
          <mat-label class="divisionsVisit">{{'MAPP-VMS-NEW-VISIT.NEW-VISITOR' | translate}}</mat-label>
          <div class="line">
            <mat-divider></mat-divider>
          </div>
        </div>
        <empusa-microapp-vms-visitor-form></empusa-microapp-vms-visitor-form>
      </div>

      <div mat-dialog-actions align="end" *ngIf="(displayNewVisit$ | async) === false" class="buttonsStep">
        <button class="buttonStepClose" mat-raised-button mat-dialog-close>{{'MAPP-VMS-NEW-VISIT.BUTTON-CANCEL' |
          translate}}</button>
        <button class="buttonStepNext" mat-raised-button matStepperNext color="primary" md-direction="right"
          [disabled]="!(thereAreParticipants$ | async)">{{'MAPP-VMS-NEW-VISIT.BUTTON-NEXT' | translate}}</button>
      </div>

    </mat-step>
    <mat-step>
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>{{'MAPP-VMS-NEW-VISIT.STEP-2' | translate}}</ng-template>
        <empusa-microapp-vms-schedule-visit></empusa-microapp-vms-schedule-visit>
        <div class="buttonsStep secondStepButtons">
          <button class="buttonStepBack" mat-button matStepperPrevious>
            <mat-icon>arrow_back</mat-icon> {{'MAPP-VMS-NEW-VISIT.BUTTON-BACK' | translate}}
          </button>
          <button class="buttonStepNext" mat-raised-button matStepperNext color="primary" md-direction="right"
            [disabled]="!(scheduleValid$ | async)">{{'MAPP-VMS-NEW-VISIT.BUTTON-NEXT' | translate}}</button>
          <button class="buttonStepClose" mat-raised-button mat-dialog-close>{{'MAPP-VMS-NEW-VISIT.BUTTON-CANCEL' |
            translate}}</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{'MAPP-VMS-NEW-VISIT.STEP-3' | translate}}</ng-template>
      <div>
        <p>{{'MAPP-VMS-NEW-VISIT-NEW-RESUME.INFO-TEXT' | translate}}</p>
      </div>
      <div>
        <div class="item2">
          <div class="item1">
            <div class="labelStepNewVisit">{{'MAPP-VMS-NEW-VISIT-NEW-RESUME.APPOINTMENT' | translate}}</div>
            <div>
              <mat-table class="tableStepNew" [dataSource]="visitDataSource">
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef>
                    {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.DATE' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let visit">{{visit.date | date:localDateFormat}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="start">
                  <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.START-TIME' | translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let visit">{{visit.start_time | date:'HH:mm'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="end">
                  <mat-header-cell *matHeaderCellDef> {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.END-TIME' | translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let visit">{{visit.end_time | date:'HH:mm'}} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsAppointment" class="columnNewVisit">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsAppointment;" matRipple class="rowNewVisit">
                </mat-row>
              </mat-table>
            </div>
          </div>
        </div>
        <div class="item2">
          <div class="item1">
            <div class="labelStepNewVisit">{{'MAPP-VMS-NEW-VISIT-NEW-RESUME.VISITORS' | translate}}</div>
            <p></p>
            <div class="cosa">
              <mat-table class="tableStepNew" [dataSource]="participantsDataSource">
                <ng-container matColumnDef="name">
                  <mat-cell class="cellStepName" *matCellDef="let participant">
                    <mat-icon> person</mat-icon>{{participant.first_name}} {{participant.last_name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                  <mat-cell class="cellStepCompany" *matCellDef="let participant"> {{participant.email}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="company">
                  <mat-cell class="cellStepCompany" *matCellDef="let participant"> {{participant.company}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="parking">
                  <mat-cell *matCellDef="let participant">
                    <mat-checkbox [disabled]="true" [checked]="participant.complimentary_parking">
                      <mat-icon class="icParkingKo"> local_parking</mat-icon>
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="rowVisitor">
                </mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>

      <div mat-dialog-actions class="buttonsStep">
        <div class="divButtonLeft">
          <button class="buttonStepBack" mat-button matStepperPrevious>
            <mat-icon>arrow_back</mat-icon> {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.BUTTON-BACK' | translate}}
          </button>
        </div>
        <div class="divButtonRight">
          <button class="buttonStepNext" (click)="onConfirm()" mat-raised-button color="primary">
            {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.BUTTON-CREATE' | translate}}</button>
          <button class="buttonStepClose" mat-raised-button mat-dialog-close>
            {{'MAPP-VMS-NEW-VISIT-NEW-RESUME.BUTTON-CANCEL' | translate}}</button>
        </div>
      </div>

    </mat-step>
  </mat-horizontal-stepper>

</mat-dialog-content>

<empusa-mat-spinner-overlay *ngIf="loading$ | async" overlay="true"></empusa-mat-spinner-overlay>