import { createAction, props } from '@ngrx/store';
import { MicroappVmsVisit } from '../../model/microapp-vms-visit.model';
import { MicroappVmsVisitor } from '../../model/microapp-vms-visitor.model';
import { User } from '@empusa/empusa-core';
import { MicroappVmsVisitorFilter } from '../../model/microapp-vms-visitor-filter.model';
import { MicroappVmsVisitorWithPendingVisits } from '../../model/microapp-vms-visitor-pending-visits.model';



export const resetAddressBook = createAction(
    '[Microapp vms Address Book] resetAddressBook');

export const searchingVisitors = createAction(
    '[Microapp vms Address Book] searchingVisitors',
    props<{ filter: MicroappVmsVisitorFilter, loggedUser: User }>());

export const storeTheVisitors = createAction(
    '[Microapp vms Address Book] searchedVisitors',
    props<{ visitors: MicroappVmsVisitorWithPendingVisits[] }>());

export const deleteVisitor = createAction(
    '[Microapp vms Address Book] deleteVisitor',
    props<{ visitor_id: number }>());

export const updateVisitor = createAction(
    '[Microapp vms Address Book] updateVisitor',
    props<{ visitor: MicroappVmsVisitor }>());

export const visitorUpdated = createAction(
    '[Microapp vms Address Book] visitorUpdated',
    props<{ visitors: MicroappVmsVisitor[] }>());

export const deletePendingVisit = createAction(
    '[Microapp vms Address Book]  deletePendingVisit',
    props<{ visitor_id: number, visit_id: string }>());

export const refreshVisit = createAction(
    '[Microapp vms Address Book]  refreshVisit',
    props<{ visit: MicroappVmsVisit }>());

export const error = createAction(
    '[Microapp vms Address Book]  error');

export const resetError = createAction(
    '[Microapp vms Address Book]  resetError');

