import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { TariffModule } from '../pages/microapp-tariff.home.module';

import { TariffFakeBackend } from '../fake/fake.backend';
import { locale as english } from '../translations/translation.en';
import { locale as spanish } from '../translations/translation.es';

export const MICROAPP_NAME: string = "tariff";
export const MICROAPP_ACCESS_TARIFF: string = "access";

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappTariffModule implements EmpusaMicroApp {
  name: string = MICROAPP_NAME;
  description: string = "MAPP-TARIFF.DESCRIPTION"
  icon: string = "account_balance";
  page = TariffModule;
  homeApp: boolean = false;
  path: string = "tariff";
  order: number = 10;
  fakebackend: EmpusaFakeBackend = new TariffFakeBackend();

  constructor(private translationLoader: TranslationLoaderService) {
    //we register ourselves
    (<any>window).empusa(this);
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
  }

}

