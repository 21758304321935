import { Component, OnInit, Input, Inject } from '@angular/core';
import { MicroappVmsVisit } from '../../../../lib/model/microapp-vms-visit.model';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MicroappVmsNewVisitServices } from '../../../../lib/services/microapp-vms-new-visit.services';
import { Store, select } from '@ngrx/store';
import * as vmsReducer from '../../../../lib/microapp-vms.reducer';
import * as logBookActions from '../../../../lib/logbook/store/microapp-vms-logbook.actions'
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { selectLogBookLoading, selectLogBookUpdateDone, selectLogBookMessageCode, getMasterConfig } from '../../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { MICROAPP_VMS_NAME, VmsConfig } from '../../../../lib/microapp-vms-config';
import { VehicleType } from '../../../../lib/model/microapp-vms-vehicle';
import { VmsMasterConfiguration } from '../../../../lib/model/microapp-vms-config.model';
import { AuthenticationService } from '@empusa/empusa-core';


@Component({
  selector: 'empusa-microapp-vms-edit-single-visit',
  templateUrl: './microapp-vms-edit-single-visit.component.html',
  styleUrls: ['./microapp-vms-edit-single-visit.component.css']
})
export class MicroappVmsEditSingleVisitComponent implements OnInit {

  visit: MicroappVmsVisit;

  //Store
  storeSuscripcion: Subscription;

  //Form
  formGroup: FormGroup;

  first_name = new FormControl();
  last_name = new FormControl();
  company = new FormControl();
  phone = new FormControl('', [Validators.pattern(this.vmsConfig.PHONE_PATTERN)]);
  email = new FormControl('', [Validators.required, Validators.email]);
  license_plate = new FormControl('', [Validators.minLength(this.vmsConfig.SIZE_LICENSE_PLATE_MIN), Validators.pattern('^[a-zA-Z0-9]*$')]);
  iu = new FormControl('', [Validators.minLength(this.vmsConfig.SIZE_IU_MIN)]);
  about = new FormControl();
  complimentary_parking = new FormControl();
  vehicle_type: string;

  emailCheck: Subscription;
  currentEmailValue: string = "";
  emailValidated: boolean = false;
  selectLogBookLoading$: Subscription;
  loading: boolean;
  masterCfg: VmsMasterConfiguration;
  can_request_parking: any;
  available_vehicles: VehicleType[];

  constructor(private store: Store<vmsReducer.MicroappVmsStates>,
    private translate: TranslateService,
    private authservice: AuthenticationService,
    private dialogRef: MatDialogRef<any>,
    private _snackBar: MatSnackBar,
    public vmsConfig: VmsConfig,
    private newVisitServices: MicroappVmsNewVisitServices,
    @Inject(MAT_DIALOG_DATA) data) {
    this.visit = data;
    this.store.select(getMasterConfig).pipe(take(1)).subscribe(masterCfg => {
      this.masterCfg = masterCfg;
    });
  }

  ngOnInit(): void {
    this.available_vehicles = this.vmsConfig.MASTER_CONFIG.available_vehicles;

    this.can_request_parking = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CAN_REQUEST_PARKING);

    this.buildForm();
    this.emailValidated = true;
    this.vehicle_type = this.visit && this.visit.vehicle_type ? this.visit.vehicle_type : '';
    this.formGroup.markAsDirty();
  }


  onSubmit() {
    console.log("Submit");
    let singleVisit = new MicroappVmsVisit();
    singleVisit.id = this.visit.id
    if (this.formGroup.controls['first_name'].value !== this.visit.first_name) {
      singleVisit.first_name = this.formGroup.controls['first_name'].value;
    }
    if (this.formGroup.controls['last_name'].value !== this.visit.last_name) {
      singleVisit.last_name = this.formGroup.controls['last_name'].value;
    }
    if (this.formGroup.controls['company'].value !== this.visit.company) {
      singleVisit.company = this.formGroup.controls['company'].value;
    }
    if (this.formGroup.controls['email'].value !== this.visit.email) {
      singleVisit.email = this.formGroup.controls['email'].value;
    }
    if (this.formGroup.controls['phone'].value !== this.visit.phone) {
      singleVisit.phone = this.formGroup.controls['phone'].value;
    }
    if (this.formGroup.controls['license_plate'].value !== this.visit.license_plate) {
      singleVisit.license_plate = this.formGroup.controls['license_plate'].value;
    }
    if (this.formGroup.controls['iu'].value !== this.visit.iu) {
      singleVisit.iu = this.formGroup.controls['iu'].value;
    }
    if (this.formGroup.controls['complimentary_parking'].value !== this.visit.complimentary_parking) {
      // if (this.formGroup.controls['iu'].value && this.formGroup.controls['iu'].value.length > 1) {
      singleVisit.complimentary_parking = this.formGroup.controls['complimentary_parking'].value;
      //}
    }
    if (this.formGroup.controls['about'].value !== this.visit.about) {
      singleVisit.about = this.formGroup.controls['about'].value;
    }

    singleVisit.vehicle_type = this.vehicle_type;

    console.log("update", singleVisit);
    this.store.dispatch(logBookActions.updateSingleVisit({ visit: singleVisit }));

    if (!this.selectLogBookLoading$)
      this.selectLogBookLoading$ = this.store.pipe(select(selectLogBookLoading))
        .subscribe(isLoading => {
          this.loading = isLoading;
          if (!isLoading) {
            this.store.pipe(select(selectLogBookUpdateDone)).pipe(take(1)).subscribe(uDone => {
              console.log("updateDone", uDone);
              if (uDone) {
                this.store.select(selectLogBookMessageCode).pipe(take(1)).subscribe(msg => {
                  console.log("msg", msg);
                  this.openSnackBar(msg);
                  this.store.dispatch(logBookActions.resetMessageCode());
                  this.dialogRef.close(singleVisit);
                });
              } else {
                this.dialogRef.close(null);
              }
            });
          }
        });

  }


  openSnackBar(msg) {
    const txt = this.translate.instant(msg);
    this._snackBar.open(txt, "X", {
      duration: 5000,
    });
  }

  private buildForm() {
    this.formGroup = new FormGroup({
      first_name: this.first_name,
      last_name: this.last_name,
      company: this.company,
      phone: this.phone,
      email: this.email,
      iu: this.iu,
      license_plate: this.license_plate,
      about: this.about,
      complimentary_parking: this.complimentary_parking
    });
    if (this.visit) {
      this.formGroup.patchValue(this.visit);
    }
    const mandatories = this.vmsConfig.MASTER_CONFIG.visitor_mandatory_fields;
    mandatories.forEach(field => {
      this.formGroup.controls[field].setValidators([Validators.required])
    });

  }

  public dynamicRequiredMark(field: string): string {
    const mandatories = this.vmsConfig.MASTER_CONFIG.visitor_mandatory_fields;
    if (mandatories.findIndex(a => a == field) == -1)
      return "";
    return "*";
  }

  getEmailErrorMessage() {
    if (this.formGroup.controls['email'].hasError('required')) {
      return 'MAPP-VMS-VISITOR-FORM.EMAIL-REQUIRED';
    }
    if (this.formGroup.controls['email'].hasError('repeated')) {
      return 'MAPP-VMS-VISITOR-FORM.EMAIL-REPEATED';
    }
    return this.formGroup.controls['email'].hasError('email') ? 'MAPP-VMS-VISITOR-FORM.EMAIL-NOT-VALID' : '';
  }


  onEmailKey() {
    this.emailValidated = false;
  }

  onEmailBlur() {
    if (!this.formGroup.controls['email'].valid) return;
    if (this.currentEmailValue == this.formGroup.controls['email'].value) return;
    this.currentEmailValue = this.formGroup.controls['email'].value;
    if (this.emailCheck) {
      this.emailCheck.unsubscribe();
    }
    this.emailCheck = this.newVisitServices.getVisitorByTenantAndEmail(this.visit.tenant_id, this.formGroup.controls['email'].value)
      .subscribe(visitorsFound => {
        if (visitorsFound && visitorsFound.length > 0) {
          //The email is from the actula user???
          const visitorFind = visitorsFound.find(eachVisitor => eachVisitor.id === this.visit.visitor_id);
          if (!visitorFind) { //Not found so email is from other user
            this.formGroup.controls['email'].setErrors({ 'repeated': true });
          }
        }
        else
          this.removeError(this.formGroup.controls['email'], 'repeated');
        this.emailValidated = true;
      }, error => {
        this.emailValidated = true;
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) { //Not found OK
            this.removeError(this.formGroup.controls['email'], 'repeated');
            return;
          }
        }
        console.error("Error emailExistCheck", error);
        this.formGroup.controls['email'].setErrors({ 'repeated': true });
      });
  }

  removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }


  onCancelAddVisitor($event) {
    $event.preventDefault();
    this.dialogRef.close();
  }

  onClose() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe();
    }
    if (this.selectLogBookLoading$) {
      this.selectLogBookLoading$.unsubscribe();
    }
  }
  changeVehicle(type: string) {
    this.vehicle_type = type;
    this.updateVehicleValidations();
  }
  updateVehicleValidations() {
    if (this.vehicle_type === this.vmsConfig.VEHICLE_CAR || this.vehicle_type === this.vmsConfig.VEHICLE_HANDICAP) {
      this.formGroup.controls['iu'].setValidators([Validators.minLength(this.vmsConfig.SIZE_IU_MAX), Validators.maxLength(this.vmsConfig.SIZE_IU_MAX)]);

    } else if (this.vehicle_type === this.vmsConfig.VEHICLE_MOTORBIKE) {
      this.formGroup.controls['iu'].setValidators([Validators.minLength(this.vmsConfig.SIZE_IU_MIN), Validators.maxLength(this.vmsConfig.SIZE_IU_MIN)]);
    }

    this.formGroup.controls['iu'].updateValueAndValidity();
    this.formGroup.markAsDirty();
  }


}
