import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { MicroappFbsUserModel } from '../client/dto/microapp-fbs-user';
import { select, Store } from '@ngrx/store';
import * as microappFbsState from '../lib/store/microapp-fbs-app-reducer';
import * as fbsActions from '../lib/store/microapp-fbs-actions';
import { pipe, Subscription } from 'rxjs';
import { anyServiceLoading, selectError, selectErrorMsg } from '../lib/store/microapp-fbs-selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { FbsConfig } from '../lib/microapp-facilitiesbooking-config';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MicroappFbsMyReservationsComponent } from './my-reservations/microapp-fbs-my-reservations.component';

@Component({
  selector: 'microapp-facilitiesbooking-facilitybooking',
  templateUrl: './microapp-facilitiesbooking.facilitybooking.component.html',
  styleUrls: ['microapp-facilitiesbooking.facilitybooking.component.css'],
})
export class MicroappFacilityBookingComponent implements OnInit, OnDestroy {
  fbsUserSubscription: Subscription;
  fbsUser: MicroappFbsUserModel;
  storeSubscription: Subscription;

  loading: boolean = false;
  siteName: string;
  anyServiceLoadingSubscription: Subscription;
  can_manage_resources: boolean;
  can_see_all_reservations: boolean;
  isSuperAdmin: boolean = false;
  isAdmin: boolean;
  can_access: boolean = false;

  @ViewChild('tabMyReservation') tabMyReservation;

  constructor(private authservice: AuthenticationService,
    private store: Store<microappFbsState.MicroappFbsStates>,
    private snackBar: MatSnackBar,
    private fbsConfig: FbsConfig,
    private translate: TranslateService) {
  }


  ngOnInit(): void {
    this.can_manage_resources = this.authservice.canUserExecute(this.fbsConfig.APPLICATION, this.fbsConfig.ROLE_RESOURCES_MANAGEMENT);
    this.can_see_all_reservations = this.authservice.canUserExecute(this.fbsConfig.APPLICATION, this.fbsConfig.ROLE_SEE_ALL_RESERVATIONS);
    this.can_access = this.authservice.canUserExecute(this.fbsConfig.APPLICATION, this.fbsConfig.ROLE_CAN_ACCESS);
    // this.siteName = this.authservice.getCurrentSite().name;

    this.loading = true;
    // this.authservice.loadCurrentSite().then(site => {
    //   this.loading = false;
    //   this.siteName = site.name;
    // });


    this.authservice.loadCurrentUser().then(user => {
      this.siteName = user.sites[0].name;
      this.loading = false;
      if (user) {
        this.store.dispatch(fbsActions.loadFbsUser({ user }));
      }
      this.storeSubscription = this.store.select('fbs').subscribe(data => {
        if (data && data.fbsUser) {
          if (!this.fbsUser || data.fbsUser.id != this.fbsUser.id) {
            this.store.dispatch(fbsActions.loadData());
          }
          this.fbsUser = data.fbsUser;
        }
      });

      this.isSuperAdmin = this.authservice.hasCurrentUserRole("SUPERADMIN");
      this.isAdmin = this.authservice.hasCurrentUserRole("ADMIN");

      this.anyServiceLoadingSubscription = this.store.pipe(select(anyServiceLoading)).subscribe(loading => {
        this.loading = loading;
      });

      this.anyServiceLoadingSubscription = this.store.pipe(select(selectError)).subscribe(error => {
        if (error)
          this.store.pipe(select(selectErrorMsg)).pipe(take(1)).subscribe(message => {
            if (message)
              this.showMessage(message)
          })
      });

    });


  }


  showMessage(messageCode: string) {
    this.snackBar.open(
      this.translate.instant(messageCode),
      "X"
      , {
        duration: 5000,
      });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {

    if (this.isAdmin) {
      if (tabChangeEvent.index === 0) {
        this.tabMyReservation.search_dates();
      }
    } else {
      if (tabChangeEvent.index === 1) {
        this.tabMyReservation.search_dates();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    };
    if (this.anyServiceLoadingSubscription) {
      this.anyServiceLoadingSubscription.unsubscribe();
    }
  }

}
