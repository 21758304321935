import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';




@Component({
    //selector: 'microapp-fittingout--fault-component',
    templateUrl: './microapp-fittingout.components-comment-fittingout.component.html',
    styleUrls: ['microapp-fittingout.components-comment-fittingout.component.css']
  })
  export class CommentComponent{
    comment: string; 
      constructor(public dialogRef:MatDialogRef<CommentComponent>,
        private translate: TranslateService){}
  
        onNoClick():void{
          this.dialogRef.close();
        }
  
  }
  