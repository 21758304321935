import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Survey } from '../../../client/dto/survey';
import { SurveyService } from '../../../client/survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import { EditParticipantComponent } from './participant/microapp-quiz.edit-participant.component';
import { EditQuestionComponent } from './question/microapp-quiz.edit-question.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Inject } from '@angular/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'microapp-quiz-survey-edit',
  templateUrl: './microapp-quiz.edit-survey.component.html',
  styleUrls: ['./microapp-quiz.edit-survey.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class EditSurveyComponent implements OnInit {

  //currentUser: User;

  /** form to validate */
  public surveyForm: FormGroup;
  /**the Survey being edited */
  loading: boolean;
  editSurvey: Survey = new Survey();
  matcher = new MyErrorStateMatcher();
  background: ThemePalette = 'primary';
  activa: boolean = false;

  /** Table of questions */
  displayedQuestionColumns: string[] = ['code', 'question', 'type', 'actions'];
  questions = new MatTableDataSource([]);
  countRowQuestion: number = -1;

  /** Table of participants */
  displayedParticipantColumns: string[] = ['firstname', 'lastname', 'email','tenant', 'actions'];
  participants = new MatTableDataSource([]);
  countRowParticipant: number = -1;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activatedRoute: ActivatedRoute, private surveyService: SurveyService,
    private snackBar: MatSnackBar, private dialogRef: MatDialogRef<EditSurveyComponent>, private translate: TranslateService, private router: Router,
    private formBuilder: FormBuilder, private location: Location, private auth: AuthenticationService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {
    this.editSurvey.sid = data;


    this.surveyForm = new FormGroup({
      title: new FormControl('', Validators.required),
      dateStart: new FormControl('', Validators.required),
      dateExpired: new FormControl('')
    });

    this.questions = new MatTableDataSource([]);
    this.questions.sort = this.sort;
    this.questions.paginator = this.paginator;

    this.participants = new MatTableDataSource([]);
    this.participants.sort = this.sort;
    this.participants.paginator = this.paginator;
   
    //getting the user info from backend
    // this.activatedRoute.params.subscribe(params => {
    //   let survey_id: string = params['id'];
    if (data) {
      this.loading = true;
      this.surveyService.getSurvey(data).subscribe(resp => {
        this.loading = false;
        this.editSurvey = resp;
        this.f.title.setValue(resp.title);

        var dateParts = resp.dateStart.split("/");
        this.f.dateStart.setValue(new Date(Date.UTC(+dateParts[2], dateParts[1] - 1, +dateParts[0], 0, 0, 1)));

        if (resp.dateExpired != '') {
          var dateParts = resp.dateExpired.split("/");
          this.f.dateExpired.setValue(new Date(Date.UTC(+dateParts[2], dateParts[1] - 1, +dateParts[0], 23, 23, 59)));
        }
        this.questions = new MatTableDataSource(resp.questions);
        this.participants = new MatTableDataSource(resp.participants);

        if (resp.active) {
          this.activa = true;
        }
      })
    }
    // });
  }

  ngOnInit() {

  }

  openDialogQuestion(sid?: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '720px';
    if (sid != null) {
      let data = this.questions.data.find(d => d.sid === sid);
      dialogConfig.data = {
        sid: data.sid,
        code: data.code,
        question: data.question,
        type: data.type,
        answers: data.answers
      };
    } else {
      this.countRowQuestion = this.countRowQuestion - 1;
      dialogConfig.data = {
        sid: this.countRowQuestion,
        code: '-----',
        question: '',
        type: '',
        answers: []
      };
    }

    const dialogRef = this.dialog.open(EditQuestionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null) {
          let index = this.questions.data.findIndex(d => d.sid === data.sid);
          if (index != -1) {
            this.questions.data[index].code = data.code;
            this.questions.data[index].question = data.question;
            this.questions.data[index].type = data.type;
            this.questions.data[index].answers = data.answers;
          } else {
            this.questions.data.push(data);
          }
          this.questions.filter = "";
          console.log("Dialog output:", data);
        }
      }
    );
    return;
  }

  deleteQuestion(sid: string) {
    let index = this.questions.data.findIndex(d => d.sid === sid);
    if (index != -1) {
      this.questions.data.splice(index, 1);
      this.questions.filter = "";
    }
  }

  openDialogParticipant(external: boolean, sid?: string, modalType?: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    if (sid != null) {
      let data = this.participants.data.find(d => d.sid === sid);
      dialogConfig.data = {
        //sid: data.sid,
        sid: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        external: external,
        new: false
      };
    } else {
      this.countRowParticipant = this.countRowParticipant - 1;
      dialogConfig.data = {
        //sid: this.countRowParticipant,
        sid: '',
        firstname: '',
        lastname: '',
        email: '',
        external: external,
        new: true,
        modalType: modalType
      };
    }

    const dialogRef = this.dialog.open(EditParticipantComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null) {
          data.forEach(element => {
            let index = this.participants.data.findIndex(d => d.sid === element.sid);
            if (index != -1) {
              this.participants.data[index].firstname = element.firstname;
              this.participants.data[index].lastname = element.lastname;
              this.participants.data[index].email = element.email;
            } else {
              this.participants.data.push(element);
            }
            this.participants.filter = "";
            console.log("Dialog output:", element);
          });
        }
      }
    );

    return;
  }

  deleteParticipant(sid: string) {
    let index = this.participants.data.findIndex(d => d.sid === sid);
    if (index != -1) {
      this.participants.data.splice(index, 1);
      this.participants.filter = "";
    }
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.surveyForm.controls; }

  /**
   * @name toBack
   * @description cancel the edition
   */
  toBack() {
    this.location.back();
  }

  /**
   * @name onSubmit
   * @description the info is being sent to the backend
   */
  onSubmit() {

    // stop here if form is invalid
    if (this.surveyForm.invalid) {
      return;
    }
    this.loading = true;
    this.editSurvey.title = this.f.title.value;
    this.editSurvey.dateStart = this.formatDate(this.f.dateStart.value);
    this.editSurvey.dateExpired = this.formatDate(this.f.dateExpired.value);
    this.editSurvey.participants = this.participants.data;
    this.editSurvey.questions = this.questions.data;
    if (this.editSurvey.sid) {
      //we are editting
      this.surveyService.updateSurvey(this.editSurvey).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-QUIZ.EDITSURVEY.OK.MESSAGE"),
            this.translate.instant("MAPP-QUIZ.EDITSURVEY.OK.TITLE"), {
            duration: 5000,
          });
          this.dialogRef.close(this.editSurvey);
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.snackBar.open(
            this.translate.instant("MAPP-QUIZ.EDITSURVEY.ERROR.MESSAGE"),
            this.translate.instant("MAPP-QUIZ.EDITSURVEY.ERROR.TITLE"),
            { duration: 5000 }
          );
        }
      );
    } else {
      //we are creating a new one
      this.surveyService.addSurvey(this.editSurvey).subscribe(
        data => {
          this.snackBar.open(this.translate.instant("MAPP-QUIZ.EDITSURVEY.OK.MESSAGE"),
            this.translate.instant("MAPP-QUIZ.EDITSURVEY.OK.TITLE"), {
            duration: 5000,
          });
          this.dialogRef.close(this.editSurvey);
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.snackBar.open(this.translate.instant("MAPP-QUIZ.EDITSURVEY.ERROR.MESSAGE"),
            this.translate.instant("MAPP-QUIZ.EDITSURVEY.ERROR.TITLE"), {
            duration: 5000,
          });
        }

      );
    }
  }
  // this.dialogRef.close(this.editParticipant);
  // 
  formatDate(date) {
    var formatDate = '';
    if (date != '') {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      formatDate = year + '-' + month + '-' + day;
    }

    return formatDate;
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
