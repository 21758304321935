import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../client/user.service';
import { RolService } from '../../../client/rol.service';
import { SiteService } from '../../../client/site.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService, User, Rol, Site, Tenant } from '@empusa/empusa-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThrowStmt } from '@angular/compiler';
import { Floor } from '@empusa/empusa-core/auth/floor';
import { TenantService } from '../../../client/tenant.service';
import { AccessGroup } from '@empusa/empusa-core/auth/accessGroup';
// import { AccessGroup } from '../../../../../../node_modules/@empusa/empusa-core/auth/accessGroup';

@Component({
    selector: 'microapp-usermanagement-building-edit',
    templateUrl: './microapp-usermanagement.edit-building.component.html',
    styleUrls: ['./microapp-usermanagement.edit-building.component.css']
})


export class EditBuildingComponent implements OnInit, OnDestroy {

    destroy$: Subject<null> = new Subject();

    currentUser: User;

    /** form to validate */
    public userForm: FormGroup;
    /**the user being edited */
    editUser: User = new User();
    roles: Rol[] = [];
    tenants: Tenant[] = [];
    matcher = new MyErrorStateMatcher();
    selectSite: number = 0
    selectTenant: number = 0
    viewSite: boolean = false;
    viewTenant: boolean = false;
    fileToUpload: File;
    kittyImagePreview: string | ArrayBuffer;
    isEdit: boolean;
    loading: boolean;
    url: any;
    viewPassword: boolean = false;
    accessList = [];
    fireTenant: Tenant;
    floorList: Floor[] = [];
    accessGroupList: AccessGroup[] = [];
    isSuperAdmin: boolean;
    editSuperAdmin: boolean = false;
    building: Site;
    showAccessGroup: boolean = false;

    @ViewChild('userPicture') userPictureImg;
    constructor(private activatedRoute: ActivatedRoute, private tenantService: TenantService,
        private userService: UserService,
        public dialogRef: MatDialogRef<EditBuildingComponent>,
        private snackBar: MatSnackBar, private translate: TranslateService, private router: Router,
        private formBuilder: FormBuilder, private location: Location, private rolService: RolService,
        private siteService: SiteService, private auth: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public ticket_id: string,
        @Inject('environment') private environment) {

        this.userForm = this.formBuilder.group({
            photo: [],
            email: ['', [Validators.required, Validators.email]],
            password: [''],
            firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Z.\u00f1\u00d1-]+(?: [a-zA-Z.\u00f1\u00d1-]+)*$')]],
            lastname: ['', [Validators.required, Validators.pattern('^[a-zA-Z.\u00f1\u00d1-]+(?: [a-zA-Z.\u00f1\u00d1-]+)*$')]],
            rol: ['', Validators.required],
            site: [],
            access: ['', Validators.required],
            tenant: [''],
            floor: ['', Validators.required],
            host: [''],
            accessGroup: ['', Validators.required],
            nocharge: [false]
        });

        this.showAccessGroup = this.environment.show_acess_group;

        this.url = "assets/img/default_user.png";
        this.isSuperAdmin = this.auth.hasCurrentUserRole("SUPERADMIN");
        auth.getUser().then(user => {
            this.currentUser = user;

            //getting all the selectable roles
            this.rolService.allRol().subscribe(resp => {

                resp.forEach(element => {
                    if (element.name != "MANAGEMENT") {
                        this.roles.push(element)
                    }
                });
            });

            this.currentUser.roles.forEach(rol => {
                if (rol.name === 'SUPERADMIN') {
                    this.viewPassword = true
                };
            });
            this.fireTenant = this.currentUser.sites![0].tenants![0];
            this.accessList = this.fireTenant.accessRights;
            this.floorList = this.fireTenant.floor;
            this.floorList.sort((a, b) =>
                a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 :
                    a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase() ? -1 :
                        0
            );
            if (this.showAccessGroup) {
                this.accessGroupList = this.fireTenant.accessGroups;
            }

            let user_id: string = ticket_id;
            if (user_id) {
                //we are editting
                this.loading = true;
                this.userService.getUser(user_id).subscribe(resp => {
                    let user: User = resp;
                    this.userService.getPhoto(user).subscribe(data => {
                        this.loading = false;
                        this.url = 'data:image/jpeg;base64,' + arrayBufferToBase64(data);
                    });
                    this.loading = false;
                    this.editUser = resp;
                    this.f.firstname.setValue(resp.givenName);
                    this.f.lastname.setValue(resp.surname);
                    this.f.host.setValue(resp.default_visitor_host);
                    this.f.email.setValue(resp.mail);
                    if (resp.roles != null) {
                        let id_roles: string[] = [];
                        for (let i = 0; i < resp.roles.length; i++) {
                            id_roles.push(resp.roles[i].uid);
                            if (resp.roles[i].name == "SUPERADMIN") {
                                this.editSuperAdmin = true;
                            }
                        }
                        this.f.rol.setValue(id_roles);
                    }
                    if (resp.accessRights != null) {
                        if (this.editSuperAdmin == true) {
                            this.f.access.setValue("---")
                        } else {
                            this.f.access.setValue(resp.accessRights.AccessRightId);
                        }
                    }
                    if (this.editSuperAdmin == true) {
                        this.f.floor.setValue("---");
                    } else {
                        this.f.floor.setValue(resp.floor.HomeFloorId);
                    }
                    if (this.showAccessGroup) {
                        if (resp.accessGroups != null) {
                            if (this.editSuperAdmin == true) {
                                this.f.access.setValue("---")
                            } else {
                                this.f.accessGroup.setValue(resp.accessGroups.AccessGroupId);
                            }
                        }
                    } else {
                        this.f.accessGroup.setValue('no');
                    }

                    if (resp.sites != null) {
                        this.viewSite = true;
                        this.f.site.setValue(resp.sites[0].uid);
                        if (resp.sites[0].tenants.length > 0) {
                            this.viewTenant = true;
                            this.f.tenant.setValue(resp.sites[0].tenants[0].uid);
                        }
                    }
                });
            } else {
                //we are adding a new user
                this.editUser = new User();
                this.f.host.setValue(false);
                this.f.site.setValue(this.currentUser.sites[0].uid);
                let accessGrouInit = "";
                if (!this.showAccessGroup) {
                    accessGrouInit = "no"
                }
                this.f.accessGroup.setValue(accessGrouInit);
                this.viewSite = true;
                this.viewTenant = true;
            }
        });

        // });
    }

    ngOnInit() {
        this.userForm.get('photo')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((newValue) => {
                this.handleFileChange(newValue.files);
            });
    }

    ngOnDestroy() {
        this.destroy$.next(null);
    }

    // convenience getter for easy access to form fields
    get f(): any { return this.userForm.controls; }

    /**
     * @name toBack
     * @description cancel the edition
     */
    toBack() {
        this.location.back();
    }

    /**
     * @name findRolByName
     * @description find a rol in the list of roles by its uid
     * @param {number} uid the name of the rol to find
     * @return {Rol} the rol found or null
     */
    findRolById(uid: string[]) {
        let rols: Rol[] = [];
        for (let i = 0; i < this.roles.length; i++) {
            let rol = this.roles[i];
            for (let j = 0; j < uid.length; j++) {
                if (rol.uid == uid[j]) {
                    rols.push(rol);
                }
            }
        }
        return rols;
    }

    /**
     * @name findSiteById
     * @description find a site in the list of Sites by its uid
     * @param {string} uid the name of the rol to find
     * @return {Site} the rol found or null
     */
    // findTenantById(uid: string) {
    //     for (let i = 0; i < this.tenants.length; i++) {
    //         let tenant: Tenant = this.tenants[i];
    //         if (tenant.uid == uid) {
    //             return tenant;
    //         }
    //     }
    //     return null;
    // }

    onSelectRol(event) {
        if (event.source.value != "SUPERADMIN") {
            if (event.source.selected) {
                this.selectSite++;
            } else {
                this.selectSite--;
            }
            if (event.source.value != "ADMIN") {
                if (event.source.selected) {
                    this.selectTenant++;
                } else {
                    this.selectTenant--;
                }
            }
        }

        if (this.selectSite != 0) {
            this.viewSite = true;
        } else {
            this.viewSite = false;
        }

        if (this.selectTenant != 0) {
            this.viewTenant = true;
        } else {
            this.viewTenant = false;
        }
    }

    handleFileChange([kittyImage]) {
        this.fileToUpload = kittyImage;
        const reader = new FileReader();
        reader.onload = (loadEvent) => (this.kittyImagePreview = loadEvent.target.result);
        reader.readAsDataURL(kittyImage);
    }

    /**
     * @name onSubmit
     * @description the info is being sent to the backend
     */
    onSubmit() {
        // stop here if form is invalid
        if (this.userForm.invalid) {
            return;
        }
        this.loading = true;
        this.editUser.userPassword = this.f.password.value;
        this.editUser.givenName = this.f.firstname.value;
        this.editUser.givenName = this.editUser.givenName.trim();
        this.editUser.surname = this.f.lastname.value;
        this.editUser.default_visitor_host = this.f.host.value;
        this.editUser.surname = this.editUser.surname.trim();
        this.editUser.mail = this.f.email.value;
        this.editUser.mail = this.editUser.mail.toLowerCase();
        this.editUser.floor = this.f.floor.value;
        this.editUser.accessRights = this.f.access.value;
        if (this.showAccessGroup) {
            this.editUser.accessGroups = this.f.accessGroup.value;
        }
        if (typeof this.kittyImagePreview === 'string') {
            let image: string = this.kittyImagePreview;
            if (image.length > 100) {
                let n = image.indexOf(",") + 1;
                this.editUser['photo'] = image.substr(n);
            }
        }
        this.editUser.roles = this.findRolById(this.f.rol.value);
        this.editUser.sites = [];
        this.editUser.sites.push(this.currentUser.sites[0]);
        this.editUser.sites[0].tenants.push(this.currentUser.sites[0].tenants[0]);
        if (this.editUser.uid) {
            //we are editting      
            console.log("this.editUser", this.editUser)
            this.userService.updateUser(this.editUser, this.editUser.sites[0].dn).subscribe(
                data => {
                    this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.MESSAGE"),
                        this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.TITLE"), {
                        duration: 5000,
                    });
                    this.loading = false;
                    this.dialogRef.close(this.editUser);
                },
                error => {
                    this.loading = false;
                    this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.MESSAGE"),
                        this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                        duration: 5000,
                    });
                });
        } else {
            //we are creating a new one
            this.userService.addUser(this.editUser, this.f.nocharge.value).subscribe(
                data => {
                    this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.MESSAGE"),
                        this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.TITLE"), {
                        duration: 5000,
                    });
                    this.loading = false;
                    this.dialogRef.close(this.editUser);
                },
                error => {
                    this.loading = false;
                    if (error.error.http_status === 409 && error.error.error_code) {
                        if (error.error.error_code === 'USRMNGMT-USER_ALREADY_EXISTS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.USER-ALREADY-EXISTS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-GROUPS_NOT_EXISTS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.GROUPS-NOT-EXISTS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });

                        } else if (error.error.error_code === 'USRMNGMT-ROLES_NOT_EXISTS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.ROLES-NOT-EXISTS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        }
                    } else if (error.error.http_status === 403 && error.error.error_code) {
                        if (error.error.error_code === 'USRMNGMT-INVALID_PERMISSIONS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.INVALID-PERMISSIONS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        }
                    } else if (error.error.http_status === 400 && error.error.error_code) {
                        if (error.error.error_code === 'USRMNGMT-CANT_GET_FLOOR_INFO') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.CANT-GET-FLOOR"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-CANT_GET_ACCESS_RIGHTS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.CANT-GET-ACCESS-RIGHTS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-LDAP_ERROR') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.LDAP-ERROR"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-NO_TARIFFS') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.NO-TARIFFS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-DUPLICATED_USER') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.DUPLICATED-USER"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-UNKNOWN') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.UNKNOWN"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-CANT_SET_STAFF_EACS_INFO') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.CANT-SET.STAFF-EACS"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        } else if (error.error.error_code === 'USRMNGMT-CANT_GENERATE_CHARGE') {
                            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.ERROR.CANT-GENERATE-CHARGE"),
                                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                                duration: 5000,
                            });
                        }
                    } else {
                        this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.MESSAGE"),
                            this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                            duration: 5000,
                        });
                    }
                });
        }
    }

    readUrl(event: any) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
                this.url = event.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

}


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
