import { FacilityBookingModule } from './../pages/microapp-facilitiesbooking.facilitybooking.module';
import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaFakeBackend, TranslationLoaderService } from '@empusa/empusa-core';
import { FbsFakeBackend } from '../fake/fake.backend';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../translations/translation.en';
//import { locale as spanish } from '../translations/translation.es';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MicroappFacilitiesbookingModule implements EmpusaMicroApp {
  name: string = "fbs";
  // description: string = this.translate.instant('MAPP-FBS-HOME.APP-NAME');
  description: string = 'MAPP-FBS-HOME.APP-NAME';
  icon: string = "book";
  page = FacilityBookingModule;
  homeApp: boolean = false;
  path: string = "facilities";
  order: number = 4;
  fakebackend: EmpusaFakeBackend = new FbsFakeBackend();

  constructor(private translationLoader: TranslationLoaderService, private translate: TranslateService) {
    //we register ourselves
    (<any>window).empusa(this);
    this.translationLoader.loadTranslations(english);
  }
}
