
export class MicroappFbsReservationSlotModel {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userId:number;
    referenceNumber: string;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    resourceId: number;
}