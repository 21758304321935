import { Participant } from './participant';
import { Question } from './question';

export class Survey {
    sid: string;
    title: string;
    dateExpired: string;
    dateStart: string;
    state: number;
    questions: Question[];
    participants: Participant[];

    constructor() {
    }
}
