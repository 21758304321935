import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as microappEpsAppReducer from '../../../lib/store/microapp-eps.app-reducer';
import * as epsActions from '../../../lib/store/microapp-eps.actions';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EpsServices } from '../../../lib/services/microapp-eps.service';
import { EpsAssignedChargeType } from '../../../lib/model/charges_and_tariffs/microapp-eps.assigned-charge-types.model';
import { EpsSpotType } from '../../../lib/model/microapp-eps.spot-type.model';
import { EpsReservationType } from '../../../lib/model/reservation/microapp-eps.reservation-type.model';
import { EpsVehicleType } from '../../../lib/model/microapp-eps.vehicle-type.model';
import { MatTableDataSource } from '@angular/material/table';
import { Tenant } from '@empusa/empusa-core';
import { EpsTenantTariff } from '../../../lib/model/charges_and_tariffs/microapp-eps.tenant-tariff.model';
import { EpsChargeType } from '../../../lib/model/charges_and_tariffs/microapp-eps.charge-type.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'empusa-microapp-eps-tariffs',
  templateUrl: './microapp-eps-tariffs.component.html',
  styleUrls: ['./microapp-eps-tariffs.component.css']
})
export class MicroappEpsTariffsComponent implements OnInit, OnDestroy {
  storeSubscription: Subscription;
  loading: boolean;
  assignedCharges: EpsAssignedChargeType[];
  spotTypes: EpsSpotType[];
  vehicleType: EpsVehicleType[];
  reservationType: EpsReservationType[];
  tenantTariffs: EpsTenantTariff[];


  newSelectTariffForm: FormGroup;
  subSpot$: Subscription;
  subReservation$: Subscription;

  selectedSpotType: number;
  selectedReservationType: number;
  tenatsDataSource: MatTableDataSource<Tenant>

  selectedSpotTypeName: string;
  selectedReservationTypeName: string;

  tenantTariffsDataSource: MatTableDataSource<EpsTenantTariff>;
  displayedColumns: string[] = ['tenant_name']
  chargesAndTariffs: EpsChargeType[];

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.tenantTariffsDataSource) {
      this.tenantTariffsDataSource.paginator = mp;
    }
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (this.tenantTariffsDataSource) {
      this.tenantTariffsDataSource.sort = ms;
    }
  }


  constructor(private epsServices: EpsServices,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: Store<microappEpsAppReducer.MicroappEpsStates>,
  ) {

    this.newSelectTariffForm = this.formBuilder.group({
      spot: ["1"],
      reservation: ['1'],
    });
    this.subSpot$ = this.f.spot.valueChanges.subscribe(newValue => {
      this.changeSpotType(newValue)
    })
    this.subReservation$ = this.f.reservation.valueChanges.subscribe(newValue => {
      this.changeReservationType(newValue)
    })
  }

  get f() { return this.newSelectTariffForm.controls; }

  ngOnInit(): void {
    this.storeSubscription = this.store.select('eps').subscribe(data => {
      this.loading = data.loading;
      if (data && data.tenantTariffs) {
        this.tenantTariffs == data.tenantTariffs;
        this.tenantTariffsDataSource = new MatTableDataSource(data.tenantTariffs);
        this.customSort();
      }
      if (data && data.chargesAndTariffs) {
        this.chargesAndTariffs = data.chargesAndTariffs;
      }
      if (data && data.assignedCharges) {
        this.assignedCharges = data.assignedCharges;
        let spot_types: EpsSpotType[] = [];

        for (var charge of data.assignedCharges) {
          // show only types with chage type
          if (!(charge.charge_type && charge.charge_type.length > 0)) {
            continue
          }
          let exist = spot_types.findIndex(st => st.spot_type_id == charge.spot_type.spot_type_id);
          if (exist == -1) {
            spot_types.push({ ...charge.spot_type });
          }
        }
        this.spotTypes = spot_types;
      }
    });
  }

  customSort() {
    this.tenantTariffsDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'tenant_name': {
          let data = ""
          if (item.tenant.name)
            data = item.tenant.name;
          return data.toLowerCase().trim();
        }
        default: {
          return item[property];
        }
      }
    };
  }

  sortDataTableObject(data: EpsTenantTariff[], sort: MatSort): EpsTenantTariff[] {
    if (!data) {
      return [];
    }
    if (sort.active === 'tenant_name' && sort.direction === "asc") {
      return data.sort((a, b) => {
        return a.tenant.name.localeCompare(b.tenant.name)
      })

    } else if (sort.active === 'tenant_name' && sort.direction === "desc") {
      return data.sort((a, b) => {
        return b.tenant.name.localeCompare(a.tenant.name)
      })

    } else if (sort.active === 'tenant_name' && sort.direction === "") {
      return data
    }
  }

  changeSpotType(selectedSpotTypeId: number) {
    let reservation_type: EpsReservationType[] = [];
    for (var charge of this.assignedCharges) {
      // show only types with chage type
      if (!(charge.charge_type && charge.charge_type.length > 0)) {
        continue
      }
      if (charge.spot_type.spot_type_id != selectedSpotTypeId) {
        continue
      }
      const exist = reservation_type.findIndex(rt => rt.reservation_type_id == charge.reservation_type.reservation_type_id);
      if (exist == -1) {
        reservation_type.push({ ...charge.reservation_type });
        this.selectedSpotTypeName = charge.spot_type.spot_short_desc;
      }
    }
    this.reservationType = reservation_type;
    this.vehicleType = [];
  }

  changeReservationType(selectedReservationTypeId: number) {
    this.displayedColumns = ['tenant_name'];
    let vehicle_type: EpsVehicleType[] = [];
    for (var charge of this.assignedCharges) {
      // show only types with chage type
      if (!(charge.charge_type && charge.charge_type.length > 0)) {
        continue
      }
      if (charge.reservation_type.reservation_type_id !== selectedReservationTypeId) {
        continue
      }

      const exist = vehicle_type.findIndex(vt => vt.vehicle_type_id == charge.vehicle_type.vehicle_type_id);
      if (exist == -1) {
        vehicle_type.push({ ...charge.vehicle_type });
        this.selectedReservationTypeName = charge.reservation_type.reservation_types_short_desc;

      }
    }

    if (vehicle_type.length > 0) {
      this.vehicleType = vehicle_type;
      for (var vehicle of this.vehicleType) {
        this.displayedColumns.push('Vehicle' + vehicle.vehicle_type_id);
      }
    }
  }

  findTenantTariff(tenantTariff: EpsTenantTariff, vehicle_type_id: number) {
    for (var charge of this.assignedCharges) {
      if (charge.vehicle_type.vehicle_type_id == vehicle_type_id &&
        charge.reservation_type.reservation_type_id == this.selectedReservationType &&
        charge.spot_type.spot_type_id == this.selectedSpotType) {

        const chargetype = charge.charge_type;

        const sel_charge = tenantTariff.charge_types.find(one =>
          one.application_charge_code == chargetype
        )
        if (sel_charge && sel_charge.tarif && sel_charge.tarif.tarif_name &&
          sel_charge.tarif.tarif_name.length > 0) {
          return sel_charge.tarif.tarif_name + " - ($" + sel_charge.tarif.total_price + ")";
        }

        return "--";
      }
    }
    return "--";
  }


  searchText(text: string) {
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

}
