<div class="container">
  <h1>
    {{'MAPP-EPS.GENERIC.CHARGETO.TENANT' | translate}}
  </h1>
    <empusa-mini-reservations-invoice [mode]="mode" [showTotals]='true' *ngIf="requestsForInvoiceYardi" [showActions]=false [rawRequests]="requestsForInvoiceYardi">
    </empusa-mini-reservations-invoice>
  </div>
  <div class="separator"></div>
  <div class="container" *ngIf="paypalEnabled && mode == 'employee'"> 
    <h1>
      {{'MAPP-EPS.GENERIC.CHARGETO.EMPLOYEE' | translate}}
    </h1>
    <empusa-mini-reservations-invoice [mode]="mode" *ngIf="requestsForInvoicePaypal && mode == 'employee'" [showActions]=false [rawRequests]="requestsForInvoicePaypal"></empusa-mini-reservations-invoice>
  </div>
  