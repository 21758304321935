export const locale = {
  "lang": "en",
  "data": {
    "MAPP-FBS-HOME": { //mandatory - microapp context
      "APP-NAME": "Facilities booking",
    },
    "MAPP-FBS-MENU": { //mandatory - microapp context
      "FACILITY_LIST": "Resources",
      "MY_BOOKINGS": "My Bookings",
      "ALL_BOOKINGS": "Bookings",
      "MANAGEMENT": "Resource Management",
      "TARIFFS":"Tariffs Assignment",
    },
    "MAPP-FBS-TOOLTIP": {
      "RESERVATION.EDIT": "Edit Reservation",
      "RESERVATION.DELETE": "Delete Reservation",
      "RESOURCE.DETAIL": "Detail Resource",
      "RESOURCE.AVALIBLRE": "Avaliable Resource",
      "RESOURCE.UNAVALIBLE": "Error in Resource",
      "RESOURCE.CANCEL": "Unavailable Resource",
      "MANAGEMENT.EDIT": "Edit Resource",
      "MANAGEMENT.DELETE": "Delete Resource",
      "MANAGEMENT.DETAIL": "Detail Resource",
      "MANAGEMENT.AVALIBLRE": "Set Avaliable",
      "MANAGEMENT.UNAVALIBLE": "Set Unavailable",
      "ASSIGN.TARIFF":"Assign Type Tariff",
    },
    "MAPP-FBS-GROUP-CAPACITY-PIPE":{
      "MIX": "Can book %3 of %2",
      "NO_UNITS_AVAILABLE":"No units available",
      "PARTICIPANT":"1 person",
      "PARTICIPANTS":"%1 participants",
      "UNITS":"%2 Units",
    },
    "MAPP-FBS-FACILITY-LIST": { //mandatory - microapp context
      "accessory": "Accessory",
      "facility": "Facility",
      "groupfacility": "Grouped facilities",
      "ACTIONS": "Actions",
      "CAPACITY":"Capacity / Units",
      "DESCRIPTION": "Description",
      "LOCATION": "Location",
      "RESOURCE":"Resource",
      "TYPE":"Type",
      "GROUPED_FACILITIES_TITLE": "Facilities in this group:",
    },
    "MAPP-FBS-CHECK_AVAILABILITY":{
      "CHECK":"Check Availability",
      "DATE":"Date",
      "FROM":"From",
      "TO":"To",
      "NOPERIOD": "The reservation has to be for hours"
    },
    "MAPP-FBS-MY-RESERVATIONS":{
      "COLLAPSE":"Collapse all",
      "DATE":"Date",
      "DATE_FROM":"Date from",
      "DATE_TO":"Date to",
      "DATE_TO_ERROR": "Select a valid date",
      "DESCRIPTION": "Description",
      "DETAILS":"Details",
      "EXPAND":"Expand all",
      "FILTER":"Filter",
      "FROM":"From",
      "GENERIC_PLACE_HOLDER":"Search",
      "RESOURCE":"Resource",
      "RESOURCE_TYPE":"Resource Type",
      "TITLE":"Title",
      "TO":"To",
      "TOTAL_COST": "Cost",
      "OWNER":"Owner",
      "TENANT":"Tenant"

    },
    "MAPP-FBS-MANAGEMENT":{
      "accessory": "Accessory",
      "facility": "Facility",
      "groupfacility": "Grouped facilities",
      "ACTIONS": "Actions",
      "CAPACITY":"Capacity / Units",
      "DESCRIPTION": "Description",
      "LOCATION": "Location",
      "NEW-FACILITY":"Facility",
      "NEW-ACCESSORY":"Accessory",
      "NEW-FACILITY-GROUP":"Grouped facilities",
      "RESOURCE": "Resource",
      "STATUS": "Status",
      "TYPE": "Type",
    },
    "MAPP-FBS-ASSIGN":{
      "LIST.CHARGE": "Charge Type",
      "LIST.TARIFF":"Tariff Type",
      "LIST.ACTIONS":"Actions"
    },
    "MAPP-FBS-ASSIGN-UPDATE":{
      "CHARGE": "Charge Type",
      "TARIFF":"Tariff Type",
      "ACTIONS":"Actions",
      "TITLE":"Assign Tariff Type",
      "MESSAGE":"Assign a tariff type to",
      "CLOSE":"Close",
      "ASSIGN":"Assign",
      "OK.MESSAGE":"OK",
      "OK.TITLE":"Assign Tariff Type Success",
      "ERROR.MESSAGE":"ERROR",
      "ERROR.TITLE":"Assign Tariff Type Fail"
    },
    "MAPP-FBS-STATUS-PIPE":{
      "AVAILABLE": "Available",
      "DRAFT": "Draft",
      "UNAVAILABLE": "Unavailable",
      "MIX":"Available:%1 Unavailable:%2 Total:%3",
    },
    "MAPP-FBS-ACCESSORY-RESERVATION":{
      "AVAILABLE_TO_BOOK": "Available units to book:",
      "AMOUNT":"Amount",
      "AMOUNT_NOT_VALID":"Not a valid amount",
      "BACK":"Back",
      "BOOKING":"Booking",
      "CANCEL":"Cancel",
      "CONFIRM":"Confirm",
      "CONFIRMBOOKING":"Confirm Booking",
      "COST":"Cost",
      "DESCRIPTION":"Description",
      "NOT_AVAILABLE":"No available units to book. Please check another date/time.",
      "NOT_POSSIBLE":"No possible to make the reservation",
      "CHECK_AVAILABILITY":"To change the amount and/or the date, please check the availability.",
      "CHANGE_DATE":"To change the booking dates, please use the slider",
      "REQUESTED":"Units requested:",
      "RESERVE":"Reserve",
      "RESERVED_UNIS": "Reserved units:",
      "TOTAL_UNIS": "Total units:",
      "TITLE":"Title",
      "TITLE_NOT_VALID":"Title is mandatory",
      "UPDATE":"Update",
      "UNITARY_COST":"Unitary cost:",
    },
    "MAPP-FBS-FACILITY-RESERVATION":{
      "AVAILABLE_TO_BOOK":"The facility is available in ",
      "BACK":"Back",
      "BOOKING":"Booking",
      "CAPACITY":"Capacity",
      "CANCEL":"Cancel",
      "CONFIRM":"Confirm",
      "CONFIRMBOOKING":"Confirm Booking",
      "COST":"Cost",
      "NOT_POSSIBLE":"No possible to make the reservation",
      "CHECK_AVAILABILITY":"To change the date and/or time, please check the availability.",
      "CHANGE_DATE":"To change the booking dates, please use the slider",
      "DESCRIPTION":"Description",
      "NOT_AVAILABLE":"The facility is not available. Please check another date/time.",
      "RESERVE":"Reserve",
      "TITLE":"Title",
      "TITLE_NOT_VALID":"Title is mandatory",
      "UPDATE":"Update",
      "UNITARY_COST":"Unitary cost:",
    },
    "MAPP-FBS-DELETE-RESERVATION":{
      "TITLE":"Delete reservation ",
      "TEXT":"Please confirm deletion of the reservation ",
      "CANCEL":"Cancel",
      "CONFIRM":"Delete",
      "PRICE":"Price",
      "RESOURCES":"Total Resources",
      "PARTICIPANTS":"Max. Participants",
      "LOCATION":"Location"
    },
    "MAPP-FBS-RESERVATION-SLOTS":{
      "TIME":"Time",
      "STATUS":"Status",
      "STATUS_FREE":"Free",
      "STATUS_NOT_RESERVABLE":"Not available for reservation",
      "STATUS_RESERVED":"%1 Reserved by %2",
    },
    "MAPP-FBS-ACCESSORY-RESERVATION-SLOTS":{
      "STATUS_ONE":"One unit available",
      "STATUS_NOT_RESERVABLE":"No available units",
      "STATUS_AVAILABLE":"%1 units available",
    },
    "MAPP-FBS-RESOUERCE-DETAILS":{
      "TITLE":"Resource Details",
      "CANCEL":"Cancel",
      "BOOK":"Book",
    },
    "MAPP-FBS-RESERVATION-CONFIRM":{
      "TITLE":"Title:",
      "DESCRIPTION":"Description:",
      "COST":"Cost:",
      "DATE":"Date and time:",
    },
    "MAPP-FBS-NEW-UPDATE-ACCESSORY":{
      "CONFIRM":"Confirm",
      "CHARGE_TYPE":"Charge",
      "DESCRIPTION":"*Description:",
      "DESCRIPTION_MANDATORY":"Description is mandatory",
      "NAME":"*Name:",
      "NAME_MANDATORY":"Name is mandatory",
      "TITLE":"Accessory"
    },
    "MAPP-FBS-SET-AVAILABLE-ACCESSORY":{
      "CANCEL":"Cancel",
      "CONFIRM":"Confirm",
      "TITLE":"Set Accessories Available",
      "NUMBER":"*Number of available accessories:",
      "NUMBER_MANDATORY":"Number of available accessories is mandatory",
    },
    "MAPP-FBS-FACILITY-DATA":{
      "PRICE":"Price",
      "RESOURCES":"Total Resources",
      "PARTICIPANTS":"Max. Participants",
      "LOCATION":"Location"
    },
    "MAPP-FBS-DELETE-RESOURCE":{
      "CANCEL":"Cancel",
      "DELETE":"Delete",
      "TEXT":"Delete resource?",
      "TITLE":"Delete Resource",
    },
    "MAPP-FBS-NEW-UPDATE-FACILITY":{
      "CAPACITY":"Capacity",
      "CAPACITY_ERROR":"Capacity must be a number",
      "CONFIRM":"Confirm",
      "CHARGE_TYPE":"*Charge",
      "DESCRIPTION":"*Description:",
      "DESCRIPTION_MANDATORY":"Description is mandatory",
      "LOCATION":"*Location:",
      "LOCATION_MANDATORY":"Location is mandatory",
      "NAME":"*Name:",
      "NAME_MANDATORY":"Name is mandatory",
      "TITLE":"Facility",
      "RESOURCES_IN_GROUP_LABEL": "Resources in this group",
      "TITLE_GROUP":"Facility Group",
    },
    "MAPP-FBS-SET-AVAILABLE-FACILITY":{
      "CANCEL":"Cancel",
      "CONFIRM":"Confirm",
      "TEXT":"Set facility as available?",
      "TITLE":"Set facility available",
    },
    "MAPP-FBS-SET-UNAVAILABLE-FACILITY":{
      "CANCEL":"Cancel",
      "CONFIRM":"Confirm",
      "TEXT":"Set facility as unavailable?",
      "TITLE":"Set facility unavailable",
    },
    "MAPP-FBS-MESSAGES":{
      "OPERATION_DONE": "Operation success.",
      "OPERATION_FAIL":"The operation could not be performed, please try again later.",
      "LOADING":"Unable to load data from server.",
      "NO_CHANGES":"No changes needed.",
      "RESOURCE_WITH_RESERVATIONS":"The operation could not be performed. The resource has active reservations.",
      "RESERVATION_NOT_DONE":"The requested reservation has not been completed, please recheck the availability.",
      "RESERVATION_NOT_DONE_DATES":"The requested reservation has not been completed, please recheck the dates.",
      "RESERVATION_PENDING_1":"The requested reservation has not been fully completed, 1 resource was not available.", 
      "RESERVATION_PENDING_X":"The requested reservation has not been fully completd, $1 resources were not available.", 
      "USER_HAS_NO_ACCESS":"Your user does not have access to the requested operation",
    },
    "MAPP-FBS-SET-AVAILABLE-GROUP": {
      "TITLE": "Set facilities group available",
      "DESC": "This will put all individual facilities in group as available. Are you sure?"
    }
  },
};
