import * as fbsReducer from './microapp-fbs-reducer';

import { ActionReducerMap } from '@ngrx/store';


export interface MicroappFbsStates {
    fbs: fbsReducer.FbsState;   
}

export const microappVmsReducer: ActionReducerMap <MicroappFbsStates> = {
    fbs: fbsReducer.fbsCounterReducer,
};
