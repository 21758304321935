import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MicroappFbsReservationGroupModel } from '../../../client/dto/microapp-fbs-reservation-group';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FbsConfig } from '../../../lib/microapp-facilitiesbooking-config';

@Component({
  selector: 'empusa-microapp-fbs-reservation-detail',
  templateUrl: './microapp-fbs-reservation-detail.component.html',
  styleUrls: ['./microapp-fbs-reservation-detail.component.css']
})
export class MicroappFbsReservationDetailComponent implements OnInit {
  reservation: MicroappFbsReservationGroupModel;

  constructor(private dialogRef: MatDialogRef<any>,
    public fbsConfig: FbsConfig,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data) {


    this.reservation = data;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  showMessage(message: string) {
    this.snackBar.open(
      //this.translate.instant("MAPP-FAULTREPORT.EDIT.UPDATE.NOT_OK.MESSAGE"),
      message,
      "X"
      , {
        duration: 5000,
      });
  }

}
