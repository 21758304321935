import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, User, Site, Tenant } from '@empusa/empusa-core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as vmsReducer from '../../../lib/microapp-vms.reducer';
import * as logBookActions from '../../../lib/logbook/store/microapp-vms-logbook.actions';
import { MicroappVmsSearchVisitFilter } from '../../../lib/model/microapp-vms-visit-filter.model';
import { MicroappVmsVisit } from '../../../lib/model/microapp-vms-visit.model';
import { MicroappVmsVisitGroup } from '../../../lib/model/microapp-vms-visit-group.model';
import { MicroappVmsVisitsUserFilter } from './microapp-vms-visits-filter';
import { DatePipe } from '@angular/common';
import { MicroappVmsTime } from '../../../lib/model/microapp-vms-time.model';
import * as moment from 'moment';
import { MICROAPP_VMS_NAME, VmsConfig } from '../../../lib/microapp-vms-config';
import { MicroappVmsVisitDetailContainerComponent } from '../visit-detail/microapp-vms-visit-detail-container.component';
import { MicroappVmsDeleteGroupVisitComponent } from '../delete-visit/group/microapp-vms-delete-group-visit.component';
import { MicroappVmsDeleteSingleVisitComponent } from '../delete-visit/single/microapp-vms-delete-single-visit.component';
import { MicroappVmsCreateNewVisitComponent } from '../../group-visit-manage/new-visit/microapp-vms-create-new-visit.component';
import { MicroappVmsSingleVisitContainerComponent } from '../../common/single-visit-detail/microapp-vms-single-visit-container.component';
import { MicroappVmsUpdateVisitComponent } from '../edit-visit/group/microapp-vms-update-visit.component';
import { MicroappVmsEditSingleVisitComponent } from '../edit-visit/single/microapp-vms-edit-single-visit.component';
import { MicroappVmsResentNotificationComponent } from '../../common/resent-notification/microapp-vms-resent-notification.component';
import { MicroappVmsCheckOutVisitComponent } from '../../common/check-out-visit/microapp-vms-check-out-visit.component';
import { VmsMasterConfiguration } from '../../../lib/model/microapp-vms-config.model';
import { getMasterConfig, get_filter } from '../../../lib/logbook/store/microapp-vms-logbook-selectors';
import { take } from 'rxjs/operators';
import { config } from 'process';

interface DateSearch {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'empusa-microapp-vms-logbook-list',
  styleUrls: ['./microapp-vms-logbook-list.component.css'],
  templateUrl: './microapp-vms-logbook-list.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})

export class MicroappVmsLogbookListComponent implements OnInit, OnDestroy {

  //Form
  formGroup: any;
  formCustomSearch: any;
  dateSearchControl = new FormControl();
  tenanSearchControl = new FormControl();
  dateSearchStart = new FormControl('', [Validators.required]);
  dateSearchEnd = new FormControl('', [Validators.required]);
  parkingFilter: boolean = null;

  basicGroupDisplayedColumns: string[] = ['Date', 'Start', 'End', 'Visitors', 'Actions'];
  groupDisplayedColumns: string[];
  dataSource: MatTableDataSource<MicroappVmsVisitGroup>;
  expandedElement: Visit;
  allRowsExpanded: boolean = false;

  displayedColumns2: string[] = ['img', 'name', 'company', 'checkIn', 'in', 'out', 'checkOut','extraData','parking', 'actions'];

  viewList1: string[] = ['nameEdificio'];
  inValue: number;
  outValue: number;
  inValuePercent: number;
  outValuePercent: number;

  tenants: Tenant[];
  allTenants = 'all';
  can_new_visit: boolean;
  loading: boolean;
  currenSearchValue: string;
  auto_check_in_mode: boolean = false;
  can_check_in_out: boolean = false;
  masterCfg: VmsMasterConfiguration;


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = mp;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentSiteSubscription: Subscription;
  storeSuscripcion: Subscription;
  subsDateSearch: Subscription;
  subsTenanSearchControl: Subscription;

  filter: MicroappVmsSearchVisitFilter = new MicroappVmsSearchVisitFilter();
  groupVisits: MicroappVmsVisitGroup[];
  pageLength: number = 0;
  userFilter = new MicroappVmsVisitsUserFilter();
  minDatestart;
  maxDateStart;
  minDateEnd;
  maxDateEnd;

  forced_off_set = undefined;

  dateSearch: DateSearch[] = [
    { value: 'tomorrow', viewValue: 'MAPP-VMS-LOGBOOK.TOMORROW' },
    { value: 'today', viewValue: 'MAPP-VMS-LOGBOOK.TODAY' },
    { value: 'yesterday', viewValue: 'MAPP-VMS-LOGBOOK.YESTERDAY' },
    { value: 'week', viewValue: 'MAPP-VMS-LOGBOOK.THIS-WEEK' },
    { value: 'month', viewValue: 'MAPP-VMS-LOGBOOK.THIS-MONTH' },
    { value: 'custom', viewValue: 'MAPP-VMS-LOGBOOK.CUSTOM' },
  ];
  readonly localDateFormat = "dd/MM/yyyy";

  constructor(public dialog: MatDialog,
    private datepipe: DatePipe,
    private authservice: AuthenticationService,
    private vmsConfig: VmsConfig,
    private translate: TranslateService,
    private store: Store<vmsReducer.MicroappVmsStates>,
  ) { }


  private config(masterConfig: VmsMasterConfiguration) {
    this.forced_off_set = this.masterCfg.offset;
    console.debug(this.forced_off_set);

    this.can_new_visit = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_NEW_VISIT);
    this.can_check_in_out = this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_CHECK_IN_OUT);
    console.debug("can_check_in_out:", this.can_check_in_out);
    console.debug("can_new_visit:", this.can_new_visit);
    console.debug("can_address_book:", this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_ADDRESS_BOOK));
    if (!this.masterCfg.parking_active) {
      let i = this.displayedColumns2.indexOf('parking');
      this.displayedColumns2.splice(i, 1);
    }

    if ('auto'.toLowerCase() == masterConfig.check_in_mode.toLowerCase()) {
      this.auto_check_in_mode = true;
      /*let i = this.displayedColumns2.indexOf('checkIn');
      this.displayedColumns2.splice(i, 1);
      i = this.displayedColumns2.indexOf('checkOut');
      this.displayedColumns2.splice(i, 1);*/
    }
  }

  ngOnInit() {
    this.store.select(getMasterConfig).pipe(take(1)).subscribe(masterCfg => {
      console.debug(masterCfg);
      this.masterCfg = masterCfg;
      this.config(masterCfg);
      this.initSite();
      this.storeSubscription();
      this.buildForm();
      this.currentSiteSubscription = this.authservice.getEvent_CurrentSite().subscribe(newSite => {
        this.initSite();
        this.store.dispatch(logBookActions.startLogBook({
          site: newSite,
          loggedUser: this.authservice.getCurrentUser()
        }));
        this.tenanSearchControl.reset();
      });
      this.store.select(get_filter).pipe(take(1)).subscribe(filter => {
        console.debug("Filter", filter);
        this.store.dispatch(logBookActions.getVisits({ filter }));
      });
    });
    this.initCustomDateRanges();

  }
  initCustomDateRanges() {
    this.minDatestart = new Date();
    this.maxDateStart = new Date();
    this.minDateEnd = new Date();
    this.maxDateEnd = new Date();
    this.minDatestart.setMonth(this.minDatestart.getMonth() - this.vmsConfig.CUSTOMSEARCH_MIN_RANGE_MONTHS);
    this.maxDateStart.setMonth(this.maxDateStart.getMonth() + this.vmsConfig.CUSTOMSEARCH_MAX_RANGE_MONTHS);
    this.minDateEnd.setMonth(this.minDateEnd.getMonth() - this.vmsConfig.CUSTOMSEARCH_MIN_RANGE_MONTHS);
    this.maxDateEnd.setMonth(this.maxDateEnd.getMonth() + this.vmsConfig.CUSTOMSEARCH_MAX_RANGE_MONTHS);
  }
  private storeSubscription() {
    this.storeSuscripcion = this.store.select('logBook').subscribe(datos => {
      this.loading = datos.loading;
      if (datos.filter_visit) {
        this.filter = datos.filter_visit;
      }
      if (datos.visits) {
        this.groupVisits = this.getGroups(datos.visits.slice());
        this.pageLength = this.groupVisits.length;
        // Assign the data to the data source for the table to render
        let tempDataSource = new MatTableDataSource(this.groupVisits);
        tempDataSource.sortingDataAccessor = (item, property) => {
          console.debug("Property", property);
          switch (property) {
            case 'Tenant': return item.tenant.name.toLocaleLowerCase();
            case 'Host': return item.host.firstName.toLocaleLowerCase()
              + item.host.lastName.toLocaleLowerCase();
            case 'Date': {
              return item.start_time
            }
            case 'Start': return item.start_time;
            case 'End': return item.end_time;
            case 'Visitors': return item.visits.length;
            default:
              if (typeof item[property] === 'string')
                return item[property].toLocaleLowerCase();

              else
                return item[property];
          }
        };
        tempDataSource.sort = this.sort;
        this.defineDataSourceFilter(tempDataSource);

        this.dataSource = tempDataSource;
        //To fitler after a search
        if (this.userFilter)
          this.dataSource.filter = JSON.stringify(this.userFilter);
      }

      this.suscribeToTenantSearchControl();


      this.subsDateSearch = this.dateSearchControl.valueChanges.subscribe(newValue => {
        if (!newValue)
          return;
        if (this.currenSearchValue === newValue)
          return;

        this.currenSearchValue = newValue;
        let filter = { ...this.filter };
        filter.site_id = this.authservice.getCurrentSite().uid;
        let dte = new Date();
        switch (newValue) {
          case 'tomorrow':
            dte.setDate(dte.getDate() + 1);
            filter.date_from = new Date(dte);
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to = new Date(dte);
            filter.date_to.setHours(23, 59, 59, 999);
            this.launchDateSearch(filter);
            break;
          case 'yesterday':
            dte.setDate(dte.getDate() - 1);
            filter.date_from = new Date(dte);
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to = new Date(dte);
            filter.date_to.setHours(23, 59, 59, 999);
            this.launchDateSearch(filter);
            break;
          case 'week':
            filter.date_from = moment().startOf('isoWeek').isoWeekday(this.masterCfg.first_day_week).toDate();
            filter.date_to = moment().endOf('isoWeek').isoWeekday(this.masterCfg.first_day_week + 6).toDate();
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to.setHours(23, 59, 59, 999);
            console.debug("Week:", filter);
            this.launchDateSearch(filter);
            break;
          case 'month':
            filter.date_from = moment().startOf('month').toDate();
            filter.date_to = moment().endOf('month').toDate();
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to.setHours(23, 59, 59, 999);
            this.launchDateSearch(filter);
            break;
          case 'custom':
            if (this.formCustomSearch) {
              this.formCustomSearch.reset();
            }
            this.buildCustomSearchForm();
            break;
          default:
            filter.date_from = new Date();
            filter.date_from.setHours(0, 0, 0, 0);
            filter.date_to = new Date();
            filter.date_to.setHours(23, 59, 59, 999);
            this.launchDateSearch(filter);
            break;
        }
        //this.filter = filter;
        //console.debug("Filter:", filter);
        /* this.store.dispatch(logBookActions.getVisits({ filter }));
         this.tenanSearchControl.reset();*/

      });
    });
  }
  launchDateSearch(filter) {
    this.store.dispatch(logBookActions.getVisits({ filter }));
    this.tenanSearchControl.reset();
  }
  customDateSearch() {
    let filter = { ...this.filter };
    filter.site_id = this.authservice.getCurrentSite().uid;
    filter.date_from = this.dateSearchStart.value;
    filter.date_from.setHours(0, 0, 0, 0);
    filter.date_to = this.dateSearchEnd.value;
    filter.date_to.setHours(23, 59, 59, 999);
    this.launchDateSearch(filter);
  }

  changeStart(event) {
    console.debug(event.value);
    this.minDateEnd = event.value;
    this.maxDateEnd = new Date(event.value);
    this.maxDateEnd.setMonth(this.maxDateEnd.getMonth() + this.vmsConfig.CUSTOMSEARCH_SEARCH_RANGE_MONTHS);
    if (event.value === null) {
      this.initCustomDateRanges();
    }
  }

  suscribeToTenantSearchControl() {
    if (this.subsTenanSearchControl) return;
    if (!this.dataSource) return;
    if (this.tenants.length <= 1) return;
    this.subsTenanSearchControl = this.tenanSearchControl.valueChanges.subscribe(tenantSearch => {
      if (this.userFilter.tenant_name == null && tenantSearch === 'all') return;
      if (tenantSearch === 'all') {
        this.userFilter.tenant_name = null;
      } else {
        this.userFilter.tenant_name = tenantSearch;
      }
      this.dataSource.filter = JSON.stringify(this.userFilter);
    });
  }


  initSite() {
    let displayedColumns = Object.assign([], this.basicGroupDisplayedColumns);
    const site = this.authservice.getCurrentSite();
    const userSite = this.authservice.getCurrentUser().sites.find(userSite => userSite.uid == site.uid);
    this.tenants = userSite.tenants;
    if (this.tenants.length > 1 || this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_FACILITY_SCOPE)) {
      displayedColumns.unshift('Tenant');
    };
    if (this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_TENANT_SCOPE)
      || this.authservice.canUserExecute(MICROAPP_VMS_NAME, this.vmsConfig.F_FACILITY_SCOPE)) {
      displayedColumns.unshift('Host');
    };
    this.groupDisplayedColumns = displayedColumns;
  }


  defineDataSourceFilter(tempDataSource: MatTableDataSource<MicroappVmsVisitGroup>) {
    tempDataSource.filterPredicate =
      (aGroup: MicroappVmsVisitGroup, filter: string) => {
        const theFilter: MicroappVmsVisitsUserFilter = JSON.parse(filter);
        let tenantFilter = false;
        let generalFilter = false;
        let startDateFilter = false;
        let endDateFilter = false;
        let starTimeFilter = false;
        let endTimeFilter = false;
        if (!theFilter) return true;

        if (theFilter.tenant_name) {
          if (aGroup.tenant.name.toLowerCase() == theFilter.tenant_name.toLowerCase()) {
            tenantFilter = true;
          }
        } else tenantFilter = true;

        //tenant filter
        if (theFilter.general) {
          aGroup.visits.forEach(visit => {
            if (visit.first_name != null && visit.first_name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (visit.last_name != null && visit.last_name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (visit.company != null && visit.company.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (visit.host.firstName != null && visit.host.firstName.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (visit.host.lastName != null && visit.host.lastName.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            } else if (visit.tenant != null && visit.tenant.name != null && visit.tenant.name.toLowerCase().indexOf(theFilter.general) !== -1) {
              generalFilter = true;
            }

          })
        } else generalFilter = true;

        //start date filter
        if (theFilter.start_date) {
          aGroup.visits.forEach(visit => {
            const visitDate = this.datepipe.transform(visit.date, "yyyyMMdd");
            if (theFilter.start_date <= visitDate) {
              startDateFilter = true;
            }
          });
        } else startDateFilter = true;

        //end date filter
        if (theFilter.end_date) {
          aGroup.visits.forEach(visit => {
            const visitDate = this.datepipe.transform(visit.date, "yyyyMMdd");
            if (theFilter.end_date >= visitDate) {
              endDateFilter = true;
            }
          });
        } else endDateFilter = true;

        //start time filter
        if (theFilter.start_time !== null) {
          let theTime = new Date(aGroup.start_time);
          theTime.setHours(theFilter.start_time.hour, theFilter.start_time.minutes);
          if (aGroup.start_time >= theTime) {
            starTimeFilter = true;
          }
        } else starTimeFilter = true;

        //start time filter
        if (theFilter.end_Time !== null) {
          let theTime = new Date(aGroup.end_time);
          theTime.setHours(theFilter.end_Time.hour, theFilter.end_Time.minutes);
          if (aGroup.end_time <= theTime) {
            endTimeFilter = true;
          }
        } else endTimeFilter = true;

        return generalFilter && startDateFilter && endDateFilter
          && starTimeFilter && endTimeFilter && tenantFilter;
      }
  }



  getGroups(visits: MicroappVmsVisit[]): MicroappVmsVisitGroup[] {
    let groupedVisits: MicroappVmsVisitGroup[] = [];
    let ciValue = 0;
    let coValue = 0;

    visits.forEach(visit => {
      let group = groupedVisits.find(group => group.group_id == visit.group_id)
      if (group && group.group_id && group.group_id !== '') {
        group.addVisit(visit);
      } else {
        let newGroup = new MicroappVmsVisitGroup(visit);
        groupedVisits.push(newGroup);
      }
      if (visit.first_in_time) {
        ciValue = ciValue + 1;
      }
      if (visit.last_out_time) {
        coValue = coValue + 1;
      };
    })
    console.debug("Grupo", groupedVisits);
    this.inValue = ciValue;
    this.outValue = coValue;
    this.inValuePercent = ciValue * 100 / visits.length;
    this.outValuePercent = coValue * 100 / visits.length;
    return groupedVisits;
  }

  applyGenericFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.userFilter.general = filterValue;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStartDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.start_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyEndDateFilter(filterValue: Date) {
    const theDate = this.datepipe.transform(filterValue, "yyyyMMdd");
    this.userFilter.end_date = theDate;
    this.dataSource.filter = JSON.stringify(this.userFilter);
  }

  applyStartTimeFilter(filterValue: string) {
    const time = new MicroappVmsTime(filterValue);
    if (time.hour !== null) {
      this.userFilter.start_time = time;
      this.dataSource.filter = JSON.stringify(this.userFilter);
    }
    else {//clear filter if not valid
      if (this.userFilter.start_time !== null) {
        this.userFilter.start_time = null;
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    }
  }


  applyEndTimeFilter(filterValue: string) {
    const time = new MicroappVmsTime(filterValue);
    if (time.hour !== null) {
      this.userFilter.end_Time = time;
      this.dataSource.filter = JSON.stringify(this.userFilter);
    }
    else {
      if (this.userFilter.end_Time !== null) {
        this.userFilter.end_Time = null;
        this.dataSource.filter = JSON.stringify(this.userFilter);
      }
    }
  }

  applyParkingFilter() {
    console.debug("Filer:", this.parkingFilter);
  }

  groupDetail(element: MicroappVmsVisitGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'popToolbar'];
    dialogConfig.width = '440px';
    dialogConfig.data = element;
    const dialogRef = this.dialog.open(MicroappVmsVisitDetailContainerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.debug(`Visit group result: ${result}`);
    });
  }

  groupDelete(element: MicroappVmsVisitGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = element;
    const dialogRef = this.dialog.open(MicroappVmsDeleteGroupVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.debug(`Dialog result: ${result}`);
    });
  }

  singleDelete(visit_id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit_id;
    const dialogRef = this.dialog.open(MicroappVmsDeleteSingleVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.debug(`Dialog result: ${result}`);
    });
  }

  checkOut(visit_id: number) {
    console.debug("Do check-out");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit_id;
    const dialogRef = this.dialog.open(MicroappVmsCheckOutVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result <= 1) {
        this.store.dispatch(logBookActions.getVisits({ filter: this.filter }));
      }
    });
  }


  notify(visit: MicroappVmsVisit) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'containerDeleteVisitor'];
    dialogConfig.data = visit;
    const dialogRef = this.dialog.open(MicroappVmsResentNotificationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        visit.notification_status = "N"
        this.store.dispatch(logBookActions.refreshSingleVisit({ visit }));
      }
    });
  }

  updateSingleVisit(visit: MicroappVmsVisit) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'editVisitSingle'];
    dialogConfig.width = '650px';
    if (this.vmsConfig.REQUEST_LICENSE_PLATE == true) {
      dialogConfig.width = '900px';
    }
    dialogConfig.maxWidth = '90vw';
    dialogConfig.maxHeight = '98%';
    dialogConfig.data = visit;
    console.debug("updateSingleVisit", visit);
    const dialogRef = this.dialog.open(MicroappVmsEditSingleVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.debug(`Dialog result: ${result}`);
    });

  }

  detailSingleVisit(visit: MicroappVmsVisit) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'popToolbar'];
    dialogConfig.width = '640px';
    dialogConfig.data = visit;
    console.debug("singel visit detail", visit);
    const dialogRef = this.dialog.open(MicroappVmsSingleVisitContainerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.debug(`Dialog result: ${result}`);
    });

  }


  updateGroupVisit(groupVisit: MicroappVmsVisitGroup) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['microapp', 'editVisit'];
    dialogConfig.width = '100%';
    dialogConfig.data = groupVisit;
    const dialogRef = this.dialog.open(MicroappVmsUpdateVisitComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(ok => {
      if (ok) {
        this.store.dispatch(logBookActions.getVisits({ filter: this.filter }));
        this.tenanSearchControl.reset();
      }
    });
  }


  newVisitDialog() {
    const dialogRef = this.dialog.open(MicroappVmsCreateNewVisitComponent, { panelClass: ['microapp', 'newVisit'], width: '100%', height: 'auto' });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.store.dispatch(logBookActions.getVisits({ filter: this.filter }));
        this.tenanSearchControl.reset();
      }
    });
  }


  onExpand($event) {
    $event.preventDefault();
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  onContinue() {
    console.debug("TODO");
  }

  reload() {
    this.store.dispatch(logBookActions.getVisits({ filter: this.filter }));
  }

  private buildForm() {

    if (this.tenants.length > 1) {
      this.formGroup = new FormGroup({
        dateSearchControl: this.dateSearchControl,
        tenanSearchControl: this.tenanSearchControl,
      });
    } else {
      this.formGroup = new FormGroup({
        dateSearchControl: this.dateSearchControl,
      });
    }
    this.formGroup;

  }
  private buildCustomSearchForm() {
    this.formCustomSearch = new FormGroup({
      dateSearchStart: this.dateSearchStart,
      dateSearchEnd: this.dateSearchEnd
    });
  }

  ngOnDestroy(): void {

    if (this.currentSiteSubscription) {
      this.currentSiteSubscription.unsubscribe()
    };
    if (this.storeSuscripcion) {
      this.storeSuscripcion.unsubscribe()
    };
    if (this.subsDateSearch) {
      this.subsDateSearch.unsubscribe()
    };
    if (this.subsTenanSearchControl) {
      this.subsTenanSearchControl.unsubscribe()
    };
  }

}

export interface Edificio {
  nameEdificio: string;
}

export interface Visit {
  Date: string;
  Start: string;
  End: string;
  Company: string;
  Visitors: string;
  Parking: string;
  Actions: any;
}



export interface Visitor {
  name: string;
  company: string;
  checkIn: string;
  checkOut: string;
  parking: string;
  actions: any;
}
