<h2 class="titleNew">{{'MAPP-FAULTREPORT.EDIT.TITLE' | translate}}<button class="closeEditVist" mat-icon-button
    mat-dialog-close>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
</button></h2>
<div *ngIf="displayLog === false">
<form class="infoIssue" [formGroup]="editForm">

    <div class="divSuperior">
        <div class="izquierda">
            <mat-form-field class="readonly">
                <div class="divReporterMail">
                    <input class="inputReporterMail"
                        placeholder="{{'MAPP-FAULTREPORT.ISSUE.INFORMANT' | translate}}" matInput [readonly]="true"
                        formControlName="reporter" name="reporter" mt-autofocus>
                </div>
            </mat-form-field>
            <!-- <mat-form-field [ngClass]="{'hiddenElement':!allowEdit, 'readSeverity': allowEdit}">
            <div class="divReporterMail">
                <input class="inputSeverity" placeholder="{{'MAPP-FAULTREPORT.ISSUE.SEVERITY' | translate}}"
                    matInput [readonly]="true" formControlName="severity" name="severity" mt-autofocus>
            </div>
        </mat-form-field> -->
            <p class="pEdit"></p>

            <mat-form-field class="readonly">
                <input matInput [readonly]="true" [errorStateMatcher]="matcher" formControlName="summary"
                    placeholder="{{'MAPP-FAULTREPORT.ISSUE.TITLE' | translate}}" name="summary"
                    [ngClass]="{ 'is-invalid': f.summary.errors }" mt-autofocus>
                <mat-error *ngIf="editForm.controls.summary.hasError('required')">
                    {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field [ngClass]="{'hiddenElement':!allowEdit, 'readonlyChangeGroup': allowEdit}">
                <input matInput [readonly]="true" placeholder="{{'MAPP-FAULTREPORT.LIST.TABLE.GROUP' | translate}}"
                    formControlName="owner" name="owner" [ngClass]="{ 'is-invalid': f.owner.errors}" mt-autofocus>
            </mat-form-field>

            <button class="changeGroup" mat-button color="primary" [ngClass]="{'hiddenElement':!allowEdit}"
                (click)="changeAssign()">{{'MAPP-FAULTREPORT.ISSUE.GROUP_RESOLVING' | translate}}</button>
        </div>

        <div class="derecha">
            <mat-form-field class="formFieldDate" appearance="none">
                <mat-label class="labelCuadrado">{{'MAPP-FAULTREPORT.EDIT.CREATED' | translate}}:</mat-label>
                <input class="inputCuadrado" matInput [readonly]="true" [errorStateMatcher]="matcher"
                    formControlName="date" name="date" [ngClass]="{ 'is-invalid': f.date.errors }" mt-autofocus>
                <mat-error *ngIf="editForm.controls.date.hasError('required')">
                    {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <p class="pEdit"></p>

            <div>
                <mat-form-field class="formFieldPriority" appearance="none">
                    <mat-label class="labelCuadrado">{{'MAPP-FAULTREPORT.ISSUE.PRIORITY' | translate}}</mat-label>
                    <img [ngClass]="{'impHigh': f.priority.name === 'urgent', 'impMedium': f.priority.name ==='moderate',
               'impLow': f.priority.name ==='low', 'impCritical': f.priority.name === 'critical'}">
                    <input class="inputCuadrado" matInput *ngIf="!allowEdit || isSub" [readonly]="true"
                        [errorStateMatcher]="matcher" formControlName="priority" name="priority" mt-autofocus>
                    <mat-select *ngIf="allowEdit && !isSub" formControlName="priority">
                        <mat-option *ngFor="let priority of listPriorities" [value]="priority.name">
                            {{priority.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="formFieldStatus" appearance="none">
                    <mat-label class="labelCuadrado">{{'MAPP-FAULTREPORT.ISSUE.STATUS' | translate}}</mat-label>
                    <mat-chip [ngClass]="{'statusAssigned':isAssigned, 'statusClose':isClose, 'statusOpen':isNew}">
                        <input matInput [readonly]="true" [errorStateMatcher]="matcher"
                            placeholder="{{'MAPP-FAULTREPORT.ISSUE.STATUS' | translate}}" formControlName="status"
                            name="status" [ngClass]="{ 'is-invalid': f.status.errors }" mt-autofocus>
                    </mat-chip>
                    <mat-error *ngIf="editForm.controls.status.hasError('required')">
                        {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <p class="pEdit"></p>

    <div class="secondLine">
        <mat-form-field *ngIf='!noTowers' [ngClass]="{'readonly':!allowEdit, 'locationEdit': allowEdit }">
            <mat-label> {{'MAPP-FAULTREPORT.EDIT.TOWER' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="tower" name="tower" mt-autofocus>
            <mat-select *ngIf="allowEdit" formControlName="tower">
                <mat-option *ngFor="let tower of towers" [value]="tower">{{tower}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="formFieldNew" *ngIf='noTowers' [ngClass]="{'readonly':!allowEdit, 'locationEdit': allowEdit }">
            <mat-label>Building</mat-label>
            <input class="inputReporterMail" matInput [readonly]="true" formControlName="tower" name="tower"
                    mt-autofocus>
        </mat-form-field>

        <mat-form-field [ngClass]="{'readonly':!allowEdit, 'locationEdit': allowEdit }">
            <mat-label> {{'MAPP-FAULTREPORT.EDIT.FLOOR' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="floor" name="floor" mt-autofocus>
            <mat-select *ngIf="allowEdit" formControlName="floor">
                <mat-option *ngFor="let floor of floors" [value]="floor">{{floor}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [ngClass]="{'readonly':!allowEdit, 'locationEdit': allowEdit }">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.LOCATION' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="location" name="location" mt-autofocus>
            <mat-select *ngIf="allowEdit" formControlName="location">
                <mat-option *ngFor="let location of locations" [value]="location">{{location}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <p class="pEdit"></p>

        <mat-form-field [ngClass]="{'readonly':!allowEdit, 'formFieldNew': allowEdit}">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.ASSET' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="category" name="category" mt-autofocus>
            <mat-select *ngIf="allowEdit" formControlName="category">
                <mat-option *ngFor="let category of categorys" [value]="category">{{category}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [ngClass]="{'readonly':!allowEdit, 'formFieldNew': allowEdit}">
            <mat-label> {{'MAPP-FAULTREPORT.EDIT.SUBCATEGORY' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="subcategory" name="subcategory" mt-autofocus>
            <mat-select *ngIf="allowEdit" formControlName="subcategory">
                <mat-option *ngFor="let subcategory of subcategorys" [value]="subcategory">{{subcategory}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field *ngIf="isAdmin || isSuperAdmin || isSub" [ngClass]="{'readonly':!allowEdit, 'formFieldNew': allowEdit}">
            <mat-label>{{'MAPP-FAULTREPORT.ISSUE.TYPE' | translate}}</mat-label>
            <input matInput *ngIf="!allowEdit || isSub" [readonly]="true" [errorStateMatcher]="matcher"
                formControlName="type" name="type" mt-autofocus>
            <mat-select *ngIf="allowEdit && !isSub" formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type">{{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <p class="pEdit"></p>

    <mat-form-field class="formDescriptionNew">
        <input matInput [readonly]="true" [errorStateMatcher]="matcher" formControlName="description"
            placeholder="{{'MAPP-FAULTREPORT.ISSUE.DESCRIPTION' | translate}}" name="description"
            [ngClass]="{ 'is-invalid': f.description.errors }" mt-autofocus>
        <mat-error *ngIf="editForm.controls.description.hasError('required')">
            {{'MAPP-FAULTREPORT.EDIT.REQUIRED' | translate}}</mat-error>
    </mat-form-field>

    <p class="pEdit"></p>

    <div class="editCommentUpdate">
        <div>
            <div >
                <button *ngIf="!isClose" class="buttonUpload" mat-stroked-button (click)="toAddComment()">
                    <mat-icon class="icComment"> comment</mat-icon>
                    {{'MAPP-FAULTREPORT.EDIT.ADD_COMMENT.TITLE' | translate}}
                </button>
                <button mat-stroked-button class="buttonUpload" (click)="displayVisitLog()" mat-button>
                    <mat-icon>subject</mat-icon>
                    <mat-label>LOG</mat-label>
                </button>
                <button *ngIf="!isClose" class='buttonUpload' mat-stroked-button (click)="onClick()">
                    <mat-icon class="icComment">file_upload</mat-icon>
                    {{'MAPP-FAULTREPORT.ISSUE.NEW_ATTACHED' | translate}}
                </button>
                <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                    accept="file_extension|image/*" style="display:none;" />
            </div>
            <p></p>
            <div name="files">

                <mat-list class="listFiles">
                    <mat-list-item class="labelFileUpload" *ngFor="let fi of filesName">
                        <button mat-stroked-button (click)="downloadFile(fi.filename)">
                            {{fi.filename}}<mat-icon>download</mat-icon>
                        </button>
                        <button *ngIf="!isClose" class="buttonCancel" mat-stroked-button (click)="deleteFile(fi.filename)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>



            </div>
        </div>
    </div>
</form>

<p class="pEdit"></p>
<div [ngClass]="{'hiddenElement':!isAdmin, 'hiddenElement2': isClose, 'checkResolve': isAdmin}" align="end">
    <mat-slide-toggle class="slideToggleResolve" labelPosition="before" [(ngModel)]="isChecked">
        {{'MAPP-FAULTREPORT.RESOLVE' | translate}}
    </mat-slide-toggle>
    <p></p>
    <mat-form-field *ngIf="isChecked === true" class="textResolveDiv">
        <mat-label>{{'MAPP-FAULTREPORT.EDIT.RESOLUTION' | translate}}*</mat-label>
        <textarea [(ngModel)]="textResolution" placeholder="{{'MAPP-FAULTREPORT.EDIT.TEXTRESOLUTION' | translate}}"
            matInput></textarea>
    </mat-form-field>

</div>

<div class="buttonDetailFaul" mat-dialog-actions align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close [ngClass]="{'hiddenElement':!allowEdit}">
        {{'MAPP-FAULTREPORT.EDIT.CANCEL' | translate}}</button>
    <button [disabled]="!editForm.valid" class="buttonConfirm" mat-raised-button [ngClass]="{'hiddenElement':!allowEdit}" color="primary"
        (click)="onUpdate()">
        {{'MAPP-FAULTREPORT.UPDATE' | translate}}</button>
</div>
</div>
<div *ngIf="displayLog === true">
<h3 class="titleLog">
    <mat-label>{{'MAPP-FAULTREPORT.ISSUE.EVENT_LOG' | translate}}</mat-label>
</h3>
<mat-list class="listLog" role="list">
    <mat-list-item class="itemLog" *ngFor="let item of listLogIssue">
        <p class="pItem" *ngIf="item.new_value!='' && item.field=='comment'">{{item.date}} - {{item.owner}}: "{{item.new_value}}"
        </p>
        <p class="pItem" *ngIf="item.new_value!='' && item.field=='cc'">{{item.date}} - A email has been sent
        </p>
        <p class="pItem" *ngIf="item.new_value!='' && item.field=='owner' && (!isUser && !isManagement)">{{item.date}} - The new resolver group is
            {{item.new_value}} by {{item.owner}}
        </p>
        <p class="pItem" *ngIf="item.new_value!='' && item.field=='attachment'">{{item.date}} - Attach file --> {{item.new_value}}
        </p>
        <p class="pItem" *ngIf="item.new_value!='' && item.field=='read'">{{item.date}} - The issue has been read by {{item.owner}}
        </p>
        <p class="pItem" *ngIf="item.field !='owner' && item.field !='read' && item.field !='cc' && item.field !='attachment' && item.field !='comment'">{{item.date}} - The {{item.field}} was changed
            to {{item.new_value}} by {{item.owner}}
        </p>
       
    </mat-list-item>
</mat-list>
<button class="buttonBack" (click)="closeVisitLog()" mat-button>
    <mat-icon>arrow_back</mat-icon>
    <mat-label>BACK</mat-label>
</button>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>