import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule,  FormsModule  } from '@angular/forms';
import { EmpusaQuestionDialog, TranslationLoaderService } from '@empusa/empusa-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MicroappNotificationsComponent } from './microapp-notifications.home.component';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule} from '@angular/common/http';
import { MatListModule} from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MicroappListComponent } from './list/microapp-notifications.list.component';
import { MicroappNewComponent } from './new/microapp-notifications.new.component';
import { MicroappDetailsComponent } from './details/microapp-notifications.details.component';


export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}


@NgModule({
  entryComponents: [
    EmpusaQuestionDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    FormsModule,
    HttpClientModule,
    MatSortModule,
    MatSidenavModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AngularEditorModule,
    RouterModule.forChild([
      {
        path: '', component: MicroappNotificationsComponent
        // , children:[
          // { path: '', redirectTo: "list", pathMatch: 'full' },
          // { path: 'list', component: MicroappListComponent},
          // { path: 'new', component: MicroappNewComponent},
          // { path: 'detail', component: MicroappDetailsComponent},
         
        // ]
      },
    ])
  ],
  declarations: [
    MicroappNotificationsComponent,
    MicroappListComponent,
    MicroappNewComponent,
    MicroappDetailsComponent,
    MatSpinnerOverlayComponent,
  ],
  providers: [
    MatSnackBar, DatePipe,
    { provide: DateAdapter, useClass: DateFormat }
  ]
})
export class NotificationModule {

  constructor(private translationLoader: TranslationLoaderService,  private dateAdapter: DateAdapter<Date>) {
    console.log("generic microapp - lazy constructor");
    dateAdapter.setLocale("en-SG"); // DD/MM/YYYY
  }

}
