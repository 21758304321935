import { Observable, of, from, throwError } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmpusaFakeBackend } from '@empusa/empusa-core';
import { MICROAPPFAKEDATABASE } from './fake.database';

/**
 * This is CRUD example for the Generic Fake Backend
 */
export class UserManagementFakeBackend extends EmpusaFakeBackend {
    microappFakeData = MICROAPPFAKEDATABASE;

    handleRoute(url: string, method: string, headers, body, database: any): Observable<HttpEvent<any>> {
        switch (true) {
            default:
                //otherwise, we don't handle this request
                return null;
        }

    }


}