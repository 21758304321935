<div>
    <h2 *ngIf="editSurvey.sid" class="titleNew">{{'MAPP-QUIZ.EDITSURVEY.TITLE' | translate}}<button class="icClose" mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button>
    </h2>
    <h2 *ngIf="!editSurvey.sid" class="titleNew">{{'MAPP-QUIZ.NEWSURVEY.TITLE' | translate}}<button class="icClose" mat-icon-button mat-dialog-close>
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example close icon">close</mat-icon>
        </button>
    </h2>
</div>
<form class="container" [formGroup]="surveyForm">

    <mat-form-field class="titleSurvey">
        <input matInput [errorStateMatcher]="matcher" formControlName="title"
            placeholder="{{'MAPP-QUIZ.EDITSURVEY.FIELD.TITLE' | translate}}" name="title" required
            [ngClass]="{ 'is-invalid': f.title.errors }" mt-autofocus [readonly]="activa">
        <mat-error *ngIf="surveyForm.controls.title.hasError('required')">
            {{'MAPP-QUIZ.EDITSURVEY.REQUIRED' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="dateStart">
        <mat-label>{{'MAPP-QUIZ.EDITSURVEY.FIELD.DATESTAR' | translate}}</mat-label>
        <input matInput [errorStateMatcher]="matcher" formControlName="dateStart" [matDatepicker]="dp_start" disabled>
        <mat-datepicker-toggle matSuffix [for]="dp_start"></mat-datepicker-toggle>
        <mat-datepicker #dp_start disabled="{{activa}}"></mat-datepicker>
        <mat-error *ngIf="surveyForm.controls.dateStart.hasError('required')">
            {{'MAPP-QUIZ.EDITSURVEY.REQUIRED' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="dateEnd">
        <mat-label>{{'MAPP-QUIZ.EDITSURVEY.FIELD.DATEEXPIRED' | translate}}</mat-label>
        <input matInput [errorStateMatcher]="matcher" formControlName="dateExpired" [matDatepicker]="dp_expired"
            disabled>
        <mat-datepicker-toggle matSuffix [for]="dp_expired"></mat-datepicker-toggle>
        <mat-datepicker #dp_expired disabled="false"></mat-datepicker>
        <mat-error *ngIf="surveyForm.controls.dateExpired.hasError('required')">
            {{'MAPP-QUIZ.EDITSURVEY.REQUIRED' | translate}}</mat-error>
    </mat-form-field>

    <mat-tab-group class="container2" [backgroundColor]="background">
        <mat-tab label="{{'MAPP-QUIZ.EDITSURVEY.FIELD.QUESTIONS' | translate}}" *ngIf="!activa">
            <div class="card-content">
                <div class="buttons">
                    <button class="extend" mat-raised-button color="primary" aria-label="FAB new question"
                        (click)="openDialogQuestion()">
                        <mat-icon>add</mat-icon>{{'MAPP-QUIZ.EDITSURVEY.NEW' | translate}}
                    </button>
                </div>
                <div class="prueba">
                    <table mat-table [dataSource]="questions" matSort class="mat-elevation-z8" id="questions">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.QUESTION.LIST.TABLE.CODE' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                        <ng-container matColumnDef="question">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.QUESTION.LIST.TABLE.QUESTION' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.QUESTION.LIST.TABLE.TYPE' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <span
                                    *ngIf="element.type == 'L'">{{'MAPP-QUIZ.QUESTION.LIST.TABLE.TYPES.ONE' | translate}}</span>
                                <span
                                    *ngIf="element.type == 'M'">{{'MAPP-QUIZ.QUESTION.LIST.TABLE.TYPES.MULTI' | translate}}</span>
                                <span
                                    *ngIf="element.type == 'U'">{{'MAPP-QUIZ.QUESTION.LIST.TABLE.TYPES.FREE' | translate}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>
                                {{'MAPP-QUIZ.QUESTION.LIST.TABLE.ACTIONS' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="action">
                                    <button type="button" mat-icon-button (click)="openDialogQuestion(element.sid)">
                                        <mat-icon class="icActions">edit</mat-icon>
                                    </button>
                                    <button type="button" mat-icon-button (click)="deleteQuestion(element.sid)">
                                        <mat-icon class="icActions">delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedQuestionColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedQuestionColumns;"></tr>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{'MAPP-QUIZ.EDITSURVEY.FIELD.PARTICIPANTS' | translate}}">
            <div class="card-content">
                <div class="buttons">
                    <button type="button" class="extend" mat-raised-button color="primary"
                        aria-label="FAB new participant" (click)="openDialogParticipant(false, null,'participants')">
                        <mat-icon>person_add</mat-icon>
                        <span class='extend_text'>{{'MAPP-QUIZ.PARTICIPANT.LISTS.ACTION.PARTICIPANTS' | translate}}</span>
                    </button>
                    <button type="button" class="extend" mat-raised-button color="primary"
                        aria-label="FAB new participant" (click)="openDialogParticipant(true, null, 'external')">
                        <mat-icon>person_add</mat-icon>
                        <span class='extend_text'>{{'MAPP-QUIZ.PARTICIPANT.LISTS.ACTION.NEWINVITED' | translate}}</span>
                    </button>
                    <button type="button" class="extend" mat-raised-button color="primary"
                    aria-label="FAB new participant" (click)="openDialogParticipant(false, null, 'tenants')">
                    <mat-icon>person_add</mat-icon>
                    <span class='extend_text'>{{'MAPP-QUIZ.PARTICIPANT.LISTS.ACTION.PARTICIPANTS_TENANTS' | translate}}</span>
                </button>
                </div>
                <div class="prueba">
                    <table mat-table [dataSource]="participants" matSort class="mat-elevation-z8" id='participants'>
                        <ng-container matColumnDef="firstname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.PARTICIPANT.LIST.TABLE.FIRSTNAME' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.PARTICIPANT.LIST.TABLE.LASTNAME' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.PARTICIPANT.LIST.TABLE.EMAIL' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>
                        <ng-container matColumnDef="tenant">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{'MAPP-QUIZ.PARTICIPANT.LIST.TABLE.TENANT' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.tenant_name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>
                                {{'MAPP-QUIZ.PARTICIPANT.LIST.TABLE.ACTIONS' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="action">
                                    <button type="button" mat-icon-button
                                        (click)="openDialogParticipant(true, element.sid)"
                                        *ngIf="!activa || element.new">
                                        <mat-icon class="icActions">edit</mat-icon>
                                    </button>
                                    <button type="button" mat-icon-button (click)="deleteParticipant(element.sid)"
                                        *ngIf="!activa || element.new">
                                        <mat-icon class="icActions">delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedParticipantColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedParticipantColumns;"></tr>
                    </table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</form>

<div mat-dialog-actions class="buttonFitting" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'MAPP-FITTINGOUT.ACTIONS.CANCEL' | translate}}</button>
    <button class="buttonConfirm" mat-raised-button color="primary" [disabled]="!surveyForm.valid" (click)="onSubmit()">
        {{'MAPP-FITTINGOUT.ACTIONS.SUBMIT' | translate}}</button>
</div>
<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>