import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as fbsActions from './microapp-fbs-actions'
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FbsConfig } from '../microapp-facilitiesbooking-config';
import { MicroappFbsUserModel } from '../../client/dto/microapp-fbs-user';
import { MicroappFacilitiesbookingService } from '../microapp-facilitiesbooking.service';
import * as microappFbsState from './microapp-fbs-app-reducer';
import { MicroappFbsChargesService } from '../microapp-facilitiesbooking-charge.service';
import { MicroappFbsChargeType } from '../../client/dto/microapp-fbs-charge-type';
import { MicroappFbsResourceGroup } from '../../client/dto/microapp-fbs-resource-group';
import { FbsResourceGroupAdapter } from '../../client/dto/microapp-fbs-resource-group-adapter';
import { MicroappFbsReservationGroupModel } from '../../client/dto/microapp-fbs-reservation-group';
import { MicroappFbsScheduleService } from '../microapp-facilitiesbooking-schedule.service';
import { MicroappFbsPeriod, MicroappFbsSchedule } from '../../client/dto/microapp-fbs-schedule';
import { AuthenticationService } from '@empusa/empusa-core';
import { MicroappFbsResourceModel } from '../../client/dto/microapp-fbs-resource';
import { MicroappFbsResourceListModel } from '../../client/dto/microapp-fbs-resource-list';

@Injectable()
export class MicroappFbsEffects {

    constructor(private actions$: Actions,
        private authservice: AuthenticationService,
        private http: HttpClient,
        private fbsConfig: FbsConfig,
        private fbsService: MicroappFacilitiesbookingService,
        private fbsChargesService: MicroappFbsChargesService,
        private fbsScheduleService: MicroappFbsScheduleService,
        private resourceGroupAdapter: FbsResourceGroupAdapter,
        private store: Store<microappFbsState.MicroappFbsStates>,
    ) { }



    loadFbsUser$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.loadFbsUser),
        switchMap(action => {

            return this.http.get<MicroappFbsUserModel>(this.fbsConfig.URL_REST_BASE + 'user/' + action.user.mail).
                pipe(
                    map(data => {
                        let fbsUser = data;
                        fbsUser.uid = action.user.uid;

                        return fbsActions.fbsUserLoaded({ fbsUser });
                    }),
                    catchError(error => {

                        return of(fbsActions.error({ errorMsg: "MAPP-FBS-MESSAGES.LOADING" }));
                    })
                );
        })
    ));



    loadedFbsUser$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.fbsUserLoaded),
        map(action => {

            //return fbsActions.loadData();
            return ({ type: "Dummy" });
        })
    ));

    loadData$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.loadData),
        withLatestFrom(this.store.select('fbs')),
        switchMap(([action, state]) => {

            const allFacilities = this.fbsService.getAllFacilities();
            // const myBookings = this.fbsService.getMyResevations(state.fbsUser.id);
            const defaultSchedule = this.fbsScheduleService.getDefaultSchedule();
            return forkJoin([allFacilities /* ,myBookings */, defaultSchedule]).pipe(
                map(resultados => {
                    const resourceList: MicroappFbsResourceListModel =  resultados[0];

                    const resources: MicroappFbsResourceModel[] = resourceList.resources;
                    const resourcesGroupedAsOne = resourceList.grouped_resources;
                    //mix all resources
                    let all_resources:MicroappFbsResourceModel[] = [];
                    resources.forEach(ele => {
                        all_resources.push({...ele});
                    });
                    resourcesGroupedAsOne.forEach(ele => {
                        all_resources.push({...ele});
                    });

                    // const myBookings: MicroappFbsReservationGroupModel[] = resultados[1];
                    const schedule: MicroappFbsSchedule = resultados[1];
                    let resources_grouped = this.generateGroups(all_resources, false, null,all_resources);
                    const resources_grouped_for_admin = this.generateGroups(all_resources, true, null,all_resources);


                    // tslint:disable-next-line: max-line-length
                    return fbsActions.dataLoaded({ resources:all_resources, resources_grouped, resources_grouped_for_admin, myBookings: [], schedule });
                }), catchError(error => {

                    return of(fbsActions.error({ errorMsg: "MAPP-FBS-MESSAGES.LOADING" }));
                })
            );
        })
    ));

    loadResources$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.loadResources),
        withLatestFrom(this.store.select('fbs')),
        switchMap(([action,state]) => {

            const allFacilities = this.fbsService.getAllFacilities();
            return allFacilities.pipe(
                map(resultados => {

                    const resourceList: MicroappFbsResourceListModel =  resultados;

                    const resources: MicroappFbsResourceModel[] = resourceList.resources;
                    const resourcesGroupedAsOne = resourceList.grouped_resources;
                    //mix all resources
                    let all_resources:MicroappFbsResourceModel[]=[];
                    resources.forEach(ele => {
                        all_resources.push({...ele});
                    });
                    resourcesGroupedAsOne.forEach(ele => {
                        all_resources.push({...ele});
                    });
                    //TODO: coger el listado tambien resourceList.xxx
                    const resources_grouped = this.generateGroups(all_resources, false,null,all_resources);
                    const resources_grouped_for_admin = this.generateGroups(all_resources, true,null, all_resources);
                    return fbsActions.resourcesLoaded({ resources:all_resources, resources_grouped, resources_grouped_for_admin });
                }), catchError(error => {

                    return of(fbsActions.error({ errorMsg: "MAPP-FBS-MESSAGES.LOADING" }));
                })
            );
        })
    ));

    checkAllFacilitiesAvailability$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.checkAllFacilitiesAvailability),
        withLatestFrom(this.store.select('fbs')),
        switchMap(([action, state]) => {
            const allFacilities = this.fbsService.checkAvailableResource(action.startDateTime, action.endDateTime, null, null);
            return allFacilities.
                pipe(
                    map(resources => {
                        const only_resources: MicroappFbsResourceModel[] = resources.resources;
                        const resourcesGroupedAsOne = resources.grouped_resources;
                        const schedule_for_day = this.get_schedule_for_today(state.schedule,action.startDateTime);
                        let resources_grouped = this.generateGroups(only_resources, false, schedule_for_day, only_resources);
                        let resources_grouped2 = this.generateGroups(resourcesGroupedAsOne, false, null, only_resources);
                        resources_grouped2.forEach(ele => {
                            resources_grouped.push(ele);
                        });
                        //MicroappFbsResourceModel[]
                        return fbsActions.checkedAllFacilitiesAvailability({ resources:only_resources, resources_grouped });
                    }),
                    catchError(error => {

                        return of(fbsActions.error({ errorMsg: "MAPP-FBS-MESSAGES.LOADING" }));
                    })
                );
        })
    ));


    loadMyReservations$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.loadMyReservations),
        withLatestFrom(this.store.select('fbs')),
        switchMap(([action, state]) => {
            const searchMyBookings = this.fbsService.getMyResevations(state.fbsUser.id, action.startDateTime, action.endDateTime,action.tenant_dn);
            return searchMyBookings.pipe(
                map(myBookings => {
                    return fbsActions.myReservationsLoaded({ myBookings });
                }), catchError(error => {

                    return of(fbsActions.error({ errorMsg: "MAPP-FBS-MESSAGES.LOADING" }));
                })
            );
        })
    ));


    loadChargeTypes$ = createEffect(() => this.actions$.pipe(
        ofType(fbsActions.loadChargeTypes),
        switchMap(action => {

            const tenant_dn = this.authservice.getCurrentUser().sites[0].tenants[0].dn
            const getChargeTypes = this.fbsChargesService.getChargeTypes(tenant_dn);
            return getChargeTypes.pipe(
                map(chargeTypes => {

                    return fbsActions.chargeTypesLoaded({ chargeTypes });
                }), catchError(error => {

                    let chargeTypes: MicroappFbsChargeType[] = [];
                    let freeCharge = new MicroappFbsChargeType;
                    freeCharge.application_charge_code = "FBS_FACILITY_TYPE_A";
                    freeCharge.description = "Charge Code for an Accessory Type A";
                    chargeTypes.push(freeCharge);
                    return of(fbsActions.chargeTypesLoaded({ chargeTypes }));
                    //eturn of(fbsActions.error());
                })
            );
        })
    ));

    private get_schedule_for_today(schedule: MicroappFbsSchedule, the_date: Date): MicroappFbsPeriod {
        const date = the_date.toISOString().split("T")[0];
        // NOTE: Booked do not give the schedule in a specified date, so as a template
        //       we take today schedule.
        let schedule_for_today = schedule.periods.find(x => x.date == date);
        if (!schedule_for_today) {
            schedule_for_today = schedule.periods.find(x => new Date(x.date).getDay() == the_date.getDay());
        }
        return schedule_for_today;
    }


    private generateGroups(resources: MicroappFbsResourceModel[], forAdmin: boolean, schedule_for_today: MicroappFbsPeriod, allResources?: MicroappFbsResourceModel[]): MicroappFbsResourceGroup[] {

        let view: MicroappFbsResourceGroup[] = [];
        resources.forEach(one_resource => {
            //If item is not available, then do not create the group
            if (!forAdmin && one_resource.statusId != this.fbsConfig.RESOURCE_STATUS_AVAILABLE) {
                //if the resource is a grouped resource and any resource is available and this one
                //  is not availabe, then mark the group as unavailable to delete it later
                if (one_resource.type == this.fbsConfig.RESERVATION_GROUP_ATTR_LABEL){
                    let group = view.find(x => x.resourceGroupId === one_resource.resourceGroup);
                    if (group) {
                        group.statusId = this.fbsConfig.RESOURCE_STATUS_UNAVAILABLE;
                    }else{
                        let group = this.resourceGroupAdapter.adapt(one_resource, schedule_for_today)
                        group.statusId = this.fbsConfig.RESOURCE_STATUS_UNAVAILABLE;
                        view.push(group);
                    }
                  }
                return;
            }

            if (one_resource.resourceGroup && one_resource.resourceGroup.length > 0 ) {
                let group = view.find(x =>  x.resourceGroupId === one_resource.resourceGroup);
                if (group) {
                    group.totalResources = group.totalResources + 1;
                    switch (one_resource.statusId) {
                        case this.fbsConfig.RESOURCE_STATUS_AVAILABLE: {
                            group.availableResources = group.availableResources + 1;
                            if (one_resource.canBookInSelectedPeriod) {
                                //group.canBookInSelectedPeriod = true;
                                group.availableResourcesToBook = group.availableResourcesToBook + 1;
                            };
                            if (!group.slots && one_resource.canBookInSelectedPeriod) {
                                group.slots = one_resource.slots;
                            };

                            if (group.combined_slots) {
                                group.combined_slots.forEach(combined_slot => {
                                  if (one_resource.slots) {
                                    one_resource.slots.forEach(item_one_slot => {
                                      if (combined_slot.startDateTime.getTime() == item_one_slot.startDateTime.getTime() &&
                                        combined_slot.endDateTime.getTime() == item_one_slot.endDateTime.getTime() &&
                                        item_one_slot.isReservable && !item_one_slot.isBooked) {
                                            combined_slot.slots.push(item_one_slot)
                                      }
                                    })
                                  }
                                });
                              }
                            break;
                        }
                        case this.fbsConfig.RESOURCE_STATUS_HIDDEN: {
                            group.hiddenResources = group.hiddenResources + 1;
                            break;
                        }
                        default: {
                           //this.fbsConfig.RESOURCE_STATUS_UNAVAILABLE
                            group.unAvailableResources = group.unAvailableResources + 1;
                            break;
                        }
                    }
                    group.addResourceToResourcesID(one_resource);
                } else {
                    view.push(this.resourceGroupAdapter.adapt(one_resource, schedule_for_today));
                }
            } else {
                view.push(this.resourceGroupAdapter.adapt(one_resource, schedule_for_today));
            };
        });
        //If any group is not availble to see, then delte it from list
        if (!forAdmin)
            view.forEach((element,index)=>{
                if(element.statusId!=this.fbsConfig.RESOURCE_STATUS_AVAILABLE)
                view.splice(index,1);
            });
        view.forEach((item) => {
          if (item.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP) {
            item.location = this.createCommaSeparatedGroupLocation(item, allResources);
            item.statusId = this.isResourceGroupAvailable(item, allResources) ?
              this.fbsConfig.RESOURCE_STATUS_AVAILABLE : this.fbsConfig.RESOURCE_STATUS_UNAVAILABLE;
            item.canBookInSelectedPeriod = this.checkIfGroupCanBookInSelectedPeriod(item, allResources);

          }
          if (item.type === this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP || item.type === this.fbsConfig.RESOURCE_TYPE_ATTR_ACCESSORY){
            item.image_urls = this.getAllImageUrls(item, allResources)
          }
        })
        return view;
    }

    createCommaSeparatedGroupLocation(resource: MicroappFbsResourceGroup, allResources: MicroappFbsResourceModel[]): string {
        const filteredResources = allResources.filter((r) =>
          resource.resourcesIds.some((id) => id.toString() === r.resourceId));

        const locationsArray = filteredResources.map((r) => r.location);

        const removedDuplicatedArray = locationsArray.filter((item, pos) => {
          return locationsArray.indexOf(item) == pos;
        });
        return removedDuplicatedArray.join(', ');

    }

    isResourceGroupAvailable(resource: MicroappFbsResourceGroup, allResources: MicroappFbsResourceModel[]) {
      // const filteredResources = allResources.filter((r) =>
      //     resource.resourcesIds.some((id) => id.toString() === r.resourceId));
      // TODO: La linea de arriba puede tener bugs. Comprobar!
      const filteredResources = allResources.filter((r) =>
        resource.resourcesIds.some((id) => id.toString() === r.resourceId) && r.type != this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP);
      return filteredResources.every((element) => element.statusId === this.fbsConfig.RESOURCE_STATUS_AVAILABLE);
    }

    checkIfGroupCanBookInSelectedPeriod(resource: MicroappFbsResourceGroup, allResources: MicroappFbsResourceModel[]) {
      // const filteredResources = allResources.filter((r) =>
      //     resource.resourcesIds.some((id) => id.toString() === r.resourceId));
      // TODO: La linea de arriba puede tener bugs. Comprobar!

      const filteredResources = allResources.filter((r) =>
        resource.resourcesIds.some((id) => id.toString() === r.resourceId) && r.type != this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP);
      return filteredResources.every((element) => element.canBookInSelectedPeriod === true);
    }

  getAllImageUrls(resource: MicroappFbsResourceGroup, allResources: MicroappFbsResourceModel[]) {

    const filteredResources = allResources.filter((r) =>
      resource.resourcesIds.some((id) => id.toString() === r.resourceId) && r.type != this.fbsConfig.RESOURCE_TYPE_ATTR_GROUP);
    let images = [];
    filteredResources.forEach(oneGroupResource => {
      if (oneGroupResource.image_urls && oneGroupResource.image_urls.length > 0){
        images = images.concat(oneGroupResource.image_urls);
      }
    });
    return images;
  }
}
